import {
  setMinutes,
  setSeconds,
  getMinutes,
  getHours,
  setMilliseconds,
  setHours,
} from 'date-fns';

import { zonedTimeToUtc } from 'date-fns-tz';

export const normalizeTimestamp = (
  videoAppointmentDate: Date,
  videoAppointmentTime: Date,
  timezone: string
) =>
  zonedTimeToUtc(
    setHours(
      setMinutes(
        setSeconds(setMilliseconds(videoAppointmentDate, 0), 0),
        getMinutes(videoAppointmentTime)
      ),
      getHours(videoAppointmentTime)
    ),
    timezone
  );
