import React from 'react';

export default ({
  color,
  secondaryColor,
  ...rest
}: React.SVGProps<SVGSVGElement> & any) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='45'
    height='44'
    viewBox='0 0 45 44'
  >
    <defs>
      <path
        id='5mfsi6513a'
        d='M43.15 21.51c0 11.879-9.66 21.509-21.575 21.509C9.659 43.019 0 33.389 0 21.509 0 9.63 9.66 0 21.575 0s21.574 9.63 21.574 21.51'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <g transform='translate(-185 -309) translate(0 44) translate(60 177) translate(124.925 88.671) matrix(-1 0 0 1 44.15 0)'>
                <mask id='cwywslhoib' fill={secondaryColor || '#fff'}>
                  <use xlinkHref='#5mfsi6513a' />
                </mask>
                <use fill={color || '#12A7A3'} xlinkHref='#5mfsi6513a' />
                <g fill={color || '#12A7A3'} mask='url(#cwywslhoib)'>
                  <path d='M0 0H43.149V43.019H0z' />
                </g>
                <path
                  fill={secondaryColor || '#fff'}
                  d='M26.995 31.743v2.22h-10.84v-2.22h10.84zm-2.21-22.345l1.574 1.57-2.405 2.399h2.707c3.266 0 5.922 2.648 5.922 5.904v5.157c0 3.256-2.656 5.904-5.922 5.904H16.488c-3.265 0-5.922-2.648-5.922-5.904v-5.157c0-3.256 2.657-5.904 5.922-5.904h2.708l-2.406-2.399 1.574-1.57 3.21 3.2 3.21-3.2zm1.876 6.566H16.488c-1.829 0-3.317 1.483-3.317 3.307v5.157c0 1.824 1.488 3.307 3.317 3.307h10.173c1.83 0 3.317-1.483 3.317-3.307v-5.157c0-1.824-1.488-3.307-3.317-3.307zm-5.086 2.488l4.785 4.77-1.575 1.57-3.21-3.2-3.21 3.2-1.575-1.57 4.785-4.77z'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
