import * as React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...rest}
  >
    <g fill='none' fillRule='evenodd' opacity='1'>
      <path d='M0 0H24V24H0z' />
      <path
        fill={color ?? '#333'}
        d='M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'
      />
    </g>
  </svg>
);
