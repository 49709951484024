import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='161'
    height='160'
    viewBox='0 0 161 160'
    {...rest}
  >
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g transform='translate(-719 -271) translate(597 271) translate(122.5) translate(30)'>
              <path
                fill='#333'
                d='M65.717 61.844L30.107 51.09 35.1 19.165 70.919 21.909z'
                transform='rotate(-25 50.513 40.504)'
              />
              <path
                fill='#FFCC46'
                d='M69.477 122H28.721c1.894-14.177 2.842-24.796 2.842-31.857 0-4.179-.633-10.42-1.898-18.724 0-14.956 6-19.56 14.164-23.312.553-.264 1.116-.478 1.69-.65 1.1-.327 2.229-.498 3.383-.498 1.454 0 2.867.27 4.228.784.575.221 1.137.478 1.691.785 2.93 1.59 5.944 2.262 8.071 5.856 3.484 5.87 5.247 8.077 5.247 17.035-.988 9.172-1.481 16.018-1.481 20.54 0 6.718.94 16.732 2.82 30.041z'
              />
              <circle
                cx='42'
                cy='51.342'
                r='1'
                fillRule='nonzero'
                stroke='#E6E6E6'
              />
              <circle
                cx='43'
                cy='54.342'
                r='1'
                fillRule='nonzero'
                stroke='#E6E6E6'
              />
              <circle
                cx='45'
                cy='57.342'
                r='1'
                fillRule='nonzero'
                stroke='#E6E6E6'
              />
              <circle
                cx='48'
                cy='59.342'
                r='1'
                fillRule='nonzero'
                stroke='#E6E6E6'
              />
              <circle
                cx='52'
                cy='59.342'
                r='1'
                fillRule='nonzero'
                stroke='#E6E6E6'
              />
              <circle
                cx='54'
                cy='56.342'
                r='1'
                fillRule='nonzero'
                stroke='#E6E6E6'
              />
              <circle
                cx='56'
                cy='53.342'
                r='1'
                fillRule='nonzero'
                stroke='#E6E6E6'
              />
              <circle
                cx='56'
                cy='50.342'
                r='1'
                fillRule='nonzero'
                stroke='#E6E6E6'
              />
              <path
                fill='#C37D5B'
                d='M31.432 32.551c.27.821.18 1.674-.183 2.388-.362.716-.998 1.292-1.821 1.561-1.647.538-3.42-.357-3.96-2l-.03-.097c-.101-.346-.14-.695-.123-1.037l.01-.137c.038-.392.15-.77.326-1.117.362-.715.998-1.291 1.822-1.56.678-.222 1.376-.202 2.001.016.007.001.014.004.022.008.127.044.252.097.372.158.347.174.662.414.925.707.198.223.367.476.499.757.038.083.075.169.106.257l.034.096'
              />
              <path
                fill='#A96648'
                d='M53.72 41.868v10.626c0 2.677-2.176 4.848-4.86 4.848S44 55.172 44 52.494V41.868h9.72z'
              />
              <path
                fill='#C37D5B'
                d='M24.746 85.23L20 51.37 26.473 50l4.747 33.859c.308 2.197-.892 4.285-2.68 4.664-1.787.378-3.486-1.096-3.794-3.293z'
              />
              <ellipse
                cx='21.403'
                cy='45.721'
                fill='#C37D5B'
                fillRule='nonzero'
                rx='6.655'
                ry='6.637'
              />
              <path
                fill='#C37D5B'
                d='M70.06 107.082l9.527-53.703-6.731-1.37-9.527 53.702c-.32 2.197.927 4.285 2.786 4.663 1.859.379 3.625-1.095 3.945-3.292z'
                transform='matrix(1 0 0 -1 0 162.442)'
              />
              <g fillRule='nonzero'>
                <path
                  fill='#333'
                  d='M.84 8.646L8.694.56C9.078.163 9.711.154 10.107.539l.012.011L23.4 13.872c.386.387.39 1.012.009 1.403l-7.978 8.212c-.385.396-1.018.405-1.414.02l-.018-.018L.843 10.042C.464 9.654.463 9.035.841 8.646z'
                  transform='rotate(180 18.856 25.496)'
                />
                <path
                  fill='#FFF'
                  d='M2.407 9.177l6.691-6.64c.39-.386 1.02-.386 1.409 0l11.307 11.22c.392.39.395 1.023.006 1.415l-.006.005-1.137 1.129c-.49-.272-1.1-.187-1.498.207l-2.802 2.781c-.377.374-.428.966-.121 1.398l-1.244 1.234c-.392.39-1.025.387-1.414-.005l-.001-.002L2.4 10.59c-.388-.392-.385-1.024.007-1.413z'
                  transform='rotate(180 18.856 25.496)'
                />
              </g>
              <path
                fill='#C37D5B'
                d='M66.26 21.24c.27.82.182 1.673-.182 2.387-.362.716-.998 1.292-1.821 1.561-1.648.538-3.42-.357-3.96-2l-.029-.096c-.103-.347-.14-.696-.124-1.038.002-.047.006-.092.01-.137.038-.392.15-.77.326-1.117.362-.715.998-1.291 1.822-1.56.678-.222 1.376-.202 2 .016.008.001.015.004.023.008.127.044.251.097.371.158.348.174.663.414.925.707.199.223.368.476.5.757.038.083.074.169.106.257l.034.096'
              />
              <path
                fill='#C37D5B'
                d='M63.476 21.168c1.575 4.796 1.056 9.774-1.066 13.946-2.115 4.176-5.83 7.54-10.637 9.112-9.616 3.145-19.963-2.082-23.114-11.674-.062-.19-.117-.375-.174-.565-.597-2.023-.82-4.065-.721-6.06.013-.27.033-.537.06-.8.217-2.289.87-4.499 1.9-6.522 2.116-4.176 5.83-7.54 10.638-9.111 3.958-1.294 8.034-1.173 11.682.094.043.009.083.024.13.048.741.256 1.467.567 2.168.92 2.03 1.022 3.868 2.418 5.4 4.135 1.16 1.3 2.15 2.776 2.914 4.415.228.485.438.988.625 1.504.067.187.133.369.195.558'
                transform='rotate(3 46.067 26.86)'
              />
              <path
                fill='#333'
                d='M43.396 6.533c3.923 0 7.641 2.051 9.48 3.317l.049-.07c.846.643 1.648 1.35 2.4 2.102 2.18 2.174 3.952 4.75 5.2 7.61.94 2.166 1.59 4.486 1.88 6.923-2.277-.837-4.293-1.865-5.969-3.04h-6.895l-2.36-7.68-1.808 7.68H29.08c-1.675 1.175-3.692 2.203-5.97 3.04.293-2.437.941-4.757 1.882-6.924 1.247-2.86 3.02-5.435 5.2-7.609.345-.345.7-.68 1.067-1.005.047-.152.091-.303.135-.451 3.33-2.448 7.488-3.893 12.002-3.893z'
                transform='rotate(-18 42.758 16.474)'
              />
              <path
                fill='#333'
                d='M40.848 33.7c-.629.128-1.255-.223-1.4-.784l-.419-1.642c-.143-.562.25-1.12.88-1.248.63-.127 1.255.223 1.4.785l.419 1.642c.143.561-.251 1.12-.88 1.247'
              />
              <circle
                cx='39.5'
                cy='31.5'
                r='1'
                fill='#FFF'
                fillRule='nonzero'
              />
              <path
                fill='#333'
                d='M52.848 30.7c-.629.128-1.255-.223-1.4-.784l-.419-1.642c-.143-.562.25-1.12.88-1.248.63-.127 1.255.223 1.4.785l.419 1.642c.143.561-.251 1.12-.88 1.247'
              />
              <circle
                cx='51.5'
                cy='28.5'
                r='1'
                fill='#FFF'
                fillRule='nonzero'
              />
              <ellipse
                cx='76.512'
                cy='108.938'
                fill='#C37D5B'
                fillRule='nonzero'
                rx='5.467'
                ry='5.454'
              />
              <ellipse
                cx='72.123'
                cy='112.632'
                fill='#C37D5B'
                fillRule='nonzero'
                rx='2.338'
                ry='2.332'
              />
              <path
                fill='#FEFEFE'
                d='M0 36.859L11.137 36.859M0 39.485L9.517 39.485M0 42.111L7.29 42.111'
              />
              <path
                fill='#C37D5B'
                fillRule='nonzero'
                d='M38 122L45 122 45 153 38 153zM54 122L61 122 61 153 54 153z'
              />
              <path
                fill='#4D4D4D'
                fillRule='nonzero'
                d='M31 153h14v7H24c0-3.866 3.134-7 7-7z'
              />
              <path
                fill='#4D4D4D'
                fillRule='nonzero'
                d='M61 153h14v7H54c0-3.866 3.134-7 7-7z'
                transform='matrix(-1 0 0 1 129 0)'
              />
            </g>
            <path
              fillRule='nonzero'
              stroke='#333'
              d='M80 27c0 .916.746 1.073 2.237.472M68 30c0 .916.746 1.073 2.237.472'
              transform='translate(-719 -271) translate(597 271) translate(122.5)'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
