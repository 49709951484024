import {
  formatClockToMinutes,
  formatMinutesToClock,
} from '@betterpt/better-components';
import {
  ProviderHoursState,
  FormattedOpenCloseDaysHours,
  initialFormattedOpenCloseDaysHours,
  initialSelectedDays,
  SelectedDays,
} from './initialValues';

export type ServerHours = {
  [day: string]: { open: number | null; close: number | null }[] | null;
};

export const convertAppHoursToServerHours = (
  providerHours: ProviderHoursState
): ServerHours => {
  const hoursForServer: ServerHours = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  };

  Object.entries(hoursForServer).forEach(([key, value]) => {
    const open = formatClockToMinutes(
      providerHours.formattedOpenCloseDaysHours[
        `${key}Open` as keyof FormattedOpenCloseDaysHours
      ] ?? ''
    );
    const close = formatClockToMinutes(
      providerHours.formattedOpenCloseDaysHours[
        `${key}Close` as keyof FormattedOpenCloseDaysHours
      ] ?? ''
    );
    value?.push({
      open,
      close,
    });
  });

  return hoursForServer;
};

export const convertServerHoursToAppHours = (
  serverHours: any
): ProviderHoursState => {
  const appHours: ProviderHoursState = {
    selectedDays: { ...initialSelectedDays },
    formattedOpenCloseDaysHours: { ...initialFormattedOpenCloseDaysHours },
  };

  Object.entries(serverHours).forEach(([key, value]) => {
    if (value && value[0].open && value[0].close) {
      appHours.selectedDays[`${key}OpenSelected` as keyof SelectedDays] = true;
      appHours.formattedOpenCloseDaysHours[
        `${key}Open` as keyof FormattedOpenCloseDaysHours
      ] = formatMinutesToClock(value[0].open);
      appHours.formattedOpenCloseDaysHours[
        `${key}Close` as keyof FormattedOpenCloseDaysHours
      ] = formatMinutesToClock(value[0].close);
    }
  });

  return appHours;
};
