import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='14'
    height='14'
    viewBox='0 0 14 14'
    {...rest}
  >
    <defs>
      <path
        id='4nc30c2xna'
        d='M7 1.458c3.06 0 5.542 2.481 5.542 5.542 0 3.06-2.481 5.542-5.542 5.542-3.06 0-5.542-2.481-5.542-5.542C1.458 3.94 3.94 1.458 7 1.458zm0 .584c-2.738 0-4.958 2.22-4.958 4.958S4.262 11.958 7 11.958 11.958 9.738 11.958 7 9.738 2.042 7 2.042zm0 .875c2.255 0 4.083 1.828 4.083 4.083S9.255 11.083 7 11.083 2.917 9.255 2.917 7 4.745 2.917 7 2.917z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g transform='translate(-139 -627) translate(112 381) translate(0 116) translate(0 123) translate(20) translate(1.167 1.167) translate(5.833 5.833)'>
                    <path d='M0 0L14 0 14 14 0 14z' />
                    <mask id='wk2rczly5b' fill='#fff'>
                      <use xlinkHref='#4nc30c2xna' />
                    </mask>
                    <use
                      fill={color ?? '#E74C3C'}
                      fillRule='nonzero'
                      xlinkHref='#4nc30c2xna'
                    />
                    <g fill={color ?? '#E74C3C'} mask='url(#wk2rczly5b)'>
                      <path d='M0 0H14V14H0z' />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
