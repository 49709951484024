import {
  usePatientContactListQuery,
  Contact,
  Clinic,
  Maybe,
} from '../../../../generated/graphql';
import usePatientFilters from '../../../../hooks/usePatientFilters';
import useDebounce from '../../../../hooks/useDebounce';
import useSnackbar from '../../../../hooks/useSnackbar';

export type PatientContactListResult = Pick<
  Contact,
  | 'id'
  | 'clinicId'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phone'
  | 'dateOfBirth'
  | 'archivedAt'
  | 'hasValidatedMobileNumber'
  | 'patientId'
> & { clinic?: Maybe<Pick<Clinic, 'clinicName' | 'displayName' | 'id'>> };

const usePatientContactList: any = () => {
  const snackbar = useSnackbar();
  const filters = usePatientFilters();
  const debouncedSearchTerm = useDebounce(filters.searchTerm.value, 500);

  const patientList = usePatientContactListQuery({
    variables: {
      input: {
        query: debouncedSearchTerm,
        offset: filters.offset.value,
        limit: 12,
        isArchived: filters.isArchived.value,
        clinicIds:
          filters.clinicIds.value.length > 0
            ? filters.clinicIds.value
            : undefined,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: () => snackbar?.useGenericErrorMessage(),
  });

  return patientList;
};

export default usePatientContactList;
