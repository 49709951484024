/* refactored 3/11/2021 */

import React from 'react';

// hooks
import useSnackbar from '../../../../hooks/useSnackbar';
import useTextFieldState from '../../../../hooks/useTextFieldState';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import useHandleError from '../../../../hooks/useHandleError';

// queries
import { useMeQuery } from '../../../../generated/graphql';

// Components
import { Header, Button } from '@betterpt/better-components';
import { GridCard } from '../../../Shared/GridCard';
import FormField from '../../../Shared/FormField';

// types
import { UpdateUserInfoInput } from '../hooks/useProfileOperations';

//style
import { logoutButtonStyle, H4, FormBody } from '../styles/Profile.style';

interface Props {
  updateUserInfo: (input: UpdateUserInfoInput) => Promise<boolean>;
}

const UserInfo = ({ updateUserInfo }: Props) => {
  //hooks
  const snackbar = useSnackbar();
  const { primaryColor } = useRemoteConfigAliases();
  const handleError = useHandleError();
  const [saving, updateSaving] = React.useState(false);
  const [isEditModeActive, setEditModeActive] = React.useState(false);

  const meQuery = useMeQuery();
  const firstNameFormState = useTextFieldState({ required: true });
  const lastNameFormState = useTextFieldState({ required: true });
  const genderFormState = useTextFieldState({ required: true });

  React.useEffect(() => {
    const me = meQuery.data?.me;
    if (meQuery.data) {
      firstNameFormState.updateValue(me?.firstName ?? '');
      lastNameFormState.updateValue(me?.lastName ?? '');
      genderFormState.updateValue(me?.gender ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meQuery.data]);

  //functions
  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateSaving(true);
    try {
      const savedNewChanges = await updateUserInfo({
        firstNameFormState,
        lastNameFormState,
        genderFormState,
      });
      if (savedNewChanges)
        snackbar?.setSuccessMessage('Information updated successfully');
      setEditModeActive(false);
    } catch (e) {
      handleError(e);
    }
    updateSaving(false);
  };

  return (
    <GridCard>
      <form onSubmit={handleSubmit}>
        <Header underline style={{ padding: '22px 23px 13px 42px' }}>
          <H4>User Info</H4>
          {isEditModeActive ? (
            <Button
              disabled={saving}
              size='small'
              color='transparent'
              type='submit'
              style={logoutButtonStyle(primaryColor, true)}
              loading={saving}
            >
              SAVE
            </Button>
          ) : (
            <Button
              size='small'
              color='transparent'
              style={logoutButtonStyle(primaryColor)}
              onClick={() => setEditModeActive(true)}
            >
              EDIT
            </Button>
          )}
        </Header>
        <FormBody>
          <FormField
            label='First Name'
            disabled={!isEditModeActive}
            placeholder='Enter your first name'
            formHandler={firstNameFormState}
            fullWidth
            inputProps={{
              maxLength: 49,
            }}
          />
          <FormField
            disabled={!isEditModeActive}
            label='Last Name'
            placeholder='Enter your last name'
            formHandler={lastNameFormState}
            fullWidth
            inputProps={{
              maxLength: 49,
            }}
          />
          <FormField
            disabled={!isEditModeActive}
            select
            SelectProps={{ native: true }}
            label='Sex Assigned at Birth'
            placeholder='select your sex assigned at birth'
            formHandler={genderFormState}
            fullWidth
          >
            <option value='' disabled>
              Select sex assigned at birth
            </option>
            <option value='M'>Male</option>
            <option value='F'>Female</option>
          </FormField>
        </FormBody>
      </form>
    </GridCard>
  );
};

export default UserInfo;
