export const renderDisplayText = ({
  isNewEmployee,
  selectedClinicIds,
  employeeClinics,
}: {
  isNewEmployee?: boolean;
  selectedClinicIds: Array<string>;
  employeeClinics: any;
}) => {
  if (isNewEmployee) {
    return (
      (selectedClinicIds.length > 0 &&
        `${selectedClinicIds.length} Selected`) ||
      'Select from list'
    );
  }

  return (
    (selectedClinicIds.length === 0 && 'Select from list') ||
    (employeeClinics &&
      employeeClinics?.length > 0 &&
      `${employeeClinics[0]?.displayName}${(employeeClinics.length > 1 &&
        ` +${employeeClinics.length - 1} more`) ||
        ''}`) ||
    ''
  );
};
