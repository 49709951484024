import styled from '@emotion/styled';
import { bestTextColor } from '@betterpt/better-components';

export const ServiceAreaHeader = styled.div`
  display: flex;
  margin: 25px 0px 0px;
  align-items: center;
  h2 {
    margin-right: 14px;
  }
  .timezone {
    background-color: ${(props) => props.color};
    color: ${(props) => bestTextColor(props.color)};
    padding: 3px 20px;
    border-radius: 4px;
  }
`;

export const facilityLogo = {
  height: '28px',
  width: '28px',
  position: 'relative' as 'relative',
  borderRadius: '50%',
  margin: '0',
};
