import { css } from '@emotion/react';

export const arrowStyle = css`
  border: none;
  background: none;
  cursor: pointer;
  svg {
    height: 36px;
    width: 36px;
  }
`;

export const imageSection = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  img {
    width: 100%;
  }
`;

export const mainSection = (width?: string) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: ${width || ''};
`;
