import * as React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { colorValues } from '@betterpt/better-components';

type Props = {
  notificationCount: number;
};

const bounceAnimation = keyframes`
from, 25%, 55% { transform: translateY(0); }
35% { transform: translateY(-6px); }
`;

const NotificationCount = styled.p`
  color: ${colorValues.emptiness} !important;
  background-color: ${colorValues.orangepink};
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: 0px;
  top: 0;
  right: 0;
  font-weight: normal !important;
  font-size: 10px;
  animation: ${bounceAnimation} 1.5s linear infinite;
`;

const NotificationCounter = ({ notificationCount }: Props) => {
  return <NotificationCount>{notificationCount}</NotificationCount>;
};

export default NotificationCounter;
