import { format, startOfYear, endOfYear, isValid, subDays } from 'date-fns';
import _ from 'lodash';

import {
  NewAppointmentStatus,
  Order,
} from '../generated/graphql';

export enum TimeDisplayValue {
  Today = 'today',
  Week = 'this week',
  Month = 'this month',
  Year = 'this year',
  Range = 'range',
}

export enum CreatedTimeDisplayValue {
  Today = 'today',
  Week = 'last 7 days',
  Month = 'last 30 days',
  Year = 'last 365 days',
  Range = 'range',
}

export enum FullInPersonStatus {
  Upcoming = 'upcoming',
  Pending = 'pending',
  Confirmed = 'confirmed',
  Completed = 'completed',
  Noshow = 'noshow',
  Cancelled = 'cancelled',
  Declined = 'declined',
}

export const sharedDefaultFilters = {
  appointmentStatuses: [NewAppointmentStatus.Completed],
  start: format(startOfYear(new Date()), 'yyyy-MM-dd'),
  end: format(endOfYear(new Date()), 'yyyy-MM-dd'),
  createdAtStart: format(subDays(new Date(),365), 'yyyy-MM-dd'),
  createdAtEnd: format(new Date(), 'yyyy-MM-dd'),
  dateOrder: Order.Desc,
  timeDisplayValue: TimeDisplayValue.Year,
  createdTimeDisplayValue: CreatedTimeDisplayValue.Year,
};

export const createInPersonAppointmentsSearchURL = ({
  clinicIds,
  employeeIds,
  contactIds
}: {
  clinicIds: string[] | never[];
  employeeIds: string[] | never[];
  contactIds: string[] | never[];
}) => {
  const sharedDefaultSearchQuery = `appointmentStatuses=${
    sharedDefaultFilters.appointmentStatuses
  }&start=${sharedDefaultFilters.start}&end=${
    sharedDefaultFilters.end
  }&dateOrder=${
    sharedDefaultFilters.dateOrder
  }&timeDisplayValue=${
    sharedDefaultFilters.timeDisplayValue
  }&createdAtStart=${
    sharedDefaultFilters.createdAtStart
  }&createdAtEnd=${
    sharedDefaultFilters.createdAtEnd
  }&createdTimeDisplayValue=${
    sharedDefaultFilters.createdTimeDisplayValue}`;

  return `${sharedDefaultSearchQuery}&clinicIds=${clinicIds?.join(
    ','
  )}&employeeIds=${employeeIds?.join(
    ','
  )}&contactIds=${contactIds?.join(
    ','
  )}`;
};

export const validateInPersonAppointmentsSearchParams = (
  urlParams: URLSearchParams
) => {
  // Validates that the search keys are present
  const hasValidSearchKeys =
    urlParams.has('appointmentStatuses') &&
    urlParams.has('start') &&
    urlParams.has('end') &&
    urlParams.has('createdAtStart') &&
    urlParams.has('createdAtEnd') &&
    urlParams.has('clinicIds') &&
    urlParams.has('employeeIds') &&
    urlParams.has('contactIds') &&
    urlParams.has('timeDisplayValue') &&
    urlParams.has('createdTimeDisplayValue') &&
    urlParams.has('dateOrder');
  //Validates that the values in the search keys are the expected values
  if (hasValidSearchKeys) {
    // vallidates appointmentStatuses
    const appointmentStatuses =
      urlParams
        .get('appointmentStatuses')
        ?.split(',')
        .filter((s) => s !== '') ?? [];
    const hasValidAppointmentStatuses =
      _.intersection((Object as any).values(NewAppointmentStatus), appointmentStatuses)
        .length === appointmentStatuses.length;
  
    // validates start date
    const start = urlParams.get('start');
    const hasValidStartDate = !!start && isValid(new Date(start));

    // validates end date
    const end = urlParams.get('end');
    const hasValidEndDate = !!end && isValid(new Date(end));

    // validates created start date
    const createdAtStart = urlParams.get('createdAtStart');
    const hasValidCreatedStartDate = !!createdAtStart && isValid(new Date(createdAtStart));

    // validates created end date
    const createdAtEnd = urlParams.get('createdAtEnd');
    const hasValidCreatedEndDate = !!createdAtEnd && isValid(new Date(createdAtEnd));
    
    //Validates dateOrder
    const dateOrder = urlParams.get('dateOrder');
    const hasValidDateOrder = (Object as any).values(Order).includes(dateOrder);

    // validates timeDisplayValue
    const timeDisplayValue = urlParams.get('timeDisplayValue');
    const hasValidTimeDisplayValue = (Object as any)
      .values(TimeDisplayValue)
      .includes(timeDisplayValue);

    // validates createdTimeDisplayValue
    const createdTimeDisplayValue = urlParams.get('createdTimeDisplayValue')
    const hasValidCreatedTimeDisplayValue = (Object as any)
    .values(CreatedTimeDisplayValue)
    .includes(createdTimeDisplayValue);
  
    // validates clinicIds are numbers
    const clinicIds = urlParams.get('clinicIds')?.split(',') ?? [];
    const hasValidClinicIds = clinicIds.every(
      (element) => !isNaN(+element) && typeof +element === 'number'
    );

    // validates employeeIds are numbers
    const employeeIds = urlParams.get('employeeIds')?.split(',') ?? [];
    const hasValidEmployeeIds = employeeIds.every(
      (element) => !isNaN(+element) && typeof +element === 'number'
    );
    
    // validated contactIds are numbers
    const contactIds = urlParams.get('contactIds')?.split(',') ?? []
    const hasValidContactIds = contactIds.every(
      (element) => !isNaN(+element) && typeof +element === 'number'
    )

    return (
      hasValidAppointmentStatuses &&
      hasValidStartDate &&
      hasValidEndDate &&
      hasValidCreatedStartDate &&
      hasValidCreatedEndDate &&
      hasValidDateOrder &&
      hasValidTimeDisplayValue &&
      hasValidCreatedTimeDisplayValue &&
      hasValidClinicIds &&
      hasValidEmployeeIds &&
      hasValidContactIds
    );
  }
  return hasValidSearchKeys;
};
