import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client/core';
import {
  Header,
  Button,
  colorValues,
  EditSaveButton,
  HoursOfOperation,
  Animation,
} from '@betterpt/better-components';
import Placeholder from '../../../../../../assets/img-unknown-cliniclogo.svg';
import CopyTimesToAll from '../../../../../Shared/CopyTimesToAll';
import { BorderedStyle, HoursOfOperationContainer, LogoImage } from './styles';
import {
  Clinic,
  Maybe,
  useProviderHoursQueryQuery,
  useUpdateProviderHoursMutation,
  useMyRoleQuery,
} from '../../../../../../generated/graphql';
import { useParams } from 'react-router-dom';
import useHoursOfOperation from './lib/useHoursOfOperation';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import { convertAppHoursToServerHours } from './lib/convertHours';
import Can from '../../../../../../components/Can';
import { Roles } from '../../../../../../helpers';
import StaffHoursInfoBox from './StaffHoursInfoBox';
import { validateTimes } from './lib/validations';
import useHandleError from '../../../../../../hooks/useHandleError';

export const DAYS_FRAGMENT = gql`
  fragment Days on EmployeeHourSlotsAtClinic {
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
  }
`;

export const PROVIDER_HOURS_QUERY = gql`
  query ProviderHoursQuery($employeeId: ID!, $facilityId: ID!) {
    employeeHoursAtClinic(
      input: { employeeId: $employeeId, clinicId: $facilityId }
    ) {
      ...Days
    }
  }
`;

export const UPDATE_PROVIDER_HOURS_MUTATION = gql`
  mutation UpdateProviderHours(
    $employeeId: ID!
    $facilityId: ID!
    $hours: EmployeeHourSlotsAtClinicInput!
  ) {
    updateEmployeeHoursAtClinic(
      input: { employeeId: $employeeId, clinicId: $facilityId, hours: $hours }
    ) {
      ...Days
    }
  }
`;

export const QUERY_MY_ROLE = gql`
  query MyRole {
    me {
      id
      role
    }
  }
`;

interface Props {
  facility: Maybe<
    Pick<Clinic, 'id' | 'clinicName' | 'logoUrl' | 'shouldUseProviderSchedule'>
  >;
  employeeName: string;
  employeeRole: string;
}

const ProviderHourBlock = ({ facility, employeeName, employeeRole }: Props) => {
  const [isFormActive, setIsFormActive] = useState(false);
  const { employeeId } = useParams<{ employeeId: string }>();
  const handleError = useHandleError();

  const { data, loading, error } = useProviderHoursQueryQuery({
    variables: {
      employeeId,
      facilityId: facility?.id!,
    },
    fetchPolicy: 'no-cache', // for some reason the cache isn't working properly for ProviderHours, so we're going to use no-cache as our fetch policy
  });

  const { data: myRoleData } = useMyRoleQuery();

  const {
    providerHours,
    dataHasBeenUpdated,
    handleChange,
    setFieldValue,
    copyTimeToAll,
    clearAllHours,
  } = useHoursOfOperation(data);
  const snackbar = useSnackbar?.();
  const [submitProviderHours, { error: submittalError, loading: saving }] =
    useUpdateProviderHoursMutation();

  const handleSubmitNewHours = async () => {
    if (!dataHasBeenUpdated) {
      setIsFormActive(false);
      return;
    }
    try {
      const hours = convertAppHoursToServerHours(providerHours);
      validateTimes(hours);

      await submitProviderHours({
        variables: {
          employeeId,
          facilityId: facility?.id!,
          hours,
        },
      });
      snackbar?.openSnackbar({
        isError: false,
        message: 'Successfully updated hours',
      });
      setIsFormActive(false);
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    if (error || submittalError) {
      snackbar?.openSnackbar({
        isError: true,
        message: error?.message ?? submittalError?.message ?? '',
      });
      setIsFormActive(false);
    }
  }, [error, snackbar, submittalError]);

  if (!facility) return null;

  const { logoUrl, clinicName } = facility;
  const image = logoUrl ?? Placeholder;

  const providerHasNoHoursAtThisFacility = Object.values(
    providerHours.formattedOpenCloseDaysHours
  ).every((value) => value === '');

  if (loading) {
    return (
      <BorderedStyle>
        <Animation type='providerAppLoader' />
      </BorderedStyle>
    );
  }

  return (
    <BorderedStyle>
      <Header underline style={{ padding: '20px 25px 20px 20px' }}>
        <h4>
          <LogoImage src={image} alt='' />
          {clinicName}
          <StaffHoursInfoBox clinicName={clinicName} facilityId={facility.id} />
        </h4>
        <Can
          role={Roles[myRoleData?.me?.role || 'initial']}
          perform='employee:edit'
          data={{
            targetRole: employeeRole,
          }}
          yes={() => (
            <EditSaveButton
              loading={saving}
              style={{ backgroundColor: colorValues.ibizateal }}
              editModeActive={isFormActive}
              onClickEdit={() => setIsFormActive(true)}
              onClickSave={handleSubmitNewHours}
            />
          )}
        />
      </Header>
      {providerHasNoHoursAtThisFacility && (
        <p className='Body' style={{ margin: '20px 40px' }}>
          {employeeName} has no availability at {clinicName}, so patients will
          not be able to book with them. Add availability to allow patients to
          book.
        </p>
      )}
      <HoursOfOperationContainer>
        {isFormActive && (
          <CopyTimesToAll
            onClick={copyTimeToAll}
            hoursTypeText='in-person appointment'
          />
        )}
        <HoursOfOperation
          hours={providerHours?.formattedOpenCloseDaysHours ?? {}}
          selectedDays={providerHours?.selectedDays ?? {}}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          disabled={!isFormActive}
        />
        <Button
          color='red'
          size='large'
          style={{ margin: '20px 0px' }}
          disabled={!isFormActive}
          onClick={clearAllHours}
        >
          DELETE ALL
        </Button>
      </HoursOfOperationContainer>
    </BorderedStyle>
  );
};

export default ProviderHourBlock;
