import {
  useAssignAppointmentToEmployeeMutation,
  useMarkAppointmentAsContactedMutation,
} from '../../../../../../generated/graphql';
import useHandleError from '../../../../../../hooks/useHandleError';

export interface ContactPatientInput {
  appointmentId: string;
}

export interface AssignEmployeeInput {
  appointmentId?: string;
  employeeId?: string;
}

const useInPersonAppointmentOperations = () => {
  const [markAppointmentAsContacted] = useMarkAppointmentAsContactedMutation();
  const [assignAppointmentToEmployee] =
    useAssignAppointmentToEmployeeMutation();
  const handleError = useHandleError();

  const contactPatient = async ({ appointmentId }: ContactPatientInput) => {
    if (!appointmentId) {
      throw new Error(
        'This appointment could not be accessed, please refresh and try again.'
      );
    }

    try {
      await markAppointmentAsContacted({
        variables: {
          id: appointmentId,
        },
      });
    } catch (e) {
      handleError(e);
    }
  };

  const assignEmployee = async ({
    appointmentId,
    employeeId,
  }: AssignEmployeeInput) => {
    if (!appointmentId || !employeeId) {
      throw new Error(
        'This appointment count not be accessed. Please refresh and try again.'
      );
    }

    try {
      await assignAppointmentToEmployee({
        variables: {
          input: { employeeId, appointmentId },
        },
      });
    } catch (e) {
      handleError(e);
    }
  };

  return {
    commands: {
      contactPatient,
      assignEmployee,
    },
  };
};

export default useInPersonAppointmentOperations;
