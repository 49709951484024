import * as React from 'react';
import { RemoteConfigContext } from '../context/remoteConfig';

const useRemoteConfig = () => {
  const config = React.useContext(RemoteConfigContext);

  return { info: config?.info, loading: config?.loading };
};

export default useRemoteConfig;
