import * as React from 'react';

// components
import { Table } from '@betterpt/better-components';
import { GridCard } from '../../../../../Shared/GridCard';

// hooks
import { useHistory } from 'react-router-dom';
import { useFacilityQuery } from '../../../../../../generated/graphql';
import { useTextFormatters } from './hooks';
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';
import { CardBody, ListTitle } from '../../FacilityDetails.style';

const EmployeesList = ({ facilityId }: { facilityId: string }) => {
  const history = useHistory();
  const facility = useFacilityQuery({
    variables: { id: facilityId },
    fetchPolicy: 'cache-and-network',
  });
  const {
    employeeSingularAlias,
    facilitiesSingularAlias,
    employeePluralAlias,
  } = useRemoteConfigAliases();
  const { formatClinicTableCopy, formatRole } = useTextFormatters();

  const tableColumns = [
    { field: 'name', title: `${employeeSingularAlias.toUpperCase()} NAME` },
    {
      field: 'id',
      title: 'ID',
    },
    { field: 'email', title: `${employeeSingularAlias.toUpperCase()} EMAIL` },
    { field: 'facilities', title: facilitiesSingularAlias.toUpperCase() },
    { field: 'role', title: 'PERMISSIONS' },
    { field: 'hasLoggedIn', title: 'HAS LOGGED IN' },
  ];

  const rowData =
    facility.data?.clinic?.employees?.map((employee) => ({
      id: employee?.id ?? '-',
      name: `${employee?.lastName}, ${employee?.firstName}`,
      email: employee?.email ?? '-',
      facilities: formatClinicTableCopy(employee?.clinics),
      role: formatRole(employee?.role),
      hasLoggedIn: employee?.hasLoggedIn ? 'Yes' : 'No',
    })) ?? [];

  const tableOptions = {
    minBodyHeight: 440,
    searchNotFoundText: `${employeeSingularAlias} not found`,
  };

  if (
    facility.called &&
    !facility.loading &&
    !facility.data?.clinic?.employees?.length
  ) {
    return (
      <>
        <ListTitle>{employeePluralAlias}</ListTitle>
        <GridCard style={{ width: '100%', marginRight: 0 }}>
          <CardBody>
            <p>
              No {employeePluralAlias.toLowerCase()} have been added to this{' '}
              {facilitiesSingularAlias.toLowerCase()}.
            </p>
          </CardBody>
        </GridCard>
      </>
    );
  }

  return (
    <>
      <ListTitle>{employeePluralAlias}</ListTitle>
      <GridCard
        data-testid='employees-list-container'
        style={{ width: '100%', marginRight: 0 }}
      >
        <Table
          columns={tableColumns}
          data={rowData}
          isLoading={facility.loading}
          options={tableOptions}
          onRowClick={(_e, rowData) =>
            history.push(`/employees/${rowData?.id}`)
          }
        />
      </GridCard>
    </>
  );
};

export default EmployeesList;
