import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

//MAIN DIALOG STYLES
export const DialogBody = styled.div`
  position: relative;
  padding: 47px 45px 36px 48px;
  margin: 5px;
`;

export const H1 = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 24px;
`;

export const SubHeader = styled.h2`
  font-size: 12px;
  font-weight: bold;
  color: ${colorValues.charcoalgray};
  position: absolute;
  left: 0;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  margin: 50px 0;
`;

export const InputStyleWrapper = styled.div`
  width: 100%;
  .MuiSelect-root {
    font-weight: bold;
    font-size: 16px !important;
    color: ${colorValues.betterptblack};
    padding: 6px 0 4px;
  }
`;

export const inputStyle = {
  fontWeight: 'bold' as 'bold',
  fontSize: 16,
  color: colorValues.betterptblack,
  padding: '6px 0 4px',
};

export const buttonStyle = (color?: string) => ({
  backgroundColor: color || colorValues.cityblock,
  fontWeight: 'bold' as 'bold',
  marginTop: '20px',
});
