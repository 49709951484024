import React from 'react';

//types
import { AssignAppointmentTypesToProviderPayload } from '../hooks/useAppointmentTypeOperations';

//hooks
import useSnackbar from '../../../../hooks/useSnackbar';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import { useMeQuery, useEmployeeAppointmentTypesQuery } from '../../../../generated/graphql';

//components
import { Button, Checkbox, TextField } from '@betterpt/better-components';

//styles
import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';
import { DialogBody } from '../AppointmentTypes.style';
import useHandleError from '../../../../hooks/useHandleError';
const formStyle = { width: '100%' };
const buttonStyle = (backgroundColor: string) => ({
  backgroundColor,
  marginLeft: 20,
  width: 228,
});
const formFieldStyle = {
  marginBottom: '1vh',
};
const formFieldInputStyle = {
  color: colorValues.betterptblack,
};
const DialogHeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const DialogHeaderText = styled.h1`
  margin: 0;
  font-size: 24px;
`;
const LoadingText = styled.div`
  margin-top: 16px;
  margin-left: 43px;
  font-size: 14px;
`;
const AppointmentTypesList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  max-height: 530px;
  overflow-y: auto;
`;
const AppointmentTypeListItem = styled.div`
display: flex;
margin-bottom: 20px;
span {
  0;
}`;

interface Props {
  providerId: string;
  onClose: () => void;
  assignProviderToAppointmentTypes: (payload: AssignAppointmentTypesToProviderPayload) => Promise<void>;
}

const AssignToExistingApptTypesForm = ({ providerId, onClose, assignProviderToAppointmentTypes }: Props) => {
  const snackbar = useSnackbar();
  const handleError = useHandleError();
  const { employeeSingularAlias, primaryColor } = useRemoteConfigAliases();

  const [loading, updateLoading] = React.useState(false);
  const [appointmentTypeIds, updateAppointmentTypeIds] = React.useState<string[]>([]);

  const meQuery = useMeQuery();
  const providerApptTypesQuery = useEmployeeAppointmentTypesQuery({
    variables: {
      id: providerId,
    },
    skip: !providerId || isNaN(Number(providerId)),
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const appointmentTypes: string[] = [];
      data.employee?.appointmentTypes?.forEach((appointmentType) => {
        if (appointmentType?.id) {
          appointmentTypes.push(appointmentType?.id);
        }
      });

      if (appointmentTypes.length) {
        updateAppointmentTypeIds(appointmentTypes);
      }
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateLoading(true);
    try {
      await assignProviderToAppointmentTypes({
        providerId,
        appointmentTypeIds,
      });
      updateLoading(false);
      snackbar?.setSuccessMessage('Appointment type configuration saved');
      onClose();
    } catch (e) {
      updateLoading(false);
      handleError(e);
    }
  };

  const handleCheckbox = (id?: string | null) => {
    if (!id) return;
    if (appointmentTypeIds.includes(id)) {
      updateAppointmentTypeIds((current) => current.filter((currentId) => currentId !== id));
    } else updateAppointmentTypeIds((current) => [...current, id]);
  };

  const renderAppointmentTypes = () => {
    if (meQuery.loading || providerApptTypesQuery.loading) {
      return <LoadingText>Loading...</LoadingText>;
    } else {
      const existingApptTypes = meQuery.data?.me?.company?.appointmentTypes ?? [];
      if (!existingApptTypes?.length) {
        return 'No existing appointment types. Add one now.';
      } else
        return existingApptTypes
          ?.filter((apptType) => !!apptType?.isActive && apptType.medium !== 'telehealth')
          .map((apptType, i) => (
            <AppointmentTypeListItem key={`apptType-${apptType?.id ?? i}`}>
              <Checkbox
                checked={appointmentTypeIds.includes(apptType?.id ?? 'impossible-id')}
                onClick={() => handleCheckbox(apptType?.id)}
              />
              <TextField
                disabled
                fullWidth
                label="Appointment Type"
                id={`apptType-${apptType?.id ?? i}`}
                value={apptType?.displayName ?? '-'}
                inputProps={{ style: formFieldInputStyle }}
                InputLabelProps={{ shrink: true }}
                style={formFieldStyle}
              />
            </AppointmentTypeListItem>
          ));
    }
  };

  return (
    <DialogBody wide>
      <form onSubmit={handleSubmit} style={formStyle}>
        <DialogHeaderSection>
          <DialogHeaderText>Assign Appointment Types to {employeeSingularAlias}</DialogHeaderText>
          <Button fullWidth type="submit" size="large" style={buttonStyle(primaryColor)} loading={loading}>
            SAVE AND FINISH UP
          </Button>
        </DialogHeaderSection>
        <AppointmentTypesList>{renderAppointmentTypes()}</AppointmentTypesList>
      </form>
    </DialogBody>
  );
};

export default AssignToExistingApptTypesForm;
