import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { colorValues } from '@betterpt/better-components';

export const Loader = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableStyleWrapper = styled.div`
  .MuiTableCell-root p {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
`;

export const DetailTableHeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const CardBody = styled.div`
  padding: 13px 40px 50px;
  overflow: auto;
`;

export const CardTitle = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const BreadCrumb = styled.div`
  display: flex;
  margin-bottom: 37px;
`;

export const TypesContainer = styled.div<{ cascade?: boolean }>`
  padding: 18px;
  background-color: ${({ cascade }) => (cascade ? 'white' : colorValues.earlysmoke)};
`;

export const NonCascadeContainer = styled.div<{display?: boolean}>`
  display: ${({ display }) => (display ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const NonCascadeHeader = styled.div`
  color: ${colorValues.charcoalgray};
  font-weight: bold;
  font-size: 16px;
  line-height: 1.25;
  white-space: nowrap;
  margin: 0px 0px 15px 0px;
`;

export const NonCascadeIcon = styled.div`
  color: ${colorValues.emptiness};
  background-color: ${colorValues.messyketchup};
  font-weight: bold;
  font-size: 10px;
  padding: 2px 20px;
  border-radius: 3px;
  white-space: nowrap;
`;

export const BreadCrumbLink = styled.h3`
  color: ${colorValues.frenchblue};
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  padding-right: 4px;
  border-bottom: 1px solid ${colorValues.frenchblue};
  &:hover {
    border-bottom: 1px dotted ${colorValues.frenchblue};
  }
`;

export const DetailSubheader = styled.h1`
  font-size: 20px;
  margin: 10px 0 0;
`;

export const DetailCardBody = styled.div`
  padding: 23px 50px 60px;
`;

export const DialogBody = styled.div<{ wide?: boolean }>`
  width: ${({ wide }) => (wide ? '75%' : '40%')};
  max-width: ${({ wide }) => (wide ? '800px' : '485px')};
  margin: 2vh auto 0;
  padding-bottom: 20vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const DialogHeader = styled.h2`
  font-size: 16px;
  margin: 6vh 0 3.5vh;
`;

export const DialogSubheader = styled.h2`
  font-size: 16px;
  margin: 6vh 0 3.5vh;
`;

export const FieldSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
`;

export const ListContainer = styled.div<{ overflow: 'scroll' | 'auto' }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 556px;
  overflow-y: ${({ overflow }) => overflow};
  overflow-x: hidden;
  padding-right: ${({ overflow }) => (overflow ? '0px' : '10px')};
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${({ overflow }) =>
      overflow === 'scroll' ? colorValues.cityblock : 'transparent'};
    -webkit-box-shadow: ${({ overflow }) =>
      overflow === 'scroll' ? '0 0 1px rgba(255, 255, 255, 0.5)' : 'none'};
  }
`;

export const ActiveHeader = styled.h4<{
  hasArchived: boolean;
  companyView: boolean;
}>`
  display: ${({ hasArchived, companyView }) =>
    hasArchived && companyView ? 'block' : 'none'};
  font-size: 16px;
  margin: 0;
  margin-bottom: 20px;
  height: 31px;
`;

export const ArchivedHeader = styled.h4`
  color: ${colorValues.charcoalgray};
  font-size: 16px;
  width: 100%;
  margin: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 31px;
`;

export const ActiveContainer = styled.div<{
  hasArchived: boolean;
  hasActive: boolean;
  companyView: boolean;
}>`
  display: ${({ hasActive }) => (hasActive ? 'flex' : 'none')};
  flex-direction: column;
  border: ${({ hasArchived, companyView }) =>
    hasArchived && companyView
      ? `2px solid ${colorValues.concretejungle}`
      : 'none'};
  border-radius: 5px;
  width: 100%;
`;

export const ArchivedContainer = styled.div<{
  hasArchived: boolean;
  companyView: boolean;
}>`
  display: ${({ hasArchived, companyView }) =>
    hasArchived && companyView ? 'flex' : 'none'};
  flex-direction: column;
  padding: 19px 6px 0 12px;
  width: 97%;
  border: ${({ hasArchived, companyView }) =>
    hasArchived && companyView ? `2px solid transparent` : 'none'};
`;

const expandDown = keyframes({
  '0%': { transform: 'scaleY(0)' },
  '100%': { transform: 'scaleY(1)' },
});
export const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  .animate {
    display: block;
    animation-name: ${expandDown};
    animation-duration: 150ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
  }
  .closed {
    display: none;
    transform: scaleY(0);
  }
`;

export const SelectDropdown = styled.div`
  position: absolute;
  z-index: 100;
  cursor: pointer;
  top: 39px;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 25px 15px;
  overflow-y: auto;
  max-height: 220px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  will-change: transform;
  transform-origin: top center;
  background-color: ${colorValues.emptiness};
  box-shadow: 0 2px 20px 0 rgba(51, 51, 51, 0.24),
    0 2px 2px 0 rgba(51, 51, 51, 0.12);
  label {
    padding: 5px 0;
  }
  span {
    padding: 0;
    margin-right: 5px;
    font-size: 14px;
  }
`;

export const WarningText = styled.p`
  color: ${colorValues.messyketchup};
  position: absolute;
  font-size: 10px;
  top: 90%;
`;

export const WarningTextFieldContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const NoProvidersOrFacilities = styled.p`
  margin: 10px 44px;
  font-size: 16px;
  font-weight: bold;
  font-style: italic;
  margin-bottom: 50px;
  margin-top: 50px;
  color: ${colorValues.cityblock};
`;

export const cardHeaderStyle = {
  padding: '20px 25px 20px 40px',
  height: 46,
};

export const cardStyle = (employeeView?: boolean) => ({
  marginRight: 20,
  marginBottom: 20,
  flex: 1,
  maxHeight: employeeView ? 1095 : 'none',
});

export const clickableInputStyle = {
  cursor: 'pointer',
  color: colorValues.betterptblack,
};

export const detailCardHeaderStyle = {
  padding: '30px 25px 25px 50px',
};

export const dialogTextInputStyle = {
  marginBottom: '3.5vh',
};

export const dropdownIconStyle = (isOpen: boolean) => ({
  transform: `rotate(${isOpen ? 180 : 0}deg)`,
  height: 24,
  width: 24,
});

export const editButtonStyle = {
  width: 96,
  backgroundColor: colorValues.dust,
};

export const killMargin = { margin: 0 };

export const pinnedDialogStyle = {
  width: '80%',
  margin: '0 auto',
  display: 'block',
  marginTop: 100,
};

export const searchBoxStyle = { marginTop: '-15px', marginBottom: 5 };

export const smallButtonStyle = {
  minWidth: 0,
  width: 32,
  height: 28,
  padding: 0,
  marginBottom: 6,
};

export const detailTableCardStyle = { margin: '28px 0px 32px' };

export const dialogButtonStyle = {
  position: 'absolute' as 'absolute',
  top: 'calc(6vh + 15px)',
  right: 'calc(6vh + 16px)',
  width: 211,
};
