import React from 'react';

//types
import { CardProps } from '@betterpt/better-components';
import { BetterAccessMigrationStatus } from '../../../generated/graphql';

//helpers
import Can from '../../Can';
import { Roles } from '../../../helpers/rbac-rules';

//hooks
import { useCompanyAppointmentTypesQuery, useEmployeeAppointmentTypesQuery, Employee } from '../../../generated/graphql';
import { useHistory, useParams } from 'react-router-dom';
import useFeatureAccessCheck from '../../../hooks/useFeatureAccessCheck';
import useRemoteConfigAliases from '../../../hooks/useRemoteConfigAliases';
import useAppointmentTypeOperations from './hooks/useAppointmentTypeOperations';

//components
import { Animation, Button, Card, Header, InfoBox, TextField } from '@betterpt/better-components';
import ApptDialogWrapper from './components/ApptDialogWrapper';
import AddNewApptTypeForm from './components/AddNewApptTypeForm';
import DefaultQuestionsForPT from './components/DefaultQuestionsForPT';
import AssignToExistingApptTypesForm from './components/AssignToExistingApptTypesForm';
import AddQualifyingQuestionsNew from './components/AddQualifyingQuestionsNew';
import AddQualifyingQuestions from './components/AddQualifyingQuestions';
import DisplayAppointmentType from './components/DisplayAppointmentType';

//assets
import { IcArrowRightWithStem, IcVisibilityOn, IcVisibilityOff } from '@betterpt/better-icons';

//styles
import {
  ActiveHeader,
  ArchivedHeader,
  ActiveContainer,
  ArchivedContainer,
  CardBody,
  CardTitle,
  FieldSection,
  ListContainer,
  cardHeaderStyle,
  cardStyle,
  editButtonStyle,
  TypesContainer,
  NonCascadeContainer,
  NonCascadeHeader,
  NonCascadeIcon,
  smallButtonStyle,
} from './AppointmentTypes.style';
import { colorValues } from '@betterpt/better-components';
import Flags from '../../../constants/flags';
import FeatureFlagCheck from '../FeatureFlagCheck';

const infoboxCascadeIconStyle = { top: '-10px', right: '0px' };
const infoboxCascadeStyle = { top: 45, right: '-50px', left: '-200px' };
const infoboxIconStyle = { top: '-1px', right: '-70px' };
const infoboxTextStyle = { fontWeight: 'normal' as 'normal' };
const infoboxStyle = { top: 45 };
const appointmentTypesSubheaderStyle = {
  fontStyle: 'italic',
  margin: '10px 0 19px',
  fontSize: 16,
};
const visibilityButtonStyle = {
  width: 'min-content',
  minWidth: 'min-content',
  height: 'min-content',
  padding: 3,
};
const archivedApptTypeButtonStyle = { backgroundColor: colorValues.cityblock };
const buttonStyle = (backgroundColor: string) => ({
  ...smallButtonStyle,
  backgroundColor,
});
const addApptTypeButtonStyle = (backgroundColor: string) => ({
  marginTop: 20,
  backgroundColor,
});

interface Props {
  profileId?: string;
  isOnProfileScreen?: boolean;
  isOnEmployeeScreen?: boolean;
}

const AppointmentTypes = ({ profileId, isOnProfileScreen, isOnEmployeeScreen, ...rest }: Props & Partial<CardProps>) => {
  const isOnCompanyScreen = !isOnProfileScreen && !isOnEmployeeScreen;
  const history = useHistory();
  const { isPT, loading: featuresLoading } = useFeatureAccessCheck();
  const { employeeId } = useParams<{ employeeId?: string }>();
  const bestEmployeeId = isOnProfileScreen ? profileId : employeeId;
  const { employeeSingularAlias, facilitiesPluralAlias, primaryColor } = useRemoteConfigAliases();
  const {
    commands: { addAppointmentType, assignProviderToAppointmentTypes },
  } = useAppointmentTypeOperations();

  const [showArchived, updateShowArchived] = React.useState(false);
  const [addAppointmentTypeDialogOpen, updateAddAppointmentTypeDialogOpen] = React.useState(false);
  const [assignProviderToAppointmentTypesDialogOpen, updateAssignAppointmentTypesDialogOpen] = React.useState(false);

  //boolean hooks to decide which UI will render in the Dialog for Adding new Appointments
  const [showNewApptForm, setShowNewApptForm] = React.useState<boolean>(false);
  const [showDefaultQuestion, setShowDefaultQuestion] = React.useState<boolean>(false);
  const [showQualifyingQuestion, setShowQualifyingQuestion] = React.useState<boolean>(false);

  // used to store the ID from the newly created appointment and pass on to Qualifying Questions form
  const [appointmentTypeId, setAppointmentTypeId] = React.useState<string>('');
  const companyQuery = useCompanyAppointmentTypesQuery({
    fetchPolicy: 'cache-and-network',
  });
  const providerQuery = useEmployeeAppointmentTypesQuery({
    variables: {
      id: bestEmployeeId!,
    },
    skip: !bestEmployeeId || isNaN(Number(bestEmployeeId)),
    fetchPolicy: 'cache-and-network',
  });

  const loading = companyQuery.loading || providerQuery.loading || featuresLoading;
  const appointmentTypes =
    (isOnCompanyScreen ? companyQuery.data?.me?.company?.appointmentTypes : providerQuery.data?.employee?.appointmentTypes) ?? [];
  const activeApptTypes = appointmentTypes.filter((apptType) => apptType?.isActive && apptType?.medium === 'inClinic');
  const archivedApptTypes = appointmentTypes.filter((apptType) => !apptType?.isActive && apptType?.medium === 'inClinic');
  const hasActive = !!activeApptTypes.length;
  const hasArchived = !!archivedApptTypes.length;
  const showArchivedByDefault = hasArchived && !hasActive && isOnCompanyScreen;
  const noAppointmentTypesYet = !loading && ((isOnCompanyScreen && !appointmentTypes.length) || (!isOnCompanyScreen && !hasActive));
  const company = companyQuery.data?.me?.company;
  const shouldRemoveCallLengthLimit = !!company?.shouldRemoveCallLengthLimit;
  const migrationStatus = company?.betterAccessMigrationStatus;
  const isMigratedToNewBookingFlow = migrationStatus === BetterAccessMigrationStatus.CompletedBookingFlowMigration;

  React.useEffect(() => {
    if (showArchivedByDefault) {
      updateShowArchived(true);
    }
  }, [showArchivedByDefault]);

  const pageSpecificSubheader = (canEdit: boolean) => {
    if (showArchivedByDefault) {
      return `Your company's appointment types are all archived.`;
    }
    const shouldEdit = noAppointmentTypesYet && canEdit;
    if (isOnEmployeeScreen) {
      return `${shouldEdit ? 'Set' : 'These are the'} appointment types that this ${employeeSingularAlias.toLowerCase()} supports.`;
    } else if (isOnProfileScreen && shouldEdit) {
      return 'Set appointment types that you support.';
    } else if (isOnProfileScreen && !shouldEdit) {
      return 'These are the appointment types that you support.';
    }
    return `${shouldEdit ? 'Add' : 'These are the'} appointment types that your company supports.`;
  };

  const generalSubheader =
    migrationStatus === BetterAccessMigrationStatus.InProgress
      ? `Your company has migrated some of its ${facilitiesPluralAlias.toLowerCase()} to the new and improved BetterAccess Booking Experience, which allows for custom qualifying questions based on configurable appointment types.`
      : `The BetterAccess Booking Experience allows for custom qualifying questions based on configurable appointment types.`;

  const infoBoxText = (() => {
    let whoSupports = 'it supports';
    if (isOnProfileScreen) {
      whoSupports = 'you support';
    } else if (isOnEmployeeScreen) {
      whoSupports = `this ${employeeSingularAlias.toLowerCase()} supports`;
    }
    return `Your company can add appointment types that ${whoSupports}. You can then customize the booking experience for these appointment types.`;
  })();

  const apptTypeDetailLink = (appointmentTypeId: string) =>
    isOnCompanyScreen
      ? `/company/appointment-type/${appointmentTypeId}`
      : `/employees/${bestEmployeeId}/config/appointment-type/${appointmentTypeId}`;

  const openDialog = () => {
    setShowNewApptForm(true); // Opens modal with new appointment type creation form
    return isOnCompanyScreen ? updateAddAppointmentTypeDialogOpen(true) : updateAssignAppointmentTypesDialogOpen(true);
  };

  const closeDialog = () => (isOnCompanyScreen ? updateAddAppointmentTypeDialogOpen(false) : updateAssignAppointmentTypesDialogOpen(false));

  let cascadeAppTypes = activeApptTypes?.filter((apptType) => apptType?.customFormTemplate?.hasOwnProperty('schemaVersion')) || [];

  let nonCascadeAppTypes = activeApptTypes?.filter((apptType) => !apptType?.customFormTemplate?.hasOwnProperty('schemaVersion')) || [];

  return (
    <>
      <ApptDialogWrapper isOpen={addAppointmentTypeDialogOpen} onClose={closeDialog}>
        {showNewApptForm ? (
          <AddNewApptTypeForm
            onClose={closeDialog}
            companyId={company?.id ?? ''}
            provider={providerQuery.data?.employee as Employee}
            addAppointmentType={addAppointmentType}
            shouldRemoveCallLengthLimit={shouldRemoveCallLengthLimit}
            setShowNewApptForm={setShowNewApptForm}
            setShowDefaultQuestion={setShowDefaultQuestion}
            setShowQualifyingQuestion={setShowQualifyingQuestion}
            setAppointmentTypeId={setAppointmentTypeId}
            isPT={isPT}
          />
        ) : showDefaultQuestion ? (
          <DefaultQuestionsForPT setShowDefaultQuestion={setShowDefaultQuestion} setShowQualifyingQuestion={setShowQualifyingQuestion} />
        ) : showQualifyingQuestion ? (
          <FeatureFlagCheck
            flag={Flags.CustomCascadingLogic}
            yes={() => <AddQualifyingQuestionsNew handleClose={closeDialog} appointmentTypeId={appointmentTypeId} />}
            no={() => <AddQualifyingQuestions handleClose={closeDialog} appointmentTypeId={appointmentTypeId} />}
          />
        ) : null}
      </ApptDialogWrapper>
      <ApptDialogWrapper isOpen={assignProviderToAppointmentTypesDialogOpen} onClose={closeDialog}>
        <AssignToExistingApptTypesForm
          onClose={closeDialog}
          providerId={providerQuery.data?.employee?.id ?? ''}
          assignProviderToAppointmentTypes={assignProviderToAppointmentTypes}
        />
      </ApptDialogWrapper>
      <Card
        fullWidth={!isPT || (isPT && isMigratedToNewBookingFlow)}
        width={576}
        fullWidthAtBreakpoint={1400}
        style={cardStyle(isOnEmployeeScreen)}
        data-cy="better-access-booking-experience-card"
        {...rest}
      >
        <Header underline style={cardHeaderStyle}>
          <CardTitle>
            {isPT ? 'BetterAccess Booking Experience' : 'Booking Experience'}
            <InfoBox
              openWith="hover"
              width={230}
              height={200}
              iconHeight={14}
              iconWidth={32}
              position="bottom"
              padding={0}
              fixedPlacement
              mainStyle={infoboxIconStyle}
              boxStyle={infoboxStyle}
            >
              <h4>APPOINTMENT TYPES</h4>
              <p style={infoboxTextStyle}>{infoBoxText}</p>
            </InfoBox>
          </CardTitle>
          <Can
            role={Roles[companyQuery.data?.me?.role ?? 'initial']}
            perform={isOnCompanyScreen ? 'appointmentType:create' : 'employeeAppointmentTypes:edit'}
            yes={() => (
              <Button
                size="small"
                style={editButtonStyle}
                onClick={openDialog}
                data-cy={isOnCompanyScreen ? 'add-appointment-types-button' : 'edit-provider-appointment-types-button'}
              >
                {isOnCompanyScreen ? 'ADD' : 'EDIT'}
              </Button>
            )}
          />
        </Header>

        {loading ? (
          <Animation type="providerAppLoader" />
        ) : (
          <CardBody>
            <Can
              role={Roles[companyQuery.data?.me?.role ?? 'initial']}
              perform={isOnCompanyScreen ? 'appointmentType:create' : 'employeeAppointmentTypes:edit'}
              yes={() => (
                <p className="Body" style={appointmentTypesSubheaderStyle}>
                  {generalSubheader} {pageSpecificSubheader(true)}
                </p>
              )}
              no={() => (
                <p className="Body" style={appointmentTypesSubheaderStyle}>
                  {generalSubheader} {pageSpecificSubheader(false)}
                </p>
              )}
            />
            <ListContainer overflow={appointmentTypes.length > 9 ? 'scroll' : 'auto'}>
              <ActiveContainer hasArchived={hasArchived} hasActive={hasActive} companyView={isOnCompanyScreen}>
                <ActiveHeader hasArchived={hasArchived} companyView={isOnCompanyScreen}>
                  Appointment Types Currently In Use
                </ActiveHeader>
                <FeatureFlagCheck
                  flag={Flags.UpdateSchemaToCascadingLogic}
                  yes={() => (
                    <>
                      {nonCascadeAppTypes.length > 0 && (
                        <TypesContainer cascade={false}>
                          <NonCascadeContainer display={!!nonCascadeAppTypes.length}>
                            <NonCascadeHeader>Review the Following Appointment Types</NonCascadeHeader>
                            <InfoBox
                              openWith="hover"
                              width={230}
                              height={200}
                              iconHeight={14}
                              iconWidth={32}
                              position="bottom"
                              padding={0}
                              fixedPlacement
                              mainStyle={infoboxCascadeIconStyle}
                              boxStyle={infoboxCascadeStyle}
                              icon={<NonCascadeIcon>NON-CASCADING</NonCascadeIcon>}
                            >
                              <h4>NON-CASCADING</h4>
                              <p style={infoboxTextStyle}>
                                These appointment types still do not use cascading logic. Click into the appointment type details and scroll
                                to the qualifying questions section to update to the new schema and activate this feature.
                              </p>
                            </InfoBox>
                          </NonCascadeContainer>
                          {nonCascadeAppTypes.map((apptType, i) => (
                            <DisplayAppointmentType
                              apptType={apptType}
                              index={i}
                              apptTypeDetailLink={apptTypeDetailLink}
                              buttonStyle={buttonStyle}
                            />
                          ))}
                        </TypesContainer>
                      )}
                      <TypesContainer cascade={true}>
                        {cascadeAppTypes.map((apptType, i) => (
                          <DisplayAppointmentType
                            apptType={apptType}
                            key={i}
                            index={i}
                            apptTypeDetailLink={apptTypeDetailLink}
                            buttonStyle={buttonStyle}
                          />
                        ))}
                      </TypesContainer>
                    </>
                  )}
                  no={() => (
                    <>
                      {activeApptTypes?.map((apptType, i) => (
                        <DisplayAppointmentType
                          apptType={apptType}
                          index={i}
                          apptTypeDetailLink={apptTypeDetailLink}
                          buttonStyle={buttonStyle}
                        />
                      ))}
                    </>
                  )}
                />
              </ActiveContainer>
              <ArchivedContainer hasArchived={hasArchived} companyView={isOnCompanyScreen}>
                <ArchivedHeader>
                  {archivedApptTypes.length} Archived Appointment Type
                  {archivedApptTypes.length === 1 ? '' : 's'}
                  <Button color="transparent" style={visibilityButtonStyle} onClick={() => updateShowArchived(!showArchived)}>
                    {showArchived ? <IcVisibilityOn color={colorValues.guajirogreen} /> : <IcVisibilityOff />}
                  </Button>
                </ArchivedHeader>
                {showArchived
                  ? archivedApptTypes?.map((apptType, i) => (
                      <FieldSection key={`archived-appointment-type-${apptType?.id ?? i}`}>
                        <TextField
                          fullWidth
                          value={`${apptType?.displayName ?? ''} (${apptType?.medium === 'telehealth' ? 'telehealth' : 'in-person'})`}
                          label="Appointment Type"
                          disabled
                          InputProps={{
                            endAdornment: (
                              <Button
                                style={{
                                  ...smallButtonStyle,
                                  ...archivedApptTypeButtonStyle,
                                }}
                                onClick={() => history.push(apptTypeDetailLink(apptType?.id ?? ''))}
                              >
                                <IcArrowRightWithStem color={colorValues.emptiness} />
                              </Button>
                            ),
                          }}
                        />
                      </FieldSection>
                    ))
                  : null}
              </ArchivedContainer>
            </ListContainer>
            {noAppointmentTypesYet && (
              <Can
                role={Roles[companyQuery.data?.me?.role ?? 'initial']}
                perform={isOnCompanyScreen ? 'appointmentTypesSubheaderStyle:create' : 'employeeAppointmentTypes:edit'}
                yes={() => (
                  <Button fullWidth size="large" onClick={openDialog} style={addApptTypeButtonStyle(primaryColor)}>
                    ADD APPOINTMENT TYPES NOW
                  </Button>
                )}
              />
            )}
          </CardBody>
        )}
      </Card>
    </>
  );
};

export default AppointmentTypes;
