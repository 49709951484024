import React from 'react';

// Hooks
import useAuthentication from '../hooks/useAuthentication';
import { useHistory } from 'react-router-dom';

const Logout = () => {
  const { push } = useHistory();
  const auth = useAuthentication();
  React.useEffect(() => {
    auth?.logout();
    push('/login');
  }, [auth, push]);
  return <div />;
};
export default Logout;
