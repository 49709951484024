import styled from '@emotion/styled';
import { colorValues, bestTextColor } from '@betterpt/better-components';

// Main View Styles

export const Page = styled.div`
  margin: 31px 28px;
`;
export const AvatarSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const Name = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-left: 15px;
`;

// Header Styles
export const TitleWrapper = styled.div<{ isManagedViaYext?: boolean | null }>`
  display: flex;
  flex-direction: column;
  background-color: ${colorValues.emptiness};
  padding: 0 0 ${({ isManagedViaYext }) => (isManagedViaYext ? 16 : 0)}px 28px;
`;
export const TitleSection = styled.div<{ isManagedViaYext?: boolean | null }>`
  margin: ${({ isManagedViaYext }) => (isManagedViaYext ? 24 : 32)}px 0;
  display: flex;
`;
export const TitleBreadCrumb = styled.h1<{ isManagedViaYext?: boolean | null }>`
  font-size: ${({ isManagedViaYext }) => (isManagedViaYext ? 16 : 20)}px;
  margin: 0;
  margin-right: 5px;
  color: ${colorValues.frenchblue};
  text-decoration: underline;
  text-decoration-style: solid;
  a {
    color: ${colorValues.frenchblue};
  }
  &:hover,
  &:focus {
    text-decoration-style: dotted;
  }
`;

export const TitleName = styled.h1<{ isManagedViaYext?: boolean | null }>`
  font-size: ${({ isManagedViaYext }) => (isManagedViaYext ? 16 : 20)}px;
  margin: 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-left: 17px;
  max-width: 1170px;
`;

export const PhotoAndNameSection = styled.div`
  display: flex;
  align-items: center;
  h1 {
    font-size: 24px;
  }
`;

export const ApptConfigHeader = styled.h2`
  font-size: 20px;
  margin: 0 0 20px 0;
`;

//Form Styles
export const H4 = styled.h4`
  color: ${colorValues.betterptblack} !important;
`;

export const FormBody = styled.div`
  padding: 11px 23px 13px 42px;
`;

export const buttonStyle = (color?: string, primary?: boolean) => {
  const bgColor = (primary && color) || colorValues.dust;
  const textColor = bestTextColor(bgColor);
  return {
    backgroundColor: bgColor,
    color: textColor,
    width: 110,
  };
};

export const ProfileImage = styled.img`
  height: 46px;
  width: 46px;
  border-radius: 50%;
  margin-right: 20px;
`;

export const ListTitle = styled.h4`
  font-size: 20px;
  margin: 22px 0px;
`;

export const CardListBody = styled.div`
  margin: 10px 44px;
  p {
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 50px;
    margin-top: 50px;
    color: ${colorValues.cityblock};
  }
`;
