import React from 'react';
import styled from '@emotion/styled';

//types
import { Role, Appointment, NewAppointmentStatus } from '../../../../../generated/graphql';
import { StylingStatus } from '../InPersonAppointmentsList/components/InPersonAppointmentStatus/InPersonAppointmentStatus';

//helpers
import Flags from '../../../../../constants/flags';
import { isBefore } from 'date-fns';
import { colorValues } from '@betterpt/better-components';
import { useAppointmentCopy } from '../InPersonAppointmentsList/components/InPersonAppointmentStatus';
import { appointmentColorMap, generateOptionMenuTitle } from '../../../../../helpers';

//hooks
import { useHistory } from 'react-router-dom';
import useInPersonAppointmentOperations from './hooks/useInPersonAppointmentOperations';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';
import { useMeQuery, useFacilityIntegrationDetailsLazyQuery, useFacilitySmsParticipationLazyQuery } from '../../../../../generated/graphql';
import useFeatureFlagWrapper from '../../../../../hooks/useFeatureFlagWrapper';

//components
import { Dialog } from '@material-ui/core';
import { TableAction } from '@betterpt/better-components';
import AssignProviderDialog from './components/AssignInPersonProviderDialog';
import CancelAppointmentDialog from './components/CancelInPersonAppointmentDialog';
import ClinicientInfoBox from '../../Shared/ClinicientInfoBox';
import ConfirmAppointmentDialog from './components/ConfirmInPersonAppointmentDialog';
import CreateInPersonAppointment from '../CreateInPersonAppointment';
import DeclineAppointmentDialog from './components/DeclineInPersonAppointmentDialog';
import RescheduleAppointmentDialog from './components/RescheduleInPersonAppointmentDialog';
import SuggestAlternateTimesDialog from '../../Shared/SuggestAlternateTimesDialog';
import SlideTransition from '../../../../Shared/SlideTransition';
import LinkContactAndPatientDialog from '../../Shared/LinkContactAndPatientDialog';
import MarkPatientContactedDialog from '../../Shared/MarkPatientContactedDialog';
import MarkNoShowDialog from '../../Shared/MarkNoShowDialog';

type Props = {
  appointment: Appointment;
  onDoneChangingOptionsAppointment: () => Promise<any> | undefined; // Call when finish cancel/ update/ or create appointment to refetch active query
  openStatus?: { value: boolean; update: (open: boolean) => void };
  isListView?: boolean;
  detailPage?: boolean;
  color?: boolean;
  icon?: {
    node: React.ReactNode;
    height?: number;
    width?: number;
  };
};

const OptionsContainer = styled.div<{ wideMode: boolean }>`
  position: relative;
  width: ${({ wideMode }) => (wideMode ? '100%' : 'auto')};
`;

const InPersonOptions = ({ appointment, openStatus, onDoneChangingOptionsAppointment, detailPage, color, icon }: Props) => {
  const history = useHistory();
  const { patientsSingularAlias, patientsPluralAlias, primaryColor } = useRemoteConfigAliases();
  const queryMe = useMeQuery();
  const featureFlag = useFeatureFlagWrapper();
  const disableChangeLocation = featureFlag(true, Flags.ChangeAppointmentLocation, true);

  const {
    commands: { contactPatient, assignEmployee },
  } = useInPersonAppointmentOperations();

  const [callQueryIntegrationDetails, queryIntegrationDetails] = useFacilityIntegrationDetailsLazyQuery({
    variables: {
      id: (appointment?.clinicId ?? appointment?.clinic?.id)!,
    },
    fetchPolicy: 'cache-first',
  });

  const [callQuerySMSparticipation, querySMSparticipation] = useFacilitySmsParticipationLazyQuery({
    variables: {
      id: (appointment?.clinicId ?? appointment?.clinic?.id)!,
    },
    fetchPolicy: 'cache-first',
  });

  const [isCreateAppointmentDialogOpen, setCreateAppointmentDialogOpen] = React.useState(false);

  const [isConfirmAppointmentDialogOpen, setConfirmAppointmentDialogOpen] = React.useState(false);

  const [isRescheduleAppointmentDialogOpen, setRescheduleAppointmentDialogOpen] = React.useState(false);

  const [isAssignProviderDialogOpen, setAssignProviderDialogOpen] = React.useState(false);

  const [isAlternateTimesDialogOpen, setAlternateTimesDialogOpen] = React.useState(false);

  const [isMarkContactedDialogOpen, setMarkContactedDialogOpen] = React.useState(false);

  const [isCancelAppointmentDialogOpen, setCancelAppointmentDialogOpen] = React.useState(false);

  const [isDeclineAppointmentDialogOpen, setDeclineAppointmentDialogOpen] = React.useState(false);

  const [isAddPatientDialogOpen, setIsAddPatientDialogOpen] = React.useState(false);

  const [isNoShowDialogOpen, setIsNoShowDialogOpen] = React.useState(false);

  // variables
  const facilityIsNotMigrated = !appointment?.clinic?.isBetterAccessOnly;
  const status: StylingStatus = useAppointmentCopy(appointment).stylingStatus;
  const requestedNewTime = appointment?.alternateTimeResponseStatus === 'requestedNewTime';
  const isExpiredRequest = status === 'expired';
  const isPendingTextConfirmation = status === 'pendingTextConfirmation';
  const isRequest = appointment?.appointmentStatus === NewAppointmentStatus.Requested;
  const isUpcoming = appointment?.appointmentStatus === NewAppointmentStatus.Upcoming;
  const requestDetailView = isRequest && detailPage;
  const wideMode = requestDetailView && !isExpiredRequest && !!icon;
  const isConfirmed = status === 'booked';
  const isDeclined = status === 'denied';
  const noShow = status === 'no-show';
  const isCancelled = status === 'cancelled';
  const isCompleted = status === 'completed';
  const backgroundColor = detailPage || color ? appointmentColorMap.get(status)?.[1] : undefined;
  const textColor = detailPage || color ? appointmentColorMap.get(status)?.[0] : undefined;
  const couldSMS = querySMSparticipation.data?.clinic?.bookingConfig?.isSMSBetaParticipant;
  const canSMS = appointment?.isSMSEligible;
  const contactId = appointment?.contact?.id;
  const isBasicUser = queryMe.data?.me?.role === Role.Self;
  const userIsProviderOnAppointment = queryMe.data?.me?.id === appointment?.employee?.id;
  const altTimesSent = !!appointment?.alternateTimesExpireAt;

  const isCancellationBlocked =
    queryIntegrationDetails.data?.clinic?.integrationDetails?.shouldBlockCancellations === true && appointment?.wasRequested;
  const canReschedule =
    (isBasicUser ? userIsProviderOnAppointment : true) &&
    !isCompleted &&
    !isDeclined &&
    !isBefore(new Date(appointment?.startTime), new Date()) &&
    !isCancelled;
  const rescheduleAppointment = (isBasicUser ? userIsProviderOnAppointment : true) && !isCompleted && !isDeclined && !isCancelled;
  const canSMSonExpiredAppointment = (!isBasicUser || userIsProviderOnAppointment) && !isExpiredRequest;
  const patientIsNotLinked = !(appointment.patient?.contact?.id || appointment.contact?.id);

  const renderAddPatientInfo = () => {
    if (contactId) return `Create new appointment`;
    else
      return `To add an appointment to this ${patientsSingularAlias.toLowerCase()}
        course of care, first add them to your ${patientsPluralAlias.toLowerCase()} list`;
  };

  const renderSMSInfo = () => {
    if (canSMS) {
      return `Suggest new time via SMS${altTimesSent ? ' (Already sent)' : ''}`;
    } else return `${patientsSingularAlias} needs a valid mobile number to receive alternate times via SMS`;
  };

  const viewAppointmentOption = (isRequest: boolean) => [
    {
      label: `View details`,
      callback: () => history.push(`/appointments/details/${appointment?.id}`),
      hidden: detailPage || !appointment?.id,
    },
  ];

  const markPatientAsContactedAction = () => [
    {
      id: 'mark-patient-as-contacted',
      label: `Mark ${patientsSingularAlias.toLowerCase()} as contacted`,
      callback: () => setMarkContactedDialogOpen(true),
      hidden: !!appointment?.hasPatientBeenContacted,
    },
  ];

  const hasClinics = queryMe?.data?.me?.clinics ? queryMe.data.me.clinics.length >= 1 : false;

  const pendingActions = [
    {
      label: 'Confirm appointment request',
      callback: () => setConfirmAppointmentDialogOpen(true),
      hidden: patientIsNotLinked || !appointment?.id,
    },
    {
      label: 'Change appointment and confirm',
      callback: () => setRescheduleAppointmentDialogOpen(true),
      hidden: patientIsNotLinked || !canReschedule,
      disabled: isCancellationBlocked,
      icon: isCancellationBlocked && <ClinicientInfoBox action="RESCHEDULING" />,
    },
    {
      label: renderSMSInfo(),
      callback: () => setAlternateTimesDialogOpen(true),
      hidden: !couldSMS || !canReschedule,
      disabled: !canSMS,
    },
    {
      label: `Add this ${patientsSingularAlias.toLowerCase()}`,
      callback: () => setIsAddPatientDialogOpen(true),
      hidden: !detailPage || !patientIsNotLinked,
    },
    {
      id: 'assign-to-scheduler',
      label: 'Assign myself as scheduler',
      callback: () =>
        assignEmployee({
          employeeId: queryMe.data?.me?.id,
          appointmentId: appointment.id,
        }),
      hidden: appointment.assignedEmployeeId === queryMe.data?.me?.id,
    },
    ...markPatientAsContactedAction(),
    {
      label: 'Decline request',
      callback: () => setDeclineAppointmentDialogOpen(true),
      hidden: !appointment?.id,
      color: colorValues.messyketchup,
      disabled: isCancellationBlocked,
      icon: isCancellationBlocked && <ClinicientInfoBox action="DECLINING" />,
    },
  ];

  const standardActions = [
    {
      label: renderAddPatientInfo(),
      callback: () => setCreateAppointmentDialogOpen(true),
      hidden: isExpiredRequest || isPendingTextConfirmation,
      disabled: !contactId,
    },
    {
      label: 'Change appointment and confirm',
      callback: () => setRescheduleAppointmentDialogOpen(true),
      hidden: patientIsNotLinked || !rescheduleAppointment || !(isPendingTextConfirmation || isExpiredRequest || isUpcoming),
      disabled: isCancellationBlocked,
      icon: isCancellationBlocked && <ClinicientInfoBox action="RESCHEDULING" />,
    },
    ...markPatientAsContactedAction(),
    {
      label: 'Mark appointment as no-show',
      callback: () => setIsNoShowDialogOpen(true),
      hidden: !isCompleted,
    },
    {
      label: 'Change provider',
      callback: () => setAssignProviderDialogOpen(true),
      hidden: !appointment?.id || !isUpcoming,
    },
    {
      id: 'assign-to-scheduler',
      label: 'Assign myself as scheduler',
      callback: () =>
        assignEmployee({
          employeeId: queryMe.data?.me?.id,
          appointmentId: appointment.id,
        }),
      hidden: appointment.assignedEmployeeId === queryMe.data?.me?.id || !isUpcoming,
    },
    {
      label: 'Decline request',
      callback: () => setDeclineAppointmentDialogOpen(true),
      hidden: !appointment?.id || !isExpiredRequest,
      color: colorValues.messyketchup,
      disabled: isCancellationBlocked,
      icon: isCancellationBlocked && <ClinicientInfoBox action="DECLINING" />,
    },
    {
      label: 'Cancel appointment',
      callback: () => setCancelAppointmentDialogOpen(true),
      hidden: !canReschedule || !appointment?.id || !(isPendingTextConfirmation || isUpcoming),
      color: colorValues.messyketchup,
      disabled: isCancellationBlocked,
      icon: isCancellationBlocked && <ClinicientInfoBox action="CANCELLING" />,
    },
  ];

  const actions = (() => {
    if (facilityIsNotMigrated) {
      return [...viewAppointmentOption(isRequest)];
    } else {
      return [...viewAppointmentOption(isRequest), ...(isRequest ? pendingActions : standardActions)];
    }
  })();

  const callOnOpen = () => {
    callQuerySMSparticipation();
    callQueryIntegrationDetails();
  };
  return (
    <OptionsContainer wideMode={!!wideMode} onClick={(e) => e.stopPropagation()}>
      {isCreateAppointmentDialogOpen && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={isCreateAppointmentDialogOpen}
          onClose={() => setCreateAppointmentDialogOpen(false)}
          TransitionComponent={SlideTransition}
          disablePortal
          PaperProps={{
            style: {
              width: '900px',
              maxWidth: '900px',
            },
          }}
        >
          <CreateInPersonAppointment
            existingAppointment={appointment}
            onDone={() => {
              setCreateAppointmentDialogOpen(false);
              onDoneChangingOptionsAppointment?.();
            }}
            onCancelClick={() => setCreateAppointmentDialogOpen(false)}
            lockPatient
            isInModal
          />
        </Dialog>
      )}
      {isCancelAppointmentDialogOpen && ( // this is necessary to trigger refetches of queries in the dialog. We have to take it off the DOM entirely to get the refetch.
        <CancelAppointmentDialog
          open={isCancelAppointmentDialogOpen}
          onClose={() => setCancelAppointmentDialogOpen(false)}
          appointmentId={appointment.id}
          onDoneChangingOptionsAppointment={onDoneChangingOptionsAppointment}
        />
      )}
      {isDeclineAppointmentDialogOpen && (
        <DeclineAppointmentDialog
          open={isDeclineAppointmentDialogOpen}
          onClose={() => setDeclineAppointmentDialogOpen(false)}
          appointmentId={appointment.id}
          onDoneChangingOptionsAppointment={onDoneChangingOptionsAppointment}
        />
      )}
      {isConfirmAppointmentDialogOpen && (
        <ConfirmAppointmentDialog
          open={isConfirmAppointmentDialogOpen}
          onClose={() => setConfirmAppointmentDialogOpen(false)}
          appointmentId={appointment.id}
          onDoneChangingOptionsAppointment={onDoneChangingOptionsAppointment}
        />
      )}
      {isRescheduleAppointmentDialogOpen && (
        <RescheduleAppointmentDialog
          open={isRescheduleAppointmentDialogOpen}
          onClose={() => setRescheduleAppointmentDialogOpen(false)}
          appointmentId={appointment.id}
          onDoneChangingOptionsAppointment={onDoneChangingOptionsAppointment}
          isRequest={isRequest}
        />
      )}
      {isAssignProviderDialogOpen && (
        <AssignProviderDialog
          open={isAssignProviderDialogOpen}
          onClose={() => setAssignProviderDialogOpen(false)}
          appointmentId={appointment.id}
          onDoneChangingOptionsAppointment={onDoneChangingOptionsAppointment}
        />
      )}
      {isAlternateTimesDialogOpen && (
        <SuggestAlternateTimesDialog
          open={isAlternateTimesDialogOpen}
          onClose={() => setAlternateTimesDialogOpen(false)}
          appointment={appointment}
          handleComplete={onDoneChangingOptionsAppointment}
        />
      )}
      {isAddPatientDialogOpen && (
        <LinkContactAndPatientDialog
          isOpen={isAddPatientDialogOpen}
          handleClose={() => setIsAddPatientDialogOpen(false)}
          appointment={appointment}
        />
      )}
      {isNoShowDialogOpen && (
        <MarkNoShowDialog
          open={isNoShowDialogOpen}
          onClose={() => setIsNoShowDialogOpen(false)}
          appointmentId={appointment.id}
          onDoneChangingOptionsAppointment={onDoneChangingOptionsAppointment}
        />
      )}
      {isMarkContactedDialogOpen && (
        <MarkPatientContactedDialog
          open={isMarkContactedDialogOpen}
          onClose={() => setMarkContactedDialogOpen(false)}
          appointmentId={appointment.id}
          contactPatient={contactPatient}
        />
      )}
      <TableAction
        options={actions}
        title={requestDetailView ? generateOptionMenuTitle(appointment) : ''}
        color={{ backgroundColor, textColor }}
        buttonColor={primaryColor}
        buttonStyle={backgroundColor ? { height: 36 } : {}}
        iconStyle={isRequest && detailPage ? { width: '100%' } : {}}
        icon={icon}
        openStatus={openStatus}
        callOnOpen={callOnOpen}
        boxStyle={{ top: '-85px' }}
        arrowStyle={{ top: '98px' }}
      />
    </OptionsContainer>
  );
};

export default InPersonOptions;
