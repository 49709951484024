import * as React from 'react';

//types
import { PatientFiltersContextType } from '../../../../../context/patientFilters';

//hooks
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';

//components
import { Filter } from '@betterpt/better-components';

const StatusFilter: React.FC<React.PropsWithChildren<{
  filters: PatientFiltersContextType;
  primaryColor?: string;
}>> = ({ filters, primaryColor }) => {
  const {
    patientsSingularAlias,
    patientsPluralAlias,
  } = useRemoteConfigAliases();

  const statusFilterOptions = [
    {
      label: 'All Statuses',
      isResetOption: true,
      selected: filters.isArchived.value === undefined,
      callback: () => filters.isArchived.update(undefined),
    },
    {
      label: `Archived ${patientsPluralAlias}`,
      selected: filters.isArchived.value === true,
      callback: () => filters.isArchived.update(true),
    },
    {
      label: `Active ${patientsPluralAlias}`,
      selected: filters.isArchived.value === false,
      callback: () => filters.isArchived.update(false),
    },
  ];

  return (
    <Filter
      type='radio'
      name='status'
      boxTitle={`${patientsSingularAlias} status`}
      options={statusFilterOptions}
      overrideButtonColor={primaryColor}
    />
  );
};

export default StatusFilter;
