import * as React from 'react';

class ErrorBoundary extends React.Component<Record<string, any>, { hasError: boolean; errorMessage: string }> {
  constructor(props: Record<string, any>) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: ''
    };
  }

  override componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({
      hasError: true,
      errorMessage: `${error?.message || 'Error'}: ${info?.componentStack || error}`
    });
  };

  override render() {
    if (this.state.hasError) {
      return (
        <div>
          <p>
            {this.state.errorMessage ? this.state.errorMessage : 'Something went wrong.'}
          </p>
        </div>
      )
    } else return this.props.children;
  }
};

export default ErrorBoundary;