import * as React from 'react';
import { Dialog } from '@material-ui/core';

type Props = {
  opened: boolean;
  handleClose: () => void;
  url?: string;
  frontOrBack: 'front' | 'back';
};

const CardDialog = ({ opened, handleClose, url, frontOrBack }: Props) => {
  return (
    <Dialog open={opened} onClose={handleClose}>
      <img src={url} alt={`${frontOrBack} of insurance card`} />
    </Dialog>
  );
};

export default CardDialog;
