import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='161'
    height='160'
    viewBox='0 0 161 160'
  >
    <defs>
      <path id='xi8ulls5da' d='M0 0.798L33.729 0.798 33.729 22.112 0 22.112z' />
      <path
        id='kyx8iu1jic'
        d='M0.066 0.821L76.599 0.821 76.599 56.75 0.066 56.75z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <g>
                <path
                  fill='#F8AA7E'
                  d='M155.979 78.577c-5.287 8.282-14.419 13.126-24.242 13.385-.526.014-.869.083-.975.246-.676 1.044-5.668-2.247-5.668-2.247s.971-7.747 4.595-7.624c3.624.124 16.45 2.846 19.514-2.236 3.062-5.082 11.103-12.128 9.58-6.96-.421 1.423-1.483 3.366-2.804 5.436'
                  transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5)'
                />
                <g transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(0 69.422)'>
                  <mask id='d68j28jk6b' fill='#fff'>
                    <use xlinkHref='#xi8ulls5da' />
                  </mask>
                  <path
                    fill='#F8AA7E'
                    d='M2.94 8.061c5.216 8.327 14.309 13.247 24.128 13.588.526.017.87.09.973.254.667 1.049 5.688-2.2 5.688-2.2s-.908-7.756-4.532-7.661c-3.625.093-16.473 2.708-19.495-2.4-3.021-5.108-11-12.22-9.52-7.04.407 1.426 1.454 3.38 2.757 5.46'
                    mask='url(#d68j28jk6b)'
                  />
                </g>
                <path
                  fill='#4A79B5'
                  d='M123.944 127.711v21.952h-88.78V127.71c0-20.458 13.844-37.687 32.683-42.82 1.278-.36 2.576-.653 3.902-.887 2.537-.449 5.142-.683 7.805-.683 3.356 0 6.615.37 9.756 1.073 1.327.303 2.625.654 3.903 1.074 6.76 2.175 12.82 5.941 17.727 10.858 8.039 8.03 13.004 19.132 13.004 31.385'
                  transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5)'
                />
                <path
                  fill='#FEFEFE'
                  d='M93.213 85.468v2.566c0 5.082-2.99 9.466-7.306 11.49-1.633.764-3.455-4.983-5.377-4.983-1.751 0-3.42 5.82-4.938 5.178-4.55-1.924-7.745-6.432-7.745-11.685v-3.142c1.278-.36 2.576-.654 3.902-.888l17.561.39c1.327.303 2.625.653 3.903 1.074'
                  transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5)'
                />
                <path
                  fill='#2B4F83'
                  d='M68.823 85.638v2.395c0 4.659 2.739 8.848 6.994 10.72.359-.284.985-1.325 1.371-1.97.994-1.654 1.932-3.218 3.342-3.218 1.455 0 2.512 1.576 3.534 3.1.435.65 1.148 1.714 1.524 1.93 4.043-1.94 6.65-6.074 6.65-10.562v-1.851c-.964-.298-1.968-.565-3.049-.815l-17.365-.385c-1.026.184-2.033.404-3.001.656m7.041 15.12c-.207 0-.424-.044-.651-.14-5.068-2.144-8.342-7.083-8.342-12.585v-3.141c0-.436.29-.82.71-.939 1.277-.36 2.622-.666 3.999-.909.063-.012.128-.016.19-.016l17.562.39c.066.003.132.01.196.025 1.431.327 2.736.686 3.99 1.098.4.131.67.506.67.927v2.565c0 5.276-3.087 10.133-7.867 12.374-1.597.75-2.809-1.06-3.879-2.656-.483-.72-1.489-2.22-1.912-2.235-.375.115-1.217 1.518-1.668 2.272-.88 1.463-1.784 2.97-2.998 2.97'
                  transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5)'
                />
                <path
                  fill='#E8956B'
                  d='M89.31 71.126V85.76c0 4.849-3.931 8.78-8.78 8.78-4.849 0-8.78-3.931-8.78-8.78V71.126h17.56z'
                  transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5)'
                />
                <path
                  fill='#F8AA7E'
                  d='M113.254 65.256c-4.38 8.046-11.603 13.556-19.75 15.95-8.143 2.41-17.2 1.7-25.245-2.68-16.091-8.762-22.032-28.903-13.27-44.995.173-.316.35-.62.53-.933 1.932-3.302 4.354-6.138 7.112-8.492.374-.318.75-.624 1.132-.916 3.295-2.56 7.023-4.452 10.976-5.61 8.143-2.41 17.199-1.7 25.245 2.68 6.623 3.607 11.524 9.131 14.379 15.518.041.067.07.137.097.23.583 1.296 1.073 2.64 1.476 4.003 1.17 3.948 1.607 8.106 1.242 12.262-.28 3.148-1 6.287-2.217 9.336-.359.903-.76 1.807-1.212 2.695-.164.321-.323.635-.495.952'
                  transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5)'
                />
                <path
                  fill='#16421F'
                  d='M70.574 49.793c-.947-.515-1.296-1.7-.78-2.647l1.618-2.973c.515-.946 1.7-1.295 2.647-.78.946.515 1.295 1.7.78 2.647l-1.619 2.972c-.515.947-1.7 1.296-2.646.78M89.424 60.057c-.947-.516-1.296-1.7-.78-2.647l1.618-2.973c.515-.946 1.7-1.296 2.647-.78.946.515 1.295 1.7.78 2.646l-1.618 2.973c-.516.946-1.701 1.296-2.647.78'
                  transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5)'
                />
                <path
                  fill='#F8AA7E'
                  d='M56.626 36.644S46.21 59.855 67.946 75.022'
                  transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5)'
                />
                <path
                  fill='#2A4E82'
                  d='M42.324 159.07c-3.982.687-7.767-1.984-8.453-5.967L23.297 91.734l14.421-2.484 10.575 61.368c.686 3.983-1.986 7.767-5.969 8.453M116.446 159.07c3.982.687 7.767-1.984 8.454-5.967l10.573-61.369-14.421-2.484-10.575 61.368c-.686 3.983 1.986 7.767 5.969 8.453'
                  transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5)'
                />
                <g transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(51.707 .154)'>
                  <mask id='5vprnakejd' fill='#fff'>
                    <use xlinkHref='#kyx8iu1jic' />
                  </mask>
                  <path
                    fill='#FFCC45'
                    d='M54.862 38.762c-.687.09-1.382.114-2.094.074-4.584-.257-8.387-3.203-9.952-7.227-2.205 2.222-5.308 3.52-8.678 3.33-4.775-.269-8.682-3.444-10.117-7.718-.267.007-.543.002-.82-.013-2.297-.13-4.385-.93-6.096-2.2-1.852 1.07-4.028 1.63-6.314 1.5-6.263-.352-11.06-5.71-10.707-11.973.352-6.264 5.72-11.06 11.984-10.708 2.286.13 4.386.93 6.106 2.211 1.843-1.08 4.007-1.642 6.304-1.512 2.244.127 4.302.903 5.993 2.13C32.524 2.974 36.551.586 41.04.839c4.488.253 8.222 3.077 9.849 6.966.138-.003.288.006.425.014 6.264.353 11.062 5.71 10.708 11.974l-.015.276c-.032.393-.086.774-.15 1.154.283-.09.574-.17.866-.228 1.006-.242 2.067-.332 3.151-.27 6.265.352 11.06 5.72 10.707 11.984-.31 5.53-4.524 9.902-9.813 10.606.147.798.262 1.594.345 2.398.383 3.618.26 7.322-.45 11.037-7.166-5.545-11.55-12.01-11.8-17.988'
                    mask='url(#5vprnakejd)'
                  />
                </g>
                <path
                  fill='#F8AA7E'
                  d='M67.057 40.643c-1.32 2.427-3.5 4.09-5.958 4.813-2.457.725-5.188.512-7.616-.81-4.853-2.642-6.645-8.718-4.003-13.573l.16-.282c.584-.996 1.314-1.851 2.146-2.56.113-.097.227-.189.341-.277.994-.773 2.12-1.343 3.312-1.693 2.456-.726 5.188-.513 7.614.81 2 1.087 3.477 2.753 4.339 4.68.011.02.02.041.029.07.176.39.324.796.445 1.206.353 1.192.485 2.446.374 3.7-.083.95-.301 1.896-.668 2.816-.108.273-.23.545-.366.813-.048.097-.097.191-.149.287M119.74 65.684c-1.321 2.427-3.5 4.089-5.958 4.812-2.457.726-5.188.513-7.616-.81-4.853-2.642-6.646-8.718-4.003-13.572l.16-.282c.584-.996 1.314-1.852 2.146-2.562.113-.095.227-.188.341-.276.994-.773 2.12-1.342 3.311-1.693 2.457-.725 5.189-.512 7.615.81 2 1.088 3.477 2.754 4.339 4.68.011.02.02.042.029.07.176.39.324.796.445 1.207.353 1.192.485 2.446.374 3.7-.083.95-.301 1.896-.668 2.816-.108.273-.23.545-.366.813-.049.097-.097.191-.149.287'
                  transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5)'
                />
                <path
                  fill='#FFCC45'
                  d='M109.499 60.913L106.276 58.713 114.527 46.626 117.75 48.826z'
                  transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5)'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
