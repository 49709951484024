import * as React from 'react';

// components
import {
  Button,
  Animation,
  Header,
  TextField,
  Switch,
  HoursOfOperation,
  daysHourOfOperationInitialValues,
  daysHoursOfOperationSubmitValues,
} from '@betterpt/better-components';
import { GridCard } from '../../../../../Shared/GridCard';
import FormField from '../../../../../Shared/FormField';
import Can from '../../../../../Can';
import CopyTimesToAll from '../../../../../Shared/CopyTimesToAll';
import FeatureFlagCheck from '../../../../../Shared/FeatureFlagCheck';

// hooks
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';
import {
  useFacilityQuery,
  useMeQuery,
  useUpdateFacilityBookingConfigMutation,
  useUpdateFacilityMutation,
  useAddFacilityToTraditionalSubscriptionMutation,
  useRemoveFacilityFromTraditionalSubscriptionMutation,
  useFacilitySubscriptionDetailQuery,
  useUpdateFacilityNotificationSettingsMutation,
  useFacilityNotificationSettingsQuery,
} from '../../../../../../generated/graphql';
import useTextFieldState from '../../../../../../hooks/useTextFieldState';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import useFeatureFlagWrapper from '../../../../../../hooks/useFeatureFlagWrapper';
import useHandleError from '../../../../../../hooks/useHandleError';

// style
import {
  loaderCardStyle,
  cardStyle,
  headerStyle,
  editButtonStyle,
} from '../../FacilityDetails.style';
import {
  BorderedCardSection,
  inPersonTextInputStyle,
  Footer,
  Divider,
  HoursContainer,
} from './InPersonSettings.style';

// helpers
import { Roles } from '../../../../../../helpers/rbac-rules';
import Flags from '../../../../../../constants/flags';

const InPersonSettings = ({ facilityId }: { facilityId: string }) => {
  const featureFlagWrapper = useFeatureFlagWrapper();
  const snackbar = useSnackbar();
  const handleError = useHandleError();
  const { data: facilityData, loading: facilityLoading } = useFacilityQuery({
    variables: { id: facilityId },
  });
  const { data: meData, loading: meLoading } = useMeQuery();
  const {
    data: notificationData,
    loading: notificationLoading,
  } = useFacilityNotificationSettingsQuery({
    variables: {
      id: facilityId,
    },
    skip: !(
      meData?.me?.role === 'owner' ||
      meData?.me?.clinics?.map((clinic) => clinic?.id).includes(facilityId)
    ),
  });

  const { data: subscriptionData } = useFacilitySubscriptionDetailQuery({
    variables: { id: facilityId },
    onError: () => snackbar?.useGenericErrorMessage(),
  });

  const [isFormDisabled, updateIsFormDisabled] = React.useState(true);
  const [isSaving, updateSaving] = React.useState(false);
  const [valuesHaveBeenSet, updateValuesHaveBeenSet] = React.useState(false);
  const {
    facilitiesSingularAlias,
    employeeSingularAlias,
    primaryColor,
    loading: remoteConfigLoading,
  } = useRemoteConfigAliases();

  // form values
  const [
    inPersonHoursOfOperation,
    updateInPersonHoursOfOperation,
  ] = React.useState<any>();
  const cancellationPolicy = useTextFieldState();

  const [
    isAppointmentReminderActive,
    updateAppointmentReminderActive,
  ] = React.useState<boolean>();
  const [isInPersonSubActive, updateIsInPersonSubActive] = React.useState<
    boolean
  >();

  const [
    shouldUseProviderSchedule,
    updateShouldUseProviderSchedule,
  ] = React.useState<boolean>();

  React.useEffect(() => {
    if (facilityData && notificationData) {
      const { clinic } = facilityData;

      // update existing values
      updateInPersonHoursOfOperation(
        daysHourOfOperationInitialValues(clinic?.bookingConfig?.hours ?? {})
      );
      cancellationPolicy.updateValue(clinic?.cancellationPolicy ?? '');
      updateIsInPersonSubActive(!!clinic?.betterPTSubscriptionActive);
      updateShouldUseProviderSchedule(!!clinic?.shouldUseProviderSchedule);
      updateAppointmentReminderActive(
        !notificationData?.clinic?.notificationSettings
          ?.shouldDisableInPersonReminderEmails
      );
      updateValuesHaveBeenSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityData, notificationData]);

  // mutations
  const [updateFacility] = useUpdateFacilityMutation();
  const [
    updateFacilityBookingConfig,
  ] = useUpdateFacilityBookingConfigMutation();
  const [
    removeFacilitySubscription,
  ] = useRemoveFacilityFromTraditionalSubscriptionMutation();
  const [
    addFacilitySubscription,
  ] = useAddFacilityToTraditionalSubscriptionMutation();

  const [
    updateFacilityNotificationSettings,
  ] = useUpdateFacilityNotificationSettingsMutation();

  // functions
  const handleFormSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isFormDisabled) {
      updateIsFormDisabled(false);
    } else {
      updateSaving(true);

      try {
        if (
          isInPersonSubActive !==
          facilityData?.clinic?.betterPTSubscriptionActive
        ) {
          if (isInPersonSubActive) {
            await addFacilitySubscription({
              variables: {
                clinicId: facilityId,
              },
            });
          } else {
            await removeFacilitySubscription({
              variables: {
                clinicId: facilityId,
              },
            });
          }
        }
        await updateFacility({
          variables: {
            input: {
              clinicId: facilityId,
              cancellationPolicy: cancellationPolicy.value,
              shouldUseProviderSchedule,
            },
          },
        });

        await updateFacilityBookingConfig({
          variables: {
            input: {
              clinicId: facilityId,
              hours: daysHoursOfOperationSubmitValues(
                inPersonHoursOfOperation.formattedOpenCloseDaysHours
              ),
            },
          },
        });

        await updateFacilityNotificationSettings({
          variables: {
            input: {
              clinicId: facilityId,
              shouldDisableInPersonReminderEmails: !isAppointmentReminderActive,
            },
          },
        });

        updateSaving(false);
        updateIsFormDisabled(true);
        snackbar?.setSuccessMessage(
          `${facilitiesSingularAlias} updated successfully!`
        );
      } catch (e) {
        updateIsFormDisabled(true);
        updateSaving(false);
        handleError(e);
      }
    }
  };

  const setFieldValue = (field: string, value: any) => {
    // we do this because internally the HoursOfOperation component is sending
    // hours.{dayVariable} here. All we need is dayVariable, so we have to get
    // everything after the period.
    const normalizedFieldName = field.split('.')[1];

    updateInPersonHoursOfOperation((oldValues: any) => {
      return {
        ...oldValues,
        formattedOpenCloseDaysHours: {
          ...oldValues.formattedOpenCloseDaysHours,
          [normalizedFieldName]: value,
        },
      };
    });
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    // I don't know why we have to do e.persist, but apparently the event turns
    // to null if we don't. Strange react stuff.
    e.persist();
    // we do this because internally the HoursOfOperation component is sending
    // a nested object. Depending on the first part of the nested object,
    // we have to determine what part of the object has to be updated.
    const fieldNameComponents = e.target.name.split('.');

    if (fieldNameComponents[0] === 'hours') {
      updateInPersonHoursOfOperation((oldValues: any) => {
        return {
          ...oldValues,
          formattedOpenCloseDaysHours: {
            ...oldValues.formattedOpenCloseDaysHours,
            [fieldNameComponents[1]]: e.target.value,
          },
        };
      });
    } else {
      updateInPersonHoursOfOperation((oldValues: any) => {
        return {
          ...oldValues,
          selectedDays: {
            ...oldValues.selectedDays,
            [fieldNameComponents[1]]: e.target.value !== 'true',
          },
        };
      });
    }
  };

  const copyTimeToAll = () => {
    const newHours: any = {};
    const newSelectedDays: any = {};
    const mondayOpen =
      inPersonHoursOfOperation.formattedOpenCloseDaysHours.mondayOpen;
    const mondayClose =
      inPersonHoursOfOperation.formattedOpenCloseDaysHours.mondayClose;

    Object.keys(inPersonHoursOfOperation.formattedOpenCloseDaysHours).forEach(
      (day) => {
        if (day.includes('Open')) {
          newHours[day] = mondayOpen;
        } else {
          newHours[day] = mondayClose;
        }
      }
    );
    Object.keys(inPersonHoursOfOperation.selectedDays).forEach(
      (day) => (newSelectedDays[day] = true)
    );

    updateInPersonHoursOfOperation({
      selectedDays: newSelectedDays,
      formattedOpenCloseDaysHours: newHours,
    });
  };
  // Loading state
  if (
    facilityLoading ||
    meLoading ||
    remoteConfigLoading ||
    notificationLoading ||
    !valuesHaveBeenSet
  ) {
    return (
      <GridCard style={loaderCardStyle} data-testid='in-person-settings-loader'>
        <Animation type='providerAppLoader' />
      </GridCard>
    );
  }

  return (
    <GridCard style={cardStyle}>
      <form onSubmit={handleFormSubmit}>
        <Header
          data-testid='in-person-settings-header'
          underline
          style={headerStyle}
        >
          <h3 className='H3'>In-Person Settings</h3>
          <Can
            role={Roles[meData?.me?.role || 'initial']}
            perform='facility:view'
            data={{
              employeeClinics: meData?.me?.clinics,
              clinicToView: facilityData?.clinic,
            }}
            yes={() => (
              <Can
                role={Roles[meData?.me?.role || 'initial']}
                perform='facility:edit'
                yes={() => (
                  <Button
                    style={editButtonStyle(isFormDisabled, primaryColor)}
                    size='small'
                    type='submit'
                    loading={isSaving}
                    disabled={isSaving}
                  >
                    {isFormDisabled ? 'EDIT' : 'SAVE'}
                  </Button>
                )}
              />
            )}
          />
        </Header>
        <BorderedCardSection>
          <Switch
            disabled={
              !subscriptionData?.clinic?.subscriptionDetail
                ?.canUpgradeToTraditionalSubscription || isFormDisabled
            }
            checked={isInPersonSubActive}
            onChange={() => updateIsInPersonSubActive(!isInPersonSubActive)}
          />
          <TextField
            value={`In-person appointments are set to ${
              isInPersonSubActive ? 'ON' : 'OFF'
            }`}
            disabled
            label='In-Person Appointments'
            fullWidth
            style={inPersonTextInputStyle}
          />
        </BorderedCardSection>

        <BorderedCardSection>
          <Switch
            disabled={isFormDisabled}
            checked={!shouldUseProviderSchedule}
            onChange={() =>
              updateShouldUseProviderSchedule(!shouldUseProviderSchedule)
            }
          />
          <TextField
            value={
              shouldUseProviderSchedule
                ? `In-person hours are set to OFF at this ${facilitiesSingularAlias.toLowerCase()}. Add ${employeeSingularAlias.toLowerCase()} hours instead.`
                : `In-person hours of operation are set at the ${facilitiesSingularAlias.toLowerCase()} level`
            }
            disabled
            label='Facility Level Hours of Operation'
            fullWidth
            style={inPersonTextInputStyle}
          />
        </BorderedCardSection>

        <Divider />
        <HoursContainer>
          {!isFormDisabled && (
            <CopyTimesToAll
              onClick={copyTimeToAll}
              hoursTypeText='in-person appointment'
            />
          )}
          <HoursOfOperation
            hours={inPersonHoursOfOperation?.formattedOpenCloseDaysHours ?? {}}
            selectedDays={inPersonHoursOfOperation?.selectedDays ?? {}}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            disabled={isFormDisabled}
          />
        </HoursContainer>
        <Divider />
        <Footer>
          <FormField
            id='cancellationPolicy'
            formHandler={cancellationPolicy}
            fullWidth
            label='In-Person Cancellation Policy'
            placeholder='Enter your cancellation policy here.'
            multiline
            disabled={isFormDisabled}
          />

          <TextField
            id='appointment-reminders'
            fullWidth
            label='In Person Appointment Reminders'
            value={`In-person appointment reminders for this facility are ${
              isAppointmentReminderActive ? 'ON' : 'OFF'
            }`}
            disabled
            InputProps={{
              endAdornment: (
                <Switch
                  disabled={isFormDisabled}
                  checked={isAppointmentReminderActive}
                  onChange={() =>
                    updateAppointmentReminderActive(
                      !isAppointmentReminderActive
                    )
                  }
                />
              ),
            }}
          />
        </Footer>
      </form>
    </GridCard>
  );
};

export default InPersonSettings;
