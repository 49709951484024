import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='25'
    height='25'
    viewBox='0 0 25 25'
  >
    <defs>
      <path
        id='boiaxrc9ya'
        d='M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g transform='translate(-264 -1888) translate(0 -1) translate(0 .036) translate(245 1519.612) translate(0 47) translate(20 89) translate(0 212) translate(0 22)'>
                      <path d='M0 0L24 0 24 24 0 24z' />
                      <mask id='9nkcw2tymb' fill='#fff'>
                        <use xlinkHref='#boiaxrc9ya' />
                      </mask>
                      <use
                        fill='#333'
                        fillRule='nonzero'
                        xlinkHref='#boiaxrc9ya'
                      />
                      <g fill={color || '#919191'} mask='url(#9nkcw2tymb)'>
                        <path d='M0 0H24V24H0z' />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
