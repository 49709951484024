import * as React from 'react';

//types
import {
  Appointment,
  useMeQuery,
} from '../../../../../../../generated/graphql';

//helpers
import { format, utcToZonedTime } from 'date-fns-tz';
import { colorValues } from '@betterpt/better-components';
import { parseAppointmentTypeField } from '../../../../../../../helpers';

//hooks
import useRemoteConfigAliases from '../../../../../../../hooks/useRemoteConfigAliases';

//components
import { StatusStyle } from '../../../../../../../helpers';

//assets
import {
  IcClose,
  IcDateRange,
  IcMore,
  IcSmartphone,
  IcSuccess,
  IcWaving,
} from '@betterpt/better-icons';

export type StylingStatus =
  | 'no-show'
  | 'booked'
  | 'completed'
  | 'cancelled'
  | 'denied'
  | 'pending'
  | 'request'
  | 'expired'
  | 'pendingTextConfirmation';

type AppointmentCopy = {
  appointmentType: string;
  appointmentDetails: string;
  schedulingActivity: string;
  bigButtonCopy: string;
  smallButtonCopy: string;
  headerCopy: string;
  detailHeaderCopy: string;
  stylingStatus: StylingStatus;
  flagIcon: React.ReactNode;
  flagColor: string;
};

export const useAppointmentCopy = (
  appointment?: Appointment,
  detailPage?: boolean,
  isBasic?: boolean
): AppointmentCopy => {
  const {
    patientsSingularAlias,
    facilitiesSingularAlias,
    loading,
  } = useRemoteConfigAliases();
  const meQuery = useMeQuery();
  if (!appointment) {
    return {
      appointmentType: '',
      appointmentDetails: '',
      schedulingActivity: '',
      bigButtonCopy: '',
      smallButtonCopy: '',
      headerCopy: '',
      stylingStatus: 'expired',
      detailHeaderCopy: '',
      flagIcon: '',
      flagColor: colorValues.cityblock,
    };
  }

  const {
    appointmentStatus,
    createdAt,
    hasPatientBeenContacted,
    patientContactedAt,
    clinic,
    wasRequested,
    appointmentType,
    isInitialEval,
  } = appointment;
  const patientAlias = loading ? '' : patientsSingularAlias;
  const hideCTA = !clinic?.isBetterAccessOnly || isBasic;

  const dateTimeAppointmentRequested = format(
    utcToZonedTime(
      new Date(createdAt ?? new Date()),
      clinic?.timeZone ?? 'America/New_York'
    ),
    'M/d @ h:mm a zzz',
    { timeZone: clinic?.timeZone ?? 'America/New_York' }
  );
  const contactedAt = hasPatientBeenContacted
    ? format(
        utcToZonedTime(
          new Date(patientContactedAt ?? new Date()),
          clinic?.timeZone ?? 'America/New_York'
        ),
        'M/d @ h:mm a zzz',
        { timeZone: clinic?.timeZone ?? 'America/New_York' }
      )
    : '';
  const requestDetails = hasPatientBeenContacted
    ? `${patientAlias} Contacted ${contactedAt}`
    : `${
        wasRequested ? 'Requested' : 'Booked'
      } ${dateTimeAppointmentRequested}`;
  const apptTypeField = parseAppointmentTypeField({
    apptType: appointmentType,
    wasRequested,
    isInitialEval,
    facilitiesSingularAlias,
  });
  const schedulingActivity = appointment.assignedEmployeeId
    ? `Assigned to ${
        appointment.assignedEmployeeId === meQuery.data?.me?.id
          ? 'me'
          : `${appointment.assignedEmployee?.firstName} ${appointment.assignedEmployee?.lastName}`
      }`
    : 'No scheduler assigned to appointment';

  if (appointmentStatus === 'Requested') {
    // REQUEST
    return {
      appointmentType: apptTypeField,
      schedulingActivity,
      appointmentDetails: requestDetails,
      bigButtonCopy: 'REQUEST',
      smallButtonCopy: 'APPT. REQUEST',
      headerCopy: 'APPOINTMENT REQUEST',
      stylingStatus: 'request',
      detailHeaderCopy: 'Appointment Request',
      flagIcon: <IcWaving />,
      flagColor: colorValues.ibizateal,
    };
  } else if (appointmentStatus === 'Upcoming') {
    // UPCOMING
    return {
      appointmentType: apptTypeField,
      schedulingActivity,
      appointmentDetails: `Confirmed`,
      bigButtonCopy: 'UPCOMING APPOINTMENT',
      smallButtonCopy: 'UPCOMING APPT.',
      headerCopy: 'UPCOMING APPOINTMENT',
      stylingStatus: 'booked',
      detailHeaderCopy: 'Upcoming Appointment',
      flagIcon: (
        <IcDateRange
          height='24px'
          width='24px'
          color={colorValues.eveningsky}
        />
      ),
      flagColor: colorValues.frost,
    };
  } else if (appointmentStatus === 'PendingTextConfirmation') {
    // PENDING TEXT
    return {
      appointmentType: apptTypeField,
      schedulingActivity,
      appointmentDetails: `Pending ${patientAlias} Text Response — ${requestDetails}`,
      bigButtonCopy: `PENDING ${patientAlias.toUpperCase()} TEXT CONFIRMATION`,
      smallButtonCopy: `PENDING TEXT CONF.`,
      headerCopy: `APPOINTMENT PENDING ${patientAlias.toUpperCase()} CONFIRMATION`,
      stylingStatus: 'pendingTextConfirmation',
      detailHeaderCopy: 'Appointment Request',
      flagIcon: <IcSmartphone color={colorValues.emptiness} />,
      flagColor: colorValues.cityblock,
    };
  } else if (appointmentStatus === 'Expired') {
    // EXPIRED
    return {
      appointmentType: apptTypeField,
      schedulingActivity,
      appointmentDetails: requestDetails,
      bigButtonCopy: 'EXPIRED (REQUEST NEVER ADDRESSED)',
      smallButtonCopy: 'EXPIRED',
      headerCopy: 'EXPIRED APPOINTMENT REQUEST',
      stylingStatus: 'expired',
      detailHeaderCopy: 'Expired Appointment Request',
      flagIcon: <IcClose />,
      flagColor: colorValues.cityblock,
    };
  } else if (appointmentStatus === 'Declined') {
    // DENIED
    return {
      appointmentType: apptTypeField,
      schedulingActivity,
      appointmentDetails: `Declined`,
      bigButtonCopy: 'DECLINED BY PROVIDER',
      smallButtonCopy: 'DECLINED',
      headerCopy: 'DECLINED APPOINTMENT REQUEST',
      stylingStatus: 'denied',
      flagIcon: <IcClose />,
      detailHeaderCopy: 'Declined Appointment Request',
      flagColor: colorValues.orangepink,
    };
  } else if (appointmentStatus === 'Cancelled') {
    // CANCELLED
    return {
      appointmentType: apptTypeField,
      schedulingActivity,
      appointmentDetails: `Cancelled`,
      bigButtonCopy: 'CANCELLED APPOINTMENT',
      smallButtonCopy: 'CANCELLED APPT.',
      headerCopy: 'CANCELLED APPOINTMENT',
      stylingStatus: 'cancelled',
      detailHeaderCopy: 'Cancelled Appointment',
      flagIcon: <IcClose />,
      flagColor: colorValues.orangepink,
    };
  } else if (appointmentStatus === 'Completed') {
    // COMPLETED
    return {
      appointmentType: apptTypeField,
      schedulingActivity,
      appointmentDetails: `Past`,
      bigButtonCopy: 'PAST APPOINTMENT',
      smallButtonCopy: 'PAST APPT.',
      headerCopy: 'PAST APPOINTMENT',
      stylingStatus: 'completed',
      detailHeaderCopy: 'Past Appointment',
      flagIcon: <IcSuccess color={colorValues.guajirogreen} />,
      flagColor: colorValues.fadedgreen,
    };
  } else if (appointmentStatus === 'NoShow') {
    // NO SHOW
    return {
      appointmentType: apptTypeField,
      schedulingActivity,
      appointmentDetails: requestDetails,
      bigButtonCopy: 'NO SHOW',
      smallButtonCopy: 'NO SHOW',
      headerCopy: 'NO SHOW APPOINTMENT',
      detailHeaderCopy: 'No Show Appointment',
      stylingStatus: 'no-show',
      flagIcon: <IcMore />,
      flagColor: colorValues.peruvianlily,
    };
  } else {
    // UNACCOUTNED FOR CASE. SHOULD NOT HAPPEN
    return {
      appointmentType: 'NOT ACCOUNTED FOR',
      schedulingActivity,
      appointmentDetails: `NOT ACCOUNTED FOR`,
      bigButtonCopy: 'NOT ACCOUNTED FOR',
      smallButtonCopy: 'NOT ACCOUNTED FOR',
      headerCopy: 'NOT ACCOUNTED FOR',
      stylingStatus: 'expired',
      detailHeaderCopy: 'Not Accounted For',
      flagIcon: '',
      flagColor: colorValues.cityblock,
    };
  }
};

export const AppointmentStatus = ({
  appointment,
  detailPage,
  isLarge,
  isBasic,
}: {
  appointment: Appointment;
  detailPage?: boolean;
  isLarge?: boolean;
  isBasic?: boolean;
}) => {
  const { bigButtonCopy, smallButtonCopy, stylingStatus } = useAppointmentCopy(
    appointment,
    detailPage,
    isBasic
  );
  const facilityHasBeenMigrated = !!appointment?.clinic?.isBetterAccessOnly;
  const copy = isLarge ? bigButtonCopy : smallButtonCopy;
  const requested = appointment?.appointmentStatus === 'Requested';

  return (
    <StatusStyle
      status={stylingStatus}
      clickable={requested && facilityHasBeenMigrated && !isBasic}
      isLarge={isLarge}
      onClick={(e) => {
        if (!requested) e.stopPropagation();
      }}
      requestDetail={requested && detailPage}
      style={facilityHasBeenMigrated ? {} : { margin: 0, padding: 0 }}
      data-cy='in-person-request-button'
    >
      <p>{copy}</p>
    </StatusStyle>
  );
};