import { useGetTableauTokenForEmployeeLazyQuery, useMeQuery } from '../../../generated/graphql';
import { gql } from '@apollo/client/core';
import { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { ReactElement } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Animation, Header } from '@betterpt/better-components';
import BlockedPageMessage from '../../Shared/BlockedPageMessage';
import { GridCard } from '../../Shared/GridCard';
import { Page, Headertext } from './EmbeddedAnalytics.style';

import { ImgAdminComputer } from '@betterpt/better-icons';

const SomethingWentWrongCard = () => (
  <Page>
    <BlockedPageMessage
      icon={<ImgAdminComputer />}
      title="Something’s Gone Wrong"
      message={`Whoops! Something’s gone wrong. Please wait a few minutes and refresh the page. If the problem persists, please contact Customer Support.`}
      hideButton={true}
      style={{ height: '80vh', minHeight: 600, width: '100%' }}
    />
  </Page>
);

// Query for Tableau Token
export const GetTableauTokenQuery = gql`
  query GetTableauTokenForEmployee($input: GetTableauTokenForEmployeeInput) {
    getTableauTokenForEmployee(input: $input)
  }
`;

// Props
type TableauProps = {
  dashboardPath: string; // This is the dashboard path in the url
  companyId?: string; // Get this from the "me" query
};

/**
 * Renders an embedded Tableau dashboard
 * @param props
 * @returns
 */
function TableauEmbed(props: { dashboardPath: string }): ReactElement<TableauProps, any> {
  // Hooks

  // Queries

  /**
   * This is the "me" query that is used to get the user's email and company id.
   */
  const { data: meQueryData, error: meQueryError, loading: meQueryLoading } = useMeQuery();
  // const { data: tableauToken, error: tableauQueryError, loading: tableauQueryLoading } = useGetTableauTokenForEmployeeQuery();
  const { useFakeCompanyData } = useFlags();

  let loadedToken;
  const [token, setToken] = useState('');
  const [viz, setViz]: [any, any] = useState();
  /**
   * This is a lazy query that is only performed if the user has access to Tableau.
   */
  const [executeQuery, response] = useGetTableauTokenForEmployeeLazyQuery({
    variables: {
      input: {
        email: meQueryData?.me?.email as string,
        employeeId: meQueryData?.me?.id as string,
      },
    },
    fetchPolicy: 'no-cache', // This is needed to ensure the token is always fresh
    onCompleted(data) {
      loadedToken = data?.getTableauTokenForEmployee;
      setToken(data?.getTableauTokenForEmployee as string);
      loadViz();
    },
  });

  /**
   * This is a ref to the Tableau web component. This is used to call methods on the web component.
   */
  const vizRef = useRef(null);

  const loadViz = () => {
    setViz(
      <tableau-viz
        ref={vizRef}
        id="tableauViz"
        src={`https://prod-useast-a.online.tableau.com/t/betterhealthcare/views/${props.dashboardPath}`}
        device={'desktop'}
        hide-tabs={false}
        token={token || loadedToken}
        toolbar={'hidden'}
      >
        {/* <viz-filter field="CompanyId" value={useFakeCompanyData ? '3692' : meQueryData?.me?.company?.id ?? ''} /> */}
        <viz-parameter name="CompanyId" value={useFakeCompanyData ? '3692' : meQueryData?.me?.company?.id ?? ''} />
      </tableau-viz>
    );
  };
  // Loads the Tableau web component. Loading the web component is done in a separate function so that it can be called again if the token || loadedToken changes.

  // Effects

  /**
   * This effect is used to check if the user has access to Tableau. If they do, then the lazy query is executed.
   * If they don't, then a message is displayed in the console.
   * TODO: Display something in the UI to let the user know they don't have access to Tableau.
   * **/
  useEffect(() => {
    if (meQueryData?.me?.isUsingTableau === true) {
      // lazy query is executed
      executeQuery();
    } else {
      console.log('User does not have access to Tableau');
    }
  }, [meQueryData?.me, executeQuery]);

  if (meQueryError || response.error) return SomethingWentWrongCard();
  return !meQueryLoading || !response.loading ? (
    <GridCard style={{ margin: '30px 30px', minWidth: 1200, minHeight: 1000 }}>
      <Header style={Headertext}>Analytics Dashboard</Header>
      <hr />
      <div style={{ paddingLeft: '20px' }}>
        <Helmet>
          <script
            type="module"
            src="https://prod-useast-a.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js"
            async
          ></script>
        </Helmet>
        {viz}
      </div>
    </GridCard>
  ) : (
    <Animation type="providerAppLoader" />
  );
}

export default TableauEmbed;
