import React from 'react';

import { Switch } from '@betterpt/better-components';
import useRemoteConfigAliases from '../../../hooks/useRemoteConfigAliases';

type Props = Parameters<typeof Switch>[0];
const SwitchCustom: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { primaryColor } = useRemoteConfigAliases();

  return <Switch customColor={primaryColor} {...props} />;
};

export default SwitchCustom;
