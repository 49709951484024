import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={color || '#333'}
          fillRule="nonzero"
          d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"
        />
        <path d="M0 0h24v24H0z" />
      </g>
    </svg>
  );
