import { jsx } from '@emotion/react';
import styled from '@emotion/styled';

// Types
import { Toolbar, Icons } from '../types';

// Styles
import { mainSection } from './style';
import SearchBar from './SearchBar';

// Toolbar component will be expanded to add more items like info boxes, text or links

const Div = styled.div``;

type Props = {
  onSearchChange?: (searchText: string) => void;
};
export default ({
  title,
  onSearchChange,
  ...rest
}: Partial<Toolbar> & Partial<Icons> & Props) => (
  <Div css={mainSection}>
    {rest.CTA && rest.searchSide === 'right' && rest.CTA}
    {(onSearchChange && rest.searchSide === 'left' && (
      <SearchBar onSearchChange={onSearchChange} {...rest} />
    )) ||
      null}
    {(typeof title === 'string' && (
      <h1 style={{ margin: '0 10px 0 10px' }}>{title}</h1>
    )) ||
      title || <div />}
    {(onSearchChange && (rest.searchSide === 'right' || !rest.searchSide) && (
      <SearchBar onSearchChange={onSearchChange} {...rest} />
    )) || <div />}
    {rest.CTA &&
      (rest.searchSide === 'left' || !rest.searchSide || !onSearchChange) &&
      rest.CTA}
  </Div>
);
