import * as React from 'react';
import { datadogRum } from '@datadog/browser-rum';

// types
import { ConfirmOrDenyStatus } from '../../../../../../generated/graphql';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

// helpers
import { utcToZonedTime } from 'date-fns-tz';
import { normalizeTimestamp } from '../../../Shared/utils';

//hooks
import {
  useInPersonAppointmentDetailsQuery,
  useUpdateInPersonAppointmentConfirmationStatusMutation,
  useUpdateInPersonAppointmentStartTimeMutation,
} from '../../../../../../generated/graphql';
import useHandleError from '../../../../../../hooks/useHandleError';

//components
import Dialog from '@material-ui/core/Dialog';
import { ReschedulePage } from '../../../Shared/ConfirmOrReschedulePage';
import { Animation } from '@betterpt/better-components';
import SlideTransition from '../../../../../Shared/SlideTransition';

type Props = {
  open: boolean;
  onClose: () => void;
  appointmentId?: string;
  onDoneChangingOptionsAppointment: () => Promise<any> | undefined;
  isRequest?: boolean;
};

interface Clinic {
  clinicName?: string | null | undefined;
  id?: string | null | undefined;
  isBetterAccessOnly?: boolean | null | undefined;
  timeZone?: string | null | undefined;
}

const RescheduleAppointmentDialog = ({ open, onClose, appointmentId, onDoneChangingOptionsAppointment, isRequest }: Props) => {
  // hooks

  const [saving, updateSaving] = React.useState(false);
  const [date, updateDate] = React.useState<MaterialUiPickersDate | null>(null);
  const [time, updateTime] = React.useState<MaterialUiPickersDate | null>(null);
  const [employeeId, updateEmployeeId] = React.useState<string>('');
  const [clinicId, updateClinicId] = React.useState<string>('');
  const [clinicData, updateClinicData] = React.useState<Clinic>({
    clinicName: '',
    id: '',
    isBetterAccessOnly: false,
    timeZone: '',
  });

  const [confirmAppointment, confirmOptions] = useUpdateInPersonAppointmentConfirmationStatusMutation();

  const [updateAppointmentStart, updateStartOptions] = useUpdateInPersonAppointmentStartTimeMutation();
  const handleError = useHandleError();

  const appointmentQuery = useInPersonAppointmentDetailsQuery({
    variables: { id: appointmentId! },
    skip: !appointmentId,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  React.useEffect(() => {
    const existingEmployeeId = appointmentQuery.data?.appointment?.employee?.id;
    const existingClinicId = appointmentQuery.data?.appointment?.clinicId;
    const existingClinicData = appointmentQuery.data?.appointment?.clinic;

    if (existingEmployeeId) {
      updateEmployeeId(existingEmployeeId);
    }
    if (existingClinicId) {
      updateClinicId(existingClinicId);
    }
    if (existingClinicData) {
      updateClinicData({ ...existingClinicData });
    }
    const appointmentTime = appointmentQuery.data?.appointment?.confirmedTime || appointmentQuery.data?.appointment?.startTime;
    const zonedTime = utcToZonedTime(
      new Date(appointmentTime ?? new Date()),
      appointmentQuery.data?.appointment?.timeZone ?? 'America/New_York'
    );
    updateDate(zonedTime);
    updateTime(zonedTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentQuery.data]);

  const sendDataDogAction = ({ isReschedule }: { isReschedule: boolean }) => {
    datadogRum.addUserAction(isReschedule ? 'reschedule_in_person_appointment' : 'update_time_and_confirm_in_person_appointment', {
      clinicId: appointmentQuery.data?.appointment?.clinicId,
      employeeId: appointmentQuery.data?.appointment?.employee?.id,
      patientId: appointmentQuery.data?.appointment?.patient?.id,
    });
  };

  const handleRescheduleSubmit = async () => {
    if (!appointmentId || !date || !time) return;
    updateSaving(true);
    try {
      if (isRequest) {
        // confirm...
        await confirmAppointment({
          variables: {
            input: {
              appointmentId: appointmentId!,
              confirmationStatus: ConfirmOrDenyStatus.Confirmed,
              employeeId,
              confirmedTime: normalizeTimestamp(date, time, appointmentQuery.data?.appointment?.timeZone ?? 'America/New_York'),
              clinicId,
            },
          },
        });

        sendDataDogAction({ isReschedule: false });
      } else {
        //just reschedule
        await updateAppointmentStart({
          variables: {
            input: {
              appointmentId: appointmentId!,
              clinicId,
              employeeId,
              startTime: normalizeTimestamp(date, time, appointmentQuery.data?.appointment?.timeZone ?? 'America/New_York'),
            },
          },
        });
        sendDataDogAction({ isReschedule: true });
      }
      setTimeout(async () => {
        await onDoneChangingOptionsAppointment();
        updateSaving(false);
        onClose();
      }, 1000);
    } catch (e) {
      updateSaving(false);
      handleError(e);
      return;
    }
  };

  const renderContent = () => {
    if (appointmentQuery.loading) {
      return <Animation type="providerAppLoader" />;
    } else {
      return (
        <ReschedulePage
          isRequest={isRequest}
          appointmentId={appointmentId}
          handleRescheduleSubmit={handleRescheduleSubmit}
          closeDialog={onClose}
          saving={saving || updateStartOptions.loading || confirmOptions.loading}
          employee={{
            value: employeeId,
            updateValue: updateEmployeeId,
          }}
          clinic={{
            value: clinicData,
            updateValue: updateClinicId,
          }}
          date={{
            value: date,
            updateValue: updateDate,
          }}
          time={{
            value: time,
            updateValue: updateTime,
          }}
        />
      );
    }
  };

  return (
    <Dialog
      TransitionComponent={SlideTransition}
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          overflow: 'visible',
        },
      }}
    >
      {renderContent()}
    </Dialog>
  );
};

export default RescheduleAppointmentDialog;
