import * as React from 'react';
import PatientForm from '../components/PatientForm';
import { Card } from '@betterpt/better-components';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';

const CreatePatient = () => {
  const { patientsSingularAlias } = useRemoteConfigAliases();
  return (
    <div style={{ padding: '25px 35px' }}>
      <h1 className='Hero' style={{ paddingBottom: '25px' }}>
        Add {patientsSingularAlias}
      </h1>
      <Card
        style={{
          width: '650px',
        }}
      >
        <PatientForm />
      </Card>
    </div>
  );
};

export default CreatePatient;
