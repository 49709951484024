import React from 'react';

//types
import {
  Appointment,
  VideoAppointment,
  VideoConfirmationStatus,
  VideoAppointmentStatus,
} from '../generated/graphql';

//helpers
import { format, utcToZonedTime } from 'date-fns-tz';
import { addDays, isAfter } from 'date-fns';
import { colorValues, changeHexColor } from '@betterpt/better-components';
import { parseAppointmentTypeField } from '../helpers';

//hooks
import useRemoteConfigAliases from '../hooks/useRemoteConfigAliases';

//styles
import styled from '@emotion/styled';
import {
  generateFrequencyCopy,
  generateIntervalCopy,
  generateFormattedEndDate,
} from './utils';

type StylingStatus =
  | 'no-show'
  | 'booked'
  | 'completed'
  | 'cancelled'
  | 'denied'
  | 'pending'
  | 'request'
  | 'expired'
  | 'pendingTextConfirmation';

export const appointmentColorMap = new Map<StylingStatus, [string, string]>([
  ['no-show', [colorValues.cauldronblack, colorValues.playa]],
  ['booked', [colorValues.eveningsky, colorValues.frost]],
  ['completed', [colorValues.guajirogreen, colorValues.fadedgreen]],
  ['cancelled', [colorValues.messyketchup, colorValues.papaya]],
  ['denied', [colorValues.messyketchup, colorValues.papaya]],
  ['pending', [colorValues.emptiness, colorValues.cityblock]],
  ['expired', [colorValues.emptiness, colorValues.cityblock]],
  ['pendingTextConfirmation', [colorValues.emptiness, colorValues.cityblock]],
  ['request', [colorValues.emptiness, colorValues.ibizateal]],
]);

export const StatusStyle = styled.div<{
  status: StylingStatus;
  isLarge?: boolean;
  clickable?: boolean;
  requestDetail?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ status }) => appointmentColorMap.get(status)?.[1]};
  height: ${({ isLarge }) => (isLarge ? '36px' : '28px')};
  min-width: ${({ isLarge }) => (isLarge ? '0px' : '160px')};
  width: ${({ requestDetail }) => (requestDetail ? 'auto' : '100%')};
  border-radius: 5px;
  padding: ${({ isLarge }) => (isLarge ? '0px 10px' : '0px')};
  margin: ${({ isLarge, requestDetail }) =>
    isLarge && !requestDetail ? '0px 10px' : '0px'};
  text-align: center;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  flex-grow: 1;
  &:hover,
  &:focus {
    background-color: ${({ clickable, status }) =>
      clickable
        ? changeHexColor(
            appointmentColorMap.get(status)?.[1] || colorValues.ibizateal,
            -15
          )
        : appointmentColorMap.get(status)?.[1]};
  }
  p {
    margin: 0;
    color: ${({ status }) => appointmentColorMap.get(status)?.[0]};
    font-weight: bold;
    font-size: 12px;
    text-align: center;
  }
`;

export const statusDictionary = new Map([
  ['booked', 'Upcoming'],
  ['completed', 'Complete'],
  ['cancelled', 'Cancelled'],
]);

export const formatDuration = (appointment?: any, isPatient?: boolean) => {
  const duration = isPatient
    ? appointment?.patientDuration
    : appointment?.providerDuration;
  if (duration) {
    if (duration < 60) return `${duration} sec`;

    const totalMinutes = Math.ceil(duration / 60);
    const hourCount = totalMinutes > 60 ? Math.floor(totalMinutes / 60) : 0;
    const minCount = totalMinutes > 60 ? totalMinutes % 60 : totalMinutes;

    const hrUnit = ` hr${hourCount > 1 ? 's' : ''}`;
    const minUnit = ` min${minCount > 1 ? 's' : ''}`;

    return `${!!hourCount ? hourCount + hrUnit : ''}${
      !!minCount ? minCount + minUnit : ''
    }`;
  }
};

export const appointmentStatusBooleans = (
  appointment?: VideoAppointment | null
) => {
  const isInitialEval = appointment?.isInitialEval;
  const pending =
    appointment?.confirmationStatus === 'pending' &&
    appointment?.status === 'booked';
  const expiredRequest =
    appointment?.confirmationStatus === 'pending' &&
    appointment?.status === 'completed';
  const upcoming =
    appointment?.status === 'booked' &&
    appointment?.confirmationStatus === 'confirmed';
  const declined = appointment?.confirmationStatus === 'denied';
  const completed = appointment?.status === 'completed';
  const cancelled = appointment?.status === 'cancelled';
  const noShow =
    completed &&
    (!appointment?.didPatientAttend || !appointment?.didProviderAttend);
  const noShowPatient = noShow && !appointment?.didPatientAttend;
  const noShowProvider = noShow && !appointment?.didProviderAttend;
  const awaitingPatientReply =
    appointment?.patientActionableReminderStatus === 'sent';

  const patientCancelled =
    appointment?.patientActionableReminderStatus === 'cancelled';

  const patientConfirmed =
    appointment?.patientActionableReminderStatus === 'confirmed';

  const isRecurringAppointment = appointment?.recurringSeries?.isActive;

  return {
    isInitialEval,
    pending,
    expiredRequest,
    declined,
    completed,
    upcoming,
    cancelled,
    noShowPatient,
    noShowProvider,
    awaitingPatientReply,
    patientCancelled,
    patientConfirmed,
    isRecurringAppointment,
  };
};

export const mapStatusForStyling = (
  appointment?: Appointment | VideoAppointment | null
) => {
  const {
    noShowPatient,
    noShowProvider,
    awaitingPatientReply,
    patientCancelled,
    patientConfirmed,
    cancelled,
    pending,
    expiredRequest,
    declined,
  } = appointmentStatusBooleans(appointment as VideoAppointment);
  const noShow = noShowPatient || noShowProvider;

  if (expiredRequest) return 'expired';
  else if (noShow) return 'no-show';
  else if (patientCancelled || cancelled || declined) return 'cancelled';
  else if (pending) return 'request';
  else if (awaitingPatientReply) return 'pending';
  else if (patientConfirmed) return 'booked';
  else return appointment?.status as StylingStatus;
};

export const AppointmentStatus = ({
  appointment,
  isLarge,
  copy,
  facilityHasBeenMigrated,
}: {
  appointment?: VideoAppointment | null;
  isLarge?: boolean;
  copy?: string;
  facilityHasBeenMigrated?: boolean;
}) => {
  const { patientsSingularAlias } = useRemoteConfigAliases();

  const generateVideoAppointmentCopy = (
    appointment?: VideoAppointment | null,
    copy?: string,
    isLarge?: boolean
  ) => {
    const {
      completed,
      pending,
      expiredRequest,
      upcoming,
      noShowPatient,
      noShowProvider,
      awaitingPatientReply,
      patientCancelled,
      patientConfirmed,
      cancelled,
      declined,
    } = appointmentStatusBooleans(appointment);

    if (copy) {
      return copy;
    } else if (pending) {
      return isLarge ? 'REQUEST —  CLICK TO VIEW' : 'REQUEST';
    } else if (declined) {
      return 'DECLINED';
    } else if (expiredRequest) {
      return isLarge ? 'EXPIRED (REQUEST NEVER ADDRESSED)' : 'REQUEST EXPIRED';
    } else if (noShowProvider && !noShowPatient) {
      return `NO SHOW (PROVIDER)`;
    } else if (noShowPatient && !noShowProvider) {
      return `NO SHOW (${patientsSingularAlias.toUpperCase()})`;
    } else if (noShowProvider && noShowPatient) {
      return `NO SHOW (BOTH)`;
    } else if (completed) {
      return `COMPLETE ${
        isLarge ? `(${formatDuration(appointment)?.toUpperCase()})` : ''
      }`;
    } else if (cancelled) {
      return 'CANCELLED';
    } else if (awaitingPatientReply) {
      return 'PENDING TEXT CONF.';
    } else if (patientCancelled) {
      return 'CANCELLED VIA TEXT';
    } else if (patientConfirmed) {
      return 'CONFIRMED VIA TEXT';
    } else if (upcoming) {
      return 'UPCOMING';
    } else {
      return statusDictionary.get(appointment?.status ?? '')?.toUpperCase();
    }
  };

  const generatedCopy = generateVideoAppointmentCopy(
    appointment,
    copy,
    isLarge
  );
  const status = mapStatusForStyling(appointment);
  const pending =
    appointment?.confirmationStatus === VideoConfirmationStatus.Pending &&
    appointment?.status === VideoAppointmentStatus.Booked;

  return (
    <StatusStyle
      status={status}
      isLarge={isLarge}
      onClick={(e) => {
        if (!pending) e.stopPropagation();
      }}
      style={facilityHasBeenMigrated ? {} : { margin: 0, padding: 0 }}
    >
      <p>{generatedCopy}</p>
    </StatusStyle>
  );
};

export const useAppointmentStatusForCards = (
  appointment?: VideoAppointment | null
) => {
  const {
    employeeSingularAlias,
    facilitiesSingularAlias,
    patientsSingularAlias,
  } = useRemoteConfigAliases();
  const {
    completed,
    upcoming,
    noShowPatient,
    noShowProvider,
    awaitingPatientReply,
    patientCancelled,
    patientConfirmed,
    cancelled,
    pending,
    expiredRequest,
    declined,
    isInitialEval,
  } = appointmentStatusBooleans(appointment);

  const apptType = parseAppointmentTypeField({
    apptType: appointment?.appointmentType,
    wasRequested: appointment?.wasRequested,
    isInitialEval,
    facilitiesSingularAlias,
    isTelehealth: true,
  });

  if (pending) {
    return {
      button: 'REQUEST — CLICK TO VIEW',
      field: apptType,
    };
  } else if (expiredRequest) {
    return {
      button: 'EXPIRED (REQUEST NEVER ADDRESSED)',
      field: apptType,
    };
  } else if (declined) {
    return {
      button: 'DECLINED',
      field: apptType,
    };
  } else if (noShowProvider && !noShowPatient) {
    return {
      button: `NO SHOW (${employeeSingularAlias.toUpperCase()})`,
      field: apptType,
    };
  } else if (noShowPatient && !noShowProvider) {
    return {
      button: `NO SHOW (${patientsSingularAlias.toUpperCase()})`,
      field: apptType,
    };
  } else if (noShowProvider && noShowPatient) {
    return {
      button: 'NO SHOW',
      field: apptType,
    };
  } else if (completed) {
    return {
      button: 'COMPLETE',
      field: `${apptType} — ${formatDuration(appointment)}`,
    };
  } else if (cancelled) {
    return {
      button: `CANCELLED`,
      field: apptType,
    };
  } else if (awaitingPatientReply) {
    return {
      button: `${
        completed ? 'STARTED' : 'UPCOMING'
      } (PENDING ${patientsSingularAlias.toUpperCase()} TEXT)`,
      field: apptType,
    };
  } else if (patientCancelled) {
    return {
      button: `${patientsSingularAlias.toUpperCase()} CANCELLED VIA TEXT`,
      field: apptType,
    };
  } else if (patientConfirmed) {
    return {
      button: `${
        completed ? 'STARTED' : 'UPCOMING'
      } (${patientsSingularAlias.toUpperCase()} CONFIRMED VIA TEXT)`,
      field: apptType,
    };
  } else if (upcoming) {
    return {
      button: 'UPCOMING',
      field: apptType,
    };
  } else {
    return {
      button: statusDictionary.get(appointment?.status ?? '')?.toUpperCase(),
      field: apptType,
    };
  }
};

export const appointmentDetailsForCards = (
  appointment: VideoAppointment | null
) => {
  const {
    pending,
    declined,
    upcoming,
    patientCancelled,
    cancelled,
    isRecurringAppointment,
  } = appointmentStatusBooleans(appointment);

  const time = (timeToFormat: any, consent?: boolean) => {
    return timeToFormat
      ? format(
          utcToZonedTime(
            new Date(timeToFormat ?? new Date()),
            appointment?.clinic?.timeZone ?? ''
          ),
          `${consent ? '' : 'M/d '}' @ 'h:mm a`
        )
      : '';
  };

  if (declined) {
    return `Declined`;
  } else if (appointment?.patientAcceptedConsentForm) {
    return `Patient Consent ${time(
      appointment.patientAcceptedConsentFormAt,
      true
    )}`;
  } else if (patientCancelled || cancelled) {
    return `Cancelled`;
  } else if (pending) {
    return `Requested ${time(appointment?.createdAt)}`;
  } else if (upcoming) {
    if (isRecurringAppointment) {
      const recurringSeries = appointment!.recurringSeries!;
      return `Recurs every ${generateIntervalCopy(
        recurringSeries
      )} ${generateFrequencyCopy(
        recurringSeries
      )} until ${generateFormattedEndDate(recurringSeries)}`;
    } else {
      return `Booked ${time(appointment?.createdAt)}`;
    }
  } else return `Booked ${time(appointment?.createdAt)}`;
};

export const appointmentStatusString = (
  appointment: any,
  employeeString: string,
  patientString: string
) => {
  const completed = appointment?.status === 'completed';
  const pending =
    appointment?.status === 'booked' &&
    appointment?.confirmationStatus === 'pending';
  const expiredRequest =
    completed && appointment?.confirmationStatus === 'pending';
  const declined = appointment?.confirmationStatus === 'denied';
  const noShow =
    completed &&
    (!appointment?.didPatientAttend || !appointment?.didProviderAttend);
  const cancelled = appointment?.status === 'cancelled';
  const awaitingPatientReply =
    appointment?.patientActionableReminderStatus === 'sent';
  const patientCancelled =
    appointment?.patientActionableReminderStatus === 'cancelled';
  const patientConfirmed =
    appointment?.patientActionableReminderStatus === 'confirmed';

  const copy = (() => {
    if (declined) {
      return 'Declined Appointment Request';
    } else if (noShow && appointment?.didPatientAttend) {
      return `No show-${employeeString}`;
    } else if (noShow && appointment?.didProviderAttend) {
      return `No show-${patientString}`;
    } else if (noShow) {
      return 'No show (Neither party attended)';
    } else if (pending) {
      return 'Appointment Request';
    } else if (expiredRequest) {
      return 'Expired Appointment Request';
    } else if (appointment?.status === 'completed') {
      return `Complete${
        formatDuration(appointment) ? ` (${formatDuration(appointment)})` : ''
      }`;
    } else if (awaitingPatientReply) {
      return 'SMS reminder sent';
    } else if (patientCancelled) {
      return 'Cancelled via text';
    } else if (cancelled) {
      return 'Cancelled';
    } else if (patientConfirmed) {
      return 'Confirmed via text';
    } else {
      return statusDictionary.get(appointment?.status ?? '');
    }
  })();

  const header = (() => {
    if (declined) {
      return 'Declined';
    } else if (noShow && appointment?.didPatientAttend) {
      return `${employeeString} No Show`;
    } else if (noShow && appointment?.didProviderAttend) {
      return `${patientString} No Show`;
    } else if (noShow) {
      return 'No Show';
    } else if (pending) {
      return 'Request';
    } else if (appointment?.status === 'completed') {
      return 'Completed';
    } else if (awaitingPatientReply) {
      return 'Pending';
    } else if (patientCancelled || cancelled) {
      return 'Cancelled';
    } else if (patientConfirmed) {
      return 'Confirmed via Text';
    } else {
      return statusDictionary.get(appointment?.status ?? '');
    }
  })();

  return { copy, header };
};

const findWhenRecordingExpires = (appointment: any) =>
  appointment?.startTime
    ? format(
        utcToZonedTime(
          new Date(addDays(new Date(appointment?.startTime ?? null), 30)),
          appointment?.clinic?.timeZone ?? 'America/New_York'
        ),
        'M/d'
      )
    : null;

export const recordingHasExpired = (appointment: any) =>
  appointment?.startTime
    ? isAfter(
        new Date(),
        new Date(addDays(new Date(appointment?.startTime ?? null), 30))
      )
    : true;

export const recordingStatusCopy = (appointment: any, loading?: boolean) => {
  const recordingExpired = recordingHasExpired(appointment);
  const dateRecordingExpires = findWhenRecordingExpires(appointment);

  if (loading) {
    return 'Sending email with link...';
  } else if (appointment?.isRecordingAvailable) {
    if (recordingExpired) {
      return `Recording unavailable (Expired ${dateRecordingExpires ??
        'after 30 days'})`;
    } else {
      return `Session recorded (Expires ${dateRecordingExpires ??
        'after 30 days'})`;
    }
  } else return 'Recording not available';
};

export const generateOptionMenuTitle = (
  appointment?: Appointment | VideoAppointment | null
) => {
  const status = mapStatusForStyling(appointment);
  switch (status) {
    case 'no-show':
      return 'NO SHOW';
    case 'booked':
    case 'pending':
      return 'UPCOMING APPOINTMENT';
    case 'completed':
      return 'COMPLETED APPOINTMENT';
    case 'denied':
      return 'DECLINED REQUEST';
    case 'request':
      return 'APPOINTMENT REQUEST';
    case 'expired':
      return 'EXPIRED REQUEST';
    default:
      return '';
  }
};
