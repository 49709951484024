import * as React from 'react';
import { Lottie, ReactLottieOwnProps } from '@crello/react-lottie';
import spinner from './JSON/spinner.json';
import heartfull from './JSON/heartfull.json';
import globespin from './JSON/globespin.json';
import alternateloader from './JSON/alternateloader.json';
import providerAppLoader from './JSON/providerAppLoader.json';

interface AnimationProp extends ReactLottieOwnProps {
  type:
    | 'spinner'
    | 'heartfull'
    | 'globespin'
    | 'alternateloader'
    | 'providerAppLoader';
}

export default ({
  height,
  width,
  type,
  config,
  style,
  ...rest
}: Partial<AnimationProp>) => {
  const animationData: any = {
    spinner,
    heartfull,
    globespin,
    alternateloader,
    providerAppLoader,
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: (type && animationData[type]) || spinner,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    ...config,
  };
  return (
    <Lottie
      style={{ margin: '0 auto', ...style }}
      config={defaultOptions}
      height={height || '200px'}
      width={width || '200px'}
      {...rest}
    />
  );
};
