import { jsx } from "@emotion/react";
import React, { useCallback, useRef } from "react";

//types
import { FilterOption } from "./";
import { ButtonColor } from "../Button/constants";

//helpers
import { bestTextColor } from "../helpers/functions";

//hooks
import { useDebounce } from "../helpers/hooks";

//components
import { FormControlLabel, Chip } from "@material-ui/core";

import Button from "../Button";
import Checkbox from "../Checkbox";
import InfoBox from "../InfoBox";
import TextField from "../TextField";

//graphics
import IcDropDownSelected from "../Icons/IcDropDownSelected";

//styles
import { colorValues } from "../helpers/colors";
import {
  FilterButton,
  FilterContainer,
  FilterLabel,
  OptionsContainer,
  OptionsList,
  RadioLabel,
  rotateStyles,
} from "./style";

type Props = {
  name: string;
  useSearch?: boolean;
  search?: { value: string; update: (searchText: string) => void };
  boxTitle?: string;
  buttonTitle: string;
  icon?: any;
  selectedOptions: string[];
  options: FilterOption[];
  hasMore?: boolean;
  onSubmit?: (selected: FilterOption[]) => void;
  onLoadMore?: () => void;
  loading?: boolean;
  complete?: boolean;
  rotateIcon?: boolean;
  applyButtonColor?: ButtonColor;
  applyButtonText?: string;
  overrideButtonColor?: string;
  customButton?: React.ReactNode;
  openStatus: { value: boolean; update: (open: boolean) => void };
  callOnOpen?: () => void;
  optionCallback?: (selected: FilterOption) => void;
  style?: {
    box?: React.CSSProperties;
    arrow?: React.CSSProperties;
    labelPadding?: string;
    buttonMargin?: string;
    [key: string]: any;
  };
};

const FilterScrollAndSearch = ({
  name,
  selectedOptions,
  search,
  useSearch = true,
  boxTitle,
  buttonTitle,
  icon,
  options,
  hasMore,
  onSubmit,
  loading,
  onLoadMore,
  complete,
  rotateIcon,
  applyButtonColor,
  applyButtonText,
  overrideButtonColor,
  customButton,
  openStatus,
  callOnOpen,
  optionCallback,
  style,
}: Props) => {
  let activeTimeout: any | undefined;
  let animationTimeout: any | undefined;
  const kebabCaseLabel = (option: FilterOption) =>
    option?.label?.toLowerCase()?.replace(/\s/g, "-");
  const startingVisibleOptions = options.filter((option) => !option?.hidden);

  const startingHeightBuffer = (options.length - 1) * 46;
  const defaultBoxMin = 260;
  const [boxHeight, setBoxHeight] = React.useState(
    defaultBoxMin + startingHeightBuffer
  );
  const observer = useRef<any>();
  const bestSearchTerm = useDebounce(search?.value ?? "", 500);
  const [rotate, setRotate] = React.useState(false);
  const [visibleOptions, setVisibleOptions] = React.useState(
    startingVisibleOptions
  );

  const sortOptions = (optionsToSort?: FilterOption[]) => {
    let newVisibleOptions = optionsToSort ?? [
      ...options.filter((option) => !option?.hidden),
    ];
    //account for the presence of a reset option, to keep it on top
    const [resetOption] = newVisibleOptions.filter(
      (option) => option?.isResetOption
    );
    //select the options that are NOT a reset option
    const mainOptions = newVisibleOptions.filter(
      (option) => !option?.isResetOption
    );
    //set which options are already selected, if any
    const selected: FilterOption[] = [];
    const notSelected: FilterOption[] = [];
    mainOptions.forEach((option) => {
      if (option?.selected) {
        selected.push(option);
      } else notSelected.push(option);
    });

    newVisibleOptions = resetOption
      ? [resetOption, ...selected, ...notSelected]
      : [...selected, ...notSelected];
    setVisibleOptions(newVisibleOptions);
  };

  const toggleCheckbox = (option: FilterOption) => {
    const workingOptions = [...visibleOptions];
    const updatedOptions = workingOptions.map((visibleOption) => {
      if (option?.isResetOption && !option?.selected) {
        //if you select the reset option, check it and uncheck everything else
        return {
          ...visibleOption,
          selected: visibleOption === option,
        };
      } else if (!option?.isResetOption) {
        //if you check a normal option, make sure the reset is unchecked
        if (visibleOption.isResetOption) {
          return {
            ...visibleOption,
            selected: false,
          };
        } else
          return {
            ...visibleOption,
            selected:
              visibleOption === option
                ? !visibleOption.selected
                : visibleOption.selected,
          };
      } else return visibleOption;
    });
    sortOptions(updatedOptions);
  };

  // reset visible options if main options change
  React.useEffect(() => {
    setVisibleOptions(options.filter((option) => !option?.hidden));
  }, [options]);

  //to handle lazy loading and/or search text state
  React.useEffect(() => {
    if (openStatus?.value) {
      callOnOpen?.();
    }
    if (!openStatus?.value) {
      search?.update?.("");
    }
  }, [openStatus?.value]);

  //monitor raw parent options for initial set up of:
  //options list, button title, and filter's box height (to account for hidden items)
  React.useEffect(() => {
    let computedHeightBuffer = (options.length - 1) * 46;
    if (startingVisibleOptions.length > 6) {
      computedHeightBuffer = 5 * 46;
    } else {
      for (let i = 0; i < options.length; i++) {
        if (options[i]?.hidden) {
          computedHeightBuffer -= 46;
        }
      }
    }
    sortOptions();
    setBoxHeight(defaultBoxMin + computedHeightBuffer);
  }, [options]);

  //handle search
  React.useEffect(() => {
    if (bestSearchTerm.length) {
      const searchResults = visibleOptions.filter(
        (option) =>
          option?.label?.toLowerCase().includes(bestSearchTerm.toLowerCase()) ||
          option?.fullTitle
            ?.toLowerCase()
            .includes(bestSearchTerm.toLowerCase())
      );
      sortOptions(searchResults);
    } else sortOptions();
  }, [bestSearchTerm, openStatus?.value]);

  //handle success animation
  React.useEffect(() => {
    if (complete) {
      activeTimeout = setTimeout(() => {
        openStatus?.update(false);
      }, 500);
    }
    //cleanup any lingering timeout effects on dismount
    return () => {
      if (activeTimeout) clearTimeout(activeTimeout);
    };
  }, [complete]);

  //handle rotate icon animation
  React.useEffect(() => {
    if (rotate && rotateIcon) {
      animationTimeout = setTimeout(() => {
        setRotate(false);
      }, 333);
    }
    //cleanup any lingering timeout effects on dismount
    return () => {
      if (animationTimeout) clearTimeout(animationTimeout);
    };
  }, [rotate]);

  const handleApplyFilter = () => {
    const selectedOptions = visibleOptions.filter(
      (option) => option?.selected && !option?.isResetOption
    );
    onSubmit?.(selectedOptions);
    search?.update?.("");
    openStatus?.update(false);
  };

  const applyButtonStyle = () => {
    const style: any = {
      height: 36,
      width: 255,
      margin: "20px 25px 0",
    };
    if (overrideButtonColor) {
      style.backgroundColor = overrideButtonColor;
      style.color = bestTextColor(overrideButtonColor);
    }
    return style;
  };

  const Chips = ({ title }: { title: string }) => (
    <Chip
      style={{
        color: colorValues.eveningsky,
        backgroundColor: colorValues.frost,
        padding: "0px 5px 0px 5px",
        margin: "2px 2px 2px 2px",
      }}
      size="small"
      label={title}
    />
  );

  const renderTags = () => {
    const tagOptions = (
      <div>
        {selectedOptions.slice(0, 4).map((option) => (
          <Chips key={option} title={option} />
        ))}
        {!!selectedOptions.slice(4).length && (
          <Chips title={`+${selectedOptions.slice(4).length} more`} />
        )}
      </div>
    );
    return tagOptions;
  };

  const lastOptionRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          onLoadMore?.();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const renderOptions = () => {
    const optionsBody = (
      <OptionsList>
        {visibleOptions.map(
          (option: FilterOption, index: number) =>
            !option?.hidden && (
              <FormControlLabel
                ref={
                  visibleOptions.length === index + 1
                    ? lastOptionRef
                    : undefined
                }
                key={(option?.label ?? "filter") + index}
                value={index}
                control={
                  <Checkbox
                    id={`filter-checkbox-${kebabCaseLabel(option)}`}
                    disabled={option?.disabled}
                    checked={option?.selected}
                    onChange={() => {
                      optionCallback?.(option);
                      toggleCheckbox(option);
                    }}
                    inputProps={{
                      ["data-testid" as any]: `filter-checkbox-${kebabCaseLabel(
                        option
                      )}`,
                    }}
                  />
                }
                label={
                  <RadioLabel
                    data-testid={`filter-label-${kebabCaseLabel(option)}`}
                    style={{
                      color: option?.disabled
                        ? colorValues.cityblock
                        : colorValues.betterptblack,
                      display: "flex",
                      alignItems: "center",
                      wordBreak: "break-word",
                    }}
                  >
                    {option?.label ?? "-"}
                    {option?.icon ?? null}
                  </RadioLabel>
                }
              />
            )
        )}
      </OptionsList>
    );

    return optionsBody;
  };

  return (
    <FilterContainer margin={style?.buttonMargin}>
      <InfoBox
        openWith="click"
        isOpen={openStatus?.value}
        toggleBox={openStatus?.update}
        width={280}
        height={boxHeight}
        iconHeight={36}
        iconWidth={204}
        position="bottom"
        padding={2}
        testId={`${name}-filter`}
        boxStyle={{
          display: openStatus?.value ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "15px 25px 25px 0px",
          ...(style?.box ?? {}),
        }}
        arrowStyle={style?.arrow}
        icon={
          customButton ?? (
            <FilterButton
              open={openStatus?.value}
              css={rotateStyles}
              onClick={() => rotateIcon && setRotate(true)}
            >
              <FilterLabel padding={style?.labelPadding}>
                <div
                  className={rotate && rotateIcon ? "rotationContainer" : ""}
                >
                  {icon ?? (
                    <IcDropDownSelected
                      style={{ width: 20, height: 20, marginTop: 5 }}
                      color={colorValues.charcoalgray}
                      css={rotate && rotateIcon ? rotateStyles : undefined}
                    />
                  )}
                </div>
                <p style={{ whiteSpace: "break-spaces", fontSize: 10 }}>
                  {loading ? "loading..." : buttonTitle.toLowerCase()}
                </p>
              </FilterLabel>
            </FilterButton>
          )
        }
      >
        <h4 style={{ paddingLeft: 35, marginBottom: 5 }}>
          {(boxTitle ?? name).toUpperCase()}
        </h4>
        <OptionsContainer
          style={{ overflow: style?.overflow, maxHeight: style?.maxHeight }}
        >
          {useSearch && (
            <div>
              <TextField
                value={search?.value ?? ""}
                placeholder={`Search ${name}`}
                onChange={(e) => search?.update?.(e.target.value)}
                overrideStyle={{
                  margin: "0 auto",
                  width: 210,
                }}
                InputProps={{
                  startAdornment: renderTags(),
                  style: { width: 207 },
                }}
              />
              {visibleOptions.length < 1 && !!bestSearchTerm.length && (
                <p style={{ marginLeft: 34 }}>No results</p>
              )}
            </div>
          )}
          {renderOptions()}
        </OptionsContainer>
        <Button
          loading={loading}
          color={applyButtonColor}
          size="small"
          style={applyButtonStyle()}
          fullWidth
          onClick={handleApplyFilter}
          success={complete}
        >
          {applyButtonText ?? "APPLY FILTER"}
        </Button>
      </InfoBox>
    </FilterContainer>
  );
};

export default FilterScrollAndSearch;
