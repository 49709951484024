import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...rest}
  >
    <defs>
      <path
        id='48l1jx2ona'
        d='M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0L24 0 24 24 0 24z' />
      <mask id='xal4iw4dyb' fill='#fff'>
        <use xlinkHref='#48l1jx2ona' />
      </mask>
      <use fill={color ?? '#333'} fillRule='nonzero' xlinkHref='#48l1jx2ona' />
      <g fill={color ?? '#333'} mask='url(#xal4iw4dyb)'>
        <path d='M0 0H24V24H0z' />
      </g>
    </g>
  </svg>
);
