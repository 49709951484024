import React from 'react';
import styled from '@emotion/styled';
import { Checkbox } from '@betterpt/better-components';
import { Widget } from '@rjsf/core';

const CheckboxLabel = styled.label`
  position: relative;
  right: 12px;
`;

const CustomCheckboxInput: Widget = (props) => {
  return (
    <CheckboxLabel className='Body'>
      <Checkbox
        checked={props.value}
        onClick={() => props.onChange(!props.value)}
      />
      Question is required
    </CheckboxLabel>
  );
};

export default CustomCheckboxInput;
