import React from 'react';

import TextField from '../TextField';
import Checkbox from '../Checkbox';

// styles
import { SelectItem, SelectSection } from './style';

import { normalizeClock, weekDays } from './helper';

type Props = {
  hours: object;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any) => void;
  selectedDays: object;
  disabled?: boolean;
};

const HoursOfOperation = ({
  disabled,
  selectedDays,
  hours,
  handleChange,
  setFieldValue,
}: Props) => {
  const capitalizeFirstLetter = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const renderHourField = (day: string) => {
    return (
      <>
        <Checkbox
          name={`selectedDays.${day}OpenSelected`}
          disabled={disabled}
          checked={selectedDays[`${day}OpenSelected`]}
          onChange={(e) => {
            setFieldValue(`hours.${day}Open`, null);
            setFieldValue(`hours.${day}Close`, null);
            handleChange(e);
          }}
          value={selectedDays[`${day}OpenSelected`]}
        />
        <p>{capitalizeFirstLetter(day)}</p>
        <TextField
          style={{ width: '45%', margin: '0 15px' }}
          disabled={disabled || !selectedDays[`${day}OpenSelected`]}
          label='From'
          name={`hours.${day}Open`}
          placeholder='00:00 AM'
          value={(hours as any)[`${day}Open`] || ''}
          onKeyDown={(e) => {
            if (e.keyCode === 8) {
              const value = (hours as any)[`${day}Open`];
              setFieldValue(
                `hours.${day}Open`,
                value.slice(0, value.length - 1)
              );
            }
          }}
          onChange={(e) => {
            e.target.value = normalizeClock(e, 'AM');
            handleChange(e);
          }}
        />
        <TextField
          placeholder='00:00 PM'
          style={{ width: '45%', margin: '0 15px' }}
          label='To'
          disabled={disabled || !selectedDays[`${day}OpenSelected`]}
          name={`hours.${day}Close`}
          value={(hours as any)[`${day}Close`] || ''}
          onKeyDown={(e) => {
            if (e.keyCode === 8) {
              const value = (hours as any)[`${day}Close`];
              setFieldValue(
                `hours.${day}Close`,
                value.slice(0, value.length - 1)
              );
            }
          }}
          onChange={(e) => {
            e.target.value = normalizeClock(e, 'PM');
            handleChange(e);
          }}
        />
      </>
    );
  };

  return (
    <SelectSection>
      {weekDays.map((day, idx) => (
        <SelectItem key={`${day}hrs${idx}`}>{renderHourField(day)}</SelectItem>
      ))}
    </SelectSection>
  );
};

export default HoursOfOperation;
