import styled from '@emotion/styled';

import { colorValues } from '@betterpt/better-components';

export const BorderedStyle = styled.div`
  border-radius: 5px;
  border: solid 2px ${colorValues.concretejungle};
  margin: 28px 60px;
  .redirect-message {
    margin: 20px 20px 20px 68px;
  }
`;

export const HoursOfOperationContainer = styled.div`
  position: relative;
  padding: 0px 20px;
`;

export const ProviderCardContainer = styled.div`
  margin-bottom: 52px;
`;

export const LogoImage = styled.img`
  margin-right: 20px;
  border-radius: 50%;
  vertical-align: middle;
  height: 32px;
  width: 32px;
`;
