import * as React from 'react';
import TextField from './ThemedTextField';

type FormHandler = {
  formHandler: {
    error: string | null | undefined;
    value: string;
    updateValue: (value: string) => void;
    updateTouched: () => void;
  };
} & Parameters<typeof TextField>[0];

const FormField = ({ formHandler, helperText, ...rest }: FormHandler) => {
  return (
    <TextField
      {...rest}
      onChange={(e) => formHandler.updateValue(e.target.value)}
      onBlur={() => formHandler.updateTouched()}
      value={formHandler.value}
      error={!!formHandler.error}
      helperText={formHandler.error || helperText}
    />
  );
};

export default FormField;
