import useTextFieldState from '../../../../../../../hooks/useTextFieldState';
import {
  emailValidation,
  nameValidation,
  normalizePhone,
  normalizeZipcode,
} from '../../../../../../../helpers';

const useFormValues = () => {
  // shared form fields
  const facilityName = useTextFieldState({
    required: true,
    validation: nameValidation,
  });
  const city = useTextFieldState({
    required: true,
    validation: nameValidation,
  });
  const state = useTextFieldState({
    required: true,
  });

  // For service area clinic form fields
  const serviceAreaName = useTextFieldState({ required: true });

  // For traditional clinic form fields
  const address = useTextFieldState({
    required: true,
    validation: nameValidation,
  });
  const address2 = useTextFieldState();
  const email = useTextFieldState({
    required: true,
    validation: emailValidation,
  });
  const zipcode = useTextFieldState({
    required: true,
    normalizer: normalizeZipcode,
  });
  const phone = useTextFieldState({
    required: true,
    normalizer: normalizePhone,
  });

  return {
    facilityName,
    city,
    state,
    serviceAreaName,
    address,
    address2,
    email,
    zipcode,
    phone,
  };
};

export default useFormValues;
