import styled from '@emotion/styled';
import { colorValues, bestTextColor } from '@betterpt/better-components';

export const UL = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  min-height: 100px;
  max-height: 300px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colorValues.cityblock};
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${colorValues.dust};
    border-radius: 5px;
  }
`;

export const DialogHeader = styled.div`
  padding: 38px 16px 18px 34px;
  background-color: ${colorValues.earlysteam};
  h1 {
    font-size: 24px;
    color: ${colorValues.betterptblack};
    margin: 0;
  }
`;

export const SearchSection = styled.div`
  display: flex;
  border-top: 1px solid ${colorValues.concretejungle};
  border-bottom: 1px solid ${colorValues.concretejungle};
  padding: 5px 17px 5px 34px;
  align-items: center;
  p {
    font-size: 14px;
    color: ${colorValues.cityblock};
    margin: 0;
    margin-right: 25px;
  }
`;

export const EmptyStateText = styled.li`
  text-align: center;
`;

export const ButtonSection = styled.div`
  padding: 33px 44px 26px 46px;
  border-top: 1px solid ${colorValues.concretejungle};
`;

export const buttonStyle = (color?: string) => ({
  backgroundColor: color,
  color: bestTextColor(color),
});
