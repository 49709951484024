import * as React from 'react';

// helpers
import { colorValues } from '@betterpt/better-components';
import { possessiveName } from '../../../../../../helpers';

// hooks
import { useParams } from 'react-router-dom';
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';
import useTextFieldState from '../../../../../../hooks/useTextFieldState';
import {
  useMeQuery,
  useFacilityQuery,
  useCompanyBillingOverviewQuery,
} from '../../../../../../generated/graphql';
import useHandleSubmit from './hooks/useHandleSubmit';
import useSnackbar from '../../../../../../hooks/useSnackbar';

// components
import {
  Button,
  ImageUploader,
  HoursOfOperation,
  daysHourOfOperationInitialValues,
  Animation,
} from '@betterpt/better-components';
import FormField from '../../../../../Shared/FormField';
import BinaryRadio from '../../../../../Shared/BinaryRadio';
import CopyTimesToAll from '../../../../../Shared/CopyTimesToAll';

// assets
import ImageUploaderBackground from '../../../../../../assets/user-profile-hero-img-no-clinic-photo.svg';
import ImageLogoBackground from '../../../../../../assets/img-unknown-cliniclogo.svg';
import { IcArrowRight } from '@betterpt/better-icons';

// styles
import { Form, ButtonStyle } from '../../CreateFacility.style';

const CreateFacilityStepTwo = () => {
  const snackbar = useSnackbar();
  const {
    patientsPluralAlias,
    facilitiesSingularAlias,
    primaryColor,
    loading: remoteConfigLoading,
  } = useRemoteConfigAliases();
  const { facilityId } = useParams<{ facilityId: string }>();
  const meQuery = useMeQuery();

  const about = useTextFieldState();
  const [isHSSAffiliate, updateIsHssAffiliate] = React.useState(false);
  const [avatarUrl, updateAvatarUrl] = React.useState<string>();
  const [logoUrl, updateLogoUrl] = React.useState<string>();
  const [
    inPersonHoursOfOperation,
    updateInPersonHoursOfOperation,
  ] = React.useState<any>();
  const [
    telehealthHoursOfOperation,
    updateTelehealthHoursOfOperation,
  ] = React.useState<any>();

  const clinicQuery = useFacilityQuery({
    variables: { id: facilityId },
  });

  const billingOverview = useCompanyBillingOverviewQuery({
    variables: { id: facilityId },
  });

  // Update from existing... there really shouldn't be any existing at this point except for
  // the hours, which are pre populated from the backend. But just in case, we'll update
  // every field with existing values.
  React.useEffect(() => {
    if (clinicQuery.data) {
      updateInPersonHoursOfOperation(
        daysHourOfOperationInitialValues(
          clinicQuery.data.clinic?.bookingConfig?.hours ?? {}
        )
      );
      updateTelehealthHoursOfOperation(
        daysHourOfOperationInitialValues(
          clinicQuery.data.clinic?.bookingConfig?.videoAppointmentHours ?? {}
        )
      );
      about.updateValue(clinicQuery.data.clinic?.about ?? '');
      updateIsHssAffiliate(clinicQuery.data.clinic?.isHSSAffiliate ?? false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicQuery.data]);

  const determineUpdaterFunctionToUse = ({
    isInPerson,
  }: {
    isInPerson: boolean;
  }) => {
    if (isInPerson) {
      return updateInPersonHoursOfOperation;
    } else {
      return updateTelehealthHoursOfOperation;
    }
  };

  const determineHoursinState = ({ isInPerson }: { isInPerson: boolean }) => {
    if (isInPerson) {
      return inPersonHoursOfOperation;
    } else {
      return telehealthHoursOfOperation;
    }
  };

  const generateUpdaterFunctions = ({
    isInPerson,
  }: {
    isInPerson: boolean;
  }) => {
    const updaterFunction = determineUpdaterFunctionToUse({ isInPerson });
    const hoursState = determineHoursinState({ isInPerson });

    const setFieldValue = (field: string, value: any) => {
      // we do this because internally the HoursOfOperation component is sending
      // hours.{dayVariable} here. All we need is dayVariable, so we have to get
      // everything after the period.
      const normalizedFieldName = field.split('.')[1];

      updaterFunction((oldValues: any) => {
        return {
          ...oldValues,
          formattedOpenCloseDaysHours: {
            ...oldValues.formattedOpenCloseDaysHours,
            [normalizedFieldName]: value,
          },
        };
      });
    };

    const handleChange = (e: React.ChangeEvent<any>) => {
      // I don't know why we have to do e.persist, but apparently the event turns
      // to null if we don't. Strange react stuff.
      e.persist();
      // we do this because internally the HoursOfOperation component is sending
      // a nested object. Depending on the first part of the nested object,
      // we have to determine what part of the object has to be updated.
      const fieldNameComponents = e.target.name.split('.');

      if (fieldNameComponents[0] === 'hours') {
        updaterFunction((oldValues: any) => {
          return {
            ...oldValues,
            formattedOpenCloseDaysHours: {
              ...oldValues.formattedOpenCloseDaysHours,
              [fieldNameComponents[1]]: e.target.value,
            },
          };
        });
      } else {
        updaterFunction((oldValues: any) => {
          return {
            ...oldValues,
            selectedDays: {
              ...oldValues.selectedDays,
              [fieldNameComponents[1]]: e.target.value !== 'true',
            },
          };
        });
      }
    };

    const copyTimeToAll = () => {
      const newHours: any = {};
      const newSelectedDays: any = {};
      const mondayOpen = hoursState.formattedOpenCloseDaysHours.mondayOpen;
      const mondayClose = hoursState.formattedOpenCloseDaysHours.mondayClose;

      Object.keys(hoursState.formattedOpenCloseDaysHours).forEach((day) => {
        if (day.includes('Open')) {
          newHours[day] = mondayOpen;
        } else if (day.includes('Close')) {
          newHours[day] = mondayClose;
        }
      });
      Object.keys(hoursState.selectedDays).forEach(
        (day) => (newSelectedDays[day] = true)
      );

      updaterFunction({
        selectedDays: newSelectedDays,
        formattedOpenCloseDaysHours: newHours,
      });
    };

    return {
      copyTimeToAll,
      setFieldValue,
      handleChange,
    };
  };

  const inPersonHoursFunctions = generateUpdaterFunctions({ isInPerson: true });
  const telehealthHoursFunctions = generateUpdaterFunctions({
    isInPerson: false,
  });

  const { handleSubmit, isSaving } = useHandleSubmit();
  const company = meQuery.data?.me?.company;
  const isNotWhiteLabel =
    company?.providerAppId !== 'acorn' && company?.providerAppId !== 'somatus';

  // LOADING STATE
  if (
    meQuery.loading ||
    clinicQuery.loading ||
    billingOverview.loading ||
    remoteConfigLoading ||
    !inPersonHoursOfOperation ||
    !telehealthHoursOfOperation
  ) {
    return <Animation type='providerAppLoader' />;
  }

  return (
    <>
      <Form
        data-testid='create-facility-hours-and-about-container'
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit({
            about: about.value,
            inPersonHoursOfOperation,
            telehealthHoursOfOperation,
            isHSSAffiliate,
            logoUrl,
            avatarUrl,
          });
        }}
      >
        <Button
          size='medium'
          type='submit'
          style={ButtonStyle(primaryColor)}
          color='transparent'
          disabled={isSaving}
          loading={isSaving}
        >
          NEXT
        </Button>
        <h4
          style={{
            marginBottom: 20,
            paddingBottom: '35px',
            borderBottom: `1px solid ${colorValues.concretejungle}`,
          }}
        >
          Add to Your Profile
        </h4>
        <ImageUploader
          type='avatar'
          onSubmit={(img) => {
            updateLogoUrl(img.base64Img);
          }}
          image={logoUrl ?? ImageLogoBackground}
          style={{
            position: 'absolute',
            left: '-185px',
            width: '160px',
            height: '160px',
          }}
          helperText='Drag or click to upload a company logo. Maximum 250x250 px.'
          onError={(message) =>
            snackbar?.openSnackbar({ isError: true, message })
          }
        />
        <ImageUploader
          type='hero'
          onSubmit={(img) => {
            updateAvatarUrl(img.base64Img);
          }}
          style={{ margin: 0 }}
          innerContainerStyle={{ justifyContent: 'center' }}
          image={avatarUrl ?? ImageUploaderBackground}
          onError={(message) =>
            snackbar?.openSnackbar({ isError: true, message })
          }
        />
        {isNotWhiteLabel && (
          <>
            <h4 style={{ marginBottom: 20 }}>About</h4>
            <FormField
              id='about'
              fullWidth
              label='Tell Us About Your Facility'
              placeholder={`This will be displayed in ${possessiveName(
                patientsPluralAlias.toLowerCase()
              )} ${facilitiesSingularAlias.toLowerCase()} searches`}
              formHandler={about}
              helperText='Max 1000 characters'
              inputProps={{
                maxLength: 1000,
              }}
            />
          </>
        )}
        {billingOverview?.data?.company?.billingOverview
          ?.isAllowedToAddHSSClinics && (
          <BinaryRadio
            label='We are an HSS Rehab Network Provider'
            upperLabel='HSS'
            updateValue={updateIsHssAffiliate}
            value={isHSSAffiliate}
            testId='hss-rehab-network-binary'
          />
        )}
        {isNotWhiteLabel && (
          <>
            <h4 style={{ marginBottom: 20 }}>Hours of Operation (In-Person)</h4>
            <div style={{ position: 'relative' }}>
              <CopyTimesToAll
                onClick={inPersonHoursFunctions.copyTimeToAll}
                hoursTypeText='in-person appointment'
              />

              <HoursOfOperation
                hours={
                  inPersonHoursOfOperation?.formattedOpenCloseDaysHours ?? {}
                }
                selectedDays={inPersonHoursOfOperation?.selectedDays ?? {}}
                handleChange={inPersonHoursFunctions.handleChange}
                setFieldValue={inPersonHoursFunctions.setFieldValue}
              />
            </div>
          </>
        )}
        <h4 style={{ marginBottom: 20 }}>Hours of Operation (Telehealth)</h4>
        <div style={{ position: 'relative' }}>
          <CopyTimesToAll
            onClick={telehealthHoursFunctions.copyTimeToAll}
            hoursTypeText='telehealth'
          />
          <HoursOfOperation
            hours={
              telehealthHoursOfOperation?.formattedOpenCloseDaysHours ?? {}
            }
            selectedDays={telehealthHoursOfOperation?.selectedDays ?? {}}
            handleChange={telehealthHoursFunctions.handleChange}
            setFieldValue={telehealthHoursFunctions.setFieldValue}
          />
        </div>
      </Form>
    </>
  );
};

export default CreateFacilityStepTwo;
