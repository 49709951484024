import { jsx } from '@emotion/react';
import React from 'react';

//assets
import IcDropDownSelected from '../Icons/IcDropDownSelected';

//styles
import { colorValues } from '../helpers/colors';
import {
  FilterButton,
  FilterContainer,
  FilterLabel,
  rotateStyles,
} from './style';

const FilterButtonOnly = ({
  loading,
  buttonTitle,
  icon,
  rotateIcon,
  onClick,
  style,
}: {
  buttonTitle?: string;
  loading?: boolean;
  icon?: any;
  rotateIcon?: boolean;
  onClick?: () => void;
  style?: {
    labelPadding?: string;
    buttonMargin?: string;
    [key: string]: any;
  };
}) => {
  let animationTimeout: any;

  const [clicked, setClicked] = React.useState(false);

  //handle animations
  React.useEffect(() => {
    if (clicked) {
      animationTimeout = setTimeout(() => {
        setClicked(false);
      }, 333);
    }

    //cleanup any lingering timeout effects on dismount
    return () => {
      if (animationTimeout) clearTimeout(animationTimeout);
    };
  }, [clicked]);

  return (
    <FilterContainer margin={style?.buttonMargin}>
      <FilterButton
        open={clicked}
        css={rotateStyles}
        onClick={() => {
          setClicked(true);
          onClick?.();
        }}
      >
        <FilterLabel padding={style?.labelPadding}>
          <div className={clicked && rotateIcon ? 'rotationContainer' : ''}>
            {icon ?? (
              <IcDropDownSelected
                style={{ width: 20, height: 20, marginTop: 5 }}
                color={colorValues.charcoalgray}
              />
            )}
          </div>
          <p style={{ fontSize: 10 }}>{loading ? 'loading...' : buttonTitle?.toLowerCase()}</p>
        </FilterLabel>
      </FilterButton>
    </FilterContainer>
  );
};

export default FilterButtonOnly;
