import React from 'react';

//assets
import { ImgKirstenPhoneSearch } from '@betterpt/better-icons';

//styles
import { GridCard } from '../../../../Shared/GridCard';
import BlockedPageMessage from '../../../../Shared/BlockedPageMessage';
import { Page, NoAnalyticsPage } from '../../EmbeddedAnalytics.style';

const NoAnalytics = ({ unavailable }: { unavailable?: boolean }) => (
  <Page>
    <GridCard data-testid='no-analytics-yet' fullWidth>
      <NoAnalyticsPage>
        <BlockedPageMessage
          hideButton
          icon={<ImgKirstenPhoneSearch style={{ height: 160, width: 160 }} />}
          title={unavailable ? 'Not Available Yet' : 'No Analytics Listed'}
          message={
            unavailable
              ? `Analytics tracking is not yet supported for your healthcare
              industry.`
              : `You don't have any analytics yet.`
          }
        />
      </NoAnalyticsPage>
    </GridCard>
  </Page>
);

export default NoAnalytics;
