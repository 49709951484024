import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

export const AddAdministratorFormContainer = styled.div<{
  isFormActive: boolean;
}>`
  display: flex;
  justify-content: ${({ isFormActive }) => (isFormActive ? '' : 'center')};
`;

export const AddAdministratorForm = styled.form`
  width: 450px;
  margin-bottom: 100px;
  h2 {
    margin: 20px 0px;
  }
`;

export const P = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${colorValues.betterptblack};
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${colorValues.betterptblack};
`;
