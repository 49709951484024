import React from 'react';
import { InfoBox, colorValues } from '@betterpt/better-components';
import styled from '@emotion/styled';

const PSafeHeader = styled.span`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.17;
  margin: 10px 0 15px;
  letter-spacing: normal;
  color: ${colorValues.charcoalgray};
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  display: block;
`;

const PSafeText = styled.span`
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${colorValues.betterptblack};
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  display: block;
`;

const ClinicientInfoBox = ({ action }: { action: string }) => (
  <InfoBox
    openWith='hover'
    width={230}
    height={135}
    position='left'
    padding='15px'
    fixedPlacement
    mainStyle={{ right: '-45px', top: '-5px' }}
    boxStyle={{ top: '-40px' }}
  >
    <PSafeHeader>{action}</PSafeHeader>
    <PSafeText>Please use Clinicient Insight for this action.</PSafeText>
  </InfoBox>
);

export default ClinicientInfoBox;
