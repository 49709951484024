import React from 'react';

export default ({
    color,
    ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
        <svg
            {...rest}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g fill="none" fillRule="evenodd">
                <path d="M0 0L24 0 24 24 0 24z" />
                <path fill={color || "#333"} d="M11.926 17.078L6.409 19.978 7.463 13.835 3 9.485 9.168 8.589 11.926 3 14.684 8.589 20.851 9.485 16.389 13.835 17.442 19.978z" />
            </g>
        </svg>
    );
