import * as React from "react";

//types
import { VideoAppointmentFiltersContextType } from "../../../context/videoAppointmentFilters";
import {
  CompanyListItemTypeEnum,
  Role,
  useMeQuery,
  useCompanyFacilitiesQuery,
  Clinic,
  Maybe,
} from "../../../generated/graphql";
import { PatientFiltersContextType } from "../../../context/patientFilters";
import { InPersonAppointmentFiltersContextType } from "../../..//context/inPersonAppointmentFilters";

//hooks
import useRemoteConfigAliases from "../../../hooks/useRemoteConfigAliases";
import _ from "lodash";

//components
import {
  FilterScrollAndSearch,
  FilterOption,
} from "@betterpt/better-components";

//helpers
import { pluralNoun } from "../../../helpers";

const FacilitiesFilter: React.FC<React.PropsWithChildren<{
  filters:
    | VideoAppointmentFiltersContextType
    | PatientFiltersContextType
    | InPersonAppointmentFiltersContextType;
  primaryColor?: string;
  first?: boolean;
}>> = ({ filters, primaryColor, first }) => {
  const { facilitiesPluralAlias, facilitiesSingularAlias } =
    useRemoteConfigAliases();

  const [nameFilter, updateNameFilter] = React.useState("");
  const [offset, setOffset] = React.useState<number>(0);
  const [isOpen, setOpenTo] = React.useState(false);
  const [facilityIds, updateFacilityIds] = React.useState<string[]>([]);
  const [facilityList, setFacilityList] = React.useState<any[]>([]);

  const meQuery = useMeQuery();
  const me = meQuery.data?.me;
  const isOwner = me?.role === Role.Owner;

  const onQueryComplete = (data: any) =>
    setFacilityList((prev) =>
      _.unionBy([...prev, ...(data?.company?.clinics?.result ?? [])], "id")
    );

  // To Get List of Facilities Wiht Pagination
  const facilitiesQuery = useCompanyFacilitiesQuery({
    variables: {
      id: meQuery.data?.me?.company?.id!,
      input: {
        query: nameFilter,
        offset,
        limit: 10,
      },
    },
    skip: !meQuery.data?.me?.company?.id || !isOwner || !isOpen,
    notifyOnNetworkStatusChange: true,
    onCompleted: onQueryComplete,
    fetchPolicy: "cache-and-network",
  });

  // To Get List of Facilities for Facility Ids Selected in Facilities Filter
  const filterFacilitiesQuery = useCompanyFacilitiesQuery({
    variables: {
      id: meQuery.data?.me?.company?.id!,
      input: {
        clinicIds: filters.clinicIds.value,
      },
    },
    onCompleted: onQueryComplete,
    skip: !filters.clinicIds.value.length || !isOpen || !isOwner,
    fetchPolicy: "cache-and-network",
  });

  const count = facilitiesQuery?.data?.company?.clinics?.result?.length ?? 0;

  const handleSubmitFacilities = (selected: FilterOption[]) => {
    setOffset(0);
    setFacilityList([]);
    const newFacilities = selected?.length
      ? selected?.map((option) => option?.value ?? "0")
      : [];

    filters.clinicIds.update(newFacilities);
  };

  React.useEffect(() => {
    updateFacilityIds(filters.clinicIds?.value ?? []);
  }, [filters.clinicIds, isOpen]);

  const facilityOptions = (() => {
    const selectAllFacilities = [
      {
        label: `All ${facilitiesPluralAlias}`,
        isResetOption: true,
        selected: facilityIds.length < 1 || facilityIds?.includes("0"),
      },
    ];

    const facilitiesToShow = isOwner
      ? facilityList
      : me?.clinics?.map((clinic) => ({
          clinicName: clinic?.displayName,
          id: clinic?.id,
          type: CompanyListItemTypeEnum.Clinic,
        }));

    const facilityOptions: FilterOption[] = (
      facilitiesToShow ? [...facilitiesToShow] : []
    ).map((facility) => {
      return {
        label: facility?.clinicName ?? "-",
        value: facility?.id ?? "0",
        hidden: !facility?.id,
        selected: facilityIds?.includes(facility?.id),
      };
    });

    return [...selectAllFacilities, ...facilityOptions];
  })();

  const filterFacilityOptions = (() => {
    const filterFacilityOptions = [
      ...(filterFacilitiesQuery?.data?.company?.clinics?.result ?? []),
    ].map((facility) => facility?.clinicName ?? "-");

    return [...filterFacilityOptions];
  })();

  const handleButtonTitle = (() => {
    let clinic_Ids = filters.clinicIds?.value;
    let title = "loading...";
    if (clinic_Ids.length < 1) {
      title = `all ${pluralNoun(facilitiesSingularAlias)}`;
    } else {
      const bestCategoryName =
        clinic_Ids.length > 1
          ? pluralNoun(facilitiesSingularAlias)
          : facilitiesSingularAlias;
      const bestCheckTitle = `${clinic_Ids.length} ${bestCategoryName}`;
      title = bestCheckTitle ?? "";
    }
    return title;
  })();

  const handleFacilityClick = (option: FilterOption) => {
    if (!option?.isResetOption) {
      updateFacilityIds((ids) => {
        if (facilityIds.includes(option?.value)) {
          return ids.filter((id) => id !== option?.value);
        } else {
          return [...ids, option?.value];
        }
      });
    } else {
      updateFacilityIds([]);
    }
  };

  const handleSearch = (searchText: string) => {
    updateNameFilter(searchText);
    setFacilityList([
      ...facilityList.filter((facility) => facilityIds.includes(facility?.id)),
    ]);
    setOffset(0);
  };

  return (
    <FilterScrollAndSearch
      selectedOptions={filterFacilityOptions}
      options={facilityOptions}
      buttonTitle={handleButtonTitle}
      useSearch={isOwner}
      search={{
        value: nameFilter,
        update: handleSearch,
      }}
      openStatus={{
        value: isOpen,
        update: (open: boolean) => {
          setOpenTo(open);
        },
      }}
      optionCallback={handleFacilityClick}
      name={facilitiesSingularAlias}
      overrideButtonColor={primaryColor}
      onLoadMore={() => setOffset(offset + count)}
      hasMore={!!count}
      onSubmit={handleSubmitFacilities}
      loading={meQuery.loading || facilitiesQuery.loading}
      style={{
        buttonMargin: "12px 2px 0 0",
        ...(first ? { box: { left: 15, top: 65 }, arrow: { left: 55 } } : {}),
      }}
    />
  );
};

export default FacilitiesFilter;
