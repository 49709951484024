import React from 'react';
import { Clinic } from '../../../../generated/graphql';

const LocationColumn = ({ clinic }: { clinic?: Clinic | null }) => {
  const isServiceArea = clinic?.location?.isServiceArea;
  const standardLocation = `${clinic?.location?.city ?? '-'}, ${clinic?.location
    ?.state ?? '-'}`;

  return (
    <p>
      {isServiceArea
        ? clinic?.location?.serviceAreaName ?? standardLocation
        : standardLocation}
    </p>
  );
};

export default LocationColumn;
