import React from 'react';

//types
import { Order, Role } from '../../../../../generated/graphql';

// helpers
import { addIndefiniteArticle, Roles } from '../../../../../helpers';

//hooks
import { useParams } from 'react-router-dom';
import {
  useMeQuery,
  usePatientContactDetailsQuery,
  useInPersonAppointmentsLazyQuery,
} from '../../../../../generated/graphql';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../../hooks/useSnackbar';

//components
import TableView from '../../../Appointments/InPersonAppointments/InPersonAppointmentsList/components/InPersonAppointmentsTable';
import CreateInPersonAppointment from '../../../Appointments/InPersonAppointments/CreateInPersonAppointment';
import { GridCard } from '../../../../Shared/GridCard';
import { CardBody } from '../PatientContactDetail.style';
import Can from '../../../../Can';

// styles
import * as styles from '../PatientContactDetail.style';
type Props = {
  updateIsCreateAppointmentDialogOpen: (
    isCreateAppointmentDialogOpen: boolean
  ) => void;
  isCreateAppointmentDialogOpen: boolean;
  isTelehealthAppointment: boolean;
};

const InPersonAppointmentsList = ({
  updateIsCreateAppointmentDialogOpen,
  isCreateAppointmentDialogOpen,
  isTelehealthAppointment,
}: Props) => {
  const { patientId } = useParams<{ patientId: string }>();
  const [offset, setOffset] = React.useState(0);
  const [defaultPageIndex, updateDefaultPageIndex] = React.useState(0);
  const pagesize = 10;
  const aliasConfig = useRemoteConfigAliases();
  const snackbar = useSnackbar();

  const patientString = aliasConfig.patientsSingularAlias ?? 'Patient';
  const facilityString = aliasConfig.facilitiesSingularAlias ?? 'Facility';

  const appointmentsQueryInput = React.useMemo(
    () => ({
      limit: pagesize,
      offset,
      contactIds: [patientId],
      order: Order.Desc,
    }),
    [offset, patientId]
  );

  const [
    callInPersonAppointmentsQuery,
    inPersonAppointmentsQuery,
  ] = useInPersonAppointmentsLazyQuery({
    onError: () => snackbar?.useGenericErrorMessage(),
    fetchPolicy: 'cache-and-network',
  });

  const patientContact = usePatientContactDetailsQuery({
    variables: { id: patientId },
  });

  const meQuery = useMeQuery({
    onCompleted(data) {
      const hasAccessToThisClinic = !!data.me?.clinics?.find(
        (clinic) => clinic?.id === patientContact.data?.contact.clinic?.id
      );
      if (hasAccessToThisClinic || data.me?.role === Role.Owner) {
        const isBasic = data.me?.role === Role.Self;
        callInPersonAppointmentsQuery({
          variables: {
            input: {
              ...appointmentsQueryInput,
              employeeIds: isBasic ? [data.me?.id ?? '0'] : null,
            },
          },
        });
      }
    },
  });
  React.useEffect(() => {
    if (meQuery.data?.me) {
      const isBasic = meQuery?.data?.me?.role === Role.Self;
      callInPersonAppointmentsQuery({
        variables: {
          input: {
            ...appointmentsQueryInput,
            employeeIds: isBasic ? [meQuery.data?.me?.id ?? '0'] : null,
          },
        },
      });
    }
  }, [
    appointmentsQueryInput,
    callInPersonAppointmentsQuery,
    meQuery.data?.me,
    offset,
  ]);

  const totalCount =
    inPersonAppointmentsQuery.data?.inPersonAppointments?.pager?.total;
  const loading = inPersonAppointmentsQuery.loading;

  const noAppointmentsCreated =
    inPersonAppointmentsQuery.called &&
    !loading &&
    !inPersonAppointmentsQuery.data?.inPersonAppointments?.pager?.total;

  return (
    <>
      {isCreateAppointmentDialogOpen && !isTelehealthAppointment && (
        <div
          style={styles.createAppointmentDialogStyles}
          onClick={() => updateIsCreateAppointmentDialogOpen(false)}
        >
          <div onClick={(e) => e.stopPropagation()} style={{ height: 'auto' }}>
            <CreateInPersonAppointment
              existingAppointment={{
                clinic: {
                  id: patientContact.data?.contact.clinicId,
                  timeZone: patientContact.data?.contact?.clinic?.timeZone,
                },
                contact: { id: patientContact.data?.contact?.id },
              }}
              onDone={() => {
                snackbar?.setSuccessMessage('Appointment booked');
                inPersonAppointmentsQuery.refetch?.({
                  input: appointmentsQueryInput,
                });
                updateIsCreateAppointmentDialogOpen(false);
              }}
              onCancelClick={() => updateIsCreateAppointmentDialogOpen(false)}
              lockPatient
              isInModal
            />
          </div>
        </div>
      )}
      <GridCard style={{ width: '100%', marginRight: 0, maxWidth: '1315px' }}>
        <Can
          role={Roles[meQuery.data?.me?.role ?? 'initial']}
          perform='facility:view'
          data={{
            employeeClinics: meQuery?.data?.me?.clinics,
            clinicToView: patientContact.data?.contact.clinic,
          }}
          yes={() => (
            <>
              {noAppointmentsCreated ? (
                <CardBody>
                  <p>
                    There are either no in person appointments registered with
                    this {patientString.toLowerCase()}, or you do not have
                    permissions to view them.
                  </p>
                </CardBody>
              ) : (
                <TableView
                  tableOptions={{ minBodyHeight: 440 }}
                  onChangePage={(pageSize: number, idx: number) => {
                    setOffset(idx * pageSize);
                    updateDefaultPageIndex(idx);
                  }}
                  isLoading={
                    inPersonAppointmentsQuery.loading || meQuery.loading
                  }
                  totalCount={totalCount ?? 0}
                  appointmentsQuery={inPersonAppointmentsQuery}
                  defaultPageIndex={defaultPageIndex}
                  pagesize={pagesize}
                  onDoneChangingOptionsAppointment={() =>
                    inPersonAppointmentsQuery.refetch?.({
                      input: appointmentsQueryInput,
                    })
                  }
                />
              )}
            </>
          )}
          no={() => (
            <CardBody>
              <p>
                You do not have permission to view the in person appointments at
                this {patientString.toLowerCase()} because they are not assigned
                to {addIndefiniteArticle(facilityString).toLowerCase()} you can
                view.
              </p>
            </CardBody>
          )}
        />
      </GridCard>
    </>
  );
};

export default InPersonAppointmentsList;
