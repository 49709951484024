import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='111'
    height='111'
    viewBox='0 0 111 111'
    {...rest}
  >
    <g fill='none' fillRule='evenodd'>
      <path
        fill={color || '#333'}
        fillRule='nonzero'
        d='M41.625 54.344c-3.191 0-5.781 2.59-5.781 5.781s2.59 5.781 5.781 5.781 5.781-2.59 5.781-5.781-2.59-5.781-5.781-5.781zm27.75 0c-3.191 0-5.781 2.59-5.781 5.781s2.59 5.781 5.781 5.781 5.781-2.59 5.781-5.781-2.59-5.781-5.781-5.781zM55.5 9.25C29.97 9.25 9.25 29.97 9.25 55.5s20.72 46.25 46.25 46.25 46.25-20.72 46.25-46.25S81.03 9.25 55.5 9.25zm0 83.25c-20.396 0-37-16.604-37-37 0-1.341.093-2.682.231-3.977 10.915-4.857 19.564-13.783 24.096-24.837C51.2 38.526 64.981 46.25 80.567 46.25c3.608 0 7.077-.416 10.407-1.203.971 3.284 1.526 6.8 1.526 10.453 0 20.396-16.604 37-37 37z'
      />
      <path d='M0 0L111 0 111 111 0 111z' />
    </g>
  </svg>
);
