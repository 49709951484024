import React from 'react';
import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

const OuterProgressBar = styled.div<{ hideBar?: boolean }>`
  width: 175px;
  height: 10px;
  display: ${({ hideBar }) => (hideBar ? 'none' : 'flex')};
  align-items: center;
  background-color: ${colorValues.dust};
  padding: 2px;
  border-radius: 20px;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const InnerProgressBar = styled.div<{ width: number; color?: string }>`
  height: 10px;
  border-radius: 20px;
  background-color: ${({ color }) => color ?? colorValues.guajirogreen};
  width: ${({ width }) => width}%;
`;

const ProgressBar = ({
  width,
  color,
  style,
}: {
  width: number | any;
  color?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <>
      <OuterProgressBar
        data-cy='minimalistic-progress-bar'
        style={style ?? {}}
        hideBar={false}
      >
        <InnerProgressBar width={width} color={color} />
      </OuterProgressBar>
    </>
  );
};

export default ProgressBar;
