import React from 'react';
import { loader } from 'graphql.macro';

//helpers
import { Roles } from '../../../../../helpers';

//hooks
import {
  useMeQuery,
  useCompanyBookingFlowQuery,
  useUpdateCompanyBookingFlowMutation,
} from '../../../../../generated/graphql';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../../hooks/useSnackbar';

//components
import {
  Animation,
  InfoBox,
  Switch,
  TextField,
  Header,
  Button,
} from '@betterpt/better-components';
import { GridCard } from '../../../../Shared/GridCard';
import Can from '../../../../Can';

//styles
import {
  CardTitle,
  SectionTitle,
  SwitchSection,
  FormStyles,
  headerStyle,
} from '../CompanyInfoTab.style';
import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';
import { buttonStyle } from '../../Company.style';
import useHandleError from '../../../../../hooks/useHandleError';

const QUERY_COMPANY_BOOKING_FLOW = loader(
  '../../../../../gql/company/CompanyBookingConfig.graphql'
);

const infoboxIconStyle = { top: '-1px', right: '-90px' };
const infoboxTextStyle = { fontWeight: 'normal' as 'normal' };
const infoboxStyle = { top: 45 };
const bookingExperienceSubheaderStyle = {
  fontStyle: 'italic',
  margin: '23px 0 19px',
  fontSize: 16,
};
export const LegacyIcon = styled.div`
  color: ${colorValues.emptiness};
  background-color: ${colorValues.cityblock};
  font-weight: bold;
  font-size: 12px;
  padding: 3px 13px;
  border-radius: 5px;
`;

type Props = {
  openSnackbar?(payload: { isError: boolean; message: string }): void;
};
const BookingExperience = ({ openSnackbar }: Props) => {
  const [isEditModeActive, setEditModeActive] = React.useState(false);

  const snackbar = useSnackbar();
  const handleError = useHandleError();

  const meQuery = useMeQuery();

  const bookingFlow = useCompanyBookingFlowQuery({
    variables: {
      id: meQuery.data?.me?.company?.id || '0',
    },
    skip: !meQuery.data?.me?.company?.id,
  });

  const queryError = bookingFlow.error;

  React.useEffect(() => {
    if (queryError?.message) {
      openSnackbar?.({
        isError: true,
        message:
          'Something went wrong! Please refresh and try again. If the issue persists, contact us at support@betterhealthcare.co',
      });
    }
  }, [openSnackbar, queryError]);

  const [updateCompanyBookingFlow, { loading: saving }] =
    useUpdateCompanyBookingFlowMutation({
      update(cache, { data: updateCompanyBookingFlow }) {
        cache.writeQuery({
          query: QUERY_COMPANY_BOOKING_FLOW,
          data: {
            company: {
              ...bookingFlow.data?.company,
              bookingFlow: updateCompanyBookingFlow,
            },
          },
        });
      },
    });

  const [askMedicare, setAskMedicare] = React.useState(false);
  const [askPostOp, setAskPostOp] = React.useState(false);
  const [askWorkersCompOrMVA, setAskWorkersCompOrMVA] = React.useState(false);
  const [askSecondaryInsurance, setAskSecondaryInsurance] =
    React.useState(false);
  const [askReturningPatient, setAskReturningPatient] = React.useState(false);
  const [askPatientAddress, setAskPatientAddress] = React.useState(false);
  const [askPreferredPatientContact, setAskPreferredPatientContact] =
    React.useState(false);
  const [askMarketingSource, setAskMarketingSource] = React.useState(false);

  const { facilitiesPluralAlias, primaryColor } = useRemoteConfigAliases();
  const subheaderText = `Your company still has ${facilitiesPluralAlias.toLowerCase()} on the legacy BetterPT Booking Experience. All ${facilitiesPluralAlias.toLowerCase()} using this Booking Experience have been configured with the following settings, irrespective of appointment type. For an improved custom experience, migrate these ${facilitiesPluralAlias.toLowerCase()} to the BetterAccess booking flow.`;

  React.useEffect(() => {
    if (bookingFlow.called && !bookingFlow.loading) {
      setAskMedicare(!!bookingFlow.data?.company?.bookingFlow?.askMedicare);
      setAskPostOp(!!bookingFlow.data?.company?.bookingFlow?.askPostOp);
      setAskWorkersCompOrMVA(
        !!bookingFlow.data?.company?.bookingFlow?.askWorkersCompOrMVA
      );
      setAskSecondaryInsurance(
        !!bookingFlow.data?.company?.bookingFlow?.askSecondaryInsurance
      );
      setAskReturningPatient(
        !!bookingFlow.data?.company?.bookingFlow?.askReturningPatient
      );
      setAskPatientAddress(
        !!bookingFlow.data?.company?.bookingFlow?.askPatientAddress
      );
      setAskPreferredPatientContact(
        !!bookingFlow.data?.company?.bookingFlow?.askPreferredPatientContact
      );
      setAskMarketingSource(
        !!bookingFlow.data?.company?.bookingFlow?.askMarketingSource
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingFlow.data]);

  const handleSave = async () => {
    if (!meQuery.data?.me?.company?.id) return;
    try {
      await updateCompanyBookingFlow({
        variables: {
          input: {
            companyId: meQuery.data?.me?.company?.id,
            askMedicare: +askMedicare,
            askPostOp: +askPostOp,
            askWorkersCompOrMVA: +askWorkersCompOrMVA,
            askSecondaryInsurance: +askSecondaryInsurance,
            askReturningPatient: +askReturningPatient,
            askPatientAddress: +askPatientAddress,
            askPreferredPatientContact: +askPreferredPatientContact,
            askMarketingSource: +askMarketingSource,
          },
        },
      });
      snackbar?.setSuccessMessage('Booking flow saved');
      setEditModeActive(false);
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <GridCard style={{ margin: '0 20px 20px 0' }}>
      {(bookingFlow.loading && <Animation type='providerAppLoader' />) || (
        <div>
          <Header underline style={headerStyle}>
            <CardTitle>
              BPT Booking Experience
              <InfoBox
                openWith='hover'
                width={230}
                height={200}
                iconHeight={14}
                iconWidth={32}
                position='bottom'
                padding={0}
                fixedPlacement
                mainStyle={infoboxIconStyle}
                boxStyle={infoboxStyle}
                icon={<LegacyIcon>LEGACY</LegacyIcon>}
              >
                <h4>BOOKING EXPERIENCE</h4>
                <p style={infoboxTextStyle}>
                  These settings will configure the questions for the original
                  BetterPT Booking Experience, and do not affect the new
                  BetterAccess Booking Experience.
                </p>
              </InfoBox>
            </CardTitle>
            <Can
              role={Roles[meQuery.data?.me?.role ?? 'initial']}
              perform='company:edit'
              yes={() => (
                <>
                  {isEditModeActive ? (
                    <Button
                      disabled={saving}
                      size='small'
                      color='transparent'
                      style={buttonStyle(primaryColor, true)}
                      onClick={handleSave}
                      loading={saving}
                    >
                      SAVE
                    </Button>
                  ) : (
                    <Button
                      size='small'
                      color='transparent'
                      style={buttonStyle(primaryColor)}
                      onClick={() => setEditModeActive(true)}
                    >
                      EDIT
                    </Button>
                  )}
                </>
              )}
            />
          </Header>
          <FormStyles>
            <p className='Body' style={bookingExperienceSubheaderStyle}>
              {subheaderText}
            </p>
            <SectionTitle>
              <h4>Appointment Type</h4>
            </SectionTitle>
            <SwitchSection>
              <TextField
                fullWidth
                value={'Require Medicare info'}
                label='Medicare'
                disabled
                InputProps={{
                  endAdornment: (
                    <Switch
                      disabled={!isEditModeActive}
                      name='askMedicare'
                      value={askMedicare}
                      onChange={() => setAskMedicare(!askMedicare)}
                      checked={askMedicare}
                    />
                  ),
                }}
              />
            </SwitchSection>
            <SwitchSection>
              <TextField
                fullWidth
                value={'Require post-op info, including surgery date'}
                label='Post-Op'
                disabled
                InputProps={{
                  endAdornment: (
                    <Switch
                      disabled={!isEditModeActive}
                      name='askPostOp'
                      value={askPostOp}
                      onChange={() => setAskPostOp(!askPostOp)}
                      checked={askPostOp}
                    />
                  ),
                }}
              />
            </SwitchSection>
            <SwitchSection>
              <TextField
                fullWidth
                value={`Require worker's comp and MVA claim info`}
                label={`Worker's Comp`}
                disabled
                InputProps={{
                  endAdornment: (
                    <Switch
                      disabled={!isEditModeActive}
                      name='askWorkersCompOrMVA'
                      value={askWorkersCompOrMVA}
                      onChange={() =>
                        setAskWorkersCompOrMVA(!askWorkersCompOrMVA)
                      }
                      checked={askWorkersCompOrMVA}
                    />
                  ),
                }}
              />
            </SwitchSection>
            <SectionTitle>
              <h4>Patient Details</h4>
            </SectionTitle>
            <SwitchSection>
              <TextField
                fullWidth
                value={'Include optional secondary insurance input'}
                label='Secondary Insurance'
                disabled
                InputProps={{
                  endAdornment: (
                    <Switch
                      disabled={!isEditModeActive}
                      name='askSecondaryInsurance'
                      value={askSecondaryInsurance}
                      onChange={() =>
                        setAskSecondaryInsurance(!askSecondaryInsurance)
                      }
                      checked={askSecondaryInsurance}
                    />
                  ),
                }}
              />
            </SwitchSection>
            <SwitchSection>
              <TextField
                fullWidth
                value={
                  'Require that patient select if they are a return visitor'
                }
                label='Return Visitor'
                disabled
                InputProps={{
                  endAdornment: (
                    <Switch
                      disabled={!isEditModeActive}
                      name='askReturningPatient'
                      value={askReturningPatient}
                      onChange={() =>
                        setAskReturningPatient(!askReturningPatient)
                      }
                      checked={askReturningPatient}
                    />
                  ),
                }}
              />
            </SwitchSection>
            <SwitchSection>
              <TextField
                fullWidth
                value={'Include optional address input'}
                label='Address'
                disabled
                InputProps={{
                  endAdornment: (
                    <Switch
                      disabled={!isEditModeActive}
                      name='askPatientAddress'
                      value={askPatientAddress}
                      onChange={() => setAskPatientAddress(!askPatientAddress)}
                      checked={askPatientAddress}
                    />
                  ),
                }}
              />
            </SwitchSection>
            <SwitchSection>
              <TextField
                fullWidth
                value={`Ask for patient's preferred method of contact`}
                label='Contact'
                disabled
                InputProps={{
                  endAdornment: (
                    <Switch
                      disabled={!isEditModeActive}
                      name='askPreferredPatientContact'
                      value={askPreferredPatientContact}
                      onChange={() =>
                        setAskPreferredPatientContact(
                          !askPreferredPatientContact
                        )
                      }
                      checked={askPreferredPatientContact}
                    />
                  ),
                }}
              />
            </SwitchSection>
            <SwitchSection>
              <TextField
                fullWidth
                value={'Ask how patient heard about your clinics'}
                label='Attribution'
                disabled
                InputProps={{
                  endAdornment: (
                    <Switch
                      disabled={!isEditModeActive}
                      name='askMarketingSource'
                      value={askMarketingSource}
                      onChange={() =>
                        setAskMarketingSource(!askMarketingSource)
                      }
                      checked={askMarketingSource}
                    />
                  ),
                }}
              />
            </SwitchSection>
          </FormStyles>
        </div>
      )}
    </GridCard>
  );
};

export default BookingExperience;
