import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...rest}
  >
    <defs>
      <path
        id='l59xexim7a'
        d='M12 1c6.072 0 11 4.928 11 11s-4.928 11-11 11S1 18.072 1 12 5.928 1 12 1zm.99 4.001h-1.98v6.01H5.016v1.979h5.994v6.02h1.98v-6.02h6.008v-1.98H12.99V5.001z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0L24 0 24 24 0 24z' />
      <mask id='1nftr8odzb' fill='#fff'>
        <use xlinkHref='#l59xexim7a' />
      </mask>
      <use
        fill={color || '#2A4E82'}
        fillRule='nonzero'
        xlinkHref='#l59xexim7a'
      />
      <g fill={color || '#4370A9'} mask='url(#1nftr8odzb)'>
        <path d='M0 0H24V24H0z' />
      </g>
    </g>
  </svg>
);
