//types
import { TextFieldState } from '../../../../../hooks/useTextFieldState';
import { BoolFieldState } from '../../../../../hooks/useBoolFieldState';

// mutations & queries
import {
  useUpdateEmployeeMutation,
  useUpdateEmployeeRoleMutation,
  useMeQuery,
  useMarkEmployeeAsTherapistMutation,
  useResetEmployeeInviteMutation,
  useArchiveEmployeeMutation,
} from '../../../../../generated/graphql';

// helpers
import {
  formatYearsExperience,
  formatPhoneForBackend,
} from '../../../../../helpers';

export interface UpdateEmployeeInfoPayload {
  employeeId: string;
  firstNameFormState: TextFieldState;
  lastNameFormState: TextFieldState;
  genderFormState: TextFieldState;
}

export interface UpdateEmployeeRoleAndPermissionsPayload {
  roleFormState: TextFieldState;
  bioFormState: TextFieldState;
  degreeOrCertificationFormState: TextFieldState;
  yearsExperienceFormState: TextFieldState;
  isTherapistFormState: BoolFieldState;
  employeeId: string;
}

export interface UpdateLoginAndContactPayload {
  employeeId: string;
  phoneFormState: TextFieldState;
  alternateEmailState: TextFieldState;
}

export interface ResetEmployeeInvitePayload {
  employeeId: string;
}

const useEmployeeOperations = () => {
  const meQuery = useMeQuery();
  const shouldNewEmployeesDefaultToProvider =
    meQuery?.data?.me?.company?.shouldNewEmployeesDefaultToProvider;
  const [resendInvite] = useResetEmployeeInviteMutation();

  const [markEmployeeAsTherapist] = useMarkEmployeeAsTherapistMutation();
  const [updateEmployee] = useUpdateEmployeeMutation();
  const [updateEmployeeRole] = useUpdateEmployeeRoleMutation();
  const [callArchiveEmployee] = useArchiveEmployeeMutation();

  const updateEmployeeInfo = async (payload: UpdateEmployeeInfoPayload) => {
    const {
      firstNameFormState,
      lastNameFormState,
      genderFormState,
      employeeId,
    } = payload;

    if (!employeeId) {
      throw new Error(
        'Your account could not be accessed, please refresh and try again.'
      );
    }
    if (!firstNameFormState.valid) {
      throw new Error('You must provide a valid first name.');
    }
    if (!lastNameFormState.valid) {
      throw new Error('You must provide a valid last name.');
    }
    if (!genderFormState.valid) {
      throw new Error('You must provide a valid gender.');
    }

    await updateEmployee({
      variables: {
        input: {
          employeeId,
          firstName: firstNameFormState.value,
          lastName: lastNameFormState.value,
          gender: genderFormState.value,
        },
      },
    });
  };

  const updateRole = async (
    payload: UpdateEmployeeRoleAndPermissionsPayload
  ) => {
    const {
      roleFormState,
      bioFormState,
      degreeOrCertificationFormState,
      yearsExperienceFormState,
      isTherapistFormState,
      employeeId,
    } = payload;
    if (!employeeId) {
      throw new Error(
        'Your account could not be accessed, please refresh and try again.'
      );
    } else if (!roleFormState.valid) {
      throw new Error('You must provide a valid role.');
    } else if (!bioFormState.valid) {
      throw new Error('You must provide a valid bio');
    } else if (!yearsExperienceFormState.valid) {
      throw new Error('You must provide a valid practice start date');
    }

    await updateEmployeeRole({
      variables: {
        input: {
          employeeId,
          role: roleFormState.value,
        },
      },
    });

    if (!shouldNewEmployeesDefaultToProvider) {
      await markEmployeeAsTherapist({
        variables: {
          input: {
            employeeId,
            isTherapist: !!isTherapistFormState.value,
          },
        },
      });
    }

    await updateEmployee({
      variables: {
        input: {
          employeeId,
          bio: bioFormState.value,
          degreeOrCertification: degreeOrCertificationFormState.value,
          yearsExperience: formatYearsExperience(yearsExperienceFormState),
        },
      },
    });
  };

  const updateLoginAndContact = async (
    payload: UpdateLoginAndContactPayload
  ) => {
    const { phoneFormState, employeeId, alternateEmailState } = payload;
    if (!employeeId) {
      throw new Error(
        'Your account could not be accessed, please refresh and try again.'
      );
    } else if (!alternateEmailState.valid) {
      throw new Error(
        'Please enter a valid email for the alternate telehealth contact email.'
      );
    }

    const formattedPhone = formatPhoneForBackend(phoneFormState.value);
    await updateEmployee({
      variables: {
        input: {
          employeeId,
          phone: !!formattedPhone?.length ? formattedPhone : null,
          alternateTelehealthContactEmail:
            alternateEmailState.value ?? undefined,
        },
      },
    });
  };

  const resetEmployeeInvite = async (payload: ResetEmployeeInvitePayload) => {
    const { employeeId } = payload;
    if (!employeeId) {
      throw new Error(
        'Your account could not be accessed, please refresh and try again.'
      );
    }
    await resendInvite({ variables: { id: employeeId } });
  };

  const archiveEmployee = async (payload: { employeeId: string }) => {
    const { employeeId } = payload;
    if (!+employeeId) {
      throw new Error(
        'This account could not be accessed. Please check the URL and try again.'
      );
    }
    await callArchiveEmployee({
      variables: {
        id: employeeId,
      },
    });
  };

  return {
    commands: {
      updateEmployeeInfo,
      updateRole,
      updateLoginAndContact,
      resetEmployeeInvite,
      archiveEmployee,
    },
  };
};

export default useEmployeeOperations;
