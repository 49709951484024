import * as React from 'react';
import { loader } from 'graphql.macro';

//helpers
import { colorValues, bestTextColor } from '@betterpt/better-components';

//hooks
import { useParams } from 'react-router-dom';
import useFeatureAccessCheck from '../../../../../hooks/useFeatureAccessCheck';
import useSnackbar from '../../../../../hooks/useSnackbar';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';
import {
  useUpdateFacilityTelehealthMutation,
  useAddFacilityToTraditionalSubscriptionMutation,
  useCompanyBillingOverviewQuery,
  useMeQuery,
} from '../../../../../generated/graphql';
import useHandleError from '../../../../../hooks/useHandleError';

//components
import { Link } from 'react-router-dom';
import { Dialog, DialogContent } from '@material-ui/core';
import { Animation, Button, Checkbox } from '@betterpt/better-components';

//gql
const FACILITY_DETAILS = loader('../../../../../gql/facility/Facility.graphql');

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

const ActivateClinicDialog = ({ isOpen, handleClose }: Props) => {
  const { facilityId } = useParams<{ facilityId: string }>();
  const { primaryColor, facilitiesSingularAlias } = useRemoteConfigAliases();
  const {
    isNotWhiteLabel,
    hasTelehealth,
    hasSubscription,
    hasBillingAccess,
    loading: companyFeaturesLoading,
  } = useFeatureAccessCheck();
  const handleError = useHandleError();
  const snackbar = useSnackbar();

  const me = useMeQuery();

  const companyBillingOverview = useCompanyBillingOverviewQuery({
    variables: {
      id: me.data?.me?.company?.id ?? '0',
    },
    onError: () => snackbar?.useGenericErrorMessage(),
    skip: !me.data?.me?.company?.id || !hasBillingAccess,
  });

  const [
    activateInPersonSubscriptionService,
    updateActivateInPersonSubscriptionService,
  ] = React.useState(false);
  const [activateTelehealthService, updateActivateTelehealthService] =
    React.useState(false);

  const [updateFacilityTelehealth, updateFacilityTelehealthOptions] =
    useUpdateFacilityTelehealthMutation();
  const [
    addFacilityToTraditionalSubscription,
    addFacilityToTraditionalSubscriptionOptions,
  ] = useAddFacilityToTraditionalSubscriptionMutation();

  const handleSubmit = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      if (activateInPersonSubscriptionService) {
        await addFacilityToTraditionalSubscription({
          variables: {
            clinicId: facilityId,
          },
        });
      }

      if (activateTelehealthService) {
        await updateFacilityTelehealth({
          variables: {
            input: {
              clinicId: facilityId,
              isTelehealthActive: true,
            },
          },
          refetchQueries: [
            { query: FACILITY_DETAILS, variables: { id: facilityId } },
          ],
        });
      }
      handleClose();
    } catch (e) {
      handleError(e);
    }
  };

  const isSaving =
    updateFacilityTelehealthOptions.loading ||
    addFacilityToTraditionalSubscriptionOptions.loading;
  const isLoading =
    me.loading || companyBillingOverview.loading || companyFeaturesLoading;
  const companyHasNotAcceptedPrepaidSubTerms = hasBillingAccess
    ? !companyBillingOverview.data?.company?.billingOverview
        ?.hasAgreedToSubscriptionTermsForPrepaidSub
    : hasSubscription;
  const companyHasNotAcceptedTransactionalSubTerms = hasBillingAccess
    ? !companyBillingOverview.data?.company?.billingOverview
        ?.hasAgreedToSubscriptionTermsForTransactionalSub
    : hasTelehealth;

  const generateLabel = (telehealth?: boolean) => {
    if (
      telehealth
        ? companyHasNotAcceptedTransactionalSubTerms
        : companyHasNotAcceptedPrepaidSubTerms
    ) {
      if (hasBillingAccess) {
        return (
          <>
            You cannot activate {telehealth ? 'telehealth' : 'in-person'}{' '}
            appointments for this {facilitiesSingularAlias.toLowerCase()} until
            you{' '}
            <Link
              to={'/company/payment'}
              style={{ color: colorValues.betterptblack }}
              className='button-or-link'
            >
              accept the company-level {telehealth ? 'feature' : 'subscription'}{' '}
              terms.
            </Link>
          </>
        );
      } else
        return `You cannot activate ${
          telehealth ? 'telehealth' : 'in-person'
        } appointments for this ${facilitiesSingularAlias.toLowerCase()} until your company owner or administrator accepts the company-level ${
          telehealth ? 'feature' : 'subscription'
        } terms.`;
    } else
      return `Activate ${
        telehealth ? 'telehealth' : 'in-person subscription'
      } service now!`;
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='sm'>
      {isLoading ? (
        <Animation type='providerAppLoader' />
      ) : (
        <DialogContent
          style={{ padding: '55px' }}
          data-testid='activate-clinic-dialog'
        >
          <h2 className='H2' style={{ marginBottom: '30px' }}>
            All set! Select which features to activate for this facility and
            click &quot;Continue.&quot;
          </h2>
          {isNotWhiteLabel && (
            <div style={{ display: 'flex', marginBottom: 10 }}>
              <Checkbox
                onChange={() =>
                  updateActivateInPersonSubscriptionService(
                    !activateInPersonSubscriptionService
                  )
                }
                disabled={companyHasNotAcceptedPrepaidSubTerms}
                inputProps={{
                  ['data-testid' as any]: 'activate-in-person-appointments',
                }}
              />
              <label
                className='Body'
                style={
                  companyHasNotAcceptedPrepaidSubTerms
                    ? { marginTop: 10 }
                    : { marginTop: 0, display: 'flex', alignItems: 'center' }
                }
              >
                {generateLabel()}
              </label>
            </div>
          )}
          <div style={{ display: 'flex' }}>
            <Checkbox
              onChange={() =>
                updateActivateTelehealthService(!activateTelehealthService)
              }
              disabled={companyHasNotAcceptedTransactionalSubTerms}
              inputProps={{
                ['data-testid' as any]: 'activate-telehealth-appointments',
              }}
            />
            <label
              className='Body'
              style={
                companyHasNotAcceptedTransactionalSubTerms
                  ? { marginTop: 10 }
                  : { marginTop: 0, display: 'flex', alignItems: 'center' }
              }
            >
              {generateLabel(true)}
            </label>
          </div>
          <Button
            fullWidth
            size='large'
            style={{
              backgroundColor: primaryColor,
              color: bestTextColor(primaryColor),
              marginTop: '40px',
            }}
            disabled={isSaving}
            loading={isSaving}
            onClick={handleSubmit}
          >
            CONTINUE
          </Button>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ActivateClinicDialog;
