import React from 'react';

// helpers
import {
  RoleToString,
  Roles,
  RolesArray,
} from '../../../../../../helpers/rbac-rules';

//Hooks
import { useRouteMatch } from 'react-router-dom';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import useFeatureAccessCheck from '../../../../../../hooks/useFeatureAccessCheck';
import useTextFieldState from '../../../../../../hooks/useTextFieldState';
import useBoolFieldState from '../../../../../../hooks/useBoolFieldState';
import {
  useEmployeeQuery,
  useMeQuery,
  Employee,
} from '../../../../../../generated/graphql';
import useHandleError from '../../../../../../hooks/useHandleError';

// Components
import { Animation, colorValues, Switch } from '@betterpt/better-components';
import { GridCard } from '../../../../../Shared/GridCard';
import FormField from '../../../../../Shared/FormField';
import FacilitySelector from '../../../FacilitySelector';
import CardHeader from './CardHeader';
import ThemedTextField from '../../../../../Shared/ThemedTextField';

//style
import { FormBody } from '../../styles/EmployeeDetail.style';

// types
import { UpdateEmployeeRoleAndPermissionsPayload } from '../../hooks/useEmployeeOperations';

// helpers
import {
  validateBio,
  normalizeMonthYearDate,
  validateExperience,
} from '../../../../../../helpers';
import { format, parse } from 'date-fns';

interface Props {
  updateRole: (input: UpdateEmployeeRoleAndPermissionsPayload) => Promise<void>;
}

const RolesAndPermissions = ({ updateRole }: Props) => {
  //hooks
  const handleError = useHandleError();
  const {
    params: { employeeId },
  } = useRouteMatch<{ employeeId: string }>();
  const { isNotWhiteLabel, loading: featuresLoading } = useFeatureAccessCheck();
  const [saving, updateSaving] = React.useState(false);
  const queryEmployee = useEmployeeQuery({
    variables: { id: employeeId },
    skip: !employeeId,
  });
  const meQuery = useMeQuery();

  const snackbar = useSnackbar();
  const [isEditModeActive, setEditModeActive] = React.useState(false);
  const roleFormState = useTextFieldState();
  const bioFormState = useTextFieldState({ validation: validateBio });
  const degreeOrCertificationFormState = useTextFieldState();
  const yearsExperienceFormState = useTextFieldState({
    normalizer: normalizeMonthYearDate,
    validation: validateExperience,
  });
  const isTherapistFormState = useBoolFieldState();

  React.useEffect(() => {
    const employee = queryEmployee.data?.employee;
    if (employee) {
      roleFormState.updateValue(employee.role ?? '');
      isTherapistFormState.updateValue(
        queryEmployee.data?.employee?.isTherapist ?? false
      );
      bioFormState.updateValue(employee?.bio ?? '');
      degreeOrCertificationFormState.updateValue(
        employee?.degreeOrCertification ?? ''
      );
      const formattedYearsExperience = employee?.yearsExperience
        ? format(
            parse(employee?.yearsExperience, 'yyyy-MM-dd', new Date()),
            'MM/yyyy'
          )
        : '';
      yearsExperienceFormState.updateValue(formattedYearsExperience);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryEmployee.data]);

  //functions
  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateSaving(true);
    try {
      await updateRole({
        employeeId,
        roleFormState,
        bioFormState,
        degreeOrCertificationFormState,
        yearsExperienceFormState,
        isTherapistFormState,
      });
      snackbar?.setSuccessMessage('User Info Update Success');
      setEditModeActive(false);
    } catch (e) {
      handleError(e);
    }
    updateSaving(false);
  };

  const rolesChecker = (role: Roles) => {
    if (
      meQuery.data?.me?.company?.shouldPreventNewAdminEmployees &&
      role === Roles.admin
    ) {
      return false;
    }
    if (
      meQuery.data?.me?.company?.shouldPreventNewBasicEmployees &&
      role === Roles.self
    ) {
      return false;
    }
    return true;
  };

  // values
  const loading = queryEmployee.loading || featuresLoading;
  const shouldDisableYextFields =
    !!queryEmployee.data?.employee?.isManagedViaYext;
  const shouldNewEmployeesDefaultToProvider =
    meQuery?.data?.me?.company?.shouldNewEmployeesDefaultToProvider;

  return (
    <GridCard>
      {loading ? (
        <Animation type='providerAppLoader' />
      ) : (
        <form onSubmit={handleSubmit}>
          <CardHeader
            headerText={
              <span>
                Roles and Permissions
                <a
                  style={{
                    fontSize: '12px',
                    color: colorValues.frenchblue,
                    fontWeight: 'bold',
                    marginLeft: '20px',
                  }}
                  className='button-or-link'
                  href='https://betterpt-external-assets.s3.amazonaws.com/Roles+and+Permissions+PDF.pdf'
                  target='_blank'
                  rel='noreferrer'
                >
                  LEARN MORE
                </a>
              </span>
            }
            isEditModeActive={isEditModeActive}
            setEditModeActive={setEditModeActive}
            saving={saving}
            employee={queryEmployee.data?.employee as Employee}
          />
          <FormBody>
            <FormField
              disabled={!isEditModeActive || saving || shouldDisableYextFields}
              fullWidth
              select
              SelectProps={{ native: true }}
              label='Permissions'
              placeholder='select permission'
              formHandler={roleFormState}
            >
              <option value='' disabled>
                Select role
              </option>
              {RolesArray.filter(rolesChecker).map((role) => (
                <option key={role} value={role}>
                  {RoleToString((Roles as any)[role ?? 'initial'])}
                </option>
              ))}
            </FormField>
            <FacilitySelector disabled={!isEditModeActive || saving} />
            {!shouldNewEmployeesDefaultToProvider && (
              <ThemedTextField
                disabled
                label='Provider Status'
                value={`Is${
                  isTherapistFormState.value ? '' : ' not'
                } a care provider`}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <Switch
                      name='providerStatus'
                      value={isTherapistFormState.value}
                      checked={isTherapistFormState.value || false}
                      onChange={() =>
                        isTherapistFormState.updateValue(
                          !isTherapistFormState.value
                        )
                      }
                      disabled={shouldDisableYextFields || !isEditModeActive}
                      inputProps={{
                        ['data-testid' as any]: `is-provider-toggle`,
                      }}
                    />
                  ),
                }}
              />
            )}
            {(isTherapistFormState.value ||
              shouldNewEmployeesDefaultToProvider) &&
              isNotWhiteLabel && (
                <>
                  <FormField
                    label='Degree/Certification'
                    fullWidth
                    disabled={!isEditModeActive}
                    placeholder="Enter is provider's degree or certification"
                    formHandler={degreeOrCertificationFormState}
                    inputProps={{
                      maxLength: 255,
                    }}
                  />
                  <FormField
                    disabled={!isEditModeActive}
                    fullWidth
                    label='Bio'
                    placeholder="Enter this provider's bio"
                    formHandler={bioFormState}
                    multiline
                    helperText={
                      isEditModeActive && !!bioFormState.value
                        ? `${bioFormState.value?.length}/1000`
                        : ''
                    }
                    error={isEditModeActive && !!bioFormState.error?.length}
                  />
                  <FormField
                    disabled={!isEditModeActive}
                    fullWidth
                    label='Practicing Since'
                    placeholder='Enter when this provider started practicing (MM/YYYY)'
                    formHandler={yearsExperienceFormState}
                  />
                </>
              )}
          </FormBody>
        </form>
      )}
    </GridCard>
  );
};

export default RolesAndPermissions;
