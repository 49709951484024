import React from 'react';

import {
  Appointment,
  AppointmentStatus as InPersonStatus,
  ConfirmationStatus,
  useMeQuery,
  Role,
} from '../../../../../../../generated/graphql';

//helpers
import { isBefore } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { normalizePhone } from '../../../../../../../helpers';
import { useAppointmentCopy } from '../InPersonAppointmentStatus';

//hooks
import useRemoteConfigAliases from '../../../../../../../hooks/useRemoteConfigAliases';
import { useRouteMatch, useHistory } from 'react-router-dom';

//Components
import { Card, Header } from '@betterpt/better-components';
import AppointmentInfoSection from './AppointmentInfoSection';
import InPersonAppointmentCardInput from './components/InPersonAppointmentCardInput';
import InPersonStatusCTA from './components/InPersonStatusCTA';
import ValidatedPhoneNumber from '../../../../../../Shared/Picas/ValidatedPhoneNumber';

//styles
import {
  CardBody,
  HeaderOptions,
  ClickableCard,
  InfoSection,
  InfoHeader,
  InfoBody,
  Title,
  Flag,
  ContactContainer,
  Contacted
} from './InPersonAppointmentCard.style';

interface Props {
  appointment: Appointment;
  onDoneChangingOptionsAppointment: () => Promise<any> | undefined;
  isListView?: boolean;
  detailPage?: boolean;
  openStatus: {
    value: boolean;
    update: (payload: boolean) => void;
  };
  selectedApptId?: string | null;
}

const DetailCard = ({
  appointment,
  isListView,
  detailPage,
  onDoneChangingOptionsAppointment,
  openStatus,
  selectedApptId,
}: Props) => {
  const match = useRouteMatch();
  const history = useHistory();
  const meQuery = useMeQuery();
  const {
    facilitiesSingularAlias,
    patientsSingularAlias,
    loading,
  } = useRemoteConfigAliases();

  const patientString = `${
    (loading && '') || (patientsSingularAlias && patientsSingularAlias) || ''
  }`;

  // status variables
  const completed = appointment?.status === InPersonStatus.Completed;
  const confirmed =
    appointment?.confirmationStatus === ConfirmationStatus.Confirmed &&
    appointment?.status === InPersonStatus.Booked;
  const pending =
    appointment?.confirmationStatus === ConfirmationStatus.Pending;
  const activeRequest =
    pending && appointment?.status === InPersonStatus.Booked;
  const requestReceivedSMS =
    !!appointment?.alternateTimesExpireAt && activeRequest;
  const timesExpired =
    requestReceivedSMS &&
    !isBefore(new Date(), new Date(appointment?.alternateTimesExpireAt));
  const waitingForPatient =
    requestReceivedSMS &&
    !timesExpired &&
    !appointment?.alternateTimeResponseStatus;
  const isBasic = meQuery.data?.me?.role === Role.Self;

  //helpers
  const makePatientContactInfo = () => {
    const formatPhone = (phone?: string | null) =>
      phone?.charAt(0) === '1' ? phone.slice(1) : phone;
    return {
      phone:
        formatPhone(
          appointment?.contact?.phone || appointment?.patient?.phone
        ) || 'Not Provided',
      email:
        appointment?.contact?.email ||
        appointment?.patient?.email ||
        'Not Provided',
    };
  };

  const generateEmployeeField = (fieldText: string) => {
    if (!!fieldText.trim().length) {
      return fieldText;
    } else {
      //if no employee info found
      if (activeRequest && !appointment?.employee) {
        return 'Pending Appt. Confirmation';
      } else if (confirmed && !appointment?.employee) {
        return 'Pending Assignment';
      } else return 'Not Provided';
    }
  };

  //appt info
  const patientName = appointment?.contact
    ? `${appointment?.contact.firstName ?? '-'} ${
        appointment?.contact.lastName ?? '-'
      }`
    : `${appointment?.patient?.firstName ?? '-'} ${
        appointment?.patient?.lastName ?? '-'
      }`;
  const { phone: patientPhone, email: patientEmail } = makePatientContactInfo();
  const employeeName = generateEmployeeField(
    `${appointment?.employee?.firstName ?? ''} ${
      appointment?.employee?.lastName ?? ''
    }`
  );
  const employeePhone = generateEmployeeField(
    normalizePhone(appointment?.employee?.phone ?? '')
  );
  const employeeEmail = generateEmployeeField(
    `${appointment?.employee?.email ?? appointment?.providerEmail ?? ''}`
  );
  const appointmentTime = format(
    utcToZonedTime(
      appointment?.startTime ?? new Date(appointment?.startTime),
      appointment?.clinic?.timeZone ?? 'America/New_York'
    ),
    "EEEE, MMMM do, yyyy 'at' h:mm aaaa"
  );

  const requestedTime = `(REQUESTED ${format(
    utcToZonedTime(
      new Date(appointment?.createdAt ?? new Date()),
      appointment?.clinic?.timeZone ?? ''
    ),
    "M/d/yyyy"
  )})`;

  const hasValidMobile = !!(
    appointment?.contact?.hasValidatedMobileNumber ??
    appointment?.patient?.contact?.hasValidatedMobileNumber
  );
  const { headerCopy, flagIcon, flagColor } = useAppointmentCopy(appointment);
  const quickActionsOpen = selectedApptId === appointment.id;
  const cardLinkActive = !quickActionsOpen && isListView;

  return (
    <ClickableCard
      isListView={isListView}
      role={cardLinkActive ? 'button' : undefined}
      tabIndex={cardLinkActive ? 0 : undefined}
      onClick={
        cardLinkActive
          ? () => history.push(`${match.path}/${appointment?.id}`)
          : undefined
      }
      onKeyDown={
        cardLinkActive
          ? (e: any) =>
              e?.key === 'Enter' &&
              history.push(`${match.path}/${appointment?.id}`)
          : undefined
      }
    >
      <Card
        fullWidthAtBreakpoint={1400}
        style={{
          width: '100%',
          borderBottomLeftRadius: isListView ? 5 : 0,
          borderBottomRightRadius: isListView ? 5 : 0,
        }}
        shadow={isListView ? 'short' : 'none'}
      >
        <Header
          underline
          style={{ padding: '12px 38px 17px 38px', position: 'relative' }}
        >
          <Flag color={flagColor}>{flagIcon}</Flag>
          <Title>
            <h3 className='H6'>{headerCopy} {requestedTime}</h3>
            <h2 className='H5'>
              {appointmentTime} – {appointment?.clinic?.clinicName}
            </h2>
          </Title>
          <HeaderOptions>
            <InPersonStatusCTA
              appointment={appointment}
              facilitiesSingularAlias={facilitiesSingularAlias}
              isBasic={isBasic}
              waitingForPatient={waitingForPatient}
              detailPage={!!detailPage}
              activeRequest={activeRequest}
              onDoneChangingOptionsAppointment={
                onDoneChangingOptionsAppointment
              }
              openStatus={openStatus}
            />
          </HeaderOptions>
        </Header>
        <CardBody>
          <InfoSection
            style={{
              marginRight: 34,
            }}
          >
            <InfoHeader>
              <ContactContainer>
                <p>{patientString} Contact</p>
                {appointment?.hasPatientBeenContacted && <Contacted>CONTACTED</Contacted>}
              </ContactContainer>
            </InfoHeader>
            <InfoBody>
              <InPersonAppointmentCardInput
                id={`patientName-${appointment?.id}`}
                label={`Name`}
                value={patientName}
                testId='patient-name'
                isListView={!!isListView}
                data-cy='in-person-appt-patient-name'
              />
              <div style={{ width: '100%', position: 'relative' }}>
                <InPersonAppointmentCardInput
                  id={`patientPhone-${appointment?.id}`}
                  label='Phone'
                  testId='patient-phone'
                  value={normalizePhone(patientPhone ?? '') || 'Not Provided'}
                  isListView={!!isListView}
                  data-cy='in-person-appt-patient-phone'
                />
                {hasValidMobile && (
                  <ValidatedPhoneNumber
                    position='right'
                    mainStyle={{ left: '-30px', top: 18, width: 'min-content' }}
                    message={`We have confirmed this is a valid mobile phone number.`}
                  />
                )}
              </div>
              <InPersonAppointmentCardInput
                id={`patientEmail-${appointment?.id}`}
                label='Email'
                isListView={!!isListView}
                testId='patient-email'
                value={patientEmail}
                data-cy='in-person-appt-patient-email'
              />
            </InfoBody>
          </InfoSection>
          <InfoSection
            style={{
              marginRight: (completed && 34) || 0,
            }}
          >
            <InfoHeader>
              <p>Provider Contact</p>
            </InfoHeader>
            <InfoBody>
              <InPersonAppointmentCardInput
                id={`employeeName-primary-${appointment?.id}`}
                label={`Name`}
                isListView={!!isListView}
                testId='employee-name'
                value={employeeName}
                data-cy='in-person-appt-employee-name'
              />
              <InPersonAppointmentCardInput
                id={`employeePhone-${appointment?.id}`}
                label={`Phone`}
                testId='employee-phone'
                isListView={!!isListView}
                value={employeePhone}
                data-cy='in-person-appt-employee-phone'
              />
              <InPersonAppointmentCardInput
                id={`employeeEmail-${appointment?.id}`}
                label={`Email`}
                testId='employee-email'
                isListView={!!isListView}
                value={employeeEmail}
                data-cy='in-person-appt-employee-email'
              />
            </InfoBody>
          </InfoSection>
          <AppointmentInfoSection
            appointment={appointment}
            isListView={isListView}
          />
        </CardBody>
      </Card>
    </ClickableCard>
  );
};

export default DetailCard;
