import React from 'react';

export const SnackbarContext = React.createContext<{
  setErrorMessage: (error: string) => void;
  setSuccessMessage: (message: string) => void;
  openSnackbar: (payload: { isError: boolean; message: string }) => void;
  errorMessage: string | undefined;
  successMessage: string | undefined;
  useGenericErrorMessage: () => void;
  clear: () => void;
} | null>(null);

export const SnackbarProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [errorMessage, updateErrorMessage] = React.useState<
    string | undefined
  >();
  const [successMessage, updateSuccessMessage] = React.useState<
    string | undefined
  >();

  const setErrorMessage = (error: string) => updateErrorMessage(error);

  const setSuccessMessage = (message: string) => updateSuccessMessage(message);

  const clear = () => {
    updateErrorMessage(undefined);
    updateSuccessMessage(undefined);
  };

  const useGenericErrorMessage = () => {
    updateErrorMessage(
      'Something went wrong! Please refresh and try again. If the issue persists, contact us at support@betterhealthcare.co'
    );
  };

  const values = React.useMemo(() => {
    const openSnackbar = ({
      message,
      isError,
    }: {
      message: string;
      isError: boolean;
    }) => {
      isError ? setErrorMessage(message) : setSuccessMessage(message);
    };

    return {
      setErrorMessage,
      setSuccessMessage,
      clear,
      openSnackbar,
      useGenericErrorMessage,
    };
  }, []);
  return (
    <SnackbarContext.Provider
      value={{
        ...values,
        errorMessage,
        successMessage,
        useGenericErrorMessage,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};
