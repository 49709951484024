import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    width='161'
    height='160'
    viewBox='0 0 161 160'
  >
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <path
                fill='#FF6454'
                d='M91 130.384H0v-22.5c0-20.97 14.19-38.63 33.5-43.89 1.31-.37 2.64-.67 4-.91 2.6-.46 5.27-.7 8-.7 3.44 0 6.78.38 10 1.1 1.36.31 2.69.67 4 1.1 6.93 2.23 13.14 6.09 18.17 11.13 8.24 8.23 13.33 19.61 13.33 32.17v22.5z'
                transform='translate(-719 -272) translate(597 272) translate(122.5) translate(35 9)'
              />
              <path
                fill='#E2956F'
                stroke='#FFF'
                strokeWidth='2'
                d='M57.5 49.884v15c0 4.97-4.926 9-11 9s-11-4.03-11-9v-15h22z'
                transform='translate(-719 -272) translate(597 272) translate(122.5) translate(35 9)'
              />
              <path
                fill='#F8AA7E'
                d='M79.5 34.884c0 9.39-3.8 17.89-9.96 24.04-6.15 6.16-14.65 9.96-24.04 9.96-18.78 0-34-15.22-34-34 0-.37.01-.73.02-1.1.12-3.92.91-7.66 2.24-11.13.18-.47.37-.93.57-1.38 1.71-3.92 4.14-7.45 7.13-10.43C27.61 4.684 36.11.884 45.5.884c7.73 0 14.85 2.57 20.55 6.92.07.04.13.09.2.16 1.16.88 2.26 1.85 3.29 2.88 2.99 2.98 5.42 6.51 7.13 10.43 1.29 2.97 2.18 6.15 2.58 9.49.12.99.2 2 .23 3.02.01.37.02.73.02 1.1'
                transform='translate(-719 -272) translate(597 272) translate(122.5) translate(35 9)'
              />
              <path
                fill='#F8AA7E'
                d='M83.5 38.384c0 1.703-.689 3.245-1.807 4.36-1.115 1.118-2.656 1.807-4.36 1.807-3.406 0-6.166-2.761-6.166-6.167 0-.067.001-.132.003-.2.022-.711.165-1.389.407-2.018.032-.085.067-.169.103-.25.31-.712.751-1.352 1.293-1.892 1.115-1.118 2.657-1.807 4.36-1.807 1.402 0 2.694.466 3.728 1.255.012.008.023.017.036.03.21.159.41.335.596.522.543.54.984 1.18 1.294 1.892.234.538.395 1.115.468 1.721.022.179.036.363.042.547.001.068.003.133.003.2M18.166 38.384c0 1.703-.689 3.245-1.806 4.36-1.115 1.118-2.656 1.807-4.36 1.807-3.406 0-6.167-2.761-6.167-6.167 0-.067.002-.132.003-.2.022-.711.165-1.389.407-2.018.032-.085.067-.169.103-.25.31-.712.752-1.352 1.293-1.892 1.116-1.118 2.657-1.807 4.36-1.807 1.402 0 2.694.466 3.728 1.255.012.008.024.017.037.03.21.159.41.335.596.522.543.54.983 1.18 1.294 1.892.233.538.395 1.115.468 1.721.021.179.035.363.041.547.003.068.003.133.003.2'
                transform='translate(-719 -272) translate(597 272) translate(122.5) translate(35 9)'
              />
              <path
                fill='#323232'
                d='M33.5 44.884c-1.104 0-2-.896-2-2v-3.333c0-1.105.896-2 2-2s2 .895 2 2v3.333c0 1.104-.896 2-2 2M55.5 44.884c-1.104 0-2-.896-2-2v-3.333c0-1.105.896-2 2-2s2 .895 2 2v3.333c0 1.104-.896 2-2 2'
                transform='translate(-719 -272) translate(597 272) translate(122.5) translate(35 9)'
              />
              <path
                fill='#6A564E'
                d='M14.619 32.217c5.629 14.066 9.683 21.1 12.162 21.1 3.718 0 8.719-1.313 17.719-1.313 9 0 8.263 1.312 15 1.312 4.491 0 10.436-7.033 17.834-21.099H79.5c1.333 24.445-9.667 36.667-33 36.667S11.667 56.662 12 32.217h2.619z'
                transform='translate(-719 -272) translate(597 272) translate(122.5) translate(35 9)'
              />
              <path
                fill='#4D4D4D'
                fillOpacity='.05'
                d='M11.5 32.217h3.119c2.94-7.732 5.237-12.71 6.894-14.932 2.484-3.334 13.788 1.346 25.487 0 11.7-1.347 19.497-3.083 21.292-1.541 1.197 1.027 4.21 6.518 9.041 16.473h2.275C75.78 11.328 64.511.884 45.801.884S15.657 11.328 11.5 32.217z'
                transform='translate(-719 -272) translate(597 272) translate(122.5) translate(35 9)'
              />
            </g>
            <g>
              <path
                fill='#333'
                fillRule='nonzero'
                d='M9.492 37.568L37.255 9.41c1.939-1.967 5.104-1.99 7.07-.05.002 0 .003.002.005.003l48.145 47.568c1.96 1.942 1.98 5.106.045 7.074L64.305 92.608c-1.939 1.966-5.105 1.988-7.07.049l-.037-.037L9.505 44.602c-1.932-1.945-1.938-5.082-.013-7.034z'
                transform='translate(-719 -272) translate(597 272) translate(122.5) rotate(180 52 80)'
              />
              <path
                fill='#FFF'
                fillRule='nonzero'
                d='M12.702 40.349l28.561-28.644c.39-.39 1.02-.392 1.412-.005l48.611 48.08c.393.39.396 1.022.008 1.415l-.003.003-6.142 6.16c-1.807-1.013-4.067-.7-5.53.768L69.253 78.522c-1.399 1.403-1.589 3.608-.45 5.229l-6.518 6.537c-.39.391-1.024.392-1.415.002l-.003-.003L12.7 41.759c-.388-.39-.387-1.02.001-1.41z'
                transform='translate(-719 -272) translate(597 272) translate(122.5) rotate(180 52 80)'
              />
              <g>
                <path
                  d='M0.81 0.81H64.017V64.017H0.81z'
                  transform='translate(-719 -272) translate(597 272) translate(122.5) rotate(180 52 80) rotate(138 37.1 42.006)'
                />
                <path
                  fill='#6A564E'
                  d='M47.028 38.162L16.235 38.162 19.692 18.714 44.957 18.714z'
                  transform='translate(-719 -272) translate(597 272) translate(122.5) rotate(180 52 80) rotate(138 37.1 42.006)'
                />
                <g>
                  <path
                    fill='#93F8A9'
                    d='M35.655 46.248v8.855H0v-8.855c0-8.254 5.56-15.205 13.126-17.275.513-.146 1.034-.264 1.567-.359 1.019-.18 2.065-.275 3.135-.275 1.347 0 2.656.15 3.918.433.533.122 1.054.264 1.567.433 2.715.878 5.148 2.397 7.12 4.38 3.228 3.24 5.222 7.719 5.222 12.663'
                    transform='translate(-719 -272) translate(597 272) translate(122.5) rotate(180 52 80) rotate(138 37.1 42.006) translate(15.73 4.802)'
                  />
                  <path
                    fill='#D2E9F8'
                    d='M23.509 28.536v1.035c0 2.05-1.2 3.819-2.934 4.635-.656.309-1.387-2.01-2.16-2.01-.703 0-1.373 2.348-1.983 2.09-1.827-.777-3.11-2.596-3.11-4.715v-1.268c.513-.145 1.034-.263 1.567-.358l7.053.157c.533.123 1.054.264 1.567.434'
                    transform='translate(-719 -272) translate(597 272) translate(122.5) rotate(180 52 80) rotate(138 37.1 42.006) translate(15.73 4.802)'
                  />
                  <path
                    fill='#2B4F83'
                    fillRule='nonzero'
                    d='M21.893 28.495l-6.974-.155c-.413.074-.814.162-1.205.264v.967c0 1.891 1.117 3.576 2.807 4.324.027-.023.062-.059.104-.109.119-.143.205-.279.48-.738.56-.935.815-1.246 1.31-1.246.505 0 .792.312 1.381 1.194.306.457.397.585.526.723.052.055.096.092.127.112 1.616-.78 2.668-2.424 2.668-4.26v-.748c-.389-.12-.794-.229-1.224-.328zm-1.43 5.344l.006.003h-.003l-.004-.003zm3.438-5.303v1.035c0 2.159-1.248 4.091-3.16 4.992-.357.167-.683.021-.989-.304-.172-.184-.27-.32-.606-.824-.4-.598-.628-.845-.73-.845-.064 0-.28.262-.64.863-.3.504-.393.65-.55.838-.284.342-.59.508-.946.357-2.014-.856-3.35-2.843-3.35-5.077v-1.268c0-.176.116-.33.285-.378.52-.148 1.053-.27 1.606-.367l.077-.006 7.052.157.079.01c.567.13 1.096.275 1.602.443.161.053.27.204.27.374z'
                    transform='translate(-719 -272) translate(597 272) translate(122.5) rotate(180 52 80) rotate(138 37.1 42.006) translate(15.73 4.802)'
                  />
                  <path
                    fill='#DF926B'
                    d='M21.942 23.616v5.904c0 1.956-1.58 3.542-3.527 3.542s-3.526-1.586-3.526-3.542v-5.904h7.053z'
                    transform='translate(-719 -272) translate(597 272) translate(122.5) rotate(180 52 80) rotate(138 37.1 42.006) translate(15.73 4.802)'
                  />
                  <path
                    fill='#F8AA7E'
                    d='M31.15 14.366c0 3.696-1.49 7.042-3.903 9.462-2.41 2.425-5.74 3.92-9.42 3.92-7.358 0-13.321-5.99-13.321-13.382 0-.145.004-.287.008-.433.047-1.543.356-3.015.877-4.38.07-.185.145-.366.224-.544.67-1.542 1.622-2.932 2.793-4.105 2.41-2.424 5.74-3.92 9.42-3.92 3.028 0 5.818 1.012 8.051 2.724.028.015.051.035.079.063.454.346.885.728 1.289 1.133 1.171 1.173 2.123 2.563 2.793 4.105.506 1.17.855 2.421 1.011 3.736.047.39.079.787.09 1.188.004.146.008.288.008.433'
                    transform='translate(-719 -272) translate(597 272) translate(122.5) rotate(180 52 80) rotate(138 37.1 42.006) translate(15.73 4.802)'
                  />
                  <path
                    fill='#333'
                    d='M13.322 18.463c-.433 0-.784-.261-.784-.583v-1.02c0-.322.351-.583.784-.583.432 0 .783.261.783.583v1.02c0 .322-.35.583-.783.583M21.942 18.463c-.433 0-.784-.261-.784-.583v-1.02c0-.322.351-.583.784-.583.432 0 .783.261.783.583v1.02c0 .322-.35.583-.783.583'
                    transform='translate(-719 -272) translate(597 272) translate(122.5) rotate(180 52 80) rotate(138 37.1 42.006) translate(15.73 4.802)'
                  />
                  <path
                    fill='#6A564E'
                    d='M18.114 0c2.847 0 5.545 1.54 6.879 2.49l.035-.052c.614.482 1.196 1.013 1.741 1.577 1.582 1.632 2.868 3.565 3.773 5.712.682 1.626 1.153 3.368 1.365 5.197-1.653-.629-3.116-1.4-4.332-2.282h-5.002l-1.712-5.765-1.312 5.765H7.728c-1.216.882-2.68 1.653-4.332 2.282.212-1.83.682-3.57 1.365-5.197.905-2.147 2.19-4.08 3.773-5.712.25-.259.509-.511.774-.755l.098-.338C11.822 1.085 14.84 0 18.114 0z'
                    transform='translate(-719 -272) translate(597 272) translate(122.5) rotate(180 52 80) rotate(138 37.1 42.006) translate(15.73 4.802)'
                  />
                  <path
                    fill='#D2E9F8'
                    d='M19.101 40.737c0 .489-.395.886-.882.886-.486 0-.881-.397-.881-.886s.395-.885.881-.885c.487 0 .882.396.882.885'
                    transform='translate(-719 -272) translate(597 272) translate(122.5) rotate(180 52 80) rotate(138 37.1 42.006) translate(15.73 4.802)'
                  />
                  <path
                    fill='#2B4F83'
                    fillRule='nonzero'
                    d='M18.71 40.737c0-.271-.22-.492-.49-.492s-.49.22-.49.492.22.492.49.492.49-.22.49-.492zm.783 0c0 .706-.57 1.28-1.274 1.28-.703 0-1.273-.574-1.273-1.28 0-.706.57-1.279 1.273-1.279s1.274.573 1.274 1.28z'
                    transform='translate(-719 -272) translate(597 272) translate(122.5) rotate(180 52 80) rotate(138 37.1 42.006) translate(15.73 4.802)'
                  />
                  <path
                    fill='#D2E9F8'
                    d='M19.101 46.543c0 .489-.395.885-.882.885-.486 0-.881-.396-.881-.885 0-.49.395-.886.881-.886.487 0 .882.397.882.886'
                    transform='translate(-719 -272) translate(597 272) translate(122.5) rotate(180 52 80) rotate(138 37.1 42.006) translate(15.73 4.802)'
                  />
                  <path
                    fill='#2B4F83'
                    fillRule='nonzero'
                    d='M18.71 46.543c0-.272-.22-.492-.49-.492s-.49.22-.49.492c0 .271.22.492.49.492s.49-.22.49-.492zm.783 0c0 .706-.57 1.279-1.274 1.279-.703 0-1.273-.573-1.273-1.28 0-.705.57-1.278 1.273-1.278s1.274.573 1.274 1.279z'
                    transform='translate(-719 -272) translate(597 272) translate(122.5) rotate(180 52 80) rotate(138 37.1 42.006) translate(15.73 4.802)'
                  />
                  <path
                    fill='#D2E9F8'
                    d='M19.101 52.348c0 .49-.395.886-.882.886-.486 0-.881-.397-.881-.886s.395-.885.881-.885c.487 0 .882.396.882.885'
                    transform='translate(-719 -272) translate(597 272) translate(122.5) rotate(180 52 80) rotate(138 37.1 42.006) translate(15.73 4.802)'
                  />
                  <path
                    fill='#2B4F83'
                    fillRule='nonzero'
                    d='M18.71 52.348c0-.271-.22-.492-.49-.492s-.49.22-.49.492.22.492.49.492.49-.22.49-.492zm.783 0c0 .706-.57 1.28-1.274 1.28-.703 0-1.273-.574-1.273-1.28 0-.706.57-1.279 1.273-1.279s1.274.573 1.274 1.28z'
                    transform='translate(-719 -272) translate(597 272) translate(122.5) rotate(180 52 80) rotate(138 37.1 42.006) translate(15.73 4.802)'
                  />
                  <path
                    fill='#F8AA7E'
                    d='M8.097 16.86c0 .76-.306 1.449-.803 1.947-.496.5-1.181.807-1.94.807-1.514 0-2.742-1.233-2.742-2.755 0-.03 0-.059.002-.09.01-.317.073-.62.18-.901l.046-.112c.138-.317.334-.604.576-.845.496-.5 1.181-.807 1.939-.807.624 0 1.198.208 1.658.56l.016.014c.093.071.182.15.265.233.241.241.437.528.575.845.104.24.176.499.208.769.01.08.017.162.019.245l.001.09M32.912 16.86c0 .76-.306 1.449-.803 1.947-.496.5-1.181.807-1.94.807-1.514 0-2.742-1.233-2.742-2.755 0-.03 0-.059.002-.09.01-.317.073-.62.18-.901l.046-.112c.138-.317.334-.604.576-.845.495-.5 1.181-.807 1.939-.807.624 0 1.198.208 1.657.56l.017.014c.093.071.182.15.265.233.241.241.437.528.575.845.104.24.176.499.208.769.01.08.016.162.019.245l.001.09'
                    transform='translate(-719 -272) translate(597 272) translate(122.5) rotate(180 52 80) rotate(138 37.1 42.006) translate(15.73 4.802)'
                  />
                </g>
              </g>
            </g>
            <path
              fill='#F8AA7E'
              d='M49.198 133.6c0 2.926-1.186 5.576-3.106 7.494-1.916 1.92-4.566 3.104-7.494 3.104-5.854 0-10.598-4.744-10.598-10.598 0-.116.002-.228.006-.344.044-1.384.352-2.698.876-3.9.532-1.22 1.29-2.322 2.222-3.25 1.918-1.922 4.568-3.106 7.494-3.106 2.928 0 5.578 1.184 7.494 3.106.934.928 1.69 2.03 2.224 3.25.522 1.202.832 2.516.876 3.9.002.116.006.228.006.344M132.2 131.6c0 2.926-1.186 5.576-3.106 7.494-1.918 1.92-4.566 3.104-7.494 3.104-5.854 0-10.6-4.744-10.6-10.598 0-.116.004-.228.008-.344.044-1.384.352-2.698.876-3.9.532-1.22 1.29-2.322 2.222-3.25 1.918-1.922 4.566-3.106 7.494-3.106s5.576 1.184 7.494 3.106c.932.928 1.69 2.03 2.224 3.25.522 1.202.832 2.516.876 3.9.002.116.006.228.006.344'
              transform='translate(-719 -272) translate(597 272) translate(122.5)'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
