import React, { ReactNode } from 'react';

import { Switch, Route, NavLink } from 'react-router-dom';

//types
import { Routes } from './types';

// helpers
import { renderRoutes } from './helper';

// styles
import {
  Wrapper,
  MainNavBar,
  MainViewStyle,
  NavSection,
  LogoSection,
  OpenCloseButton,
  FooterLogo,
  MobileNavBar,
  HamburgerButton,
  OpenNavBackground,
  RightPlaceholderDiv,
} from './styles';

// Icons
import IcCollapse from '../Icons/IcCollapse';
import IcMenu from '../Icons/IcMenu';

import NavHeaderList from './NavList';

type LogoComponent = (props: { isMainNavOpen: boolean } & React.HTMLAttributes<HTMLOrSVGElement>) => ReactNode;

type Props = {
  fixed?: boolean;
  alternateFixed?: boolean;
  routes: Routes;
  primaryColor?: string;
  logo?: string | ReactNode;
  mobileNavLogo?: ReactNode;
  smallLogo?: string | ReactNode;
  logoSectionAsRoute?: {
    logo?: ReactNode | string;
    title?: string | React.ReactNode;
    componentOverride?: LogoComponent; // override the logo section component needs id='logo-section' or id='logo-section-link'
    route: { exact?: boolean; path: string; component: () => ReactNode };
  };
  hideFooterLogo?: boolean;
  title?: string | React.ReactNode;
  isOpenAsDefault?: boolean;
  catchAllView?: ReactNode;
  isMobile?: boolean;
};

/**
 * Main navigation component: creates a react router Route for each path the routes array.
 * -->> Needs to be wrapped with a Router component <<--
 * @param {Props} {
 *   fixed // fix navbar to full page height and make content scrollable,
 *   alternateFixed, // an alternate approach to the fixed which will enable window.scrollTo(0,0) to work consistently
 *   routes, // array of elements or routes to render on navbar, only routes with path will render as view
 *   logo,
 *   title,
 *   isOpenAsDefault,
 *   logoSectionAsRoute?: {
 *     logo?: ReactNode | string;
 *     title?: string | React.ReactNode;
 *     componentOverride?: (props: { isMainNavOpen: boolean }) => ReactNode; // override the logo section component needs   id='logo-section'
 *     route: { exact?: boolean; path: string; component: () => ReactNode };
 * };
 *   catchAllView, // default view if route not found
 *   primaryColor,
 *   hideFooterLogo
 * }
 * @returns
 */
const VerticalNavigation = ({
  routes,
  logo,
  smallLogo,
  title,
  isOpenAsDefault,
  logoSectionAsRoute,
  primaryColor,
  catchAllView,
  hideFooterLogo,
  fixed,
  alternateFixed,
  isMobile,
  mobileNavLogo,
}: Props) => {
  const defaultOpenState = isMobile ? 0 : localStorage.getItem('isMainNavOpen') || 0;
  const [isMainNavOpen, setMainNavOpen] = React.useState(isOpenAsDefault !== undefined ? isOpenAsDefault : !!+defaultOpenState);

  const onClickOpenClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setMainNavOpen(!isMainNavOpen);
    if (!isMobile) {
      localStorage.setItem('isMainNavOpen', isMainNavOpen ? '0' : '1');
    }
  };

  const headerRoutes = routes.header.filter((route) => !route.hideTab);
  const footerRoutes = routes.footer.filter((route) => !route.hideTab);

  const finalLogoToShow = logoSectionAsRoute?.logo || (isMainNavOpen ? logo : smallLogo ?? logo);
  const finalTitleToShow = logoSectionAsRoute?.title || title;

  const showSeparatorLine = !!finalLogoToShow || !!finalTitleToShow;

  return (
    <Wrapper isMobile={isMobile} fixed={fixed}>
      {isMobile && (
        <MobileNavBar>
          <HamburgerButton onClick={() => setMainNavOpen(true)}>
            <IcMenu />
          </HamburgerButton>

          <LogoSection id={`logo-section${logoSectionAsRoute ? '-link' : ''}`} hasTitle={!!title} isMobile={isMobile}>
            {mobileNavLogo ? <div className="logo">{mobileNavLogo}</div> : null}
          </LogoSection>
          <RightPlaceholderDiv />
        </MobileNavBar>
      )}

      {isMobile && isMainNavOpen && <OpenNavBackground onClick={() => setMainNavOpen(false)} />}
      <MainNavBar isMobile={isMobile} isOpen={isMainNavOpen} primaryColor={primaryColor} alternateFixed={alternateFixed}>
        <NavSection isOpen={isMainNavOpen}>
          <div style={{ position: 'relative', width: '100%' }}>
            <NavLink
              tabIndex={logoSectionAsRoute ? 0 : -1}
              style={{
                width: '100%',
                cursor: logoSectionAsRoute ? 'pointer' : 'default',
              }}
              to={(logoSectionAsRoute && logoSectionAsRoute?.route.path) || '#'}
              exact={logoSectionAsRoute?.route.exact}
            >
              {logoSectionAsRoute?.componentOverride ? (
                logoSectionAsRoute.componentOverride({ isMainNavOpen })
              ) : (
                <LogoSection
                  id={`logo-section${logoSectionAsRoute ? '-link' : ''}`}
                  hasTitle={isMainNavOpen && !!title}
                  isOpen={isMainNavOpen}
                  showLine={!!showSeparatorLine}
                >
                  {!!finalLogoToShow || !!finalTitleToShow ? (
                    <div className="logo">
                      {typeof finalLogoToShow === 'string' ? (
                        <img data-testid="logo" src={finalLogoToShow} alt="badger logo" />
                      ) : (
                        finalLogoToShow
                      )}
                      {isMainNavOpen &&
                        finalTitleToShow &&
                        ((typeof finalTitleToShow === 'string' && <p>{finalTitleToShow}</p>) || finalTitleToShow)}
                    </div>
                  ) : null}
                </LogoSection>
              )}
            </NavLink>
            {!isMobile && (
              <OpenCloseButton data-testid="collapse" onClick={onClickOpenClose}>
                <IcCollapse color={primaryColor} />
              </OpenCloseButton>
            )}
          </div>
          <NavHeaderList routes={headerRoutes} isMainNavOpen={isMainNavOpen} onLinkClick={() => isMobile && setMainNavOpen(false)} />
        </NavSection>
        <NavSection isOpen={isMainNavOpen}>
          <NavHeaderList routes={footerRoutes} isMainNavOpen={isMainNavOpen} onLinkClick={() => isMobile && setMainNavOpen(false)} />
          {!hideFooterLogo && <FooterLogo isOpen={isMainNavOpen}>{isMainNavOpen && <p>POWERED BY BETTERACCESS</p>}</FooterLogo>}
        </NavSection>
      </MainNavBar>
      <MainViewStyle fixed={fixed} isMainNavOpen={isMainNavOpen} alternateFixed={alternateFixed}>
        <Switch>
          {[
            logoSectionAsRoute && (
              <Route
                key="logo-route"
                path={logoSectionAsRoute.route.path}
                exact={logoSectionAsRoute.route.exact}
                children={logoSectionAsRoute.route.component}
              />
            ),
            ...renderRoutes([...routes.header, ...routes.footer]),
            <Route key="catch-all-route" children={catchAllView} />,
          ]}
        </Switch>
      </MainViewStyle>
    </Wrapper>
  );
};
export default VerticalNavigation;
