import React, { Fragment } from 'react';

//helpers
import { darkenLightColor } from '@betterpt/better-components';

//hooks
import useRemoteConfigAliases from '../../../../../../../hooks/useRemoteConfigAliases';
import useAppointmentFilters from '../../../../../../../hooks/useInPersonAppointmentFilters';

//components
import { Pagination, Animation } from '@betterpt/better-components';
import InPersonAppointmentCard from '../InPersonAppointmentCard';
import NoAppointmentsView from '../../../../Shared/NoAppointmentsView';

type Props = {
  appointments: any[];
  isLoading: boolean;
  totalCount: number;
  onChangePage: (pageSize: number, idx: number, activeSection: number) => void;
  pagesize: number;
  onDoneChangingOptionsAppointment: () => Promise<any> | undefined;
};

const InPersonAppointmentsCardView = ({
  appointments,
  totalCount,
  onChangePage,
  isLoading,
  pagesize,
  onDoneChangingOptionsAppointment,
}: Props) => {
  const [selectedApptId, setSelectedApptId] = React.useState<string | null>(
    null
  );
  const { defaultPageIndex, defaultTableSection } = useAppointmentFilters();
  const { primaryColor } = useRemoteConfigAliases();

  if (appointments.length === 0 && !isLoading) {
    return (
      <NoAppointmentsView loading={isLoading} isNoMatchingAppointmentsPage />
    );
  }

  return (
    <div data-testid='appointment-details-view-container'>
      <Fragment>
        {appointments.map((appointment) =>
          isLoading ? (
            <Animation type='providerAppLoader' key={appointment.id} />
          ) : (
            appointment && (
              <InPersonAppointmentCard
                key={appointment.id}
                appointment={appointment}
                onDoneChangingOptionsAppointment={
                  onDoneChangingOptionsAppointment
                }
                isListView
                selectedApptId={selectedApptId}
                openStatus={{
                  value: selectedApptId === appointment?.id,
                  update: (isOpen) => {
                    setSelectedApptId(isOpen ? appointment?.id ?? null : null);
                  },
                }}
              />
            )
          )
        )}
      </Fragment>
      <div style={{ opacity: isLoading ? 0 : 1 }}>
        <Pagination
          pageSize={pagesize}
          totalCount={totalCount}
          onChangePage={onChangePage}
          defaultPageIndex={defaultPageIndex.value}
          defaultSection={defaultTableSection.value}
          primaryColor={darkenLightColor(primaryColor)}
        />
      </div>
    </div>
  );
};

export default InPersonAppointmentsCardView;
