import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colorValues } from '../helpers/colors';

type Props = {
  isOpen?: boolean;
  hasTitle?: boolean;
  showLine?: boolean;
  primaryColor?: string;
  alternateFixed?: boolean;
  isMobile?: boolean;
};

export const MobileNavBar = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colorValues.concretejungle};
  min-height: 50px;
  align-items: center;
  z-index: 5;
  background-color: ${colorValues.emptiness};
`;

export const HamburgerButton = styled.button`
  background-color: transparent;
  border: none;
  margin-left: 16px;
  padding: 10px 10px 10px 0;
`;
export const RightPlaceholderDiv = styled.div<Props>`
  width: 24px;
  margin-left: 16px;
`;

export const OpenNavBackground = styled.div<Props>`
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: rgba(51, 51, 51, 0.54);
  z-index: 6;
`;

const makeRGBAColor = (colorAsHex: string) =>
  `rgba(${parseInt(colorAsHex.substring(1, 3), 16)}, ${parseInt(
    colorAsHex.substring(3, 5),
    16
  )}, ${parseInt(colorAsHex.substring(5, 7), 16)}, 0.15)`;

export const Wrapper = styled.div<{ fixed?: boolean; isMobile?: boolean }>`
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: ${({ fixed }) => (fixed && '100vh') || ''};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`;

export const MainNavBar = styled.div<Props>`
  z-index: 101;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  box-shadow: 4px 2px 4px 0 rgba(51, 51, 51, 0.12),
    0 0 2px 0 rgba(51, 51, 51, 0.12);
  width: ${(props: Partial<Props>) =>
    props.isOpen ? 220 : props.isMobile ? 0 : 72}px;
  overflow: ${(props: Partial<Props>) =>
    props.isMobile ? 'scroll' : 'initial'};
  background-color: ${colorValues.emptiness};
  min-height: ${(props: Partial<Props>) => (props.isMobile ? 'auto' : '600px')};
  position: ${(props: Partial<Props>) =>
    props.alternateFixed || props.isMobile ? 'fixed' : 'initial'};
  height: ${(props: Partial<Props>) =>
    props.alternateFixed || props.isMobile ? '100vh' : 'initial'};
  p {
    color: ${colorValues.charcoalgray};
  }
  #logo-section-link {
    &:hover,
    &:focus {
      background-color: ${colorValues.dust};
    }
  }
  .active {
    .listItem {
      background-color: ${({ primaryColor }) =>
        (primaryColor && makeRGBAColor(primaryColor)) || colorValues.frost};
      svg:first-of-type {
        opacity: 1;
      }
    }
    #logo-section-link {
      background-color: ${({ primaryColor }) =>
        (primaryColor && makeRGBAColor(primaryColor)) || ''};
    }
    a,
    p {
      color: ${colorValues.betterptblack};
    }
  }
`;

export const LogoSection = styled.div<Props>`
  border-bottom: ${({ showLine }: Partial<Props>) =>
    showLine ? `1px solid ${colorValues.concretejungle}` : 'none'};
  position: relative;
  display: flex;
  align-items: ${({ hasTitle }: Partial<Props>) =>
    hasTitle ? 'flex-start' : 'center'};
  flex-direction: ${({ hasTitle }: Partial<Props>) =>
    hasTitle ? 'column' : 'row'};
  justify-content: center;
  width: 100%;
  min-height: 24px;
  .logo {
    padding: ${({ isOpen, isMobile }: Partial<Props>) =>
      isMobile ? 0 : isOpen ? '20px 17px 20px 10px' : '20px 0 20px 0'};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 100%;
    display: inline-block;
  }
  svg {
    padding: 7px;
  }
  p {
    display: inline-block;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: ${colorValues.betterptblack};
  }
`;

export const OpenCloseButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  position: absolute;
  bottom: -15px;
  right: -12px;
  cursor: pointer;
  z-index: 1;
`;
export const NavSection = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: ${colorValues.betterptblack};
  a {
    text-decoration: none;
    color: ${colorValues.betterptblack};
  }
  ul {
    list-style: none;
    padding: 0;
    align-self: ${({ isOpen }: Partial<Props>) =>
      isOpen ? 'flex-start' : 'center'};
  }
`;

const TabSharedStyle = (isOpen: boolean | undefined) => css`
  width: ${isOpen ? '187px' : '58px'};
  height: ${isOpen ? '42px' : '58px'};
  border-radius: ${isOpen ? '0 100px 100px 0' : '50%'};
  display: flex;
  align-items: center;
  justify-content: ${isOpen ? 'flex-start' : 'center'};
  p {
    margin-left: 5px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: bold;
  }
  ${isOpen ? 'padding-left: 24px;' : ''}
  ${isOpen ? 'margin: 8px 0 8px 0;' : ''}
  cursor: pointer;
  position: relative;
`;

export const Tab = styled.div<Props>`
  ${({ isOpen }: Partial<Props>) => TabSharedStyle(isOpen)}
  &:hover {
    background-color: ${colorValues.dust};
  }
  svg:first-of-type {
    opacity: 0.54;
  }
`;

export const FooterLogo = styled.div<Props>`
  ${({ isOpen }: Partial<Props>) => ({
    width: isOpen ? '187px' : '58px',
    height: isOpen ? '42px' : '58px',
    margin: isOpen ? '0px 0px 16px' : '',
    paddingLeft: isOpen ? '8px' : '',
    display: 'flex',
    alignItems: 'center',
    justifyContent: isOpen ? 'flex-start' : 'center',
  })}
  p {
    color: ${colorValues.betterptblack} !important;
    display: inline-block;
    cursor: default;
    font-size: 12px;
  }
`;

export const MainViewStyle = styled.div<{
  fixed?: boolean;
  isMainNavOpen?: boolean;
  alternateFixed?: boolean;
}>`
  flex: 1;
  overflow: ${({ fixed }) => (fixed && 'auto') || 'initial'};
  margin-left: ${({ isMainNavOpen, alternateFixed }) =>
    alternateFixed ? (isMainNavOpen ? '220px' : '72px') : 'initial'};
`;
