import * as React from 'react';

//hooks
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';

// components
import { Button, CloseButton, Radio } from '@betterpt/better-components';
import ThemedTextField from '../../../../Shared/ThemedTextField';

//styles
import { colorValues } from '@betterpt/better-components';
import {
  useButtonStyle,
  DialogBody,
  DialogParagraph,
  H1,
  otherReasonStyle,
  RadioButton,
  RadioButtonSection,
  reasonRequiredStyle,
} from './DenyRequestPage.style';

type Props = {
  denyAppointment: () => void;
  closeDialog: () => void;
  saving: boolean;
  denialReason?: {
    value: string | undefined;
    update: (reason: string) => void;
  };
  denialExplanation?: { value: string; update: (reason: string) => void };
};

enum DenialReason {
  insurance = 'insurance',
  time = 'time',
  location = 'location',
  other = 'other',
}

const DenyRequestPage = ({
  denyAppointment,
  closeDialog,
  saving,
  denialReason,
  denialExplanation,
}: Props) => {
  const [reasonRequiredError, toggleReasonRequiredError] = React.useState(
    false
  );

  const {
    patientsSingularAlias,
    facilitiesSingularAlias,
  } = useRemoteConfigAliases();

  const denialReasons = [
    {
      value: DenialReason.insurance,
      label: `Insurance didn't match`,
    },
    {
      value: DenialReason.time,
      label: `Times didn't match`,
    },
    {
      value: DenialReason.location,
      label: `${patientsSingularAlias} booked at a different ${facilitiesSingularAlias.toLowerCase()}`,
    },
    { value: DenialReason.other, label: 'Other' },
  ];

  const handleDeny = () => {
    if (
      denialReason?.value === DenialReason.other &&
      !denialExplanation?.value?.length
    ) {
      toggleReasonRequiredError(true);
    } else {
      denyAppointment();
    }
  };

  return (
    <DialogBody>
      <H1>Decline Appointment Request</H1>
      <DialogParagraph className='Body'>
        If you are sure you wish to decline this request, please choose a reason
        why and click {`"`}Decline appointment request{`"`}. All parties
        involved will receive a notification.
      </DialogParagraph>
      <RadioButtonSection data-cy='appointment-dialog-denial-reasons'>
        {denialReasons.map((reason) => {
          return (
            <RadioButton key={`${reason.value}-radio-button`}>
              <Radio
                name={reason.value}
                value={reason.value}
                checked={denialReason?.value === reason.value}
                onChange={() => denialReason?.update(reason.value)}
              />
              <p
                style={{
                  color:
                    denialReason?.value === reason.value
                      ? colorValues.betterptblack
                      : colorValues.charcoalgray,
                }}
              >
                {reason.label}
              </p>
            </RadioButton>
          );
        })}
        {denialReason?.value === DenialReason.other && (
          <ThemedTextField
            placeholder='Please enter a reason'
            value={denialExplanation?.value}
            error={reasonRequiredError}
            onChange={(e) => {
              if (e.currentTarget.value?.length) {
                toggleReasonRequiredError(false);
              }
              denialExplanation?.update(e.currentTarget.value);
            }}
            style={otherReasonStyle}
            data-cy='custom-denial-reason'
          />
        )}
        {reasonRequiredError && (
          <p style={{ ...otherReasonStyle, ...reasonRequiredStyle }}>
            Required
          </p>
        )}
      </RadioButtonSection>

      <Button
        fullWidth
        size='large'
        color='transparent'
        style={useButtonStyle(colorValues.orangepink)}
        onClick={handleDeny}
        disabled={saving || !denialReason?.value}
        loading={saving}
        data-cy='appointment-dialog-denial-button'
      >
        DECLINE APPOINTMENT REQUEST
      </Button>
      <Button
        fullWidth
        size='large'
        color='transparent'
        style={useButtonStyle(colorValues.emptiness)}
        onClick={closeDialog}
      >
        NEVER MIND
      </Button>
      <CloseButton onClose={closeDialog} />
    </DialogBody>
  );
};

export default DenyRequestPage;
