import { jsx } from '@emotion/react';

import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';

// Icons
import IcAdd from '../Icons/IcAdd';
import IcWarning from '../Icons/IcWarning';

// Constants
import { colorValues } from '../helpers/colors';
import {
  dropSection,
  imageUploader,
  mainStyle,
  avatarHelperText,
} from './style';

type Props = {
  onSubmit: (imgData: any) => void;
  image: string;
  type: 'avatar' | 'hero';
  disabled?: boolean;
  loading?: boolean;
  width?: number;
  helperText?: string;
  style?: React.CSSProperties;
  innerContainerStyle?: React.CSSProperties;
  onError?(message: string): void;
};

const Div = styled.div``;
const P = styled.p``;

/**
 * This image uploader renders as circle when type = avatar and as rectangle when type = hero.
 * Image uploader accepts jpeg and png file types.
 * @param {Props} {
 *   image: string,
 *   disabled: boolean,
 *   loading: boolean,
 *   onSubmit: (imgData: any) => void,
 *   width: number,
 *   type: hero | avatar
 *   helperText: string
 *   style:  React.CSSProperties
 * }
 * @returns
 */
const ImageUploader = ({
  image,
  disabled,
  loading,
  onSubmit,
  width,
  type,
  helperText,
  style,
  innerContainerStyle,
  onError,
}: Props) => {
  const [rejectedMessage, setRejectedMessage] = React.useState('');

  const onDrop = React.useCallback((acceptedFiles: any, rejectedFiles: any) => {
    if (rejectedFiles.length > 0) {
      setRejectedMessage(
        `Sorry, we don’t support this file type. ${rejectedFiles[0].type}`
      );
    } else {
      setRejectedMessage(rejectedMessage);
    }
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();

      reader.onload = () => {
        const imgData = { base64Img: reader.result, file };
        onSubmit(imgData);
      };
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');

      reader.readAsDataURL(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (rejectedMessage) {
      onError && onError(rejectedMessage);
    }
  }, [rejectedMessage]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFileDialogActive,
  } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
  });

  // Checks if Dropzone is activated by user action or error message
  const isDropzoneActive =
    isDragActive || isFileDialogActive || !!rejectedMessage;

  return (
    <Div style={style} css={mainStyle({ width, type })}>
      <Div css={imageUploader({ width, type })} style={innerContainerStyle}>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p>Saving...</p>
          </div>
        ) : (
          <div>
            <img src={image} alt='uploaded file' />
            {!disabled && (
              <div
                style={{ cursor: (!disabled && 'pointer') || '' }}
                css={dropSection(isDropzoneActive)}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {(!rejectedMessage && (
                  <IcAdd color={colorValues.emptiness} />
                )) || <IcWarning color={colorValues.emptiness} />}
              </div>
            )}
          </div>
        )}
      </Div>
      {helperText && !disabled && <P css={avatarHelperText}>{helperText}</P>}
    </Div>
  );
};

export default ImageUploader;
