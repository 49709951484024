import styled from '@emotion/styled';

export const CardSection = styled.div`
  padding: 18px 25px 5px;
  position: relative;
`;

export const employeeDrawerContainer = {
  position: 'relative' as 'relative',
  maxWidth: '521px',
  width: '100%',
};

export const AutocompleteStyleWrapper = styled.div`
  .MuiAutocomplete-endAdornment {
    top: 0;
  }
  .MuiAutocomplete-popupIndicator {
    padding: 2px !important;
    top: -4px !important;
  }
  .MuiAutocomplete-inputRoot[class*='MuiInput-root'][class*='MuiInput-marginDense']
    .MuiAutocomplete-input:first-of-type {
    padding: 6px 0;
  }
`;

export const generalizedEmployeeDrawerContainer = {
  position: 'relative' as 'relative',
  maxWidth: '521px',
  width: '100%',
  marginTop: '20px',
};

export const GeneralizedAutocompleteStyleWrapper = styled.div<{
  text?: string;
}>`
  .MuiAutocomplete-endAdornment {
    top: -16px;
  }
  .MuiAutocomplete-input {
    height: 19px;
    font-size: ${(props) => (props.text ? '16px' : '14px')};
    font-weight: ${(props) => (props.text ? 'bold' : 'normal')};
  }
  .MuiAutocomplete-inputRoot[class*='MuiInput-root'][class*='MuiInput-marginDense']
    .MuiAutocomplete-input:first-of-type {
    padding: 6px 0;
  }
`;
