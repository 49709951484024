import * as React from 'react';

//types
import { Contact } from '../../../../../../generated/graphql';

//helpers
import {
  addIndefiniteArticle,
  normalizePhone,
} from '../../../../../../helpers';

//hooks
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';

//components
import { Animation, Button, Radio } from '@betterpt/better-components';
import ThemedTextField from '../../../../../Shared/ThemedTextField';

//assets
import { IcSearch } from '@betterpt/better-icons';

//style
import { colorValues, bestTextColor } from '@betterpt/better-components';
import {
  DialogBody,
  RadioButton,
  RadioButtonSection,
  TextFieldContainer,
} from '../LinkContactAndPatientDialog.style';

type Props = {
  saving: boolean;
  potentialMatches: Array<Contact | null | undefined>;
  loadingMatches: boolean;
  selectedSearchContactId?: string;
  searchHasBeenCalled: boolean;
  updateContactMatchSearchText: (searchText: string) => void;
  searchForContactMatches: () => void;
  updateSelectedSearchContactId: (id: string) => void;
  handleLinkExistingContact: () => void;
};

const SearchExistingContactsView = ({
  saving,
  potentialMatches,
  updateContactMatchSearchText,
  searchForContactMatches,
  loadingMatches,
  selectedSearchContactId,
  updateSelectedSearchContactId,
  handleLinkExistingContact,
  searchHasBeenCalled,
}: Props) => {
  const {
    patientsSingularAlias,
    patientsPluralAlias,
    primaryColor,
  } = useRemoteConfigAliases();
  const lowerCasePatientAlias = patientsSingularAlias.toLowerCase();
  const noResultsFound =
    !loadingMatches && searchHasBeenCalled && potentialMatches.length === 0;

  return (
    <DialogBody fontWeight='normal' style={{ overflowY: 'hidden', margin: '16px', paddingBottom: '50px'}}>
      <h2 className='H2'>{patientsSingularAlias} Info for this Appointment</h2>
      <p className='Body'>
        Select the contact you want to link this {lowerCasePatientAlias}&apos;s
        records to
      </p>
      <TextFieldContainer
        style={{ height: '250px', padding: '24px 65px 24px 65px', overflowY: 'scroll' }}
      >
        <ThemedTextField
          fullWidth
          label={`Search ${patientsPluralAlias.toLowerCase()}`}
          placeholder={`Search for ${addIndefiniteArticle(
            patientsSingularAlias
          ).toLowerCase()} and select from the list below`}
          inputProps={{ style: { marginTop: 10 } }}
          InputProps={{
            endAdornment: (
              <Button
                style={{
                  width: '32px',
                  height: '32px',
                  marginBottom: 6,
                  backgroundColor: primaryColor,
                  color: bestTextColor(primaryColor),
                  minWidth: '32px',
                  padding: '0px',
                }}
                onClick={searchForContactMatches}
              >
                <IcSearch color={colorValues.emptiness} />
              </Button>
            ),
          }}
          onChange={(e) => updateContactMatchSearchText(e.currentTarget.value)}
          onKeyDown={(e) => e.key === 'Enter' && searchForContactMatches()}
        />
        {loadingMatches && <Animation type='providerAppLoader' />}
        {noResultsFound && <p>No search results found. Please try again.</p>}
        <RadioButtonSection style={{ overflow: 'auto', marginLeft: '-12px' }}>
          {potentialMatches.map((contact) => {
            const alreadyLinked = !!contact?.patientId;
            if (contact) {
              return (
                <RadioButton key={`${contact.id}-radio-button`}>
                  <Radio
                    checked={selectedSearchContactId === contact.id}
                    onClick={() => updateSelectedSearchContactId(contact.id)}
                    disabled={alreadyLinked}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      color: alreadyLinked
                        ? colorValues.charcoalgray
                        : colorValues.betterptblack,
                    }}
                  >
                    <p style={{ fontSize: 14 }}>
                      {contact.lastName}, {contact.firstName}{' '}
                    </p>
                    <p style={{ fontSize: 12 }}>
                      ({contact.email}, {normalizePhone(contact.phone ?? '')})
                      {alreadyLinked ? (
                        <span style={{ color: colorValues.messyketchup }}>
                          {' '}
                          — already linked to a{' '}
                          {patientsSingularAlias.toLowerCase()}
                        </span>
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                </RadioButton>
              );
            } else return null;
          })}
        </RadioButtonSection>
      </TextFieldContainer>
      <Button
        size='large'
        fullWidth
        style={{
          marginTop: '20px',
          backgroundColor: primaryColor,
          color: bestTextColor(primaryColor),
          whiteSpace: 'nowrap',
          position: 'absolute',
          bottom: '0'
        }}
        loading={saving}
        onClick={handleLinkExistingContact}
        disabled={!selectedSearchContactId}
      >
        FINISH UP
      </Button>
    </DialogBody>
  );
};

export default SearchExistingContactsView;
