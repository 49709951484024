import * as roles from '../helpers/rbac-rules';

export const checkPermissions = (
  role: roles.Roles,
  action: string,
  data?: any
) => {
  const permissions = roles.getRules(role);
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions: string[] = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }
  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = (dynamicPermissions as any)[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

type Props = {
  role: roles.Roles;
  yes: () => any;
  no: () => any;
  perform: string;
  data?: any;
};

const Can = ({ role, perform, data, yes, no }: Props) =>
  checkPermissions(role, perform, data) ? yes() : no();

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
