import * as React from 'react';

// components
import FormField from '../../../../../../Shared/FormField';

// hooks
import useRemoteConfigAliases from '../../../../../../../hooks/useRemoteConfigAliases';

// types
import { TextFieldState } from '../../../../../../../hooks/useTextFieldState';

type ZipAndContactProps = {
  zipcode: TextFieldState;
  phone: TextFieldState;
  email: TextFieldState;
};

export const ZipcodeAndContactInformation: React.FC<React.PropsWithChildren<ZipAndContactProps>> = ({
  zipcode,
  phone,
  email,
}) => {
  const { facilitiesSingularAlias } = useRemoteConfigAliases();

  return (
    <>
      <FormField
        id='zipcode'
        fullWidth
        label='Zipcode'
        placeholder={`Enter the zipcode of your ${facilitiesSingularAlias.toLowerCase()}`}
        formHandler={zipcode}
      />
      <h4 style={{ marginBottom: 22, marginTop: 40 }}>Contact Information</h4>
      <FormField
        id='phone'
        fullWidth
        label='Phone'
        placeholder={`Enter the contact number of your ${facilitiesSingularAlias.toLowerCase()}`}
        formHandler={phone}
      />
      <FormField
        id='email'
        fullWidth
        label='Email'
        placeholder={`Enter the contact email of your ${facilitiesSingularAlias.toLowerCase()}`}
        formHandler={email}
      />
    </>
  );
};

type AddressProps = {
  address: TextFieldState;
  address2: TextFieldState;
};

export const AddressFields: React.FC<React.PropsWithChildren<AddressProps>> = ({
  address,
  address2,
}) => {
  const { facilitiesSingularAlias } = useRemoteConfigAliases();

  return (
    <>
      <FormField
        id='address'
        fullWidth
        label='Address'
        placeholder={`Enter the address of your ${facilitiesSingularAlias.toLowerCase()}`}
        formHandler={address}
        inputProps={{
          maxLength: 255,
        }}
      />
      <FormField
        id='address2'
        fullWidth
        label='Address Line 2'
        placeholder='Enter floor, suite number, etc. (optional)'
        formHandler={address2}
        inputProps={{
          maxLength: 255,
        }}
      />
    </>
  );
};
