import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='24'
    height='25'
    viewBox='0 0 24 25'
  >
    <defs>
      <path
        id='flslthlfga'
        d='M5 4c0 2.75 2.24 4.99 4.99 5h2.54c1.3 0 1.97.72 2.36 1.11l4.47 4.48L17.95 16 14 12.05V24h-2v-6h-2v6H8V10.71C5.11 9.84 3 7.16 3 4zm6 0c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <g transform='translate(-289 -105) translate(245 93.5) translate(0 1) translate(41) translate(3 11)'>
                <path d='M0 0H24V24H0z' />
                <mask id='ceurgwm3ib' fill={color || '#fff'}>
                  <use xlinkHref='#flslthlfga' />
                </mask>
                <use fill='#000' fillRule='nonzero' xlinkHref='#flslthlfga' />
                <g fill={color || '#FFF'} mask='url(#ceurgwm3ib)'>
                  <path d='M0 0H24V24H0z' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
