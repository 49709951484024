import React from 'react';

// helpers
import { Roles, RoleToString } from '../../../../helpers/rbac-rules';
import { darkenLightColor, colorValues } from '@betterpt/better-components';

//hooks
import {
  useCompanyEmployeesQuery,
  useMeQuery,
} from '../../../../generated/graphql';
import { useHistory } from 'react-router-dom';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import useDebounce from '../../../../hooks/useDebounce';
import useSnackbar from '../../../../hooks/useSnackbar';

//components
import { Table, Card } from '@betterpt/better-components';
import NoEmployeesFound from './NoEmployeesFound';

//assets
import { IcUnavailable, IcSuccessCircle } from '@betterpt/better-icons';

export const facilitiesString = (clinics: any) => {
  if (!clinics || !clinics?.[0]) return '-';
  const totalClinics = clinics.length;
  const firstClinic = clinics[0];

  return `${firstClinic.displayName}${(totalClinics > 1 &&
    ` +${totalClinics - 1}`) ||
    ''}`;
};

export const hasLoggedIn = (hasLoggedIn?: boolean | null) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    {(hasLoggedIn && <IcSuccessCircle color={colorValues.guajirogreen} />) || (
      <IcUnavailable color={colorValues.messyketchup} />
    )}
    <p style={{ margin: '0 0 0 10px' }}>{(hasLoggedIn && 'Yes') || 'No'}</p>
  </div>
);

const EmployeesTable = ({
  searchTerm,
  resetFilters,
}: {
  searchTerm: string;
  resetFilters: () => void;
}) => {
  const history = useHistory();
  const meQuery = useMeQuery();
  const snackbar = useSnackbar();
  const {
    primaryColor,
    employeeSingularAlias,
    facilitiesSingularAlias,
  } = useRemoteConfigAliases();
  const couldHaveNonProviders = !meQuery.data?.me?.company
    ?.shouldNewEmployeesDefaultToProvider;

  const pageSize = 13;
  const [offset, setOffset] = React.useState(0);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const queryEmployees = useCompanyEmployeesQuery({
    variables: {
      id: meQuery.data?.me?.company?.id!,
      input: { limit: pageSize, offset, query: debouncedSearchTerm },
    },
    onError: () => snackbar?.useGenericErrorMessage(),
    fetchPolicy: 'cache-and-network',
    skip: !meQuery.data?.me?.company?.id,
  });

  React.useEffect(() => {
    if (debouncedSearchTerm) {
      setOffset(0);
    }
  }, [debouncedSearchTerm]);

  const columns = (() => {
    const columnArray = [
      {
        field: 'employeeName',
        title: `${employeeSingularAlias.toUpperCase()} NAME`,
      },
      {
        field: 'id',
        title: 'ID',
      },
      { field: 'email', title: `${employeeSingularAlias.toUpperCase()} EMAIL` },
      { field: 'facility', title: `${facilitiesSingularAlias.toUpperCase()}` },
      { field: 'permissions', title: 'PERMISSIONS' },
      { field: 'hasLoggedIn', title: 'HAS LOGGED IN' },
    ];
    if (couldHaveNonProviders) {
      columnArray.splice(3, 0, {
        field: 'providerStatus',
        title: 'PROVIDER STATUS',
      });
    }
    return columnArray;
  })();

  const rowData =
    queryEmployees?.data?.company?.employees?.result?.map((employee) => ({
      id: employee?.id,
      employeeName: `${employee?.firstName} ${employee?.lastName}`,
      email: employee?.email,
      facility: facilitiesString(employee?.clinics),
      providerStatus: `${employee?.isTherapist ? '' : 'Not'} Provider`,
      permissions: RoleToString(Roles[employee?.role ?? 'initial']),
      hasLoggedIn: hasLoggedIn(employee?.hasLoggedIn),
    })) || [];

  const loading = meQuery.loading || queryEmployees?.loading;

  return (
    <Card
      style={{ position: 'relative', width: '100%' }}
      fullWidthAtBreakpoint={1400}
    >
      <Table
        onRowClick={(_e, rowData) => history.push(`/employees/${rowData?.id}`)}
        columns={columns}
        data={rowData}
        isLoading={loading}
        isSearching={!!searchTerm}
        options={{
          minBodyHeight: 690,
          overflow: 'visible' as 'visible',
          pagination: {
            backToFirstPage: offset === 0,
            totalCount:
              queryEmployees?.data?.company?.employees?.pager?.total || 0,
            pagesize: pageSize,
            onChangePage: (pageSize, idx) => {
              window.scrollTo(0, 0);
              setOffset(idx * pageSize);
            },
            primaryColor: darkenLightColor(primaryColor),
          },
          searchNotFoundText: `${employeeSingularAlias} Not Found`,
          searchNotFoundComponent: (
            <NoEmployeesFound resetFilters={resetFilters} />
          ),
        }}
      />
    </Card>
  );
};

export default EmployeesTable;
