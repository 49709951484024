import * as React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';

import { SnackbarProvider } from '../../context/snackbarMessage';

import Welcome from './Welcome';
import CompanyInfo from './CompanyInfo';
import ProfileInfo from './ProfileInfo';
import BillingInfo from './BillingInfo';
import Snackbar from '../Shared/Snackbar';
import AddAdministrator from './AddAdministrator';

const OnboardingRoot = () => {
  const match = useRouteMatch();
  return (
      <SnackbarProvider>
        <Snackbar />
        <Switch>
          <Route path={`${match.path}/welcome`}>
            <Welcome />
          </Route>
          <Route path={`${match.path}/company`}>
            <CompanyInfo />
          </Route>
          <Route path={`${match.path}/profile`}>
            <ProfileInfo />
          </Route>
          <Route path={`${match.path}/add-administrator`}>
            <AddAdministrator />
          </Route>
        </Switch>
      </SnackbarProvider>
  );
};

export default OnboardingRoot;
