import * as React from 'react';

const ICGoogle = ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...rest}
  >
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <path
                fill='#4285F4'
                d='M20.64 12.205c0-.639-.057-1.252-.164-1.841H12v3.481h4.844c-.209 1.125-.843 2.078-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615z'
                transform='translate(-134 -284) translate(65.25 105.135) translate(56.75 166.865) translate(12 12)'
              />
              <path
                fill='#34A853'
                d='M12 21c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H3.957v2.332C5.438 18.983 8.482 21 12 21z'
                transform='translate(-134 -284) translate(65.25 105.135) translate(56.75 166.865) translate(12 12)'
              />
              <path
                fill='#FBBC05'
                d='M6.964 13.71c-.18-.54-.282-1.117-.282-1.71s.102-1.17.282-1.71V7.958H3.957C3.347 9.173 3 10.548 3 12s.348 2.827.957 4.042l3.007-2.332z'
                transform='translate(-134 -284) translate(65.25 105.135) translate(56.75 166.865) translate(12 12)'
              />
              <path
                fill='#EA4335'
                d='M12 6.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C16.463 3.891 14.426 3 12 3 8.482 3 5.438 5.017 3.957 7.958l3.007 2.332C7.672 8.163 9.656 6.58 12 6.58z'
                transform='translate(-134 -284) translate(65.25 105.135) translate(56.75 166.865) translate(12 12)'
              />
              <path
                d='M0 0L24 0 24 24 0 24z'
                transform='translate(-134 -284) translate(65.25 105.135) translate(56.75 166.865) translate(12 12)'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ICGoogle;
