import * as React from 'react';

// componets
import FormField from '../../../../../../Shared/FormField';

// types
import { TextFieldState } from '../../../../../../../hooks/useTextFieldState';

// constants
import states from '../../../../../../../constants/states';

// hooks
import useRemoteConfigAliases from '../../../../../../../hooks/useRemoteConfigAliases';

type Props = {
  city: TextFieldState;
  state: TextFieldState;
  isServiceArea: boolean;
};

const StateAndCity: React.FC<React.PropsWithChildren<Props>> = ({ city, state, isServiceArea }) => {
  const { facilitiesSingularAlias } = useRemoteConfigAliases();

  return (
    <>
      <FormField
        id='city'
        fullWidth
        label='City'
        placeholder={
          isServiceArea
            ? 'Enter a city centrally located in your service area'
            : `Enter ${facilitiesSingularAlias.toLowerCase()}'s city name`
        }
        formHandler={city}
        inputProps={{
          maxLength: 255,
        }}
      />
      <FormField
        id='state'
        fullWidth
        select
        label='State'
        formHandler={state}
        SelectProps={{ native: true }}
      >
        <option disabled value=''>
          Please select from the dropdown
        </option>
        {states.map((state) => (
          <option key={state.value} value={state.value}>
            {state.text}
          </option>
        ))}
      </FormField>
    </>
  );
};

export default StateAndCity;
