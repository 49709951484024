import * as React from 'react';
import { gql } from '@apollo/client/core';
import Auth from '@aws-amplify/auth';
import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { IntercomProvider, useIntercom } from 'react-use-intercom';

const env =
  (window.__RUNTIME_CONFIG__.REACT_APP_NODE_ENV as
    | 'local'
    | 'development'
    | 'staging'
    | 'production') ?? 'local';

const endpoint = window.__RUNTIME_CONFIG__.REACT_APP_GRAPHQL_ENDPOINT

const apolloClient = new ApolloClient({
  uri: endpoint,
  name: `intercom-${env}`,
  version: '0.0.1',
  cache: new InMemoryCache(),
  connectToDevTools: env !== 'production',
});

// QUERIES
const PATIENT_QUERY = gql`
  {
    mep {
      id
      email
      firstName
      lastName
    }
  }
`;

const EMPLOYEE_QUERY = gql`
  {
    me {
      id
      email
      firstName
      lastName
    }
  }
`;

const HASH_QUERY = gql`
  {
    intercomHash {
      hash
    }
  }
`;

export function fetchUserAndIntercomHash(isPatient: boolean) {
  return Auth.currentSession()
    .then((currentSession) => {
      const cognitoToken = currentSession.getIdToken().getJwtToken();
      const context = {
        headers: {
          authorization: cognitoToken,
        },
        fetchPolicy: 'no-cache',
      };

      return Promise.all([
        apolloClient.query({
          query: isPatient ? PATIENT_QUERY : EMPLOYEE_QUERY,
          context,
        }),
        apolloClient.query({
          query: HASH_QUERY,
          context,
        }),
      ]);
    })
    .catch((e) => {
      return e;
    });
}

const INTERCOM_APP_ID = window.__RUNTIME_CONFIG__.REACT_APP_INTERCOM_APP_ID;

const Intercom: React.FC<React.PropsWithChildren<{
  isPatient: boolean;
  customSelector?: string;
  hideDefaultLauncher?: boolean;
}>> = ({ children, isPatient, customSelector, hideDefaultLauncher }) => {
  const { boot, update } = useIntercom();
  console.log(customSelector);

  React.useEffect(() => {
    fetchUserAndIntercomHash(isPatient)
      .then((responses) => {
        const [userResponse, hashRes] = responses ?? [];
        const meOrMep = isPatient ? 'mep' : 'me';
        if (hashRes?.data?.intercomHash && userResponse?.data?.[meOrMep]) {
          boot({
            name: `${userResponse.data?.[meOrMep].firstName +
              ' ' +
              userResponse.data?.[meOrMep].lastName}`,
            email: userResponse.data[meOrMep].email,
            userHash: hashRes.data.intercomHash.hash,
            customAttributes: {
              user_type: isPatient ? 'patient' : 'provider',
            },
            customLauncherSelector: customSelector,
            hideDefaultLauncher,
          });
          return;
        } else {
          boot({
            customLauncherSelector: customSelector,
            hideDefaultLauncher,
          });
        }
        update();
      })
      .catch((e) => {
        console.log(e);
        boot({
          customLauncherSelector: customSelector,
          hideDefaultLauncher,
        });
      });
  }, []);

  return <>{children}</>;
};

export const IntercomWrapper: React.FC<React.PropsWithChildren<{
  isPatient: boolean;
  customSelector?: string;
  hideDefaultLauncher?: boolean;
}>> = ({ children, isPatient, customSelector, hideDefaultLauncher }) => {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <Intercom
        hideDefaultLauncher={hideDefaultLauncher}
        customSelector={customSelector}
        isPatient={isPatient}
      >
        {children}
      </Intercom>
    </IntercomProvider>
  );
};
