import React from 'react';

//types
import {
  AlternateTimeResponseStatus,
  Appointment,
} from '../../../../../../../generated/graphql';

//helpers
import { format, isBefore } from 'date-fns';
import { useAppointmentCopy } from '../InPersonAppointmentStatus';

// hooks
import { useMeQuery } from '../../../../../../../generated/graphql';
import useRemoteConfigAliases from '../../../../../../../hooks/useRemoteConfigAliases';

//components
import { Animation } from '@betterpt/better-components';
import ThemedTextField from '../../../../../../../components/Shared/ThemedTextField';

//styles
import { colorValues } from '@betterpt/better-components';
import {
  AltTimesColumn,
  ExpireDateText,
  InfoSection,
  InfoHeader,
  InfoBody,
} from './InPersonAppointmentCard.style';

type Props = {
  appointment: Appointment;
  isListView?: boolean;
};
const AppointmentInfoSection = ({ appointment, isListView }: Props) => {
  const meQuery = useMeQuery();
  const { patientsSingularAlias } = useRemoteConfigAliases();
  const {
    appointmentType,
    schedulingActivity,
    appointmentDetails,
  } = useAppointmentCopy(appointment);
  const isRequest = appointment?.confirmationStatus === 'pending';
  const requestReceivedSMS = !!appointment?.alternateTimesExpireAt && isRequest;
  const timesExpired =
    requestReceivedSMS &&
    !isBefore(new Date(), new Date(appointment?.alternateTimesExpireAt));
  const waitingForPatient =
    requestReceivedSMS &&
    isRequest &&
    !timesExpired &&
    !appointment?.alternateTimeResponseStatus;
  const needsFollowUp =
    appointment?.alternateTimeResponseStatus ===
    AlternateTimeResponseStatus.NeedsFollowUp;
  const requestedNewTime =
    appointment?.alternateTimeResponseStatus ===
    AlternateTimeResponseStatus.RequestedNewTime;
  const contactThisPatient =
    needsFollowUp ||
    requestedNewTime ||
    (timesExpired && !appointment?.alternateTimeResponseStatus);
  const alternateTime1 = format(
    new Date(appointment?.alternateTime1 ?? new Date()),
    'M/d @ h:mm a'
  );
  const alternateTime2 = format(
    new Date(appointment?.alternateTime2 ?? new Date()),
    'M/d @ h:mm a'
  );
  const alternateTimesExpireAt = format(
    new Date(appointment?.alternateTimesExpireAt ?? new Date()),
    'M/d @ h:mm a'
  );

  const altTimesHelperTextColor = () => {
    if (waitingForPatient) {
      return colorValues.guajirogreen;
    } else if (contactThisPatient) {
      return colorValues.messyketchup;
    } else return colorValues.cityblock;
  };
  const expireTimeCopy = () => {
    if (waitingForPatient) {
      return `These times will expire if the ${patientsSingularAlias.toLowerCase()} doesn't reply before ${alternateTimesExpireAt}`;
    } else if (contactThisPatient) {
      if (requestedNewTime) {
        return `Please contact this ${patientsSingularAlias.toLowerCase()}, neither time works for them.`;
      } else if (needsFollowUp) {
        return `Please contact this ${patientsSingularAlias.toLowerCase()}, they replied after these times expired.`;
      } else
        return `Please contact this ${patientsSingularAlias.toLowerCase()}, they did not respond before the times expired.`;
    } else
      return `These times expired because the ${patientsSingularAlias.toLowerCase()} didn't select a time before ${alternateTimesExpireAt}`;
  };

  if (meQuery.loading) {
    return <Animation type='providerAppLoader' />;
  }

  const qualifyingInfoAdded =
    !!appointment.customFormResponse &&
    Object.keys(appointment.customFormResponse).length > 0;
  const qualifyingQuestionResponse = qualifyingInfoAdded
    ? 'Qualifying Info Added'
    : 'No Qualifying Info';

  return (
    <InfoSection
      style={{ margin: '0', marginLeft: '34px', width: '40%' }}
      data-testid='appointment-details'
    >
      <InfoHeader>
        <p>Appointment Status</p>
      </InfoHeader>
      <InfoBody>
        <ThemedTextField
          id={`appointmentType-${appointment?.id}`}
          label='Type'
          fullWidth
          InputProps={{ disableUnderline: true }}
          value={appointmentType}
          disabled
          data-private
          inputProps={{
            style: {
              cursor: isListView ? 'pointer' : 'default',
              fontSize: '16px',
            },
          }}
          data-cy='in-person-appt-type'
        />
        <ThemedTextField
          id={`schedulingActivity-${appointment?.id}`}
          label='Scheduling Activity'
          fullWidth
          InputProps={{
            disableUnderline: true,
          }}
          value={schedulingActivity}
          disabled
          data-private
          inputProps={{
            style: {
              cursor: isListView ? 'pointer' : 'default',
              fontSize: '16px',
            },
          }}
          data-cy='in-person-appt-scheduling-activity'
        />
        <ThemedTextField
          id={`qualifyingQuestions-${appointment?.id}`}
          label='Info'
          fullWidth
          InputProps={{ disableUnderline: true }}
          value={qualifyingQuestionResponse}
          disabled
          data-private
          inputProps={{
            style: {
              fontSize: '16px',
            },
          }}
          data-cy='in-person-appt-info'
        />
        {requestReceivedSMS && (
          <AltTimesColumn>
            <ThemedTextField
              id={`alternateTimes-${appointment?.id}`}
              label='Alternate Times Offered'
              fullWidth
              InputProps={{
                disableUnderline: true,
              }}
              value={`${alternateTime1}, ${alternateTime2}`}
              disabled
              data-private
              inputProps={{
                style: {
                  cursor: isListView ? 'pointer' : 'default',
                  fontSize: '16px',
                  color: waitingForPatient
                    ? colorValues.betterptblack
                    : colorValues.cityblock,
                },
              }}
            />
            <ExpireDateText
              style={{
                color: altTimesHelperTextColor(),
              }}
            >
              {expireTimeCopy()}
            </ExpireDateText>
          </AltTimesColumn>
        )}
      </InfoBody>
    </InfoSection>
  );
};

export default AppointmentInfoSection;
