import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...rest}
  >
    <g fill='none' fillRule='evenodd'>
      <path
        fill={color || '#2A4E82'}
        fillRule='nonzero'
        d='M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z'
      />
      <path d='M0 0L24 0 24 24 0 24z' />
    </g>
  </svg>
);
