/* Refactored on 3/10/2021 */

import * as React from 'react';
import { Link, useParams } from 'react-router-dom';

// components
import Dialog from '@material-ui/core/Dialog';
import {
  Animation,
  Button,
  Checkbox,
  CloseButton,
} from '@betterpt/better-components';
import ThemedTextField from '../../../../../Shared/ThemedTextField';

// hooks
import {
  useCompanyListItemsQuery,
  useFacilityEmployeesQuery,
  useMeQuery,
} from '../../../../../../generated/graphql';
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import useHandleError from '../../../../../../hooks/useHandleError';

// types
import {
  CompanyListItemTypeEnum,
  CompanyListItem,
  Maybe,
} from '../../../../../../generated/graphql';

// styles
import {
  DialogBody,
  StaffTableContainer,
  StaffTable,
  StaffMemberLabel,
  generateButtonStyle,
  searchTextStyle,
} from './AddEmployeesToClinicDialog.style';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  addEmployeesToClinic: (facilityIds: string[]) => Promise<void>;
}

const AddEmployeesToClinicDialog = ({
  isOpen,
  handleClose,
  addEmployeesToClinic,
}: Props) => {
  const meQuery = useMeQuery();
  const handleError = useHandleError();
  const { facilityId } = useParams<{ facilityId: string }>();
  const [selectedStaff, updateSelectedStaff] = React.useState<string[]>([]);
  const [searchText, updateSearchText] = React.useState('');
  const [saving, updateSaving] = React.useState(false);

  const snackbar = useSnackbar();

  const staffMembersAtClinic = useFacilityEmployeesQuery({
    variables: {
      id: facilityId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const allStaffMembersQuery = useCompanyListItemsQuery({
    variables: {
      input: {
        companyId: meQuery.data?.me?.company?.id!,
        type: CompanyListItemTypeEnum.Employee,
      },
    },
    skip: !meQuery.data?.me?.company?.id,
    fetchPolicy: 'cache-and-network',
  });

  const queryError = staffMembersAtClinic.error || allStaffMembersQuery.error;

  React.useEffect(() => {
    if (queryError?.message) {
      snackbar?.useGenericErrorMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryError]);

  const staffToShow = allStaffMembersQuery.data?.companyListItems
    ?.filter(
      (staff) =>
        !staffMembersAtClinic.data?.clinic?.employees
          ?.map((e) => e?.id)
          .includes(staff?.id)
    )
    .filter((s) => s?.name?.toLowerCase().includes(searchText.toLowerCase()));

  const {
    employeePluralAlias,
    employeeSingularAlias,
    facilitiesSingularAlias,
    primaryColor,
    secondaryColor,
  } = useRemoteConfigAliases();

  // I think having a separate submitForm function within here is OK. It is responsible
  // for updating relevant pieces of UI like loading state and the snackbar in addition to calling
  // the command it gets passed down as props.
  const submitForm = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    updateSaving(true);
    try {
      await addEmployeesToClinic(selectedStaff);
      snackbar?.setSuccessMessage(
        `Successfully added ${selectedStaff.length} ${
          selectedStaff.length > 1 ? employeePluralAlias : employeeSingularAlias
        } to your ${facilitiesSingularAlias}`
      );
      updateSaving(false);
      handleClose();
    } catch (e) {
      updateSaving(false);
      handleError(e);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      {allStaffMembersQuery.loading ? (
        <Animation type='providerAppLoader' />
      ) : (
        <DialogBody>
          <CloseButton onClose={handleClose} />
          <h2 className='H1'>
            Assign {employeePluralAlias.toLowerCase()} to your{' '}
            {facilitiesSingularAlias.toLowerCase()}
          </h2>
          <p className='Body'>
            Choose the {employeePluralAlias.toLowerCase()} from your company
            that you would like to assign to your{' '}
            {facilitiesSingularAlias.toLowerCase()} and click &quot;Assign to{' '}
            {facilitiesSingularAlias}&quot; or click &quot;Add New{' '}
            {employeeSingularAlias}&quot; if you would like to add someone new.
          </p>
          <StaffTableContainer>
            <ThemedTextField
              onChange={(e) => updateSearchText(e.currentTarget.value)}
              fullWidth
              placeholder='Type to search for a name'
              style={searchTextStyle}
            />
            <StaffTable>
              {staffToShow?.map((staffMember: Maybe<CompanyListItem>) => {
                if (staffMember) {
                  const staffMemberSelected = selectedStaff.includes(
                    staffMember.id
                  );
                  return (
                    <StaffMemberLabel key={staffMember.id}>
                      <Checkbox
                        checked={staffMemberSelected}
                        onChange={() =>
                          updateSelectedStaff(
                            (staff) =>
                              staffMemberSelected
                                ? staff.filter((s) => s !== staffMember.id) // remove staff from array
                                : [...staff, staffMember.id] // add staff to array
                          )
                        }
                      />
                      {staffMember?.name}
                    </StaffMemberLabel>
                  );
                } else return null;
              })}
            </StaffTable>
          </StaffTableContainer>
          <Button
            size='large'
            style={generateButtonStyle(primaryColor)}
            fullWidth
            onClick={submitForm}
            disabled={selectedStaff.length === 0}
            loading={saving}
          >
            ASSIGN SELECTED TO {facilitiesSingularAlias.toUpperCase()}
          </Button>
          <Link to='/employees/new'>
            <Button
              size='large'
              style={generateButtonStyle(secondaryColor)}
              fullWidth
            >
              ADD NEW {employeeSingularAlias.toUpperCase()}
            </Button>
          </Link>
        </DialogBody>
      )}
    </Dialog>
  );
};

export default AddEmployeesToClinicDialog;
