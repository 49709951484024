import * as React from 'react';

//types
import { VideoAppointmentFiltersContextType } from '../../../context/videoAppointmentFilters';
import { InPersonAppointmentFiltersContextType } from '../../../context/inPersonAppointmentFilters';
import { Order } from '../../../generated/graphql';

//components
import { FilterButtonOnly } from '@betterpt/better-components';

//assets
import { IcOrderSwap } from '@betterpt/better-icons';

//styles
import { colorValues } from '@betterpt/better-components';
import * as styles from './Filters.style';

const EmployeesFilter: React.FC<React.PropsWithChildren<{
  filters:
    | VideoAppointmentFiltersContextType
    | InPersonAppointmentFiltersContextType;
}>> = ({ filters }) => {
  const isAscending = filters.dateOrder.value === Order.Asc;
  const filterCopy = isAscending ? 'oldest first' : 'newest first';

  const handleClick = () => {
    filters.dateOrder.update(isAscending ? Order.Desc : Order.Asc);
  };

  return (
    <styles.FilterContainer>
      <FilterButtonOnly
        buttonTitle={filterCopy}
        rotateIcon
        onClick={handleClick}
        icon={<IcOrderSwap color={colorValues.cityblock} />}
      />
    </styles.FilterContainer>
  );
};

export default EmployeesFilter;
