import React from 'react';
import styled from '@emotion/styled';

//components
import { Card, colorValues } from '@betterpt/better-components';
import { ImgAdminComputer } from '@betterpt/better-icons';
import BlockedPageMessage from '../../../Shared/BlockedPageMessage';
import NotificationItem from './NotificationItem';

//gql
import { gql } from '@apollo/client/core';
import {
  Appointment,
  usePendingAppointmentsQuery,
  usePendingVideoAppointmentsQuery,
  VideoAppointment,
} from '../../../../generated/graphql';

//helpers
import { mapPendingAppointmentsToNotificationData, Notification } from '../../../../helpers/mapDataToNotifications';
import { isToday } from 'date-fns';

export const PENDING_APPOINTMENTS_QUERY = gql`
  query PendingAppointments($input: PendingAppointmentsInput) {
    pendingAppointments(input: $input) {
      result {
        id
        clinicId
        startTime
        createdAt
        clinic {
          id
          clinicName
        }
      }
    }
  }
`;

export const PENDING_VIDEO_APPOINEMTNS_QUERY = gql`
  query PendingVideoAppointments($input: PendingVideoAppointmentsInput) {
    pendingVideoAppointments(input: $input) {
      result {
        id
        clinicId
        startTime
        createdAt
        clinic {
          id
          clinicName
        }
      }
    }
  }
`;

// styles
export const cardStyle = {
  width: '90%',
  height: '700px',
  overflow: 'auto',
};

export const noNotificationsStyle = {
  ...cardStyle,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column' as 'column',
};
const HeaderText = styled.h2`
  padding: 20px 33px 20px 44px;
  margin: 0px;
  border-bottom: 1px solid ${colorValues.concretejungle};
`;

const SectionTitleSeparator = styled.div`
  background-color: ${colorValues.earlysmoke};
  padding: 8px 46px;
  p {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
`;

const NotificationPanel = () => {
  const pendingInPersonAppointmentsQuery = usePendingAppointmentsQuery({
    variables: {
      input: {
        limit: 100,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const pendingVideoAppointmentsQuery = usePendingVideoAppointmentsQuery({
    variables: {
      input: {
        limit: 100,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const pendingVideoAppointments =
    mapPendingAppointmentsToNotificationData({
      appointmentData: pendingVideoAppointmentsQuery.data?.pendingVideoAppointments?.result as VideoAppointment[],
      isInPerson: false,
    }) ?? [];

  const pendingInPersonAppointments =
    mapPendingAppointmentsToNotificationData({
      appointmentData: pendingInPersonAppointmentsQuery.data?.pendingAppointments?.result as Appointment[],
      isInPerson: true,
    }) ?? [];

  const allNotifications: Notification[] = [...pendingVideoAppointments!, ...pendingInPersonAppointments!].sort((a, b) =>
    a.timeStamp > b.timeStamp ? -1 : 1
  );

  let newNotifications: Notification[] = [];

  let oldNotifications: Notification[] = [];

  allNotifications.forEach((notification) => {
    if (isToday(new Date(notification.timeStamp))) {
      newNotifications = [...newNotifications, notification];
    } else {
      oldNotifications = [...oldNotifications, notification];
    }
  });

  if (!allNotifications.length) {
    return (
      <Card style={noNotificationsStyle}>
        <BlockedPageMessage
          icon={<ImgAdminComputer />}
          title="You have no notifications!"
          message="Any notifications you get will appear here."
          hideButton={true}
        />
      </Card>
    );
  }

  return (
    <Card style={cardStyle}>
      <HeaderText className="H1">Action Items</HeaderText>
      {!!newNotifications.length && (
        <SectionTitleSeparator>
          <p>NEW</p>
        </SectionTitleSeparator>
      )}
      {newNotifications.map((notification) => (
        <NotificationItem key={notification.id} notification={notification} />
      ))}
      {!!oldNotifications && !!newNotifications.length && (
        <SectionTitleSeparator>
          <p>OLD</p>
        </SectionTitleSeparator>
      )}
      {oldNotifications.map((notification) => (
        <NotificationItem key={notification.id} notification={notification} />
      ))}
    </Card>
  );
};

export default NotificationPanel;
