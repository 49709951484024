import * as React from 'react';

//types
import { CreateInPersonAppointmentForm } from '../../InPersonAppointments/CreateInPersonAppointment/hooks/useCreateInPersonAppointmentFormHandlers';
import { CreateVideoAppointmentForm } from '../../VideoAppointments/CreateVideoAppointment/hooks/useCreateVideoAppointmentFormHandlers';

// hooks
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';
import { useHistory } from 'react-router-dom';

// gql
import {
  useMeQuery,
  Role,
  CompanyListOptionType,
  useCompanyListOptionsQuery,
  CompanyListOption,
  BetterAccessMigrationStatus,
} from '../../../../../generated/graphql';

// components
import FormField from '../../../../Shared/FormField';
import ThemedTextField from '../../../../Shared/ThemedTextField';
import NoFacilitiesWarningDialog from './NoFacilitiesWarningDialog';

//style
import { CardSection } from './FacilityPicker.style';

type Props = {
  form: CreateInPersonAppointmentForm | CreateVideoAppointmentForm;
  facilityType:
    | CompanyListOptionType.TelehealthFacilities
    | CompanyListOptionType.InPersonFacilities;
  disabled?: boolean;
};

const FacilityPicker: React.FC<React.PropsWithChildren<Props>> = ({ form, disabled, facilityType }) => {
  const { facilitiesSingularAlias } = useRemoteConfigAliases();
  const meQuery = useMeQuery();
  const history = useHistory();

  // update query for company list item query
  const queryFacilities = useCompanyListOptionsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        companyId: meQuery.data?.me?.company?.id as string,
        type: facilityType,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const loading = meQuery.loading || queryFacilities.loading;
  const isBasic = meQuery.data?.me?.role === Role.Self;
  const allFacilities = queryFacilities.data?.companyListOptions ?? [];
  const myFacilities = meQuery.data?.me?.clinics ?? [];
  const myActiveMigratedFacilities = myFacilities?.filter(
    (facility) =>
      (facility?.isBetterAccessOnly &&
        facilityType === CompanyListOptionType.TelehealthFacilities &&
        facility?.isTelehealthActive) ||
      (facilityType === CompanyListOptionType.InPersonFacilities &&
        facility?.betterPTSubscriptionActive)
  );
  const facilitiesToDisplay =
    meQuery.data?.me?.role === Role.Owner
      ? allFacilities
      : myActiveMigratedFacilities?.map(
          (facility): CompanyListOption => ({
            name: facility?.displayName,
            id: facility?.id as string,
            timeZone: facility?.timeZone,
          })
        );
  const formHandler = {
    ...form.facilityId,
    updateValue(value: string) {
      form.facilityId.updateValue(value);
      if (!isBasic) form.employeeId.updateValue('');
      // Check selected facility and update timezone
      const selectedFacility:
        | CompanyListOption
        | null
        | undefined = facilitiesToDisplay.find(
        (facility) => facility && facility.id === value
      );
      form.timezone.updateValue(selectedFacility?.timeZone || '');
    },
  };

  const handleClose = () => {
    history.push('/facilities');
  };

  const noFacilities =
    (meQuery.data?.me?.role !== Role.Self && !facilitiesToDisplay.length) ||
    (meQuery.data?.me?.role === Role.Self &&
      facilityType === CompanyListOptionType.TelehealthFacilities &&
      !facilitiesToDisplay.length);
  const noMigratedFacilities =
    meQuery.data?.me?.company?.betterAccessMigrationStatus ===
      BetterAccessMigrationStatus.NotStarted ||
    (meQuery.data?.me?.role !== Role.Owner &&
      !myActiveMigratedFacilities.some(
        (facility) => facility?.isBetterAccessOnly
      ));
  const openWarningDialog = noFacilities || noMigratedFacilities;

  return (
    <CardSection data-testid='facility-picker'>
      <NoFacilitiesWarningDialog
        open={openWarningDialog}
        onClose={handleClose}
        facilityType={facilityType}
        useMigrationCopy={!!noMigratedFacilities}
      />
      <h2 className='H6'>{facilitiesSingularAlias} Info</h2>
      {disabled ? (
        <ThemedTextField
          id='facility'
          label={facilitiesSingularAlias}
          disabled
          InputProps={{ readOnly: true }}
          value={form.facilityId.value}
          select
          SelectProps={{ native: true }}
          inputProps={{
            'data-testid': 'facilities-input',
          }}
          style={{ maxWidth: '521px', width: '100%' }}
        >
          <option disabled value={''}>
            {loading ? 'Loading...' : 'Please select from the dropdown'}
          </option>
          {facilitiesToDisplay.map((facility) => (
            <option value={facility?.id ?? ''} key={facility?.id}>
              {facility?.name}
            </option>
          ))}
        </ThemedTextField>
      ) : (
        <FormField
          id='facility'
          label={`Select ${facilitiesSingularAlias ?? ''}`}
          formHandler={formHandler}
          select
          SelectProps={{ native: true }}
          inputProps={{
            'data-testid': 'facilities-input',
          }}
          style={{ maxWidth: '521px', width: '100%' }}
          disabled={loading}
          data-cy='create-appointment-facilities'
        >
          <option disabled value={''}>
            {loading ? 'Loading...' : 'Please select from the dropdown'}
          </option>
          {facilitiesToDisplay.map((facility) => (
            <option value={facility?.id ?? ''} key={facility?.id}>
              {facility?.name}
            </option>
          ))}
        </FormField>
      )}
    </CardSection>
  );
};

export default FacilityPicker;
