import * as React from 'react';

//helpers
import { differenceInMonths, format, isValid } from 'date-fns';
import { colorValues } from '@betterpt/better-components';
import { normalizeDate } from '../../../../../../../helpers';

//hooks
import useRemoteConfigAliases from '../../../../../../../hooks/useRemoteConfigAliases';

//components
import { Button, CloseButton } from '@betterpt/better-components';
import { DateRange } from 'react-date-range';
import { Dialog } from '@material-ui/core';
import ThemedTextField from '../../../../../../Shared/ThemedTextField';
import SlideTransition from '../../../../../../Shared/SlideTransition';

//styles
import {
  datePickerSelectedColor,
  DatePickerStyleWrapper,
} from '../../../../../../Shared/Filters/Filters.style';
import {
  CSVDateError,
  CSVDatePickerDialogBody,
  CSVDateRangeInputSection,
  CSVDateSection,
} from '../AnalyticsCSV.style';

type Props = {
  open: boolean;
  handleClose: () => void;
  startDate: string;
  endDate: string;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
  sendCSVDownloadEmail: (dates?: { startDate: Date; endDate: Date }) => void;
  loading?: boolean;
};

const CSVDatePickerDialog: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  handleClose,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  sendCSVDownloadEmail,
  loading,
}) => {
  const { primaryColor } = useRemoteConfigAliases();
  const now = new Date();
  // const isJanuary = getMonth(now) === 0;
  // const initialMonth = getMonth(subMonths(now, 1));
  // const initialYear = getYear(isJanuary ? subYears(now, 1) : now);

  const [errorMessage, updateErrorMessage] = React.useState('');
  const [calendarDates, updateCalendarDates] = React.useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: now,
    endDate: now,
  });

  const isDateInvalid = (date?: string) => !!date && !isValid(new Date(date));

  const applyDate = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (isValid(start) && isValid(end)) {
      !!errorMessage.length && updateErrorMessage('');
      sendCSVDownloadEmail({ startDate: start, endDate: end });
    } else updateErrorMessage('Invalid date range');
  };

  const validateDateRange = (months: number) => {
    if (months > 3) {
      updateErrorMessage(
        'Sorry, you cannot download more than three months’ data at a time.'
      );
    } else if (errorMessage.includes('three month')) {
      updateErrorMessage('');
    }
  };

  const updateDateField = (
    newDateText: string,
    dateKey: 'startDate' | 'endDate'
  ) => {
    const isFullDate = (date: string) => date.length === 10;

    const newDate = new Date(newDateText);
    const valid = isValid(newDate) && isFullDate(newDateText);
    const isStart = dateKey === 'startDate';
    const otherDate = isStart
      ? endDate || calendarDates.endDate
      : startDate || calendarDates.startDate;
    const bothInputsFilledOut =
      isFullDate(newDateText) &&
      (isStart ? isFullDate(endDate) : isFullDate(startDate));

    if (isStart) {
      setStartDate(newDateText);
    } else {
      setEndDate(newDateText);
    }
    if (valid) {
      const newStart = isStart ? newDate : calendarDates.startDate;
      const newEnd = isStart ? calendarDates.endDate : newDate;
      updateCalendarDates({
        startDate: newStart,
        endDate: bothInputsFilledOut ? newEnd : newDate, //reset end if starting from scratch
      });
    }
    if (bothInputsFilledOut) {
      const totalMonths = Math.abs(
        differenceInMonths(new Date(otherDate), newDate)
      );

      validateDateRange(totalMonths);
    }
  };

  const showError = !!errorMessage.length;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      disablePortal
      PaperProps={{
        style: {
          width: '880px',
          maxWidth: '880px',
        },
      }}
    >
      <CSVDatePickerDialogBody>
        <h1 className='H6' style={{ color: colorValues.charcoalgray }}>
          CHOOSE A DATE RANGE FOR YOUR CSV
        </h1>
        <p className='Body'>
          Select a date range and click “Send” to recieve an email with your
          analytics by date.
        </p>
        <CSVDateSection>
          <DatePickerStyleWrapper>
            <DateRange
              css={datePickerSelectedColor(primaryColor)}
              direction='horizontal'
              months={2}
              // initialMonth={initialMonth}
              // initialYear={initialYear}
              maxDate={now}
              rangeColors={[primaryColor]}
              color={colorValues.concretejungle}
              ranges={[
                {
                  startDate: calendarDates.startDate,
                  endDate: calendarDates.endDate,
                  key: 'selection',
                  autoFocus: true,
                },
              ]}
              onChange={(dateRange: any) => {
                const months = differenceInMonths(
                  dateRange.selection?.endDate,
                  dateRange.selection?.startDate
                );
                validateDateRange(months);
                updateCalendarDates({
                  startDate: dateRange.selection?.startDate ?? new Date(),
                  endDate: dateRange.selection?.endDate ?? new Date(),
                });
                setStartDate(
                  format(
                    dateRange.selection?.startDate ?? new Date(),
                    'MM/dd/yyyy'
                  )
                );
                setEndDate(
                  format(
                    dateRange.selection?.endDate ?? new Date(),
                    'MM/dd/yyyy'
                  )
                );
              }}
              scroll={{
                monthHeight: 231,
                monthWidth: 231,
                enabled: true,
              }}
              showMonthAndYearPickers={false}
              showDateDisplay={false}
            />
          </DatePickerStyleWrapper>
          <CSVDateRangeInputSection>
            <ThemedTextField
              fullWidth
              name='start'
              value={startDate && normalizeDate(startDate)}
              error={isDateInvalid(startDate)}
              helperText={isDateInvalid(startDate) && 'invalid date'}
              label='From'
              placeholder='MM/DD/YYYY'
              onChange={(e) => updateDateField(e.target.value, 'startDate')}
              style={{ marginBottom: 30 }}
            />
            <ThemedTextField
              fullWidth
              name='end'
              value={endDate && normalizeDate(endDate)}
              error={isDateInvalid(endDate)}
              helperText={isDateInvalid(endDate) && 'invalid date'}
              label='To'
              placeholder='MM/DD/YYYY'
              onChange={(e) => updateDateField(e.target.value, 'endDate')}
              style={{ marginBottom: 60 }}
            />
            {showError && <CSVDateError>{errorMessage}</CSVDateError>}
            <Button
              style={{ backgroundColor: primaryColor, height: 48 }}
              size='small'
              fullWidth
              onClick={applyDate}
              error={showError}
              loading={loading}
              disabled={
                !startDate ||
                !endDate ||
                isDateInvalid(startDate) ||
                isDateInvalid(endDate) ||
                showError
              }
            >
              SEND
            </Button>
          </CSVDateRangeInputSection>
        </CSVDateSection>
        <CloseButton onClose={handleClose} />
      </CSVDatePickerDialogBody>
    </Dialog>
  );
};

export default CSVDatePickerDialog;
