import _ from 'lodash';
import initialSchema from '../schemas/initialFormSchema.json';

export function mapFormDataToCustomFormSchema(newRawFormQuestions: any[]) {
  const newFormSchema = _.cloneDeep(initialSchema) as any;
  const order: string[] = [];
  if (!newRawFormQuestions) return newFormSchema;

  newRawFormQuestions.forEach((question) => {
    order.push(question.questionText);
    switch (question['What type of question do you want to ask?']) {
      case 'Open-ended':
        newFormSchema.properties[question.questionText] = {
          type: 'string',
          title: question.questionText,
          maxLength: 1000,
        };
        if (question.required) {
          newFormSchema.required = [
            ...newFormSchema.required,
            question.questionText,
          ];
        }
        break;
      case 'Multiple choice':
        newFormSchema.properties[question.questionText] = {
          type: 'string',
          title: question.questionText,
          enum: question.responses,
        };
        if (question.required) {
          newFormSchema.required = [
            ...newFormSchema.required,
            question.questionText,
          ];
        }
        break;
      case 'Yes/No':
        newFormSchema.properties[question.questionText] = {
          type: 'string',
          title: question.questionText,
          enum: ['Yes', 'No'],
        };
        if (question.required) {
          newFormSchema.required = [
            ...newFormSchema.required,
            question.questionText,
          ];
        }
    }
  });
  return {
    customFormTemplate: newFormSchema,
    customFormUISchemaTemplate: { 'ui:order': order },
  };
}

interface QuestionSchema {
  type: string;
  title: string;
  enum?: string[];
}

function determineQuestionType(question: QuestionSchema) {
  if (!question.enum) return 'Open-ended';
  else if (
    question.enum.length === 2 &&
    question.enum.includes('Yes') &&
    question.enum.includes('No')
  )
    return 'Yes/No';
  else return 'Multiple choice';
}

export function mapCustomFormSchemaToFormData(
  formSchema: any,
  uiOrder: string[]
) {
  const formData: any = { questions: [] };
  if (!formSchema || !uiOrder) return null;

  // check that required questions are on top on the current schema order
  const totalRequired = formSchema.required.length;
  const toCheckRequired = uiOrder.slice(0, totalRequired);
  const required = formSchema.required;
  const areRequiredQuestionsInBeginning = required.every((answer: string) =>
    toCheckRequired.includes(answer)
  );

  let newUISchema = uiOrder;
  if (!areRequiredQuestionsInBeginning) {
    // reorganize questions to have required questions on top
    const toDeleteSet = new Set(required);
    const questionsWithoutRequired = uiOrder.filter(
      (question: string) => {
        return !toDeleteSet.has(question);
      }
    );
    const newOrder = [...required, ...questionsWithoutRequired];

    newUISchema = newOrder;
  }

  newUISchema.forEach((questionText) => {
    const questionValue = formSchema.properties[questionText] as QuestionSchema;
    formData.questions = [
      ...formData.questions,
      {
        'What type of question do you want to ask?': determineQuestionType(
          questionValue
        ),
        questionText,
        responses: questionValue.enum,
        required: formSchema.required.includes(questionText),
      },
    ];
  });

  return formData;
}

export function checkTemplatePropertiesExist(
  formSchema: any
) {
  return _.isEmpty(formSchema?.properties) ? "Add" : "Update";
}

// function to check whether questions already exist or not.
export function checkTemplateQuestionsExist(
  formSchema: any
) {
  return formSchema.hasOwnProperty('questions') && formSchema?.questions.length > 0 ? "Update" : "Add";
}

// FROM:
// {
//   "title": "Additional Questions",
//   "description": "This provider would like you to answer some additional questions",
//   "type": "object",
//   "required": [
//     "What kind of therapy do you want?"
//   ],
//   "properties": {
//     "What kind of therapy do you want?": {
//       "type": "string",
//       "title": "What kind of therapy do you want?",
//       "enum": [
//         "OT"
//       ]
//     }
//   }
// }

// TO:
// {
//   "questions": [
//     {
//       "What type of question do you want to ask?": "Multiple choice",
//       "responses": [
//         "OT",
//         "PT"
//       ],
//       "required": false,
//       "questionText": "What kind of therapy do you want?"
//     }
//   ]
// }