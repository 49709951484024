import * as React from 'react';

// helpers
import {
  mapPatientToInsuranceInfo,
  mapPatientToPatientInfo,
} from '../../../../helpers';

// hooks
import { useParams } from 'react-router-dom';
import {
  Patient,
  usePatientContactDetailsQuery,
} from '../../../../generated/graphql';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../hooks/useSnackbar';

// Components
import { Link } from 'react-router-dom';
import {
  Animation,
  Card,
  Button,
  colorValues,
} from '@betterpt/better-components';

// style
import * as styles from './PatientDetail.style';
import { IcArrowLeft, IcSuccessCircle } from '@betterpt/better-icons';
import PatientInfo from './PatientInfo';
import InsuranceInfo from './InsuranceInfo';

const PatientContactDetail = () => {
  const snackbar = useSnackbar();
  const { patientId } = useParams<{ patientId: string }>();

  const { patientsSingularAlias } = useRemoteConfigAliases();
  const patientDetail = usePatientContactDetailsQuery({
    variables: {
      id: patientId,
    },
    onError: () => snackbar?.useGenericErrorMessage(),
  });

  if (patientDetail.loading) {
    return (
      <span data-testid='patient-detail-loader'>
        <Animation type='providerAppLoader' />
      </span>
    );
  }

  return (
    <styles.PatientDetailContainer>
      <styles.PatientDetailsHeader>
        <h1 className='H1'>
          <span style={styles.breadCrumbsLink}>
            <Link to='/patients' style={styles.breadCrumbsLinkItem}>
              Patients /
            </Link>{' '}
            <Link
              to={`/patients/${patientId}`}
              style={styles.breadCrumbsLinkItem}
            >
              {patientDetail.data?.contact.firstName}{' '}
              {patientDetail.data?.contact.lastName} /
            </Link>
          </span>{' '}
          BetterAccess Profile
        </h1>
        <Link
          to={`/patients/${patientId}`}
          style={{
            ...styles.betterAccessPatientButtonContainer,
            width: '93%',
            textAlign: 'right',
          }}
        >
          <Button
            style={{
              ...styles.betterAccessPatientButton,
              padding: '6px 10px',
              width: '93%',
            }}
          >
            <styles.LinkedPatientText>
              <IcArrowLeft style={{ marginRight: '10px' }} />
              GO BACK TO THE CONTACT INFO YOU HAVE ON FILE FOR THIS{' '}
              {patientsSingularAlias.toUpperCase()}
            </styles.LinkedPatientText>
            <IcSuccessCircle color={colorValues.guajirogreen} />
          </Button>
        </Link>
      </styles.PatientDetailsHeader>
      <Card fullWidth>
        <div style={{ display: 'flex' }}>
          <PatientInfo
            data={mapPatientToPatientInfo(
              patientDetail.data?.contact.patient as Patient
            )}
          />
          <InsuranceInfo
            data={mapPatientToInsuranceInfo(
              patientDetail.data?.contact.patient as Patient
            )}
          />
        </div>
      </Card>
    </styles.PatientDetailContainer>
  );
};

export default PatientContactDetail;
