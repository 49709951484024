import * as React from 'react';

// types
import {
  AlternateTimeResponseStatus,
  Appointment,
} from '../../../../../../generated/graphql';

//helpers
import { format, utcToZonedTime } from 'date-fns-tz';

// hooks
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';

// components
import Dialog from '@material-ui/core/Dialog';
import { Animation, Button, CloseButton } from '@betterpt/better-components';
import SlideTransition from '../../../../../Shared/SlideTransition';

//styles
import {
  buttonStyle,
  DialogBody,
  H1,
} from '../InPersonAppointmentDetails.style';

type Props = {
  appointment: Appointment;
  open: boolean;
  onClose: () => void;
};

const ContactThisPatientDialog = ({ appointment, open, onClose }: Props) => {
  const {
    primaryColor,
    patientsSingularAlias,
    loading,
  } = useRemoteConfigAliases();
  const { startTime, alternateTimeResponseStatus } = appointment;
  const timezone = appointment?.clinic?.timeZone ?? 'America/New_York';
  const requestedDate = format(
    utcToZonedTime(new Date(startTime), timezone),
    `MM/dd/yyyy 'at' h:mm a`
  );
  const requestedNewTime =
    alternateTimeResponseStatus ===
    AlternateTimeResponseStatus.RequestedNewTime;
  const NeedsFollowUp =
    alternateTimeResponseStatus === AlternateTimeResponseStatus.NeedsFollowUp;

  const copy = (() => {
    if (requestedNewTime) {
      return {
        opening: `Your ${patientsSingularAlias.toLowerCase()} would like to speak
        with you`,
        reason: `neither option works for them.`,
      };
    } else if (NeedsFollowUp) {
      return {
        opening: `Your ${patientsSingularAlias.toLowerCase()} would like to speak
        with you`,
        reason: `they responded after these times expired.`,
      };
    } else
      return {
        opening: `You should speak with your ${patientsSingularAlias.toLowerCase()}`,
        reason: `they did not respond before the times expired.`,
      };
  })();

  return (
    <Dialog
      TransitionComponent={SlideTransition}
      open={open}
      onClose={onClose}
      maxWidth='sm'
      PaperProps={{
        style: {
          width: 600,
          maxWidth: 600,
        },
      }}
    >
      <DialogBody>
        {loading ? (
          <Animation type='providerAppLoader' />
        ) : (
          <React.Fragment>
            <H1>Please contact this {patientsSingularAlias.toLowerCase()}</H1>
            <p className='Body' style={{ marginBottom: '24px' }}>
              {copy.opening} regarding their appointment request for{' '}
              {requestedDate}. You texted alternate times for this appointment,
              but {copy.reason}
              <br />
              <br />
              {`Click "View Appointment Request" to view the original request details and reach out to this ${patientsSingularAlias.toLowerCase()}.`}
            </p>

            <Button
              fullWidth
              size='large'
              color='transparent'
              style={buttonStyle(primaryColor)}
              onClick={onClose}
            >
              VIEW APPOINTMENT REQUEST
            </Button>
          </React.Fragment>
        )}

        <CloseButton onClose={onClose} />
      </DialogBody>
    </Dialog>
  );
};

export default ContactThisPatientDialog;
