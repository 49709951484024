import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='25'
    height='24'
    viewBox='0 0 25 24'
    {...rest}
  >
    <defs>
      <path id='prefix__a' d='M0 0L24 0 24 24 0 24z' />
    </defs>
    <g fill='none' fillRule='evenodd' transform='translate(1)'>
      <mask id='prefix__b' fill='#fff'>
        <use xlinkHref='#prefix__a' />
      </mask>
      <path
        fill={color || '#fff'}
        fillRule='nonzero'
        d='M12 2c5.5 0 10 4.5 10 10s-4.5 10-10 10S2 17.5 2 12 6.5 2 12 2zm.5 5H11v6l5.2 3.2.8-1.3-4.5-2.7V7z'
        mask='url(#prefix__b)'
      />
    </g>
  </svg>
);
