import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='24'
    height='24'
    viewBox='0 0 24 24'
  >
    <defs>
      <path
        id='lqaq26proa'
        d='M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g transform='translate(-265 -1280) translate(0 -1) translate(245 762) translate(0 408) translate(20 89) translate(0 22)'>
                  <path d='M0 0L24 0 24 24 0 24z' />
                  <mask id='nj6ogkcz4b' fill='#fff'>
                    <use xlinkHref='#lqaq26proa' />
                  </mask>
                  <use
                    fill={color || '#333'}
                    fillRule='nonzero'
                    xlinkHref='#lqaq26proa'
                  />
                  <g fill={color || '#333'} mask='url(#nj6ogkcz4b)'>
                    <path d='M0 0H24V24H0z' />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
