import * as React from 'react';
import * as styles from './Filters.style';

//helpers
import { colorValues } from '@betterpt/better-components';

// hooks
import usePatientFilters from '../../../../../hooks/usePatientFilters';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';

// components
import { Button } from '@betterpt/better-components';
import { FacilitiesFilter } from '../../../../Shared/Filters';
import StatusFilter from './StatusFilter';

// assets
import { IcLoading } from '@betterpt/better-icons';

const Filters: React.FC<React.PropsWithChildren<{ resetFilters: () => void; loading?: boolean }>> = ({
  resetFilters,
  loading,
}) => {
  const filters = usePatientFilters();
  const { primaryColor } = useRemoteConfigAliases();

  return (
    <styles.Filters data-testid='filters-container'>
      <styles.FilterSection>
        <FacilitiesFilter filters={filters} primaryColor={primaryColor} first />
        <StatusFilter filters={filters} primaryColor={primaryColor} />
      </styles.FilterSection>
      <Button
        size='small'
        color='transparent'
        onClick={resetFilters}
        loading={loading}
        style={{ color: colorValues.cityblock }}
      >
        <IcLoading color={colorValues.cityblock} />
        RESET FILTERS
      </Button>
    </styles.Filters>
  );
};

export default Filters;
