import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={color || '#333'}
          d="M8 13C5.667 13 .999 14.167.999 16.5v2.499H15V16.5c0-2.333-4.666-3.5-7-3.5m8.001 0c-.291 0-.617.018-.966.054C16.193 13.892 17 15.017 17 16.5v2.499h5.999V16.5c0-2.333-4.665-3.5-6.998-3.5M8 4.999A3.001 3.001 0 0 0 8 11a2.993 2.993 0 0 0 2.991-3A2.994 2.994 0 0 0 8 4.999m8.001 0a3.002 3.002 0 0 0 0 6.001 2.992 2.992 0 0 0 2.989-3 2.993 2.993 0 0 0-2.989-3.001"
        />
      </g>
    </svg>
  );
