import styled from '@emotion/styled';
import { colorValues, bestTextColor } from '@betterpt/better-components';

export const Page = styled.div`
margin: 20px;
`;

export const NoAnalyticsPage = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormBody = styled.div`
  padding: 20px 23px 30px 42px;
`;

export const Headertext = {
    width: '535px',
    height: '24px',
    margin: '29px 29px ',
    fontFamily: 'Roboto',
    fontSize: '25px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: colorValues.betterptblack,
};
