import styled from '@emotion/styled';

export const CardSection = styled.div`
  padding: 18px 25px 5px;
  position: relative;
`;

export const appointmentTypeDrawerContainer = {
  position: 'relative' as 'relative',
  maxWidth: '521px',
  width: '100%',
};

export const AutocompleteStyleWrapper = styled.div`
  .MuiAutocomplete-endAdornment {
    top: 0;
  }
  .MuiAutocomplete-popupIndicator {
    padding: 2px !important;
    top: -4px !important;
  }
  .MuiAutocomplete-inputRoot[class*='MuiInput-root'][class*='MuiInput-marginDense']
    .MuiAutocomplete-input:first-of-type {
    padding: 6px 0;
  }
`;
