import * as React from 'react';
import { useParams } from 'react-router-dom';
import { TextField } from '@betterpt/better-components';
import useRemoteConfigAliases from '../../../hooks/useRemoteConfigAliases';
import { inputFieldStyle } from './ThemedTextField.style';
import { determineVariant } from '../../authentication/AuthWrapper/loginVariantConfig';

type Props = Parameters<typeof TextField>[0];

const TextFieldCustom: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { variant } = useParams<{ variant: string }>();
  const { textFieldColor } = useRemoteConfigAliases();
  const authPagesPrimaryColor =
    determineVariant(variant).darkColorVariant ??
    determineVariant(variant).color;

  return (
    <TextField
      {...props}
      InputLabelProps={{ shrink: true }}
      overrideStyle={inputFieldStyle(
        variant ? authPagesPrimaryColor : textFieldColor // if there's a variant, then we're on the login pages, so use that, otherwise use the remote config color.
      )}
    />
  );
};

export default TextFieldCustom;
