import * as React from 'react';

//hooks
import { useHistory } from 'react-router-dom';
import useRemoteConfigAliases from '../../../hooks/useRemoteConfigAliases';

//components
import { Button, CloseButton } from '@betterpt/better-components';
import Dialog from '@material-ui/core/Dialog';

//style
import { bestTextColor } from '@betterpt/better-components';
import {
  CongratsDialogContent,
  DialogTitle,
  DialogText,
  DialogThankYouButton,
} from './CongratsDialog.style';

type Props = {
  open: boolean;
  text: string;
};

const CongratsDialog = ({ open, text }: Props) => {
  const history = useHistory();
  const { primaryColor } = useRemoteConfigAliases();

  const [isDialogOpen, setDialogOpen] = React.useState(open);

  const handleClose = () => {
    setDialogOpen(false);
    history.push({
      state: {
        congratsDialogOpen: false,
        congratsDialogText: '',
      },
    });
  };

  return (
    <Dialog
      open={isDialogOpen}
      disablePortal
      PaperProps={{
        style: {
          width: '500px',
          maxWidth: '500px',
        },
      }}
    >
      <CongratsDialogContent>
        <DialogTitle>Congratulations!</DialogTitle>
        <DialogText>{text}</DialogText>
        <DialogThankYouButton>
          <Button
            onClick={handleClose}
            color='no-border'
            size='large'
            fullWidth
            style={{
              color: bestTextColor(primaryColor),
              backgroundColor: primaryColor,
            }}
          >
            THANK YOU
          </Button>
        </DialogThankYouButton>
        <CloseButton onClose={handleClose} />
      </CongratsDialogContent>
    </Dialog>
  );
};

export default CongratsDialog;
