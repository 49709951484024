import React, { CSSProperties, ReactNode } from 'react';

import { Switch } from 'react-router-dom';


import { renderRoutes } from './helper';
import { Routes } from './types';
import { HorizontalNavContainer, HorizontalNav, ButtonsSection } from './HorizontalNavigation.styles';
import NavTabs from './NavTabs';

type HorizontalNavProps = {
  tabs: Routes;
  pageBackgroundColor: CSSProperties['color'];
  buttons?: ReactNode[];
  margin?: CSSProperties['margin'];
  padding?: CSSProperties['padding'];
  hideTabs?: boolean;
  navBackgroundColor?: string;
};

export default ({
  tabs,
  pageBackgroundColor,
  buttons,
  margin,
  padding,
  hideTabs,
  navBackgroundColor,
}: HorizontalNavProps) => {
  return (
    <div>
      {hideTabs ? null : (
        <HorizontalNav
          padding={padding}
          margin={margin}
          navBackgroundColor={navBackgroundColor}
        >
          <HorizontalNavContainer>
            <NavTabs routes={tabs} backgroundColor={pageBackgroundColor} />
            <ButtonsSection>{buttons?.map((button) => button)}</ButtonsSection>
          </HorizontalNavContainer>
        </HorizontalNav>
      )}
      <Switch>{[renderRoutes(tabs)]}</Switch>
    </div>
  );
};
