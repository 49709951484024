import * as React from 'react';

//types
import { Contact, Patient } from '../../../../../../generated/graphql';

//helpers
import { format, parse } from 'date-fns';
import { normalizePhone } from '../../../../../../helpers';

//hooks
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';

//components
import { Button } from '@betterpt/better-components';
import ThemedTextField from '../../../../../Shared/ThemedTextField';

//styles
import { colorValues, bestTextColor } from '@betterpt/better-components';
import {
  BoxHeader,
  ContactPatientBox,
  DialogBody,
  LinkExistingContainer,
} from '../LinkContactAndPatientDialog.style';

type Props = {
  contact: Contact | null | undefined;
  patient: Patient | null | undefined;
  handleLinkExistingContact: () => void;
  handleCreateAndLinkNewContact: () => void;
  saving: boolean;
};

const ExistingContactTextField = ({
  value,
  label,
}: {
  value?: string | null;
  label: string;
  isFullWidth?: boolean;
}) => (
  <ThemedTextField
    disabled
    value={value}
    label={label}
    style={{ margin: '10px 10px 0px 40px', width: '230px' }}
    inputProps={{ style: { fontWeight: 'normal' } }}
  />
);

const LinkExistingContactView = ({
  contact,
  patient,
  saving,
  handleLinkExistingContact,
  handleCreateAndLinkNewContact,
}: Props) => {
  const contactFullName = `${contact?.firstName} ${contact?.lastName}`;
  const patientFullName = `${patient?.firstName} ${patient?.lastName}`;

  const formattedContactBirthday = contact?.dateOfBirth
    ? format(parse(contact.dateOfBirth, 'yyyy-MM-dd', new Date()), 'dd/MM/yy')
    : 'Not Provided';
  const formattedPatientBirthday = patient?.birthday
    ? format(parse(patient.birthday, 'yyyy-MM-dd', new Date()), 'dd/MM/yy')
    : 'Not Provided';

  const { patientsSingularAlias, primaryColor } = useRemoteConfigAliases();

  const lowerCasePatientAlias = patientsSingularAlias.toLowerCase();
  const upperCasePatientAlias = patientsSingularAlias.toUpperCase();
  return (
    <DialogBody>
      <h2 className='H2'>{patientsSingularAlias} Info for this Appointment</h2>
      <p className='Body'>
        It appears this new request matches the details of a{' '}
        {lowerCasePatientAlias} that you already have on file. If this is
        correct, click &quot;LINK {upperCasePatientAlias} RECORDS&quot;
      </p>
      <LinkExistingContainer>
        <ContactPatientBox>
          <BoxHeader headerColor={colorValues.guajirogreen}>
            <h3>REQUESTING {upperCasePatientAlias}</h3>
          </BoxHeader>
          <ExistingContactTextField value={patientFullName} label='Name' />
          <ExistingContactTextField
            value={formattedPatientBirthday}
            label='DOB'
          />
          <ExistingContactTextField
            value={patient?.email ?? 'Not Provided'}
            label='Email'
          />
          <ExistingContactTextField
            value={
              patient?.phone ? normalizePhone(patient?.phone) : 'Not Provided'
            }
            label='Phone'
          />
        </ContactPatientBox>
        <ContactPatientBox>
          <BoxHeader headerColor={colorValues.eveningsky}>
            <h3>YOUR CONTACT</h3>
          </BoxHeader>
          <ExistingContactTextField value={contactFullName} label='Name' />
          <ExistingContactTextField
            value={formattedContactBirthday}
            label='DOB'
          />
          <ExistingContactTextField
            value={contact?.email ?? 'Not Provided'}
            label='Email'
          />
          <ExistingContactTextField
            value={
              contact?.phone ? normalizePhone(contact?.phone) : 'Not Provided'
            }
            label='Phone'
          />
        </ContactPatientBox>
      </LinkExistingContainer>
      <Button
        size='large'
        fullWidth
        style={{
          marginTop: '20px',
          backgroundColor: primaryColor,
          color: bestTextColor(primaryColor),
          whiteSpace: 'nowrap',
        }}
        loading={saving}
        onClick={handleLinkExistingContact}
      >
        LINK {upperCasePatientAlias} RECORDS
      </Button>
      <Button
        size='large'
        fullWidth
        style={{
          marginTop: '20px',
          backgroundColor: colorValues.emptiness,
          color: colorValues.betterptblack,
          whiteSpace: 'nowrap',
        }}
        loading={saving}
        onClick={handleCreateAndLinkNewContact}
      >
        DON&apos;T LINK. SAVE TWO CONTACTS AS SEPERATE ACCOUNTS
      </Button>
    </DialogBody>
  );
};

export default LinkExistingContactView;
