import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

export const TextFieldContainer = styled.div`
  padding: 24px 0;
  background-color: ${colorValues.earlysmoke};
`;

export const DialogBody = styled.div<{ fontWeight?: string }>`
  position: relative;
  margin: 35px;
  input {
    font-weight: ${({ fontWeight }) => fontWeight ?? 'bold'};
  }
`;

export const RadioButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

export const RadioButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  p {
    margin: 5px 0 0 10px;
  }
`;

export const LinkExistingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px -30px;
`;

export const ContactPatientBox = styled.div`
  width: 100%;
  margin: 0px 2px;
  height: 283px;
  border-radius: 5px;
  border: solid 1px ${colorValues.concretejungle};
  background-color: ${colorValues.emptiness};
`;

export const BoxHeader = styled.div<{ headerColor: string }>`
  border-bottom: 1px solid ${colorValues.concretejungle};
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    color: ${({ headerColor }) => headerColor};
    font-weight: bold;
    font-size: 14px;
  }
`;
