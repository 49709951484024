//hooks
import useFeatureAccessCheck from '../../../../hooks/useFeatureAccessCheck';
import useSnackbar from '../../../../hooks/useSnackbar';

//components
import AppointmentTypes from '../../../Shared/AppointmentTypes';
import BookingExperience from './components/BookingExperience';
import Contact from './components/Contact';
import LogoCard from './components/LogoCard';

//styles
import { Grid, GridRow } from '../../../Shared/GridCard';
import { PageSectionHeader } from './CompanyInfoTab.style';
import { Page } from '../Company.style';

const CompanyInfoTab = () => {
  const { isPT, isMigratedToNewBookingFlow, isNotWhiteLabel, loading } = useFeatureAccessCheck();

  const { openSnackbar } = useSnackbar() ?? {};

  return (
    <Page>
      <Grid>
        <GridRow>
          <LogoCard />
        </GridRow>
        <GridRow>
          <Contact />
        </GridRow>
        {isNotWhiteLabel && !loading && (
          <>
            <PageSectionHeader>Appointment Configuration</PageSectionHeader>
            <GridRow>
              {isPT && !isMigratedToNewBookingFlow && <BookingExperience openSnackbar={openSnackbar} />}
              <AppointmentTypes />
            </GridRow>
          </>
        )}
      </Grid>
    </Page>
  );
};

export default CompanyInfoTab;
