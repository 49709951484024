import * as React from "react";

//types
import {
  CompanyListItem,
  CompanyListItemTypeEnum,
  Maybe,
  Role,
  Employee,
  useCompanyEmployeesQuery,
  useMeQuery,
} from "../../../generated/graphql";
import { VideoAppointmentFiltersContextType } from "../../../context/videoAppointmentFilters";
import { InPersonAppointmentFiltersContextType } from "../../../context/inPersonAppointmentFilters";

//hooks
import useRemoteConfigAliases from "../../../hooks/useRemoteConfigAliases";
import _ from "lodash";

//components
import {
  FilterScrollAndSearch,
  FilterOption,
} from "@betterpt/better-components";

//helpers
import { pluralNoun } from "../../../helpers";

const EmployeesFilter: React.FC<React.PropsWithChildren<{
  filters:
    | VideoAppointmentFiltersContextType
    | InPersonAppointmentFiltersContextType;
  primaryColor?: string;
}>> = ({ filters, primaryColor }) => {
  const { employeePluralAlias, employeeSingularAlias } =
    useRemoteConfigAliases();
  const [nameFilter, updateNameFilter] = React.useState("");
  const [offset, setOffset] = React.useState<number>(0);
  const [isOpen, setOpenTo] = React.useState(false);
  const [employeeIds, updateEmployeeIds] = React.useState<string[]>([]);
  const [employeeList, setEmployeeList] = React.useState<any[]>([]);

  const meQuery = useMeQuery();
  const me = meQuery.data?.me;
  const isBasic = me?.role === Role.Self;

  const onQueryComplete = (data: any) =>
    setEmployeeList((prev) =>
      _.unionBy([...prev, ...(data?.company?.employees?.result ?? [])], "id")
    );

  // To Get List of Employees Wiht Pagination
  const employeesQuery = useCompanyEmployeesQuery({
    variables: {
      id: meQuery.data?.me?.company?.id!,
      input: {
        query: nameFilter,
        offset,
        limit: 10,
      },
    },
    skip: !meQuery.data?.me?.company?.id || isBasic || !isOpen,
    notifyOnNetworkStatusChange: true,
    onCompleted: onQueryComplete,
    fetchPolicy: "cache-and-network",
  });

  // To Get List of Employees for Employee Ids Selected in Employees Filter
  const filterEmployeeQuery = useCompanyEmployeesQuery({
    variables: {
      id: meQuery.data?.me?.company?.id!,
      input: {
        employeeIds: filters.employeeIds.value,
      },
    },
    onCompleted: onQueryComplete,
    skip: !filters.employeeIds.value.length || !isOpen || isBasic,
    fetchPolicy: "cache-and-network",
  });

  const count = employeesQuery?.data?.company?.employees?.result?.length ?? 0;

  const handleSubmitEmployees = (selected: FilterOption[]) => {
    setOffset(0);
    setEmployeeList([]);
    const newEmployees = selected?.length
      ? selected?.map((option) => option?.value ?? "0")
      : [];

    filters.employeeIds.update(newEmployees);
  };

  const justMeOption = [
    {
      firstName: me?.firstName,
      lastName: me?.lastName,
      id: me?.id,
      type: CompanyListItemTypeEnum.Employee,
    },
  ];

  const invertName = (employee: Maybe<Employee>) =>
    `${employee?.lastName}, ${employee?.firstName}`;

  React.useEffect(() => {
    updateEmployeeIds(filters.employeeIds?.value ?? []);
  }, [filters.contactIds, isOpen]);

  const employeeOptions = (() => {
    const selectAllEmployees = isBasic
      ? []
      : [
          {
            label: `All ${employeePluralAlias}`,
            isResetOption: true,
            selected: employeeIds.length < 1 || employeeIds?.includes("0"),
            hidden: isBasic,
          },
        ];

    const employeesToShow: any[] = isBasic
      ? justMeOption
      : employeeList.filter((employee) => employee?.id);

    const employeeOptions = employeesToShow.map((employee: any) => ({
      label: invertName(employee),
      value: employee?.id,
      selected: isBasic || employeeIds.includes(employee?.id),
    }));

    return [...selectAllEmployees, ...employeeOptions];
  })();

  const filterEmployeeOptions = (() => {
    const filterEmployeeOptions = [
      ...(filterEmployeeQuery?.data?.company?.employees?.result ?? []),
    ].map(invertName);

    return [...filterEmployeeOptions];
  })();

  const handleButtonTitle = (() => {
    let employee_ids = filters.employeeIds?.value;
    let title = "loading...";
    if (employee_ids.length < 1) {
      title = `all ${pluralNoun(employeeSingularAlias)}`;
    } else {
      const bestCategoryName =
        employee_ids.length > 1
          ? pluralNoun(employeeSingularAlias)
          : employeeSingularAlias;
      const bestCheckTitle = `${employee_ids.length} ${bestCategoryName}`;
      title = bestCheckTitle ?? "";
    }
    return title;
  })();

  const handleEmployeeClick = (option: FilterOption) => {
    if (!option?.isResetOption) {
      updateEmployeeIds((ids) => {
        if (employeeIds.includes(option?.value)) {
          return ids.filter((id) => id !== option?.value);
        } else {
          return [...ids, option?.value];
        }
      });
    } else {
      updateEmployeeIds([]);
    }
  };

  const handleSearch = (searchText: string) => {
    updateNameFilter(searchText);
    setEmployeeList([
      ...employeeList.filter((employee) => employeeIds.includes(employee?.id)),
    ]);
    setOffset(0);
  };

  return (
    <FilterScrollAndSearch
      selectedOptions={filterEmployeeOptions}
      options={employeeOptions}
      buttonTitle={handleButtonTitle}
      search={{
        value: nameFilter,
        update: handleSearch,
      }}
      useSearch={!isBasic}
      openStatus={{
        value: isOpen,
        update: (open: boolean) => {
          setOpenTo(open);
        },
      }}
      optionCallback={handleEmployeeClick}
      name={employeeSingularAlias}
      overrideButtonColor={primaryColor}
      onLoadMore={() => setOffset(offset + count)}
      hasMore={!!count}
      onSubmit={handleSubmitEmployees}
      loading={employeesQuery.loading}
      style={{ buttonMargin: "12px 4px 0 0" }}
    />
  );
};

export default EmployeesFilter;
