import { jsx } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

// Components
import RenderNumbers from './RenderNumbers';

// Icons
import Back from '../../Icons/IcArrowLeft';
import Arrow from '../../Icons/IcArrowRight';

// Constants & styles
import { colorValues } from '../../helpers/colors';
import { pagerStyle, arrowStyle } from './style';

interface Pagination {
  pageSize: number;
  totalCount: number;
  backToFirstPage?: boolean;
  onChangePage: (
    pageSize: number,
    pageIndex: number,
    activeSection: number
  ) => void;
  defaultPageIndex?: number;
  defaultSection?: number;
  primaryColor?: string;
  searchValue?: string;
}

const Div = styled.div``;
const Button = styled.button``;

export default ({
  pageSize,
  totalCount,
  backToFirstPage,
  onChangePage,
  defaultPageIndex,
  defaultSection,
  primaryColor,
  searchValue,
}: Pagination) => {
  const [pages, setPages] = React.useState(1);
  const [activePage, setActivePage] = React.useState(0);
  const [sections, setSectionsTo] = React.useState(1);
  const [activeSection, setActiveSectionTo] = React.useState(1);
  const [total, setTotalTo] = React.useState(0);
  const pagesInSection = 5;
  const previousSearchValue = React.useRef(searchValue);

  React.useEffect(() => {
    if (totalCount > 0) {
      setPages(
        (Math.ceil(totalCount / pageSize) <= pagesInSection &&
          Math.ceil(totalCount / pageSize)) ||
          pagesInSection
      );
      setSectionsTo(Math.ceil(totalCount / pageSize / pagesInSection));
      setTotalTo(totalCount);
    }
  }, [totalCount, pageSize]);

  React.useEffect(() => {
    if (backToFirstPage) {
      setActivePage(0);
      setActiveSectionTo(1);
    }
  }, [backToFirstPage]);

  React.useEffect(() => {
    if(searchValue !== undefined && searchValue !== previousSearchValue.current) {
      setActivePage(0);
      setActiveSectionTo(1);
      previousSearchValue.current = searchValue;
    }
  }, [searchValue])

  //Set page default to provided index, if index
  React.useEffect(() => {
    if (defaultPageIndex !== null && defaultPageIndex !== undefined && defaultPageIndex !== activePage) {
      setActivePage(defaultPageIndex);
    }
  }, [defaultPageIndex, pages]);

  //Set default section to index
  React.useEffect(() => {
    if (defaultSection && defaultSection !== activeSection) {
      setActiveSectionTo(defaultSection);
    }
  }, [defaultSection]);

  React.useEffect(() => {
    // Check if the number of sections has changed to 1 and the total pages are 5 or less.
    // Also check if the active page exceeds the new total pages or if the active section is not the first one.
    if ((sections === 1 && pages <= 5 && (activePage >= pages || activeSection > 1)) ||
        (sections < activeSection)) { // If there are fewer sections than the current active section
      setActivePage(0); // Reset to the first page
      setActiveSectionTo(1); // Reset to the first section
    }
  }, [sections, pages, activePage, activeSection]);

  const activePageClick = (idx: number, activeSection: number) => {
    onChangePage(pageSize, idx, activeSection);
    setActivePage(idx);
  };

  const prevSection = () => {
    if (activeSection > 1) {
      setActiveSectionTo(activeSection - 1);
      activePageClick((activeSection - 1) * pages - 1, activeSection - 1);
    }
  };
  const nextSection = () => {
    if (activeSection < sections) {
      setActiveSectionTo(activeSection + 1);
      activePageClick(activeSection * pages, activeSection + 1);
    }
  };

  return (
    <div>
      {pages > 1 && totalCount > 0 ? (
        <Div css={pagerStyle}>
          {sections > 1 && (
            <Button
              disabled={activeSection === 1}
              css={arrowStyle}
              onClick={() => prevSection()}
            >
              <Back
                color={
                  (activeSection === 1 && colorValues.concretejungle) ||
                  primaryColor ||
                  colorValues.eveningsky
                }
              />
            </Button>
          )}
          <RenderNumbers
            sections={sections}
            pages={pages}
            activeSection={activeSection}
            total={total}
            pageSize={pageSize}
            pagesInSection={pagesInSection}
            activePage={activePage}
            activePageClick={(index) => activePageClick(index, activeSection)}
            primaryColor={primaryColor}
          />
          {sections > 1 && (
            <Button
              disabled={activeSection === sections}
              css={arrowStyle}
              onClick={() => nextSection()}
            >
              <Arrow
                color={
                  (activeSection === sections && colorValues.concretejungle) ||
                  primaryColor ||
                  colorValues.eveningsky
                }
              />
            </Button>
          )}
        </Div>
      ) : null}
    </div>
  );
};
