import * as React from 'react';
import styled from '@emotion/styled';
import { IcLoading } from '@betterpt/better-icons';
import { colorValues, InfoBox } from '@betterpt/better-components';
import { RecurringSeries } from '../../../../../../generated/graphql';
import {
  generateFrequencyCopy,
  generateIntervalCopy,
  generateFormattedEndDate,
} from '../../../../../../helpers/utils';

type Props = {
  recurringSeries: RecurringSeries;
  iconColor?: string;
  style?: { [key: string]: any };
  icon?: React.ReactNode;
  position?: 'left' | 'right' | 'top' | 'bottom' | null;
  fixedPlacement: boolean;
};

const RecurringAppointmentInfoBoxContent = styled.p`
  font-size: 14px !important;
`;

const RecurringAppointmentInfoBoxHeader = styled.h3`
  color: ${colorValues.charcoalgray} !important;
  font-size: 12px !important;
`;

const RecurringAppointmentsInfoBox = ({
  recurringSeries,
  iconColor,
  style,
  icon,
  position,
  fixedPlacement,
}: Props) => {
  const formattedEndDate = generateFormattedEndDate(recurringSeries);

  const intervalCopy = generateIntervalCopy(recurringSeries);

  const frequencyCopy = generateFrequencyCopy(recurringSeries);

  return (
    <InfoBox
      icon={icon ?? <IcLoading color={iconColor ?? colorValues.cityblock} />}
      width={300}
      height={200}
      openWith='hover'
      mainStyle={style}
      position={position ?? 'right'}
      fixedPlacement={fixedPlacement}
    >
      <RecurringAppointmentInfoBoxHeader className='H6'>
        RECURRING APPOINTMENT
      </RecurringAppointmentInfoBoxHeader>
      <RecurringAppointmentInfoBoxContent>
        This appointment will recur every {intervalCopy} {frequencyCopy} until{' '}
        {formattedEndDate}
        {recurringSeries.shouldSkipWeekends ? ' excluding weekends.' : '.'}
        <br />
        <br />
        After each completed appointment, invitations for the next appointment
        will automatically be sent to both the patient and the provider, and
        display in your upcoming appointments view on the platform.
      </RecurringAppointmentInfoBoxContent>
    </InfoBox>
  );
};

export default RecurringAppointmentsInfoBox;
