import React from 'react';
import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';
import { TextFieldState } from '../../../../../hooks/useTextFieldState';
import FormField from '../../../../Shared/FormField';
import { RoleToString, Roles, RolesArray } from '../../../../../helpers';

export const LearnMoreAboutRoles = styled.a`
  position: absolute;
  top: -3px;
  left: 115px;
  color: ${colorValues.frenchblue};
  z-index: 10;
`;

interface Props {
  roleState: TextFieldState;
  rolesChecker(role: Roles): boolean;
}

const RolePicker: React.FC<React.PropsWithChildren<Props>> = ({ roleState, rolesChecker }) => {
  return (
    <div style={{ position: 'relative' }}>
      <LearnMoreAboutRoles
        className='H6 button-or-link'
        href='https://betterpt-external-assets.s3.amazonaws.com/Roles+and+Permissions+PDF.pdf'
        target='_blank'
        rel='noreferrer'
      >
        LEARN MORE
      </LearnMoreAboutRoles>

      <FormField
        fullWidth
        select
        label='Roles and Permissions'
        formHandler={roleState}
        SelectProps={{ native: true }}
      >
        <option disabled value=''>
          Please select from the dropdown
        </option>
        {RolesArray.filter(rolesChecker).map((role) => (
          <option key={role} value={role}>
            {RoleToString((Roles as any)[role])}
          </option>
        ))}
      </FormField>
    </div>
  );
};

export default RolePicker;
