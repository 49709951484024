import React from 'react';
import { useLocation } from 'react-router-dom';

//helpers
import { bestTextColor } from '@betterpt/better-components';

//hooks
import {
  useMeQuery,
  useUpdateEmployeeAgreementMutation,
} from '../../generated/graphql';
import useRemoteConfigAliases from '../../hooks/useRemoteConfigAliases';

//components
import { Button, Checkbox, colorValues } from '@betterpt/better-components';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import useAuthentication from '../../hooks/useAuthentication';
import useHandleError from '../../hooks/useHandleError';

//styles
const dialogTitleStyle = { padding: '52px 52px 20px' };
const dialogContentStyle = { padding: '8px 52px' };
const linkStyle = { color: colorValues.betterptblack };
const buttonContainerStyle = {
  display: 'flex',
  padding: '30px 52px 52px',
  alignItems: 'center',
  justifyContent: 'center',
};
const buttonStyle = (primaryColor: string) => ({
  color: bestTextColor(primaryColor),
  backgroundColor: primaryColor,
});

const AcceptTermsDialog = () => {
  const location = useLocation();
  const auth = useAuthentication();

  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [tosAccepted, setTosAccepted] = React.useState(false);
  const [privacyAccepted, setprivacyAccepted] = React.useState(false);

  const meQuery = useMeQuery({ skip: !auth?.user });
  const { primaryColor } = useRemoteConfigAliases();
  const handleError = useHandleError();

  const [updateEmployeeAgreement, { loading: savingAgreement }] =
    useUpdateEmployeeAgreementMutation();

  React.useEffect(() => {
    if (
      location.pathname !== '/terms-of-use' && 
      location.pathname !== '/privacy-policy' && 
      meQuery.data &&
      !meQuery.loading &&
      meQuery.data?.me?.company?.providerAppId?.toLowerCase() !== 'acorn' &&
      (!meQuery.data?.me?.acceptEmployeePrivacy ||
        !meQuery.data?.me?.acceptEmployeeTOS)
    ) {
      setDialogOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meQuery.data, location.pathname]);

  const handleContinue = async () => {
    const employeeId = meQuery?.data?.me?.id;
    if (!employeeId) return;
    try {
      await updateEmployeeAgreement({
        variables: {
          input: {
            employeeId,
            acceptEmployeeTOS: true,
            acceptEmployeePrivacy: true,
          },
        },
      });
      setDialogOpen(false);
    } catch (e) {
      handleError(e);
    }
  };

  return (
    //TODO: update copy to final on terms popup
    <Dialog fullWidth open={isDialogOpen}>
      <DialogTitle id='simple-dialog-title' style={dialogTitleStyle}>
        Please accept our terms.
      </DialogTitle>
      <DialogContent style={dialogContentStyle}>
        <FormGroup>
          <FormControlLabel
            label={
              <p>
                I have read and accept the{' '}
                <a
                  className='button-or-link'
                  href='/terms-of-use'
                  target='_blank'
                  style={linkStyle}
                >
                  Terms and Conditions.
                </a>
              </p>
            }
            control={
              <Checkbox
                checked={tosAccepted}
                onClick={() => setTosAccepted(!tosAccepted)}
              />
            }
          />

          <FormControlLabel
            label={
              <p>
                I have read and accept the{' '}
                <a
                  href='/privacy-policy'
                  className='button-or-link'
                  target='_blank'
                  style={linkStyle}
                >
                  Privacy Policy.
                </a>
              </p>
            }
            control={
              <Checkbox
                checked={privacyAccepted}
                onClick={() => setprivacyAccepted(!privacyAccepted)}
              />
            }
          />
        </FormGroup>
      </DialogContent>
      <div style={buttonContainerStyle}>
        <Button
          onClick={handleContinue}
          color='no-border'
          size='large'
          fullWidth
          loading={savingAgreement}
          disabled={savingAgreement || !tosAccepted || !privacyAccepted}
          style={buttonStyle(primaryColor)}
        >
          CONTINUE
        </Button>
      </div>
    </Dialog>
  );
};

export default AcceptTermsDialog;
