import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...rest}
  >
    <defs>
      <path d='M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z' id='a' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <mask id='b' fill='#fff'>
        <use xlinkHref='#a' />
      </mask>
      <use fill={color || '#333'} xlinkHref='#a' />
      <g mask='url(#b)' fill={color || '#333'}>
        <path d='M0 0h24v24H0z' />
      </g>
    </g>
  </svg>
);
