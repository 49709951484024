import * as React from 'react';
import { PatientFiltersContext } from '../context/patientFilters';

export default function usePatientFilters() {
  const context = React.useContext(PatientFiltersContext);

  if (context === undefined) {
    throw new Error(
      '`usePatientFilters` hook must be used within a `PatientFiltersContextProvider` component'
    );
  }
  return context;
}
