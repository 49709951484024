import Badger from './icons/Badger';
import IcAdd from './icons/IcAdd';
import IcAddCircle from './icons/IcAddCirle';
import IcAlert from './icons/IcAlert';
import IcArrowDown from './icons/IcArrowDown';
import IcArrowLeft from './icons/IcArrowLeft';
import IcArrowLeftWithStem from './icons/IcArrowLeftWithStem';
import IcArrowRight from './icons/IcArrowRight';
import IcArrowRightWithStem from './icons/IcArrowRightWithStem';
import IcArrowUp from './icons/IcArrowUp';
import IcBetterHealthcare from './icons/IcBetterHealthcare';
import IcBetterPT from './icons/IcBetterPT';
import IcBetterTelehealth from './icons/IcBetterTelehealth';
import IcBusiness from './icons/IcBusiness';
import IcCamera from './icons/IcCamera';
import IcCard from './icons/IcCard';
import IcChat from './icons/IcChat';
import IcClipboard from './icons/IcClipboard';
import IcClock from './icons/IcClock';
import IcClose from './icons/IcClose';
import IcCloseFilter from './icons/IcCloseFilter';
import IcCollapse from './icons/IcCollapse';
import IcCopyPaste from './icons/IcCopyPaste';
import IcCreditCard from './icons/IcCreditCard';
import IcDateRange from './icons/IcDateRange';
import IcDelete from './icons/IcDelete';
import IcDoubleCheck from './icons/IcDoubleCheck';
import IcDownload from './icons/IcDownload';
import IcDropDownSelected from './icons/IcDropDownSelected';
import IcDropdownUnselected from './icons/IcDropdownUnselected';
import IcEdit from './icons/IcEdit';
import IcEmail from './icons/IcEmail';
import IcFace from './icons/IcFace';
import IcFileDownload from './icons/IcFileDownload';
import IcGoogle from './icons/IcGoogle';
import IcHangup from './icons/IcHangup';
import IcHangupRound from './icons/IcHangupRound';
import IcHome from './icons/IcHome';
import IcHss from './icons/IcHss';
import IcInfoOutline from './icons/IcInfoOutline';
import IcInsurance from './icons/IcInsurance';
import IcInvite from './icons/IcInvite';
import IcLink from './icons/IcLink';
import IcList from './icons/IcList';
import IcLoading from './icons/IcLoading';
import IcMenu from './icons/IcMenu';
import IcMicOff from './icons/IcMicOff';
import IcMicOn from './icons/IcMicOn';
import IcMore from './icons/IcMore';
import IcNoteAdd from './icons/IcNoteAdd';
import IcOpenFilter from './icons/IcOpenFilter';
import IcOrderSwap from './icons/IcOrderSwap';
import IcPartner from './icons/IcPartner';
import IcProfile from './icons/IcProfile';
import IcRecord from './icons/IcRecord';
import IcRemove from './icons/IcRemove';
import IcSave from './icons/IcSave';
import IcScreenshareOff from './icons/IcScreenshareOff';
import IcScreenshareOn from './icons/IcScreenshareOn';
import IcSearch from './icons/IcSearch';
import IcSend from './icons/IcSend';
import IcSettings from './icons/IcSettings';
import IcSmartphone from './icons/IcSmartphone';
import IcStar from './icons/IcStar';
import IcSuccess from './icons/IcSuccess';
import IcSuccessCircle from './icons/IcSuccessCircle';
import IcSupportAndFeedback from './icons/IcSupportAndFeedback';
import IcTrendingUp from './icons/IcTrendingUp';
import IcTv from './icons/IcTv';
import IcUnavailable from './icons/IcUnavailable';
import IcVideoOff from './icons/IcVideoOff';
import IcVideoOn from './icons/IcVideoOn';
import IcVisibilityOff from './icons/IcVisibilityOff';
import IcVisibilityOn from './icons/IcVisibilityOn';
import IcWarning from './icons/IcWarning';
import IcWaving from './icons/IcWaving';
import IcWifiRedSlash from './icons/IcWifiRedSlash';
import ImgAdminComputer from './icons/ImgAdminComputer';
import ImgAdminGuy from './icons/ImgAdminGuy';
import ImgAndrewTelehealth from './icons/ImgAndrewTelehealth';
import ImgBoyInsurance from './icons/ImgBoyInsurance';
import ImgBoyShrug from './icons/ImgBoyShrug';
import ImgBlondeGirlComputer from './icons/ImgBlondeGirlComputer';
import ImgBlondeGirlBlueSuitComputer from './icons/ImgBlondeGirlBlueSuitComputer';
import ImgBrunetteGirlComputer from './icons/ImgBrunetteGirlComputer';
import ImgBrunetteGirlRedSuitComputer from './icons/ImgBrunetteGirlRedSuitComputer';
import ImgBrunetteGirlRedShirt from './icons/ImgBrunetteGirlRedShirt';
import ImgCovidManager from './icons/ImgCovidManager';
import ImgDanBeard from './icons/ImgDanBeard';
import ImgDanielPhoneSearch from './icons/ImgDanielPhoneSearch';
import ImgGirlComputer from './icons/ImgGirlComputer';
import ImgGirlBlueDressPhone from './icons/ImgGirlBlueDressPhone';
import ImgGirlYellowDressPhone from './icons/ImgGirlYellowDressPhone';
import ImgKirstenPhoneSearch from './icons/ImgKirstenPhoneSearch';
import ImgLighthouse from './icons/ImgLighthouse';
import ImgSmallGirlPhoneSearch from './icons/ImgSmallGirlPhoneSearch';
import ImgSuitLadyComputer from './icons/ImgSuitLadyComputer';
import ICAnchor from './icons/ICAnchor';
import IcLogout from './icons/IcLogout';
import IcNetworkGroup from './icons/IcNetworkGroup';
import IcNetworkPartenrs from './icons/IcNetworkPartenrs';
// Export individual components
export {
  Badger,
  IcAdd,
  IcAddCircle,
  IcAlert,
  IcArrowDown,
  IcArrowLeft,
  IcArrowLeftWithStem,
  IcArrowRight,
  IcArrowRightWithStem,
  IcArrowUp,
  IcBetterHealthcare,
  IcBetterPT,
  IcBetterTelehealth,
  IcBusiness,
  IcCamera,
  IcCard,
  IcChat,
  IcClipboard,
  IcClock,
  IcClose,
  IcCloseFilter,
  IcCollapse,
  IcCopyPaste,
  IcCreditCard,
  IcDateRange,
  IcDelete,
  IcDoubleCheck,
  IcDownload,
  IcDropDownSelected,
  IcDropdownUnselected,
  IcEdit,
  IcEmail,
  IcFace,
  IcFileDownload,
  IcGoogle,
  IcHangup,
  IcHangupRound,
  IcHome,
  IcHss,
  IcInfoOutline,
  IcInsurance,
  IcInvite,
  IcLink,
  IcList,
  IcLoading,
  IcMenu,
  IcMicOff,
  IcMicOn,
  IcMore,
  IcNoteAdd,
  IcOpenFilter,
  IcOrderSwap,
  IcPartner,
  IcProfile,
  IcRecord,
  IcRemove,
  IcSave,
  IcScreenshareOff,
  IcScreenshareOn,
  IcSearch,
  IcSend,
  IcSettings,
  IcSmartphone,
  IcStar,
  IcSuccess,
  IcSuccessCircle,
  IcSupportAndFeedback,
  IcTrendingUp,
  IcTv,
  IcUnavailable,
  IcVideoOff,
  IcVideoOn,
  IcVisibilityOff,
  IcVisibilityOn,
  IcWarning,
  IcWaving,
  IcWifiRedSlash,
  ImgAdminComputer,
  ImgAdminGuy,
  ImgAndrewTelehealth,
  ImgBoyInsurance,
  ImgBoyShrug,
  ImgBlondeGirlComputer,
  ImgBlondeGirlBlueSuitComputer,
  ImgBrunetteGirlComputer,
  ImgBrunetteGirlRedSuitComputer,
  ImgBrunetteGirlRedShirt,
  ImgCovidManager,
  ImgDanBeard,
  ImgDanielPhoneSearch,
  ImgGirlComputer,
  ImgGirlBlueDressPhone,
  ImgGirlYellowDressPhone,
  ImgKirstenPhoneSearch,
  ImgLighthouse,
  ImgSmallGirlPhoneSearch,
  ImgSuitLadyComputer,
  ICAnchor,
  IcLogout,
  IcNetworkGroup,
  IcNetworkPartenrs
};

export const iconsDictionary = {
  Badger,
  IcAdd,
  IcAddCircle,
  IcAlert,
  IcArrowDown,
  IcArrowLeft,
  IcArrowLeftWithStem,
  IcArrowRight,
  IcArrowRightWithStem,
  IcArrowUp,
  IcBetterHealthcare,
  IcBetterPT,
  IcBetterTelehealth,
  IcBusiness,
  IcCamera,
  IcCard,
  IcChat,
  IcClipboard,
  IcClock,
  IcClose,
  IcCloseFilter,
  IcCollapse,
  IcCopyPaste,
  IcCreditCard,
  IcDateRange,
  IcDelete,
  IcDoubleCheck,
  IcDownload,
  IcDropDownSelected,
  IcDropdownUnselected,
  IcEdit,
  IcEmail,
  IcFace,
  IcFileDownload,
  IcGoogle,
  IcHangup,
  IcHangupRound,
  IcHome,
  IcHss,
  IcInfoOutline,
  IcInsurance,
  IcInvite,
  IcLink,
  IcList,
  IcLoading,
  IcMenu,
  IcMicOff,
  IcMicOn,
  IcMore,
  IcNoteAdd,
  IcOpenFilter,
  IcOrderSwap,
  IcProfile,
  IcRecord,
  IcRemove,
  IcSave,
  IcScreenshareOff,
  IcScreenshareOn,
  IcSearch,
  IcSend,
  IcSettings,
  IcSmartphone,
  IcStar,
  IcSuccess,
  IcSuccessCircle,
  IcSupportAndFeedback,
  IcTrendingUp,
  IcTv,
  IcUnavailable,
  IcVideoOff,
  IcVideoOn,
  IcVisibilityOff,
  IcVisibilityOn,
  IcWarning,
  IcWaving,
  IcWifiRedSlash,
  ImgAdminComputer,
  ImgAdminGuy,
  ImgAndrewTelehealth,
  ImgBoyInsurance,
  ImgBoyShrug,
  ImgBlondeGirlComputer,
  ImgBlondeGirlBlueSuitComputer,
  ImgBrunetteGirlComputer,
  ImgBrunetteGirlRedSuitComputer,
  ImgBrunetteGirlRedShirt,
  ImgCovidManager,
  ImgDanBeard,
  ImgDanielPhoneSearch,
  ImgGirlComputer,
  ImgGirlBlueDressPhone,
  ImgGirlYellowDressPhone,
  ImgKirstenPhoneSearch,
  ImgLighthouse,
  ImgSmallGirlPhoneSearch,
  ImgSuitLadyComputer,
  ICAnchor,
  IcLogout,
  IcNetworkGroup,
  IcNetworkPartenrs
};

// Export list for storybooks to render icons
export const IconsList = Object.values(iconsDictionary);
