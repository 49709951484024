// Role-Based Access Control Scheme (rbac)

import { canEditRole, canViewFacility } from './dynamicRules';

export enum Roles {
  owner = 'owner',
  admin = 'admin',
  manager = 'manager',
  scheduler = 'scheduler',
  self = 'self',
  initial = 'initial',
}

export const permissionsToEditByRole: { [key: string]: Roles[] } = {
  admin: [Roles.manager, Roles.scheduler, Roles.self],
  manager: [Roles.self, Roles.scheduler],
  owner: [Roles.owner, Roles.admin, Roles.manager, Roles.scheduler, Roles.self],
  self: [],
  scheduler: [],
};

export const RolesArray = [
  Roles.owner,
  Roles.admin,
  Roles.manager,
  Roles.scheduler,
  Roles.self,
];

export const RoleToString = (role: Roles) => {
  switch (role) {
    case Roles.owner:
      return 'Owner';
    case Roles.admin:
      return 'Admin';
    case Roles.manager:
      return 'Manager';
    case Roles.scheduler:
      return 'Scheduler';
    case Roles.self:
      return 'Basic';
    case Roles.initial:
      return 'Initial';
  }
};

export const getRules = (role: Roles) => {
  switch (role) {
    case Roles.owner:
      return {
        static: [
          'analytics:access',
          'appointment:downloadRecording',
          'appointmentType:create',
          'appointmentType:edit',
          'billingOverview:access',
          'company:edit',
          'employee:create',
          'employee:viewAppointments',
          'employeeAppointmentTypes:edit',
          'facility:associateToSelf',
          'facility:create',
          'facility:edit',
          'facility:view',
          'patient:create',
        ],
        dynamic: {
          'employee:edit': ({ targetRole }: { targetRole: Roles }) =>
            canEditRole({ currentUserRole: Roles.owner, targetRole }),
        },
      };
    case Roles.admin:
      return {
        static: [
          'analytics:access',
          'appointment:downloadRecording',
          'appointmentType:create',
          'appointmentType:edit',
          'billingOverview:access',
          'company:edit',
          'employee:create',
          'employee:viewAppointments',
          'employeeAppointmentTypes:edit',
          'facility:create',
          'facility:edit',
          'patient:create',
        ],
        dynamic: {
          'employee:edit': ({ targetRole }: { targetRole: Roles }) =>
            canEditRole({ currentUserRole: Roles.admin, targetRole }),
          'facility:view': canViewFacility,
        },
      };
    case Roles.manager:
      return {
        static: [
          'appointment:downloadRecording',
          'employee:create',
          'employee:viewAppointments',
          'employeeAppointmentTypes:edit',
          'facility:edit',
          'patient:create',
        ],
        dynamic: {
          'employee:edit': ({ targetRole }: { targetRole: Roles }) =>
            canEditRole({ currentUserRole: Roles.manager, targetRole }),
          'facility:view': canViewFacility,
        },
      };
    case Roles.scheduler:
      return {
        static: [
          'employee:viewAppointments',
          'employeeAppointmentTypes:edit',
          'patient:create',
        ],
        dynamic: { 'facility:view': canViewFacility },
      };
    case Roles.self:
      return {
        static: ['patient:create'],
        dynamic: { 'facility:view': canViewFacility },
      };
    case Roles.initial:
      return {
        static: [],
        dynamic: {},
      };
  }
};
