import styled from '@emotion/styled';
import { bestTextColor } from '@betterpt/better-components';

export const CreateTeamBody = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 56px;
`;

export const Form = styled.form`
  width: 367px;
`;

export const ButtonStyle = (color?: string) => ({
  backgroundColor: color,
  color: bestTextColor(color),
  marginTop: 40,
  marginBottom: 40,
});

export const EmailOptOutLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
