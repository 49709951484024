import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const initDataDog = () => {
  datadogLogs.init({
    clientToken: 'pub8e558fa9263149c3f9e5b1fc7f962972',
    forwardErrorsToLogs: true,
    version: '2.1.0',
    service: 'better-access',
    site: 'datadoghq.com',
    env: window.__RUNTIME_CONFIG__.REACT_APP_NODE_ENV,
  });

  datadogRum.init({
    applicationId: '842991e7-ad26-456c-9795-1b186dcb0254',
    clientToken: 'pub04aa318b3c5e97f7db1715e65ad720db',
    site: 'datadoghq.com',
    service: 'BetterAccess',
    env: window.__RUNTIME_CONFIG__.REACT_APP_NODE_ENV,
    version: '2.1.0',
    sampleRate: 100,
    trackInteractions: true,
  });

  const clinicAppLogger = datadogLogs.createLogger('clinicAppLogger');
  clinicAppLogger?.addContext('env', window.__RUNTIME_CONFIG__.REACT_APP_NODE_ENV);
};

export default initDataDog;
