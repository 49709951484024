import React from 'react';

//helpers
import { possessiveName } from '../../../../../helpers';
import Flags from '../../../../../constants/flags';

// hooks
import { useEmployeeQuery } from '../../../../../generated/graphql';
import { useRouteMatch } from 'react-router-dom';
import useSnackbar from '../../../../../hooks/useSnackbar';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';

// components
import { Route } from 'react-router-dom';
import { Grid } from '../../../../Shared/GridCard';
import AppointmentTypes from '../../../../Shared/AppointmentTypes';
import AppointmentTypeDetail from '../../../AppointmentTypeDetail';
import ProviderHours from './components/ProviderHours';
import FeatureFlagCheck from '../../../../Shared/FeatureFlagCheck';

//styles
import { ApptConfigHeader, Page } from '../styles/EmployeeDetail.style';

const AppointmentConfigTab = () => {
  const {
    params: { employeeId },
  } = useRouteMatch<{ employeeId: string }>();
  const snackbar = useSnackbar();
  const { employeeSingularAlias } = useRemoteConfigAliases();

  const employeeQuery = useEmployeeQuery({
    variables: { id: employeeId },
    skip: !employeeId,
    fetchPolicy: 'cache-and-network',
  });

  React.useEffect(() => {
    if (employeeQuery.error?.message) {
      snackbar?.useGenericErrorMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeQuery.error]);

  const employeeName = possessiveName(
    employeeQuery.loading
      ? employeeSingularAlias
      : employeeQuery?.data?.employee?.firstName ||
          employeeQuery?.data?.employee?.lastName
  );

  return (
    <Page>
      <Route
        path='/employees/:employeeId/config/appointment-type/:appointmentTypeId'
        exact
      >
        <AppointmentTypeDetail />
      </Route>
      <Route path='/employees/:employeeId/config' exact>
        <Grid>
          <ProviderHours />
          <ApptConfigHeader>{employeeName} Appointment Types</ApptConfigHeader>
          <AppointmentTypes fullWidth isOnEmployeeScreen />
        </Grid>
      </Route>
    </Page>
  );
};

export default AppointmentConfigTab;
