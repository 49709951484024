export enum colorValues {
  betterptblack = '#333333',
  cauldronblack = '#4d4d4d',
  charcoalgray = '#717171',
  cityblock = '#919191',
  concretejungle = '#b1b1b1',
  coneyislandsidewalk = '#c0c0c0',
  dust = '#e6e6e6',
  earlysmoke = '#f5f5f5',
  earlysteam = '#fafafa',
  emptiness = '#ffffff',
  dyspnea = '#e7ebec',
  frost = '#d9e8ff',
  fresco = '#a4bcda',
  hssalternateblue = '#6eb1de',
  frenchblue = '#4370a9',
  hssblue = '#0088cf',
  eveningsky = '#2a4e82',
  krishnamurti = '#7343a9',
  ibizateal = '#12a7a3',
  guajirogreen = '#056B59',
  lightishgreen = '#69df86',
  foamgreen = '#93f8a9',
  fadedgreen = '#d5eccc',
  jaliscowave = '#dff5f3',
  peruvianlily = '#ffdf55',
  pacermaize = '#ffcc46',
  orangepink = '#ff6454',
  messyketchup = '#e74c3c',
  coffee = '#6a564e',
  earth = '#a96648',
  earlgray = '#cb7553',
  englishbreakfast = '#e19068',
  farofa = '#e2946d',
  fawn = '#f8aa7e',
  francisbacon = '#f0bca3',
  munch = '#f9c7af',
  norwegiansalmon = '#ffb1a9',
  lavender = '#f0e3ff',
  papaya = '#ffdfdc',
  playa = '#fff5d9',
  iceblue = '#ecf0f1',
}

export const cssColorValues = {
  'var(--betterpt-black)': '#333333',
  'var(--cauldron-black)': '#4d4d4d',
  'var(--charcoal-gray)': '#717171',
  'var(--city-block)': '#919191',
  'var(--concrete-jungle)': '#b1b1b1',
  'var(--coney-island-sidewalk)': '#c0c0c0',
  'var(--dust)': '#e6e6e6',
  'var(--early-smoke)': '#f5f5f5',
  'var(--early-steam)': '#fafafa',
  'var(--emptiness)': '#ffffff',
  'var(--evening-sky)': '#2a4e82',
  'var(--french-blue)': '#4370a9',
  'var(--fresco)': '#a4bcda',
  'var(--frost)': '#d9e8ff',
  'var(--krishnamurti)': '#7343a9',
  'var(--messy-ketchup)': '#e74c3c',
  'var(--orange-pink)': '#ff6454',
  'var(--ibiza-teal)': '#12a7a3',
  'var(--guajiro-green)': '#056B59',
  'var(--pacer-maize)': '#ffcc46',
  'var(--peruvian-lily)': '#ffdf55',
  'var(--coffee)': '#6a564e',
  'var(--earth)': '#a96648',
  'var(--earl-gray)': '#cb7553',
  'var(--english-breakfast)': '#e19068',
  'var(--farofa)': '#e2946d',
  'var(--fawn)': '#f8aa7e',
  'var(--francis-bacon)': '#f0bca3',
  'var(--munch)': '#f9c7af',
  'var(--norwegian-salmon)': '#ffb1a9',
  'var(--lavender)': '#f0e3ff',
  'var(--jalisco-wave)': '#dff5f3',
  'var(--papaya)': '#ffdfdc',
  'var(--playa)': '#fff5d9',
};
