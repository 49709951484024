import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='38'
    height='39'
    viewBox='0 0 38 39'
  >
    <defs>
      <path
        id='9fspu7zxoa'
        d='M31.667 6.333H6.333c-1.741 0-3.15 1.425-3.15 3.167l-.016 19c0 1.742 1.425 3.167 3.166 3.167h25.334c1.741 0 3.166-1.425 3.166-3.167v-19c0-1.742-1.425-3.167-3.166-3.167zm0 6.334L19 20.583 6.333 12.667V9.5L19 17.417 31.667 9.5v3.167z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g transform='translate(-349 -277) translate(0 -1) translate(241 122) translate(67 88) translate(0 42) translate(41 26.5)'>
                  <path d='M0 0L38 0 38 38 0 38z' />
                  <mask id='s4z9vgf2qb' fill={color || '#FFF'}>
                    <use xlinkHref='#9fspu7zxoa' />
                  </mask>
                  <use fill='#333' fillRule='nonzero' xlinkHref='#9fspu7zxoa' />
                  <g fill={color || '#FFF'} mask='url(#s4z9vgf2qb)'>
                    <path d='M0 0H38V38H0z' />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
