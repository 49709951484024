import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import MTableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

//types
import { Column } from '../types';

// Constants
import { colorValues } from '../../helpers/colors';

const Div = styled.div``;
const P = styled.p``;

const notFoundText = css`
  margin: 0;
  width: 100%;
  text-align: center;
  color: ${colorValues.cityblock};
`;

const notFoundComponent = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchNotFoundRow = <RowData extends object>({
  columns,
  searchNotFoundText,
  searchNotFoundComponent,
}: {
  columns: Column<RowData>[];
  searchNotFoundText?: string;
  searchNotFoundComponent?: React.ReactElement<any>;
}) => {
  const component = !!searchNotFoundComponent && (
    <Div css={notFoundComponent}>{searchNotFoundComponent}</Div>
  );
  const text = !searchNotFoundComponent && (
    <P css={notFoundText}>{searchNotFoundText || 'Search Not Found'}</P>
  );
  return (
    <MTableRow>
      <TableCell colSpan={columns.length + 1}>{component || text}</TableCell>
    </MTableRow>
  );
};

export default SearchNotFoundRow;
