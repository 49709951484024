import React from 'react';
import styled from '@emotion/styled';

//components
import { GridCard } from '../../../../../Shared/GridCard';
import { Header, Button, colorValues, Animation } from '@betterpt/better-components';
import Can from '../../../../../Can';
import FormField from '../../../../../Shared/FormField';
import { IcEdit } from '@betterpt/better-icons';

// styles
import * as sharedStyles from '../../FacilityDetails.style';

// hooks
import { useFacilityQuery, useMeQuery } from '../../../../../../generated/graphql';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import useTextFieldState from '../../../../../../hooks/useTextFieldState';
import useHandleError from '../../../../../../hooks/useHandleError';

// helpers
import { Roles } from '../../../../../../helpers/rbac-rules';
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';

const Body = styled.div`
  margin: 28px 24px 56px 60px;
`;

const HourNote = ({
  facilityId,
  addHoursOfOperationNote,
}: {
  facilityId: string;
  addHoursOfOperationNote: (props: { hoursOfOperationNotes: string }) => Promise<void>;
}) => {
  // hooks
  const handleError = useHandleError();
  const snackbar = useSnackbar();
  const facility = useFacilityQuery({
    variables: { id: facilityId },
  });

  const { primaryColor, loading: remoteConfigLoading } = useRemoteConfigAliases();

  const me = useMeQuery();
  const [isFormDisabled, updateIsFormDisabled] = React.useState(true);
  const [isSaving, updateSaving] = React.useState(false);

  const hoursNote = useTextFieldState();

  const handleformsubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (hoursNote.value.length > 250) {
      snackbar?.openSnackbar({
        isError: true,
        message: 'Hours Note must not exceed 250 characters.',
      });
      return;
    }
    if (isFormDisabled) {
      updateIsFormDisabled(false);
    } else {
      updateSaving(true);
      try {
        await addHoursOfOperationNote({
          hoursOfOperationNotes: hoursNote.value,
        });
        snackbar?.openSnackbar({ isError: false, message: 'Hours Note Saved' });
        updateSaving(false);
        updateIsFormDisabled(true);
      } catch (e) {
        handleError(e);
      }
    }
  };

  React.useEffect(() => {
    if (facility.data) {
      hoursNote.updateValue(facility.data?.clinic?.hoursOfOperationNotes ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility.data]);

  // Loading state
  if (facility.loading || me.loading || remoteConfigLoading) {
    return (
      <GridCard style={sharedStyles.loaderCardStyle} data-testid="in-person-settings-loader">
        <Animation type="providerAppLoader" />
      </GridCard>
    );
  }
  return (
    <GridCard style={{ marginRight: 28 }}>
      <form onSubmit={handleformsubmit}>
        <Header underline style={sharedStyles.headerStyle}>
          <h3 className="H3">Hours Note (non-integrated members only) </h3>
          <Can
            role={Roles[me.data?.me?.role || 'initial']}
            perform="facility:view"
            data={{
              employeeClinics: me?.data?.me?.clinics,
              clinicToView: facility.data?.clinic,
            }}
            yes={() => (
              <Can
                role={Roles[me.data?.me?.role || 'initial']}
                perform="facility:edit"
                yes={() => (
                  <Button
                    style={sharedStyles.editButtonStyle(isFormDisabled, primaryColor)}
                    size="small"
                    type="submit"
                    loading={isSaving}
                    disabled={isSaving}
                  >
                    {isFormDisabled ? 'EDIT' : 'SAVE'}
                  </Button>
                )}
              />
            )}
          />
        </Header>
        <Body>
          <FormField
            id="hourNote"
            formHandler={hoursNote}
            fullWidth
            label="Hours Note"
            placeholder="Add a note to accompany your facility’s hours here, if needed"
            multiline
            disabled={isFormDisabled}
            helperText={!isFormDisabled && `${hoursNote.value.length}/250`}
            InputProps={{
              endAdornment: <IcEdit color={colorValues.coneyislandsidewalk} />,
            }}
          />
        </Body>
      </form>
    </GridCard>
  );
};

export default HourNote;
