import * as React from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

import { Animation } from '@betterpt/better-components';

import useAuthentication from '../hooks/useAuthentication';
import { Loader } from './Home/Home.style';

const ProtectedRoute = ({ children, ...rest }: RouteProps) => {
  const location = useLocation();
  const auth = useAuthentication();

  React.useEffect(() => {
    if (!auth?.redirectTo && !auth?.user) {
      auth?.redirectAfterLogin(`${location.pathname}${location.search}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (auth?.user === undefined) {
    return (
      <Loader>
        <Animation type='providerAppLoader' />
      </Loader>
    );
  }

  return (
    <Route exact {...rest}>
      {auth?.user ? children : <Redirect to='/login' />}
    </Route>
  );
};

export default ProtectedRoute;
