import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='40'
    height='45'
    viewBox='0 0 40 45'
    {...rest}
  >
    <g fill='none' fillRule='nonzero' transform='translate(0 .5)'>
      <ellipse cx='19.976' cy='19.835' fill='#12a7a3' rx='19.926' ry='19.8' />
      <path
        fill='#FFF'
        d='M12.453 43.984c-.035-.08-.053-.126-.078-.188l-.068-.169-.131-.33-.253-.65a92.245 92.245 0 0 1-1.387-3.904 74.267 74.267 0 0 1-1.568-5.365 43.585 43.585 0 0 1-.61-2.832 26.902 26.902 0 0 1-.385-3.07 16.031 16.031 0 0 1-.014-1.736c.032-.619.099-1.28.292-2.046.099-.381.229-.788.426-1.225.197-.43.467-.896.834-1.342a5.343 5.343 0 0 1 1.372-1.177c.065-.04.131-.075.197-.112l.1-.053c.033-.016.054-.023.08-.035.102-.04.204-.083.305-.117.202-.072.401-.134.594-.18a7.498 7.498 0 0 1 2.092-.212c.618.02 1.169.098 1.684.194 1.026.195 1.917.459 2.78.714l1.261.386c.414.122.83.158 1.237.2.817.078 1.614.071 2.368-.007.378-.038.744-.098 1.098-.165.177-.034.351-.08.522-.116.151-.037.302-.076.45-.122a6.894 6.894 0 0 0 1.697-.767c.522-.339.991-.745 1.316-1.266.16-.262.282-.564.312-.934.034-.37-.046-.796-.219-1.263.486.103.958.38 1.317.819.36.435.586 1.023.637 1.622.054.6-.042 1.205-.24 1.755a4.83 4.83 0 0 1-.362.794c-.14.252-.3.493-.478.716a6.46 6.46 0 0 1-2.7 1.996 7.1 7.1 0 0 1-.801.262 9.976 9.976 0 0 1-.757.164c-.504.093-1.007.143-1.5.17-.989.058-1.944.027-2.87.004-.466-.008-.922-.04-1.378.002l-1.348.134c-.878.085-1.726.176-2.45.333a4.863 4.863 0 0 0-.934.287c-.25.112-.422.245-.462.352a.12.12 0 0 0-.009.074c0 .01.008.02.012.029.003.001-.002.007.007.004l.03-.011.057-.028c.16-.078.312-.195.414-.298.208-.213.228-.282.254-.186.02.086.04.298.084.547.043.25.106.547.189.86.163.631.394 1.327.66 2.032.27.707.575 1.43.903 2.156a61.567 61.567 0 0 0 2.208 4.353 80.076 80.076 0 0 0 1.87 3.21l.322.52.16.254.079.123c.023.037.054.086.07.104l-9.288 4.736z'
      />
      <ellipse cx='22.19' cy='15.875' fill='#FFF' rx='1.181' ry='1.173' />
      <path
        fill='#FFF'
        d='M18.38 19.383c-.835-.058-1.624-.093-2.396-.058A15.073 15.073 0 0 0 9.59 21.01c-1.284.669-2.472 1.517-3.435 2.575-.965 1.058-1.673 2.343-1.783 4.004-.416-.721-.563-1.617-.482-2.497.077-.886.403-1.738.843-2.507a8.558 8.558 0 0 1 1.676-2.04 10.207 10.207 0 0 1 2.16-1.486c1.551-.799 3.294-1.23 5.027-1.2 1.724.037 3.448.523 4.782 1.524z'
      />
      <path
        fill='#C6C6C6'
        d='M16.387 19.897c-1.324.11-2.539.36-3.693.78-1.152.421-2.233.984-3.256 1.649a18.934 18.934 0 0 0-2.867 2.293c-.443.43-.859.886-1.244 1.373-.2.242-.37.499-.548.763-.162.268-.32.546-.448.852a3.59 3.59 0 0 1 .066-1.002c.074-.328.17-.657.3-.968a8.38 8.38 0 0 1 .992-1.766 11.177 11.177 0 0 1 2.94-2.772c1.14-.739 2.422-1.286 3.768-1.534 1.342-.236 2.756-.168 3.99.332z'
      />
      <path
        fill='#FFF'
        d='M31.17 19.4l-.042-.014c-1.196-.425-1.534-1.663-.718-2.63l.03-.033 1.824.922L31.17 19.4z'
      />
      <path
        fill='#12a7a3'
        d='M30.694 19.863l-.045-.019a2.266 2.266 0 0 1-.9-3.303l.028-.038 1.916 1.26-1 2.1z'
      />
      <path
        fill='#C6C6C6'
        d='M17.196 42.54l-.085-.146-.07-.13-.136-.25-.26-.494a40.697 40.697 0 0 1-.484-.989c-.312-.661-.6-1.331-.875-2.009a32.95 32.95 0 0 1-1.375-4.198c-.364-1.444-.614-2.946-.66-4.514a13.24 13.24 0 0 1 .151-2.398c.133-.81.353-1.632.715-2.419a7.434 7.434 0 0 1 .834-1.399l.098-.13.086-.112a2.26 2.26 0 0 1 .72-.594c.2-.105.389-.171.56-.215.342-.087.618-.099.868-.099.25.002.468.024.676.05a10.8 10.8 0 0 1 1.138.22c.707.173 1.364.363 2.016.534.65.17 1.293.33 1.933.449.641.12 1.282.196 1.92.211a6.585 6.585 0 0 0 1.88-.213 4.97 4.97 0 0 0 1.722-.843 6.154 6.154 0 0 0 1.37-1.478 6.028 6.028 0 0 1-1.017 1.836 5.576 5.576 0 0 1-1.706 1.382c-.661.35-1.386.576-2.115.718-.731.144-1.469.195-2.197.209-.727.013-1.448-.02-2.152-.07-.702-.048-1.396-.112-2.047-.145a9.546 9.546 0 0 0-.91-.008 2.36 2.36 0 0 0-.352.04c-.093.02-.16.05-.146.064.007.006.035.006.087-.015a.637.637 0 0 0 .189-.126c.018-.016.032-.034.049-.05l.022-.026-.01.02-.042.085a4.153 4.153 0 0 0-.314.886c-.248.984-.201 2.112.022 3.26.215 1.15.614 2.318 1.083 3.47a33.296 33.296 0 0 0 1.662 3.41c.307.558.633 1.11.967 1.652.167.271.337.541.508.805l.255.392.127.19.062.09a.922.922 0 0 0 .052.072l-4.82 3.026z'
      />
      <path
        fill='#FFF'
        d='M7.873 8.835c.91-.365 1.857-.622 2.825-.806.968-.185 1.96-.275 2.96-.278 1.995.002 4.034.4 5.887 1.23.462.206.916.435 1.355.683.436.254.864.522 1.271.812.816.581 1.572 1.23 2.27 1.916.701.683 1.345 1.402 1.99 2.087.323.342.65.673 1.007.96.358.28.754.529 1.245.581-.234.074-.494.08-.75.05a3.068 3.068 0 0 1-.74-.205c-.477-.19-.908-.455-1.321-.723-.824-.547-1.577-1.143-2.337-1.7-.76-.559-1.514-1.097-2.297-1.577a18.78 18.78 0 0 0-1.188-.682c-.4-.215-.809-.415-1.223-.595-1.657-.74-3.425-1.207-5.262-1.46-1.841-.262-3.736-.284-5.692-.293z'
      />
      <path
        fill='#C6C6C6'
        d='M18.34 10.402a10.355 10.355 0 0 0-3.971-.553c-1.305.069-2.587.352-3.832.768a19.92 19.92 0 0 0-3.601 1.641c-.576.335-1.135.7-1.673 1.103a9.973 9.973 0 0 0-1.51 1.388c.185-.685.557-1.325.995-1.895.443-.57.961-1.081 1.52-1.54a12.213 12.213 0 0 1 3.795-2.086c1.382-.462 2.871-.68 4.341-.528a7.64 7.64 0 0 1 2.132.534c.673.285 1.297.67 1.803 1.168z'
      />
    </g>
  </svg>
);
