import axios from 'axios';

// types
import { TextFieldState } from '../../../../hooks/useTextFieldState';

// hooks
import useAuthentication from '../../../../hooks/useAuthentication';
import {
  useUpdateEmployeeMutation,
  useMarkEmployeeAsTherapistMutation,
} from '../../../../generated/graphql';

export interface UpdateProfileInfoFormData {
  employeeId?: string;
  firstNameFormState: TextFieldState;
  lastNameFormState: TextFieldState;
  genderFormState: TextFieldState;
}

export interface UpdateProfileImagePayload {
  employeeId?: string;
  base64Img: string;
}

const useProfileInfoOperations = () => {
  const [updateProfile] = useUpdateEmployeeMutation();
  const [markAsTherapist] = useMarkEmployeeAsTherapistMutation();
  const auth = useAuthentication();

  const updateProfileInfo = async (formData: UpdateProfileInfoFormData) => {
    const {
      employeeId,
      firstNameFormState,
      lastNameFormState,
      genderFormState,
    } = formData;
    if (!employeeId) {
      throw new Error(
        'Your account could not be accessed, please refresh and try again.'
      );
    } else if (!firstNameFormState.valid) {
      throw new Error('You must include a valid first name.');
    } else if (!lastNameFormState.valid) {
      throw new Error('You must enter a valid last name.');
    } else if (!genderFormState.valid) {
      throw new Error('You must provide a valid gender.');
    }
    await markAsTherapist({
      variables: {
        input: {
          employeeId,
          isTherapist: true,
        },
      },
    });
    await updateProfile({
      variables: {
        input: {
          employeeId,
          firstName: firstNameFormState.value,
          lastName: lastNameFormState.value,
          gender: genderFormState.value,
        },
      },
    });
  };

  const uploadProfileImage = async ({
    base64Img,
    employeeId,
  }: UpdateProfileImagePayload) => {
    const userSession = auth?.user?.getSignInUserSession();
    const token = userSession?.getIdToken();
    const endpoint =
      window.__RUNTIME_CONFIG__.REACT_APP_API_ENDPOINT ??
      'https://api.development.betterpt.com/v0/api';
    if (!token) {
      throw new Error(
        'There was an issue accessing your account. Please refresh the page.'
      );
    }
    await axios.post(
      `${endpoint}/employees/${employeeId}/avatar`,
      { file: base64Img },
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: token?.getJwtToken(),
        },
      }
    );
  };

  return {
    commands: {
      updateProfileInfo,
      uploadProfileImage,
    },
  };
};

export default useProfileInfoOperations;
