import axios from 'axios';

// types
import { TextFieldState } from '../../../../hooks/useTextFieldState';
import { HealthcareVertical } from '../../../../generated/graphql';
// hooks
import useAuthentication from '../../../../hooks/useAuthentication';
import {
  useUpdateCompanyMutation,
  useUpdateCompanyLocationMutation,
  useMeQuery,
  useCompanyBillingOverviewQuery,
} from '../../../../generated/graphql';

export interface UpdateCompanyInfoFormData {
  companyId?: string;
  companyNameFormState: TextFieldState;
  phoneNumberFormState: TextFieldState;
  contactEmailFormState: TextFieldState;
  companyWebsiteFormState: TextFieldState;
  companyVerticalFormState: TextFieldState;
  addressFormState: TextFieldState;
  stateFormState: TextFieldState;
  cityFormState: TextFieldState;
  zipcodeFormState: TextFieldState;
}

export interface UpdateCompanyAvatarPayload {
  companyId?: string;
  base64Img: string;
}

const useCompanyInfoOperations = () => {
  const [updateCompany] = useUpdateCompanyMutation();
  const [updateCompanyLocation] = useUpdateCompanyLocationMutation();
  const meQuery = useMeQuery();
  const billingProfileQuery = useCompanyBillingOverviewQuery({
    variables: { id: meQuery.data?.me?.company?.id ?? '' },
    skip: !meQuery.data?.me?.company?.id,
  });
  const auth = useAuthentication();

  const updateCompanyInfo = async (formData: UpdateCompanyInfoFormData) => {
    const allowSelectIndustry =
      billingProfileQuery.data?.company?.billingOverview?.billingPartnership !==
      'Clinicient';

    const {
      companyId,
      companyNameFormState,
      phoneNumberFormState,
      contactEmailFormState,
      companyWebsiteFormState,
      companyVerticalFormState,
      addressFormState,
      stateFormState,
      cityFormState,
      zipcodeFormState,
    } = formData;
    if (!companyId) {
      throw new Error(
        'Your account could not be accessed, please refresh and try again.'
      );
    } else if (!companyNameFormState.valid) {
      throw new Error('You must include a valid company name.');
    } else if (!phoneNumberFormState.valid) {
      throw new Error('You must enter a valid phone number.');
    } else if (!contactEmailFormState.valid) {
      throw new Error('You must enter a valid company contact email.');
    } else if (!companyWebsiteFormState.valid) {
      throw new Error('The company website you enter must be a valid URL');
    } else if (!companyVerticalFormState.valid && allowSelectIndustry) {
      throw new Error('You must select a healthcare vertical.');
    } else if (!addressFormState.valid) {
      throw new Error('You must enter a valid address.');
    } else if (!cityFormState.valid) {
      throw new Error('You must enter a valid city name.');
    } else if (!stateFormState.valid) {
      throw new Error('You must select a state.');
    } else if (!zipcodeFormState.valid) {
      throw new Error('You must enter a valid zipcode.');
    }
    await updateCompany({
      variables: {
        input: {
          companyId,
          companyName: companyNameFormState.value,
          phone: phoneNumberFormState.value,
          email: contactEmailFormState.value,
          website: companyWebsiteFormState.value,
          healthcareVertical: allowSelectIndustry
            ? (companyVerticalFormState.value as HealthcareVertical)
            : HealthcareVertical.PhysicalTherapy,
        },
      },
    });
    await updateCompanyLocation({
      variables: {
        input: {
          companyId,
          address: addressFormState.value,
          state: stateFormState.value,
          city: cityFormState.value,
          zipcode: zipcodeFormState.value,
        },
      },
    });
  };

  const uploadCompanyAvatar = async ({
    base64Img,
    companyId,
  }: UpdateCompanyAvatarPayload) => {
    const userSession = auth?.user?.getSignInUserSession();
    const token = userSession?.getIdToken();
    const endpoint =
      window.__RUNTIME_CONFIG__.REACT_APP_API_ENDPOINT ??
      'https://api.development.betterpt.com/v0/api';

    if (!token) {
      throw new Error(
        'There was an issue accessing your account. Please refresh the page.'
      );
    }
    await axios.post(
      `${endpoint}/companies/${companyId}/avatar`,
      { file: base64Img },
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: token?.getJwtToken(),
        },
      }
    );
  };

  return {
    commands: {
      updateCompanyInfo,
      uploadCompanyAvatar,
    },
  };
};

export default useCompanyInfoOperations;
