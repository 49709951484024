import { css } from '@emotion/react';
import { colorValues } from '../helpers/colors';

export const mainStyle = ({
  width,
  type,
}: {
  width: number | undefined;
  type: 'avatar' | 'hero';
}) => css`
  font-family: Roboto, sans-serif;
  margin: 0 auto;
  width: ${width || (type === 'avatar' && 160) || 400}px;
`;

export const imageUploader = ({
  width,
  type,
}: {
  width: number | undefined;
  type: 'avatar' | 'hero';
}) => css`
  position: relative;
  width: ${width || (type === 'avatar' && 160) || 400}px;
  height: ${(type === 'avatar' && `${width || 160}px`) || ''};
  overflow: hidden;
  display: flex;
  border-radius: ${type === 'avatar' ? '50%' : 0};
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const dropSection = (isActive: boolean) => css`
  background-color: ${isActive ? 'rgba(51, 51, 51, 0.5)' : ''};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:hover {
    background-color: rgba(51, 51, 51, 0.5);
    svg {
      opacity: 1;
    }
  }
  svg {
    height: 40%;
    width: 40%;
    opacity: ${isActive ? 1 : 0};
  }
`;

export const avatarHelperText = css`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: ${colorValues.cityblock};
`;

export const rejectMessageStyle = css`
  text-align: center;
  font-size: 14px;
  color: white;
`;
