import * as React from 'react';
import styled from '@emotion/styled';

// components
import { GridCard } from '../../../Shared/GridCard';
import CardTextField from './CardTextField';

// style
import { colorValues } from '@betterpt/better-components';

export const InfoHeader = styled.div`
  background-color: ${colorValues.earlysteam};
  padding: 10px 39px;
  border-bottom: solid 1px ${colorValues.concretejungle};
  border-radius: 5px 5px 0px 0px;
  p {
    color: ${colorValues.charcoalgray};
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
`;

export const CardContents = styled.div`
  padding: 15px 15px 20px 40px;
`;

export const cardStyle = {
  marginRight: '25px',
  width: '504px',
  position: 'relative' as 'relative',
  borderRadius: '5px',
  margin: '20px 28px',
  border: `1px solid ${colorValues.dust}`,
  boxShadow: 'none !important',
};

export const TextFieldRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

type Props = {
  customFormResponse: any;
};

const CustomFormResponse = ({ customFormResponse }: Props) => {
  if (Object.entries(customFormResponse).length === 0) return null;
  return (
    <GridCard style={cardStyle} shadow='none'>
      <InfoHeader>
        {/* Custom Questions now to be called Qualifying Question since the section has been seperated from Appointment Info */}
        <p>Qualifying Questions</p>
      </InfoHeader>
      <CardContents data-cy='in-person-appt-custom-responses'>
        {Object.entries(customFormResponse).map(([key, value]) => (
          <TextFieldRow key={key}>
            <CardTextField fullWidth label={key} value={value as string} />
          </TextFieldRow>
        ))}
      </CardContents>
    </GridCard>
  );
};

export default CustomFormResponse;
