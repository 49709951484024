import React from 'react';
import styled from '@emotion/styled';
import { Button, colorValues } from '@betterpt/better-components';
import { IcArrowDown, IcArrowUp, IcRemove } from '@betterpt/better-icons';
import { ArrayFieldTemplateProps } from '@rjsf/core';

const EditButton = styled.button<{ color: string }>`
  border: none;
  background-color: ${(props) => props.color};
  height: 28px;
  width: 40px;
  border-radius: 5px;
  margin: 5px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 5px;
`;

const QuestionContainer = styled.div`
  position: relative;
  margin: 20px 0px;
  border: 2px solid ${colorValues.concretejungle};
  border-radius: 5px;
  padding: 10px 0px;
`;

const QuestionNumber = styled.h3`
  margin: 5px 14px 0px;
  color: ${colorValues.charcoalgray};
`;

const QuestionsArrayTemplate = (props: ArrayFieldTemplateProps) => {
  return (
    <div>
      <h2 style={{ maxWidth: '300px' }} className='H2'>
        {`${props.uiSchema['ui:title']} ${props.schema.title}`}
      </h2>
      <p className='Body'>
        <i>{`${props.uiSchema['ui:description']} ${props.schema.description}`}</i>
      </p>
      {props.items &&
        props.items.map((element) => (
          <QuestionContainer key={element.key}>
            <QuestionNumber className='H3'>
              Question {element.index + 1}
            </QuestionNumber>
            {element.children}
            <ButtonContainer>
              <EditButton
                title='Move question up'
                color={element.hasMoveUp ? colorValues.frost : colorValues.dust}
                onClick={element.onReorderClick(
                  element.index,
                  element.index - 1
                )}
                disabled={!element.hasMoveUp}
              >
                <IcArrowUp
                  color={
                    element.hasMoveUp
                      ? colorValues.eveningsky
                      : colorValues.emptiness
                  }
                  opacity={1}
                  height='12px'
                  width='12px'
                />
              </EditButton>
              <EditButton
                title='Move question down'
                color={
                  element.hasMoveDown ? colorValues.frost : colorValues.dust
                }
                onClick={element.onReorderClick(
                  element.index,
                  element.index + 1
                )}
                disabled={!element.hasMoveDown}
              >
                <IcArrowDown
                  color={
                    element.hasMoveDown
                      ? colorValues.eveningsky
                      : colorValues.emptiness
                  }
                  opacity={1}
                  height='12px'
                  width='12px'
                />
              </EditButton>
              <EditButton
                title='Delete question'
                color={colorValues.orangepink}
                onClick={element.onDropIndexClick(element.index)}
              >
                <IcRemove
                  height='25px'
                  width='25px'
                  color={colorValues.emptiness}
                />
              </EditButton>
            </ButtonContainer>
          </QuestionContainer>
        ))}

      {props.canAdd && (
        <Button
          color='lightBlue'
          fullWidth
          onClick={props.onAddClick}
          type='button'
          size='large'
        >
          ADD NEW QUESTION
        </Button>
      )}
    </div>
  );
};

export default QuestionsArrayTemplate;
