import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    width='25'
    height='25'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...rest}
  >
    <defs>
      <path
        d='m21.313 5.59 1.037 1.037-8.828 8.828-2.466-2.558 1.045-1.037 1.421 1.513 7.79-7.783zm-8 0 1.037 1.037-8.828 8.828-4.112-4.112 1.045-1.037 3.067 3.067 7.79-7.783z'
        id='a'
      />
    </defs>
    <g transform='translate(.5 .138)' fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <mask id='b' fill='#fff'>
        <use xlinkHref='#a' />
      </mask>
      <use fill='#333' fillRule='nonzero' xlinkHref='#a' />
      <g mask='url(#b)' fill={color || '#919191'}>
        <path d='M0 0h24v24H0z' />
      </g>
    </g>
  </svg>
);
