import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import MTableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

//types
import { Column } from '../types';

// Components
import Animation from '../../Animation';

// Constants
import { colorValues } from '../../helpers/colors';

const P = styled.p``;

const loadingText = (hasRowData: boolean) => css`
  margin: 0;
  padding-top: ${hasRowData ? '0' : '5vh'};
  width: 100%;
  text-align: center;
  color: ${colorValues.cauldronblack};
`;

const LoadingRow = <RowData extends object>({
  columns,
  data,
}: {
  columns: Column<RowData>[];
  data: RowData[];
}) => {
  return (
    <MTableRow style={{ border: 'none' }}>
      <TableCell style={{ border: 'none' }} colSpan={columns.length}>
        <P css={loadingText(data.length > 0)}>Loading...</P>
        <Animation type='alternateloader' height='10px' width='100%' />
      </TableCell>
    </MTableRow>
  );
};

export default LoadingRow;
