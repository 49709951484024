import { colorValues } from '@betterpt/better-components';
import styled from '@emotion/styled';

export const PatientDetailContainer = styled.div`
  padding: 35px 28px;
`;

export const PatientDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  max-width: 1315px;
  h1 {
    max-width: 650px;
    width: 100%;
    margin: 0 15px 0 0;
  }
`;

export const addAppointmentStyleButton = (primaryColor: string) => ({
  backgroundColor: primaryColor,
  color: colorValues.emptiness,
  fontWeight: 'bold' as 'bold',
  height: '48px',
  width: '260px',
});

export const cardStyle = {
  width: '100%',
  marginBottom: 25,
};

export const createAppointmentDialogStyles = {
  position: 'fixed' as 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 11,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  overflow: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const breadCrumbsLink = {
  textDecoration: 'underline',
  color: colorValues.frenchblue,
};

export const betterAccessPatientButton = {
  backgroundColor: colorValues.emptiness,
  color: colorValues.betterptblack,
  borderRadius: '23.5px',
  boxShadow:
    '0 0 4px 0 rgba(51, 51, 51, 0.47), 0 2px 2px 0 rgba(51, 51, 51, 0.24)',
  justifyContent: 'space-between',
  width: '100%',
  height: 'auto',
};

export const LinkedProfileButtonText = styled.p`
  text-align: left;
  margin: 0;
`;

export const betterAccessPatientButtonContainer = {
  maxWidth: '650px',
  width: '100%',
};

export const LinkedPatientText = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 25px;
  }
`;

export const CardBody = styled.div`
  margin: 10px 44px;
  p {
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 50px;
    margin-top: 50px;
    color: ${colorValues.cityblock};
  }
`;
