import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import Auth from '@aws-amplify/auth';

const env = (window.__RUNTIME_CONFIG__.REACT_APP_NODE_ENV ?? 'local') as
  | 'local'
  | 'development'
  | 'staging'
  | 'production'
  | 'test';

const uri =
  window.__RUNTIME_CONFIG__.REACT_APP_GRAPHQL_ENDPOINT ??
  'https://api.development.betterpt.com/v0/graphql';

const AsyncTokenLookup = async () => {
  try {
    const session = await Auth.currentSession();

    const token = session.getIdToken().getJwtToken();
    return token;
  } catch (error) {
    const href = window.location.href;
    // check location is not on auth pages before pushing to login
    if (
      !href.includes('/login') &&
      !href.includes('/signup') &&
      !href.includes('confirm') &&
      !href.includes('change-password') &&
      !href.includes('forgot-password')
    ) {
      localStorage.clear();

      window.location.href = '/login?sessionExpired=true';
      return undefined;
    }
  }
};

const authLink = setContext(async (_, { headers }) => {
  const token = await AsyncTokenLookup();

  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
  uri,
});

export default new ApolloClient({
  name: `provider-app-${env}`,
  version: 'BetterAccess 2.0.0',
  cache: new InMemoryCache(),
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  connectToDevTools: env !== 'production',
  defaultOptions: {
    watchQuery: { errorPolicy: 'all' },
  },
});
