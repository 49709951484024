import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

export const AnalyticsRow = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const AnalyticsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 5%;
`;

export const AnalyticsCircleColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 140px;
`;

export const AnalyticsSectionTitle = styled.h2`
  font-size: 20px;
  width: max-content;
  margin: 0 0 20px 10px;
`;

export const AnalyticsListLink = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: ${colorValues.frenchblue};
  height: min-content;
  margin: 1px 0 25px 45px;
`;

export const AnalyticsCircle = styled.div<{ color: string }>`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: ${(props) => props.color || colorValues.ibizateal};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export const AnalyticsCircleText = styled.p`
  font-size: 16px;
  color: ${colorValues.emptiness};
  font-weight: bold;
  margin: 0;
`;

export const AnalyticsCaptionText = styled.p`
  color: ${colorValues.charcoalgray};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  max-width: 170px;
  margin: 0;
  margin-bottom: 10px;
`;

export const AnalyticsParagraph = styled.p`
  width: 350px;
  margin-top: 75px;
  margin-bottom: 25px;
  line-height: 1.43;
`;

export const CSVParagraph = styled.p`
  width: 500px;
  margin-top: 50px;
  margin-bottom: 10px;
  line-height: 1.43;
`;

export const GraphKey = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0 50px;
`;

export const GraphKeyColor = styled.div<{ color: string }>`
  background-color: ${(props) => props.color || colorValues.frenchblue};
  border-radius: 50%;
  height: 15px;
  width: 15px;
  margin-left: 40px;
  margin: 0 10px 10px 40px;
`;

//LOOKER STYLES

const cardBoxShadow = `0 2px 10px 0 rgb(51 51 51 / 24%), 0 2px 2px 0 rgb(51 51 51 / 6%),0px 2px 7px 0px rgb(51 51 51 / 6%), 0 0 2px 0 rgb(51 51 51 / 6%)`;

export const ExternalAnalyticsContainer = styled.div`
  display: flex;
  height: 100%;
  width: calc(100% - 30px);
`;

export const ExternalAnalayticsCard = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 5px;
  padding: clamp(100px, 20vh, 250px) clamp(50px, 10vw, 300px);
  box-shadow: ${cardBoxShadow};
`;

export const ExternalAccessImage = styled.div`
  height: 182px;
  width: 182px;
  position: relative;
  border-radius: 50%;
  background-color: ${colorValues.ibizateal};
  img {
    top: 18px;
    left: -35px;
    width: 249px;
    position: absolute;
  }
`;
export const ExternalAnalyticsHeader = styled.h1`
  font-size: 20px;
  margin: 38px 0 0;
  font-weight: bold;
  text-align: center;
`;

export const ExternalAnalyticsMessage = styled.p`
  font-size: 14px;
  line-height: 1.43;
  margin: 18px 0 25px;
`;

export const CVSDownloadCard = styled.div`
  flex: 1.5;
  height: 40%;
  border-radius: 5px;
  box-shadow: ${cardBoxShadow};
  margin-left: 20px;
`;

export const CSVDownloadHeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid ${colorValues.coneyislandsidewalk};
  padding: clamp(20px, 2vh, 40px) 0;
`;

export const CSVDownloadIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 5px;
  margin-left: clamp(20px, 2vw, 40px);
  background-color: ${colorValues.ibizateal};
  svg {
    width: 15px;
    height: auto;
  }
`;

export const CSVDownloadHeader = styled.h2`
  margin: 0 0 0 9px;
  font-size: 16px;
`;

export const CSVDownloadBody = styled.div`
  padding: clamp(20px, 2vh, 50px) clamp(20px, 2vw, 40px);
  text-align: left;
  p {
    margin: 0 0 clamp(30px, 2.2vh, 50px) 0;
    font-size: 14px;
    line-height: 1.43;
  }
  button {
    margin-bottom: clamp(20px, 2vh, 40px);
  }
`;
