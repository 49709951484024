import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='161'
    height='160'
    viewBox='0 0 161 160'
  >
    <defs>
      <path
        id='12xuovomfa'
        d='M0.704 0.822L66.522 0.822 66.522 30.21 0.704 30.21z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    fill='#4D4D4D'
                    d='M100 77.933L12.539 98 7 36.91 80.702 20z'
                    transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(29 1)'
                  />
                  <g>
                    <path
                      fill='#A4BBD9'
                      d='M102 131.768V157H0v-25.232c0-23.517 15.905-43.32 37.55-49.22 1.468-.415 2.959-.751 4.483-1.02 2.914-.516 5.907-.785 8.967-.785 3.856 0 7.6.426 11.209 1.233 1.524.348 3.015.752 4.483 1.234 7.768 2.5 14.729 6.83 20.367 12.482C96.295 104.92 102 117.681 102 131.768'
                      transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(29 1)'
                    />
                    <path
                      fill='#FEFEFE'
                      d='M66.692 83.21v2.95c0 5.841-3.434 10.88-8.393 13.207-1.876.879-3.969-5.727-6.178-5.727-2.012 0-3.929 6.69-5.673 5.952-5.23-2.213-8.899-7.394-8.899-13.432v-3.611c1.469-.415 2.96-.752 4.484-1.02l20.176.448c1.524.347 3.015.751 4.483 1.233'
                      transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(29 1)'
                    />
                    <path
                      fill='#2B4F83'
                      d='M38.67 83.406v2.754c0 5.355 3.149 10.171 8.036 12.322.412-.326 1.132-1.525 1.576-2.265 1.142-1.902 2.22-3.7 3.839-3.7 1.672 0 2.885 1.813 4.06 3.564.5.747 1.319 1.97 1.752 2.218 4.643-2.23 7.638-6.982 7.638-12.14V84.03c-1.11-.343-2.264-.65-3.501-.935l-19.95-.443c-1.177.21-2.332.463-3.45.754m8.09 17.38c-.238 0-.487-.05-.748-.16-5.822-2.465-9.583-8.142-9.583-14.466v-3.612c0-.501.334-.942.817-1.079 1.471-.416 3.017-.768 4.592-1.045.074-.014.142-.02.22-.017l20.175.449c.077.002.15.01.226.026 1.637.374 3.136.788 4.583 1.262.46.151.771.582.771 1.067v2.949c0 6.063-3.547 11.646-9.037 14.222-1.84.861-3.23-1.22-4.457-3.052-.556-.83-1.712-2.554-2.198-2.57-.43.134-1.397 1.745-1.917 2.612-1.009 1.682-2.049 3.415-3.443 3.415'
                      transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(29 1)'
                    />
                    <path
                      fill='#CB7553'
                      d='M62.209 66.725v16.821c0 5.574-4.517 10.093-10.088 10.093-5.57 0-10.088-4.52-10.088-10.093V66.725h20.176z'
                      transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(29 1)'
                    />
                    <path
                      fill='#E19068'
                      d='M84.7 34.981c2.338 10.267.302 20.508-4.899 28.767-5.187 8.267-13.53 14.54-23.792 16.88-20.524 4.678-40.947-8.17-45.625-28.705-.092-.404-.17-.8-.252-1.207-.845-4.316-.912-8.603-.323-12.728.079-.559.173-1.11.28-1.651.892-4.712 2.668-9.178 5.194-13.18 5.187-8.268 13.53-14.54 23.792-16.881 8.447-1.926 16.87-.89 24.181 2.447.086.026.166.066.26.124 1.486.674 2.93 1.46 4.311 2.33 4.01 2.514 7.546 5.768 10.39 9.628 2.15 2.926 3.914 6.182 5.184 9.734.377 1.052.715 2.137 1.002 3.244.104.403.204.793.297 1.198'
                      transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(29 1)'
                    />
                    <path
                      fill='#2B4F83'
                      d='M36.17 54.096c-1.206.275-2.407-.481-2.683-1.69l-.805-3.534c-.275-1.208.48-2.41 1.688-2.685 1.207-.274 2.408.48 2.683 1.69l.805 3.533c.276 1.209-.48 2.41-1.688 2.686M60.213 48.615c-1.206.274-2.407-.481-2.683-1.69l-.805-3.534c-.275-1.208.48-2.41 1.688-2.685 1.207-.274 2.408.48 2.684 1.69l.804 3.533c.275 1.209-.48 2.41-1.688 2.686'
                      transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(29 1)'
                    />
                    <g transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(29 1) translate(15.692)'>
                      <mask id='1p8spdi8yb' fill='#fff'>
                        <use xlinkHref='#12xuovomfa' />
                      </mask>
                      <path
                        fill='#4D4D4D'
                        d='M.704 16.121c.125.28.25.56.385.824.796 1.592 1.871 3.052 3.198 4.373l.004.013c8.017 8.04 25.019 11.199 43.19 7.056 7.116-1.622 13.587-4.171 19.04-7.335-.348-.552-.705-1.088-1.07-1.608-3.152-4.544-7.036-8.398-11.42-11.4C45.009 1.833 33.907-.737 22.863 1.78 13.77 3.852 6.143 9.042.704 16.121'
                        mask='url(#1p8spdi8yb)'
                      />
                    </g>
                    <path
                      fill='#4D4D4D'
                      d='M5.378 56.132C19.752 46.764 27.83 34.689 25.323 23.68c-.353-1.549-.903-3.035-1.647-4.441-1.092-2.088-2.585-3.996-4.44-5.716-.254-.26-.52-.5-.803-.737-1.608 1.925-3.08 3.978-4.412 6.104-3.87 6.16-6.59 13.03-7.956 20.281-1.023 5.492-1.297 11.185-.687 16.96'
                      transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(29 1)'
                    />
                    <path
                      fill='#FEFEFE'
                      d='M52.307 118.208c-1.826 0-3.307-1.48-3.307-3.308 0-1.827 1.481-3.308 3.307-3.308 1.827 0 3.307 1.48 3.307 3.308 0 1.827-1.48 3.308-3.307 3.308'
                      transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(29 1)'
                    />
                    <path
                      fill='#2B4F83'
                      d='M55.053 114.9c0-1.516-1.233-2.747-2.746-2.747-1.514 0-2.746 1.231-2.746 2.747 0 1.515 1.232 2.748 2.746 2.748 1.513 0 2.746-1.233 2.746-2.748m-6.613 0c0-2.133 1.734-3.868 3.867-3.868 2.132 0 3.867 1.735 3.867 3.868s-1.735 3.87-3.867 3.87c-2.133 0-3.867-1.737-3.867-3.87'
                      transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(29 1)'
                    />
                    <path
                      fill='#FEFEFE'
                      d='M52.307 149.327c-1.826 0-3.307-1.48-3.307-3.308 0-1.827 1.481-3.307 3.307-3.307 1.827 0 3.307 1.48 3.307 3.307 0 1.828-1.48 3.308-3.307 3.308'
                      transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(29 1)'
                    />
                    <path
                      fill='#2B4F83'
                      d='M55.053 146.019c0-1.515-1.233-2.748-2.746-2.748-1.514 0-2.746 1.233-2.746 2.748 0 1.515 1.232 2.747 2.746 2.747 1.513 0 2.746-1.232 2.746-2.747m-6.613 0c0-2.133 1.734-3.869 3.867-3.869 2.132 0 3.867 1.736 3.867 3.869s-1.735 3.869-3.867 3.869c-2.133 0-3.867-1.736-3.867-3.869'
                      transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(29 1)'
                    />
                  </g>
                </g>
                <g>
                  <path
                    fill='#294F83'
                    d='M1.94 66.816V69.3c.002.41.782.885 1.748.885h126.624c.966 0 1.746-.475 1.748-.87v-2.498H1.94zM130.312 72H3.688C1.628 72 .008 70.825 0 69.325V65h134v4.3c-.009 1.525-1.63 2.7-3.688 2.7z'
                    transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(13 88)'
                  />
                  <path
                    fill='#A3BBD8'
                    d='M76 62c-.246 1.722-1.14 3-2.202 3H60.203c-1.065 0-1.957-1.278-2.203-3h18z'
                    transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(13 88)'
                  />
                  <path
                    fill='#294F83'
                    d='M59.343 63.004c.334.605.923.992 1.576.992h14.162c.653 0 1.242-.387 1.576-.992H59.343zM75.08 65H60.919c-1.324 0-2.468-.982-2.782-2.389L58 62h20l-.137.611C77.548 64.018 76.404 65 75.081 65z'
                    transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(13 88)'
                  />
                  <path
                    fill='#E5E5E4'
                    d='M132.513 70H5.684C4.204 70 3 68.87 3 67.482c0-.253.218-.482.487-.482.27 0 .487.18.487.431v.05c0 .885.767 1.605 1.71 1.605h126.829c.269 0 .487.205.487.457s-.218.457-.487.457'
                    transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(13 88)'
                  />
                  <path
                    fill='#F9F9FA'
                    d='M116.007 70H16.993C15.341 70 14 68.676 14 67.042V6.162C14 3.31 16.338 1 19.223 1h94.555C116.662 1 119 3.31 119 6.161v60.88c0 1.635-1.34 2.959-2.993 2.959'
                    transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(13 88)'
                  />
                  <path
                    fill='#294F83'
                    d='M19.142 1.954c-2.322 0-4.212 1.912-4.212 4.262V68.02c0 1.117.898 2.026 2.002 2.026h98.136c1.104 0 2.002-.909 2.002-2.026V6.216c0-2.35-1.89-4.262-4.211-4.262H19.142zM115.068 72H16.932C14.764 72 13 70.214 13 68.02V6.216C13 2.788 15.756 0 19.142 0h93.717C116.245 0 119 2.788 119 6.216V68.02c0 2.194-1.764 3.98-3.932 3.98z'
                    transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(13 88)'
                  />
                  <path
                    fill='#E5E5E4'
                    d='M111.03 69H22.105C18.74 69 16 66.242 16 62.85V8.978c0-.54.435-.978.97-.978.538 0 .972.437.972.978V62.85c0 2.313 1.868 4.194 4.164 4.194h88.923c.537 0 .971.437.971.978 0 .54-.434.978-.97.978'
                    transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(13 88)'
                  />
                  <path
                    fill='#E5E5E4'
                    d='M74 36c0 3.866-3.134 7-7 7s-7-3.134-7-7 3.134-7 7-7 7 3.134 7 7'
                    transform='translate(-745 -322) translate(0 -1) translate(246 117) translate(355 206) translate(144.5) translate(13 88)'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
