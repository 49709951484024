import React from 'react';

//types
import { Role } from '../../../../generated/graphql';
import {
  MappedAppointmentTypeDetail,
  MappedAppointmentTypeProvider,
  SetAppointmentTypeProvidersPayload,
} from '../hooks/useAppointmentTypeDetailOperations';

//helpers
import { Roles } from '../../../../helpers';
import { colorValues, alphabeticalSort } from '@betterpt/better-components';

//hooks
import { useHistory } from 'react-router-dom';
import { useMeQuery } from '../../../../generated/graphql';
import useDebounce from '../../../../hooks/useDebounce';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';

//components
import { Button, Card, InfoBox, Table } from '@betterpt/better-components';
import Can from '../../../Can';
import ThemedTextField from '../../../Shared/ThemedTextField';
import ProviderAction from './ProviderAction';
import SetAppointmentTypeProvidersDialog from './SetAppointmentTypeProvidersDialog';

//assets
import { IcSuccessCircle, IcUnavailable } from '@betterpt/better-icons';

//styles
import {
  DetailSubheader,
  DetailTableHeaderSection,
  NoProvidersOrFacilities,
  Row,
  TableStyleWrapper,
  detailTableCardStyle,
  killMargin,
} from '../../../Shared/AppointmentTypes/AppointmentTypes.style';
const buttonStyle = (primaryColor: string) => ({
  marginLeft: 25,
  backgroundColor: primaryColor,
});
const integrationColumnHeaderStyle = { position: 'relative' as 'relative' };
const integrationColumnTextStyle = { margin: 0 };
const integrationInfoBoxStyle = { left: '100px', top: '-1px' };
const integrationInfoBoxContentStyle = { padding: 20 };
const integrationRowStyle = { display: 'flex', alignItems: 'center' };
const integrationRowTextStyle = { marginLeft: 6 };

interface Props {
  mappedAppointmentType: MappedAppointmentTypeDetail;
  setAppointmentTypeProviders: (
    payload: SetAppointmentTypeProvidersPayload
  ) => Promise<boolean>;
}

const AppointmentTypeProviders = ({
  mappedAppointmentType,
  setAppointmentTypeProviders,
}: Props) => {
  const history = useHistory();
  const meQuery = useMeQuery({
    fetchPolicy: 'cache-first',
  });
  const { employeePluralAlias, primaryColor } = useRemoteConfigAliases();
  const { loading, providers } = mappedAppointmentType;

  const [
    isSetProvidersDialogOpen,
    updateIsSetProvidersDialogOpen,
  ] = React.useState(false);
  const [searchText, updateSearchText] = React.useState('');
  const [selectedProviderId, setSelectedProviderId] = React.useState<
    string | null
  >(null);
  const debouncedSearchText = useDebounce(searchText, 500);
  const existingProviderIds = React.useMemo(
    () => providers.map((staff) => staff.id),
    [providers]
  );

  const IntegrationColumnHeader = (
    <div style={integrationColumnHeaderStyle}>
      <p style={integrationColumnTextStyle}>IS INTEGRATED</p>
      <InfoBox
        openWith='hover'
        height={100}
        width={170}
        padding={0}
        mainStyle={integrationInfoBoxStyle}
        boxStyle={integrationInfoBoxContentStyle}
        fixedPlacement
      >
        <h3 className='H6'>INTEGRATED {employeePluralAlias.toUpperCase()}</h3>
        <p className='Body'>
          This status is only relevant if your company uses EMR integration.
        </p>
      </InfoBox>
    </div>
  );

  const columns = [
    {
      field: 'name',
      title: 'NAME',
    },
    {
      field: 'id',
      title: 'ID',
    },
    {
      field: 'role',
      title: 'ROLE',
    },
    {
      field: 'isIntegrated',
      title: IntegrationColumnHeader,
      width: 115,
    },
    {
      field: 'action',
      title: 'ACTION',
      width: 80,
    },
  ];

  const filteredProviders: MappedAppointmentTypeProvider[] = alphabeticalSort(
    providers,
    'name'
  ).filter((provider) =>
    (
      provider.name.toLowerCase() + provider.roleToDisplay.toLowerCase()
    ).includes(debouncedSearchText.toLowerCase())
  );

  const canUserRemoveProviders = () =>
    meQuery.data?.me?.role
      ? (meQuery.data?.me?.role as Role) !== Role.Self
      : false;

  const data = filteredProviders.map((provider, i) => ({
    id: provider?.id,
    name: provider?.name,
    role: provider?.roleToDisplay,
    isIntegrated: (
      <div style={integrationRowStyle}>
        {provider?.isIntegrated ? (
          <IcSuccessCircle color={colorValues.guajirogreen} />
        ) : (
          <IcUnavailable />
        )}
        <p style={integrationRowTextStyle} className='Body'>
          {provider?.isIntegrated ? 'Yes' : 'No'}
        </p>
      </div>
    ),
    action: (
      <ProviderAction
        employeeId={provider?.id}
        canUserRemoveProviders={canUserRemoveProviders()}
        firstInList={!i}
        openStatus={{
          value: selectedProviderId === provider?.id,
          update: (isOpen) => {
            setSelectedProviderId(isOpen ? provider?.id ?? null : null);
          },
        }}
      />
    ),
  }));

  const tableOptions = {
    minBodyHeight: 500,
    maxBodyHeight: 500,
    overflow: 'auto' as 'auto',
    searchNotFoundText: 'No results',
  };

  return (
    <>
      <SetAppointmentTypeProvidersDialog
        open={isSetProvidersDialogOpen}
        onClose={() => updateIsSetProvidersDialogOpen(false)}
        setAppointmentTypeProviders={setAppointmentTypeProviders}
        existingProviderIds={existingProviderIds}
      />
      <DetailTableHeaderSection>
        <DetailSubheader style={killMargin}>
          {employeePluralAlias} That Use this Appointment Type
        </DetailSubheader>
        <Row>
          {(providers.length || loading) && (
            <ThemedTextField
              label={`Search ${employeePluralAlias.toLowerCase()}`}
              placeholder='Enter name or role'
              value={searchText}
              onChange={(e) => {
                updateSearchText(e.target.value);
              }}
              style={killMargin}
            />
          )}
          <Can
            role={Roles[meQuery.data?.me?.role ?? 'initial']}
            perform='appointmentType:edit'
            yes={() => (
              <Button
                style={buttonStyle(primaryColor)}
                onClick={() => updateIsSetProvidersDialogOpen(true)}
              >
                ASSIGN {employeePluralAlias.toUpperCase()} TO APPT. TYPE
              </Button>
            )}
          />
        </Row>
      </DetailTableHeaderSection>
      <Card fullWidth style={detailTableCardStyle}>
        {providers.length || loading ? (
          <TableStyleWrapper>
            <Table
              onRowClick={(_e, provider) =>
                history.push(`/employees/${provider?.id}`)
              }
              columns={columns}
              data={data}
              isLoading={loading}
              isSearching={!!searchText}
              options={tableOptions}
            />
          </TableStyleWrapper>
        ) : (
          <NoProvidersOrFacilities>
            No {employeePluralAlias.toLowerCase()} use this appointment type or
            you do not have permission to view them.
          </NoProvidersOrFacilities>
        )}
      </Card>
    </>
  );
};

export default AppointmentTypeProviders;
