import * as React from 'react';
import { datadogRum } from '@datadog/browser-rum';

// types
import { ConfirmOrDenyStatus } from '../../../../../../generated/graphql';

//hooks
import useSnackbar from '../../../../../../hooks/useSnackbar';
import {
  useUpdateInPersonAppointmentConfirmationStatusMutation,
  useInPersonAppointmentDetailsQuery,
} from '../../../../../../generated/graphql';

//components
import Dialog from '@material-ui/core/Dialog';
import { Animation } from '@betterpt/better-components';
import DenyRequestPage from '../../../Shared/DenyRequestPage';
import SlideTransition from '../../../../../Shared/SlideTransition';
import useHandleError from '../../../../../../hooks/useHandleError';

type Props = {
  open: boolean;
  onClose: () => void;
  appointmentId?: string;
  onDoneChangingOptionsAppointment: () => Promise<any> | undefined;
};

const DeclineAppointmentDialog = ({
  open,
  onClose,
  appointmentId,
  onDoneChangingOptionsAppointment,
}: Props) => {
  const snackbar = useSnackbar();

  const [saving, updateSaving] = React.useState(false);
  const [denialReason, updateDenialReason] = React.useState<string | undefined>(
    undefined
  );
  const [denialExplanation, updateDenialExplanation] = React.useState('');

  const [declineAppointment] =
    useUpdateInPersonAppointmentConfirmationStatusMutation();
  const handleError = useHandleError();

  const appointmentQuery = useInPersonAppointmentDetailsQuery({
    variables: { id: appointmentId! },
    skip: !appointmentId,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const handleDeny = async () => {
    if (!appointmentId) return;
    updateSaving(true);
    try {
      await declineAppointment({
        variables: {
          input: {
            appointmentId: appointmentId!,
            confirmationStatus: ConfirmOrDenyStatus.Denied,
            denialReason,
            denialExplanation,
          },
        },
      });
      snackbar?.setSuccessMessage('Appointment request declined');
      datadogRum.addUserAction('deny_in_person_appointment', {
        clinicId: appointmentQuery.data?.appointment?.clinicId,
        employeeId: appointmentQuery.data?.appointment?.employee?.id,
        patientId: appointmentQuery.data?.appointment?.patient?.id,
      });

      setTimeout(async () => {
        await onDoneChangingOptionsAppointment();
        updateSaving(false);
        onClose();
      }, 1000);
    } catch (e) {
      updateSaving(false);
      handleError(e);
      return;
    }
  };

  const renderContent = () => {
    if (appointmentQuery.loading) {
      return <Animation type='providerAppLoader' />;
    } else {
      return (
        <DenyRequestPage
          denyAppointment={handleDeny}
          closeDialog={() => onClose()}
          saving={saving}
          denialReason={{
            value: denialReason,
            update: updateDenialReason,
          }}
          denialExplanation={{
            value: denialExplanation,
            update: updateDenialExplanation,
          }}
        />
      );
    }
  };

  return (
    <Dialog
      TransitionComponent={SlideTransition}
      open={open}
      onClose={() => onClose()}
      maxWidth='sm'
      fullWidth
    >
      {renderContent()}
    </Dialog>
  );
};

export default DeclineAppointmentDialog;
