import React from 'react';
import styled from '@emotion/styled';
import { Card, Header } from '@betterpt/better-components';
import YextLogo from '../../../../assets/logos-partners-square-logo-yext@3x.png';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';

const YextLogoImage = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 16px;
`;

const headerStyle = {
  justifyContent: 'flex-start',
  paddingTop: '25px',
  paddingBottom: '25px',
};

const cardStyle = {
  maxWidth: '1182px',
  margin: '20px 0 9px',
};

const CardText = styled.p`
  margin: 20px 37px 20px 60px;
`;

const YextMessage = () => {
  const { employeeSingularAlias } = useRemoteConfigAliases();
  const lowerEmployeeSingularAlias = employeeSingularAlias.toLowerCase();
  return (
    <Card style={cardStyle}>
      <Header underline style={headerStyle}>
        <YextLogoImage src={YextLogo} alt='Yext logo' />
        <h3 className='H3'>This account is managed on Yext</h3>
      </Header>
      <CardText className='Body'>
        This {lowerEmployeeSingularAlias}’s account is managed through your Yext
        – BetterAccess integration. You can add appointments, appointment types,
        alternate telehealth email contacts and facilities for this{' '}
        {lowerEmployeeSingularAlias} here, but if you need to make any other
        changes to this {lowerEmployeeSingularAlias}’s account, please do so
        through Yext.
      </CardText>
    </Card>
  );
};

export default YextMessage;
