import React from 'react';
import { colorValues } from '@betterpt/better-components';
import { Clinic } from '../../../../generated/graphql';

const tableBubbleStyle = (textLength?: number) => ({
  maxWidth: 152,
  height: 28,
  borderRadius: 5,
  fontSize: (textLength ?? 0) > 12 ? 10 : 12,
  fontWeight: 700,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const IntegrationColumn = ({ clinic }: { clinic?: Clinic | null }) => {
  const integrated = clinic?.isIntegrated;

  const generateIntegrationData = () => {
    const data = {
      text: 'NON-INTEGRATED',
      backgroundColor: colorValues.dust,
      color: colorValues.betterptblack,
    };

    if (integrated) {
      data.text = 'INTEGRATED';
      data.backgroundColor = colorValues.fadedgreen;
      data.color = colorValues.guajirogreen;
    }

    return data;
  };
  const subData = generateIntegrationData();

  const text = subData.text;
  const backgroundColor = subData.backgroundColor;
  const color = subData.color;

  const bestStyles = {
    ...tableBubbleStyle(),
    backgroundColor,
    color,
    minWidth: 104,
    display: 'flex',
  };

  return <p style={bestStyles}>{text}</p>;
};

export default IntegrationColumn;
