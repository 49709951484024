import { Clinic, Maybe, Role } from '../../../../../../../generated/graphql';

const useTextFormaters = () => {
  const formatClinicTableCopy = (clinics: Maybe<Clinic>[] | null = []) => {
    if (!clinics?.length) return '';
    if (clinics?.length === 1) return clinics?.[0]?.displayName;
    const otherOrOthers = clinics?.length > 2 ? 'others' : 'other';
    return `${clinics?.[0]?.displayName} and ${clinics?.length -
      1} ${otherOrOthers}`;
  };

  const formatRole = (role?: Maybe<Role>) => {
    if (!role) return '';
    return `${role?.charAt(0).toUpperCase() + role?.slice(1).toLowerCase()}`;
  };

  return { formatClinicTableCopy, formatRole };
};

export default useTextFormaters;
