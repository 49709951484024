import React from 'react';
//Hooks
import useSnackbar from '../../../../hooks/useSnackbar';
import useTextFieldState from '../../../../hooks/useTextFieldState';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import useHandleError from '../../../../hooks/useHandleError';

// queries and mutations
import { useMeQuery } from '../../../../generated/graphql';

// Components
import { Header, Button, colorValues } from '@betterpt/better-components';
import { GridCard } from '../../../Shared/GridCard';
import Can, { checkPermissions } from '../../../Can';
import FacilitySelector from '../../Employees/FacilitySelector';
import FormField from '../../../Shared/FormField';

// helper
import { RoleToString, Roles } from '../../../../helpers/rbac-rules';

//style
import { logoutButtonStyle, H4, FormBody } from '../styles/Profile.style';

const RolesAndPermissions = () => {
  //hooks
  const meQuery = useMeQuery();
  const snackbar = useSnackbar();
  const { primaryColor } = useRemoteConfigAliases();
  const handleError = useHandleError();

  const [isEditModeActive, setEditModeActive] = React.useState(false);
  const permissions = useTextFieldState();
  const canAssociateSelf = checkPermissions(
    Roles[meQuery.data?.me?.role || 'initial'],
    'facility:associateToSelf'
  );

  React.useEffect(() => {
    const me = meQuery.data?.me;
    if (meQuery.data) {
      permissions.updateValue(RoleToString(Roles[me?.role ?? 'initial']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meQuery.data]);

  //functions
  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const employeeId = meQuery?.data?.me?.id;
    if (!employeeId) return;
    try {
      setEditModeActive(false);
      snackbar?.setSuccessMessage('Information updated successfully');
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <GridCard>
      <form onSubmit={handleSubmit}>
        <Header underline style={{ padding: '22px 23px 13px 42px' }}>
          <H4>
            Roles and Permissions
            <a
              style={{
                fontSize: '12px',
                color: colorValues.frenchblue,
                fontWeight: 'bold',
                marginLeft: '20px',
              }}
              className='button-or-link'
              href='https://betterpt-external-assets.s3.amazonaws.com/Roles+and+Permissions+PDF.pdf'
              target='_blank'
              rel='noreferrer'
            >
              LEARN MORE
            </a>
          </H4>
          {canAssociateSelf && (
            <>
              {isEditModeActive ? (
                <Button
                  size='small'
                  color='transparent'
                  type='submit'
                  style={logoutButtonStyle(primaryColor, true)}
                >
                  SAVE
                </Button>
              ) : (
                <Button
                  size='small'
                  color='transparent'
                  style={logoutButtonStyle(primaryColor)}
                  onClick={() => setEditModeActive(true)}
                >
                  EDIT
                </Button>
              )}
            </>
          )}
        </Header>
        <FormBody>
          <FormField
            disabled
            label={`Permissions ${
              isEditModeActive
                ? '(Note: you cannot edit your own permissions)'
                : ''
            }`}
            formHandler={permissions}
            fullWidth
          />
          <Can
            role={Roles[meQuery.data?.me?.role || 'initial']}
            perform='facility:associateToSelf'
            yes={() => <FacilitySelector disabled={!isEditModeActive} />}
          />
        </FormBody>
      </form>
    </GridCard>
  );
};

export default RolesAndPermissions;
