import React from 'react';

//helpers
import { bestTextColor, colorValues } from '@betterpt/better-components';

//hooks
import { useHistory } from 'react-router-dom';
import useRemoteConfigAliases from '../../../hooks/useRemoteConfigAliases';

//components
import { Link } from 'react-router-dom';
import { Button, CloseButton } from '@betterpt/better-components';
import Dialog from '@material-ui/core/Dialog';
import SlideTransition from '../../Shared/SlideTransition';

import { DialogTitle, DialogBody } from './AddAppointmentButtonWithDialog.style';

type Props = {
  color?: colorValues | string;
  size?: 'large' | 'medium' | 'small';
  onInPersonClick?: () => void;
  showInPersonOption: boolean;
};

const AddAppointmentButtonWithDialog = ({ color, size, onInPersonClick, showInPersonOption }: Props) => {
  const history = useHistory();
  const { secondaryColor, primaryColor, facilitiesPluralAlias } = useRemoteConfigAliases();

  const [isDialogOpen, updateDialogOpen] = React.useState(false);

  const handleClick = () => {
    if (showInPersonOption) {
      onInPersonClick ? onInPersonClick() : history.push('/appointments/new');
    } else {
      updateDialogOpen(true);
    }
  };

  const handleInPersonClick = () => {
    updateDialogOpen(false);
    onInPersonClick && onInPersonClick();
  };

  const handleButtonWhenNoneActive = () => {
    updateDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog open={isDialogOpen} onClose={() => updateDialogOpen(false)} TransitionComponent={SlideTransition}>
        <DialogTitle style={!showInPersonOption ? { fontWeight: 'bold', marginBottom: 0 } : {}}>
          {!showInPersonOption
            ? `You have no ${facilitiesPluralAlias.toLowerCase()} set up for appointments`
            : 'What type of appointment would you like to make?'}
        </DialogTitle>

        {!showInPersonOption ? (
          <DialogBody style={{ paddingTop: 20 }}>
            <p className="Paragraph" style={{ marginBottom: 40 }}>
              Contact support@betterhealthcare.co to start adding more facilities. You will then be able to add staff and appointments, and
              gain access to analytics and more!
            </p>
            <Button
              style={{
                height: 48,
                backgroundColor: primaryColor,
                color: bestTextColor(primaryColor),
              }}
              fullWidth
              onClick={handleButtonWhenNoneActive}
            >
              OK
            </Button>
          </DialogBody>
        ) : (
          <DialogBody>
            {onInPersonClick ? (
              <Button
                style={{
                  height: 48,
                  backgroundColor: secondaryColor,
                  color: bestTextColor(secondaryColor),
                }}
                fullWidth
                onClick={handleInPersonClick}
              >
                IN-PERSON
              </Button>
            ) : (
              <Link to="/appointments/new">
                <Button
                  style={{
                    height: 48,
                    backgroundColor: secondaryColor,
                    color: bestTextColor(secondaryColor),
                  }}
                  fullWidth
                >
                  IN-PERSON
                </Button>
              </Link>
            )}
          </DialogBody>
        )}
        <CloseButton onClose={() => updateDialogOpen(false)} />
      </Dialog>
      <Button
        key={1}
        onClick={handleClick}
        size={size}
        style={{
          backgroundColor: color || secondaryColor,
          color: bestTextColor(color || secondaryColor),
          marginRight: 15,
          marginLeft: 15,
          minWidth: 174,
        }}
      >
        ADD APPOINTMENT
      </Button>
    </React.Fragment>
  );
};

export default AddAppointmentButtonWithDialog;
