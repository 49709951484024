import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='267'
    height='267'
    viewBox='0 0 267 267'
    {...rest}
  >
    <g fill='none' fillRule='evenodd'>
      <g transform='translate(1.669 40.05)'>
        <path
          fill='#F0BCA3'
          d='M103.463 201.919L111.806 201.919 111.806 183.563 103.463 183.563zM125.156 201.919L133.5 201.919 133.5 183.563 125.156 183.563z'
        />
        <path
          fill='#2A4E82'
          d='M102.823 188.569L113.892 188.569 112.946 138.506 102.823 138.506zM123.487 188.569L134.543 188.569 133.873 138.506 123.75 138.506z'
        />
        <path
          fill='#FF54A0'
          d='M120.442 52.595c-12.117-13.801-10.944-34.973 2.62-47.302 6.778-6.16 17.181-5.563 23.242 1.338 6.051 6.895 5.471 17.488-1.306 23.646-6.783 6.165-7.369 16.75-1.315 23.645 5.992 6.853 16.346 7.497 23.125 1.441.04-.035.08-.07.12-.108-13.565 12.33-34.373 11.136-46.485-2.66'
        />
        <path
          fill='#B1B1B1'
          d='M142.025 155.445H93.778v-53.766c0-13.791 7.807-25.404 18.43-28.864.72-.243 1.452-.441 2.2-.598 1.43-.303 2.9-.46 4.402-.46 1.892 0 3.73.25 5.501.723.748.203 1.48.44 2.2.723 3.813 1.466 7.23 4.005 9.997 7.32 4.533 5.412 7.333 12.896 7.333 21.156l-1.816 53.766z'
        />
        <path
          fill='#F0BCA3'
          d='M95.401 52.297c.375 7.711 3.507 14.522 8.28 19.298 4.767 4.784 11.167 7.527 18.07 7.108 13.806-.836 24.389-14.011 23.64-29.433-.014-.304-.037-.599-.058-.903-.245-3.213-.975-6.249-2.09-9.04-.152-.377-.31-.747-.474-1.107-1.414-3.142-3.341-5.933-5.658-8.248-4.767-4.784-11.167-7.527-18.07-7.108-5.682.344-10.814 2.772-14.83 6.597-.05.036-.093.08-.142.14-.817.775-1.587 1.62-2.304 2.512-2.079 2.58-3.725 5.587-4.826 8.882-.83 2.497-1.358 5.147-1.518 7.907-.05.82-.067 1.652-.048 2.49.006.306.014.601.028.905'
        />
        <circle
          cx='143.512'
          cy='55.069'
          r='6.675'
          fill='#F0BCA3'
          fillRule='nonzero'
        />
        <circle
          cx='98.456'
          cy='55.069'
          r='6.675'
          fill='#F0BCA3'
          fillRule='nonzero'
        />
        <path
          fill='#FF54A0'
          d='M149.476 55.63c-14.962-9.346-23.435-19.572-20.948-27.178.359-1.087.923-2.092 1.66-2.956 1.11-1.337 2.637-2.47 4.54-3.404.26-.147.532-.275.822-.398 1.641 1.645 3.187 3.392 4.624 5.227 4.064 5.172 6.945 10.664 8.427 16.199 1.112 4.19 1.45 8.396.875 12.51M93.928 54.153c7.166-5.442 10.94-11.86 9.214-17.147-.244-.747-.589-1.453-1.028-2.1-.65-.966-1.503-1.823-2.538-2.566-.143-.115-.292-.221-.447-.319-.764 1.039-1.46 2.13-2.08 3.266-1.79 3.226-2.944 6.78-3.4 10.46-.339 2.799-.245 5.636.279 8.406'
        />
        <path
          fill='#FF54A0'
          d='M130.784 43.753c.356-.7.612-1.444.758-2.21.224-1.144.227-2.354.02-3.61-.02-.183-.05-.364-.09-.542-1.275.194-2.539.474-3.78.839-3.547 1.015-6.876 2.712-9.8 4.991-2.22 1.74-4.159 3.812-5.748 6.142 8.915 1.219 16.121-.65 18.64-5.61z'
        />
        <path
          fill='#FF54A0'
          d='M139.518 39.836c.095-.78.08-1.565-.044-2.336-.181-1.151-.592-2.289-1.216-3.399-.081-.165-.171-.324-.27-.478-1.132.618-2.224 1.314-3.264 2.08-2.987 2.168-5.534 4.9-7.503 8.043-1.49 2.394-2.604 5.005-3.3 7.737 8.794-1.904 14.926-6.125 15.597-11.647z'
        />
        <path
          fill='#FF54A0'
          d='M142.09 17.915c.023.259.043.52.052.774.049 1.582-.186 3.158-.692 4.655l.004.011c-3.075 9.58-15.998 19.955-33.272 25.665-6.763 2.235-13.406 3.5-19.475 3.84.422-5.365 1.98-10.573 4.57-15.27 4.768-8.753 12.974-15.892 23.47-19.362 8.642-2.856 17.482-2.784 25.343-.313'
        />
        <path
          fill='#FFF'
          d='M145.058 34.047c-.202 0-.392-.09-.506-.242-6.393-8.615-17.12-13.096-31.884-13.317-.332-.001-.6-.25-.599-.554v-.003c.007-.303.295-.526.618-.537 15.188.228 26.248 4.872 32.876 13.803.182.249.112.585-.158.752l-.009.006c-.101.061-.218.092-.338.092'
        />
        <path
          fill='#FFF'
          d='M145.058 30.71c-.202 0-.392-.09-.506-.243-6.393-8.615-17.12-13.094-31.884-13.317-.332-.001-.6-.25-.599-.554v-.003c.007-.301.301-.534.618-.537 15.188.23 26.248 4.873 32.876 13.804.182.247.112.584-.158.751l-.009.006c-.101.06-.218.093-.338.093'
        />
        <path
          fill='#2F9247'
          d='M147.122 35.559c-.2.014-.399-.096-.527-.296-7.2-11.295-18.327-16.655-33.076-15.93-.331.022-.623-.298-.65-.713v-.004c-.021-.412.227-.756.555-.775h.01c15.172-.744 26.649 4.814 34.111 16.524.206.324.168.788-.084 1.037-.003.002-.005.006-.01.008-.095.09-.209.14-.329.149'
        />
        <path
          fill='#4A79B5'
          d='M91.781 205.226h22.111v-11.651h-10.39c-6.473 0-11.72 5.217-11.72 11.651M145.598 205.226h-22.11v-11.651h10.389c6.473 0 11.721 5.217 11.721 11.651'
        />
        <rect
          width='3.337'
          height='5.006'
          x='125.156'
          y='60.075'
          fill='#2F9247'
          rx='1.5'
        />
        <rect
          width='3.337'
          height='5.006'
          x='108.469'
          y='60.075'
          fill='#2F9247'
          rx='1.5'
        />
        <path
          fill='#FFF'
          d='M119.574 85.741h.037c.904-.008 1.63-.742 1.622-1.64-.008-.898-.747-1.62-1.65-1.612h-.038c-.904.008-1.63.742-1.622 1.641.009.897.747 1.619 1.65 1.611M119.574 97.569h.037c.904-.009 1.63-.743 1.622-1.64-.008-.898-.747-1.62-1.65-1.612h-.038c-.904.008-1.63.743-1.622 1.64.009.898.747 1.62 1.65 1.612M119.574 109.397l.037-.001c.904-.007 1.63-.741 1.622-1.64-.008-.897-.747-1.62-1.65-1.61h-.038c-.904.007-1.63.74-1.622 1.64.009.897.747 1.618 1.65 1.611M119.574 121.224h.037c.904-.008 1.63-.741 1.622-1.64-.008-.898-.747-1.62-1.65-1.612l-.038.001c-.904.007-1.63.742-1.622 1.64.009.897.747 1.62 1.65 1.611M120.132 133.414h.037c.904-.008 1.63-.742 1.622-1.64-.007-.898-.747-1.62-1.65-1.611h-.038c-.903.007-1.63.742-1.622 1.64.009.897.747 1.62 1.65 1.611M120.132 145.096h.037c.904-.009 1.63-.743 1.622-1.641-.007-.897-.747-1.62-1.65-1.611h-.038c-.903.007-1.63.742-1.622 1.64.009.898.747 1.62 1.65 1.612'
        />
        <path
          fill='#333'
          d='M117.691 109.71l1.058 16.688c.107 1.63-.957 3.025-2.376 3.086l-10.979.545-1.64.086c-1.42.06-2.646-1.216-2.755-2.86l-.72-11.436-.336-5.241c-.098-1.645.966-3.037 2.374-3.1l12.621-.617c1.418-.073 2.645 1.204 2.753 2.848'
        />
        <path
          fill='#F0BCA3'
          d='M97.63 128.198c-2.644-4.176-19.972-6.002-22.498-14.597-1.902-6.475 5.77-23.188 18.124-33.217 2.735-2.221 7.546-5.267 9.78-2.55 2.233 2.72 1.827 6.723-.908 8.943-8.74 7.096-18.087 23.217-17.06 24.738 2.271 3.368 11.366 9.51 17.06 8.964 5.69-.546 6.031 2.833 5.578 6.315-.416 3.204-3.841 6.775-7.015 4.717-1.282-1.009-2.302-2.113-3.062-3.313z'
        />
        <path
          fill='#4A79B5'
          d='M207.634 26.37l-2.21-5.123 5.133-2.206 2.2 5.12-5.123 2.208zm1.076-10.515c.557.222.83.859.608 1.415-.222.555-.857.83-1.413.607-.558-.222-.83-.858-.609-1.415.223-.555.859-.829 1.414-.607zm-3.634 3.237c-.556-.221-.83-.858-.607-1.414.222-.556.857-.83 1.414-.607.555.222.829.857.607 1.414-.222.556-.858.829-1.414.607zm-1.216-2.828c-.556-.222-.829-.858-.607-1.413.222-.556.859-.83 1.414-.609.556.222.83.859.608 1.415-.222.555-.858.828-1.415.607zm-1.916-3.155l-2.21-5.123 5.133-2.205 2.2 5.12-5.123 2.208zm5.551-.081c.557.22.83.857.609 1.413-.222.557-.859.83-1.415.608-.556-.221-.83-.858-.607-1.414.22-.556.857-.83 1.413-.607zm4.575 5.363l5.628-2.418c.552-.238.806-.874.57-1.426l-2.639-6.137c-.237-.552-.873-.805-1.425-.568l-5.628 2.417-2.418-5.628c-.126-.273-.339-.474-.6-.579-.254-.1-.557-.105-.826.01L198.595 6.7c-.552.238-.805.873-.568 1.425l2.418 5.629-5.627 2.418c-.552.237-.807.874-.57 1.424l2.637 6.139c.238.55.874.805 1.426.568l5.628-2.419 2.418 5.628c.12.283.339.475.6.58.263.105.557.105.836-.006l6.137-2.638c.551-.237.805-.873.569-1.425l-2.43-5.632z'
        />
        <path
          fill='#FF6454'
          d='M248.641 63.084L240.3 62.418 244.834 69.3 241.611 76.857 249.7 74.765 256.048 80.1 256.515 71.925 263.663 67.666 255.86 64.705 253.929 56.737z'
        />
        <path
          fill='#69DF86'
          d='M202.465 106.091l-14.879-7.184 5.59-11.806 14.877 7.183-5.588 11.807zm8.128-17.173l-1.062-.514 1.016-2.146-2.126-1.026-1.016 2.147-8.5-4.106 1.014-2.147-2.124-1.026-1.017 2.147-1.062-.513c-1.18-.57-2.573-.055-3.131 1.126l-7.123 15.021c-.558 1.18-.07 2.602 1.109 3.172l14.878 7.184c1.169.565 2.582.06 3.14-1.12l7.114-15.026c.557-1.181.06-2.609-1.11-3.173zm-5.681 6.487l-2.126-1.027-1.015 2.147 2.124 1.026 1.017-2.146zm-4.252-2.053l-2.125-1.027-1.015 2.147 2.124 1.027 1.016-2.147zm-4.25-2.052l-2.125-1.026-1.016 2.146 2.124 1.026 1.017-2.146z'
        />
        <path
          fill='#FFB1A9'
          d='M17.687 120.271l-1.646-3.149-3.192 1.624-1.647-3.147-3.192 1.623 1.647 3.15-3.192 1.622 1.647 3.148 3.191-1.623 1.648 3.148 3.191-1.624-1.646-3.15 3.191-1.622zm-11.044-9.528l10.594-.605 2.964 5.666c2.742 5.242 1.627 12.014-2.691 15.718-5.564 1.323-11.805-1.708-14.547-6.95L0 118.908l6.643-8.164z'
        />
        <path
          fill='#2A4E82'
          d='M8.924 52.829l-.668-1.079-1.077.669-.667-1.078-1.077.669.667 1.078-1.076.669.668 1.077 1.076-.668.668 1.078 1.076-.668-.667-1.079 1.077-.668zm-.517-5.65c2.874-1.785 9.508-3.904 11.284-1.036l2.003 3.235L4.467 60.075l-2.003-3.234c-1.776-2.868 3.068-7.877 5.943-9.662zm-6.008-9.703C.019 38.953-.714 42.079.762 44.462 2.237 46.845 5.36 47.578 7.74 46.1c2.38-1.477 3.112-4.604 1.637-6.987-1.476-2.383-4.598-3.116-6.977-1.638z'
        />
        <path
          fill='#FFCC46'
          d='M54.06 48.786c1.92-.92 4.248-.158 5.198 1.703.95 1.86.162 4.113-1.759 5.033-1.921.92-4.249.157-5.198-1.703-.95-1.86-.163-4.114 1.758-5.033m-2.292-4.491c-4.477 2.142-6.318 7.41-4.105 11.744 3.01 5.895 15.57 10.711 15.57 10.711s3.674-12.587.665-18.481c-2.213-4.334-7.654-6.117-12.13-3.974'
        />
      </g>
      <path
        fill='#F0BCA3'
        d='M121.256 169.264c-3.513-.365-6.06-3.494-5.69-6.984.368-3.49 3.51-6.025 7.026-5.655 11.226 1.172 23.628-5.322 24.675-7.178 1.664-2.947-5.23-17.454-8.711-21.98-2.145-2.789-2.805-7.822 0-9.954 2.806-2.132 6.15 1.026 8.295 3.813 2.245 2.92 14.747 21.459 9.77 31.17-3.284 6.404-17.517 13.011-26.637 13.011-2.483 0-5.851 4.058-8.728 3.757z'
      />
      <g>
        <path d='M0 0H8.344V8.344H0z' transform='rotate(-27 340.06 -231.302)' />
        <path
          fill='#2A4E82'
          fillRule='nonzero'
          d='M2.079 2.692c.24-.24.632-.24.874 0L4.17 3.9l1.221-1.208c.214-.213.548-.236.789-.07l.085.07c.242.238.242.627 0 .867l-1.312 1.3L6.265 6.16c.242.238.242.627 0 .866-.24.24-.632.24-.874 0l-1.22-1.209-1.22 1.21c-.213.212-.547.236-.788.07l-.086-.07c-.241-.239-.241-.628 0-.867l1.313-1.3-1.311-1.301c-.242-.239-.242-.628 0-.867zm1.533-1.52c.303-.298.795-.298 1.099 0l.087.085c.224.3.2.722-.075.993l-.557.547-.554-.545c-.303-.299-.303-.782 0-1.08z'
          transform='rotate(-27 340.06 -231.302)'
        />
      </g>
    </g>
  </svg>
);
