import * as React from 'react';

//types
import { Role } from '../../../generated/graphql';

//helpers
import { firebaseAnalytics } from '../../../config/firebase';

//hooks
import useRemoteConfigAliases from '../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../hooks/useSnackbar';
import { useCompanyBillingOverviewQuery, useMeQuery } from '../../../generated/graphql';

//components
import { Button } from '@betterpt/better-components';
import { Link } from 'react-router-dom';

//styles
import { bestTextColor } from '@betterpt/better-components';
import {
  WelcomeContainer,
  BodySection,
  WhiteBox,
  Circle,
  GrayLine,
  numberCircleStyle,
  StepsContainer,
  Step,
  VerticalLine,
  FooterSection,
} from './Welcome.style';
import useHandleError from '../../../hooks/useHandleError';
const linkStyle = { textDecoration: 'none' };
const numberTextStyle = (primaryColor: string) => ({
  color: bestTextColor(primaryColor),
});
const buttonStyle = (primaryColor: string) => ({
  backgroundColor: primaryColor,
  color: bestTextColor(primaryColor),
  maxWidth: '524px',
  height: '63px',
});

const Welcome = () => {
  const snackbar = useSnackbar();
  const handleError = useHandleError();
  const { primaryColor, facilitiesPluralAlias, employeePluralAlias } = useRemoteConfigAliases();

  const meQuery = useMeQuery();
  const billingQuery = useCompanyBillingOverviewQuery({
    variables: { id: meQuery.data?.me?.company?.id ?? '' },
    skip: meQuery.data?.me?.role !== Role.Owner || !meQuery.data?.me?.company?.id,
  });

  const queryError = meQuery.error || billingQuery.error;

  React.useEffect(() => {
    if (queryError?.message) {
      snackbar?.useGenericErrorMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryError]);

  React.useEffect(() => {
    if (!meQuery.loading && !billingQuery.loading) {
      try {
        if (meQuery.data?.me?.role === Role.Owner) {
          firebaseAnalytics.logEvent('tutorial_begin', {
            partner: billingQuery.data?.company?.billingOverview?.billingPartnership,
          });
        }
      } catch (e) {
        handleError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meQuery.data, billingQuery.data, handleError]);

  const NumberText: React.FC<React.PropsWithChildren<any>> = (props) => (
    <h2 style={numberTextStyle(primaryColor)} className="H5" {...props}>
      {props.children}
    </h2>
  );

  return (
    <WelcomeContainer>
      <h1 className="Hero">Welcome!</h1>
      <BodySection>
        <WhiteBox>
          <GrayLine />
          <StepsContainer>
            <VerticalLine />
            <Step>
              <Circle color={primaryColor} style={numberCircleStyle}>
                <NumberText>1</NumberText>
              </Circle>
              <h2 className="H2">Sign Up</h2>
            </Step>
            <Step>
              <Circle color={primaryColor} style={numberCircleStyle}>
                <NumberText>2</NumberText>
              </Circle>
              <h2 className="H2">
                Add {facilitiesPluralAlias} & {employeePluralAlias}
              </h2>
            </Step>
            <Step>
              <Circle color={primaryColor} style={numberCircleStyle}>
                <NumberText>3</NumberText>
              </Circle>
              <h2 className="H2">Add/Confirm Appointments</h2>
            </Step>
          </StepsContainer>
        </WhiteBox>
      </BodySection>
      <FooterSection>
        <Link to="/onboarding/company" style={linkStyle}>
          <Button style={buttonStyle(primaryColor)} size="large" fullWidth>
            {"LET'S GET STARTED"}
          </Button>
        </Link>
      </FooterSection>
    </WelcomeContainer>
  );
};

export default Welcome;
