import * as React from 'react';

//hooks
import { useMeQuery } from '../../../../generated/graphql';
import { UpdateCompanyAvatarPayload } from '../hooks/useCompanyInfoOperations';
import useHandleError from '../../../../hooks/useHandleError';
import useSnackbar from '../../../../hooks/useSnackbar';

//components
import { ImageUploader } from '@betterpt/better-components';

//assets
import PlaceholderImage from '../../../../assets/img-unknown-cliniclogo.svg';

interface Props {
  uploadCompanyAvatar: (payload: UpdateCompanyAvatarPayload) => Promise<void>;
}

const ImageForm = ({ uploadCompanyAvatar }: Props) => {
  const snackbar = useSnackbar();
  const handleError = useHandleError();
  const meQuery = useMeQuery();
  const companyId = meQuery.data?.me?.company?.id;

  const [image, updateImage] = React.useState<string>('');
  const [isLoadingImage, setLoadingImage] = React.useState(false);

  React.useEffect(() => {
    if (meQuery?.data) {
      const company = meQuery?.data?.me?.company;
      updateImage(company?.avatarUrl ?? '');
    }
  }, [meQuery.data]);

  const handleSubmitAvatar = async (base64Img: string) => {
    setLoadingImage(true);
    try {
      await uploadCompanyAvatar({ base64Img, companyId });
      updateImage(base64Img);
      setLoadingImage(false);
    } catch (e) {
      setLoadingImage(false);
      handleError(e);
    }
  };

  return (
    <ImageUploader
      loading={isLoadingImage}
      type='avatar'
      image={image || PlaceholderImage}
      onSubmit={(event) => {
        handleSubmitAvatar(event.base64Img);
      }}
      style={{
        display: 'inline-block',
        margin: '60px 74px',
      }}
      helperText='Drag or click to upload a company logo. Maximum 250x250 px.'
      onError={(message) => snackbar?.openSnackbar({ isError: true, message })}
    />
  );
};

export default ImageForm;
