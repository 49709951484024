import React from 'react';

//components
import TableView from '../../Appointments/InPersonAppointments/InPersonAppointmentsList/components/InPersonAppointmentsTable';
import { GridCard } from '../../../Shared/GridCard';

//queries
import {
  useInPersonAppointmentsQuery,
  useMeQuery,
  Order,
} from '../../../../generated/graphql';
import { ListTitle, CardListBody } from '../styles/Profile.style';

//hooks
import useSnackbar from '../../../../hooks/useSnackbar';

const InPersonAppointmentsList = () => {
  const snackbar = useSnackbar();
  const meQuery = useMeQuery();
  const [offset, setOffset] = React.useState(0);
  const [defaultPageIndex, updateDefaultPageIndex] = React.useState(0);
  const pagesize = 10;

  const appointmentsQueryInput = {
    limit: pagesize,
    offset,
    employeeIds: [meQuery.data?.me?.id ?? ''],
    order: Order.Desc,
  };

  const inPersonAppointmentsQuery = useInPersonAppointmentsQuery({
    variables: {
      input: appointmentsQueryInput,
    },
    skip: !meQuery.data?.me?.id,
    onError: () => snackbar?.useGenericErrorMessage(),
    fetchPolicy: 'cache-and-network',
  });

  const totalCount =
    inPersonAppointmentsQuery.data?.inPersonAppointments?.pager?.total;
  const loading = inPersonAppointmentsQuery.loading;
  if (
    !inPersonAppointmentsQuery.called && 
    !loading &&
    !inPersonAppointmentsQuery.data?.inPersonAppointments?.pager?.total
  ) {
    return (
      <>
        <ListTitle>In-Person Appointments</ListTitle>
        <GridCard style={{ width: '100%', marginRight: 0 }}>
          <CardListBody>
            <p>You don&apos;t have any in-person appointments.</p>
          </CardListBody>
        </GridCard>
      </>
    );
  }

  return (
    <>
      <ListTitle>My In-Person Appointments</ListTitle>
      <GridCard style={{ width: '100%', marginRight: 0 }}>
        <TableView
          tableOptions={{
            minBodyHeight: 440,
            maxBodyHeight: 440,
            overflow: 'auto',
          }}
          onChangePage={(pageSize: number, idx: number) => {
            setOffset(idx * pageSize);
            updateDefaultPageIndex(idx);
          }}
          isLoading={inPersonAppointmentsQuery.loading}
          totalCount={totalCount ?? 0}
          appointmentsQuery={inPersonAppointmentsQuery}
          defaultPageIndex={defaultPageIndex}
          pagesize={pagesize}
          onDoneChangingOptionsAppointment={() =>
            inPersonAppointmentsQuery.refetch({
              input: appointmentsQueryInput,
            })
          }
        />
      </GridCard>
    </>
  );
};

export default InPersonAppointmentsList;
