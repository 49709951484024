import React from 'react';

//hooks
import { useRouteMatch, useHistory } from 'react-router-dom';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import { useEmployeeQuery } from '../../../../generated/graphql';

//components
import { Link } from 'react-router-dom';
import { Button } from '@betterpt/better-components';

//styles
import {
  BreadCrumb,
  BreadCrumbLink,
} from '../../../Shared/AppointmentTypes/AppointmentTypes.style';

const BreadCrumbs = ({ employeeId }: { employeeId?: string }) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { employeePluralAlias } = useRemoteConfigAliases();
  const invalidEmployeeId = !employeeId || isNaN(Number(employeeId));
  const employeeQuery = useEmployeeQuery({
    variables: {
      id: employeeId!,
    },
    skip: invalidEmployeeId,
    fetchPolicy: 'cache-first',
  });

  const firstLinkUrl = invalidEmployeeId ? '/company/info' : '/employees';
  const firstLinkText = invalidEmployeeId
    ? 'Company Info '
    : employeePluralAlias + ' ';
  const rawName = `${employeeQuery.data?.employee?.firstName ?? ''} ${
    employeeQuery.data?.employee?.lastName ?? ''
  }`;
  const employeeNameLinkText = rawName.trim().length ? `/ ${rawName} ` : '';

  return employeeId ? (
    <Button
      color='transparent'
      style={{
        padding: 0,
        marginBottom: 20,
        width: 'min-content',
      }}
      onClick={() => history.goBack()}
    >
      {`<`} BACK
    </Button>
  ) : (
    <BreadCrumb>
      <Link to={firstLinkUrl}>
        <BreadCrumbLink>{firstLinkText}</BreadCrumbLink>
      </Link>
      {employeeNameLinkText.length ? (
        <Link to={`/employees/${employeeId}`}>
          <BreadCrumbLink>{employeeNameLinkText}</BreadCrumbLink>
        </Link>
      ) : null}
      <Link to={url}>
        <BreadCrumbLink>/ Appointment Type Details</BreadCrumbLink>
      </Link>
    </BreadCrumb>
  );
};

export default BreadCrumbs;
