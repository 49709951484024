import React from 'react';
import styled from '@emotion/styled';
import { IcInfoOutline } from '@betterpt/better-icons';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';
import { InfoBox, colorValues } from '@betterpt/better-components';
import { addIndefiniteArticle, possessiveName } from '../../../../../helpers';

export const ProfessionalInfoHeader = styled.div`
  display: flex;
  justifycontent: space-between;
  align-items: center;
  marginbottom: 32;
  margintop: 42;
`;

const ProfessionalInformationInfoBox = () => {
  const {
    employeePluralAlias,
    employeeSingularAlias,
    facilitiesPluralAlias,
    facilitiesSingularAlias,
  } = useRemoteConfigAliases();

  return (
    <ProfessionalInfoHeader>
      <h4>Professional Information</h4>
      <InfoBox
        openWith='hover'
        height={140}
        width={170}
        position='right'
        padding={0}
        icon={<IcInfoOutline color={colorValues.betterptblack} />}
      >
        <div>
          <h4>PROFESSIONAL INFO</h4>
          <p style={{ fontSize: 11 }}>
            Set up this {possessiveName(employeeSingularAlias.toLowerCase())}{' '}
            {facilitiesPluralAlias.toLowerCase()} as well as roles and
            permissions. <br />
            <br /> Please note, {employeePluralAlias} need to be affiliated with{' '}
            {addIndefiniteArticle(facilitiesSingularAlias).toLowerCase()} in
            order to treat patients on our platform.
          </p>
        </div>
      </InfoBox>
    </ProfessionalInfoHeader>
  );
};

export default ProfessionalInformationInfoBox;
