import React from 'react';
import { gql } from '@apollo/client/core';
import { Animation, Card, Header } from '@betterpt/better-components';
import { useEmployeeClinicsQuery } from '../../../../../../generated/graphql';
import { useParams } from 'react-router-dom';
import ProviderHourBlock from './ProviderHourBlock';
import DirectToClinicHours from './DirectToClinicHours';
import { ProviderCardContainer } from './styles';

export const EMPLOYEE_CLINICS_QUERY = gql`
  query EmployeeClinics($id: ID!) {
    employee(id: $id) {
      id
      firstName
      lastName
      role
      clinics {
        id
        clinicName
        logoUrl
        shouldUseProviderSchedule
      }
    }
  }
`;

const ProviderHours = () => {
  const { employeeId } = useParams<{ employeeId: string }>();
  const { data, loading } = useEmployeeClinicsQuery({
    variables: { id: employeeId },
  });

  if (loading) {
    return (
      <ProviderCardContainer>
        <Card fullWidth>
          <Animation type='providerAppLoader' />
        </Card>
      </ProviderCardContainer>
    );
  }

  const employeeName = `${data?.employee?.firstName} ${data?.employee?.lastName}`;
  const role = data?.employee?.role ?? 'basic';
  const facilities = data?.employee?.clinics;
  const sortedFacilities = facilities?.slice(0).sort((facility) => {
    return facility?.shouldUseProviderSchedule ? -1 : 1;
  });

  return (
    <ProviderCardContainer>
      <h2 className='H1'>Staff Hours of Operation</h2>
      <Card fullWidth>
        <Header underline style={{ padding: '30px 25px 30px 62px' }}>
          <h3>Staff In-Person Hours</h3>
        </Header>
        {sortedFacilities?.map((facility) =>
          facility?.shouldUseProviderSchedule ? (
            <ProviderHourBlock
              key={facility?.id}
              facility={facility}
              employeeName={employeeName}
              employeeRole={role}
            />
          ) : (
            <DirectToClinicHours key={facility?.id} facility={facility} />
          )
        )}
      </Card>
    </ProviderCardContainer>
  );
};

export default ProviderHours;
