import React from 'react';

//helpers
import { emailValidation, normalizePhone } from '../../../../../../helpers';

//hooks
import { useRouteMatch } from 'react-router-dom';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import useTextFieldState from '../../../../../../hooks/useTextFieldState';
import { UpdateLoginAndContactPayload } from '../../hooks/useEmployeeOperations';
import useHandleError from '../../../../../../hooks/useHandleError';

//components
import { Animation } from '@betterpt/better-components';
import { GridCard } from '../../../../../Shared/GridCard';
import FormField from '../../../../../Shared/FormField';
import ThemedTextField from '../../../../../Shared/ThemedTextField';
import CardHeader from './CardHeader';
import ValidatedPhoneNumber from '../../../../../Shared/Picas/ValidatedPhoneNumber';

//queries and mutations
import {
  Employee,
  useEmployeeQuery,
  useMeQuery,
} from '../../../../../../generated/graphql';

//styles
import { FormBody } from '../../styles/EmployeeDetail.style';

interface Props {
  updateLoginAndContact: (
    payload: UpdateLoginAndContactPayload
  ) => Promise<void>;
}

const LoginContactInformation = ({ updateLoginAndContact }: Props) => {
  const snackbar = useSnackbar();
  const {
    params: { employeeId },
  } = useRouteMatch<{ employeeId: string }>();

  const handleError = useHandleError();
  const meQuery = useMeQuery();
  const queryEmployee = useEmployeeQuery({
    variables: { id: employeeId },
    skip: !employeeId,
  });

  const [isEditModeActive, setEditModeActive] = React.useState(false);
  const [isSaving, updateSaving] = React.useState(false);
  const phoneFormState = useTextFieldState({
    normalizer: normalizePhone,
    initialValue: normalizePhone(queryEmployee.data?.employee?.phone ?? ''),
  });

  const alternateEmailState = useTextFieldState({
    validation: emailValidation,
    initialValue:
      queryEmployee.data?.employee?.alternateTelehealthContactEmail ?? '',
  });

  React.useEffect(() => {
    if (queryEmployee.data) {
      phoneFormState.updateValue(queryEmployee.data?.employee?.phone ?? '');
      alternateEmailState.updateValue(
        queryEmployee.data?.employee?.alternateTelehealthContactEmail ?? ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryEmployee.data]);

  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateSaving(true);
    try {
      await updateLoginAndContact({
        employeeId,
        phoneFormState,
        alternateEmailState,
      });
      snackbar?.setSuccessMessage('Information updated successfully');
      setEditModeActive(false);
      updateSaving(false);
    } catch (e) {
      updateSaving(false);
      handleError(e);
    }
  };

  const isSelf = queryEmployee.data?.employee?.id === meQuery.data?.me?.id;
  const loading = queryEmployee.loading;
  const shouldDisablePrimaryEmailAndPhone =
    !!queryEmployee.data?.employee?.isManagedViaYext;

  const generatePhonePlaceholder = () => {
    if (isEditModeActive && !shouldDisablePrimaryEmailAndPhone)
      return 'Enter a mobile phone number';
    else if (isSelf || shouldDisablePrimaryEmailAndPhone)
      return 'No phone number on file';
    else return 'Click edit to add a phone number';
  };

  return (
    <GridCard>
      {loading ? (
        <Animation type='providerAppLoader' />
      ) : (
        <form onSubmit={handleSubmit}>
          <CardHeader
            headerText='Login and Contact Information'
            isEditModeActive={isEditModeActive}
            setEditModeActive={setEditModeActive}
            saving={isSaving}
            employee={queryEmployee.data?.employee as Employee}
          />
          <FormBody>
            <ThemedTextField
              fullWidth
              disabled
              value={queryEmployee.data?.employee?.email ?? ''}
              label='Account Email'
              placeholder='No account email on file'
            />
            <FormField
              label='Mobile Phone'
              id='phone'
              placeholder={generatePhonePlaceholder()}
              formHandler={phoneFormState}
              disabled={!isEditModeActive || shouldDisablePrimaryEmailAndPhone}
              fullWidth
              InputProps={{
                endAdornment: queryEmployee.data?.employee
                  ?.hasValidatedMobileNumber && (
                  <>
                    {
                      <ValidatedPhoneNumber
                        position='left'
                        message={`We have confirmed this is a valid mobile phone number.`}
                        mainStyle={{ position: 'relative' }}
                      />
                    }
                  </>
                ),
              }}
            />
            {shouldDisablePrimaryEmailAndPhone && (
              <FormField
                label='Alternate Telehealth Contact Email'
                id='phone'
                placeholder={
                  isEditModeActive
                    ? 'Enter a alternate telehealth contact email'
                    : 'Click edit to add an alternate telehealth contact email'
                }
                formHandler={alternateEmailState}
                disabled={!isEditModeActive}
                fullWidth
              />
            )}
          </FormBody>
        </form>
      )}
    </GridCard>
  );
};

export default LoginContactInformation;
