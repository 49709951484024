import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    width='161'
    height='160'
    viewBox='0 0 161 160'
  >
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g transform='translate(-719 -272) translate(597 272) translate(122.5)'>
            <g transform='translate(34 2)'>
              <path
                fill='#2A4E82'
                d='M77.67 81.33c-.26-.259-.52-.509-.78-.75-.371-.36-.75-.71-1.13-1.049-.12-.111-.241-.221-.36-.311-.26-.25-.54-.48-.81-.7-.321-.27-.64-.53-.97-.78-.62-.5-1.25-.97-1.9-1.43-3.61-2.56-7.63-4.59-11.91-6.01-.1-.03-.21-.069-.31-.1-.08-.03-.16-.049-.24-.08-.14-.04-.28-.089-.43-.13-1.09-.339-2.2-.629-3.33-.89-.17-.04-.34-.069-.51-.11-3.06-.65-6.23-.99-9.49-.99-2.73 0-5.4.24-8 .7-.16.031-.33.06-.49.09-1.19.22-2.36.491-3.51.821-.4.109-.79.219-1.18.339-.27.081-.53.161-.8.26-3.24 1.03-6.33 2.441-9.21 4.16-3.32 1.96-6.36 4.33-9.06 7.05C5.06 89.64 0 100.97 0 113.5V136h91v-22.5c0-12.559-5.09-23.94-13.33-32.17z'
              />
              <path
                fill='#4D4D4D'
                fillRule='nonzero'
                d='M11 24L79 32.037 79 68 11 68z'
              />
              <path
                fill='#FFF'
                fillRule='nonzero'
                d='M34 78L57.816 80.822 44.405 113.437z'
              />
              <circle cx='45' cy='93' r='1' fill='#B1B1B1' fillRule='nonzero' />
              <circle
                cx='45'
                cy='105'
                r='1'
                fill='#B1B1B1'
                fillRule='nonzero'
              />
              <path
                fill='#E7E7E7'
                d='M53.934 88.74c-.141.079-.29.159-.43.22-.131.08-.25.139-.381.2-.11.05-.24.059-.37.04h-.01c-.74-.141-1.77-1.341-2.87-2.74-1.64-2.111-3.42-4.66-4.52-4.66-.99 0-2.57 2.45-4.04 4.509-1.19 1.66-2.31 3.071-3 2.781-.26-.111-.51-.231-.76-.35-.12-.061-.24-.12-.36-.2-4.97-2.7-7.22-8.62-5.75-14.05.11-.4.24-.801.39-1.2l.01-.04.51-1.34c1.31-.37 2.64-.67 4-.91h.17l17.83.399c.06.011.11.03.17.04 1.3.301 2.58.651 3.83 1.061l.16.33.18.389c.03.071.06.13.09.201.12.259.22.53.31.799v.01c1.95 5.42-.07 11.67-5.16 14.511'
              />
              <path
                fill='#E2956F'
                d='M55 53v15c0 4.97-4.03 9-9 9s-9-4.03-9-9V53h18z'
              />
              <path
                fill='#F8AA7E'
                d='M77.65 39.296c-.699 9.364-5.122 17.557-11.724 23.231-6.59 5.685-15.35 8.841-24.714 8.141C22.483 69.27 8.44 52.958 9.84 34.23c.028-.369.065-.727.103-1.095.411-3.9 1.478-7.57 3.062-10.932.215-.456.438-.9.671-1.334 1.998-3.782 4.684-7.12 7.888-9.87 6.592-5.684 15.351-8.84 24.715-8.14 7.71.575 14.617 3.668 19.977 8.431.067.045.124.1.188.174 1.091.964 2.116 2.014 3.066 3.118 2.76 3.194 4.92 6.896 6.333 10.932 1.066 3.058 1.716 6.295 1.866 9.656.046.996.05 2.01.004 3.028-.017.37-.034.73-.062 1.1'
              />
              <circle cx='28' cy='46' r='3' fill='#4D4D4D' fillRule='nonzero' />
              <circle cx='52' cy='46' r='3' fill='#4D4D4D' fillRule='nonzero' />
              <path
                fill='#4D4D4D'
                d='M61.56 7.022c-.032.273-.067.545-.111.807-.26 1.57-.783 3.106-1.547 4.59v.01c-4.602 9.013-17.805 16.274-33.78 17.62-6.255.529-12.21.092-17.48-1.141.15-.565.312-1.118.482-1.66 1.466-4.723 3.707-9.054 6.567-12.791C21.575 6.733 30.07 1.558 39.78.74 47.77.065 55.33 2.443 61.56 7.022'
              />
              <path
                fill='#4D4D4D'
                d='M81.104 38.814c-12.613-4.125-21.293-11.931-22.05-21.396-.106-1.332-.056-2.66.155-3.97.306-1.943.94-3.835 1.885-5.662.122-.27.258-.527.409-.786 1.615 1.168 3.164 2.472 4.626 3.868 4.247 4.04 7.827 8.928 10.51 14.446 2.028 4.181 3.557 8.703 4.465 13.5'
              />
              <path fill='#4A79B5' fillRule='nonzero' d='M64 108H78V110H64z' />
              <path
                fill='#F06456'
                fillRule='nonzero'
                d='M56.859 81v1.966c0 2.21-1.369 4-3.057 4-.41 0-.816-.108-1.194-.318L46 82.978l6.112-5.31c1.406-1.222 3.303-.72 4.237 1.12.332.656.51 1.425.51 2.212z'
              />
              <path
                fill='#F06456'
                fillRule='nonzero'
                d='M45.859 81v1.966c0 2.21-1.369 4-3.057 4-.41 0-.816-.108-1.194-.318L35 82.978l6.112-5.31c1.406-1.222 3.303-.72 4.237 1.12.332.656.51 1.425.51 2.212z'
                transform='matrix(-1 0 0 1 80.859 0)'
              />
            </g>
            <g>
              <path
                fill='#294F83'
                d='M1.94 66.816V69.3c.002.41.782.885 1.748.885h126.624c.966 0 1.746-.475 1.748-.87v-2.498H1.94zM130.312 72H3.688C1.628 72 .008 70.825 0 69.325V65h134v4.3c-.009 1.525-1.63 2.7-3.688 2.7z'
                transform='translate(13 88)'
              />
              <path
                fill='#A3BBD8'
                d='M76 62c-.246 1.722-1.14 3-2.202 3H60.203c-1.065 0-1.957-1.278-2.203-3h18z'
                transform='translate(13 88)'
              />
              <path
                fill='#294F83'
                d='M59.343 63.004c.334.605.923.992 1.576.992h14.162c.653 0 1.242-.387 1.576-.992H59.343zM75.08 65H60.919c-1.324 0-2.468-.982-2.782-2.389L58 62h20l-.137.611C77.548 64.018 76.404 65 75.081 65z'
                transform='translate(13 88)'
              />
              <path
                fill='#E5E5E4'
                d='M132.513 70H5.684C4.204 70 3 68.87 3 67.482c0-.253.218-.482.487-.482.27 0 .487.18.487.431v.05c0 .885.767 1.605 1.71 1.605h126.829c.269 0 .487.205.487.457s-.218.457-.487.457'
                transform='translate(13 88)'
              />
              <path
                fill='#F9F9FA'
                d='M116.007 70H16.993C15.341 70 14 68.676 14 67.042V6.162C14 3.31 16.338 1 19.223 1h94.555C116.662 1 119 3.31 119 6.161v60.88c0 1.635-1.34 2.959-2.993 2.959'
                transform='translate(13 88)'
              />
              <path
                fill='#294F83'
                d='M19.142 1.954c-2.322 0-4.212 1.912-4.212 4.262V68.02c0 1.117.898 2.026 2.002 2.026h98.136c1.104 0 2.002-.909 2.002-2.026V6.216c0-2.35-1.89-4.262-4.211-4.262H19.142zM115.068 72H16.932C14.764 72 13 70.214 13 68.02V6.216C13 2.788 15.756 0 19.142 0h93.717C116.245 0 119 2.788 119 6.216V68.02c0 2.194-1.764 3.98-3.932 3.98z'
                transform='translate(13 88)'
              />
              <path
                fill='#E5E5E4'
                d='M111.03 69H22.105C18.74 69 16 66.242 16 62.85V8.978c0-.54.435-.978.97-.978.538 0 .972.437.972.978V62.85c0 2.313 1.868 4.194 4.164 4.194h88.923c.537 0 .971.437.971.978 0 .54-.434.978-.97.978'
                transform='translate(13 88)'
              />
              <path
                fill='#E5E5E4'
                d='M74 36c0 3.866-3.134 7-7 7s-7-3.134-7-7 3.134-7 7-7 7 3.134 7 7'
                transform='translate(13 88)'
              />
            </g>
            <rect
              width='8'
              height='8'
              x='76'
              y='80'
              fill='#F06456'
              fillRule='nonzero'
              rx='2'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
