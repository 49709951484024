export const initialFormattedOpenCloseDaysHours = {
  fridayClose: '',
  fridayOpen: '',
  mondayClose: '',
  mondayOpen: '',
  saturdayClose: '',
  saturdayOpen: '',
  sundayClose: '',
  sundayOpen: '',
  thursdayClose: '',
  thursdayOpen: '',
  tuesdayClose: '',
  tuesdayOpen: '',
  wednesdayClose: '',
  wednesdayOpen: '',
};

export const initialSelectedDays = {
  fridayOpenSelected: false,
  mondayOpenSelected: false,
  saturdayOpenSelected: false,
  sundayOpenSelected: false,
  thursdayOpenSelected: false,
  tuesdayOpenSelected: false,
  wednesdayOpenSelected: false,
};

export type FormattedOpenCloseDaysHours = Partial<
  typeof initialFormattedOpenCloseDaysHours
>;
export type SelectedDays = Partial<typeof initialSelectedDays>;

export type ProviderHoursState = {
  formattedOpenCloseDaysHours: FormattedOpenCloseDaysHours;
  selectedDays: SelectedDays;
};
