import { jsx } from '@emotion/react';
import styled from '@emotion/styled';

// Icons
import IcSearch from '../../../Icons/IcSearch';

// Constants & types
import { colorValues } from '../../../helpers/colors';
import { Toolbar, Icons } from '../../types';

// Components
import TextField from '../../../TextField';
import Button from '../../../Button';

// Styles
import { searchSection } from '../style';

type Props = {
  onSearchChange?: (searchText: string) => void;
  buttonColor?: string;
};

const Div = styled.div``;
const searchFieldStyle = (color?: string) => ({
  marginBottom: 20,
  '& label': {
    color: `${colorValues.betterptblack} !important`,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: color,
  },
  '& .MuiInput-underline:hover': {
    borderBottomColor: color,
  },
  '& label.Mui-focused': {
    color: color,
  },
  '& .MuiInput-underline:hover:before': {
    borderBottom: 'color',
  },
  '& .MuiInput-underline.Mui-disabled:after': {
    borderBottom: `1px solid ${colorValues.concretejungle}`,
  },
  '& .MuiInput-underline.Mui-disabled:before': {
    borderBottom: `1px solid ${colorValues.concretejungle}`,
  },
  marginRight: 15,
});

export default ({
  searchIcon,
  searchLabel,
  searchPlaceholder,
  onSearchChange,
  searchValue,
  searchWidth,
  buttonColor,
}: Partial<Toolbar> & Partial<Icons> & Props) => (
  <Div css={searchSection}>
    <TextField
      inputProps={{ style: { width: searchWidth || 'auto' } }}
      InputProps={{ startAdornment: searchIcon }}
      value={searchValue || ''}
      label={searchLabel}
      placeholder={searchPlaceholder || 'Search'}
      overrideStyle={searchFieldStyle(buttonColor || colorValues.frenchblue)}
      onChange={(e) => onSearchChange && onSearchChange(e.target.value)}
    />
    <Button
      overrideStyle={{
        height: 30,
        minWidth: 30,
        padding: 0,
        backgroundColor: buttonColor || colorValues.frenchblue,
      }}
    >
      <IcSearch color={colorValues.emptiness} />
    </Button>
  </Div>
);
