import React from 'react';

//types
import {
    Employee,
    Maybe,
} from '../../../../generated/graphql';
import styled from '@emotion/styled';

//hooks
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';

//components
import { Button } from '@betterpt/better-components';

//styles
import { colorValues } from '@betterpt/better-components';
import {
    DialogBody,
    DialogHeader,
} from '../AppointmentTypes.style';
import { Checkbox } from '@betterpt/better-components';
import ProgressBar from "../../ProgressBar";


const QuestionContainer = styled.div`
  border-radius: 5px;
  border: solid 2px ${colorValues.concretejungle};
  margin: 15px 25px 15px 0px;
  padding: 18px 0px 18px 21px;
`;

const QuestionText = styled.p`
  border-bottom: 1px solid ${colorValues.coneyislandsidewalk};
`;

const QuestionNumber = styled.h3`
  margin: 0px 0px 10px 0px;
  color: ${colorValues.charcoalgray};
`;

const CheckboxLabel = styled.label`
  position: relative;
  right: 12px;
`;


// styles
const formStyle = { width: '100%' };
const firstHeaderSize = { fontSize: 24 };
const buttonStyle = (backgroundColor: string) => ({
    backgroundColor,
    marginTop: '7vh',
    position: 'absolute' as 'absolute',
    top: 40,
    right: 40,
    width: 290,
});
const providerSubheaderStyle = {
    fontStyle: 'italic',
    margin: '10px 0 19px',
};

interface Props {
    setShowDefaultQuestion: Function;
    setShowQualifyingQuestion: Function;
}

const DefaultQuestionsForPT = ({
    setShowDefaultQuestion,
    setShowQualifyingQuestion
}: Props) => {
    const {
        patientsSingularAlias,
        primaryColor,
    } = useRemoteConfigAliases();

    const handleAcknowledgement = () => {
        setShowDefaultQuestion(false);
        setShowQualifyingQuestion(true);

    };

    return (
        <DialogBody wide>
            <ProgressBar width={70} style={{ marginTop: 20 }} />
            <div style={formStyle}>
                <DialogHeader style={firstHeaderSize}>
                    Industry Specific Qualifying Questions for <br /> this Appointment Type
                </DialogHeader>
                <p className='Body' style={providerSubheaderStyle}>
                    The two questions below are to be added by default
                    to your{' '}{patientsSingularAlias.toLowerCase()}{' '} booking flow.
                    These are not editable.
                </p>
                <QuestionContainer key={"question_1"}>
                    <QuestionNumber className='H3'>Question 1 (Default)</QuestionNumber>
                    <p className='H6'>Yes or No Question</p>
                    <QuestionText className='Body'>
                        Do you have a physician's referral?
                    </QuestionText>
                    <CheckboxLabel className='Body'>
                        <Checkbox
                            checked={true}
                            disabled
                        />
                        Question is required
                    </CheckboxLabel>
                </QuestionContainer>

                <QuestionContainer key={"question_2"}>
                    <QuestionNumber className='H3'>Question 2 (Default)</QuestionNumber>
                    <p className='H6'>Open-Ended Question</p>
                    <QuestionText className='Body'>
                        If yes, add your referring physician's name (required for referrals).
                    </QuestionText>
                    <CheckboxLabel className='Body'>
                        <Checkbox
                            checked={false}
                            disabled
                        />
                        Question is required
                    </CheckboxLabel>
                </QuestionContainer>

                <Button
                    onClick={handleAcknowledgement}
                    size='large'
                    style={buttonStyle(primaryColor)}
                    // loading={loading}
                    data-cy='create-appointment-type-button'
                >
                    ACKNOWLEDGE AND CONTINUE
                </Button>
            </div>
        </DialogBody>
    );
};

export default DefaultQuestionsForPT;
