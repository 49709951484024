import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...rest}
  >
    <g id='Scenes' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Leaders-Copy' transform='translate(-3384.000000, -2089.000000)'>
        <g
          id='icon-glyph-ic-logout'
          transform='translate(3384.000000, 2089.000000)'
        >
          <polygon id='Path' points='0 0 24 0 24 24 0 24'></polygon>
          <polygon
            id='a-path'
            fill={color || '#333333'}
            points='10.09 15.59 11.5 17 16.5 12 11.5 7 10.09 8.41 12.67 11 3 11 3 13 12.67 13'
          ></polygon>
          <path
            d='M19,3 L5,3 C3.8954305,3 3,3.8954305 3,5 L3,9 L5,9 L5,5 L19,5 L19,19 L5,19 L5,15 L3,15 L3,19 C3,20.1045695 3.8954305,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z'
            id='a-path'
            fill={color || '#333333'}
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
