import useRemoteConfig from './useRemoteConfig';
import { colorValues } from '@betterpt/better-components';
import { Company, useMeQuery } from '../generated/graphql';
import logosAndColorsMap, {
  determineConfigKey,
} from '../helpers/logosAndColors';
import useAuthentication from './useAuthentication';

const useRemoteConfigAliases = () => {
  const auth = useAuthentication();
  const meQuery = useMeQuery({ skip: !auth?.user });

  const remoteConfig = useRemoteConfig();
  const employeesCopy = JSON.parse(
    remoteConfig?.info?.getValue('employeesAlias')?.asString() || '{}'
  );
  const employeeSingularAlias: string = remoteConfig.loading
    ? ''
    : employeesCopy?.singular ?? 'Employee';

  const employeePluralAlias: string = remoteConfig.loading
    ? ''
    : employeesCopy?.plural ?? 'Employees';

  const facilitiesCopy = JSON.parse(
    remoteConfig?.info?.getValue('facilitiesAlias')?.asString() || '{}'
  );

  const facilitiesSingularAlias: string = remoteConfig.loading
    ? ''
    : facilitiesCopy?.singular ?? 'Facility';

  const facilitiesPluralAlias: string = remoteConfig.loading
    ? ''
    : facilitiesCopy?.plural ?? 'Facilities';

  const patientsCopy = JSON.parse(
    remoteConfig?.info?.getValue('patientsAlias')?.asString() || '{}'
  );

  const patientsSingularAlias: string = remoteConfig.loading
    ? ''
    : patientsCopy?.singular ?? 'Patient';

  const patientsPluralAlias: string = remoteConfig.loading
    ? ''
    : patientsCopy?.plural ?? 'Patients';

  const configKey = determineConfigKey(
    meQuery.data?.me?.company as Company | null | undefined
  );

  const primaryColor =
    logosAndColorsMap.get(configKey)?.primaryColor ?? colorValues.ibizateal;
  const secondaryColor =
    logosAndColorsMap.get(configKey)?.secondaryColor ?? colorValues.frenchblue;
  const tertiaryColor =
    logosAndColorsMap.get(configKey)?.tertiaryColor ?? colorValues.frost;
  const textFieldColor =
    logosAndColorsMap.get(configKey)?.textFieldColor ?? colorValues.ibizateal;

  return {
    loading: remoteConfig.loading,
    employeeSingularAlias,
    employeePluralAlias,
    facilitiesSingularAlias,
    facilitiesPluralAlias,
    patientsSingularAlias,
    patientsPluralAlias,
    primaryColor,
    textFieldColor,
    secondaryColor,
    tertiaryColor,
  };
};

export default useRemoteConfigAliases;
