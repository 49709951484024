import * as React from 'react';

// components
import { GridCard } from '../../../Shared/GridCard';
import CardTextField from '../../Appointments/Shared/CardTextField';

// types
import {
  useCompanyBookingFlowQuery,
  useMeQuery,
} from '../../../../generated/graphql';

// style
import * as styles from './PatientDetail.style';
import useGetAuthenticatedImage from './hooks/useGetInsuranceCard';
import CardDialog from './CardDialog';

// assets
import FrontImagePlaceholder from '../../../../assets/img-nocardboy.svg';
import BackImagePlaceholder from '../../../../assets/img-nocardgirl.svg';

export type MappedInsuranceInfoData = {
  insuranceCardFrontUrl: string | null | undefined;
  insuranceCardBackUrl: string | null | undefined;
  primaryInsuranceCarrier: string | null | undefined;
  primaryInsurancePlan: string | null | undefined;
  primaryInsuranceMemberId: string | null | undefined;
  secondaryInsuranceCarrier: string | null | undefined;
  secondaryInsurancePlan: string | null | undefined;
  secondaryInsuranceMemberId: string | null | undefined;
};

type Props = {
  data: MappedInsuranceInfoData;
};

const InsuranceInfo = ({ data }: Props) => {
  const {
    insuranceCardFrontUrl,
    insuranceCardBackUrl,
    primaryInsuranceCarrier,
    primaryInsurancePlan,
    primaryInsuranceMemberId,
    secondaryInsuranceCarrier,
    secondaryInsurancePlan,
    secondaryInsuranceMemberId,
  } = data;

  const meQuery = useMeQuery();

  const companyBookingFlowQuery = useCompanyBookingFlowQuery({
    variables: {
      id: meQuery.data?.me?.company?.id || '0',
    },
    skip: !meQuery.data?.me?.company?.id,
  });

  const [
    frontOfInsuranceCard,
    updateFrontOfInsuranceCard,
  ] = React.useState<string>();
  const [
    backOfInsuranceCard,
    updateBackOfInsuranceCard,
  ] = React.useState<string>();
  const [openedCardModal, updateOpenedCardModal] = React.useState<
    'front' | 'back' | null
  >(null);

  const askSecondaryInsurance =
    companyBookingFlowQuery.data?.company?.bookingFlow?.askSecondaryInsurance;

  const getInsuranceCard = useGetAuthenticatedImage();

  const dialogUrl =
    openedCardModal === 'front'
      ? frontOfInsuranceCard
      : openedCardModal === 'back'
      ? backOfInsuranceCard
      : '';

  const handleCardModalClose = () => updateOpenedCardModal(null);
  const openFrontOfCard = () => updateOpenedCardModal('front');
  const openBackOfCard = () => updateOpenedCardModal('back');

  React.useEffect(() => {
    if (insuranceCardFrontUrl) {
      getInsuranceCard(insuranceCardFrontUrl).then(
        (url) => url && updateFrontOfInsuranceCard(url)
      );
    }
    if (insuranceCardBackUrl) {
      getInsuranceCard(insuranceCardBackUrl).then(
        (url) => url && updateBackOfInsuranceCard(url)
      );
    }
  }, [getInsuranceCard, insuranceCardBackUrl, insuranceCardFrontUrl]);

  return (
    <styles.StackedCards>
      {!!openedCardModal && (
        <CardDialog
          opened={!!openedCardModal}
          handleClose={handleCardModalClose}
          frontOrBack={openedCardModal}
          url={dialogUrl}
        />
      )}
      {!!primaryInsuranceCarrier && (
        <GridCard
          style={{
            ...styles.cardStyle,
            width: 'initial',
            margin: '0',
            marginRight: '0',
          }}
          data-testid='primary-insurance-info'
          shadow='none'
        >
          <styles.InfoHeader>
            <p>Insurance Info</p>
          </styles.InfoHeader>
          <styles.CardContents>
            <CardTextField
              fullWidth
              label='Carrier'
              value={primaryInsuranceCarrier}
              data-cy='patient-info-primary-insurance-carrier'
            />
            <CardTextField
              fullWidth
              label='Plan'
              value={primaryInsurancePlan ?? 'Not Provided'}
              data-cy='patient-info-primary-insurance-plan'
            />
            <CardTextField
              fullWidth
              label='Number'
              value={primaryInsuranceMemberId ?? 'Not Provided'}
              data-cy='patient-info-primary-insurance-memberNumber'
            />
          </styles.CardContents>
        </GridCard>
      )}
      {(frontOfInsuranceCard || backOfInsuranceCard) && (
        <GridCard
          style={{
            ...styles.cardStyle,
            width: 'initial',
            margin: '0',
            marginRight: '0',
          }}
          data-testid='insurance-card-container'
          shadow='none'
        >
          <styles.InfoHeader>
            <p>Insurance {!!primaryInsuranceCarrier ? 'Card' : 'Info'}</p>
          </styles.InfoHeader>
          <styles.CardContents>
            <styles.InsuranceImageContainer>
              <div>
                <p className='Form'>Front of Card</p>
                <img
                  src={frontOfInsuranceCard}
                  alt='Front of the insurance card'
                  onClick={openFrontOfCard}
                  data-cy='patient-info-insurance-card-front'
                />
              </div>
              <div>
                <p className='Form'>Back of Card</p>
                <img
                  src={backOfInsuranceCard}
                  alt='Back of the insurance card'
                  onClick={openBackOfCard}
                  data-cy='patient-info-insurance-card-back'
                />
              </div>
            </styles.InsuranceImageContainer>
          </styles.CardContents>
        </GridCard>
      )}

      {!frontOfInsuranceCard &&
        !backOfInsuranceCard &&
        !primaryInsuranceCarrier && (
          <GridCard
            style={{
              ...styles.cardStyle,
              width: 'initial',
              margin: '0',
              marginRight: '0',
            }}
            data-testid='no-insurance-container'
            shadow='none'
          >
            <styles.InfoHeader>
              <p>Insurance Info</p>
            </styles.InfoHeader>
            <styles.CardContents>
              <styles.InsuranceImageContainer isPlaceholder>
                <div>
                  <p className='Form'>Front of Card</p>
                  <img
                    src={FrontImagePlaceholder}
                    alt='Front of card not added. Please contact patient for info.'
                  />
                </div>
                <div>
                  <p className='Form'>Back of Card</p>
                  <img
                    src={BackImagePlaceholder}
                    alt='Back of card not added. Please contact patient for info.'
                  />
                </div>
              </styles.InsuranceImageContainer>
            </styles.CardContents>
          </GridCard>
        )}

      {!!(askSecondaryInsurance && secondaryInsuranceCarrier) && (
        <GridCard
          style={{
            ...styles.cardStyle,
            width: 'initial',
            margin: '40px 0 0 0',
          }}
          shadow='none'
        >
          <styles.InfoHeader>
            <p>Secondary Insurance Info</p>
          </styles.InfoHeader>
          <styles.CardContents>
            <CardTextField
              fullWidth
              label='Carrier'
              value={secondaryInsuranceCarrier}
              data-cy='patient-info-secondary-insurance-carrier'
            />
            <CardTextField
              fullWidth
              label='Plan'
              value={secondaryInsurancePlan ?? 'Not Provided'}
              data-cy='patient-info-secondary-insurance-plan'
            />
            <CardTextField
              fullWidth
              label='Number'
              value={secondaryInsuranceMemberId ?? 'Not Provided'}
              data-cy='patient-info-secondary-insurance-memberNumber'
            />
          </styles.CardContents>
        </GridCard>
      )}
    </styles.StackedCards>
  );
};

export default InsuranceInfo;
