import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

export const CSVBreadcrumb = styled.h2`
  font-size: 20px;
  margin: 0 10px 0 0;
`;

export const CSVRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const CSVHeader = styled.h1`
  font-size: 20px;
  margin: 30px 0 20px;
`;

export const CSVGrayText = styled.p`
  color: ${colorValues.charcoalgray};
  font-size: 12px;
  font-weight: bold;
  line-height: 1.25;
  max-width: 80px;
  margin: 0;
`;

export const CSVPageBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px;
  margin: 100px auto 65px;
`;

export const CSVParagraph = styled.p`
  font-size: 16px;
  line-height: 1.5;
  max-width: 380px;
  margin-bottom: 30px;
`;

export const CSVDisclaimer = styled.p`
  font-size: 12px;
  line-height: 1.17;
  font-weight: bold;
  font-style: italic;
  max-width: 460px;
  margin-top: 20px;
`;

export const CSVSentDialog = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 60px;
`;

export const CSVEmail = styled.span`
  color: ${colorValues.frenchblue};
  border-bottom: 1px solid ${colorValues.frenchblue};
`;

export const CSVDatePickerDialogBody = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px;
  height: 404px;
`;

export const CSVDateSection = styled.div`
  display: flex;
  align-items: center;
`;

export const CSVDateRangeInputSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 218px;
  position: relative;
  margin-left: 10px;
`;

export const CSVDateError = styled.p`
  color: ${colorValues.messyketchup};
  font-size: 12px;
  margin: 0 5px;
  position: absolute;
  bottom: 65px;
`;
