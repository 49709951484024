import styled from '@emotion/styled';
import { colorValues, bestTextColor } from '@betterpt/better-components';

//MAIN DIALOG STYLES
export const DialogBody = styled.div`
  position: relative;
  padding: 47px 45px 36px 48px;
  margin: 5px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const H1 = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 24px;
`;

export const buttonStyle = (color?: string) => ({
  backgroundColor: color || colorValues.cityblock,
  color: bestTextColor(color ?? colorValues.cityblock),
  fontWeight: 'bold' as 'bold',
  marginTop: '20px',
});

//EMPLOYEE PICKER STYLES
export const employeeDrawerContainer = {
  position: 'relative' as 'relative',
  maxWidth: '521px',
  width: '100%',
  marginTop: '20px',
};

export const AutocompleteStyleWrapper = styled.div`
  .MuiAutocomplete-endAdornment {
    top: -16px;
  }
`;
