import styled from '@emotion/styled';

export const AutocompleteDropdownDrawerContainer = {
  position: 'relative' as 'relative',
  maxWidth: '521px',
  width: '100%',
  marginTop: '20px',
};


export const AutocompleteDropdownStyleWraper = styled.div<{
  text?: string;
}>`
  .MuiAutocomplete-endAdornment {
    top: -16px;
  }
  .MuiAutocomplete-input {
    height: 19px;
    font-size: ${(props) => (props.text ? '16px' : '14px')};
    font-weight: ${(props) => (props.text ? 'bold' : 'normal')};
  }
  .MuiAutocomplete-inputRoot[class*='MuiInput-root'][class*='MuiInput-marginDense']
    .MuiAutocomplete-input:first-of-type {
    padding: 6px 0;
  }
`;
