import * as React from 'react';

export default ({ color, opacity, ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width='109'
    height='108'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...rest}
  >
    <defs>
      <path
        d='m76.5 67.5 6.975 6.975C79.155 82.08 68.49 88.155 58.5 89.64V49.5H72v-9H58.5v-5.31c5.22-1.89 9-6.84 9-12.69C67.5 15.075 61.425 9 54 9s-13.5 6.075-13.5 13.5c0 5.85 3.78 10.8 9 12.69v5.31H36v9h13.5v40.14c-9.99-1.485-20.655-7.56-24.975-15.165L31.5 67.5 13.5 54v13.5C13.5 84.96 35.64 99 54 99s40.5-14.04 40.5-31.5V54l-18 13.5zM54 18c2.475 0 4.5 2.025 4.5 4.5S56.475 27 54 27a4.513 4.513 0 0 1-4.5-4.5c0-2.475 2.025-4.5 4.5-4.5z'
        id='a'
      />
    </defs>
    <g transform='translate(.5)' fill='none' fillRule='evenodd'>
      <path d='M0 0h108v108H0z' />
      <mask id='b' fill='#fff'>
        <use xlinkHref='#a' />
      </mask>
      <use fill='#000' fillRule='nonzero' xlinkHref='#a' />
      <g mask='url(#b)' fill='#E74C3C'>
        <path d='M0 0h108v108H0z' />
      </g>
    </g>
  </svg>
);
