import * as React from 'react';

//types
import { CompanyListOptionType } from '../../../../../generated/graphql';

//hooks
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';

//components
import { Button } from '@betterpt/better-components';
import { Dialog } from '@material-ui/core';

//style
import { DialogTitle, DialogMessage, DialogBody } from './FacilityPicker.style';

type Props = {
  open: boolean;
  onClose: () => void;
  facilityType:
    | CompanyListOptionType.TelehealthFacilities
    | CompanyListOptionType.InPersonFacilities;
  useMigrationCopy?: boolean;
};

const NoFacilitiesWarningDialog = ({
  open,
  onClose,
  facilityType,
  useMigrationCopy,
}: Props) => {
  const {
    facilitiesSingularAlias,
    facilitiesPluralAlias,
    primaryColor,
  } = useRemoteConfigAliases();

  const copy = (() => {
    if (useMigrationCopy) {
      return {
        title: 'been migrated to BetterAccess',
        body: `been migrated over to this application. Please email support@betterhealthcare.co to ask our support staff to migrate the ${facilitiesSingularAlias?.toLowerCase()} you would like to create an appointment for.`,
      };
    } else
      return {
        title: 'this feature turned on',
        body: `this feature turned on. Please turn this feature on for the ${facilitiesSingularAlias?.toLowerCase()} you would like to create an appointment for. You can do this on its ${facilitiesSingularAlias?.toLowerCase() ??
          ''} detail page.`,
      };
  })();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogBody>
        <DialogTitle>
          None of your {facilitiesPluralAlias?.toLowerCase() ?? ''} have{' '}
          {copy.title}
        </DialogTitle>
        <DialogMessage>
          You cannot create{' '}
          {facilityType === CompanyListOptionType.InPersonFacilities
            ? 'an in-person'
            : 'a telehealth'}{' '}
          appointment because none of your{' '}
          {facilitiesPluralAlias?.toLowerCase() ?? ''} have {copy.body}
        </DialogMessage>
        <Button
          style={{ backgroundColor: primaryColor, marginTop: 30 }}
          fullWidth
          onClick={onClose}
          size='large'
        >
          THANK YOU
        </Button>
      </DialogBody>
    </Dialog>
  );
};

export default NoFacilitiesWarningDialog;
