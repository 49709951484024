import * as React from 'react';

// types
import { Order, Role } from '../../../../../generated/graphql';
import Flags from '../../../../../constants/flags';

// hooks
import { useMeQuery, useInPersonAppointmentsQuery, useTotalInPersonAppointmentsQuery } from '../../../../../generated/graphql';
import { useHistory, useParams, useRouteMatch, Link } from 'react-router-dom';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';
import useInPersonAppointmentFilters from '../../../../../hooks/useInPersonAppointmentFilters';

// components
import { Redirect, Route, Switch } from 'react-router-dom';
import Filters from './Filters';
import InPersonAppointmentsTable from './components/InPersonAppointmentsTable';
import InPersonAppointmentsCardView from './components/InPersonAppointmentsCardView';
import NoAppointmentsView from '../../Shared/NoAppointmentsView';
import useSnackbar from '../../../../../hooks/useSnackbar';
import useFeatureFlagWrapper from '../../../../../hooks/useFeatureFlagWrapper';

// style
import * as styles from './InPersonAppointmentsList.style';
import { Button } from '@betterpt/better-components';

const InPersonAppointmentsList = () => {
  const snackbar = useSnackbar();
  const filters = useInPersonAppointmentFilters();
  const { patientsSingularAlias, primaryColor } = useRemoteConfigAliases();
  const meQuery = useMeQuery();
  const match = useRouteMatch();
  const history = useHistory();
  const params = useParams() as any;
  const featureFlagWrapper = useFeatureFlagWrapper();

  const patientId = params?.patientId;
  const pagesize = patientId ? 7 : 12;

  const isBasic = meQuery.data?.me?.role === Role.Self;
  const inPersonAppointmentsInput = {
    limit: pagesize,
    offset: filters.offset.value,
    order: filters.dateOrder.value,
    start: filters.start.value,
    end: filters.end.value,
    appointmentStatuses: filters.appointmentStatuses.value,
    createdAtStart: filters.createdAtStart.value,
    createdAtEnd: filters.createdAtEnd.value,
    clinicIds: filters.clinicIds.value,
    employeeIds: filters.employeeIds.value,
    contactIds: filters.contactIds.value,
    assignedEmployeeIds: featureFlagWrapper(filters.assignedEmployeeIds?.value, Flags.AssignAppointments),
    isAssigned: featureFlagWrapper(filters.isAssigned?.value, Flags.AssignAppointments),
  };
  const basicInPersonInput = {
    limit: pagesize,
    offset: 0,
    order: filters.dateOrder.value,
    start: filters.start.value,
    end: filters.end.value,
    createdAtStart: filters.createdAtStart.value,
    createdAtEnd: filters.createdAtEnd.value,
    clinicIds: filters.clinicIds.value,
    appointmentStatuses: filters.appointmentStatuses.value,
    employeeIds: [meQuery.data?.me?.id ?? '0'],
    contactIds: filters.contactIds.value,
  };

  const patientAppointmentsInput = {
    limit: pagesize,
    offset: filters?.offset.value ?? 0,
    contactIds: [patientId],
    order: Order.Desc,
  };
  const basicPatientInput = {
    limit: 1,
    offset: 0,
    employeeIds: [meQuery.data?.me?.id ?? '0'],
    contactIds: [patientId],
  };

  const determineQueryInput = () => {
    if (isBasic && patientId) {
      return basicPatientInput;
    } else if (isBasic && !patientId) {
      return basicInPersonInput;
    } else if (!isBasic && patientId) {
      return patientAppointmentsInput;
    } else if (!isBasic && !patientId) {
      return inPersonAppointmentsInput;
    } else {
      return basicInPersonInput;
    }
  };

  const inPersonAppointmentsQuery = useInPersonAppointmentsQuery({
    variables: {
      input: determineQueryInput(),
    },
    onError: (e) => {
      if (e.message === 'Employee does not have access to this clinic!') {
        snackbar?.openSnackbar({
          isError: true,
          message: 'You do not have access to one or more clinics associated with this filter',
        });
      } else {
        snackbar?.openSnackbar({ isError: true, message: e.message });
      }
    },
    fetchPolicy: 'cache-and-network',
    skip: !filters.filtersHaveBeenSet,
  });
  const totalAppointmentsQuery = useTotalInPersonAppointmentsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !filters.filtersHaveBeenSet,
  });

  // Handle Errors in query
  const queryError = meQuery.error || totalAppointmentsQuery.error;

  React.useEffect(() => {
    if (queryError?.message && queryError?.message !== 'Not permitted!') {
      snackbar?.useGenericErrorMessage();
    } else if (queryError?.message) {
      snackbar?.setErrorMessage(queryError.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryError]);

  const loading = meQuery.loading || inPersonAppointmentsQuery.loading;
  const totalCount = inPersonAppointmentsQuery.data?.inPersonAppointments?.pager?.total;
  const noAppointmentsFound = !inPersonAppointmentsQuery.data?.inPersonAppointments?.pager?.total && !loading;
  const noAppointmentsEver =
    !totalAppointmentsQuery.data?.inPersonAppointments?.pager?.total && !totalAppointmentsQuery.loading && filters.filtersHaveBeenSet;
  const appointments = inPersonAppointmentsQuery?.data?.inPersonAppointments?.result ?? [];

  const pushToNoAppointmentsPage = !loading && noAppointmentsEver && !window.location.pathname.includes('no-appointments');

  React.useEffect(() => {
    if (pushToNoAppointmentsPage) {
      if (!patientId) history.push(`${match.path}/no-appointments`);
    }
  });

  const handlePageChange = (pageSize: number, idx: number, activeSection: number) => {
    filters.offset.update(idx * pageSize);
    filters.defaultTableSection.update(activeSection);
    filters.defaultPageIndex.update(idx);
    window.scrollTo(0, 0);
  };

  const handleChangingAppointmentOptions = () =>
    inPersonAppointmentsQuery.refetch({
      input: inPersonAppointmentsInput,
    });

  const handleAddAppointmentClick = () => history.push(`/appointments/new`);

  return (
    <styles.InPersonAppointmentContainer>
      {!patientId && (
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button style={styles.addAppointmentsButton(primaryColor)} size="large" onClick={handleAddAppointmentClick}>
              ADD APPOINTMENT
            </Button>
          </div>
          <Filters />
        </>
      )}

      {patientId ? (
        noAppointmentsFound ? (
          <NoAppointmentsView subject={`this ${patientsSingularAlias.toLowerCase()}`} />
        ) : (
          <InPersonAppointmentsTable
            onChangePage={handlePageChange}
            isLoading={loading}
            pagesize={12}
            totalCount={totalCount ?? 0}
            appointmentsQuery={inPersonAppointmentsQuery}
            onDoneChangingOptionsAppointment={handleChangingAppointmentOptions}
          />
        )
      ) : (
        <Switch>
          <Route path={match.path} exact>
            <Redirect to={`${match.path}/${meQuery.data?.me?.role === Role.Owner ? 'list' : 'details'}`} exact />
          </Route>
          <Route path={`${match.path}/list`} exact>
            <InPersonAppointmentsTable
              onChangePage={handlePageChange}
              isLoading={loading || pushToNoAppointmentsPage}
              pagesize={12}
              totalCount={totalCount ?? 0}
              appointmentsQuery={inPersonAppointmentsQuery}
              onDoneChangingOptionsAppointment={handleChangingAppointmentOptions}
            />
          </Route>
          <Route path={`${match.path}/details`} exact>
            <InPersonAppointmentsCardView
              onChangePage={handlePageChange}
              appointments={appointments}
              isLoading={loading || pushToNoAppointmentsPage}
              totalCount={totalCount ?? 0}
              pagesize={12}
              onDoneChangingOptionsAppointment={handleChangingAppointmentOptions}
            />
          </Route>
          <Route path={`${match.path}/no-appointments`} exact>
            <NoAppointmentsView />
          </Route>
        </Switch>
      )}
    </styles.InPersonAppointmentContainer>
  );
};

export default InPersonAppointmentsList;
