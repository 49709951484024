import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='24'
    viewBox='0 0 25 24'
    {...rest}
  >
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0L24 0 24 24 0 24z' transform='translate(.43)' />
      <path
        stroke={color || '#2A4E82'}
        strokeWidth='2'
        d='M16.09 10.045c1.076 1.152 1.076 2.94 0 4.091l-6.363 6.819c-.984 1.054-2.636 1.11-3.69.127-.045-.041-.087-.084-.128-.127-1.075-1.152-1.075-2.94 0-4.091l6.364-6.819c.984-1.054 2.636-1.11 3.69-.127.045.041.087.084.128.127z'
        transform='translate(.43)'
      />
      <path
        stroke={color || '#2A4E82'}
        strokeWidth='2'
        d='M18.818 4.045c1.13 1.06 1.187 2.834.128 3.964-.041.043-.084.086-.128.127l-7.273 6.819c-1.227 1.15-3.136 1.15-4.363 0-1.13-1.06-1.187-2.834-.128-3.964.041-.043.084-.086.128-.127l7.273-6.819c1.227-1.15 3.136-1.15 4.363 0z'
        transform='translate(.43)'
      />
    </g>
  </svg>
);
