import styled from '@emotion/styled';
import { colorValues, bestTextColor } from '@betterpt/better-components';

export const CardSection = styled.div`
  padding: 25px;
  border-bottom: 1px solid ${colorValues.concretejungle};
  position: relative;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const subHeaderRowStyle = {
  marginBottom: '21px',
};

export const recurringButtonStyle = {
  color: colorValues.eveningsky,
  border: `2px solid ${colorValues.eveningsky}`,
  backgroundColor: colorValues.emptiness,
  marginLeft: '192px',
};

export const CalendarStyleWrapper = styled.div<{ primaryColor: string }>`
  .MuiButton-root {
    color: ${({ primaryColor }) => bestTextColor(primaryColor)};
  }
  .MuiPickersDay-daySelected {
    color: ${({ primaryColor }) => bestTextColor(primaryColor)};
  }
  .MuiPickersClockNumber-clockNumberSelected {
    color: ${({ primaryColor }) => bestTextColor(primaryColor)};
  }
  .MuiPickersToolbarText-toolbarBtnSelected {
    color: ${({ primaryColor }) => bestTextColor(primaryColor)};
  }
`;
