import styled from '@emotion/styled';

export const RootComponent = styled.div`
  text-align: center;
  color: ${(props) => props.color};
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
`;
