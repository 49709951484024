import React from 'react';
import { Route } from 'react-router-dom';

//types
import { RouteType } from './types';

export const renderRoutes = (routes: RouteType[]) => {
  return routes
    .filter((route) => !!route.path)
    .map((route) => {
      return [
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          children={route.component}
        />,
      ];
    });
};
