import React, { Fragment } from 'react';

// helpers
import { Roles } from '../../../../../../helpers/rbac-rules';
import { format } from 'date-fns';

// hooks
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import { useRouteMatch } from 'react-router-dom';
import {
  useEmployeeQuery,
  useMeQuery,
} from '../../../../../../generated/graphql';
import { ResetEmployeeInvitePayload } from '../../hooks/useEmployeeOperations';
import useHandleError from '../../../../../../hooks/useHandleError';

//components
import { GridCard } from '../../../../../Shared/GridCard';
import { Header, Button, Animation } from '@betterpt/better-components';
import ThemedTextField from '../../../../../Shared/ThemedTextField';
import Can from '../../../../../Can';

//assets
import { IcSuccessCircle } from '@betterpt/better-icons';

//styles
import { colorValues } from '@betterpt/better-components';
import { buttonStyle, FormBody, H4 } from '../../styles/EmployeeDetail.style';

interface Props {
  resetEmployeeInvite: (payload: ResetEmployeeInvitePayload) => Promise<void>;
}

const LoginStatus = ({ resetEmployeeInvite }: Props) => {
  //hooks
  const { primaryColor } = useRemoteConfigAliases();
  const {
    params: { employeeId },
  } = useRouteMatch<{ employeeId: string }>();

  const handleError = useHandleError();
  const snackbar = useSnackbar();
  const meQuery = useMeQuery();
  const queryEmployee = useEmployeeQuery({
    variables: { id: employeeId },
    skip: !employeeId,
  });
  const employee = queryEmployee?.data?.employee;

  const [loginStatus, updateLoginStatus] = React.useState('');
  const [sendingInvite, updateSendinginvite] = React.useState(false);

  React.useEffect(() => {
    if (employee) {
      if (employee?.hasLoggedIn) {
        updateLoginStatus('User has logged in successfully');
      } else if (employee?.lastEmailInviteSentAt) {
        updateLoginStatus(
          `User has not logged in, Last invite sent: ${format(
            new Date(employee?.lastEmailInviteSentAt),
            'MM/dd h:m a'
          )}`
        );
      } else {
        updateLoginStatus('User has not been sent an email invite');
      }
    }
  }, [employee]);

  const allowResendInvite =
    queryEmployee?.called &&
    !queryEmployee?.loading &&
    !queryEmployee?.data?.employee?.hasLoggedIn;

  const fetching = queryEmployee?.loading;
  const shouldHideResendButton = queryEmployee.data?.employee?.isManagedViaYext;

  const handleResend = async () => {
    updateSendinginvite(true);
    try {
      await resetEmployeeInvite({ employeeId });
      snackbar?.setSuccessMessage('Invite sent');
    } catch (e) {
      handleError(e);
    }
    updateSendinginvite(false);
  };

  return (
    <GridCard>
      {fetching ? (
        <Animation type='providerAppLoader' />
      ) : (
        <Fragment>
          <Header underline style={{ padding: '22px 23px 13px 42px' }}>
            <H4>Login Status</H4>
            {shouldHideResendButton ? null : (
              <Can
                role={Roles[meQuery.data?.me?.role || 'initial']}
                perform='employee:edit'
                data={{
                  targetRole: queryEmployee?.data?.employee?.role,
                }}
                yes={() => (
                  <>
                    {allowResendInvite && (
                      <Button
                        size='small'
                        color='transparent'
                        onClick={handleResend}
                        loading={sendingInvite}
                        style={{
                          ...buttonStyle(primaryColor, true),
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                      >
                        RESEND INVITE
                      </Button>
                    )}
                  </>
                )}
              />
            )}
          </Header>
          <FormBody>
            <ThemedTextField
              value={loginStatus}
              label='Invite / Login Status'
              disabled
              fullWidth
              InputProps={{
                endAdornment: employee?.hasLoggedIn && (
                  <IcSuccessCircle color={colorValues.guajirogreen} />
                ),
              }}
            />
          </FormBody>
        </Fragment>
      )}
    </GridCard>
  );
};

export default LoginStatus;
