import { colorValues } from '@betterpt/better-components';
import SomatusBig from '../assets/companyLogos/SomatusFull.svg';
import SomatusSmall from '../assets/companyLogos/SomatusSmall.png';
import AcornBig from '../assets/companyLogos/AcornFull.svg';
import AcornSmall from '../assets/companyLogos/AcornSmall.svg';
import BetterAccessFullLogoNoTurtle from '../../src/assets/companyLogos/BetterAccessFullNoTurtle.svg';
import WebPTLogo from '../assets/companyLogos/WebPTlogo.svg';

import { Company } from '../generated/graphql';

export type LogoAndColorConfigKey = 'somatus' | 'acorn' | 'betterhealthcare';

type LogoAndColorValues = {
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  textFieldColor: string;
  organizationUrl: any;
  organizationSmallUrl: any;
};

export const determineConfigKey = (
  company?: Company | null
): LogoAndColorConfigKey => {
  if (!company) {
    return 'betterhealthcare';
  } else if (company.providerAppId === 'somatus') {
    return 'somatus';
  } else if (company.providerAppId === 'acorn') {
    return 'acorn';
  } else {
    return 'betterhealthcare';
  }
};

export default new Map<LogoAndColorConfigKey, LogoAndColorValues>([
  [
    'somatus',
    {
      primaryColor: '#083050',
      secondaryColor: '#2f9247',
      tertiaryColor: colorValues.frost,
      textFieldColor: '#083050',
      organizationUrl: SomatusBig,
      organizationSmallUrl: SomatusSmall,
    },
  ],
  [
    'acorn',
    {
      primaryColor: '#235d6f',
      secondaryColor: '#aaca7e',
      tertiaryColor: colorValues.frost,
      textFieldColor: '#235d6f',
      organizationUrl: AcornBig,
      organizationSmallUrl: AcornSmall,
    },
  ],
  [
    'betterhealthcare',
    {
      primaryColor: colorValues.ibizateal,
      secondaryColor: colorValues.frenchblue,
      tertiaryColor: colorValues.frost,
      textFieldColor: colorValues.ibizateal,
      organizationUrl: BetterAccessFullLogoNoTurtle,
      organizationSmallUrl: WebPTLogo,
    },
  ],
]);
