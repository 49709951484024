import React from 'react';

export default ({
  color,
  secondaryColor,
  ...rest
}: { color?: string; secondaryColor?: string } & React.SVGProps<
  SVGSVGElement
>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 296.999 296.999'
    {...rest}
  >
    <g fill={color ?? '#919191'} fillOpacity='1'>
      <g fill={color ?? '#919191'} fillOpacity='1'>
        <path
          d='M228.205,197.771c-17.471,0-32.85,9.085-41.692,22.772l-43.697-28.849c9.465-11.862,15.142-26.873,15.142-43.194 c0-16.32-5.678-31.332-15.143-43.194l43.697-28.849c8.842,13.686,24.222,22.772,41.692,22.772 c27.358,0,49.615-22.257,49.615-49.615C277.82,22.257,255.563,0,228.205,0c-27.357,0-49.614,22.257-49.614,49.614 c0,2.577,0.198,5.109,0.579,7.581l-51.512,34.009c-11.14-7.625-24.601-12.095-39.09-12.095c-38.262,0-69.391,31.129-69.391,69.391 c0,38.262,31.129,69.39,69.391,69.39c14.489,0,27.95-4.47,39.091-12.095l51.513,34.009c-0.381,2.472-0.579,5.004-0.579,7.581 c0,27.357,22.257,49.614,49.614,49.614c27.358,0,49.615-22.257,49.615-49.614C277.82,220.029,255.563,197.771,228.205,197.771z M228.205,20.119c16.264,0,29.495,13.231,29.495,29.494s-13.232,29.495-29.495,29.495s-29.494-13.232-29.494-29.495 S211.942,20.119,228.205,20.119z M88.567,197.771c-27.169,0-49.272-22.102-49.272-49.271s22.103-49.272,49.272-49.272 c27.169,0,49.272,22.103,49.272,49.272C137.839,175.668,115.736,197.771,88.567,197.771z M228.205,276.881 c-16.264,0-29.494-13.231-29.494-29.494c0-16.263,13.231-29.495,29.494-29.495s29.495,13.232,29.495,29.495 S244.469,276.881,228.205,276.881z'
          fill={color ?? '#333333'}
          fillOpacity='1'
        />
      </g>
    </g>
  </svg>
);
