import * as React from 'react';

//hooks
import useFeatureAccessCheck from '../../../../hooks/useFeatureAccessCheck';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';

//components
import BlockedPageMessage from '../../../Shared/BlockedPageMessage';

//assets
import {
  ImgBrunetteGirlRedSuitComputer,
  ImgBlondeGirlBlueSuitComputer,
} from '@betterpt/better-icons';

const NoFacilitiesFound = ({
  resetFilters,
}: {
  isNoResultsPage?: boolean;
  resetFilters?: () => void;
}) => {
  const {
    facilitiesSingularAlias,
    facilitiesPluralAlias,
    loading: remoteConfigLoading,
  } = useRemoteConfigAliases();
  const {
    loading: companyFeaturesLoading,
  } = useFeatureAccessCheck();
  const loading = remoteConfigLoading || companyFeaturesLoading;

  const generateBlockPageCopy = () => {
    let icon = <ImgBrunetteGirlRedSuitComputer />;
    let title = `No ${facilitiesPluralAlias} Yet`;
    let message = `Your company doesn't have any ${facilitiesPluralAlias.toLowerCase()} yet. Please contact your company owner or administrator and have them add your ${facilitiesPluralAlias.toLowerCase()} to the platform.`;
    let buttonText = '';
    const pathname = '';
    let onClick: (() => void) | undefined = undefined;

    icon = <ImgBlondeGirlBlueSuitComputer />;
    title = `No ${facilitiesPluralAlias.toLowerCase()} found matching these filters`;
    message = 'Try resetting your filters by clicking the button below';
    buttonText = `RESET ${facilitiesSingularAlias.toUpperCase()} FILTERS`;
    onClick = resetFilters;

    return {
      icon,
      title,
      message,
      buttonText,
      pathname,
      onClick,
    };
  };
  const blockPageCopy = generateBlockPageCopy();

  return loading ? null : (
    <BlockedPageMessage
      icon={blockPageCopy.icon}
      title={blockPageCopy.title}
      message={blockPageCopy.message}
      hideButton={true}
      pathname={blockPageCopy.pathname}
      onClick={blockPageCopy.onClick}
      style={{ paddingBottom: 50 }}
      wideText
    />
  );
};

export default NoFacilitiesFound;
