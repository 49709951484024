import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
  >
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0L24 0 24 24 0 24z' />
      <path
        fill={color || '#056B59'}
        fillRule='nonzero'
        d='M12 1c6.072 0 11 4.928 11 11s-4.928 11-11 11S1 18.072 1 12 5.928 1 12 1zm6.133 5.658l-8.521 8.47-3.475-3.474-1.487 1.521 4.962 4.956 10-10-1.479-1.473z'
      />
    </g>
  </svg>
);
