import * as React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <div className='section'>
        <h1>Privacy Policy</h1>
        <p>Effective Date: June 14, 2016</p>
        <p>
          At BetterPT, we are committed to protecting your privacy and we take
          great care with your information. This policy will help you understand
          how we use and protect your data. If you have any questions, feel free
          to contact us at support@betterhealthcare.co. You can also learn more
          at BetterPT.com/privacy policy. Thank you so much for choosing
          BetterPT.
        </p>
        <h2>1. Introduction</h2>
        <p>
          BetterPT, Inc. (“us,” “we,” or “BetterPT”) is committed to respecting
          the privacy rights of our customers, visitors, and other users of the
          BetterPT mobile application (the “Mobile App”) and related websites,
          applications and services provided by BetterPT and on/in which this
          Privacy Policy is posted or referenced (collectively, the “Services”).
          We created this Privacy Policy (“Privacy Policy”) to give you
          confidence as you use the Services and to demonstrate our commitment
          to the protection of privacy. This Privacy Policy is only applicable
          to the Services. This Privacy Policy does not apply to any other
          website or digital service that you may be able to access through the
          Services or any website or digital services of betterPT’s business
          partners, each of which may have data collection, storage and use
          practices and policies that may materially differ from this Privacy
          Policy. Your use of the Services is governed by this Privacy Policy
          and the Agreement (as the term “Agreement” is defined in our Terms of
          Use). Any capitalized term used but not defined in this Privacy Policy
          shall have the meaning in the Agreement.
        </p>
        <p>
          BY USING THE SERVICES, YOU AGREE TO THE PRACTICES AND POLICIES
          OUTLINED IN THIS PRIVACY POLICY AND YOU HEREBY CONSENT TO THE
          COLLECTION, USE, AND SHARING OF YOUR INFORMATION AS DESCRIBED IN THIS
          PRIVACY POLICY. IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY, YOU
          CANNOT USE THE SERVICES. IF YOU USE THE SERVICES ON BEHALF OF SOMEONE
          ELSE (SUCH AS YOUR CHILD), YOU REPRESENT THAT YOU ARE AUTHORIZED BY
          SUCH INDIVIDUAL OR ENTITY TO ACCEPT THIS PRIVACY POLICY ON SUCH
          INDIVIDUAL’S BEHALF.
        </p>
        <h2>2. Information We Collect</h2>
        <h3>2.1 Personal Information</h3>
        <h4>2.1.1 Personal Information Generally</h4>
        <p>
          Some of the Services require us to know more about you so that we can
          best meet your needs. When you access these Services, we may ask you
          to voluntarily provide us certain information that personally
          identifies (or could be used to personally identify) you (“Personal
          Information”). Personal Information includes (but is not limited to)
          the following categories of information: (1) contact data (such as
          your e-mail address and phone number); (2) demographic data (such as
          your gender, your date of birth and your zip code); (3) insurance data
          (such as your insurance carrier, insurance plan, member ID, group ID
          and payer ID)] (4) Protected Health Information (as defined below),
          including your patient data (such as the physical therapists
          (“Physical Therapists”) or other healthcare providers you have
          visited, your reasons for visit, your dates of visit, your medical
          history, and other medical and health information you choose to share
          with us), and (5) other information that you voluntarily choose to
          provide to us, unique identifiers such as passwords, and Personal
          Information in emails or other communications that you send to us.
        </p>
        <h4>2.1.2 Billing, Collection and Payment Information</h4>
        <p>
          When you make a payment through our Services to a Physical Therapist
          (as further described in and subject to other provisions of the
          Agreement), your payment card information is processed by our payment
          processing partner Braintree, a Paypal company. Braintree collects
          your voluntarily provided payment card information in order to process
          your payments in connection with your use of the Services. Braintree’s
          use and storage of information it collects is governed by Braintree’s
          applicable terms of service and privacy policy. The information we
          store may include your payment card type and the last four digits of
          the payment card. We may provide to you the option to remove your
          stored payment card information through your account settings page.
        </p>
        <h3>2.2 Traffic Data</h3>
        <p>
          We also may automatically collect certain data when you use the
          Services, such as (1) IP address; (2) domain server; (3) type of
          device(s) used to access the Services; (4) web browser(s) used to
          access the Services; (5) referring webpage or other source through
          which you accessed the Services; (6) geolocation information; and (7)
          other statistics and information associated with the interaction
          between your browser or device and the Services (collectively “Traffic
          Data”). Depending on applicable law, some Traffic Data may be Personal
          Information.
        </p>
        <p>
          We may also collect additional information, which may be Personal
          Information, as otherwise described to you at the point of collection
          or pursuant to your consent.
        </p>
        <h3>2.3 HIPAA and PHI</h3>
        <p>
          Under a federal law called the Health Insurance Portability and
          Accountability Act (“HIPAA”), some of the demographic, health and/or
          health-related information that BetterPT collects as part of providing
          the Services may be considered “protected health information” or
          “PHI”. Specifically, when BetterPT receives identifiable information
          about you from or on behalf of your Physical Therapist, this
          information is PHI. HIPAA provides specific protections for the
          privacy and security of PHI and restricts how PHI is used and
          disclosed. In addition, state privacy laws may further protect certain
          categories of sensitive PHI. BetterPT may only use and disclose your
          PHI in the ways permitted by your Physical Therapist(s). In addition,
          you have been or will be asked to e-sign the BetterPT HIPAA
          Authorization (the “Authorization”). While your decision to e-sign the
          Authorization is entirely voluntary, if you do not e-sign the
          Authorization, you are not authorized to use the Services. If you
          choose to e-sign the Authorization, you agree that BetterPT may use
          and disclose your PHI in the same way it uses and discloses your
          Personal Information that is not PHI. These uses and disclosures are
          described in this Privacy Policy. To the extent any provision in the
          Authorization is inconsistent with this Privacy Policy or other
          provisions of the Agreement, then the provision in the Authorization
          only controls with respect to your PHI.{' '}
        </p>
        <h2>3. How We Collect Information</h2>
        <p>
          We collect information (including Personal Information and Traffic
          Data) when you use and interact with the Services, and in some cases
          from third party sources. Such information includes:
        </p>
        <ul>
          <li>
            When you use the Services’ interactive tools and services, such as
            searching for Physical Therapists, searching for available
            appointments with Physical Therapists and completing medical history
            forms (“Case History Forms”) prior to Physical Therapist
            appointments;
          </li>
          <li>
            When you voluntarily provide information in free-form text boxes
            through the Services and through responses to surveys,
            questionnaires and the like;
          </li>
          <li>
            When you voluntarily provide information to your Physical Therapist,
            your Physical Therapist may input such information into the
            Services;{' '}
          </li>
          <li>
            If you download and install certain applications and software we
            make available, we may receive and collect information transmitted
            from your computing device for the purpose of providing you the
            relevant Services, such as information that lets BetterPT know when
            you are logged on and available to receive update or alert notices;
          </li>
          <li>
            If you download our mobile application, we require that your device
            settings allow us to receive information about your location
            (including your geolocation) and mobile device;
          </li>
          <li>
            Through cookies, web beacons, website analytics services and other
            tracking technology (collectively, “Tracking Tools”), as described
            below; and
          </li>
          <li>
            When you use the “Contact Us” function on the Mobile App, send us an
            email or otherwise contact us.
          </li>
        </ul>
        <h2>4. Tracking Tools and “Do Not Track”</h2>
        <h3>4.1. Tracking Tools</h3>
        <p>
          We may use tools outlined below in order to better understand users.
        </p>
        <ul>
          <li>
            Cookies: “Cookies” are small computer files transferred to your
            computing device that contain information such as user ID, user
            preferences, lists of pages visited and activities conducted while
            using the Services. We use Cookies to help us improve or tailor the
            Services by tracking your navigation habits, storing your
            authentication status so you do not have to re-enter your
            credentials each time you use the Services, customizing your
            experience with the Services and for analytics and fraud prevention.
            Cookies used by our business partners may collect information when
            you use the Services, such as the IP address, mobile device ID,
            operating system, browser, web page interactions, the geographic
            location of your internet service provider and demographic
            information, such as gender and age range. These Cookies help
            BetterPT learn more about our users’ demographics and internet
            behaviors. For more information on cookies, visit http://
            www.allaboutcookies.org.
          </li>
          <li>
            Web Beacons: “Web Beacons” (a.k.a. clear GIFs or pixel tags) are
            tiny graphic image files imbedded in a web page or email that may be
            used to collect anonymous information about your use of our
            Services, and the emails, special promotions or newsletters that we
            send you. The information collected by Web Beacons allows us to
            analyze how many people are using the Services or opening our
            emails, and for what purpose.
          </li>
          <li>
            Website Analytics: We may use third-party website analytics services
            in connection with the Services, including, for example, to register
            mouse clicks, mouse movements, scrolling activity and text that you
            type into the Mobile App. These website analytics services generally
            do not collect Personal Information unless you voluntarily provide
            it and generally do not track your browsing habits across websites
            which do not use their services. We use the information collected
            from these services to help make the Services easier to use and as
            otherwise set forth in Section 6 (Use of Information).
          </li>
          <li>
            Mobile Device Identifiers: Mobile device identifiers are data stored
            on your mobile device that may track mobile device and data and
            activities occurring on and through it, as well as the applications
            installed on it. Mobile device identifiers enable collection of
            Personal Information (such as media access control, address and
            location) and Traffic Data. As with other Tracking Tools, mobile
            device identifiers help BetterPT learn more about our users’
            demographics and internet behaviors.
          </li>
        </ul>
        <h3>
          4.2. Options for Opting out of Cookies and Mobile Device Identifiers
        </h3>
        <p>
          Some web browsers (including some mobile web browsers) allow you to
          reject Cookies or to alert you when a Cookie is placed on your
          computer, tablet or mobile device. You may be able to reject mobile
          device identifiers by activating the appropriate setting on your
          mobile device. Although you are not required to accept betterPT’s
          Cookies or mobile device identifiers, if you block or reject them, you
          may not have access to all features available through the Services.
        </p>
        <p>
          You may opt out of receiving certain Cookies and certain trackers by
          visiting the Network Advertising Initiative (NAI) opt out page or the
          Digital Advertising Alliance (DAA) consumer opt-out page. When you use
          these opt-out features, an “opt-out” Cookie will be placed on your
          computer or tablet indicating that you do not want to receive
          interest-based advertising from NAI or DAA member companies. If you
          delete Cookies on your computer or tablet, you may need to opt out
          again.{' '}
        </p>
        <h3>4.3. How BetterPT Responds to Browser “Do Not Track” Signals</h3>
        <p>
          Some web browsers (including Safari, Internet Explorer, Firefox and
          Chrome) incorporate a “Do Not Track” (DNT) or similar feature that
          signals to websites that a visitor does not want to have his/her
          online activity and behavior tracked. If a website operator elects to
          respond to a particular DNT signal, the website operator may refrain
          from collecting certain Personal Information about the browser’s user.
          Not all browsers offer a DNT option and there is currently no industry
          consensus as to what constitutes a DNT signal. For these reasons, many
          website operators, including BetterPT, do not take action to respond
          to DNT signals. For more information about DNT signals, visit http://
          allaboutdnt.com.
        </p>
        <h2>5. Use of Information</h2>
        <p>
          We use your information, including Personal Information, to provide
          the Services to you and to help improve them, including to:
        </p>
        <ul>
          <li>
            provide you with the products, services and information you request
            and respond to correspondence that we receive from you;
          </li>
          <li>
            provide, maintain, administer or expand the Services, perform
            business analyses, or for other internal purposes to support,
            improve or enhance our business, the Services, and other products
            and services we offer;
          </li>
          <li>
            notify you about certain resources or Physical Therapists we think
            you may be interested in learning more about;
          </li>
          <li>
            send you information about BetterPT or our products or Services;
          </li>
          <li>
            contact you when necessary or requested, including to remind you of
            an upcoming appointment;
          </li>
          <li>
            customize and tailor your experience of the Services, which may
            include sending customized messages or showing you Sponsored Results
            that may be of interest to you based on information collected in
            accordance with this Privacy Policy;
          </li>
          <li>
            send emails and other communications that display content that we
            think will interest you and according to your preferences;
          </li>
          <li>
            combine information received from third parties with the information
            that we have from or about you and use the combined information for
            any of the purposes described in this Privacy Policy;
          </li>
          <li>
            use statistical information that we collect in any way permitted by
            law, including from third parties in connection with their
            commercial and marketing efforts; and
          </li>
          <li>
            prevent, detect and investigate security breaches and potentially
            illegal or prohibited activities.
          </li>
        </ul>
        <p>
          We may use information that is neither Personal Information nor PHI
          (including non-PHI Personal Information that has been de-identified
          and/or aggregated) to better understand who uses BetterPT and how we
          can deliver a better healthcare experience, or otherwise at our
          discretion.
        </p>
        <h2>6. Disclosure of Information</h2>
        <p>We may disclose certain information that we collect from you:</p>
        <ul>
          <li>
            We will share your Personal Information with Physical Therapists
            with whom you choose to schedule through the Services. For example,
            if you complete a Case History Form using the Services in advance of
            an appointment, we may share your Case History Form with your
            Physical Therapists. In addition, if you see different Physical
            Therapists by scheduling through the Services, we may share one
            Physical Therapist’s notes with another Physical Therapist, to the
            extent you are a patient in common and such disclosure is consistent
            with your treatment needs.{' '}
          </li>
          <li>
            We may share your Personal Information with your Physical Therapists
            to enable them to refer you to and make appointments with other
            Physical Therapists on your behalf or to perform analyses on
            potential health issues or treatments, provided that you choose to
            use the applicable Services.
          </li>
          <li>
            We may share your Personal Information with Physical Therapists in
            the event of an emergency.
          </li>
          <li>
            We may share your Personal Information as part of an anonymized,
            aggregated data set, in which case the Personal Information will be
            de-identified.{' '}
          </li>
          <li>
            We may also share your Personal Information with organizations that
            collect, aggregate and organize your information so they can make it
            more easily accessible to your Physical Therapists.
          </li>
          <li>
            We do not sell email addresses to third parties. We may share your
            email address with our business partners to enable them to help
            BetterPT customize our advertising.
          </li>
          <li>
            We may share your Personal Information and Traffic Data with our
            service providers and business partners who perform core operational
            services (such as hosting, billing, fulfillment, data storage,
            security, insurance verification, or Website analytics set forth in
            Section 4.1 (Tracking Tools)) and/or by making certain features
            available to our users.
          </li>
          <li>
            For the purposes of determining eligibility and cost-sharing
            obligations, and otherwise obtaining benefit plan information, we
            may share with the insurance provider you identify to us (via our
            business partners) your insurance-related Personal Information.
          </li>
          <li>
            We may transfer your information to another company in connection
            with a merger, sale, acquisition or other change of ownership or
            control by or of BetterPT (whether in whole or in part). Should one
            of these events occur, we will make reasonable efforts to notify you
            before your information becomes subject to different privacy and
            security policies and practices.
          </li>
        </ul>
        <p>
          We also may need to disclose your Personal Information or any other
          information we collect about you if we determine in good faith that
          such disclosure is needed to: (1) comply with applicable law,
          regulation, court order or other legal process; (2) protect the
          rights, property or safety of BetterPT or another party; (3) enforce
          the Agreement or other agreements with you; or (4) respond to claims
          that any posting or other content violates third-party rights.
        </p>
        <p>
          We may disclose information that is neither Personal Information nor
          PHI (including non-PHI Personal Information that has been
          de-identified and/or aggregated) at our discretion.
        </p>
        <p>
          We store and process your information on our servers in the United
          States and abroad. We maintain industry standard backup and archival
          systems.
        </p>
        <h2>7. Public Information</h2>
        <p>
          Any information that you may reveal in a review posting or online
          discussion or forum is intentionally open to the public and is not in
          any way private. We recommend that you carefully consider whether to
          disclose any Personal Information in any public posting or forum. What
          you have written may be seen and/or collected by third parties and may
          be used by others in ways we are unable to control or predict.
        </p>
        <h2>8. Storage and Security of Information</h2>
        <p>
          The security of your Personal Information is important to us. We
          endeavor to follow generally accepted industry standards to protect
          the Personal Information submitted to us, both during transmission and
          in storage. For example, when you enter sensitive information on our
          Mobile App, we encrypt that information using secure socket layer
          technology.
        </p>
        <p>
          Although we make good faith efforts to store Personal Information in a
          secure operating environment that is not open to the public, we do not
          and cannot guarantee the security of your Personal Information. If we
          become aware that your Personal Information has been disclosed in a
          manner not in accordance with this Privacy Policy, we will use
          reasonable efforts to notify you of the nature and extent of the
          disclosure (to the extent we know that information) as soon as
          reasonably possible and as permitted or required by law.
        </p>
        <h2>9. Controlling Your Personal Information</h2>
        <p>
          If you are a registered user of the Services, you can modify some of
          the Personal Information you have included in your profile or change
          your username by logging in and accessing your account. If you wish to
          close your account, please email us at support@betterhealthcare.co.
          BetterPT will delete your account and the related information at your
          request as soon as reasonably possible. Please note, however, that
          BetterPT reserves the right to retain information from closed
          accounts, including to comply with law, prevent fraud, resolve
          disputes, enforce the Agreement and take other actions permitted by
          law.
        </p>
        <p>
          You must promptly notify us if any of your account data is lost,
          stolen or used without permission.
        </p>
        <h2>10. Information Provided by or on Behalf of Children</h2>
        <p>
          The Services are not intended for use by children and children are
          prohibited from using the Services. BetterPT does not knowingly
          collect any information from children, nor are the Services directed
          to children.
        </p>
        <p>
          By accessing, using and/or submitting information to or through the
          Services, you represent that you are not younger than age 13. If we
          learn that we have received any information directly from a child
          under age 13 without his/her parent’s written consent, we will use
          that information only to respond directly to that child (or his/her
          parent or legal guardian) to inform the child that he/she cannot use
          the Services and subsequently we will delete that information.
        </p>
        <p>
          If you are between age thirteen (13) and the age of majority in your
          place of residence, you may use the Services only with the consent of
          or under the supervision of your parent or legal guardian. If you are
          a parent or legal guardian of a minor child, you may, in compliance
          with the Agreement, use the Services on behalf of such minor child.
          Any information that you provide us while using the Services on behalf
          of your minor child will be treated as Personal Information as
          otherwise provided herein.
        </p>
        <h2>11. Links to Other Websites</h2>
        <p>
          The Services contain links to third party websites with which BetterPT
          has no affiliation. A link to a non-BetterPT website does not mean
          that we endorse that website, the quality or accuracy of information
          presented on the non-BetterPT website or the persons or entities
          associated with the non-BetterPT website. If you decide to visit a
          third party website, you are subject to the privacy policy of the
          third party website as applicable and we are not responsible for the
          policies and practices of the third party website. We encourage you to
          ask questions before you disclose your information to others.
        </p>
        <h2>12. Updates and Changes to Privacy Policy</h2>
        <p>
          The effective date of this Privacy Policy is set forth at the top of
          this webpage. We will notify you of any material change by posting
          notice on this webpage. Your continued use of the Services after the
          effective date constitutes your acceptance of the amended Privacy
          Policy. We encourage you to periodically review this page for the
          latest information on our privacy practices. The amended Privacy
          Policy supersedes all previous versions. IF YOU DO NOT AGREE TO FUTURE
          CHANGES TO THIS PRIVACY POLICY, YOU MUST STOP USING THE SERVICES AFTER
          THE EFFECTIVE DATE OF SUCH CHANGES.
        </p>
        <h2>13. Contacts</h2>
        <p>
          If you have any comments, concerns or questions about this Privacy
          Policy, please contact us at support@betterhealthcare.co.
        </p>
      </div>
      <style>
        {`
      .section {
        width: 900px;
        text-align: left;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 100px;
      }

      .logo {
        text-align: center;
        padding-top: 15px;
      }

      .section h1,
      .section h2,
      .section h3,
      .section h4 {
        color: #00207f;
      }

      .section h1 {
        font-size: 30px;
        line-height: 33px;
      }

      .section h2 {
        font-size: 24px;
        line-height: 28px;
      }

      .section h3 {
        font-size: 22px;
        line-height: 28px;
      }

      .section h4 {
        font-size: 20px;
        line-height: 28px;
      }

      .section ul {
        list-style-type: circle;
        margin-left: 20px;
        margin-bottom: 20px;
      }

      `}
      </style>
    </div>
  );
};

export default PrivacyPolicy;
