import React from 'react';

import useRemoteConfigAliases from '../../../hooks/useRemoteConfigAliases';
import { bestTextColor } from '@betterpt/better-components';
import { IcArrowLeft } from '@betterpt/better-icons';
import { useHistory } from 'react-router-dom';
import { NavSection } from './CreateFormNav.style';

const CreateFormNav = ({ title }: { title: string }) => {
  const history = useHistory();
  const { primaryColor } = useRemoteConfigAliases();

  return (
    <NavSection primaryColor={primaryColor}>
      <IcArrowLeft
        style={{ marginLeft: 44, marginRight: 80, cursor: 'pointer' }}
        onClick={() => history.goBack()}
        color={bestTextColor(primaryColor)}
      />
      <h4>{title}</h4>
    </NavSection>
  );
};

export default CreateFormNav;
