import { colorValues } from '@betterpt/better-components';
import styled from '@emotion/styled';

export const PatientDetailContainer = styled.div`
  padding: 35px 28px;
`;

export const PatientDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  h1 {
    margin: 0 15px 0 0;
  }
`;

export const breadCrumbsLink = {
  textDecoration: 'underline',
  color: colorValues.frenchblue,
};

export const breadCrumbsLinkItem = {
  color: colorValues.frenchblue,
  fontWeight: 'normal' as 'normal',
  fontSize: 16,
  lineHeight: 1.25,
};

export const betterAccessPatientButton = {
  backgroundColor: colorValues.emptiness,
  color: colorValues.betterptblack,
  borderRadius: '23.5px',
  boxShadow:
    '0 0 4px 0 rgba(51, 51, 51, 0.47), 0 2px 2px 0 rgba(51, 51, 51, 0.24)',
  justifyContent: 'space-between',
  width: '100%',
};

export const betterAccessPatientButtonContainer = {
  maxWidth: '650px',
  width: '100%',
};

export const LinkedPatientText = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 25px;
  }
`;

export const InfoHeader = styled.div`
  background-color: ${colorValues.earlysteam};
  padding: 10px 10px 10px 39px;
  border-bottom: solid 1px ${colorValues.concretejungle};
  border-radius: 5px 5px 0px 0px;
  p {
    color: ${colorValues.charcoalgray};
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
`;

export const StackedCards = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 48%;
  margin: 20px 28px;
`;

export const cardStyle = {
  marginRight: '25px',
  width: '504px',
  position: 'relative' as 'relative',
  borderRadius: '5px',
  margin: '20px 28px',
  border: `1px solid ${colorValues.dust}`,
  boxShadow: 'none !important',
};

export const CardContents = styled.div`
  padding: 15px 15px 20px 40px;
  min-height: 213px;
`;

export const InsuranceImageContainer = styled.div<{ isPlaceholder?: boolean }>`
  display: flex;
  img {
    width: ${({ isPlaceholder }) => (isPlaceholder ? '100%' : '98%')};
    height: ${({ isPlaceholder }) => (isPlaceholder ? '70%' : 'auto')};
    cursor: pointer;
    margin-right: 50px;
    border-radius: 5px;
  }
  p {
    color: ${colorValues.charcoalgray};
    margin-bottom: 23px;
  }
`;

export const TextFieldRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
