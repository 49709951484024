import * as React from 'react';

//components 
import { GridCard } from '../../../../Shared/GridCard';
import CardTextField from '../CardTextField';

//types
import { VideoAppointment, Appointment } from '../../../../../generated/graphql';

//style
import * as styles from './IndustrySpecificQuestionsPT.style';

type Props = {
    appointment: VideoAppointment | Appointment
}

const IndustrySpecificQuestionsPT = ({ appointment }: Props) => {
    const referralStatus = appointment?.isDirectAccess
        ? 'Direct Access'
        : 'Referral';
    const referringPhysician = appointment?.isDirectAccess
        ? 'N/A'
        : appointment?.referredBy ?? 'Not Provided';


    return (
        <GridCard style={styles.cardStyle} shadow='none'>
            <styles.InfoHeader>
                <p>Industry Specific Qualifying Questions</p>
            </styles.InfoHeader>
            <styles.CardContents>
                <styles.TextFieldRow>
                    <CardTextField label='Referral' value={referralStatus} />
                    {!appointment?.isDirectAccess && <CardTextField label='Physician Name' value={referringPhysician} />}
                </styles.TextFieldRow>
            </styles.CardContents>
        </GridCard>
    );
};

export default IndustrySpecificQuestionsPT;