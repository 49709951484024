import React from 'react';
import { NavLink } from 'react-router-dom';
// types
import { RouteType } from './types';
import { Tab } from './styles';

type Props = {
  routes: RouteType[];
  isMainNavOpen: boolean;
  onLinkClick: () => void;
};

export default ({ routes, isMainNavOpen, onLinkClick }: Props) => (
  <ul style={{ marginBottom: 10 }}>
    {routes.map((route, idx) => {
      if (!route.path) {
        // if you don't provide a path, it will just render the item, but with no link provided.
        // You can also give it an onclick handler if youwant
        return (
          <Tab
            onClick={() => {
              onLinkClick();
              !!route.onClick && route?.onClick();
            }}
            isOpen={isMainNavOpen}
            className='listItem'
            key={idx}
            id={route.id}
          >
            <div>{route.icon}</div>
            {isMainNavOpen && (
              <div>
                {typeof route.display === 'string' ? (
                  <p>{route.display}</p>
                ) : (
                  route.display
                )}
              </div>
            )}
          </Tab>
        );
      }
      return (
        <li onClick={onLinkClick} key={route.path}>
          <NavLink to={route.path}>
            <Tab isOpen={isMainNavOpen} className='listItem'>
              <div>{route.icon}</div>
              {isMainNavOpen && (
                <div>
                  {typeof route.display === 'string' ? (
                    <p>{route.display}</p>
                  ) : (
                    route.display
                  )}
                </div>
              )}
            </Tab>
          </NavLink>
        </li>
      );
    })}
  </ul>
);
