import React from 'react';

//types
import { TableProps } from './types';

//components
import MTable from '@material-ui/core/Table';
import FilterRow from './FilterRow';
import LoadingRow from './LoadingRow';
import Pagination from './Pagination';
import SearchNotFoundRow from './SearchNotFoundRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from './TableHead';
import TableRows from './TableRows';
import ToolBar from './ToolBar';

//styles
import { makeStyles, createStyles } from '@material-ui/core';
import { mainTableStyles } from './style';

const useStyles = (
  tableHeightForLoadingState: number | string | false | undefined
) =>
  makeStyles(() =>
    createStyles({
      root: mainTableStyles(tableHeightForLoadingState),
    })
  );

const Table = <RowData extends object>({
  columns,
  data,
  icons,
  onRowClick,
  options,
  isLoading,
  isSearching = false,
  onSearchChange,
  onFilterColumnChange,
  detailPanel,
  checkboxSelection = false,
  handleAllSelection,
  allRecordsSelected = false
}: TableProps<RowData>) => {
  const searching = isSearching || !!options?.toolbar?.searchValue;
  const searchNotFound = searching && data.length === 0 && !isLoading;
  const classes = useStyles(searchNotFound && options?.minBodyHeight)();
  const [isFilterRowActive, setFilterRowActive] = React.useState(false);

  return (
    <>
      {!options?.hideToolbar &&
        ((options?.toolbar && options.toolbar.Component) ||
          (options?.toolbar && (
            <ToolBar
              searchIcon={icons?.searchIcon}
              onSearchChange={onSearchChange}
              searchSide={options?.toolbar?.searchSide}
              {...options.toolbar}
            />
          )))}
      <div
        style={{
          minHeight: options?.minBodyHeight,
          maxHeight: options?.maxBodyHeight || 'none',
          overflow: options?.overflow || 'auto',
          paddingBottom: options?.paddingBottom,
        }}
      >
        <MTable
          className={classes.root}
          stickyHeader={!!options?.maxBodyHeight}
        >
          <TableHead
            columns={columns}
            onFilterClick={() => {
              if (isFilterRowActive && onFilterColumnChange)
                onFilterColumnChange({}, true);
              setFilterRowActive(!isFilterRowActive);
            }}
            filtering={options?.filtering}
            detailPanel={detailPanel}
            checkboxSelection={checkboxSelection}
            handleAllSelection={handleAllSelection}
            allRecordsSelected={allRecordsSelected}
          />
          <TableBody>
            {isFilterRowActive && (
              <FilterRow
                columns={columns}
                setFilterRowActive={setFilterRowActive}
                onFilterColumnChange={onFilterColumnChange}
                usesDetailPanel={!!detailPanel}
                resetFilters={options?.resetFilters}
              />
            )}
            {searchNotFound ? (
              <SearchNotFoundRow
                columns={columns}
                searchNotFoundText={options?.searchNotFoundText}
                searchNotFoundComponent={options?.searchNotFoundComponent}
              />
            ) : (
              <TableRows
                data={data}
                columns={columns}
                onRowClick={onRowClick}
                detailPanel={detailPanel}
                filtering={options?.filtering}
                cellPadding={options?.cellPadding}
              />
            )}
            {isLoading && <LoadingRow columns={columns} data={data} />}
          </TableBody>
        </MTable>
      </div>
      {options?.pagination && (
        <Pagination
          pageSize={options.pagination?.pagesize || 0}
          backToFirstPage={
            options.pagination?.backToFirstPage ||
            !!options.toolbar?.searchValue
          }
          totalCount={options?.pagination?.totalCount || 0}
          onChangePage={options?.pagination.onChangePage}
          defaultPageIndex={options?.pagination.defaultPageIndex}
          defaultSection={options?.pagination.defaultSection}
          primaryColor={options?.pagination.primaryColor}
          searchValue={options.toolbar?.searchValue}
        />
      )}
    </>
  );
};

export default Table;
