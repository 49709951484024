import * as React from 'react';
import { loader } from 'graphql.macro';

//types
import { Employee } from '../../../../../../generated/graphql';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { TextFieldState } from '../../../../../../hooks/useTextFieldState';

//helpers
import { datadogRum } from '@datadog/browser-rum';
import { normalizeTimestamp } from '../../../Shared/utils';

//hooks
import { useHistory } from 'react-router-dom';
import { useCreateInPersonAppointmentMutation } from '../../../../../../generated/graphql';
import useTextFieldState from '../../../../../../hooks/useTextFieldState';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';
import useHandleError from '../../../../../../hooks/useHandleError';
import { format } from 'date-fns';

const IN_PERSON_APPOINTMENTS_QUERY = loader(
  '../../../../../../gql/inPersonAppointments/InPersonAppointments.graphql'
);

interface DateTimeFormState {
  value: MaterialUiPickersDate | null;
  updateValue: (newDate: MaterialUiPickersDate) => void;
  error: React.ReactNode | undefined;
  updateError: (newError: React.ReactNode) => void;
}
export interface CreateInPersonAppointmentForm {
  date: DateTimeFormState;
  time: DateTimeFormState;
  timezone: TextFieldState;
  facilityId: TextFieldState;
  employeeId: TextFieldState;
  appointmentTypeId: TextFieldState;
  patientId: TextFieldState;
  provider: {
    value: Partial<Employee> | null | undefined;
    updateValue: React.Dispatch<
      React.SetStateAction<Partial<Employee> | null | undefined>
    >;
  };
  isValid: boolean;
  handleSubmit: (
    e: React.SyntheticEvent<HTMLFormElement>,
    onDone?: () => void
  ) => void;
  saving: boolean;
}

const useCreateInPersonAppointmentFormHandlers = ({
  existingAppointment,
}: {
  existingAppointment?: any;
}) => {
  const history = useHistory();
  const snackbar = useSnackbar();
  const handleError = useHandleError();
  const {
    employeeSingularAlias,
    facilitiesSingularAlias,
    patientsSingularAlias,
  } = useRemoteConfigAliases();

  const [
    scheduleAppointment,
    scheduleAppointmentOptions,
  ] = useCreateInPersonAppointmentMutation();

  const [
    inPersonAppointmentDate,
    updateInPersonAppointmentDate,
  ] = React.useState<MaterialUiPickersDate | null>(null);
  const [
    inPersonAppointmentTime,
    updateInPersonAppointmentTime,
  ] = React.useState<MaterialUiPickersDate | null>(null);
  const [dateFormError, updateDateFormError] = React.useState<
    React.ReactNode | undefined
  >(undefined);
  const [timeFormError, updateTimeFormError] = React.useState<
    React.ReactNode | undefined
  >(undefined);
  const [providerForNewApptType, updateProviderForNewApptType] = React.useState<
    Partial<Employee> | null | undefined
  >();
  const facilityId = useTextFieldState({ required: true });
  const employeeId = useTextFieldState();
  const patientId = useTextFieldState();
  const appointmentTypeId = useTextFieldState();

  const date = {
    value: inPersonAppointmentDate,
    updateValue: updateInPersonAppointmentDate,
    error: dateFormError,
    updateError: updateDateFormError,
  };

  const time = {
    value: inPersonAppointmentTime,
    updateValue: updateInPersonAppointmentTime,
    error: timeFormError,
    updateError: updateTimeFormError,
  };

  const provider = {
    value: providerForNewApptType,
    updateValue: updateProviderForNewApptType,
  };

  const timezone = useTextFieldState();

  React.useEffect(() => {
    if (existingAppointment) {
      timezone.updateValue(existingAppointment.clinic?.timeZone ?? '');
      facilityId.updateValue(existingAppointment.clinic?.id || '');
      employeeId.updateValue(
        existingAppointment.employee?.id || existingAppointment.employeeId || ''
      );
      appointmentTypeId.updateValue(
        existingAppointment?.appointmentType?.id ||
          existingAppointment?.appointmentTypeId ||
          ''
      );
      patientId.updateValue(existingAppointment?.contact?.id || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingAppointment]);

  const isValid = !!(
    facilityId.valid &&
    employeeId.value &&
    patientId.value &&
    date.value &&
    !date.error &&
    time.value &&
    !time.error
  );

  const handleSubmit = async (
    e: React.SyntheticEvent<HTMLFormElement>,
    onDone?: () => void
  ) => {
    e.preventDefault();
    try {
      if (!date.value || !!date.error) {
        throw new Error('Please select a valid date.');
      } else if (!time.value || !!time.error) {
        throw new Error('Please select a valid time.');
      } else if (!facilityId.valid) {
        throw new Error(
          `Please select a ${facilitiesSingularAlias.toLowerCase()}.`
        );
      } else if (!employeeId.value) {
        throw new Error(
          `Please select a ${employeeSingularAlias.toLowerCase()}.`
        );
      } else if (!patientId.value) {
        throw new Error(
          `Please select a ${patientsSingularAlias.toLowerCase()}.`
        );
      } else if (
        !!appointmentTypeId.value &&
        isNaN(Number(appointmentTypeId.value))
      ) {
        throw new Error(
          `Something went wrong accessing this appointment type. Please refresh and try again.`
        );
      }

      await scheduleAppointment({
        variables: {
          input: {
            clinicId: facilityId.value,
            employeeId: employeeId.value,
            appointmentTypeId: appointmentTypeId.value || undefined,
            contactId: patientId.value,
            startTime: normalizeTimestamp(
              date.value,
              time.value,
              timezone.value
            ).toISOString(),
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: IN_PERSON_APPOINTMENTS_QUERY,
            variables: { input: { limit: 10, offset: 0 } },
          },
        ],
      });
      snackbar?.setSuccessMessage('Succesfully scheduled appointment!');

      datadogRum.addUserAction('create_in_person_appointment', {
        clinicId: facilityId.value,
        employeeId: employeeId.value,
        contactId: patientId.value,
      });

      if (existingAppointment) {
        onDone && onDone();
      } else {
        history.push(`/appointments/list`);
      }
    } catch (e) {
      handleError(e);
    }
  };

  return {
    date,
    time,
    timezone,
    facilityId,
    employeeId,
    provider,
    appointmentTypeId,
    patientId,
    isValid,
    handleSubmit,
    saving: scheduleAppointmentOptions.loading,
  };
};

export default useCreateInPersonAppointmentFormHandlers;
