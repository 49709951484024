import React, { Dispatch, SetStateAction } from 'react';
import MTableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Column } from '../types';
import Input from '../../TextField';

import IcCloseFilter from '../../Icons/IcCloseFilter';

const FilterRow = <RowData extends object>({
  columns,
  setFilterRowActive,
  onFilterColumnChange,
  usesDetailPanel,
  resetFilters,
}: {
  columns: Column<RowData>[];
  setFilterRowActive: Dispatch<SetStateAction<boolean>>;
  onFilterColumnChange?: (activeFilters: Object, close?: boolean) => void;
  usesDetailPanel?: boolean;
  resetFilters?: { value: boolean; update: (reset: boolean) => void };
}) => {
  const [activeFilters, setActiveFilters] = React.useState<any>({});

  //account for detail panel adding an extra column to the left
  const columnArray = usesDetailPanel
    ? [
        {
          disableFilter: true,
        },
        ...columns,
      ]
    : columns;

  const closeFilters = (
    _e?:
      | React.MouseEvent<SVGSVGElement, MouseEvent>
      | React.KeyboardEvent<SVGSVGElement>
  ) => {
    onFilterColumnChange && onFilterColumnChange({}, true);
    setFilterRowActive(false);
    setActiveFilters({});
  };

  React.useEffect(() => {
    if (!!resetFilters) {
      if (resetFilters.value) {
        closeFilters();
        resetFilters.update(false);
      }
    }
  }, [resetFilters?.value]);

  return (
    <>
      <MTableRow>
        {columnArray.map((col, idx) => (
          <TableCell
            colSpan={idx + 1 === columns.length ? 2 : 1}
            size='small'
            key={idx}
          >
            {!col.disableFilter && (
              <Input
                value={activeFilters[col.field] || ''}
                overrideStyle={{
                  margin: 0,
                  '& .MuiInputBase-root': { margin: 0 },
                  '& svg': {
                    cursor: 'pointer',
                  },
                }}
                onChange={(e) => {
                  const newFilters = {
                    ...activeFilters,
                    [col.field as any]: e.target.value,
                  };
                  setActiveFilters(newFilters);
                  onFilterColumnChange &&
                    onFilterColumnChange(newFilters, false);
                }}
                InputProps={{
                  endAdornment: (
                    <IcCloseFilter
                      role='button'
                      aria-label='close filter'
                      tabIndex={0}
                      onClick={closeFilters}
                      onKeyDown={(e) => e.key === 'Enter' && closeFilters()}
                    />
                  ),
                }}
                placeholder={col.filterPlaceholder || 'Type to filter'}
              />
            )}
          </TableCell>
        ))}
      </MTableRow>
    </>
  );
};

export default FilterRow;
