import React from 'react';

export default ({
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#4A79B5"
          fillRule="nonzero"
          d="M10 6h4v2h-4V6zM3 18v-2h18v2H3zm3-7h12v2H6v-2z"
        />
        <path d="M0 24h24V0H0z" />
      </g>
    </svg>
  );
