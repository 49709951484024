import * as React from 'react';
import Auth from '@aws-amplify/auth';
import axios from 'axios';

// images
import ImageLogoBackground from '../../../../../../assets/img-unknown-cliniclogo.svg';

// components
import { Button, Animation, Header, ImageUploader } from '@betterpt/better-components';
import { GridCard } from '../../../../../Shared/GridCard';
import TraditionalAddressFields from './TraditionalAddressFields';
import ServiceAreaFields from './ServiceAreaFields';
import Can from '../../../../../Can';

// hooks
import {
  useFacilityQuery,
  useMeQuery,
  useUpdateFacilityMutation,
  useUpdateFacilityLocationMutation,
} from '../../../../../../generated/graphql';
import useTextFieldState from '../../../../../../hooks/useTextFieldState';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import useHandleError from '../../../../../../hooks/useHandleError';

// style
import * as sharedStyles from '../../FacilityDetails.style';
import * as styles from './FacilityContact.style';

// helpers
import { normalizeZipcode, normalizePhone } from '../../../../../../helpers/normalizers';
import { emailValidation, nameValidation, urlValidation } from '../../../../../../helpers/validations';
import { Roles } from '../../../../../../helpers/rbac-rules';
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';

const FacilityContact = ({ facilityId }: { facilityId: string }) => {
  // hooks
  const snackbar = useSnackbar();
  const facilityQuery = useFacilityQuery({
    variables: { id: facilityId },
  });
  const me = useMeQuery();
  const handleError = useHandleError();
  const [isFormDisabled, updateIsFormDisabled] = React.useState(true);
  const [isSaving, updateSaving] = React.useState(false);
  const [isServiceArea, updateIsServiceArea] = React.useState(false);

  // shared
  const city = useTextFieldState({
    validation: nameValidation,
  });
  const state = useTextFieldState({ required: true });
  const [facilityLogo, updateFacilityLogo] = React.useState<string>(ImageLogoBackground);
  const [hasLogoBeenUpdated, updateHasLogoBeenUpdated] = React.useState(false);

  // hooks - service area
  const serviceAreaName = useTextFieldState({
    required: true,
    validation: nameValidation,
  });

  // hooks - traditional
  const address = useTextFieldState({
    required: true,
    validation: nameValidation,
  });
  const address2 = useTextFieldState();
  const zipcode = useTextFieldState({
    required: true,
    normalizer: normalizeZipcode,
  });
  const phone = useTextFieldState({
    required: true,
    normalizer: normalizePhone,
  });
  const email = useTextFieldState({
    required: true,
    validation: emailValidation,
  });

  // update existing values from query
  React.useEffect(() => {
    const { data, called, loading } = facilityQuery;
    if (called && !loading && data) {
      const { clinic } = data;
      const location = clinic?.location;
      const initialPhone = clinic?.phone ?? '';
      city.updateValue(location?.city?.trim() ?? '');
      state.updateValue(location?.state ?? '');
      serviceAreaName.updateValue(location?.serviceAreaName?.trim() ?? '');
      address.updateValue(location?.address?.trim() ?? '');
      address2.updateValue(location?.address2 ?? '');
      zipcode.updateValue(location?.zipcode ?? '');
      phone.updateValue(initialPhone);
      email.updateValue(clinic?.email ?? '');
      if (clinic?.logoUrl) {
        updateFacilityLogo(clinic?.logoUrl);
      }
      if (facilityQuery.data?.clinic?.location?.isServiceArea) {
        updateIsServiceArea(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityQuery.data]);

  const [updateFacility] = useUpdateFacilityMutation();
  const [updateFacilityLocation] = useUpdateFacilityLocationMutation();

  // values
  const { facilitiesSingularAlias, primaryColor, loading: remoteConfigLoading } = useRemoteConfigAliases();
  const disableButton =
    !city.valid || !state.valid || isServiceArea
      ? !serviceAreaName.valid
      : !address.valid || !address2.valid || !zipcode.valid || !phone.valid || !email.valid;

  // functions
  const handleFormSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isFormDisabled) {
      updateIsFormDisabled(false);
    } else {
      updateSaving(true);
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const endpoint = window.__RUNTIME_CONFIG__.REACT_APP_API_ENDPOINT ?? 'https://ap.development.betterpt.com/v0/api';

        if (hasLogoBeenUpdated) {
          await axios({
            method: 'POST',
            url: `${endpoint}/clinics/${facilityId}/logo`,
            headers: {
              authorization: token,
              'Content-Type': 'application/json',
            },
            data: {
              file: facilityLogo,
            },
          });
          updateHasLogoBeenUpdated(false);
        }

        await updateFacility({
          variables: {
            input: {
              clinicId: facilityId,
              email: isServiceArea ? undefined : email.value,
              phone: isServiceArea ? undefined : phone.value,
            },
          },
        });
        await updateFacilityLocation({
          variables: {
            input: {
              clinicId: facilityId,
              address: address.value,
              address2: address2.value || undefined,
              city: city.value,
              state: state.value,
              zipcode: zipcode.value,
              serviceAreaName: serviceAreaName.value || undefined,
            },
          },
        });

        await facilityQuery.refetch();

        updateSaving(false);
        updateIsFormDisabled(true);
        snackbar?.setSuccessMessage(`${facilitiesSingularAlias} updated successfully!`);
      } catch (e) {
        updateIsFormDisabled(true);
        updateSaving(false);
        handleError(e);
      }
    }
  };

  if (facilityQuery.loading || me.loading || remoteConfigLoading)
    return (
      <GridCard style={sharedStyles.loaderCardStyle} data-testid="facility-contact-loader">
        <Animation type="providerAppLoader" />
      </GridCard>
    );

  const facilityIsManagedViaYext = !!facilityQuery.data?.clinic?.isManagedViaYext;

  return (
    <GridCard style={sharedStyles.cardStyle}>
      <form onSubmit={handleFormSubmit} data-testid="facility-contact-form">
        <Header underline style={sharedStyles.headerStyle} data-testid="facility-contact-header">
          <ImageUploader
            image={facilityLogo}
            style={styles.facilityLogo}
            onSubmit={(img) => {
              updateHasLogoBeenUpdated(true);
              updateFacilityLogo(img.base64Img);
            }}
            type="avatar"
            width={28}
            disabled={isFormDisabled}
            onError={(message) => snackbar?.openSnackbar({ isError: true, message })}
          />
          <h3 className="H3" style={{ marginLeft: '10px' }}>
            Contact
          </h3>
          <Can
            role={Roles[me.data?.me?.role || 'initial']}
            perform="facility:view"
            data={{
              employeeClinics: me?.data?.me?.clinics,
              clinicToView: facilityQuery.data?.clinic,
            }}
            yes={() => (
              <Can
                role={Roles[me.data?.me?.role || 'initial']}
                perform="facility:edit"
                yes={() => (
                  <Button
                    style={sharedStyles.editButtonStyle(isFormDisabled, primaryColor)}
                    size="small"
                    type="submit"
                    loading={isSaving}
                    disabled={(!isFormDisabled && disableButton) || isSaving}
                  >
                    {isFormDisabled ? 'EDIT' : 'SAVE'}
                  </Button>
                )}
              />
            )}
          />
        </Header>
        <sharedStyles.InnerCard>
          {isServiceArea ? (
            <ServiceAreaFields
              city={city}
              state={state}
              serviceAreaName={serviceAreaName}
              timeZone={facilityQuery?.data?.clinic?.timeZone ?? 'America/New_York'}
              isFormDisabled={isFormDisabled}
              shouldDisableYextFields={facilityIsManagedViaYext}
            />
          ) : (
            <TraditionalAddressFields
              address={address}
              address2={address2}
              city={city}
              state={state}
              zipcode={zipcode}
              phone={phone}
              email={email}
              isFormDisabled={isFormDisabled}
              shouldDisableYextFields={facilityIsManagedViaYext}
            />
          )}
        </sharedStyles.InnerCard>
      </form>
    </GridCard>
  );
};

export default FacilityContact;
