//types
import { TextFieldState } from '../../../../hooks/useTextFieldState';
import { BoolFieldState } from '../../../../hooks/useBoolFieldState';
import { PasswordState } from '../../../authentication/hooks/usePasswordState';

//helpers
import {
  formatPhoneForBackend,
  formatYearsExperience,
} from '../../../../helpers';

//hooks
import {
  useMeQuery,
  useMarkEmployeeAsTherapistMutation,
  useUpdateEmployeeMutation,
} from '../../../../generated/graphql';
import useAuthentication from '../../../../hooks/useAuthentication';

export interface UpdateUserInfoInput {
  firstNameFormState: TextFieldState;
  lastNameFormState: TextFieldState;
  genderFormState: TextFieldState;
}

export interface UpdateProviderDetailsInput {
  bioFormState: TextFieldState;
  degreeOrCertificationFormState: TextFieldState;
  yearsExperienceFormState: TextFieldState;
  isTherapistFormState: BoolFieldState;
}

export interface UpdateLoginContactInput {
  oldPasswordFormState: TextFieldState;
  newPasswordFormState: PasswordState;
  phoneFormState: TextFieldState;
}

const useProfileOperations = () => {
  const meQuery = useMeQuery();
  const auth = useAuthentication();
  const [updateMe] = useUpdateEmployeeMutation();
  const [updateProviderStatus] = useMarkEmployeeAsTherapistMutation();
  const me = meQuery?.data?.me;
  const employeeId = me?.id;

  const updateUserInfo = async ({
    firstNameFormState,
    lastNameFormState,
    genderFormState,
  }: UpdateUserInfoInput) => {
    if (!employeeId) {
      throw new Error(
        'Your account could not be accessed, please refresh and try again.'
      );
    }

    let networkRequestCalled = false;
    if (!firstNameFormState.valid) {
      throw new Error('You must provide a valid first name.');
    }
    if (!lastNameFormState.valid) {
      throw new Error('You must provide a valid last name.');
    }
    if (!genderFormState.valid) {
      throw new Error('You must provide a valid gender.');
    }
    //if user info has changed, call to the backend
    if (
      firstNameFormState.value !== me?.firstName ||
      lastNameFormState.value !== me?.lastName ||
      genderFormState.value !== me?.gender
    ) {
      await updateMe({
        variables: {
          input: {
            employeeId,
            firstName: firstNameFormState.value,
            lastName: lastNameFormState.value,
            gender: genderFormState.value,
          },
        },
      });
      networkRequestCalled = true;
    }
    return networkRequestCalled;
  };

  const updateProviderDetails = async ({
    bioFormState,
    degreeOrCertificationFormState,
    yearsExperienceFormState,
    isTherapistFormState,
  }: UpdateProviderDetailsInput) => {
    if (!employeeId) {
      throw new Error(
        'Your account could not be accessed, please refresh and try again.'
      );
    }

    let networkRequestCalled = false;
    const shouldEmployeesDefaultToProvider =
      me?.company?.shouldNewEmployeesDefaultToProvider;
    const userIsProvider = me?.isTherapist;
    const yearsOfExperience = formatYearsExperience(yearsExperienceFormState);

    if (!bioFormState.valid) {
      throw new Error('You must provide a valid bio.');
    }
    if (!degreeOrCertificationFormState.valid) {
      throw new Error('You must provide a valid degree or certification.');
    }
    if (!yearsExperienceFormState.valid) {
      throw new Error('You must provide a valid practice start date.');
    }
    if (!isTherapistFormState.valid) {
      throw new Error('You must select a valid care provider status.');
    }
    //if provider status has changed, call to the backend
    if (
      !shouldEmployeesDefaultToProvider &&
      isTherapistFormState.value !== userIsProvider
    ) {
      await updateProviderStatus({
        variables: {
          input: {
            employeeId,
            isTherapist: !!isTherapistFormState.value,
          },
        },
      });
      networkRequestCalled = true;
    }
    //if provider details have changed, call to the backend
    if (
      me?.bio !== bioFormState.value ||
      me?.degreeOrCertification !== degreeOrCertificationFormState.value ||
      me?.yearsExperience !== yearsOfExperience
    ) {
      await updateMe({
        variables: {
          input: {
            employeeId,
            bio: bioFormState.value,
            degreeOrCertification: degreeOrCertificationFormState.value,
            yearsExperience: yearsOfExperience ?? null,
          },
        },
      });
      networkRequestCalled = true;
    }
    return networkRequestCalled;
  };

  const updateLoginContactInfo = async ({
    oldPasswordFormState,
    newPasswordFormState,
    phoneFormState,
  }: UpdateLoginContactInput) => {
    if (!employeeId) {
      throw new Error(
        'Your account could not be accessed, please refresh and try again.'
      );
    }

    let networkRequestCalled = false;
    const missingOldPassword =
      !!newPasswordFormState.value &&
      (!oldPasswordFormState.value || !oldPasswordFormState.valid);
    const bothPasswordFieldsFilled =
      !!newPasswordFormState.value && !!oldPasswordFormState.value;
    const passwordsAreTheSame =
      bothPasswordFieldsFilled &&
      newPasswordFormState.value === oldPasswordFormState.value;

    if (missingOldPassword) {
      throw new Error('Your old password is required to set a new password.');
    }
    if (passwordsAreTheSame) {
      throw new Error(
        'Your new password must be different from your old password.'
      );
    }
    if (!!newPasswordFormState.value && !newPasswordFormState.valid) {
      throw new Error('You must enter a valid new password');
    }
    //if user is trying to change their password, call to the backend
    if (
      bothPasswordFieldsFilled &&
      !passwordsAreTheSame &&
      !missingOldPassword
    ) {
      await auth?.resetPassword(
        oldPasswordFormState.value,
        newPasswordFormState.value
      );
      networkRequestCalled = true;
    }
    //if phone has been changed, call to the backend
    if (
      formatPhoneForBackend(phoneFormState.value) !==
      formatPhoneForBackend(meQuery.data?.me?.phone)
    ) {
      if (!phoneFormState.valid) {
        throw new Error('Please enter a valid phone number.');
      }

      await updateMe({
        variables: {
          input: {
            employeeId,
            phone: formatPhoneForBackend(phoneFormState.value) || null,
          },
        },
      });
      networkRequestCalled = true;
    }
    return networkRequestCalled;
  };

  return {
    commands: {
      updateUserInfo,
      updateProviderDetails,
      updateLoginContactInfo,
    },
  };
};

export default useProfileOperations;
