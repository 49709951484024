import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

export const ProfileFormContainer = styled.div`
  display: flex;
`;

export const ProfileForm = styled.form`
  width: 450px;
  h2 {
    margin: 20px 0px;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
`;

export const changePasswordButtonStyle = {
  color: colorValues.betterptblack,
  backgroundColor: colorValues.dust,
  width: '90px',
  marginBottom: '5px',
};
