import { bestTextColor, colorValues } from '@betterpt/better-components';

export const formFieldStyle = {
  margin: '28px 51px',
  maxWidth: '531px',
  width: '80%',
};

export const detailsNameFieldStyle = {
  width: '47%',
  marginBottom: '0px',
};

export const nameRowStyle = {
  display: 'flex',
  flexDirection: 'row' as any,
  justifyContent: 'space-between',
  marginBottom: '0px',
  ...formFieldStyle,
};

export const submitButtonStyle = (primaryColor: string) => ({
  backgroundColor: primaryColor,
  color: bestTextColor(primaryColor),
  fontWeight: 'bold' as 'bold',
  margin: '28px 51px',
  maxWidth: '531px',
  width: '80%',
});

export const editSaveButtonStyle = (
  editModeActive: boolean,
  isFormValid?: boolean,
  primaryColor?: string
) => {
  if (editModeActive) {
    const buttonColor = !isFormValid ? colorValues.dust : primaryColor;
    return {
      backgroundColor: buttonColor,
      color: bestTextColor(buttonColor),
      width: 105,
    };
  } else
    return {
      backgroundColor: colorValues.dust,
      color: colorValues.betterptblack,
      width: 105,
    };
};
