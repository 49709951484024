import styled from '@emotion/styled';

export const CongratsDialogContent = styled.div`
  padding: 35px 30px 40px;
  position: relative;
`;

export const DialogTitle = styled.h1`
  padding: 16px 24px;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
`;

export const DialogText = styled.p`
  padding: 0px 25px 20px;
  font-size: 16px;
  line-height: 1.6;
`;

export const DialogThankYouButton = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center;
`;
