import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

// components
import { Button } from '@betterpt/better-components';
import TextField from '../../Shared/ThemedTextField';
import AuthWrapper from '../AuthWrapper';

// hooks
import useAuthentication from '../../../hooks/useAuthentication';
import usePasswordState from '../hooks/usePasswordState';
import useCodeState from '../hooks/useCodeState';

// style
import { loginButtonStyle } from '../shared.style';

// copy
import { determineVariant } from '../AuthWrapper/loginVariantConfig';

const ForgotPasswordSubmit: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { variant } = useParams<{ variant: string }>();
  const { push } = useHistory();
  const { email } = useParams<{ email: string }>();
  const decodedEmail = decodeURIComponent(email).replace('$dot$', '.');
  const code = useCodeState();
  const newPassword = usePasswordState();
  const [confirmPassword, updateConfirmPassword] = React.useState('');
  const auth = useAuthentication();

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    const loginLink = variant ? `/login/${variant}` : '/login';
    e.preventDefault();
    auth
      ?.forgotPasswordSubmit(decodedEmail, code.value, newPassword.value)
      .then(() => {
        push(loginLink);
      });
  };

  // values
  const primaryColor = determineVariant(variant).color;
  const passwordsDontMatch = newPassword.value !== confirmPassword;
  const isButtonDisabled =
    passwordsDontMatch ||
    newPassword.errors.length > 0 ||
    newPassword.value.length < 1 ||
    auth?.isLoading ||
    !!code.error;
  const passwordError =
    newPassword.errors.length > 0
      ? newPassword.errors.map((err) => (
          <React.Fragment key={err}>
            {err}
            <br />
          </React.Fragment>
        ))
      : '';

  return (
    <AuthWrapper>
      <form onSubmit={handleSubmit}>
        <h1 className='H2'>Forgot Password</h1>
        <p style={{ overflowWrap: 'break-word' }}>
          Please check <b>{decodedEmail}</b> and follow the password reset
          instructions.
        </p>
        <TextField
          onChange={(e) => code.updateValue(e.currentTarget.value)}
          label='Code'
          placeholder='Enter your code here'
          error={!!code.error}
          helperText={code.error}
        />
        <TextField
          onChange={(e) => newPassword.updateValue(e.currentTarget.value)}
          label='Password'
          placeholder='Enter your new password here'
          type='password'
          error={newPassword.errors.length > 0}
          helperText={passwordError}
        />
        <TextField
          onChange={(e) => updateConfirmPassword(e.currentTarget.value)}
          label='Confirm Password'
          placeholder='Re-enter your new password'
          type='password'
          error={passwordsDontMatch}
          helperText={passwordsDontMatch ? 'Passwords must match' : ''}
        />

        <Button
          loading={auth?.isLoading}
          type='submit'
          fullWidth
          size='large'
          style={loginButtonStyle(primaryColor)}
          disabled={auth?.isLoading || isButtonDisabled}
        >
          Submit
        </Button>
      </form>
    </AuthWrapper>
  );
};

export default ForgotPasswordSubmit;
