import * as React from 'react';
import { datadogRum } from '@datadog/browser-rum';

//hooks
import useSnackbar from '../../../../../../hooks/useSnackbar';
import {
  useCancelInPersonAppointmentMutation,
  useInPersonAppointmentDetailsQuery,
} from '../../../../../../generated/graphql';

//components
import Dialog from '@material-ui/core/Dialog';
import { Animation } from '@betterpt/better-components';
import ConfirmCancellationPage from '../../../Shared/ConfirmCancellationPage';
import SlideTransition from '../../../../../Shared/SlideTransition';
import useHandleError from '../../../../../../hooks/useHandleError';

type Props = {
  open: boolean;
  onClose: () => void;
  appointmentId?: string;
  onDoneChangingOptionsAppointment: () => Promise<any> | undefined;
};

const CancelAppointmentDialog = ({
  open,
  onClose,
  appointmentId,
  onDoneChangingOptionsAppointment,
}: Props) => {
  const snackbar = useSnackbar();

  const [saving, updateSaving] = React.useState(false);

  const [cancelAppointment] = useCancelInPersonAppointmentMutation();
  const handleError = useHandleError();

  const appointmentQuery = useInPersonAppointmentDetailsQuery({
    variables: { id: appointmentId! },
    skip: !appointmentId,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const handleCancel = async () => {
    if (!appointmentId) return;
    updateSaving(true);
    try {
      await cancelAppointment({
        variables: {
          id: appointmentId,
        },
      });
      snackbar?.setSuccessMessage('Appointment cancelled');

      datadogRum.addUserAction('cancel_in_person_appointment', {
        clinicId: appointmentQuery.data?.appointment?.clinicId,
        employeeId: appointmentQuery.data?.appointment?.employee?.id,
        patientId: appointmentQuery.data?.appointment?.patient?.id,
      });

      setTimeout(async () => {
        await onDoneChangingOptionsAppointment();
        updateSaving(false);
        onClose();
      }, 1000);
    } catch (e) {
      updateSaving(false);
      handleError(e);
      return;
    }
  };

  const renderContent = () => {
    if (appointmentQuery.loading) {
      return <Animation type='providerAppLoader' />;
    } else {
      return (
        <ConfirmCancellationPage
          cancelAppointment={handleCancel}
          closeDialog={() => onClose()}
          saving={saving}
        />
      );
    }
  };

  return (
    <Dialog
      TransitionComponent={SlideTransition}
      open={open}
      onClose={() => onClose()}
      maxWidth='sm'
      fullWidth
    >
      {renderContent()}
    </Dialog>
  );
};

export default CancelAppointmentDialog;
