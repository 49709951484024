import React from 'react';

//components
import { Button, CloseButton } from '@betterpt/better-components';
import { Dialog } from '@material-ui/core';
import SlideTransition from '../../../Shared/SlideTransition';

//styles
import styled from '@emotion/styled';
const Container = styled.div`
  padding: 52px;
  display: flex;
  flex-direction: column;
  text-align: left;
`;
const Header = styled.h1`
  font-size: 20px;
  margin: 0;
`;
const Body = styled.p`
  font-size: 14px;
  line-height: 1.43;
  margin: 20px 0 22px;
`;
const paperStyle = { width: 480, height: 309 };

type Props = {
  saving: boolean;
  open: boolean;
  onClose: () => void;
  onContinue: () => Promise<void>;
};

const AreYouSureDialog = ({ saving, open, onClose, onContinue }: Props) => {
  const handleEnterKey = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        onContinue();
      }
    },
    [onContinue]
  );

  React.useEffect(() => {
    if (open) {
      window.addEventListener('keydown', handleEnterKey);
    }
    return () => {
      window.removeEventListener('keydown', handleEnterKey);
    };
  }, [handleEnterKey, open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={SlideTransition}
      PaperProps={{
        style: paperStyle,
      }}
    >
      <Container>
        <CloseButton onClose={onClose} />
        <Header>Are you sure you want to continue?</Header>
        <Body>
          Any changes you make to appointment types will be displayed for all
          people who have taken part in any affected sessions, including users
          who have taken part in these appointment types in the past.
        </Body>
        <Button
          fullWidth
          size='large'
          loading={saving}
          onClick={onContinue}
          data-cy='edit-appointment-type-confirm-button'
        >
          CONTINUE
        </Button>
      </Container>
    </Dialog>
  );
};

export default AreYouSureDialog;
