import Auth from '@aws-amplify/auth';

const {
  REACT_APP_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_APP_CLIENT_ID,
} = window.__RUNTIME_CONFIG__;

const domainURIs = new Map([
  ['production', 'identity.betterpt.com'],
  ['staging', 'betterpt-staging.auth.us-east-1.amazoncognito.com'],
  ['development', 'betterpt-development.auth.us-east-1.amazoncognito.com'],
]);

const redirectURLs = new Map([
  ['production', 'https://providers.betterhealthcare.co/'],
  ['staging', 'https://provider-application.staging.betterpt.com'],
  ['development', 'https://provider-application.development.betterpt.com'],
]);

export const cognito = {
  region: REACT_APP_REGION ?? 'us-east-1',
  userPoolId: REACT_APP_USER_POOL_ID ?? 'us-east-1_xv61G05vv',
  userPoolWebClientId: REACT_APP_APP_CLIENT_ID ?? '156306c4jp7sg3bcmsk809ljc0',
};
const domainURI =
  domainURIs.get(window.__RUNTIME_CONFIG__.REACT_APP_NODE_ENV) ?? 'identity.betterpt.com';
const redirectURL =
  redirectURLs.get(window.__RUNTIME_CONFIG__.REACT_APP_NODE_ENV) ?? 'https://providers.betterpt.com';

export const oauth = {
  domain: domainURI,
  redirectSignIn: `${redirectURL}/federated-login?signedIn=true`,
  redirectSignOut: `${redirectURL}/login?signedOut=true`,
  responseType: 'code',
};

const configureAmplify = () => Auth.configure({ ...cognito, oauth });

export default configureAmplify;
