import * as React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...rest}
  >
    <g fill='none' fillRule='evenodd'>
      <path
        fill={color ?? '#B1B1B1'}
        fillRule='nonzero'
        d='M3 15l3.99 4v-3H14v-2H6.99v-3L3 15zm14.01-7H10v2h7.01v3L21 9l-3.99-4v3z'
        transform='rotate(90 12 12)'
      />
      <path d='M0 0L24 0 24 24 0 24z' transform='rotate(90 12 12)' />
    </g>
  </svg>
);
