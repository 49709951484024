import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    width='109'
    height='108'
    viewBox='0 0 109 108'
  >
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <path
                fill='#333'
                fillRule='nonzero'
                d='M4.725 34.017h4.81V14.579h-4.81v-4.86L23.962 0 43.2 9.72v4.859h-4.81v19.438h4.81v9.72h-6.06L6.841 61.424l2.693-17.689H4.725v-9.719zm25.934-.015V14.565H19.122v19.437h11.537'
                transform='translate(-668 -220) translate(103 67) translate(565.5 153) translate(31.05 .675)'
              />
              <path
                fill='#FF6454'
                fillRule='nonzero'
                d='M0 107.325L.193 106.147 44.07 80.325 46.575 96.918 28.899 107.325 0 107.325M5.014 74.203L40.018 54 42.525 70.327 2.025 93.825 5.014 74.203z'
                transform='translate(-668 -220) translate(103 67) translate(565.5 153) translate(31.05 .675)'
              />
              <path
                fill='#FF6454'
                d='M9.467 43.875L6.75 61.425 37.125 43.944z'
                transform='translate(-668 -220) translate(103 67) translate(565.5 153) translate(31.05 .675)'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
