import React from 'react';

// types
import { JSONSchema7 } from 'json-schema';
import { ISubmitEvent } from '@rjsf/core';
import useAppointmentTypeDetailOperations from '../../../Views/AppointmentTypeDetail/hooks/useAppointmentTypeDetailOperations';

// schema
import providerCreateFormSchema from '../../../Views/AppointmentTypeDetail/schemas/providerCreateFormSchema.json';

// helpers
import { mapCustomFormSchemaToFormData, checkTemplatePropertiesExist } from '../../../Views/AppointmentTypeDetail/helpers/customFormMappers';

// hooks
import useSnackbar from '../../../../hooks/useSnackbar';
import useRemoteConfigAliaes from '../../../../hooks/useRemoteConfigAliases';
import useHandleError from '../../../../hooks/useHandleError';

// components
import Form from '@rjsf/core';
import { Animation, Button } from '@betterpt/better-components';
import CustomCheckboxInput from '../../../Views/AppointmentTypeDetail/components/RjsfComponents/CustomCheckboxInput';
import CustomFieldTemplate from '../../../Views/AppointmentTypeDetail/components/CustomFieldTemplate';
import CustomFormInput from '../../../Views/AppointmentTypeDetail/components/RjsfComponents/CustomFormInput';
import MultipleChoiceAnswersTemplate from '../../../Views/AppointmentTypeDetail/components/RjsfComponents/MultipleChoiceAnswersTemplate';
import QuestionsArrayTemplate from '../../../Views/AppointmentTypeDetail/components/RjsfComponents/QuestionsArrayTemplate';
import QuestionTypeSelectInput from '../../../Views/AppointmentTypeDetail/components/RjsfComponents/QuestionTypeSelectInput';
import ProgressBar from "../../ProgressBar";

//styles
import styled from '@emotion/styled';
import { CompanyAppointmentType, useAppointmentTypeQuery } from '../../../../generated/graphql';

const DialogBody = styled.div`
  width: 500px;
  margin: 50px auto 0 auto;
`;

const saveButtonStyle = (primaryColor: string) => ({
    backgroundColor: primaryColor,
    marginTop: '7vh',
    position: 'absolute' as 'absolute',
    top: 40,
    right: 40,
    width: 211,
});

interface Props {
    handleClose(): void;
    appointmentTypeId?: string;
}

const AddQualifyingQuestions: React.FC<React.PropsWithChildren<Props>> = ({
    handleClose,
    appointmentTypeId,
}) => {

    const snackbar = useSnackbar();
    const handleError = useHandleError();
    const { primaryColor } = useRemoteConfigAliaes();
    const [saving, updateSaving] = React.useState(false);
    const [formData, updateFormData] = React.useState<any>({});
    const {
        commands: {
            updateAppointmentTypeQuestions,
        },
        queries: { mapAppointmentTypeDetails },
    } = useAppointmentTypeDetailOperations();

    const appointmentTypeQuery = useAppointmentTypeQuery({
        variables: {
            id: appointmentTypeId!,
        },
        skip: !appointmentTypeId,
        onError: () => snackbar?.useGenericErrorMessage(),
        fetchPolicy: 'cache-and-network',
    });

    let loading = appointmentTypeQuery.loading;
    const appointmentType:
        | CompanyAppointmentType
        | null
        | undefined = appointmentTypeQuery.data
            ?.appointmentType as CompanyAppointmentType;

    const mappedAppointmentType = React.useMemo(
        () =>
            mapAppointmentTypeDetails({
                appointmentType,
                loading
            }),
        [appointmentTypeQuery, loading, mapAppointmentTypeDetails]
    );

    const customFormTemplate = mappedAppointmentType.customFormTemplate;
    const customFormUiTemplate = mappedAppointmentType.customFormUISchemaTemplate;
    const [disableSave, updateDisableSave] = React.useState(false);

    const updateSchema = async (e: ISubmitEvent<any>) => {
        if (!e.formData.questions) {
            handleClose();
        }
        else {
            updateSaving(true);
            try {
                await updateAppointmentTypeQuestions({
                    appointmentTypeId: mappedAppointmentType.id,
                    formQuestions: e.formData.questions,
                });
                snackbar?.setSuccessMessage('Appointment type questions saved');
                updateSaving(false);
                handleClose();
            } catch (e) {
                updateSaving(false);
                handleError(e);
            }
        }
    };

    const uiSchema = {
        questions: {
            "ui:title": checkTemplatePropertiesExist(customFormTemplate), // Rather then using an extra state to change the title & description we will just check if properties attribute is empty from the backend
            "ui:description": checkTemplatePropertiesExist(customFormTemplate), //We are checking properties inside customFormTemplate because once user creates a questions template it will exist in the database even after they remove all the questions 
            'ui:ArrayFieldTemplate': QuestionsArrayTemplate,
            items: {
                responses: {
                    'ui:ArrayFieldTemplate': MultipleChoiceAnswersTemplate,
                },
                'What type of question do you want to ask?': {
                    'ui:widget': QuestionTypeSelectInput,
                },
            },
            'ui:order': [
                'How would you like to be contacted?',
                'How did you hear about us?',
            ],
        },
    };

    React.useEffect(() => {
        if (!loading) {
            updateFormData(mapCustomFormSchemaToFormData(
                customFormTemplate,
                customFormUiTemplate?.['ui:order']
            ) ?? {});
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const widgets = {
        TextWidget: CustomFormInput,
        CheckboxWidget: CustomCheckboxInput,
    };

    const handleFormChange = ({ formData }: any) => {
        let requiredFormData: any = []
        let nonRequiredFormData: any = []
        let hasQuestionsWithOnlyNumbers = false;
        formData['questions']?.forEach((key: any) => {
            if (/^\d+$/.test(key?.questionText)) {
                hasQuestionsWithOnlyNumbers = true;
            }
            key?.required
                ? requiredFormData.push(key)
                : nonRequiredFormData.push(key);
        });
        if (hasQuestionsWithOnlyNumbers) {
            updateDisableSave(true);
            snackbar?.openSnackbar({
                isError: true,
                message: 'Question cannot be a number!',
            });
        } else {
            updateDisableSave(false);
        }
        updateFormData({ questions: [...requiredFormData, ...nonRequiredFormData] })
    };

    return (
        loading ? (
            <Animation type='providerAppLoader' />
        ) : (
            <DialogBody>
                <ProgressBar width={100} style={{ marginTop: 20 }} />
                {saving ? (
                    <>
                        <Animation type='providerAppLoader' />
                        <Button
                            loading={saving}
                            style={saveButtonStyle(primaryColor)}
                            size='large'
                            disabled={disableSave}
                        >
                            SAVE AND FINISH UP
                        </Button>
                    </>
                ) : (
                    <Form
                        name='Create Custom Form'
                        schema={providerCreateFormSchema as JSONSchema7}
                        onSubmit={updateSchema}
                        formData={formData}
                        onChange={handleFormChange}
                        uiSchema={uiSchema}
                        widgets={widgets}
                        FieldTemplate={CustomFieldTemplate}
                        showErrorList={false}
                        disabled={saving}
                    >
                        <Button
                            loading={saving}
                            style={saveButtonStyle(primaryColor)}
                            size='large'
                            disabled={disableSave}
                        >
                            SAVE AND FINISH UP
                        </Button>
                    </Form>
                )}
            </DialogBody>
        )
    );
};

export default AddQualifyingQuestions;