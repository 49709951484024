import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    width='161'
    height='160'
    viewBox='0 0 161 160'
  >
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g transform='translate(-727 -264) translate(597 264) translate(130.5) translate(1 26)'>
              <path
                fill='#F06456'
                d='M89.236 57.507v23.897H53.158V57.507c0-8.265 5.625-15.224 13.28-17.297.52-.146 1.048-.265 1.587-.359 1.03-.181 2.09-.276 3.172-.276 1.364 0 2.688.15 3.965.434.539.122 1.066.264 1.585.433 2.748.879 5.21 2.4 7.204 4.386 3.267 3.244 5.285 7.729 5.285 12.679'
              />
              <path
                fill='#E2946D'
                d='M55.316 29.34c.252 4.62 2.361 8.701 5.576 11.563 3.21 2.867 7.52 4.51 12.168 4.26 9.297-.5 16.423-8.396 15.919-17.638-.009-.182-.024-.359-.039-.54-.165-1.927-.656-3.746-1.407-5.418-.103-.226-.21-.448-.32-.663-.952-1.884-2.25-3.556-3.81-4.943-3.21-2.867-7.52-4.51-12.168-4.26-3.826.206-7.282 1.661-9.987 3.953-.034.022-.063.049-.095.085-.55.464-1.07.97-1.552 1.505-1.4 1.546-2.508 3.348-3.25 5.323-.558 1.496-.914 3.084-1.022 4.738-.033.49-.045.99-.033 1.492.005.183.01.36.02.542'
              />
              <circle cx='87' cy='31' r='4' fill='#E2946D' fillRule='nonzero' />
              <circle
                cx='57.158'
                cy='31'
                r='4'
                fill='#E2946D'
                fillRule='nonzero'
              />
              <path
                fill='#6A564E'
                d='M90.324 24.213c.073 1.336-.427 2.572-1.302 3.504-.1 1.303-.38 2.608-.84 3.893-.06.238-.061.542-.072.845-.058 1.52-.342 1.837-.882 3.335-2.741-2.64-1.719-1.098-4.082-2.191-1.68-.639-2.933-2.087-3.183-3.859-.915-.074-1.77-.382-2.486-.86-.774.52-1.703.848-2.72.904-2.18.118-4.11-1.046-4.98-2.792-.323.073-.656.125-1 .144-1.012.055-1.967-.167-2.783-.592-.084-.043-.168-.09-.253-.136-.361-.062-.654-.19-.93-.343-.673.537-1.5.915-2.408 1.066-.007.112-.02.224-.032.336-.218 1.643-1.307 3.047-2.832 3.779-1.247 2.1-1.983 2.353-3.423 3.714-.66-1.45-1.067-2.948-1.248-4.455-.15-1.26-.142-2.529.026-3.776-1.678-.772-2.844-2.37-2.892-4.255-.066-2.46 1.797-4.556 4.295-4.982.09-1.504.911-2.838 2.134-3.686-.074-.29-.122-.591-.14-.902-.146-2.708 2.063-5.027 4.938-5.184 1.09-.059 2.114.201 2.976.702.078.017.1-.002.121-.016.798-.585 1.785-.95 2.875-1.01.872-.047 1.706.113 2.448.427.905-1.036 2.24-1.732 3.768-1.815 2.356-.128 4.425 1.238 5.176 3.222.1.263.174.533.225.816.118-.015.23-.026.348-.032.425-.023.839.002 1.237.07 2.17.373 3.891 2.022 4.191 4.137 2.523.213 4.55 2.144 4.684 4.601.075 1.393-.47 2.682-1.413 3.622.265.54.425 1.138.459 1.769z'
              />
              <path
                fill='#2B4F83'
                d='M61.617 114.916L67.683 114.916 67.683 81.404 61.617 81.404z'
              />
              <path
                fill='#B1B1B1'
                d='M55 116.982h13.25V110h-6.226c-3.88 0-7.024 3.126-7.024 6.982'
              />
              <path
                fill='#2B4F83'
                d='M74.157 114.916L80.223 114.916 80.223 81.404 74.157 81.404z'
              />
              <path
                fill='#B1B1B1'
                d='M87.25 116.982H74V110h6.226c3.88 0 7.024 3.126 7.024 6.982'
              />
              <path
                fill='#4A79B5'
                d='M80.247 68.22c-1.488 0-3.093-.09-4.817-.27-2.104-.22-3.63-2.094-3.41-4.186.221-2.09 2.105-3.61 4.211-3.389 6.727.703 9.846-.347 10.474-1.459.996-1.766-1.302-6.634-3.388-9.347-1.286-1.67-.965-4.06.716-5.339 1.682-1.277 4.085-.957 5.37.713 1.346 1.75 7.864 10.827 3.984 17.7-2.11 3.737-6.428 5.578-13.14 5.578M62.852 70.026c-.163 0-.329-.01-.496-.032-2.197-.282-13.271-2.073-15.449-9.652-1.445-5.033 1.451-10.4 8.854-16.41 1.64-1.331 4.053-1.089 5.391.54 1.339 1.63 1.095 4.029-.544 5.36-5.237 4.251-6.684 7.192-6.332 8.418.559 1.948 5.655 3.753 9.062 4.19 2.099.27 3.58 2.18 3.309 4.265-.25 1.92-1.897 3.321-3.795 3.321'
              />
              <path
                fill='#4D4D4D'
                d='M66.94 37.521h.03c.725-.006 1.307-.595 1.3-1.315-.006-.72-.598-1.299-1.323-1.292h-.03c-.724.006-1.306.595-1.3 1.315.007.72.6 1.298 1.323 1.292M77.277 37.521h.03c.725-.006 1.306-.595 1.3-1.315-.006-.72-.598-1.299-1.323-1.292h-.03c-.724.006-1.307.595-1.3 1.315.006.72.598 1.298 1.323 1.292'
              />
              <path
                fill='#FFF'
                d='M71.655 49.38h.022c.541-.005.976-.444.972-.983-.005-.537-.448-.97-.989-.965h-.023c-.541.004-.976.444-.972.983.006.538.448.97.99.966M71.655 56.468h.022c.541-.005.976-.445.972-.983-.005-.538-.448-.97-.989-.965h-.023c-.541.004-.976.445-.972.983.006.537.448.97.99.965M71.655 63.556h.022c.541-.005.976-.445.972-.983-.005-.538-.448-.97-.989-.965h-.023c-.541.004-.976.444-.972.983.006.537.448.97.99.965M71.655 70.644h.022c.541-.005.976-.445.972-.984-.005-.537-.448-.97-.989-.965h-.023c-.541.004-.976.445-.972.983.006.538.448.97.99.966M71.655 77.732h.022c.541-.005.976-.445.972-.983-.005-.538-.448-.971-.989-.966h-.023c-.541.005-.976.445-.972.983.006.538.448.97.99.966'
              />
              <path
                fill='#333'
                d='M75.435 60.032l-.415 8.57c-.038.836-.76 1.49-1.609 1.446l-6.577-.31-.983-.045c-.85-.045-1.5-.762-1.463-1.607l.288-5.87.127-2.692c.045-.844.767-1.497 1.61-1.454l7.56.362c.85.039 1.5.756 1.462 1.6'
              />
              <path
                fill='#E2946D'
                d='M66.834 69.737c-.721.66-1.673 1.086-2.727 1.143-1.213.063-2.337-.368-3.173-1.117-.844-.743-1.392-1.81-1.457-3.015 0-.045-.005-.095-.005-.14 0-.134 0-.26.012-.394.026-.43.115-.844.262-1.23.198-.522.486-.991.849-1.391.128-.14.262-.273.41-.394.006-.012.012-.018.018-.025.71-.596 1.61-.978 2.612-1.028.358-.019.702.006 1.04.07l-.286 5.87c-.039.845.613 1.562 1.462 1.607l.983.044zM73.446 64.368c.067 1.205.616 2.27 1.455 3.017.838.748 1.963 1.177 3.175 1.112 2.427-.13 4.286-2.19 4.155-4.602l-.011-.142c-.043-.502-.17-.977-.367-1.413-.026-.06-.055-.117-.084-.174-.248-.49-.587-.928-.994-1.289-.837-.748-1.962-1.177-3.174-1.112-1 .054-1.901.434-2.607 1.032-.009.006-.017.013-.024.022-.145.121-.28.253-.405.392-.365.404-.655.874-.848 1.39-.146.39-.239.804-.267 1.236-.009.128-.012.258-.009.39l.005.14'
              />
              <path
                fill='#4A79B5'
                d='M124.425 13.802l-1.324-3.07 3.075-1.322 1.318 3.068-3.07 1.324zm.645-6.3c.334.132.497.514.364.847-.133.333-.514.497-.847.364-.334-.133-.498-.515-.365-.848.134-.332.515-.497.848-.364zm-2.178 1.939c-.333-.133-.497-.514-.364-.847.133-.334.514-.497.847-.364.333.133.497.514.364.847-.133.333-.514.497-.847.364zm-.728-1.695c-.334-.133-.497-.514-.364-.847.133-.333.514-.497.847-.364.333.133.497.514.364.848-.133.332-.514.496-.847.363zm-1.149-1.89l-1.324-3.07 3.076-1.322 1.318 3.068-3.07 1.324zm3.327-.05c.333.133.497.515.364.848-.133.333-.514.497-.847.364-.334-.132-.497-.514-.364-.847.132-.333.514-.497.847-.364zm2.74 3.215l3.374-1.45c.33-.141.483-.523.341-.854l-1.58-3.677c-.143-.33-.525-.483-.855-.34l-3.373 1.448L123.54.775c-.075-.163-.203-.284-.36-.347-.152-.06-.333-.063-.494.006l-3.678 1.581c-.33.142-.482.523-.34.854l1.449 3.373-3.372 1.449c-.331.142-.484.524-.341.854l1.58 3.678c.142.33.523.483.854.34l3.373-1.449 1.449 3.373c.072.17.202.284.36.347.157.063.333.063.5-.004l3.677-1.58c.331-.142.483-.524.342-.854l-1.456-3.375z'
              />
              <path
                fill='#FF6454'
                d='M148.998 35.803L144 35.404 146.717 39.528 144.785 44.057 149.633 42.803 153.437 46 153.717 41.101 158 38.549 153.325 36.775 152.167 32z'
              />
              <path
                fill='#69DF86'
                d='M121.327 61.575l-8.916-4.305 3.35-7.075 8.915 4.305-3.349 7.075zm4.871-10.291l-.636-.308.609-1.286-1.275-.615-.608 1.287-5.094-2.46.608-1.287-1.273-.615-.61 1.286-.636-.307c-.707-.341-1.542-.033-1.877.675l-4.268 9.001c-.334.707-.042 1.56.665 1.901l8.915 4.305c.701.339 1.548.036 1.882-.67l4.263-9.006c.334-.707.036-1.563-.665-1.901zm-3.405 3.887l-1.273-.615-.609 1.287 1.273.615.61-1.287zm-2.547-1.23l-1.274-.615-.608 1.287 1.273.615.609-1.287zm-2.547-1.23l-1.274-.614-.609 1.286 1.274.615.61-1.286z'
              />
              <path
                fill='#FFB1A9'
                d='M10.6 70.073l-.987-1.887-1.913.973-.987-1.886-1.913.973.987 1.886-1.913.973.987 1.887 1.912-.973.988 1.887 1.912-.974-.986-1.887 1.912-.972zm-6.62-5.71L10.33 64l1.776 3.395c1.642 3.142.975 7.2-1.613 9.42-3.334.792-7.074-1.024-8.717-4.165L0 69.255l3.98-4.892z'
              />
              <path
                fill='#2A4E82'
                d='M5.348 29.658l-.4-.647-.646.401-.4-.646-.645.401.4.646-.645.4.4.646.645-.4.4.646.645-.4-.4-.647.646-.4zm-.31-3.386c1.722-1.07 5.697-2.34 6.762-.62L13 27.59 2.677 34l-1.2-1.938c-1.065-1.719 1.838-4.72 3.56-5.79zm-3.6-5.814c-1.427.885-1.866 2.758-.981 4.186.884 1.428 2.755 1.867 4.18.982 1.427-.885 1.865-2.759.981-4.187-.884-1.428-2.755-1.867-4.18-.981z'
              />
              <path
                fill='#FFCC46'
                d='M32.395 27.235c1.151-.551 2.546-.094 3.115 1.02.57 1.115.098 2.465-1.054 3.016-1.15.552-2.546.095-3.115-1.02-.569-1.115-.097-2.465 1.054-3.016m-1.374-2.691c-2.682 1.283-3.785 4.44-2.46 7.037 1.804 3.533 9.33 6.419 9.33 6.419s2.203-7.543.4-11.075c-1.327-2.597-4.587-3.665-7.27-2.381'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
