import { css } from '@emotion/react';

import { CardProps, ShadowType } from '../Card/';
import { colorValues } from '../helpers/colors';
import { HeaderProps } from './Header';

const setshadow = (shadow: ShadowType) => {
  switch (shadow) {
    case 'short':
      return '0 0 2px 0 rgba(51, 51, 51, 0.24), 0 2px 2px 0 rgba(51, 51, 51, 0.12)';
    case 'deep':
      return '0 2px 20px 0 rgba(51, 51, 51, 0.24), 0 2px 2px 0 rgba(51, 51, 51, 0.12)';
    case 'line':
      return 'none';
    case 'none':
      return 'none';
    default:
      return '0 2px 20px 0 rgba(51, 51, 51, 0.24), 0 2px 2px 0 rgba(51, 51, 51, 0.12)';
  }
};

export const card = ({
  width,
  fullWidth,
  shadow,
  fullWidthAtBreakpoint: fullWidthAtBreakpoint,
}: Partial<CardProps>) => css`
  display: inline-block;
  vertical-align: top;
  border-radius: 5px;
  box-shadow: ${setshadow(shadow)};
  border: ${shadow === 'line' ? `1px solid ${colorValues.dust}` : ''};
  font-family: Roboto, sans-serif;
  width: ${width ? `${width}px` : 'fit-content'};
  ${fullWidth ? 'width: 100% !important' : ''};
  min-width: min-content;
  background-color: ${colorValues.emptiness};
  @media only screen and (max-width: ${fullWidthAtBreakpoint}px) {
    width: 100%;
  }
`;

export const header = ({ underline }: Partial<HeaderProps>) => css`
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${underline ? `border-bottom: 1px solid ${colorValues.concretejungle};` : ''};
  h1,
  h4,
  h3 {
    margin: 0;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${colorValues.betterptblack};
    max-width: 70%;
  }
  h1 {
    font-size: 20px;
  }
  h3 {
    font-size: 16px;
  }
`;
