import React from 'react';
import { colorValues, TextField } from '@betterpt/better-components';
import { Widget } from '@rjsf/core';

const textFieldStyle = { margin: '10px 0px 20px 0px' };

const errorMap = new Map([['should be string', 'Please enter a value.']]);

const CustomFormInput: Widget = (props) => {
  const { value = '', label, onChange, rawErrors } = props;

  const errorsToRender = (rawErrors ?? []).map((err) => errorMap.get(err));

  const styleOverride = {
    '& label.Mui-focused': {
      color: colorValues.guajirogreen,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colorValues.guajirogreen,
    },
  };

  return (
    <TextField
      value={value}
      label={label}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      style={textFieldStyle}
      overrideStyle={styleOverride}
      error={errorsToRender.length > 0}
      helperText={errorsToRender.length > 0 && errorsToRender[0]}
    />
  );
};
export default CustomFormInput;
