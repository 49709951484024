import * as React from 'react';

// helpers
import { determineVariant } from '../AuthWrapper/loginVariantConfig';

// hooks
import { useHistory, useParams } from 'react-router-dom';
import useAuthentication from '../../../hooks/useAuthentication';
import useRemoteConfig from '../../../hooks/useRemoteConfig';
import useEmailState from '../hooks/useEmailState';
import usePasswordState from '../hooks/usePasswordState';
import useQueryParams from '../hooks/useQueryParams';

// components
import { Button } from '@betterpt/better-components';
import { Link } from 'react-router-dom';
import TextField from '../../Shared/ThemedTextField';
import AuthWrapper from '../AuthWrapper';

// style
import { loginButtonStyle } from '../shared.style';
import useTextFieldState from '../../../hooks/useTextFieldState';
import FormField from '../../Shared/FormField';

const Login: React.FC<React.PropsWithChildren<unknown>> = () => {
  // hooks
  const { push } = useHistory();
  const { variant } = useParams<{ variant: string }>();
  const email = useEmailState();
  const queryParams = useQueryParams();
  const confirmedEmail = queryParams.get('confirmedEmail');
  const sessionExpired = queryParams.get('sessionExpired');
  const decodedEmail = decodeURIComponent(confirmedEmail || '');
  const password = usePasswordState();
  const auth = useAuthentication();
  const remoteConfig = useRemoteConfig();
  const code = useTextFieldState();

  const [isMFARequired, setMFARequired] = React.useState(false);

  const variantConfig = determineVariant(variant);

  const hideSignup = variantConfig.hideSignupPage || remoteConfig.loading;
  // functions
  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    const changePasswordLink = variant
      ? `/change-password/${variant}/${encodeURIComponent(
          email.value || decodedEmail
        )}`
      : `/change-password/${encodeURIComponent(email.value || decodedEmail)}`;

    e.preventDefault();
    auth
      ?.login(
        email.value.trim() || decodedEmail,
        password.value.trim(),
        code.value.trim(),
        variantConfig.passVariantToBackend ? variant : undefined
      )
      .then(() => {
        if (auth.redirectTo) {
          push(auth.redirectTo);
        } else {
          push('/');
        }
      })
      .catch((e) => {
        if (e.message === 'Error: NEW_PASSWORD_REQUIRED') {
          push(changePasswordLink);
        }
        if (e.message === 'Error: SOFTWARE_TOKEN_MFA') {
          setMFARequired(true);
        }
      });
  };

  // values
  const primaryColor = variantConfig.color;

  const disableButton =
    !!email.error ||
    !password.value.trim().length ||
    (email.value.trim().length < 1 && !confirmedEmail) ||
    auth?.isLoading;
  const showPasswordRequired =
    !password.value.trim().length && password.touched;
  const signupLink = variant ? `/signup/${variant}` : '/signup?' + queryParams;
  const forgotPasswordLink = variant
    ? `/forgot-password/${variant}`
    : '/forgot-password?' + queryParams;

  return (
    <AuthWrapper>
      <form onSubmit={handleSubmit}>
        <h1 className='H2' style={{ fontSize: (isMFARequired && 20) || '' }}>
          {(isMFARequired &&
            'Please enter your two-factor authentication code') ||
            'Log In'}
        </h1>
        {!!confirmedEmail && (
          <p>
            Your account has been validated,
            <br />
            you can now log in
          </p>
        )}
        {!!sessionExpired && (
          <p>
            Your session expired.
            <br />
            Please log back in.
          </p>
        )}
        {!isMFARequired && (
          <React.Fragment>
            <TextField
              defaultValue={decodedEmail}
              onChange={(e) => email.updateValue(e.currentTarget.value)}
              label='Your email'
              placeholder='Enter your company contact email here'
              onBlur={email.handleBlur}
              error={!!email.error}
              helperText={email.error}
              data-cy='login-email'
            />
            <TextField
              onChange={(e) => password.updateValue(e.currentTarget.value)}
              label='Password'
              placeholder='Enter your password here'
              type='password'
              error={showPasswordRequired}
              helperText={showPasswordRequired ? 'Required' : ''}
              data-cy='login-password'
            />
          </React.Fragment>
        )}
        {isMFARequired && (
          <FormField
            label='2FA Code'
            placeholder='Enter your two factor authentication code'
            formHandler={code}
          ></FormField>
        )}
        {!isMFARequired && (
          <Link to={forgotPasswordLink} className='forgot-password'>
            <p className='Body button-or-link'>Forgot my password</p>
          </Link>
        )}
        <Button
          loading={auth?.isLoading}
          style={loginButtonStyle(primaryColor)}
          disabled={disableButton}
          type='submit'
          fullWidth
          size='large'
          data-cy='login-button'
        >
          {(isMFARequired && 'CONTINUE') || 'SUBMIT'}
        </Button>
      </form>
    </AuthWrapper>
  );
};

export default Login;
