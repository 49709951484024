import * as React from 'react';

// components
import { Button, InfoBox } from '@betterpt/better-components';
import FormField from '../../../../../Shared/FormField';
import PossibleAddressesDialog from '../../../../../Shared/PossibleAddressesDialog';
import BinaryRadio from '../../../../../Shared/BinaryRadio';
import ServiceAreaSection from './components/ServiceAreaSection';
import {
  AddressFields,
  ZipcodeAndContactInformation,
} from './components/TraditionalSections';
import StateAndCity from './components/StateAndCity';

//hooks
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';
import { useMeQuery } from '../../../../../../generated/graphql';
import useFormValues from './hooks/useFormValues';
import useHandleAddress from './hooks/useHandleAddress';
import useHandleSubmit from './hooks/useHandleSubmit';

// constants
import { colorValues } from '@betterpt/better-components';

//styles
import { Form, CreateFacilityForm, ButtonStyle } from '../../CreateFacility.style';

// assets
import { IcArrowRight } from '@betterpt/better-icons';

const NameAndContactInfo = () => {
  const meQuery = useMeQuery();
  const formValues = useFormValues();
  const {
    facilitiesSingularAlias,
    patientsPluralAlias,
    employeePluralAlias,
    primaryColor,
  } = useRemoteConfigAliases();

  const [isServiceArea, updateIsServiceArea] = React.useState(false);

  React.useEffect(() => {
    if (meQuery?.data?.me?.company?.shouldFacilityDefaultToServiceArea) {
      updateIsServiceArea(true);
    }
  }, [meQuery.data]);

  const disableButton =
    !formValues.facilityName.valid ||
    !formValues.city.valid ||
    !formValues.state.valid ||
    (isServiceArea
      ? !formValues.serviceAreaName.valid
      : !formValues.address.valid ||
        !formValues.address2.valid ||
        !formValues.email.valid ||
        !formValues.zipcode.valid ||
        !formValues.phone.valid);

  const { addresses, handleAddressesDone } = useHandleAddress(formValues);

  const { isLoading, handleSubmit } = useHandleSubmit();

  return (
    <>
      <PossibleAddressesDialog
        addresses={addresses}
        onClose={handleAddressesDone}
      />

      <CreateFacilityForm
        data-testid='create-facility-name-and-contact-container'
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(formValues, isServiceArea);
        }}
      >
        <div className='form-max-width form-max-width-btn-wrapper'>
        <Button
          type='submit'
          style={ButtonStyle(primaryColor)}
          disabled={disableButton || isLoading}
          loading={isLoading}
          className="disabled-btn-style"
        >
          NEXT
        </Button>
        <h4
          style={{
            marginBottom: 20,
            paddingBottom: '35px',
            borderBottom: `1px solid ${colorValues.concretejungle}`,
          }}
        >
          Create Your {facilitiesSingularAlias}
        </h4>
        </div>
      <div className='name-and-contact-info-wrapper'>
        <div className='form-max-width'>
              <div style={{ position: 'relative' }}>
                <BinaryRadio
                  upperLabel='Service Area'
                  label={`Is this ${facilitiesSingularAlias.toLowerCase()} a service area?`}
                  updateValue={updateIsServiceArea}
                  value={isServiceArea}
                  testId='is-service-area'
                  style={{ color: colorValues.charcoalgray }}
                />

                <InfoBox
                  openWith='hover'
                  width={240}
                  height={135}
                  iconHeight={24}
                  iconWidth={24}
                  position='bottom'
                  padding='15px'
                  fixedPlacement
                  mainStyle={{ left: '230px', top: '-5px', zIndex: 100 }}
                  boxStyle={{
                    maxHeight: 'min-content',
                  }}
                >
                  <h4>WHAT DOES THIS MEAN?</h4>
                  <p>
                    If you do not conduct your appointments from a specific address,
                    for example, if you exclusively do home-care or telehealth, we
                    recommend that you set up your{' '}
                    {facilitiesSingularAlias.toLowerCase()} as a service area. Your
                    service area will determine the timezone we use for telehealth
                    calls between {employeePluralAlias.toLowerCase()} and{' '}
                    {patientsPluralAlias.toLowerCase()}.
                  </p>
                </InfoBox>
              </div>

              <h4
                style={{
                  marginBottom: 20,
                }}
              >
                Name {!isServiceArea && 'and Address'}
              </h4>

              <FormField
                id='facilityName'
                fullWidth
                label={`${facilitiesSingularAlias} Name`}
                placeholder={`Enter your ${facilitiesSingularAlias.toLowerCase()} name here`}
                formHandler={formValues.facilityName}
                inputProps={{
                  maxLength: 255,
                }}
              />
              {isServiceArea && (
                <ServiceAreaSection serviceAreaName={formValues.serviceAreaName} />
              )}
              {!isServiceArea && (
                <AddressFields
                  address={formValues.address}
                  address2={formValues.address2}
                />
              )}
              <StateAndCity
                city={formValues.city}
                state={formValues.state}
                isServiceArea={isServiceArea}
              />
              {!isServiceArea && (
                <ZipcodeAndContactInformation
                  zipcode={formValues.zipcode}
                  email={formValues.email}
                  phone={formValues.phone}
                />
              )}
              </div>
      </div>

      </CreateFacilityForm>
    </>
  );
};

export default NameAndContactInfo;
