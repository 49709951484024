import React from 'react';
import Auth from '@aws-amplify/auth';

//hooks
import useAuthentication from '../../../../hooks/useAuthentication';
import useSnackbar from '../../../../hooks/useSnackbar';
import useHandleError from '../../../../hooks/useHandleError';

export type UseMFAReturnType = {
  isEnabled: boolean;
  getQRUrl: () => Promise<void>;
  enableMfa: (code: string) => Promise<void>;
  disableMfa: () => void;
  codeUrl: string;
  loading: boolean;
};

const useMfa = (): UseMFAReturnType => {
  const auth = useAuthentication();
  const handleError = useHandleError();

  const [isEnabled, setIsEnabledTo] = React.useState(false);
  const [codeUrl, setCodeUrl] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const snackbar = useSnackbar();

  React.useEffect(() => {
    if (auth?.user) {
      setIsEnabledTo(auth.user.preferredMFA === 'SOFTWARE_TOKEN_MFA');
    }
  }, [auth]);

  const enableMfa = async (code: string) => {
    if (!auth) return;
    setLoading(true);
    try {
      await Auth.verifyTotpToken(auth.user, code);
      await Auth.setPreferredMFA(auth.user, 'TOTP');
      setIsEnabledTo(true);
      auth.reloadCurrentUser();
      snackbar?.setSuccessMessage('MFA enabled');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const disableMfa = async () => {
    if (!auth) return;
    setLoading(true);
    try {
      await Auth.setPreferredMFA(auth.user, 'NOMFA');
      setIsEnabledTo(false);
      auth.reloadCurrentUser();
      snackbar?.setSuccessMessage('MFA disabled');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const getQRUrl = async () => {
    setLoading(true);
    try {
      const issuer = 'betterPT-provider-app';
      const user = await Auth.currentAuthenticatedUser();
      const username = user?.attributes?.email;
      const code = await Auth.setupTOTP(user);
      setCodeUrl(
        `otpauth://totp/BetterPT:${username}?secret=${code}&issuer=${issuer}`
      );
      setLoading(false);
    } catch (error) {
      handleError(error);
      setLoading(false);
    }
  };

  return {
    isEnabled,
    getQRUrl,
    enableMfa,
    disableMfa,
    codeUrl,
    loading,
  };
};

export default useMfa;
