import React from 'react';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import client from '../config/apollo';
import { firebaseAnalytics } from '../config/firebase';

export interface CognitoCustomUserType extends CognitoUser {
  preferredMFA: any;
}

export const UserContext = React.createContext<{
  user?: CognitoCustomUserType | null;
  isLoading: boolean;
  login: (
    email: string,
    password: string,
    mfaCode?: string,
    signupAffiliate?: string
  ) => Promise<void | any>;
  signup: (
    email: string,
    password: string,
    signupAffiliate?: string
  ) => Promise<void | any>;
  confirmEmail: (email: string, code: string) => Promise<void | any>;
  forceChangePassword: (
    email: string,
    newPassword: string
  ) => Promise<void | any>;
  forgotPassword: (email: string) => Promise<void | any>;
  forgotPasswordSubmit: (
    email: string,
    code: string,
    newPassword: string
  ) => Promise<void | any>;
  resetPassword: (
    oldPassword: string,
    newPassword: string
  ) => Promise<void | any>;
  logout: () => void;
  error: string | undefined;
  clearErrorMessage: () => void;
  reloadCurrentUser: () => void;
  redirectTo: string | undefined;
  redirectAfterLogin: (url: string) => void;
  federatedSignin: (provider: string) => void;
  completeFederatedSignIn: (cognitoUser: CognitoUser) => void;
} | null>(null);

// eslint-disable-next-line react/prop-types
export const AuthProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [user, setUser] = React.useState<CognitoCustomUserType | null>();
  const [isLoading, updateIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [redirectTo, updateRedirectAfterLoginTo] = React.useState<
    string | undefined
  >();

  React.useEffect(() => {
    updateIsLoading(true);
    Auth.currentAuthenticatedUser()
      .then((user: CognitoCustomUserType) => {
        updateIsLoading(false);
        setUser(user);
      })
      .catch(() => {
        console.error('No current user');
        updateIsLoading(false);
        setUser(null);
      });
  }, []);

  const redirectAfterLogin = (url: string) => {
    updateRedirectAfterLoginTo(url);
  };

  const login = async (
    email: string,
    password: string,
    mfaCode?: string,
    signupAffiliate?: string
  ) => {
    updateIsLoading(true);
    try {
      const cognitoUser = await Auth.signIn(
        email.trim().toLowerCase(),
        password.trim()
      );
      if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        updateIsLoading(false);
        setUser(cognitoUser);
        throw new Error('NEW_PASSWORD_REQUIRED');
      } else if (
        mfaCode &&
        cognitoUser.challengeName === 'SOFTWARE_TOKEN_MFA'
      ) {
        const loggedInUser = await Auth.confirmSignIn(
          cognitoUser,
          mfaCode,
          'SOFTWARE_TOKEN_MFA'
        );
        setUser(loggedInUser);
        updateIsLoading(false);
      } else if (
        !mfaCode &&
        cognitoUser.challengeName === 'SOFTWARE_TOKEN_MFA'
      ) {
        updateIsLoading(false);
        throw new Error('SOFTWARE_TOKEN_MFA');
      } else {
        setUser(cognitoUser);
        updateIsLoading(false);
        firebaseAnalytics.logEvent('login', {
          method: 'email',
          partner: signupAffiliate,
        });
        return cognitoUser;
      }
    } catch (err: any) {
      const validErrors = ['NEW_PASSWORD_REQUIRED', 'SOFTWARE_TOKEN_MFA'];
      if (!validErrors.includes(err.message)) {
        setError('This email/password combination is invalid.');
        updateIsLoading(false);
        console.error(err);
      }
      throw new Error(err);
    }
  };

  const signup = (
    email: string,
    password: string,
    signupAffiliate?: string
  ) => {
    updateIsLoading(true);
    return Auth.signUp({
      username: email.trim().toLowerCase(),
      password: password.trim(),
      attributes: signupAffiliate
        ? {
            email: email.trim().toLowerCase(),
            'custom:userType': '2',
            'custom:signupAffiliate': signupAffiliate,
          }
        : {
            email: email.trim().toLowerCase(),
            'custom:userType': '2',
          },
    })
      .then((result) => {
        updateIsLoading(false);
        firebaseAnalytics.logEvent('sign_up', {
          method: 'email',
          partner: signupAffiliate,
        });
        return result;
      })
      .catch((err) => {
        setError('This email/password combination is invalid.');
        updateIsLoading(false);
        console.error(err);
        throw new Error(err);
      });
  };

  const confirmEmail = (email: string, code: string) => {
    updateIsLoading(true);
    return Auth.confirmSignUp(email.trim().toLowerCase(), code.trim())
      .then((result) => {
        updateIsLoading(false);
        return result;
      })
      .catch((err) => {
        setError('This confirmation code is invalid.');
        updateIsLoading(false);
        console.error(err);
        throw new Error(err);
      });
  };

  const forgotPassword = (email: string) => {
    updateIsLoading(true);
    return Auth.forgotPassword(email.trim().toLowerCase())
      .then(() => {
        updateIsLoading(false);
      })
      .catch((err) => {
        setError('This email is invalid.');
        updateIsLoading(false);
        console.error(err);
        throw new Error(err);
      });
  };

  const forgotPasswordSubmit = (
    email: string,
    code: string,
    newPassword: string
  ) => {
    updateIsLoading(true);
    return Auth.forgotPasswordSubmit(
      email.trim().toLowerCase(),
      code,
      newPassword
    )
      .then(() => {
        updateIsLoading(false);
      })
      .catch((err) => {
        setError('This email/password combination is invalid.');
        updateIsLoading(false);
        console.error(err);
        throw new Error(err);
      });
  };

  const clearErrorMessage = () => setError(undefined);

  const logout = () =>
    Auth.signOut().then((data) => {
      firebaseAnalytics.logEvent('logout');
      updateRedirectAfterLoginTo(undefined);
      setUser(null);
      client.cache.reset();
      return data;
    });

  const reloadCurrentUser = () => {
    Auth.currentAuthenticatedUser().then((user: CognitoCustomUserType) => {
      setUser(user);
    });
  };

  const values = React.useMemo(() => {
    const resetPassword = (oldPassword: string, newPassword: string) => {
      updateIsLoading(true);
      return Auth.changePassword(user, oldPassword, newPassword)
        .then(() => {
          updateIsLoading(false);
        })
        .catch((err) => {
          setError('This password is invalid.');
          updateIsLoading(false);
          console.error(err);
          throw new Error(err.message);
        });
    };

    const forceChangePassword = (email: string, newPassword: string) => {
      updateIsLoading(true);
      return Auth.completeNewPassword(user, newPassword, {
        email: email.trim().toLowerCase(),
      })
        .then((user) => {
          setUser(user);
          updateIsLoading(false);
          return user;
        })
        .catch((err) => {
          setError('This password is invalid.');
          updateIsLoading(false);
          console.error(err);
          throw new Error(err);
        });
    };

    // Federated Signin

    const federatedSignin = async (provider: string) => {
      try {
        const providers = new Map([
          ['hss', 'hssadfs'],
          ['select', 'select'],
        ]);
        const customProvider = providers.get(provider);
        if (customProvider) {
          console.log(customProvider);
          const fed = await Auth.federatedSignIn({ customProvider });
          console.log(fed);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const completeFederatedSignIn = async (cognitoUser: CognitoUser) => {
      const token =
        cognitoUser?.getSignInUserSession()?.getIdToken()?.getJwtToken() ||
        user?.getSignInUserSession()?.getIdToken()?.getJwtToken() ||
        '';

      window.localStorage.setItem('activeToken', token);
      user?.getUserAttributes(async (err, result) => {
        if (err) console.error('Error getting user attributes: ', err);
        else {
          const creds = {
            email: result
              ?.find((res) => res?.getName() === 'email')
              ?.getValue(),
            password: '',
          };
          const identities = JSON.parse(
            result
              ?.find((res) => res?.getName() === 'identities')
              ?.getValue() ?? ''
          );
          if (identities[0] && identities[0].providerType === 'SAML') {
            localStorage.setItem('providerType', 'SAML');
          }
          console.log(creds);
        }
      });
    };

    return {
      user,
      login,
      signup,
      confirmEmail,
      forceChangePassword,
      forgotPassword,
      forgotPasswordSubmit,
      resetPassword,
      logout,
      clearErrorMessage,
      reloadCurrentUser,
      redirectTo,
      redirectAfterLogin,
      federatedSignin,
      completeFederatedSignIn,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <UserContext.Provider value={{ ...values, isLoading, error }}>
      {children}
    </UserContext.Provider>
  );
};
