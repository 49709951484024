import React from 'react';
import axios from 'axios';
import { loader } from 'graphql.macro';

//helpers
import {
    hasValueChangedInObject,
    nameValidation,
    Roles,
} from '../../../../../helpers';

//hooks
import {
  useMeQuery,
  useUpdateCompanyMutation,
} from '../../../../../generated/graphql';
import useAuthentication from '../../../../../hooks/useAuthentication';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../../hooks/useSnackbar';
import useTextFieldState from '../../../../../hooks/useTextFieldState';
import useHandleError from '../../../../../hooks/useHandleError';

//components
import {
  Header,
  Button,
  Animation,
  ImageUploader,
  InfoBox,
} from '@betterpt/better-components';
import FormField from '../../../../Shared/FormField';
import Can from '../../../../Can';

//assets
import PlaceholderImage from '../../../../../assets/img-unknown-user.svg';

//styles
import { GridCard } from '../../../../Shared/GridCard';
import { FormBody, buttonStyle } from '../../Company.style';
import { CardTitle, headerStyle } from '../CompanyInfoTab.style';
import { ProfileImage } from '../../../Employees/EmployeeDetail/styles/EmployeeDetail.style';

//types
const QUERY_ME = loader('../../../../../gql/employee/Me.graphql');

const infoboxTextStyle = { fontWeight: 'normal' as const };
const infoboxStyle = { top: 45, marginLeft: '40px' };

const LogoCard = () => {
    //hooks
    const snackbar = useSnackbar();
    const handleError = useHandleError();
    const meQuery = useMeQuery();
    const auth = useAuthentication();
    const { primaryColor } = useRemoteConfigAliases();
    const [isEditModeActive, setEditModeActive] = React.useState(false);
    const [loading, updateLoading] = React.useState(false);
    const [logo, updateLogo] = React.useState('');
    const companyName = useTextFieldState({
        validation: nameValidation,
    });

  const [updateCompany, updateCompanyOptions] = useUpdateCompanyMutation({
    refetchQueries: [{ query: QUERY_ME }],
  });

  React.useEffect(() => {
    if (meQuery.data?.me?.company?.smallAvatarUrl) {
      updateLogo(meQuery.data?.me?.company?.smallAvatarUrl);
    }
  }, [meQuery.data]);

  React.useEffect(() => {
    if (meQuery.data) {
      const company = meQuery.data?.me?.company;
      companyName.updateValue(company?.companyName ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meQuery.data]);

  // functions
  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const companyId = meQuery.data?.me?.company?.id;
    if (!companyId) return;
    try {
      const companyContact = {
        companyName: companyName.value,
      };
      hasValueChangedInObject(meQuery.data?.me?.company, companyContact) &&
        (await updateCompany({
          variables: {
            input: {
              companyId,
              ...companyContact,
            },
          },
        }));
      snackbar?.setSuccessMessage('Company name saved');
      setEditModeActive(false);
    } catch (e) {
      handleError(e);
    }
  };

  const postLogo = async (base64Img: string) => {
    updateLoading(true);
    const userSession = auth?.user?.getSignInUserSession();
    const token = userSession?.getIdToken();
    const endpoint =
      window.__RUNTIME_CONFIG__.REACT_APP_API_ENDPOINT ??
      'https://api.development.betterpt.com/v0/api';
    try {
      await axios.post(
        `${endpoint}/companies/${meQuery.data?.me?.company?.id}/avatar`,
        { file: base64Img },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: token?.getJwtToken() as string,
          },
        }
      );
      await meQuery.refetch();
      updateLogo(base64Img);
      updateLoading(false);
    } catch (e) {
      updateLoading(false);
      handleError(e);
    }
  };

    React.useEffect(() => {
        if (meQuery.data) {
            const company = meQuery.data?.me?.company;
            companyName.updateValue(company?.companyName?.trim() ?? '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meQuery.data]);
  // values
  // const company = meQuery.data?.me?.company;

  // const isNotWhiteLabel =
  //   company?.providerAppId !== 'acorn' && company?.providerAppId !== 'somatus';
  const disableButton = !companyName.valid;

  const saving = !!updateCompanyOptions.loading;

  const fetching = meQuery.loading;
  return (
    <GridCard>
      {fetching ? (
        <Animation type='providerAppLoader' />
      ) : (
        <form onSubmit={handleSubmit}>
          <Header underline style={headerStyle}>
            <CardTitle>Logo and Name</CardTitle>
            <Can
              role={Roles[meQuery.data?.me?.role || 'initial']}
              perform='company:edit'
              yes={() => (
                <>
                  {isEditModeActive ? (
                    <Button
                      disabled={disableButton || saving}
                      size='small'
                      color='transparent'
                      type='submit'
                      style={buttonStyle(primaryColor, true)}
                      loading={saving}
                    >
                      SAVE
                    </Button>
                  ) : (
                    <Button
                      size='small'
                      color='transparent'
                      style={buttonStyle(primaryColor)}
                      onClick={() => setEditModeActive(true)}
                    >
                      EDIT
                    </Button>
                  )}
                </>
              )}
            />
          </Header>
          <FormBody>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Can
                role={Roles[meQuery.data?.me?.role || 'initial']}
                perform='company:edit'
                yes={() => (
                  <InfoBox
                    openWith='hover'
                    width={230}
                    height={200}
                    boxStyle={infoboxStyle}
                    icon={
                      <ImageUploader
                        loading={loading}
                        type='avatar'
                        width={46}
                        image={logo || PlaceholderImage}
                        onSubmit={(event) => {
                          postLogo(event.base64Img);
                        }}
                        style={{ marginRight: 20 }}
                        onError={(message) =>
                          snackbar?.openSnackbar({ isError: true, message })
                        }
                      />
                    }
                  >
                    <h4>COMPANY LOGO</h4>
                    <p style={infoboxTextStyle}>
                      Drag or click to upload a company logo. Maximum 250x250
                      px.
                    </p>
                    <p style={infoboxTextStyle}>
                      Please note, we only support .JPG, .PNG or .PDF files.
                    </p>
                  </InfoBox>
                )}
                no={() => <ProfileImage src={logo || PlaceholderImage} />}
              />
              <FormField
                disabled={!isEditModeActive}
                fullWidth
                label='Company Name'
                placeholder='Enter your company name here'
                formHandler={companyName}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </div>
          </FormBody>
        </form>
      )}
    </GridCard>
  );
};

export default LogoCard;
