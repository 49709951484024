import React from 'react';
import { colorValues, TextField } from '@betterpt/better-components';


type Props = {
    value?: string;
    label: string;
    onChange: any;
    rawErrors?: [];
    elementId?: number;
    elementKey?: string;
}

const textFieldStyle = { margin: '10px 0px 10px 0px' };

const errorMap = new Map([['should be string', 'Please enter a value.']]);

const CustomFormInputNew = (props: Props) => {
    const { value = '', label, onChange, rawErrors, elementId, elementKey } = props;
    const [text, setText] = React.useState(value);
    const errorsToRender = (rawErrors ?? []).map((err) => errorMap.get(err));

    const styleOverride = {
        '& label.Mui-focused': {
            color: colorValues.guajirogreen,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: colorValues.guajirogreen,
        },
    };

    return (
        <TextField
            value={text}
            label={label}
            onChange={(e) => setText(e.target.value)}
            fullWidth
            style={textFieldStyle}
            overrideStyle={styleOverride}
            error={errorsToRender.length > 0}
            helperText={errorsToRender.length > 0 && errorsToRender[0]}
            onBlur={(e) => onChange(elementId, elementKey, e.target.value)}
        />
    );
};
export default CustomFormInputNew;
