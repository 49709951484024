import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='54'
    height='54'
    viewBox='0 0 54 54'
    {...rest}
  >
    <defs>
      <path
        id='h3xnkc1hna'
        d='M36 13.5L41.153 18.652 30.172 29.633 21.172 20.633 4.5 37.328 7.673 40.5 21.172 27 30.172 36 44.347 21.848 49.5 27 49.5 13.5z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0L54 0 54 54 0 54z' />
      <mask id='3y1spsnf4b' fill='#fff'>
        <use xlinkHref='#h3xnkc1hna' />
      </mask>
      <g fill={color ?? '#12A7A3'} mask='url(#3y1spsnf4b)'>
        <path d='M0 0H54V54H0z' />
      </g>
    </g>
  </svg>
);
