import React from 'react';

import { InfoBox, colorValues } from '@betterpt/better-components';
import { IcSuccessCircle } from '@betterpt/better-icons';

type Props = {
  message?: string;
  position?: 'left' | 'right' | 'top' | 'bottom';
  mainStyle?: { [key: string]: any };
};

const ValidatedPhoneNumber = ({ message, position, mainStyle }: Props) => (
  <InfoBox
    openWith='hover'
    height={120}
    width={200}
    boxStyle={{ zIndex: 5 }}
    padding={0}
    iconHeight={24}
    iconWidth={24}
    position={position ?? 'right'}
    mainStyle={mainStyle ?? {}}
    fixedPlacement
    icon={
      <IcSuccessCircle
        color={colorValues.guajirogreen}
        aria-label='Verified phone number'
      />
    }
  >
    <div>
      <h3 className='H6'>VALID PHONE NUMBER</h3>
      <p className='Body' style={{ fontSize: '14px' }}>
        {message ||
          'This phone number has been validated and is eligible to receive SMS notifications.'}
      </p>
    </div>
  </InfoBox>
);

export default ValidatedPhoneNumber;
