import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24" {...rest}>
    <defs>
        <path id="xpmj1la3na" d="M22.725 12.217C19.93 9.607 16.155 8 11.995 8S4.06 9.607 1.265 12.217c-.164.162-.265.388-.265.64 0 .254.1.48.266.642l2.272 2.24c.165.162.394.261.65.261.248 0 .477-.1.642-.253.724-.668 1.549-1.228 2.437-1.67.303-.145.514-.452.514-.813v-2.799c1.328-.433 2.748-.66 4.214-.66 1.466 0 2.887.227 4.215.651v2.8c0 .351.211.667.513.812.898.442 1.714 1.011 2.447 1.67.165.163.394.253.641.253.257 0 .486-.1.65-.262l2.273-2.24c.165-.162.266-.387.266-.64s-.11-.47-.275-.632z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
        <path d="M0 0L24 0 24 24 0 24z"/>
        <mask id="q2sfoxwk0b" fill="#fff">
            <use xlinkHref="#xpmj1la3na"/>
        </mask>
        <use fill="#333" fillRule="nonzero" xlinkHref="#xpmj1la3na"/>
        <g fill={color ?? "#FFF"} mask="url(#q2sfoxwk0b)">
            <path d="M0 0H24V24H0z"/>
        </g>
    </g>
</svg>
)