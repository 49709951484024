import * as React from 'react';

//hooks
import useSnackbar from '../../../../hooks/useSnackbar';
import { useMarkAppointmentAsNoShowMutation } from '../../../../generated/graphql';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';

//components
import Dialog from '@material-ui/core/Dialog';
import { Button, colorValues, bestTextColor } from '@betterpt/better-components';
import SlideTransition from '../../../Shared/SlideTransition';

//style
// import {
//   buttonStyle,
//   DialogBody,
//   DialogParagraph,
//   H1,
// } from '../InPersonOptions.style';
import styled from '@emotion/styled';
import useHandleError from '../../../../hooks/useHandleError';

type Props = {
  open: boolean;
  onClose: () => void;
  appointmentId?: string;
  onDoneChangingOptionsAppointment: () => Promise<any> | undefined;
};

const buttonStyle = (color?: string) => ({
    backgroundColor: color || colorValues.cityblock,
    color: bestTextColor(color ?? colorValues.cityblock),
    fontWeight: 'bold' as 'bold',
    marginTop: '20px',
  });

const DialogBody = styled.div`
  position: relative;
  padding: 47px 45px 36px 48px;
  margin: 5px;
`;

const DialogParagraph = styled.p`
  margin-bottom: 25px;
  line-height: 1.5;
  max-width: 480px;
`;

const H1 = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 24px;
`;

const CancelAppointmentDialog = ({
  open,
  onClose,
  appointmentId,
  onDoneChangingOptionsAppointment,
}: Props) => {
  const snackbar = useSnackbar();

  const [saving, updateSaving] = React.useState(false);

  const [markAsNoShow] = useMarkAppointmentAsNoShowMutation();

  const { primaryColor } = useRemoteConfigAliases();
  const handleError = useHandleError();

  const handleMarkNoShow = async () => {
    if (!appointmentId) return;
    updateSaving(true);
    try {
      await markAsNoShow({
        variables: {
          id: appointmentId,
        },
      });
      snackbar?.setSuccessMessage('Appointment marked as no show');

      setTimeout(async () => {
        await onDoneChangingOptionsAppointment();
        updateSaving(false);
        onClose();
      }, 1000);
    } catch (e) {
      updateSaving(false);
      handleError(e);
      return;
    }
  };

  return (
    <Dialog
      TransitionComponent={SlideTransition}
      open={open}
      onClose={() => onClose()}
      maxWidth='sm'
      fullWidth
    >
      <DialogBody>
        <H1>Mark as No Show</H1>
        <DialogParagraph className='Body'>
          Are you sure you want to mark this appointment as a no show?
        </DialogParagraph>
        <Button
          style={buttonStyle(primaryColor)}
          size='large'
          loading={saving}
          fullWidth
          onClick={handleMarkNoShow}
        >
          MARK AS NO SHOW
        </Button>
        <Button
          style={buttonStyle(colorValues.emptiness)}
          size='large'
          loading={saving}
          fullWidth
          onClick={onClose}
        >
          NEVER MIND
        </Button>
      </DialogBody>
    </Dialog>
  );
};

export default CancelAppointmentDialog;