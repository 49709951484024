import * as React from 'react';
import { VideoAppointmentFiltersContext } from '../context/videoAppointmentFilters';

export default function useVideoAppointmentFilters() {
  const context = React.useContext(VideoAppointmentFiltersContext);

  if (context === undefined) {
    throw new Error(
      '`useInPersonAppointmentFilters` hook must be used within correct context component'
    );
  }
  return context;
}
