import React from 'react';

export default ({
  color,
  secondaryColor,
  ...rest
}: { color?: string; secondaryColor?: string } & React.SVGProps<
  SVGSVGElement
>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...rest}
  >
    <defs>
      <path
        id='le0ujnshua'
        d='M9 11.75c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zm6 0c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8 0-.29.02-.58.05-.86 2.36-1.05 4.23-2.98 5.21-5.37C11.07 8.33 14.05 10 17.42 10c.78 0 1.53-.09 2.25-.26.21.71.33 1.47.33 2.26 0 4.41-3.59 8-8 8z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g transform='translate(-12 -293) translate(0 116) translate(0 177) translate(12)'>
              <path d='M0 0L24 0 24 24 0 24z' />
              <mask id='o5h6lz08wb' fill={secondaryColor ?? '#fff'}>
                <use xlinkHref='#le0ujnshua' />
              </mask>
              <use
                fill={color ?? '#333'}
                fillRule='nonzero'
                xlinkHref='#le0ujnshua'
              />
              <g fill={color ?? '#333'} mask='url(#o5h6lz08wb)'>
                <path d='M0 0H24V24H0z' />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
