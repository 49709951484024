import * as React from 'react';

//helpers
import { addIndefiniteArticle } from '../../../../helpers';

//hooks
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';

//components
import BlockedPageMessage from '../../../Shared/BlockedPageMessage';

//assets
import { ImgAdminGuy, ImgBrunetteGirlRedShirt } from '@betterpt/better-icons';

const NoPatientsFound = ({
  isNoResultsPage,
  resetFilters,
  copyOptions,
}: {
  isNoResultsPage?: boolean;
  resetFilters?: () => void;
  copyOptions?: {
    noFacilities: boolean;
    companyHasNoFacilities: boolean;
    noAssignedFacilities: boolean;
    noPatients: boolean;
  };
}) => {
  const {
    facilitiesPluralAlias,
    facilitiesSingularAlias,
    patientsSingularAlias,
    patientsPluralAlias,
    loading: remoteConfigLoading,
  } = useRemoteConfigAliases();
  const loading = remoteConfigLoading;

  const generateBlockPageCopy = () => {
    let icon = <ImgBrunetteGirlRedShirt />;
    let title = '';
    let message = '';
    let buttonText = '';
    let pathname = '';
    let onClick: (() => void) | undefined = undefined;

    if (isNoResultsPage) {
      icon = <ImgAdminGuy />;
      title = `No ${patientsPluralAlias.toLowerCase()} found matching these filters`;
      message = 'Try resetting your filters by clicking the button below';
      buttonText = `RESET ${patientsSingularAlias.toUpperCase()} FILTERS`;
      onClick = resetFilters;
    } else if (copyOptions?.noPatients) {
      title = `No ${patientsPluralAlias} Yet`;
      message = `Click the button below to create ${addIndefiniteArticle(
        patientsSingularAlias.toLowerCase()
      )}.`;
      buttonText = `ADD ${addIndefiniteArticle(
        patientsSingularAlias
      ).toUpperCase()}`;
      pathname = '/patients/new';
    }

    return {
      icon,
      title,
      message,
      buttonText,
      pathname,
      onClick,
    };
  };
  const blockPageCopy = generateBlockPageCopy();

  return loading ? null : (
    <BlockedPageMessage
      icon={blockPageCopy.icon}
      title={blockPageCopy.title}
      message={blockPageCopy.message}
      buttonText={blockPageCopy.buttonText}
      pathname={blockPageCopy.pathname}
      onClick={blockPageCopy.onClick}
      style={{ paddingBottom: 50 }}
      wideText
    />
  );
};

export default NoPatientsFound;
