import { datadogRum } from '@datadog/browser-rum';

// hooks
import {
  useCreateCompanyFacilityMutation,
  useCreateCompanyFacilityWithServiceAreaMutation,
  useMeQuery,
} from '../../../../../../../generated/graphql';
import { useHistory } from 'react-router-dom';
import useHandleError from '../../../../../../../hooks/useHandleError';

// types
import { TextFieldState } from '../../../../../../../hooks/useTextFieldState';

type FormValues = {
  [k: string]: TextFieldState;
};

const useHandleSubmit = () => {
  const handleError = useHandleError();
  const history = useHistory();

  // apollo mutations
  const [createCompanyFacility, createCompanyFacilityOptions] =
    useCreateCompanyFacilityMutation();
  const [
    createCompanyFacilityWithServiceArea,
    createCompanyFacilityWithServiceAreaOptions,
  ] = useCreateCompanyFacilityWithServiceAreaMutation();

  const meQuery = useMeQuery();

  const handleSubmit = async (
    formValues: FormValues,
    isServiceArea: boolean
  ) => {
    const companyId = meQuery?.data?.me?.company?.id;
    if (!companyId) return;

    const handlePostMutation = (id?: string | null) => {
      history.push({
        pathname: `/facilities/new/${id}/hours-and-about`,
      });
      datadogRum.addUserAction('create_new_facility', {
        companyId,
        clinicName: formValues.facilityName.value,
        city: formValues.city.value,
        state: formValues.state.value,
        isServiceArea,
      });
    };

    try {
      const sharedValues = {
        companyId,
        clinicName: formValues.facilityName.value.trim(),
        city: formValues.city.value.trim(),
        state: formValues.state.value,
      };

      if (isServiceArea) {
        const facilityResult = await createCompanyFacilityWithServiceArea({
          variables: {
            input: {
              ...sharedValues,
              serviceAreaName: formValues.serviceAreaName.value.trim(),
            },
          },
        });
        handlePostMutation(
          facilityResult?.data?.createClinicWithServiceArea?.id
        );
      } else {
        const initialPhone = formValues.phone.value;
        const bestPhone =
          initialPhone?.charAt(0) === '1'
            ? initialPhone.slice(1)
            : initialPhone;
        const facilityResult = await createCompanyFacility({
          variables: {
            input: {
              ...sharedValues,
              address: formValues.address.value.trim(),
              address2: formValues.address2.value,
              zipcode: formValues.zipcode.value,
              email: formValues.email.value,
              phone: bestPhone.split('-').join(''),   
              useClinicName: true,
            },
          },
        });
        handlePostMutation(facilityResult?.data?.createClinic?.id);
      }
    } catch (e) {
      handleError(e);
    }
  };

  const isLoading =
    createCompanyFacilityOptions.loading ||
    createCompanyFacilityWithServiceAreaOptions.loading;

  return { handleSubmit, isLoading };
};

export default useHandleSubmit;
