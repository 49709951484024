import React from 'react';

import { InfoBox } from '@betterpt/better-components';

const NotMigratedInfoBox = ({
  icon,
  isRequest,
  facilitiesSingularAlias,
}: {
  icon: JSX.Element;
  isRequest: boolean;
  facilitiesSingularAlias: string;
}) => (
  <InfoBox
    openWith='hover'
    height={213}
    width={285}
    position='bottom'
    padding={0}
    iconHeight={24}
    iconWidth={24}
    icon={icon}
    fullWidth
  >
    <div>
      <h4>{facilitiesSingularAlias.toUpperCase()} NOT YET MIGRATED</h4>
      <p style={{ fontSize: '14px' }}>
        The {facilitiesSingularAlias.toLowerCase()} for this{' '}
        {isRequest ? 'appointment' : 'request'} has not yet been migrated to the
        BetterAccess platform. Please {isRequest ? 'respond to' : 'manage'} the{' '}
        {isRequest ? 'request' : 'appointment'} from the BetterPT Clinic App at{' '}
        <b>clinics.betterpt.com</b>.
        <br />
        <br />
        If you would like this {facilitiesSingularAlias.toLowerCase()} to be
        migrated to BetterAccess, please reach out to us at
        support@betterhealthcare.co
      </p>
    </div>
  </InfoBox>
);

export default NotMigratedInfoBox;
