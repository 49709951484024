import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...rest}
  >
    <defs>
      <filter
        id='bbjaaqb4ka'
        width='118.2%'
        height='132.2%'
        x='-9.1%'
        y='-16.1%'
        filterUnits='objectBoundingBox'
      >
        <feOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur
          in='shadowOffsetOuter1'
          result='shadowBlurOuter1'
          stdDeviation='5'
        />
        <feColorMatrix
          in='shadowBlurOuter1'
          result='shadowMatrixOuter1'
          values='0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.47 0'
        />
        <feMerge>
          <feMergeNode in='shadowMatrixOuter1' />
          <feMergeNode in='SourceGraphic' />
        </feMerge>
      </filter>
      <circle id='uic6hdpweb' cx='11' cy='11' r='11' />
      <path
        id='6fdw027nrd'
        d='M6 1.25c2.623 0 4.75 2.127 4.75 4.75S8.623 10.75 6 10.75 1.25 8.623 1.25 6 3.377 1.25 6 1.25zm0 .5C3.653 1.75 1.75 3.653 1.75 6S3.653 10.25 6 10.25 10.25 8.347 10.25 6 8.347 1.75 6 1.75zm0 .75c1.933 0 3.5 1.567 3.5 3.5S7.933 9.5 6 9.5 2.5 7.933 2.5 6 4.067 2.5 6 2.5z'
      />
    </defs>
    <g
      fill='none'
      fillRule='evenodd'
      filter='url(#bbjaaqb4ka)'
      transform='translate(-4 -112)'
    >
      <g transform='translate(5 113)'>
        <mask id='2f9q324syc' fill='#fff'>
          <use xlinkHref='#uic6hdpweb' />
        </mask>
        <circle cx='11' cy='11' r='11.5' stroke='#000' />
        <g fill='#E74C3C' mask='url(#2f9q324syc)'>
          <path d='M0 0H24V24H0z' transform='translate(-1 -1)' />
        </g>
        <g mask='url(#2f9q324syc)'>
          <g transform='translate(5 5)'>
            <path d='M0 0L12 0 12 12 0 12z' />
            <mask id='nfl4td05ae' fill='#fff'>
              <use xlinkHref='#6fdw027nrd' />
            </mask>
            <use
              fill={color ?? '#E74C3C'}
              fillRule='nonzero'
              xlinkHref='#6fdw027nrd'
            />
            <g fill='#FFF' mask='url(#nfl4td05ae)'>
              <path d='M0 0H12V12H0z' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
