import * as React from 'react';

//hooks
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';
import {
  useInPersonAppointmentDetailsQuery,
  useUpdateInPersonAppointmentStartTimeMutation,
} from '../../../../../../generated/graphql';

//components
import Dialog from '@material-ui/core/Dialog';
import { Animation, Button, CloseButton } from '@betterpt/better-components';
import { GeneralizedEmployeePicker } from '../../../Shared/EmployeePicker';
import SlideTransition from '../../../../../Shared/SlideTransition';

//style
import { colorValues } from '@betterpt/better-components';
import {
  buttonStyle,
  DialogBody,
  DialogParagraph,
  H1,
} from '../InPersonOptions.style';
import useHandleError from '../../../../../../hooks/useHandleError';

type Props = {
  open: boolean;
  onClose: () => void;
  appointmentId?: string;
  onDoneChangingOptionsAppointment: () => Promise<any> | undefined;
};

const AssignProviderDialog = ({
  open,
  onClose,
  appointmentId,
  onDoneChangingOptionsAppointment,
}: Props) => {
  const handleError = useHandleError();
  const { primaryColor } = useRemoteConfigAliases();

  const [saving, updateSaving] = React.useState(false);
  const [employeeId, updateEmployeeId] = React.useState<string>('');

  const [
    changeAppointment,
    changeAppointmentOptions,
  ] = useUpdateInPersonAppointmentStartTimeMutation();

  const appointmentQuery = useInPersonAppointmentDetailsQuery({
    variables: { id: appointmentId! },
    skip: !appointmentId,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const facilityId = appointmentQuery.data?.appointment?.clinic?.id;
  const apptTime = new Date(
    appointmentQuery.data?.appointment?.confirmedTime ??
      appointmentQuery.data?.appointment?.startTime ??
      new Date()
  );

  React.useEffect(() => {
    const existingEmployeeId = appointmentQuery.data?.appointment?.employee?.id;
    if (existingEmployeeId) {
      updateEmployeeId(existingEmployeeId);
    }
  }, [appointmentQuery.data]);

  const handleRescheduleSubmit = async () => {
    if (!appointmentId || !employeeId) return;
    updateSaving(true);
    try {
      // add the new employee
      await changeAppointment({
        variables: {
          input: {
            appointmentId: appointmentId!,
            employeeId,
            startTime: apptTime,
          },
        },
      });

      setTimeout(async () => {
        await onDoneChangingOptionsAppointment();
        updateSaving(false);
        onClose();
      }, 1000);
    } catch (e) {
      updateSaving(false);
      handleError(e);
      return;
    }
  };

  const renderContent = () => {
    if (appointmentQuery.loading) {
      return <Animation type='providerAppLoader' />;
    } else {
      return (
        <DialogBody>
          <H1>Assign a Provider</H1>
          <DialogParagraph className='Body' style={{ marginBottom: '54px' }}>
            Please select a provider to assign to this appointment.
          </DialogParagraph>
          <GeneralizedEmployeePicker
            employee={{
              value: employeeId,
              updateValue: updateEmployeeId,
            }}
            facilityId={facilityId}
          />

          <Button
            fullWidth
            size='large'
            style={buttonStyle(primaryColor)}
            onClick={handleRescheduleSubmit}
            disabled={!employeeId}
            loading={saving || changeAppointmentOptions.loading}
          >
            CONFIRM PROVIDER
          </Button>
          <Button
            fullWidth
            size='large'
            color='transparent'
            style={buttonStyle(colorValues.emptiness)}
            onClick={onClose}
          >
            CANCEL
          </Button>
          <CloseButton onClose={onClose} />
        </DialogBody>
      );
    }
  };

  return (
    <Dialog
      TransitionComponent={SlideTransition}
      open={open}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
    >
      {renderContent()}
    </Dialog>
  );
};

export default AssignProviderDialog;
