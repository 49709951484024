import * as React from 'react';

//types
import { CreateInPersonAppointmentForm } from '../../InPersonAppointments/CreateInPersonAppointment/hooks/useCreateInPersonAppointmentFormHandlers';
import { CreateVideoAppointmentForm } from '../../VideoAppointments/CreateVideoAppointment/hooks/useCreateVideoAppointmentFormHandlers';
import { Employee, Role } from '../../../../../generated/graphql';

//helpers
import { normalizePhone } from '../../../../../helpers';

//hooks
import {
  useMeQuery,
  useEmployeeQuery,
  useFacilityEmployeesQuery,
} from '../../../../../generated/graphql';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';

//components
import { Animation } from '@betterpt/better-components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ThemedTextField from '../../../../Shared/ThemedTextField';

//styles
import {
  AutocompleteStyleWrapper,
  CardSection,
  employeeDrawerContainer,
} from './EmployeePicker.style';
const autoCompleteStyle = { maxWidth: '521px' };

type Props = {
  form: CreateInPersonAppointmentForm | CreateVideoAppointmentForm;
  facilityId: string;
  disabled?: boolean;
  lockEmployee?: boolean;
  isForTelehealth?: boolean;
};

const EmployeePicker: React.FC<React.PropsWithChildren<Props>> = ({
  form,
  facilityId,
  lockEmployee,
  isForTelehealth,
}) => {
  const [open, setOpen] = React.useState(false);
  const [nameInput, updateNameInput] = React.useState('');

  const { facilitiesSingularAlias } = useRemoteConfigAliases();

  const queryMe = useMeQuery();

  const queryEmployees = useFacilityEmployeesQuery({
    variables: {
      id: facilityId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !facilityId || lockEmployee,
  });

  const employeeDetails = useEmployeeQuery({
    variables: {
      id: form.employeeId.value,
    },
    skip: !lockEmployee || !form.employeeId.value,
  });

  // values
  const isBasicAccount = queryMe.data?.me?.role === Role.Self;
  const employees: any[] = isBasicAccount
    ? [queryMe.data?.me]
    : queryEmployees.data?.clinic?.employees ?? [];
  const providers = employees.filter((staff) => staff?.isTherapist);

  const sharedProps = {
    selectOnFocus: true,
    clearOnBlur: true,
    blurOnSelect: true,
    fullWidth: true,
  };
  const loading = queryMe.loading || queryEmployees.loading;

  React.useEffect(() => {
    if (facilityId) {
      updateNameInput('');
      if (!lockEmployee) {
        form.employeeId.updateValue('');
        form.provider.updateValue(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId]);

  // static employee info
  if (lockEmployee) {
    if (employeeDetails.loading) {
      return <Animation type='providerAppLoader' />;
    }

    return (
      <CardSection>
        <h2 className='H6'>Appointment Details</h2>
        <ThemedTextField
          label='Provider'
          style={autoCompleteStyle}
          disabled
          fullWidth
          value={`${employeeDetails.data?.employee?.firstName ?? ''} ${
            employeeDetails.data?.employee?.lastName ?? ''
          }`}
        />
      </CardSection>
    );
  }

  const renderEmployeeOption = (employee: Employee) => (
    <p style={{ margin: '0px' }}>
      {employee?.firstName} {employee?.lastName} ({employee?.email}
      {employee?.phone ? `, ${normalizePhone(employee?.phone)}` : ''})
    </p>
  );

  return (
    <CardSection>
      <AutocompleteStyleWrapper>
        <h2 className='H6'>Appointment Details</h2>
        <div style={employeeDrawerContainer}>
          <div style={{ width: '100%' }}>
            <Autocomplete
              {...sharedProps}
              options={providers}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              disabled={loading || !facilityId}
              inputValue={nameInput}
              style={autoCompleteStyle}
              loading={queryEmployees.loading}
              loadingText='Loading...'
              onInputChange={(_, newInputValue) =>
                updateNameInput(newInputValue)
              }
              getOptionLabel={(option) =>
                `${option?.firstName} ${option?.lastName}`
              }
              getOptionSelected={(option, value) => option?.id === value?.id}
              onChange={(_, value) => {
                if (value) {
                  form.employeeId.updateValue(
                    typeof value === 'string' ? value : value.id
                  );
                  form.provider.updateValue(value);
                } else {
                  form.employeeId.updateValue('');
                  form.provider.updateValue(null);
                }
              }}
              data-testid='create-appointment-employee-info'
              renderInput={(params) => (
                <ThemedTextField
                  {...params}
                  label='Select Provider'
                  placeholder={
                    loading
                      ? 'Loading...'
                      : !facilityId
                      ? `Select ${facilitiesSingularAlias.toLowerCase()} before selecting provider`
                      : 'Type to search for a provider'
                  }
                  data-cy='create-appointment-employees'
                />
              )}
              renderOption={renderEmployeeOption}
            />
          </div>
        </div>
      </AutocompleteStyleWrapper>
    </CardSection>
  );
};

export default EmployeePicker;
