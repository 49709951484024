import * as React from 'react';

//helpers
import { format, isValid } from 'date-fns';
import { colorValues, bestTextColor } from '@betterpt/better-components';
import { normalizeDate } from '../../../helpers';

//hooks
import useRemoteConfigAliases from '../../../hooks/useRemoteConfigAliases';
import useVideoAppointmentFilters from '../../../hooks/useVideoAppointmentFilters';
import useInPersonAppointmentFilters from '../../../hooks/useInPersonAppointmentFilters';

//components
import { Button, CloseButton } from '@betterpt/better-components';
import { DateRange } from 'react-date-range';
import { Dialog } from '@material-ui/core';
import ThemedTextField from '../ThemedTextField';
import SlideTransition from '../SlideTransition';

//styles
import * as styles from './Filters.style';

type Props = {
  isDatePickerOpen: boolean;
  setIsDatePickerOpen: (isOpen: boolean) => void;
  telehealth?: boolean;
  isCreatedDateFilter?: boolean;
};

const DatePickerDialog: React.FC<React.PropsWithChildren<Props>> = ({
  setIsDatePickerOpen,
  isDatePickerOpen,
  telehealth,
  isCreatedDateFilter,
}) => {
  const { primaryColor } = useRemoteConfigAliases();
  const videoFilters = useVideoAppointmentFilters();
  const inPersonFilters = useInPersonAppointmentFilters();
  const filters = telehealth ? videoFilters : inPersonFilters;

  const [startDate, setStartDate] = React.useState<string>('');
  const [endDate, setEndDate] = React.useState<string>('');
  const [calendarDates, updateCalendarDates] = React.useState<any>({
    startDate: new Date(),
    endDate: new Date(),
  });

  const isDateInvalid = (date?: string) => !!date && !isValid(new Date(date));

  const applyDate = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (isValid(start) && isValid(end)) {
      if (isCreatedDateFilter) {
        filters?.createdAtStart.update(format(start, 'yyyy-MM-dd'));
        filters?.createdAtEnd.update(format(end, 'yyyy-MM-dd'));
      } else {
        filters?.start.update(format(start, 'yyyy-MM-dd'));
        filters?.end.update(format(end, 'yyyy-MM-dd'));
      }
      setIsDatePickerOpen(false);
    }
  };

  const updateDateField = (
    newDateText: string,
    dateKey: 'startDate' | 'endDate'
  ) => {
    const isFullDate = (date: string) => date.length === 10;

    const newDate = new Date(newDateText);
    const valid = isValid(newDate) && isFullDate(newDateText);
    const isStart = dateKey === 'startDate';
    const bothInputsFilledOut =
      isFullDate(newDateText) &&
      (isStart ? isFullDate(endDate) : isFullDate(startDate));

    if (isStart) {
      setStartDate(newDateText);
    } else {
      setEndDate(newDateText);
    }
    if (valid) {
      const newStart = isStart ? newDate : calendarDates.startDate;
      const newEnd = isStart ? calendarDates.endDate : newDate;
      updateCalendarDates({
        startDate: newStart,
        endDate: bothInputsFilledOut ? newEnd : newDate, //reset end if starting from scratch
      });
    }
  };

  return (
    <Dialog
      open={isDatePickerOpen}
      onClose={() => setIsDatePickerOpen(true)}
      TransitionComponent={SlideTransition}
      disablePortal
      PaperProps={{
        style: {
          width: '880px',
          maxWidth: '880px',
        },
      }}
    >
      <styles.DatePickerDialogBody>
        <h1 className='H6' style={{ color: colorValues.charcoalgray }}>
          CHOOSE A DATE RANGE TO VIEW APPOINTMENTS
        </h1>
        <p className='Body'>
          Select a date range and click “Set filter” to view appointment data
          from a specific period.
        </p>
        <div style={styles.sectionDates}>
          <styles.DatePickerStyleWrapper>
            <DateRange
              css={styles.datePickerSelectedColor(primaryColor)}
              direction='horizontal'
              months={2}
              rangeColors={[primaryColor]}
              color={colorValues.earlysteam}
              ranges={[
                {
                  startDate: calendarDates.startDate,
                  endDate: calendarDates.endDate,
                  key: 'selection',
                  autoFocus: true,
                },
              ]}
              onChange={(dateRange: any) => {
                updateCalendarDates({
                  startDate: dateRange.selection.startDate,
                  endDate: dateRange.selection.endDate,
                });
                if (dateRange.selection.startDate) {
                  setStartDate(
                    format(dateRange.selection.startDate, 'MM/dd/yyyy')
                  );
                }
                if (dateRange.selection.endDate) {
                  setEndDate(format(dateRange.selection.endDate, 'MM/dd/yyyy'));
                }
              }}
              scroll={{
                monthHeight: 231,
                monthWidth: 231,
                enabled: true,
              }}
              showMonthAndYearPickers={false}
              showDateDisplay={false}
            />
          </styles.DatePickerStyleWrapper>
          <div style={styles.inputSectionStyle}>
            <ThemedTextField
              fullWidth
              name='start'
              value={startDate && normalizeDate(startDate)}
              error={isDateInvalid(startDate)}
              helperText={isDateInvalid(startDate) && 'Invalid date'}
              label='From'
              placeholder='MM/DD/YYYY'
              onChange={(e) =>
                updateDateField(normalizeDate(e.target.value), 'startDate')
              }
              style={{ marginBottom: 30 }}
            />
            <ThemedTextField
              fullWidth
              name='end'
              value={endDate && normalizeDate(endDate)}
              error={isDateInvalid(endDate)}
              helperText={isDateInvalid(endDate) && 'Invalid date'}
              label='To'
              placeholder='MM/DD/YYYY'
              onChange={(e) =>
                updateDateField(normalizeDate(e.target.value), 'endDate')
              }
              style={{ marginBottom: 60 }}
            />
            <Button
              style={{
                backgroundColor: primaryColor,
                color: bestTextColor(primaryColor),
                height: 48,
              }}
              size='small'
              onClick={applyDate}
              fullWidth
              disabled={
                !startDate ||
                !endDate ||
                isDateInvalid(startDate) ||
                isDateInvalid(endDate)
              }
            >
              SET FILTER
            </Button>
          </div>
        </div>
        <CloseButton onClose={() => setIsDatePickerOpen(!isDatePickerOpen)} />
      </styles.DatePickerDialogBody>
    </Dialog>
  );
};

export default DatePickerDialog;
