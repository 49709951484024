import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    width='161'
    height='160'
    viewBox='0 0 161 160'
  >
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    fill='#A4BBD9'
                    d='M74 113.801V132H0v-18.199c0-16.96 11.54-31.244 27.242-35.499 1.065-.3 2.147-.542 3.253-.736C32.609 77.194 34.78 77 37 77c2.797 0 5.513.307 8.132.89 1.106.25 2.187.542 3.253.89 5.635 1.803 10.685 4.925 14.775 9.002 6.7 6.656 10.84 15.86 10.84 26.02'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(42 1)'
                  />
                  <path
                    fill='#FEFEFE'
                    d='M48 79.3v2.278c0 4.514-2.475 8.407-6.048 10.204-1.352.68-2.86-4.425-4.452-4.425-1.45 0-2.831 5.17-4.088 4.6C29.644 90.246 27 86.243 27 81.577v-2.79c1.058-.32 2.132-.58 3.23-.788l14.54.346c1.098.27 2.172.58 3.23.954'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(42 1)'
                  />
                  <path
                    fill='#2B4F83'
                    d='M27.643 79.208v2.026c0 3.942 2.306 7.486 5.889 9.07.302-.24.83-1.122 1.155-1.667.836-1.4 1.626-2.723 2.813-2.723 1.226 0 2.114 1.333 2.975 2.623.367.55.967 1.45 1.284 1.632 3.403-1.64 5.598-5.138 5.598-8.935v-1.566c-.81-.252-1.656-.478-2.566-.69l-14.62-.326c-.865.156-1.712.342-2.528.556M33.572 92c-.175 0-.357-.037-.549-.119C28.757 90.068 26 85.89 26 81.234v-2.657c0-.37.244-.694.598-.795 1.074-.305 2.207-.563 3.366-.769.054-.01.109-.01.161-.013l14.786.33c.056.002.11.008.165.02 1.205.277 2.304.581 3.359.93.337.11.565.428.565.784v2.17c0 4.463-2.6 8.573-6.623 10.469-1.348.632-2.366-.897-3.266-2.247-.408-.61-1.255-1.88-1.611-1.89-.315.097-1.024 1.283-1.405 1.92-.74 1.239-1.501 2.514-2.523 2.514'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(42 1)'
                  />
                  <path
                    fill='#A96648'
                    d='M45 68v12.5c0 4.142-3.358 7.5-7.5 7.5-4.142 0-7.5-3.358-7.5-7.5V68h15z'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(42 1)'
                  />
                  <path
                    fill='#323232'
                    d='M34.395 50.95C22.246 37.312 23.422 16.39 37.022 4.206c6.795-6.087 17.225-5.497 23.3 1.323 6.069 6.814 5.486 17.28-1.308 23.367-6.8 6.093-7.388 16.554-1.319 23.368C63.77 59.083 74.2 59.673 81 53.58c-13.6 12.184-34.461 11.006-46.605-2.63'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(42 1)'
                  />
                  <path
                    fill='#C37D5B'
                    d='M57.624 38.938c2.364 7.215 1.586 14.703-1.6 20.98-3.173 6.281-8.747 11.342-15.962 13.706-14.43 4.73-29.956-3.131-34.685-17.56-.093-.286-.177-.565-.261-.852-.895-3.041-1.23-6.114-1.082-9.115.02-.406.05-.808.09-1.204.326-3.442 1.305-6.767 2.851-9.81 3.175-6.282 8.749-11.342 15.964-13.706 5.938-1.947 12.056-1.765 17.53.141.065.013.124.037.195.073 1.112.384 2.202.852 3.253 1.384 3.048 1.537 5.804 3.637 8.105 6.22 1.74 1.956 3.224 4.175 4.372 6.64.341.731.658 1.487.937 2.263.101.282.2.556.293.84'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(42 1)'
                  />
                  <path
                    fill='#33201A'
                    d='M24.917 55.918c-.825.282-1.713-.187-1.983-1.047l-.855-2.721c-.27-.86.179-1.786 1.004-2.068.825-.282 1.713.187 1.983 1.048l.855 2.72c.27.86-.179 1.786-1.004 2.068M40.918 50.918c-.826.282-1.714-.187-1.984-1.047l-.855-2.72c-.271-.86.178-1.787 1.004-2.07.825-.28 1.713.188 1.983 1.048l.855 2.721c.27.86-.179 1.786-1.003 2.068'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(42 1)'
                  />
                  <path
                    fill='#323232'
                    d='M58 37c-9.377-.343-16.569-3.603-18.5-9.236-.271-.793-.433-1.61-.48-2.444-.079-1.234.082-2.496.467-3.771.045-.186.102-.366.167-.549 1.296.433 2.566.961 3.79 1.56 3.551 1.73 6.764 4.09 9.449 6.989C54.922 31.745 56.657 34.235 58 37M4.952 56c7.115-6.2 10.595-13.863 8.391-20.536-.31-.938-.723-1.832-1.235-2.672-.755-1.245-1.713-2.369-2.855-3.364-.158-.153-.321-.292-.493-.428-.729 1.232-1.374 2.537-1.938 3.884-1.639 3.9-2.597 8.201-2.788 12.692-.14 3.401.146 6.901.918 10.424'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(42 1)'
                  />
                  <path
                    fill='#323232'
                    d='M40.927 18.697c.022.205.044.41.054.608.073 1.191-.067 2.407-.407 3.634l.002.008c-2.038 7.444-11.18 15.313-23.562 19.437C12.165 44 7.382 44.856 3 45c.018-.438.047-.872.087-1.299.32-3.715 1.321-7.297 2.918-10.573 3.278-6.761 9.072-12.19 16.598-14.696 6.195-2.063 12.59-1.829 18.324.265'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(42 1)'
                  />
                  <path
                    fill='#FEFEFE'
                    d='M58.191 34c-.259 0-.514-.124-.67-.356-9.327-13.785-23.118-13.943-29.007-14.01l-.718-.01c-.447-.008-.803-.378-.796-.826.008-.448.41-.799.823-.798l.71.01c5.613.064 20.526.235 30.327 14.722.251.372.155.876-.215 1.128-.139.095-.298.14-.454.14'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(42 1)'
                  />
                  <path
                    fill='#FEFEFE'
                    d='M59.191 31c-.259 0-.514-.124-.67-.356-9.326-13.785-23.118-13.943-29.007-14.01l-.718-.01c-.446-.008-.803-.378-.796-.826.008-.444.37-.798.81-.798h.014l.709.01c5.614.064 20.527.235 30.327 14.722.251.372.155.876-.215 1.128-.139.095-.298.14-.454.14'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(42 1)'
                  />
                  <path
                    fill='#F06456'
                    d='M58.191 33c-.259 0-.514-.124-.67-.356C48.194 18.858 34.402 18.7 28.513 18.633l-.717-.01c-.447-.007-.803-.376-.796-.825.007-.444.368-.798.809-.798h.014l.708.01c5.615.063 20.528.234 30.329 14.722.251.372.155.876-.215 1.128-.139.095-.298.14-.454.14'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(42 1)'
                  />
                </g>
                <g>
                  <path
                    fill='#294F83'
                    d='M1.94 66.816V69.3c.002.41.782.885 1.748.885h126.624c.966 0 1.746-.475 1.748-.87v-2.498H1.94zM130.312 72H3.688C1.628 72 .008 70.825 0 69.325V65h134v4.3c-.009 1.525-1.63 2.7-3.688 2.7z'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(13 88)'
                  />
                  <path
                    fill='#A3BBD8'
                    d='M76 62c-.246 1.722-1.14 3-2.202 3H60.203c-1.065 0-1.957-1.278-2.203-3h18z'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(13 88)'
                  />
                  <path
                    fill='#294F83'
                    d='M59.343 63.004c.334.605.923.992 1.576.992h14.162c.653 0 1.242-.387 1.576-.992H59.343zM75.08 65H60.919c-1.324 0-2.468-.982-2.782-2.389L58 62h20l-.137.611C77.548 64.018 76.404 65 75.081 65z'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(13 88)'
                  />
                  <path
                    fill='#E5E5E4'
                    d='M132.513 70H5.684C4.204 70 3 68.87 3 67.482c0-.253.218-.482.487-.482.27 0 .487.18.487.431v.05c0 .885.767 1.605 1.71 1.605h126.829c.269 0 .487.205.487.457s-.218.457-.487.457'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(13 88)'
                  />
                  <path
                    fill='#F9F9FA'
                    d='M116.007 70H16.993C15.341 70 14 68.676 14 67.042V6.162C14 3.31 16.338 1 19.223 1h94.555C116.662 1 119 3.31 119 6.161v60.88c0 1.635-1.34 2.959-2.993 2.959'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(13 88)'
                  />
                  <path
                    fill='#294F83'
                    d='M19.142 1.954c-2.322 0-4.212 1.912-4.212 4.262V68.02c0 1.117.898 2.026 2.002 2.026h98.136c1.104 0 2.002-.909 2.002-2.026V6.216c0-2.35-1.89-4.262-4.211-4.262H19.142zM115.068 72H16.932C14.764 72 13 70.214 13 68.02V6.216C13 2.788 15.756 0 19.142 0h93.717C116.245 0 119 2.788 119 6.216V68.02c0 2.194-1.764 3.98-3.932 3.98z'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(13 88)'
                  />
                  <path
                    fill='#E5E5E4'
                    d='M111.03 69H22.105C18.74 69 16 66.242 16 62.85V8.978c0-.54.435-.978.97-.978.538 0 .972.437.972.978V62.85c0 2.313 1.868 4.194 4.164 4.194h88.923c.537 0 .971.437.971.978 0 .54-.434.978-.97.978'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(13 88)'
                  />
                  <path
                    fill='#E5E5E4'
                    d='M74 36c0 3.866-3.134 7-7 7s-7-3.134-7-7 3.134-7 7-7 7 3.134 7 7'
                    transform='translate(-750 -347) translate(0 -1) translate(251 120) translate(386 228) translate(113.5) translate(13 88)'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
