import styled from '@emotion/styled';

import { colorValues } from '../helpers/colors';
import { CSSProperties } from 'react';

type TabSectionProps = { backgroundColor: CSSProperties['color'] };

export const Tab = styled.div`
  width: 150px;
  height: 45px;
  border-radius: 5px 5px 0 0;
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
  color: ${colorValues.charcoalgray};
  font-weight: normal;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
`;

export const TabSection = styled.div<TabSectionProps>`
  display: flex;
  width: min-content;
  font-family: Roboto, sans-serif;
  a {
    text-decoration: none;
  }
  .active {
    .listItem {
      background-color: ${(props) =>
        props.backgroundColor || colorValues.earlysmoke};
      border-top: 1px solid ${colorValues.coneyislandsidewalk};
      border-left: 1px solid ${colorValues.coneyislandsidewalk};
      border-right: 1px solid ${colorValues.coneyislandsidewalk};
      box-shadow: inset 0px 0px 5px ${colorValues.coneyislandsidewalk};
      color: ${colorValues.betterptblack};
      font-weight: bold;
      .line {
        display: block !important;
      }
    }
  }
`;

export const HorizontalNavContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (min-width: 1401px) {
    max-width: 1175px;
  }
  @media screen and (max-width: 1400px) {
    max-width: 1190px;
  }
`;

export const HorizontalNav = styled.div<{
  margin?: CSSProperties['margin'];
  padding?: CSSProperties['padding'];
  navBackgroundColor?: string;
}>`
  background-color: ${({ navBackgroundColor }) =>
    navBackgroundColor ?? 'transparent'};
  border-bottom: 1px solid ${colorValues.coneyislandsidewalk};
  display: flex;
  justify-content: space-between;
  padding: ${({ padding }) =>
    typeof padding === 'number' ? `${padding}px` : padding || 0};
  padding-bottom: 0;
  margin: ${({ margin }) => margin || 0};
`;

export const ButtonsSection = styled.div`
  display: flex;
  align-items: center;
`;

type LineCutProps = { backgroundColor: CSSProperties['color'] };

export const LineCut = styled.div<LineCutProps>`
  height: 10px;
  position: absolute;
  bottom: -1px;
  background-color: ${(props) =>
    props.backgroundColor || colorValues.earlysmoke};
  width: 100%;
  display: none;
  box-shadow: inset 0 -3px 3px -16px ${colorValues.coneyislandsidewalk},
    inset 3px 0px 3px -3px ${colorValues.coneyislandsidewalk},
    inset -3px 0px 3px -3px ${colorValues.coneyislandsidewalk};
`;
