import * as React from 'react';
import ThemedTextField from './ThemedTextField';
import { Radio } from '@betterpt/better-components';
import styled from '@emotion/styled';

const BinaryRadioWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  label {
    display: flex;
    align-items: center;
  }
  margin-right: 14px;
`;

type Props = {
  label: string;
  value?: boolean;
  updateValue: (value: boolean) => void;
  disabled?: boolean;
  testId?: string;
  upperLabel?: string;
  style?: { [key: string]: any };
};

const BinaryRadio: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  value,
  updateValue,
  disabled,
  testId,
  upperLabel,
  style = {},
}) => {
  return (
    <BinaryRadioWrapper>
      <ThemedTextField
        InputProps={{ readOnly: true }}
        value={label}
        disabled={disabled}
        label={upperLabel}
        inputProps={{
          style,
        }}
      />
      <label>
        Yes
        <Radio
          checked={value === true}
          onChange={() => updateValue(true)}
          disabled={disabled}
          data-testid={testId ? `${testId}-yes` : ''}
        />
      </label>
      <label>
        No
        <Radio
          checked={value === false}
          onChange={() => updateValue(false)}
          disabled={disabled}
          data-testid={testId ? `${testId}-no` : ''}
        />
      </label>
    </BinaryRadioWrapper>
  );
};

export default BinaryRadio;
