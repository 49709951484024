import * as React from 'react';

// types
import { TextFieldState } from '../../../../../../../hooks/useTextFieldState';

// hooks
import useRemoteConfigAliases from '../../../../../../../hooks/useRemoteConfigAliases';

// components
import { InfoBox } from '@betterpt/better-components';
import FormField from '../../../../../../Shared/FormField';

//assets
import { colorValues } from '@betterpt/better-components';
import { IcInfoOutline } from '@betterpt/better-icons';

type Props = {
  serviceAreaName: TextFieldState;
};

const ServiceAreaSection: React.FC<React.PropsWithChildren<Props>> = ({ serviceAreaName }) => {
  const {
    facilitiesSingularAlias,
    patientsPluralAlias,
    employeePluralAlias,
  } = useRemoteConfigAliases();

  return (
    <>
      <div style={{ position: 'relative' }}>
        <h4 style={{ marginBottom: 22, marginTop: 40 }}>Service Area</h4>
        <InfoBox
          openWith='hover'
          width={250}
          height={135}
          iconHeight={24}
          iconWidth={24}
          position='right'
          padding='15px'
          fixedPlacement
          mainStyle={{ right: '-15px', top: '-20px' }}
          boxStyle={{
            maxHeight: 'min-content',
          }}
          icon={<IcInfoOutline color={colorValues.betterptblack} />}
        >
          <h4>SERVICE AREA</h4>
          <p>
            Your service area will determine the timezone we use when setting up
            telehealth calls between {employeePluralAlias.toLowerCase()} and{' '}
            {patientsPluralAlias.toLowerCase()}.
            <br />
            <br />
            Service areas can be anything from county names, to city names, to
            entire regions, and will be displayed alongside the{' '}
            {facilitiesSingularAlias.toLowerCase()} name.
          </p>
        </InfoBox>
      </div>
      <FormField
        id='serviceAreaName'
        fullWidth
        label='Service Area Name'
        placeholder='Enter a name for your service area'
        formHandler={serviceAreaName}
        inputProps={{
          maxLength: 255,
        }}
      />
    </>
  );
};

export default ServiceAreaSection;
