import styled from '@emotion/styled';

// Main View Styles
export const Page = styled.div`
  margin: 31px 33px;
`;

// Header Styles
export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 38px;
  h1 {
    font-size: 24px;
    margin: 0;
    margin-right: 20px;
  }
`;
