import React from 'react';
import styled from '@emotion/styled';
import { bestTextColor } from '@betterpt/better-components';

//components
import { IcFace } from '@betterpt/better-icons';
import { Button } from '@betterpt/better-components';
import { Link } from 'react-router-dom';

//hooks
import { useHistory } from 'react-router-dom';
import useRemoteConfigAliases from '../hooks/useRemoteConfigAliases';

const P = styled.p`
  width: 333px;
  font-size: 24px;
  font-weight: bold;
  margin-top: 48px;
  margin-bottom: 73px;
  text-align: center;
`;
const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const NotFoundPage = () => {
  const history = useHistory();
  const { primaryColor } = useRemoteConfigAliases();

  const buttonStyle = {
    backgroundColor: primaryColor,
    color: bestTextColor(primaryColor),
    marginBottom: 20,
    width: 243,
  };
  return (
    <Page>
      <ContentSection>
        <IcFace />
        <P>Sorry, but the page you’re looking for doesn’t exist!</P>
        <Link to='/appointments'>
          <Button style={buttonStyle}>GO TO APPOINTMENTS PAGE</Button>
        </Link>
        <Button style={buttonStyle} onClick={() => history.goBack()}>
          GO BACK
        </Button>
      </ContentSection>
    </Page>
  );
};

export default NotFoundPage;
