import React from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

//components
import { Button, ImageUploader } from '@betterpt/better-components';

//hooks
import { useMeQuery } from '../../../../generated/graphql';

// assets
import PlaceholderImage from '../../../../assets/img-unknown-user.svg';

// styles
import {
  Header,
  logoutButtonStyle,
  PhotoAndNameSection,
} from '../styles/Profile.style';

//hooks
import useAuthentication from '../../../../hooks/useAuthentication';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../hooks/useSnackbar';

const ProfileHeader = () => {
  //hooks
  const snackbar = useSnackbar();
  const auth = useAuthentication();
  const meQuery = useMeQuery();
  const { primaryColor } = useRemoteConfigAliases();
  const { push } = useHistory();

  const [avatar, setAvatar] = React.useState('');
  const [isLoadingAvatar, setLoadingAvatarAvatar] = React.useState(false);

  const { firstName, lastName, id } = meQuery?.data?.me || {};

  React.useEffect(() => {
    if (meQuery.called && !meQuery.loading && meQuery.data?.me?.profileImgUrl) {
      setAvatar(meQuery.data?.me?.profileImgUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meQuery.data]);

  const handleUploadAvatar = (base64Img: any) => {
    const userSession = auth?.user?.getSignInUserSession();
    const token = userSession?.getIdToken();
    if (!token) {
      return;
    }
    setLoadingAvatarAvatar(true);
    const endpoint =
      window.__RUNTIME_CONFIG__.REACT_APP_API_ENDPOINT ??
      'https://api.development.betterpt.com/v0/api';
    axios
      .post(
        `${endpoint}/employees/${id}/avatar`,
        { file: base64Img },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: token.getJwtToken(),
          },
        }
      )
      .then(() => meQuery.refetch())
      .then(() => {
        setAvatar(base64Img);
        setLoadingAvatarAvatar(false);
      });
  };

  return (
    <Header>
      <PhotoAndNameSection>
        <ImageUploader
          loading={isLoadingAvatar}
          type='avatar'
          width={46}
          image={avatar || PlaceholderImage}
          onSubmit={(event) => {
            handleUploadAvatar(event.base64Img);
          }}
          style={{
            marginRight: 20,
          }}
          onError={(message) =>
            snackbar?.setErrorMessage(message)
          }
        />
        <h1>
          {firstName ?? ''} {lastName ?? ''}
        </h1>
      </PhotoAndNameSection>
      <Button
        color='transparent'
        style={logoutButtonStyle(primaryColor)}
        onClick={() => push('/logout')}
      >
        LOG OUT
      </Button>
    </Header>
  );
};

export default ProfileHeader;
