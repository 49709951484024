import React from 'react';

//components
import { Button, TextField } from '@betterpt/better-components';

//styles
import { colorValues } from '@betterpt/better-components';
import { FieldSection } from '../AppointmentTypes.style';

//hooks
import { useHistory } from 'react-router-dom';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';

//assets
import { IcArrowRightWithStem } from '@betterpt/better-icons';

interface Props {
  apptType: any;
  index: number;
  apptTypeDetailLink: (appointmentTypeId: string) => string;
  buttonStyle: (backgroundColor: string) => React.CSSProperties | undefined;
}

const DisplayAppointmentType = ({
  apptType,
  index,
  apptTypeDetailLink,
  buttonStyle,
}: Props) => {
  const history = useHistory();
  const { primaryColor } = useRemoteConfigAliases();
  return (
    <FieldSection key={`appointment-type-${apptType?.id ?? index}`}>
      <TextField
        fullWidth
        value={`${apptType?.displayName ?? ''} (${
          apptType?.medium === 'telehealth' ? 'telehealth' : 'in-person'
        })`}
        label="Appointment Type"
        disabled
        InputProps={{
          endAdornment: (
            <Button
              style={buttonStyle(primaryColor)}
              onClick={() =>
                history.push(apptTypeDetailLink(apptType?.id ?? ''))
              }
            >
              <IcArrowRightWithStem color={colorValues.emptiness} />
            </Button>
          ),
        }}
      />
    </FieldSection>
  );
};

export default DisplayAppointmentType;
