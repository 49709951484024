import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='54'
    height='54'
    viewBox='0 0 54 54'
    {...rest}
  >
    <defs>
      <path
        id='cq9k8g8aoa'
        d='M38.25 2.272l-22.5-.022c-2.475 0-4.5 2.025-4.5 4.5v40.5c0 2.475 2.025 4.5 4.5 4.5h22.5c2.475 0 4.5-2.025 4.5-4.5V6.75c0-2.475-2.025-4.478-4.5-4.478zm0 40.478h-22.5v-31.5h22.5v31.5z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0L54 0 54 54 0 54z' />
      <mask id='bzo0863elb' fill='#fff'>
        <use xlinkHref='#cq9k8g8aoa' />
      </mask>
      <use fill='#333' fillRule='nonzero' xlinkHref='#cq9k8g8aoa' />
      <g fill={color ?? '#2A4E82'} mask='url(#bzo0863elb)'>
        <path d='M0 0H54V54H0z' />
      </g>
    </g>
  </svg>
);
