import * as React from 'react';

import { colorValues, Button } from '@betterpt/better-components';

//types
import {
  CompanyListItem,
  CompanyListItemTypeEnum,
  Maybe,
  Role,
  useCompanyListItemsQuery,
  useMeQuery,
} from '../../../generated/graphql';
import { InPersonAppointmentFiltersContextType } from '../../../context/inPersonAppointmentFilters';

//components
import {
  InfoBox,
  FilterButtonOnly,
  Radio,
  Checkbox,
} from '@betterpt/better-components';
import styled from '@emotion/styled';
import ThemedTextField from '../ThemedTextField';

const EmployeeListContainer = styled.div`
  display: block;
  border: 2px solid ${colorValues.concretejungle};
  border-radius: 5px;
  max-height: 272px;
  overflow: auto;
`;

const ScheduleStatusLabel = styled.label`
  display: block;
`;

const EmployeesFilter: React.FC<React.PropsWithChildren<{
  filters: InPersonAppointmentFiltersContextType;
  primaryColor?: string;
}>> = ({ filters, primaryColor }) => {
  const [assignedEmployeeIds, updateAssignedEmployeeIds] = React.useState<
    string[]
  >([]);
  const [isAssigned, updateIsAssigned] = React.useState<boolean | null>(null);
  const [nameFilter, updateNameFilter] = React.useState('');
  const meQuery = useMeQuery();
  const me = meQuery.data?.me;
  const isBasic = me?.role === Role.Self;

  const employeesQuery = useCompanyListItemsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        companyId: meQuery.data?.me?.company?.id!,
        type: CompanyListItemTypeEnum.Employee,
      },
    },
    skip: !meQuery.data?.me?.company?.id || isBasic,
    onError: (e) => console.error(e.message),
  });

  React.useEffect(() => {
    updateAssignedEmployeeIds(filters.assignedEmployeeIds?.value ?? []);
    updateIsAssigned(filters.isAssigned?.value ?? null);
  }, [filters.assignedEmployeeIds, filters.isAssigned]);

  const handleSubmitAssignedFilter = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    filters.assignedEmployeeIds?.update(assignedEmployeeIds);
    if (assignedEmployeeIds.length) {
      filters.isAssigned?.update(true);
    } else filters.isAssigned?.update(isAssigned);
  };

  const copiedEmployees = employeesQuery.data?.companyListItems
    ? [...employeesQuery.data?.companyListItems]
    : [];

  const justMeOption = [
    {
      name: me?.firstName + ' ' + me?.lastName,
      id: me?.id,
      type: CompanyListItemTypeEnum.Employee,
    },
  ];

  const sortByLastName = (
    a: Maybe<CompanyListItem>,
    b: Maybe<CompanyListItem>
  ) =>
    (a?.name?.split(' ').slice(-1)[0]?.toLowerCase() ?? 0) <
    (b?.name?.split(' ').slice(-1)[0]?.toLowerCase() ?? 0)
      ? -1
      : 1;

  const sortBySelected = (employee: Maybe<CompanyListItem>) =>
    assignedEmployeeIds.includes(employee?.id ?? '0') ? -1 : 1;

  const invertName = (name: string) =>
    `${name.split(' ').slice(-1)[0]}, ${name
      .split(' ')
      .slice(0, -1)
      .join(' ')}`;

  const employeesToShow: any[] = isBasic
    ? justMeOption
    : copiedEmployees
        .sort(sortByLastName)
        .sort(sortBySelected)
        .filter((employee) => employee?.id)
        .filter((employee) =>
          employee?.name?.toLowerCase().includes(nameFilter.toLowerCase())
        );

  const determineFilterLabel = () => {
    if (filters.isAssigned?.value === null) return 'all appointments';
    if (filters.isAssigned?.value === false) return 'all unassigned';
    if (
      filters.isAssigned?.value === true &&
      filters.assignedEmployeeIds?.value.length === 0
    )
      return 'all assigned';
    else return 'assigned';
  };

  const handleAllAppointmentsClick = () => {
    updateIsAssigned(null);
    updateAssignedEmployeeIds([]);
  };

  const handleNotAssignedClick = () => {
    updateIsAssigned(false);
    updateAssignedEmployeeIds([]);
  };

  const handleAssignClick = () => {
    updateIsAssigned(true);
  };

  const handleAssignedEmployeeClick = (employeeId: string) => {
    updateIsAssigned(true);
    updateAssignedEmployeeIds((ids) => {
      if (assignedEmployeeIds.includes(employeeId)) {
        return ids.filter((id) => id !== employeeId);
      } else {
        return [...ids, employeeId];
      }
    });
  };

  const onNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateNameFilter(e.currentTarget.value);
  };

  return (
    <InfoBox
      icon={<FilterButtonOnly buttonTitle={determineFilterLabel()} style={{ buttonMargin: "12px 6px 0 0" }} />}
      height={500}
      padding={0}
      width={280}
      arrowStyle={{ left: 100 }}
      boxStyle={{ left: -25 }}
    >
      <h4>Scheduling Status</h4>
      <ScheduleStatusLabel>
        <Radio
          checked={isAssigned === null}
          onClick={handleAllAppointmentsClick}
        />
        All Appointments
      </ScheduleStatusLabel>
      <ScheduleStatusLabel>
        <Radio
          checked={isAssigned === false}
          onClick={handleNotAssignedClick}
        />
        Not Assigned to Scheduler
      </ScheduleStatusLabel>
      <ScheduleStatusLabel>
        <Radio checked={isAssigned === true} onClick={handleAssignClick} />
        Assigned to Scheduler
      </ScheduleStatusLabel>
      <EmployeeListContainer>
        <ThemedTextField
          onChange={onNameInputChange}
          value={nameFilter}
          placeholder='Search for staff here'
          label='Search Staff'
          style={{ margin: '10px', maxWidth: '250px' }}
        />
        {employeesToShow.map((employee) => (
          <ScheduleStatusLabel key={employee.id}>
            <Checkbox
              checked={assignedEmployeeIds.includes(employee.id)}
              onClick={(e) => {
                handleAssignedEmployeeClick(employee.id);
              }}
            />
            {invertName(employee.name)}
            {employee.id === meQuery.data?.me?.id ? ' (me)' : ''}
          </ScheduleStatusLabel>
        ))}
      </EmployeeListContainer>
      <Button
        style={{ backgroundColor: primaryColor, marginTop: '20px' }}
        onClick={handleSubmitAssignedFilter}
      >
        APPLY FILTER
      </Button>
    </InfoBox>
  );
};

export default EmployeesFilter;
