import React from 'react';
import axios from 'axios';

// components
import OnboardingWrapper from '../OnboardingWrapper';
import {
  Button,
  colorValues,
  ImageUploader,
} from '@betterpt/better-components';
import FormField from '../../Shared/FormField';

// hooks
import { useHistory } from 'react-router-dom';
import useRemoteConfigAliases from '../../../hooks/useRemoteConfigAliases';
import useTextFieldState from '../../../hooks/useTextFieldState';
import useSnackbar from '../../../hooks/useSnackbar';
import useFeatureAccessCheck from '../../../hooks/useFeatureAccessCheck';
import useAuthentication from '../../../hooks/useAuthentication';

//styles
import {
  AddAdministratorFormContainer,
  AddAdministratorForm,
  Title,
  P,
} from './AddAdministrator.style';

// assets & helpers
import {
  emailValidation,
  addIndefiniteArticle,
  normalizeMonthYearDate,
  validateExperience,
  formatYearsExperience,
  validateBio,
} from '../../../helpers';
import BinaryRadio from '../../Shared/BinaryRadio';
import PlaceholderImage from '../../../assets/img-unknown-user.svg';

// gql
import {
  useMeQuery,
  useCreateCompanyEmployeeMutation,
  Role,
  Gender,
} from '../../../generated/graphql';
import useHandleError from '../../../hooks/useHandleError';
import useFeatureFlagWrapper from 'apps/provider-app/src/hooks/useFeatureFlagWrapper';
import Flags from 'apps/provider-app/src/constants/flags';

const AddAdministrator = () => {
  const meQuery = useMeQuery();
  const auth = useAuthentication();
  const [
    createEmployee,
    createEmployeeOptions,
  ] = useCreateCompanyEmployeeMutation();

  const snackbar = useSnackbar();
  const { isNotWhiteLabel } = useFeatureAccessCheck();

  const shouldNewEmployeesDefaultToProvider =
    meQuery?.data?.me?.company?.shouldNewEmployeesDefaultToProvider;

  const history = useHistory();

  const [avatar, setAvatar] = React.useState('');
  const [savingAvatar, setSavingAvatar] = React.useState(false);
  const {
    primaryColor,
    employeeSingularAlias,
    employeePluralAlias,
    facilitiesPluralAlias,
  } = useRemoteConfigAliases();
  const featureFlagWrapper = useFeatureFlagWrapper();
  const [isTherapist, updateIsTherapist] = React.useState<boolean>();
  const firstName = useTextFieldState({ required: true });
  const lastName = useTextFieldState({ required: true });
  const gender = useTextFieldState({ required: true });
  const email = useTextFieldState({
    required: true,
    validation: emailValidation,
  });
  const bio = useTextFieldState({ validation: validateBio });
  const degreeOrCertification = useTextFieldState();
  const yearsExperience = useTextFieldState({
    normalizer: normalizeMonthYearDate,
    validation: validateExperience,
  });
  const handleError = useHandleError();

  const nextPageURL = featureFlagWrapper(
    '/onboarding/profile',
    Flags.DisableBillingForCompanySetup,
    '/onboarding/billing'
  ) ?? '';


  const [
    isAddAdministratorFormActive,
    setAddAdministratorFormActive,
  ] = React.useState(false);

  const done = () => {
    history.push({
      pathname: '/',
      state: {
        congratsDialogOpen: true,
        congratsDialogText: `You’ve just set up your company. Now you can add ${employeePluralAlias?.toLocaleLowerCase() ||
          'care team members'} and ${facilitiesPluralAlias?.toLocaleLowerCase() ||
          'clients'} to our platform. If you add ${addIndefiniteArticle(
            employeeSingularAlias?.toLocaleLowerCase() || 'care team member'
          )} and give them administrative permissions, they can assist with setup.`,
      },
    });
  };
  const saveEmployeeAvatar = async (id: string, done: () => void) => {
    const userSession = auth?.user?.getSignInUserSession();
    const token = userSession?.getIdToken();
    if (!avatar || !id || !token) return;
    const endpoint =
      window.__RUNTIME_CONFIG__.REACT_APP_API_ENDPOINT ??
      'https://api.development.betterpt.com/v0/api';
    try {
      await axios.post(
        `${endpoint}/employees/${id}/avatar`,
        { file: avatar },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: token.getJwtToken(),
          },
        }
      );
      snackbar?.setSuccessMessage('Administrator Added');
      setSavingAvatar(false);
      done();
    } catch (error) {
      setSavingAvatar(false);
      handleError(error);
      done();
    }
  };

  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const companyId = meQuery?.data?.me?.company?.id;
    if (!companyId) return;

    if (isAddAdministratorFormActive) {
      try {
        const employee = await createEmployee({
          variables: {
            input: {
              firstName: firstName.value,
              lastName: lastName.value,
              companyId,
              email: email.value.trim().toLowerCase(),
              role: Role.Admin,
              isTherapist: shouldNewEmployeesDefaultToProvider || !!isTherapist,
              gender: gender.value as Gender,
              bio: bio.value || undefined,
              degreeOrCertification: degreeOrCertification.value || undefined,
              yearsExperience: formatYearsExperience(yearsExperience),
            },
          },
        });

        const employeeId = employee.data?.createCompanyEmployee?.result?.id;

        if (avatar && employeeId) {
          setSavingAvatar(true);
          setTimeout(() => {
            saveEmployeeAvatar(employeeId, done);
          }, 1000);
        } else {
          snackbar?.setSuccessMessage('Administrator Added');
          done();
        }
      } catch (e) {
        handleError(e);
      }
    } else {
      // Skip step
      done();
    }
  };

  const disableButton =
    !firstName.valid ||
    !lastName.valid ||
    !gender.valid ||
    !email.valid ||
    !bio.valid ||
    (!shouldNewEmployeesDefaultToProvider && isTherapist === undefined) ||
    createEmployeeOptions.loading;

  const showProviderFields = isNotWhiteLabel && isTherapist;

  return (
    <OnboardingWrapper
      saving={createEmployeeOptions.loading || savingAvatar}
      form='addCompanyAdministrator'
      goToPreviousStep={() => {
        if (isAddAdministratorFormActive) {
          setAddAdministratorFormActive(false);
        } else {
          history.push(nextPageURL);
        }
      }}
      disableButton={isAddAdministratorFormActive && disableButton}
      buttonText={`${isAddAdministratorFormActive ? 'ADD' : 'SKIP'
        } AND FINISH UP`}
    >
      <AddAdministratorFormContainer
        isFormActive={isAddAdministratorFormActive}
      >
        {isAddAdministratorFormActive && (
          <ImageUploader
            type='avatar'
            image={avatar || PlaceholderImage}
            onSubmit={(event) => {
              setAvatar(event.base64Img);
            }}
            style={{
              display: 'inline-block',
              margin: '60px 74px',
            }}
            helperText='Drag or click to upload a profile picture. Maximum 250x250 px.'
            onError={(message) =>
              snackbar?.openSnackbar({ isError: true, message })
            }
          />
        )}
        <AddAdministratorForm
          id='addCompanyAdministrator'
          onSubmit={handleSubmit}
        >
          {(!isAddAdministratorFormActive && (
            <React.Fragment>
              <Title>Add a Company Administrator (Optional)</Title>
              <P>
                You&apos;re almost finished adding your company to the platform.
                After that you will be able to add facilities and staff.
                <br />
                <br />
                An administrator will be able to do all of this for you. They
                will have access to your entire company dashboard, and will be
                able to add and remove facilities, as well as managers,
                schedulers and basic care providers.
              </P>
              <Button
                style={{ backgroundColor: primaryColor }}
                onClick={() => setAddAdministratorFormActive(true)}
                size='large'
                fullWidth
              >
                ADD AN ADMINISTRATOR
              </Button>
            </React.Fragment>
          )) || (
              <React.Fragment>
                {' '}
                <h2 className='H4' style={{ margin: '40px 0px 32px 0px' }}>
                  Add an Administrator{' '}
                  <a
                    className='button-or-link'
                    style={{
                      fontSize: '12px',
                      color: colorValues.frenchblue,
                    }}
                    href='https://betterpt-external-assets.s3.amazonaws.com/Roles+and+Permissions+PDF.pdf'
                    target='_blank'
                    rel='noreferrer'
                  >
                    LEARN MORE ABOUT ROLES & PERMISSIONS
                  </a>
                </h2>
                <FormField
                  fullWidth
                  label={`${employeeSingularAlias ?? ''} First Name`}
                  placeholder='Enter your administrator’s first name'
                  formHandler={firstName}
                  inputProps={{
                    maxLength: 49,
                  }}
                />
                <FormField
                  fullWidth
                  label={`${employeeSingularAlias ?? ''} Last Name`}
                  placeholder='Enter your administrator’s last name'
                  formHandler={lastName}
                  inputProps={{
                    maxLength: 49,
                  }}
                />
                <FormField
                  select
                  fullWidth
                  label={`${employeeSingularAlias ?? ''} Gender`}
                  formHandler={gender}
                  SelectProps={{ native: true }}
                >
                  <option value=''>Enter your administrator’s gender</option>
                  <option value='M'>Male</option>
                  <option value='F'>Female</option>
                </FormField>
                <FormField
                  fullWidth
                  label={`${employeeSingularAlias ?? ''} Email`}
                  placeholder='Enter administrator’s contact email'
                  formHandler={email}
                  inputProps={{
                    maxLength: 49,
                  }}
                />
                {!shouldNewEmployeesDefaultToProvider && (
                  <React.Fragment>
                    <h2 className='H4' style={{ margin: '40px 0px 32px 0px' }}>
                      Professional Information
                    </h2>
                    <BinaryRadio
                      label='Is this user a care provider?'
                      value={isTherapist}
                      updateValue={updateIsTherapist}
                    />
                  </React.Fragment>
                )}
                {showProviderFields && (
                  <>
                    <h4>Provider Information</h4>
                    <FormField
                      fullWidth
                      label='Bio'
                      formHandler={bio}
                      placeholder="Enter this provider's bio."
                      multiline
                      helperText={`${bio.value.length}/1000`}
                      error={!!bio.error?.length}
                    />
                    <FormField
                      fullWidth
                      label='Degree Or Certification'
                      placeholder="Enter this provider's degree or certification here"
                      formHandler={degreeOrCertification}
                      inputProps={{
                        maxLength: 255,
                      }}
                    />
                    <FormField
                      fullWidth
                      label='Practicing Since'
                      placeholder='Enter when this user started practicing (MM/YYYY)'
                      formHandler={yearsExperience}
                    />
                  </>
                )}
              </React.Fragment>
            )}
        </AddAdministratorForm>
      </AddAdministratorFormContainer>
    </OnboardingWrapper>
  );
};

export default AddAdministrator;
