import React from 'react';

//helpers
import { bestTextColor } from '@betterpt/better-components';

//hooks
import { useHistory } from 'react-router-dom';
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';

//components
import { GridCard } from '../../../../../Shared/GridCard';
import { Button, Header } from '@betterpt/better-components';

//assets
import { IcEmail } from '@betterpt/better-icons';

//styles
import { FormBody as CardBody } from '../../../EmbeddedAnalytics.style';
import {
  AnalyticsCircle,
  AnalyticsCircleColumn,
  AnalyticsCircleText,
  AnalyticsColumn,
  AnalyticsRow,
  AnalyticsSectionTitle,
  CSVParagraph,
} from '../../AnalyticsCSVTab.style';

const AnalyticsCSV = () => {
  const history = useHistory();
  const { primaryColor } = useRemoteConfigAliases();

  return (
    <GridCard data-testid="analytics-csv-card" fullWidth>
      <Header underline style={{ padding: '0 23px 0 42px' }}>
        <h2 style={{ fontSize: 16 }}>Analytics CSV</h2>
      </Header>
      <CardBody>
        <AnalyticsRow>
          <AnalyticsColumn>
            <AnalyticsSectionTitle>Request a CSV</AnalyticsSectionTitle>
            <AnalyticsCircleColumn>
              <AnalyticsCircle color={primaryColor}>
                <IcEmail style={{ height: 38, width: 38 }} color={bestTextColor(primaryColor)} />
                <AnalyticsCircleText style={{ color: bestTextColor(primaryColor) }}>CSV</AnalyticsCircleText>
              </AnalyticsCircle>
            </AnalyticsCircleColumn>
          </AnalyticsColumn>

          <AnalyticsColumn style={{ marginLeft: '55px' }}>
            <CSVParagraph>
              Recieve a CSV of all data on in-person appointments to the email associated with your account. For security reasons, you will
              have a ten minute window to download this file. You will also not be allowed to download more than three months’ data at a
              time.
            </CSVParagraph>
            <AnalyticsRow>
              <Button
                style={{
                  backgroundColor: primaryColor,
                  color: bestTextColor(primaryColor),
                  width: 219,
                  marginRight: 15,
                }}
                onClick={() => history.push('/embedded-analytics/in-person-csv')}
              >
                GET IN-PERSON CSV
              </Button>
            </AnalyticsRow>
          </AnalyticsColumn>
        </AnalyticsRow>
      </CardBody>
    </GridCard>
  );
};

export default AnalyticsCSV;
