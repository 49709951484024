import { jsx } from '@emotion/react';
import React from 'react';

//helpers
import { colorValues } from '../helpers/colors';
import { bestTextColor } from '../helpers/functions';

//components
import Button from '../Button';
import InfoBox from '../InfoBox';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

//graphics
import IcDropDownSelected from '../Icons/IcDropDownSelected';

//styles
import { ActionButton, radioStyle, RadioLabel } from './TableAction.style';

export type RadioOption = {
  label: string;
  callback: (...args: any[]) => void;
  disabled?: boolean;
  color?: string;
  hidden?: boolean | null;
  icon?: any;
  id?: string;
};

type Props = {
  options: RadioOption[];
  icon?: {
    node: any;
    height?: number;
    width?: number;
  };
  color?: { backgroundColor?: string; textColor?: string };
  title?: string;
  buttonColor?: string;
  openStatus?: { value: boolean; update: (open: boolean) => void };
  loading?: boolean;
  complete?: boolean;
  arrowStyle?: { [key: string]: any };
  boxStyle?: { [key: string]: any };
  buttonStyle?: { [key: string]: any };
  iconStyle?: { [key: string]: any };
  mainStyle?: { [key: string]: any };
  id?: string;
  callOnOpen?: () => void;
};

const TableAction = ({
  options,
  icon,
  color,
  title,
  buttonColor,
  openStatus,
  loading,
  complete,
  arrowStyle,
  boxStyle,
  buttonStyle,
  iconStyle,
  mainStyle,
  id,
  callOnOpen,
}: Props) => {
  const startingHeightBuffer = (options.length - 1) * 48;
  const [boxHeight, setBoxHeight] = React.useState(200 + startingHeightBuffer); //approximation for InfoBox to compute certain internal styles
  const [isOpen, setOpenTo] = React.useState(false);
  const [selectedActionIndex, setSelectedActionIndex] = React.useState<number>(
    -1
  );

  //on mount, update the box height to account for any hidden items
  React.useEffect(() => {
    let computedHeightBuffer = (options.length - 1) * 48;
    for (let i = 0; i < options.length; i++) {
      if (options[i]?.hidden) {
        computedHeightBuffer -= 48;
      }
    }
    if (computedHeightBuffer !== startingHeightBuffer) {
      setBoxHeight(200 + computedHeightBuffer);
    }

    return () => setSelectedActionIndex(-1);
  }, [options, startingHeightBuffer, isOpen]);

  React.useEffect(() => {
    if (openStatus) {
      openStatus.update(isOpen);
    }
    if (isOpen && callOnOpen) {
      callOnOpen();
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (complete) {
      setTimeout(() => {
        setOpenTo(false);
        openStatus?.update(false);
      }, 2000);
    }
  }, [complete]);

  return (
    <InfoBox
      openWith='click'
      testId={`table-action-${id}`}
      isOpen={openStatus?.value ?? isOpen}
      toggleBox={(isOpen) => {
        openStatus?.update(isOpen);
        setOpenTo(isOpen);
      }}
      width={250}
      height={boxHeight}
      iconHeight={icon?.height || 28}
      iconWidth={icon?.width || 40}
      position='left'
      padding={4}
      mainStyle={{ display: 'flex', alignItems: 'center', ...mainStyle }}
      boxStyle={{ left: '-340px', height: 'fit-content', ...boxStyle }}
      arrowStyle={arrowStyle}
      iconStyle={iconStyle}
      icon={
        icon?.node || (
          <ActionButton
            color={color?.backgroundColor}
            open={openStatus?.value ?? isOpen}
            style={buttonStyle ?? {}}
          >
            <IcDropDownSelected
              color={color?.textColor}
              style={{ width: 20 }}
            />
          </ActionButton>
        )
      }
    >
      <h4>{title?.toUpperCase() || 'QUICK ACTION'}</h4>
      <RadioGroup
        name='options'
        value={selectedActionIndex}
        onChange={(e) => {
          e.stopPropagation();
          setSelectedActionIndex(parseInt(e.target.value));
        }}
      >
        {options.map(
          (option: RadioOption, index: number) =>
            !option?.hidden && (
              <FormControlLabel
                key={option?.label + index}
                value={index}
                control={
                  <Radio
                    css={radioStyle}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    disabled={option?.disabled}
                    inputProps={{
                      ['data-testid' as any]: option?.id,
                      ['data-cy' as any]: option?.id,
                    }}
                  />
                }
                label={
                  <RadioLabel
                    style={{
                      display: 'flex',
                      position: 'relative',
                      color: `${
                        option?.disabled
                          ? colorValues.cityblock
                          : option?.color ?? colorValues.betterptblack
                      }`,
                    }}
                  >
                    {option?.label ?? '-'}
                    {option?.icon ?? null}
                  </RadioLabel>
                }
              />
            )
        )}
      </RadioGroup>
      <Button
        loading={loading}
        size='small'
        style={{
          height: 36,
          marginTop: 20,
          fontSize: complete ? '20px' : '14px',
          backgroundColor: buttonColor ?? colorValues.frenchblue,
          color: bestTextColor(buttonColor ?? colorValues.frenchblue),
        }}
        fullWidth
        onClick={() => {
          if (selectedActionIndex > -1) {
            options[selectedActionIndex]?.callback();
            openStatus?.update(false);
            setOpenTo(false);
          }
        }}
        success={complete}
        data-testid={`${id ?? ''}-continue`}
      >
        CONTINUE
      </Button>
    </InfoBox>
  );
};

export default TableAction;
