import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...rest}
  >
    <defs>
      <path
        id='xhjf75rzoa'
        d='M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0L24 0 24 24 0 24z' />
      <mask id='4jaxj91xyb' fill='#fff'>
        <use xlinkHref='#xhjf75rzoa' />
      </mask>
      <use fill={color ?? '#333'} fillRule='nonzero' xlinkHref='#xhjf75rzoa' />
      <g fill={color ?? '#333'} mask='url(#4jaxj91xyb)'>
        <path d='M0 0H24V24H0z' />
      </g>
    </g>
  </svg>
);
