import { colorValues } from '../helpers/colors';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const defaultInputStyle = (
  hasStartAdornment: boolean
): CSSProperties => ({
  // Main Styles
  width: hasStartAdornment ? 'auto' : '200px',
  '& .MuiFormControl-root': {
    width: '100%',
  },
  '& .MuiGrid-item': {
    width: hasStartAdornment ? 'auto' : '100%',
  },
  '& input, textarea, label, select, .MuiSelect-root': {
    fontSize: 14,
  },
  '& p': {
    fontSize: 10,
    marginTop: 5,
  },
  '& .MuiSvgIcon-root': {
    opacity: 0.5,
  },
  '& label.Mui-focused': {
    color: colorValues.frenchblue,
  },
  '& label.MuiInputLabel-shrink': {
    transform: 'translate(0, 1.5px) scale(0.71)',
  },
  '& .MuiInputBase-input': {
    padding: '6px 0',
  },
  '& .MuiInputBase-inputAdornedStart': {
    marginLeft: 5,
  },
  '& .MuiInputBase-multiline': {
    padding: '0',
  },
  '& .MuiInputLabel-formControl': {
    transform: 'translate(0, 18px) scale(1)',
  },
  '& .MuiInput-underline': {
    marginTop: 15,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: colorValues.frenchblue,
  },
  // Disabled styles
  '& .MuiInputBase-input.Mui-disabled, .MuiFormLabel-root.Mui-disabled': {
    color: colorValues.cauldronblack,
  },
  '& .MuiInput-underline.Mui-disabled:after': {
    borderBottom: `1px solid ${colorValues.concretejungle}`,
  },
  '& .MuiInput-underline.Mui-disabled:before': {
    borderBottom: `1px solid ${colorValues.concretejungle}`,
  },
  // Error styles
  '& label.Mui-error': {
    color: colorValues.orangepink,
  },
  '& .MuiInput-underline.Mui-error:after': {
    borderBottomColor: colorValues.orangepink,
  },
  '& p.Mui-error': {
    color: colorValues.orangepink,
  },
  // Select Styles
  '& .MuiSelect-select:focus': {
    backgroundColor: `transparent !important`,
  },
  '& .MuiListItem-root': {
    fontSize: 14,
  },
});

type BuildInputStyle = (
  overrideStyle: CSSProperties,
  hasStartAdornment: boolean
) => CSSProperties;

export const buildInputStyle: BuildInputStyle = (
  overrideStyle: CSSProperties,
  hasStartAdornment: boolean
) => {
  return { ...defaultInputStyle(hasStartAdornment), ...overrideStyle };
};
