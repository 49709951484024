import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='33'
    height='32'
    viewBox='0 0 33 32'
    {...rest}
  >
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <path
                fill={color || '#333'}
                d='M24.573 16.876c-.853 4.305-5.016 7.1-9.299 6.242-1.076-.215-2.058-.641-2.91-1.226 2.785-.584 5.129-2.662 5.97-5.522 1.3-.374 2.587-.837 3.853-1.389.807-.351 1.607-.739 2.398-1.163.188.982.195 2.017-.012 3.058'
                transform='translate(-65 -953) translate(65 105) translate(.25 848) translate(0 5.09)'
              />
              <path
                fill={color || '#056B59'}
                d='M21.795 3.642c-.225.3-.435.54-.634.785-.1.115-.197.232-.293.352-.095.12-.189.227-.28.338l-.533.627-.504.579c-.654.733-1.265 1.378-1.882 1.928-.155.14-.307.265-.463.396-.154.13-.31.254-.465.37-.157.118-.314.234-.472.344-.158.105-.317.213-.479.315-.643.4-1.31.741-2.009 1.02-.539.214-1.095.397-1.676.542-.646.163-1.323.279-2.038.34-.31.027-.628.044-.953.05-.875.016-1.807-.05-2.817-.213-.506-.084-1.034-.194-1.59-.34-.56-.152-1.134-.32-1.789-.586.06-.733.21-1.35.377-1.948.173-.588.379-1.132.61-1.642.465-1.017 1.025-1.902 1.659-2.675.431-.522.893-.994 1.385-1.418.524-.454 1.081-.852 1.668-1.197.487-.287.993-.535 1.518-.75.872-.356 1.794-.609 2.76-.736.963-.128 1.966-.133 2.997.027.129.021.255.04.384.066l.39.084c.26.068.514.126.776.217.13.041.26.086.39.136.13.049.257.094.386.15.259.108.518.235.771.367.252.13.507.287.755.452.247.164.49.343.73.549.238.202.467.418.69.659.221.254.434.505.631.812'
                transform='translate(-65 -953) translate(65 105) translate(.25 848) translate(0 5.09)'
              />
              <path
                fill={color || '#333'}
                d='M27.437 3.662c2.09 0 4.055 1.745 4.534 3.101-2.55 2.514-5.141 4.362-7.701 5.68-1.446.745-2.883 1.32-4.296 1.751-2.466.752-4.86 1.063-7.114 1.063-1.534 0-3.002-.145-4.381-.392-1.764-.315-3.383-.8-4.811-1.366C2.238 12.932 1 12.282 0 11.636c.081.046 1.612.885 3.843 1.601 1.697.545 3.799 1.02 5.978 1.02.2 0 .4-.005.602-.013.26-.035.521-.054.782-.055.78-.077 1.561-.224 2.328-.46.358-.11.712-.24 1.062-.39 1.536-.659 2.917-1.527 4.2-2.535.566-.444 1.112-.915 1.644-1.408 1.418-1.312 2.734-2.775 4.04-4.277.913-1.05 1.95-1.457 2.958-1.457zm.2 1.429c-.804 0-1.455.651-1.455 1.454 0 .804.651 1.455 1.454 1.455.804 0 1.455-.651 1.455-1.455 0-.803-.651-1.454-1.455-1.454z'
                transform='translate(-65 -953) translate(65 105) translate(.25 848) translate(0 5.09)'
              />
              <path
                fill={color || '#FEFEFE'}
                d='M10.91 13.882c.241-.02.484-.053.726-.1-.242.002-.485.035-.727.1'
                transform='translate(-65 -953) translate(65 105) translate(.25 848) translate(0 5.09)'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
