import * as React from 'react';

// types
import {
  HealthcareVertical,
  useCompanyBillingOverviewQuery,
} from '../../../../generated/graphql';
import { UpdateCompanyInfoFormData } from '../hooks/useCompanyInfoOperations';

// components
import FormField from '../../../Shared/FormField';
import EmailInfoBox from './EmailInfoBox';

// constants
import states from '../../../../constants/states';

// styles
import { CompanyFormWrapper, Row } from '../CompanyInfo.style';

//helpers
import {
  emailValidation,
  nameValidation,
  normalizePhone,
  normalizeZipcode,
  phoneValidation,
  urlValidation,
} from '../../../../helpers';

// hooks
import { useHistory } from 'react-router';
import useTextFieldState from '../../../../hooks/useTextFieldState';
import { useMeQuery } from '../../../../generated/graphql';
import useSnackbar from '../../../../hooks/useSnackbar';
import useHandleError from '../../../../hooks/useHandleError';

interface Props {
  updateSaving: (value: boolean) => void;
  updateCompanyInfo: (payload: UpdateCompanyInfoFormData) => Promise<void>;
}

const CompanyForm = ({ updateCompanyInfo, updateSaving }: Props) => {
  const history = useHistory();
  const snackbar = useSnackbar();
  const meQuery = useMeQuery();
  const companyId = meQuery.data?.me?.company?.id;
  const billingOverviewQuery = useCompanyBillingOverviewQuery({
    variables: { id: companyId ?? '' },
    skip: !meQuery.data?.me?.company?.id,
  });
  const handleError = useHandleError();

  const companyNameFormState = useTextFieldState({ 
    required: true,
    validation: nameValidation,
  });
  const addressFormState = useTextFieldState({ 
    required: true,
    validation: nameValidation,
  });
  const cityFormState = useTextFieldState({ 
    required: true,
    validation: nameValidation,
  });
  const stateFormState = useTextFieldState({ required: true });
  const zipcodeFormState = useTextFieldState({
    required: true,
    normalizer: normalizeZipcode,
  });
  const phoneNumberFormState = useTextFieldState({
    required: true,
    normalizer: normalizePhone,
    validation: phoneValidation,
  });
  const contactEmailFormState = useTextFieldState({
    required: true,
    validation: emailValidation,
  });
  const companyWebsiteFormState = useTextFieldState({
    validation: urlValidation,
  });
  const companyVerticalFormState = useTextFieldState({ required: true });

  React.useEffect(() => {
    if (meQuery?.data) {
      const company = meQuery?.data?.me?.company;
      const location = company?.location;
      const phone =
        company?.phone?.charAt(0) === '1'
          ? company?.phone?.slice(1)
          : company?.phone;

      company?.companyName &&
        companyNameFormState.updateValue(company?.companyName);
      location?.address && addressFormState.updateValue(location?.address);
      location?.city && cityFormState.updateValue(location?.city);
      location?.state && stateFormState.updateValue(location?.state);
      location?.zipcode && zipcodeFormState.updateValue(location?.zipcode);
      phone && phoneNumberFormState.updateValue(phone);
      company?.email && contactEmailFormState.updateValue(company?.email);
      company?.website && companyWebsiteFormState.updateValue(company?.website);
      company?.healthcareVertical &&
        companyVerticalFormState.updateValue(company?.healthcareVertical);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meQuery.data]);

  const handleSubmitCompanyInfoForm = async (
    e: React.SyntheticEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    updateSaving(true);
    try {
      await updateCompanyInfo({
        companyId,
        companyNameFormState,
        addressFormState,
        cityFormState,
        stateFormState,
        zipcodeFormState,
        phoneNumberFormState,
        contactEmailFormState,
        companyWebsiteFormState,
        companyVerticalFormState,
      });
      snackbar?.setSuccessMessage('Company info saved');
      updateSaving(false);
      history.push('/onboarding/profile');
    } catch (e) {
      updateSaving(false);
      handleError(e);
    }
  };

  const allowSelectIndustry =
    billingOverviewQuery.data?.company?.billingOverview?.billingPartnership !==
    'Clinicient';

  return (
    <CompanyFormWrapper
      onSubmit={handleSubmitCompanyInfoForm}
      id='companyOnboardingForm'
    >
      <h2 className='H4'>Create Your Company</h2>
      <FormField
        fullWidth
        label='Company Name'
        placeholder='Enter your company name here'
        formHandler={companyNameFormState}
        inputProps={{
          maxLength: 255,
        }}
      />
      <FormField
        fullWidth
        label='Company HQ Address'
        placeholder='Enter the address for your company headquarters'
        formHandler={addressFormState}
        inputProps={{
          maxLength: 255,
        }}
      />
      <FormField
        fullWidth
        label='City'
        placeholder="Enter your company HQ's city name"
        formHandler={cityFormState}
        inputProps={{
          maxLength: 255,
        }}
      />
      <Row>
        <FormField
          select
          label='State'
          style={{ margin: '0px 20px 20px 0px' }}
          formHandler={stateFormState}
          placeholder='Select a state'
          SelectProps={{
            native: true,
          }}
        >
          <option>Select a state</option>
          {states.map((state) => (
            <option key={state.value} value={state.value}>
              {state.text}
            </option>
          ))}
        </FormField>
        <FormField
          label='Zipcode'
          placeholder='Enter zipcode'
          style={{ margin: '0px 0px 20px 20px' }}
          formHandler={zipcodeFormState}
        />
      </Row>
      <FormField
        fullWidth
        label='Phone Number'
        placeholder="Enter your company HQ's contact phone number"
        formHandler={phoneNumberFormState}
      />
      <div style={{ position: 'relative' }}>
        <FormField
          fullWidth
          label='Company Email'
          placeholder="Enter your company HQ's contact email"
          formHandler={contactEmailFormState}
          inputProps={{
            maxLength: 100,
          }}
        />
        <EmailInfoBox />
      </div>
      <FormField
        fullWidth
        label='Company Website'
        placeholder='Enter the URL of your company website (Optional)'
        formHandler={companyWebsiteFormState}
        inputProps={{
          maxLength: 49,
        }}
      />
      {allowSelectIndustry && (
        <>
          <h2 className='H4'>Healthcare Industry</h2>
          <FormField
            select
            label='Industry'
            formHandler={companyVerticalFormState}
            placeholder='Industry'
            fullWidth
            SelectProps={{
              native: true,
            }}
          >
            <option>Please select your healthcare industry</option>
            {Object.keys(HealthcareVertical).map((vertical) => (
              <option
                key={vertical}
                value={(HealthcareVertical as any)[vertical]}
              >
                {vertical.split(/(?=[A-Z])/).join(' ')}
              </option>
            ))}
          </FormField>
        </>
      )}
    </CompanyFormWrapper>
  );
};

export default CompanyForm;
