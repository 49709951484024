import * as React from 'react';

//types
import { Contact } from '../../../../generated/graphql';

//helpers
import { possessiveName } from '../../../../helpers';

// hooks
import { useParams } from 'react-router-dom';
import { useMeQuery, usePatientContactDetailsQuery } from '../../../../generated/graphql';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../hooks/useSnackbar';

// Components
import { Link } from 'react-router-dom';
import { Animation, Card, Button, colorValues } from '@betterpt/better-components';
import { GridRow } from '../../../Shared/GridCard';
import InPersonAppointmentsList from './InPersonAppointmentsList';

import PatientForm, { SecondaryContactForm } from '../components/PatientForm';

// style
import * as styles from './PatientContactDetail.style';
import AddAppointmentButtonWithDialog from '../../../Shared/AddAppointmentButtonWithDialog';
import { IcArrowRight, IcSuccessCircle } from '@betterpt/better-icons';

const PatientContactDetail = () => {
  const snackbar = useSnackbar();
  const meQuery = useMeQuery();
  const { patientId } = useParams<{ patientId: string }>();
  const { patientsSingularAlias, primaryColor } = useRemoteConfigAliases();

  const [isCreateAppointmentDialogOpen, updateIsCreateAppointmentDialogOpen] = React.useState(false);
  const [isTelehealthAppointment, updateIsTelehealhtAppointment] = React.useState(false);

  const patientDetail = usePatientContactDetailsQuery({
    variables: {
      id: patientId,
    },
    onError: () => snackbar?.useGenericErrorMessage(),
    fetchPolicy: 'cache-and-network',
  });

  const firstName = possessiveName(patientDetail.data?.contact.firstName ?? patientsSingularAlias);

  const loading = patientDetail.loading || meQuery.loading;
  const company = meQuery.data?.me?.company;
  const isNotWhiteLabel = company?.providerAppId !== 'acorn' && company?.providerAppId !== 'somatus';

  if (loading) {
    return (
      <span data-testid="patient-detail-loader">
        <Animation type="providerAppLoader" />
      </span>
    );
  }

  const patientFullName = `${patientDetail.data?.contact.patient?.firstName} ${patientDetail.data?.contact.patient?.lastName}`;
  const contactIsLinked = !!patientDetail.data?.contact.patientId;

  return (
    <styles.PatientDetailContainer data-testid="patient-detail-container">
      <styles.PatientDetailsHeader>
        <h1 className="H1">
          <Link to="/patients" style={styles.breadCrumbsLink}>
            Patients /
          </Link>{' '}
          {patientDetail.data?.contact.firstName} {patientDetail.data?.contact.lastName}
        </h1>
        {contactIsLinked && (
          <Link to={`/patients/${patientId}/access-details`} style={styles.betterAccessPatientButtonContainer}>
            <Button style={styles.betterAccessPatientButton}>
              <styles.LinkedPatientText>
                <IcSuccessCircle style={{ flexShrink: 0 }} color={colorValues.guajirogreen} />
                <styles.LinkedProfileButtonText>LINKED TO BETTERACCESS PATIENT {patientFullName}</styles.LinkedProfileButtonText>
              </styles.LinkedPatientText>
              <IcArrowRight style={{ flexShrink: 0 }} />
            </Button>
          </Link>
        )}
      </styles.PatientDetailsHeader>
      <GridRow style={{ justifyContent: 'flex-start', maxWidth: '1315px' }}>
        <Card style={{ ...styles.cardStyle, marginRight: 15 }}>
          <PatientForm isDetailsPage existingDetails={patientDetail.data?.contact as Contact} />
        </Card>
        <Card style={{ ...styles.cardStyle, height: 342 }}>
          <SecondaryContactForm
            existingDetails={patientDetail.data?.contact as Contact}
            refetch={patientDetail.refetch}
            loading={patientDetail.loading}
          />
        </Card>
      </GridRow>
      {isNotWhiteLabel && (
        <>
          <styles.PatientDetailsHeader>
            <h2 className="H1">{`${firstName}`} In Person Appointments</h2>
            {!patientDetail.data?.contact.archivedAt && (
              <AddAppointmentButtonWithDialog
                showInPersonOption={!!patientDetail.data?.contact.clinic?.betterPTSubscriptionActive}
                color={primaryColor}
                size="large"
                onInPersonClick={() => {
                  updateIsTelehealhtAppointment(false);
                  updateIsCreateAppointmentDialogOpen(true);
                }}
              />
            )}
          </styles.PatientDetailsHeader>
          <InPersonAppointmentsList
            updateIsCreateAppointmentDialogOpen={updateIsCreateAppointmentDialogOpen}
            isCreateAppointmentDialogOpen={isCreateAppointmentDialogOpen}
            isTelehealthAppointment={isTelehealthAppointment}
          />
        </>
      )}
    </styles.PatientDetailContainer>
  );
};

export default PatientContactDetail;
