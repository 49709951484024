import React from 'react';

//Hooks
import useSnackbar from '../../../../../../hooks/useSnackbar';
import useTextFieldState from '../../../../../../hooks/useTextFieldState';
import useHandleError from '../../../../../../hooks/useHandleError';
import { useRouteMatch } from 'react-router-dom';

// queries
import {
  Employee,
  useEmployeeQuery,
} from '../../../../../../generated/graphql';

// Components
import { Animation } from '@betterpt/better-components';
import { GridCard } from '../../../../../Shared/GridCard';
import FormField from '../../../../../Shared/FormField';
import CardHeader from './CardHeader';

//style
import { FormBody } from '../../styles/EmployeeDetail.style';

//types
import { UpdateEmployeeInfoPayload } from '../../hooks/useEmployeeOperations';
import {nameValidation} from '../../../../../../helpers';;

interface Props {
  updateEmployeeInfo: (payload: UpdateEmployeeInfoPayload) => Promise<void>;
}

const UserInfo = ({ updateEmployeeInfo }: Props) => {
  //hooks
  const {
    params: { employeeId },
  } = useRouteMatch<{ employeeId: string }>();
  const queryEmployee = useEmployeeQuery({
    variables: { id: employeeId },
    skip: !employeeId,
  });
  const snackbar = useSnackbar();
  const handleError = useHandleError();
  const [isEditModeActive, setEditModeActive] = React.useState(false);
  const [isSaving, updateIsSaving] = React.useState(false);
  const firstNameFormState = useTextFieldState({
    required: true,
    validation: nameValidation
  });
  const lastNameFormState = useTextFieldState({ 
    required: true,
    validation: nameValidation
  });
  const genderFormState = useTextFieldState({ required: true });

  React.useEffect(() => {
    const employee = queryEmployee.data?.employee;
    if (employee) {
      firstNameFormState.updateValue(employee?.firstName?.trim() ?? '');
      lastNameFormState.updateValue(employee?.lastName?.trim() ?? '');
      genderFormState.updateValue(employee?.gender ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryEmployee.data]);

  //functions
  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateIsSaving(true);
    try {
      await updateEmployeeInfo({
        employeeId,
        firstNameFormState,
        lastNameFormState,
        genderFormState,
      });

      snackbar?.setSuccessMessage('User Info Update Success');
      updateIsSaving(false);
      setEditModeActive(false);
    } catch (e) {
      updateIsSaving(false);
      handleError(e);
    }
  };

  // values
  const loading = queryEmployee.loading;
  const shouldHideEditButton = !!queryEmployee.data?.employee?.isManagedViaYext;

  return (
    <GridCard>
      {loading ? (
        <Animation type='providerAppLoader' />
      ) : (
        <form onSubmit={handleSubmit}>
          <CardHeader
            headerText='User Info'
            isEditModeActive={isEditModeActive}
            setEditModeActive={setEditModeActive}
            saving={isSaving}
            employee={queryEmployee.data?.employee as Employee}
            hideEditButton={shouldHideEditButton}
          />
          <FormBody>
            <FormField
              label='First Name'
              fullWidth
              disabled={!isEditModeActive}
              placeholder='Enter your first name'
              formHandler={firstNameFormState}
              inputProps={{
                maxLength: 49,
              }}
            />
            <FormField
              disabled={!isEditModeActive}
              fullWidth
              label='Last Name'
              placeholder='Enter your last name'
              formHandler={lastNameFormState}
              inputProps={{
                maxLength: 49,
              }}
            />
            <FormField
              disabled={!isEditModeActive}
              fullWidth
              select
              SelectProps={{ native: true }}
              label='Sex Assigned at Birth'
              placeholder='select your sex assigned at birth'
              formHandler={genderFormState}
            >
              <option value='' disabled>
                Select sex assigned at birth
              </option>
              <option value='M'>Male</option>
              <option value='F'>Female</option>
            </FormField>
          </FormBody>
        </form>
      )}
    </GridCard>
  );
};

export default UserInfo;
