import * as React from 'react';

//types
import { Contact } from '../../../../../../generated/graphql';

//helpers
import { normalizePhone } from '../../../../../../helpers';

// hooks
import { useHistory } from 'react-router-dom';
import {
  useFacilityContactListQuery,
  useToggleContactArchiveStatusMutation,
} from '../../../../../../generated/graphql';
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';
import { CardBody, ListTitle } from '../../FacilityDetails.style';
import useSnackbar from '../../../../../../hooks/useSnackbar';

// components
import { Table, Animation, colorValues } from '@betterpt/better-components';
import { GridCard } from '../../../../../Shared/GridCard';
import { BetterAccessColumnHeader } from '../../../../Patients/PatientList/PatientList';
import ContactOptions from '../../../../Patients/components/ContactOptions';
import ValidatedPhoneNumberPica from '../../../../../Shared/Picas/ValidatedPhoneNumber';

// assets
import { IcSuccessCircle } from '@betterpt/better-icons';

const PatientsList = ({ facilityId }: { facilityId: string }) => {
  const snackbar = useSnackbar();
  const history = useHistory();
  const isPartnerSite =
    window.location.host.includes('acorn') ||
    window.location.host.includes('somatus');

  const [offset, updateOffset] = React.useState(0);
  const [tableSection, updateTableSection] = React.useState(0);
  const [pageIndex, updatePageIndex] = React.useState(0);
  const [backToFirstPage, updateBackToFirstPage] = React.useState(false);
  const [selectedContactId, setSelectedContactId] = React.useState<
    string | null
  >(null);

  const facilityContactListQuery = useFacilityContactListQuery({
    variables: {
      id: facilityId,
      contactsInput: {
        offset,
        limit: 9,
        isArchived: false,
      },
    },
    onError: () => snackbar?.useGenericErrorMessage(),
    fetchPolicy: 'cache-and-network',
  });

  const [
    toggleArchivePatient,
    toggleArchivePatientOptions,
  ] = useToggleContactArchiveStatusMutation({
    onCompleted: () => {
      facilityContactListQuery.refetch();
    },
  });

  const {
    patientsSingularAlias,
    patientsPluralAlias,
    facilitiesSingularAlias,
    primaryColor,
  } = useRemoteConfigAliases();

  React.useEffect(() => {
    const backToStart = offset === 0 && tableSection === 0 && pageIndex === 0;
    updateBackToFirstPage(backToStart);
  }, [offset, tableSection, pageIndex]);

  const sharedColumns = [
    {
      field: 'name',
      title: `${patientsSingularAlias.toUpperCase()} NAME`,
      cellStyle: { wordBreak: 'break-word', maxWidth: 300 },
    },
    {
      field: 'email',
      title: `EMAIL`,
      cellStyle: { wordBreak: 'break-all', maxWidth: 300 },
    },
    { field: 'phone', title: `PHONE` },
    {
      field: 'dateOfBirth',
      title: 'DATE OF BIRTH',
    },
  ];

  const partnerColumns = [
    ...sharedColumns,
    {
      field: 'options',
      title: 'ACTION',
      cellStyle: { width: 100, textAlign: 'center' },
    },
  ];

  const betterAccessColumns = [
    ...sharedColumns,
    {
      field: 'hasBetterAccessAccount',
      title: <BetterAccessColumnHeader />,
      cellStyle: { maxWidth: 150, minWidth: 65, width: 65 },
    },
    {
      field: 'options',
      title: 'ACTION',
      cellStyle: { width: 100, textAlign: 'center' },
    },
  ];

  const rowData =
    facilityContactListQuery.data?.clinic?.contacts?.result?.map((contact) => ({
      id: contact?.id,
      name: `${contact?.lastName ?? '-'}, ${contact?.firstName ?? '-'}${
        !!contact?.archivedAt ? ' (Archived)' : ''
      }`,
      email: contact?.email,
      phone: (
        <div
          style={{
            position: 'relative',
            display: 'flex',
          }}
        >
          {contact?.hasValidatedMobileNumber && (
            <ValidatedPhoneNumberPica
              mainStyle={{ left: '-25px', top: '-2px' }}
            />
          )}
          {normalizePhone(contact?.phone || '') || '-'}
        </div>
      ),
      dateOfBirth: contact?.dateOfBirth,
      hasBetterAccessAccount: !isPartnerSite && contact?.patientId && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <IcSuccessCircle color={colorValues.guajirogreen} />
        </div>
      ),
      options: (
        <div data-testid={`contact-options-${contact?.id}`}>
          <ContactOptions
            patient={contact as Contact}
            toggleArchivePatient={toggleArchivePatient}
            isSavingArchive={toggleArchivePatientOptions.loading}
            openStatus={{
              value: selectedContactId === contact?.id,
              update: (isOpen) => {
                setSelectedContactId(isOpen ? contact?.id ?? null : null);
              },
            }}
          />
        </div>
      ),
    })) ?? [];

  const tableOptions = {
    minBodyHeight: 440,
    overflow: 'visible' as const,
    pagination: {
      backToFirstPage,
      totalCount:
        facilityContactListQuery?.data?.clinic?.contacts?.pager?.total || 0,
      pagesize: 12,
      defaultPageIndex: pageIndex,
      defaultSection: tableSection,
      onChangePage: (pageSize: number, idx: number, activeSection: number) => {
        updateOffset(idx * pageSize);
        updateTableSection(activeSection);
        updatePageIndex(idx);
      },
      primaryColor,
    },
    searchNotFoundText: 'Patient Not Found',
  };

  if (facilityContactListQuery.loading)
    return (
      <GridCard data-testid='employees-list-loader' style={{ width: '100%' }}>
        <Animation type='providerAppLoader' />
      </GridCard>
    );

  if (
    facilityContactListQuery.called &&
    !facilityContactListQuery.loading &&
    !facilityContactListQuery.data?.clinic?.contacts?.result?.length
  ) {
    return (
      <>
        <ListTitle>{patientsPluralAlias}</ListTitle>
        <GridCard style={{ width: '100%', marginRight: 0 }}>
          <CardBody>
            <p>
              No {patientsPluralAlias.toLowerCase()} have been added to this{' '}
              {facilitiesSingularAlias.toLowerCase()}.
            </p>
          </CardBody>
        </GridCard>
      </>
    );
  }

  return (
    <>
      <ListTitle>{patientsPluralAlias}</ListTitle>
      <GridCard
        data-testid='employees-list-container'
        style={{ width: '100%', marginRight: 0 }}
      >
        <Table
          columns={isPartnerSite ? partnerColumns : betterAccessColumns}
          data={rowData}
          isLoading={facilityContactListQuery.loading}
          options={tableOptions}
          onRowClick={(_e, rowData) => {
            history.push(`/patients/${rowData?.id}`);
          }}
        />
      </GridCard>
    </>
  );
};

export default PatientsList;
