import * as React from 'react';
import { datadogRum } from '@datadog/browser-rum';

// types
import { ConfirmOrDenyStatus } from '../../../../../../generated/graphql';

//hooks
import useHandleError from '../../../../../../hooks/useHandleError';
import {
  useInPersonAppointmentDetailsQuery,
  useUpdateInPersonAppointmentConfirmationStatusMutation,
} from '../../../../../../generated/graphql';

//components
import { Animation } from '@betterpt/better-components';
import Dialog from '@material-ui/core/Dialog';
import { ConfirmPage } from '../../../Shared/ConfirmOrReschedulePage';
import SlideTransition from '../../../../../Shared/SlideTransition';
import { PENDING_APPOINTMENTS_QUERY } from '../../../../Notifications/components/NotificationsPanel';

type Props = {
  open: boolean;
  onClose: () => void;
  appointmentId?: string;
  onDoneChangingOptionsAppointment: () => Promise<any> | undefined;
};

const ConfirmInPersonAppointmentDialog = ({
  open,
  onClose,
  appointmentId,
  onDoneChangingOptionsAppointment,
}: Props) => {
  // hooks
  const handleError = useHandleError();

  const [saving, updateSaving] = React.useState(false);
  const [employeeId, updateEmployeeId] = React.useState<string>('');

  const [confirmAppointment, confirmOptions] =
    useUpdateInPersonAppointmentConfirmationStatusMutation();

  const appointmentQuery = useInPersonAppointmentDetailsQuery({
    variables: { id: appointmentId! },
    skip: !appointmentId,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const appointment = appointmentQuery.data?.appointment;
  const alreadyHasEmployee =
    !!appointment?.employee || !!appointment?.employeeTherapistId;

  React.useEffect(() => {
    if (alreadyHasEmployee) {
      const existingId =
        appointment?.employee?.id ?? appointment?.employeeTherapistId ?? '';
      updateEmployeeId(existingId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alreadyHasEmployee]);

  const handleConfirmSubmit = async () => {
    if (!appointmentId || !employeeId) return;
    updateSaving(true);
    const apptTime =
      appointmentQuery.data?.appointment?.confirmedTime ||
      appointmentQuery.data?.appointment?.startTime;
    try {
      await confirmAppointment({
        variables: {
          input: {
            appointmentId: appointmentId!,
            confirmationStatus: ConfirmOrDenyStatus.Confirmed,
            confirmedTime: new Date(apptTime),
            employeeId,
          },
        },
        refetchQueries: [
          {
            query: PENDING_APPOINTMENTS_QUERY,
            variables: {
              input: {
                limit: 100,
              },
            },
          },
        ],
      });

      datadogRum.addUserAction('confirm_in_person_appointment', {
        clinicId: appointmentQuery.data?.appointment?.clinicId,
        employeeId:
          appointmentQuery.data?.appointment?.employee?.id ?? employeeId,
        patientId: appointmentQuery.data?.appointment?.patient?.id,
      });

      setTimeout(async () => {
        await onDoneChangingOptionsAppointment();
        updateSaving(false);
        onClose();
      }, 1000);
    } catch (e) {
      updateSaving(false);
      handleError(e);
      return;
    }
  };

  const renderContent = () => {
    if (appointmentQuery.loading) {
      return <Animation type='providerAppLoader' />;
    } else {
      return (
        <ConfirmPage
          appointmentId={appointmentId}
          handleConfirmSubmit={handleConfirmSubmit}
          closeDialog={onClose}
          saving={saving || confirmOptions.loading}
          employee={{
            value: employeeId,
            updateValue: updateEmployeeId,
          }}
        />
      );
    }
  };

  return (
    <Dialog
      TransitionComponent={SlideTransition}
      open={open}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
      PaperProps={{
        style: {
          overflow: 'visible',
        },
      }}
    >
      {renderContent()}
    </Dialog>
  );
};

export default ConfirmInPersonAppointmentDialog;
