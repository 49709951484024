import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...rest}
  >
    <defs>
      <path
        id='z1ggl5yrea'
        d='M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0V0zm0 0h24v24H0V0z' />
      <mask id='dtswbsdokb' fill='#fff'>
        <use xlinkHref='#z1ggl5yrea' />
      </mask>
      <use fill={color ?? '#333'} fillRule='nonzero' xlinkHref='#z1ggl5yrea' />
      <g fill={color ?? '#333'} mask='url(#dtswbsdokb)'>
        <path d='M0 0H24V24H0z' />
      </g>
      <path
        fill='#E74C3C'
        d='M10 -1H13V24H10z'
        transform='rotate(-45 11.5 11.5)'
      />
    </g>
  </svg>
);
