import React from 'react';

import { CSVGrayText, CSVRow } from '../AnalyticsCSV.style';

const TimeCSVClock = () => {
  const [minutes, setMinutes] = React.useState(10);
  const [seconds, setSeconds] = React.useState(0);
  React.useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <CSVRow>
      <CSVGrayText style={{ marginTop: 4 }}>EMAIL LINK EXPIRES IN:</CSVGrayText>
      <CSVGrayText style={{ fontSize: 28 }}>
        {minutes === 0 && seconds === 0 ? null : (
          <span>
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </span>
        )}
      </CSVGrayText>
    </CSVRow>
  );
};

export default TimeCSVClock;
