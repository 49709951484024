import React from 'react';

import {
  Checkbox as MCheckbox,
  makeStyles,
  createStyles,
  CheckboxProps,
} from '@material-ui/core';
import { colorValues } from '../helpers/colors';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      color: colorValues.betterptblack,
      '&.MuiCheckbox-colorSecondary.Mui-checked': {
        color: colorValues.betterptblack,
      },
      '&.MuiCheckbox-colorSecondary.Mui-checked.Mui-disabled': {
        color: colorValues.concretejungle,
      },
      '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
        backgroundColor: 'rgba(51, 51, 51, 0.04)',
      },
      '&.MuiCheckbox-colorSecondary.Mui-checked.Mui-disabled:hover': {
        backgroundColor: colorValues.concretejungle,
      },
      '&.MuiIconButton-colorSecondary:hover': {
        backgroundColor: 'rgba(51, 51, 51, 0.04)',
      },
    },
  })
);

const Checkbox = ({ ...rest }: CheckboxProps) => {
  const classes = useStyles();
  return <MCheckbox className={classes.root} {...rest} />;
};

export default Checkbox;
