import React from 'react';
import styled from '@emotion/styled';

//hooks
import { useHistory } from 'react-router-dom';

// helpers
import { Notification } from '../../../../helpers/mapDataToNotifications';

// components
import { Button, colorValues } from '@betterpt/better-components';

// assets
import { IcArrowRightWithStem } from '@betterpt/better-icons';

//styles
const NotificationItemContainer = styled.div`
  padding: 15px 33px 20px 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colorValues.concretejungle};
  button {
    svg {
      height: 24px;
      width: 24px;
    }
  }
`;

const SubTitle = styled.h4`
  color: ${colorValues.charcoalgray};
  margin-bottom: 0px;
`;

const buttonStyle = {
  backgroundColor: colorValues.ibizateal,
  width: '48px',
  padding: '0px',
  minWidth: '48px',
};

const NotificationItem = ({ notification }: { notification: Notification }) => {
  const { push } = useHistory();
  return (
    <NotificationItemContainer>
      <div>
        <h3 className='H5'> {notification.title}</h3>
        <SubTitle className='H5'>{notification.subTitle}</SubTitle>
      </div>
      <Button
        onClick={() => push(notification.linkToOnCtaClick)}
        style={buttonStyle}
        size='large'
      >
        <IcArrowRightWithStem color={colorValues.emptiness} />
      </Button>
    </NotificationItemContainer>
  );
};

export default NotificationItem;
