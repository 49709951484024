import * as React from 'react';
import { Maybe } from '../generated/graphql';

interface BoolFieldOptions {
  required?: boolean;
  initialValue?: Maybe<boolean>;
}

const useBoolFieldState = (options?: BoolFieldOptions) => {
  const [value, updateValue] = React.useState<Maybe<boolean> | undefined>(
    options?.initialValue
  );
  const [error, updateError] = React.useState<string | null>();
  const [touched, updateTouched] = React.useState(false);

  React.useEffect(() => {
    if (options?.required && touched && value !== false && value !== true) {
      updateError('Required');
    } else if (
      options?.required &&
      touched &&
      (value === false || value === true)
    ) {
      updateError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, touched]);

  return {
    value,
    updateValue,
    touched,
    updateTouched: () => updateTouched(true),
    error,
    valid: !error && !!(options?.required ? !!value : true),
  };
};

export type BoolFieldState = {
  value: Maybe<boolean> | undefined;
  updateValue: (value: boolean | null) => void;
  touched: boolean;
  updateTouched: () => void;
  error: string | null | undefined;
  valid: boolean;
};

export default useBoolFieldState;
