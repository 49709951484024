import * as React from 'react';
import ThemedTextField from '../../../Shared/ThemedTextField';

const CardTextField = ({
  value,
  label,
  fullWidth,
  ...rest
}: {
  value: string;
  label: string;
  fullWidth?: boolean;
}) => {
  return (
    <ThemedTextField
      {...rest}
      disabled
      style={{
        marginTop: 10,
        marginBottom: 20,
        width: fullWidth ? '100%' : '48%',
      }}
      label={label}
      value={value}
      inputProps={{
        style: {
          fontWeight: 'bold' as 'bold',
          fontSize: '16px',
          cursor: 'text',
        },
      }}
    />
  );
};

export default CardTextField;
