import styled from '@emotion/styled';

export const PatientsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    margin-right: 20px;
    margin-bottom: 0;
  }
`;
