import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#0088CF" d="M0 0H24V24H0z" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M4.9 15.483H3.288c0-2.24 0-4.478.057-6.776H4.9v2.475h2.245V8.707H8.7c0 2.239 0 4.478-.058 6.776H7.088v-2.77H4.842c.058.884.058 1.827.058 2.77zM21.31 10.18c-.404.295-.75.59-1.152.884-.116-.118-.173-.295-.288-.413-.403-.47-.922-.707-1.555-.53-.23.06-.403.177-.403.413 0 .235.115.412.288.471.46.177.864.295 1.324.471.288.118.576.177.864.354.633.353 1.036.825 1.036 1.59 0 .826-.345 1.415-1.036 1.827-.921.53-2.764.59-3.8-.471-.288-.295-.46-.648-.749-.943.461-.294.922-.53 1.325-.825l.345.53c.403.53 1.152.767 1.785.59.23-.06.403-.177.46-.472.058-.294-.115-.471-.345-.53-.345-.177-.748-.295-1.151-.412-.346-.118-.749-.236-1.094-.413-.634-.294-.98-.825-.98-1.59 0-.767.289-1.356.922-1.71 1.094-.647 3.167-.589 4.146 1.12.057 0 .057.06.057.06zM11.003 13.008c.23.472.46.825.921 1.002.403.177.806.236 1.21.118.23-.06.402-.177.46-.472.058-.294-.115-.471-.346-.53-.345-.177-.748-.295-1.151-.412-.345-.118-.69-.236-1.036-.413-.634-.294-1.037-.825-1.037-1.59 0-.826.288-1.474 1.037-1.827 1.094-.53 2.994-.472 3.972 1.12 0 0 0 .058.058.117-.403.295-.806.59-1.152.884-.172-.177-.345-.412-.518-.59-.403-.353-.863-.47-1.382-.353-.172.06-.345.177-.403.413-.057.235.058.412.23.471.346.177.692.295 1.037.412.403.177.806.295 1.21.472 1.496.707 1.208 2.651.172 3.358-1.267.825-3.282.53-4.203-.648-.173-.235-.288-.471-.46-.707.575-.294.978-.53 1.381-.825z"
      />
    </g>
  </svg>
);
