import React from 'react';
import { datadogRum } from '@datadog/browser-rum';

//types
import {
  Role,
  Contact,
  Clinic,
  CompanyListItemTypeEnum,
  CompanyListItem,
} from '../../../../../generated/graphql';

// helpers
import { format, parse } from 'date-fns';
import { colorValues } from '@betterpt/better-components';
import {
  emailValidation,
  phoneValidation,
  nameValidation,
  normalizePhone,
  formatPhoneForBackend,
  validateBirthday,
  normalizeDate,
  possessiveName,
} from '../../../../../helpers';

// hooks
import { useHistory } from 'react-router-dom';
import {
  useCreatePatientContactMutation,
  useMeQuery,
  useToggleContactArchiveStatusMutation,
  useUpdatePatientContactMutation,
  useCompanyListItemsQuery,
} from '../../../../../generated/graphql';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../../hooks/useSnackbar';
import useTextFieldState from '../../../../../hooks/useTextFieldState';
import useHandleError from '../../../../../hooks/useHandleError';

// components
import ValidatedPhoneNumberPica from '../../../../Shared/Picas/ValidatedPhoneNumber';
import { Header, Button } from '@betterpt/better-components';
import FormField from '../../../../Shared/FormField';
import ThemedTextField from '../../../../Shared/ThemedTextField';
import ThemedSwitch from '../../../../Shared/ThemedSwitch';

// queries
import { loader } from 'graphql.macro';

// style
import {
  submitButtonStyle,
  formFieldStyle,
  detailsNameFieldStyle,
  nameRowStyle,
  editSaveButtonStyle,
} from './PatientForm.style';

const QUERY_TOTAL_PATIENTS = loader(
  '../../../../../gql/patients/TotalPatients.graphql'
);

type Props = {
  existingDetails?: Contact;
  isDetailsPage?: boolean;
  facilityId?: string;
  populateAppointmentFormWithNewPatient?: (
    patientId: string,
    patientName: string
  ) => void;
};

const PatientForm: React.FC<React.PropsWithChildren<Props>> = ({
  existingDetails,
  isDetailsPage,
  facilityId,
  populateAppointmentFormWithNewPatient,
}) => {
  const handleError = useHandleError();
  const history = useHistory();
  const [formDisabled, updateFormDisabled] = React.useState(isDetailsPage);

  const queryMe = useMeQuery();
  const queryFacilities = useCompanyListItemsQuery({
    variables: {
      input: {
        companyId: queryMe.data?.me?.company?.id!,
        type: CompanyListItemTypeEnum.Clinic,
      },
    },
    skip: !queryMe.data?.me?.company?.id,
    fetchPolicy: 'cache-and-network',
  });

  const { facilitiesSingularAlias, patientsSingularAlias, primaryColor } =
    useRemoteConfigAliases();

  const snackbar = useSnackbar();

  const [createPatient, { loading: savingCreatePatient }] =
    useCreatePatientContactMutation();

  const [updatePatient, { loading: savingUpdatePatient }] =
    useUpdatePatientContactMutation();

  const [toggleArchive, { loading: savingArchiveToggle }] =
    useToggleContactArchiveStatusMutation();

  const firstName = useTextFieldState({
    validation: (value: string) => nameValidation(value, true),
    initialValue: existingDetails?.firstName,
  });
  const lastName = useTextFieldState({
    validation: (value: string) => nameValidation(value, true),
    initialValue: existingDetails?.lastName,
  });
  const email = useTextFieldState({
    validation: (value: string) => emailValidation(value, true),
    initialValue: existingDetails?.email,
  });
  const phoneIsRequired =
    queryMe.data?.me?.company?.shouldRequirePhoneForNewContacts ?? false;
  const phone = useTextFieldState({
    normalizer: normalizePhone,
    required: phoneIsRequired,
    validation: (value: string) => phoneValidation(value),
    initialValue: normalizePhone(existingDetails?.phone ?? ''),
  });
  const dateOfBirth = useTextFieldState({
    normalizer: normalizeDate,
    initialValue: existingDetails?.dateOfBirth
      ? format(
          parse(existingDetails.dateOfBirth, 'yyyy-MM-dd', new Date()),
          'MM/dd/yyyy'
        )
      : '',
    validation: validateBirthday,
  });
  const facility = useTextFieldState({
    required: true,
    initialValue: facilityId,
  });

  const [isArchived, updateIsArchived] = React.useState(
    !!existingDetails?.archivedAt
  );

  React.useEffect(() => {
    if (existingDetails) {
        const fname = existingDetails?.firstName;
        const lname = existingDetails?.lastName;
        firstName.updateValue(fname?.trim() ?? '');
        lastName.updateValue(lname?.trim() ?? '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [existingDetails]);

  const handleSubmit = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const formattedPhone = formatPhoneForBackend(phone.value);

    if (isDetailsPage && existingDetails?.id) {
      try {
        await updatePatient({
          variables: {
            input: {
              firstName: firstName.value,
              lastName: lastName.value,
              email: email.value,
              phone: formattedPhone.length ? formattedPhone : null,
              dateOfBirth: dateOfBirth.value
                ? format(new Date(dateOfBirth.value), 'yyyy-MM-dd')
                : undefined,
              contactId: existingDetails.id,
            },
          },
        });
        if (isArchived !== !!existingDetails.archivedAt) {
          await toggleArchive({
            variables: {
              input: {
                contactId: existingDetails.id,
                isArchived,
              },
            },
          });
        }
        snackbar?.setSuccessMessage(
          `Succesfully Updated ${patientsSingularAlias}!`
        );
        updateFormDisabled(true);
        queryMe?.refetch?.();
      } catch (e) {
        handleError(e);
      }
    } else {
      try {
        const response = await createPatient({
          variables: {
            input: {
              firstName: firstName.value.trim(),
              lastName: lastName.value.trim(),
              email: email.value || undefined,
              phone: phone.value?.split('-').join('') || undefined,
              dateOfBirth: dateOfBirth.value
                ? format(new Date(dateOfBirth.value), 'yyyy-MM-dd')
                : undefined,
              clinicId: facility.value,
            },
          },
          refetchQueries: [{ query: QUERY_TOTAL_PATIENTS }],
        });

        datadogRum.addUserAction('create_new_patient_contact', {
          clinicId: facility.value,
        });

        snackbar?.setSuccessMessage(
          `Succesfully Added ${patientsSingularAlias}!`
        );
        if (
          response.data?.createContact?.id &&
          populateAppointmentFormWithNewPatient
        ) {
          populateAppointmentFormWithNewPatient(
            response.data?.createContact.id,
            `${firstName.value} ${lastName.value}`
          );
        } else {
          history.push(`/patients/${response.data?.createContact?.id}`);
        }
      } catch (e) {
        handleError(e);
      }
    }
  };

  const loading = queryMe.loading || queryFacilities.loading;
  const savingForm =
    savingUpdatePatient || savingCreatePatient || savingArchiveToggle;

  const allFacilities = queryFacilities.data?.companyListItems ?? [];
  const myFacilities = queryMe.data?.me?.clinics ?? [];
  const facilitiesToDisplay =
    queryMe.data?.me?.role === Role.Owner ? allFacilities : myFacilities;

  const isFormValid =
    firstName.valid &&
    lastName.valid &&
    email.valid &&
    phone.valid &&
    dateOfBirth.valid &&
    (phoneIsRequired
      ? phone.value.length > 0
      : phone.value.length > 0 || email.value.length > 0) &&
    (isDetailsPage ? true : facility.valid);

  const submitButtonDisabled =
    savingUpdatePatient ||
    savingCreatePatient ||
    savingArchiveToggle ||
    !isFormValid;

  return (
    <form
      data-testid='create-patient-container'
      id='patient-form'
      style={{ marginBottom: isDetailsPage ? 63 : 0 }}
    >
      {isDetailsPage && (
        <Header underline style={{ paddingLeft: '45px' }}>
          <h2 className='H3'>
            {isDetailsPage
              ? `Primary Contact Info`
              : `${patientsSingularAlias} Info`}
          </h2>
          {formDisabled ? (
            <Button
              style={editSaveButtonStyle(false)}
              size='small'
              type='button'
              onClick={(e) => {
                e.preventDefault();
                updateFormDisabled(false);
              }}
            >
              EDIT
            </Button>
          ) : (
            <Button
              style={editSaveButtonStyle(true, isFormValid, primaryColor)}
              size='small'
              disabled={submitButtonDisabled}
              onClick={handleSubmit}
              loading={savingForm}
            >
              SAVE
            </Button>
          )}
        </Header>
      )}
      <div style={isDetailsPage ? nameRowStyle : {}}>
        <FormField
          style={isDetailsPage ? detailsNameFieldStyle : formFieldStyle}
          id='firstName'
          placeholder={`Enter the ${possessiveName(
            patientsSingularAlias.toLowerCase()
          )} first name`}
          label='First Name'
          formHandler={firstName}
          disabled={formDisabled}
          inputProps={{
            maxLength: 50,
          }}
        />
        <FormField
          style={isDetailsPage ? detailsNameFieldStyle : formFieldStyle}
          id='lastName'
          placeholder={`Enter the ${possessiveName(
            patientsSingularAlias.toLowerCase()
          )} last name`}
          label='Last Name'
          formHandler={lastName}
          disabled={formDisabled}
          inputProps={{
            maxLength: 50,
          }}
        />
      </div>
      <FormField
        style={formFieldStyle}
        id='email'
        placeholder={`Enter the ${possessiveName(
          patientsSingularAlias.toLowerCase()
        )} email`}
        label='Email'
        formHandler={email}
        disabled={formDisabled}
        inputProps={{
          maxLength: 50,
        }}
      />
      <FormField
        style={formFieldStyle}
        id='phone'
        placeholder={`Enter the ${possessiveName(
          patientsSingularAlias.toLowerCase()
        )} phone number`}
        label='Phone'
        formHandler={phone}
        disabled={formDisabled}
        InputProps={{
          endAdornment: isDetailsPage &&
            existingDetails?.hasValidatedMobileNumber && (
              <ValidatedPhoneNumberPica mainStyle={{ position: 'relative' }} />
            ),
        }}
      />
      {!phoneIsRequired && !isDetailsPage && (
        <p className='Body' style={formFieldStyle}>
          NOTE: You must enter an email and/or a phone number for your{' '}
          {patientsSingularAlias.toLowerCase()} contact.
        </p>
      )}
      <FormField
        style={formFieldStyle}
        id='dateOfBirth'
        placeholder='MM/DD/YYYY'
        label='DOB'
        formHandler={dateOfBirth}
        disabled={formDisabled}
      />
      {!isDetailsPage && (
        <FormField
          style={formFieldStyle}
          select
          id='facility'
          label={`Select ${facilitiesSingularAlias ?? ''}`}
          formHandler={facility}
          SelectProps={{ native: true }}
          disabled={!!facilityId || loading}
        >
          <option disabled value={''}>
            {loading ? 'Loading...' : 'Please select from the dropdown'}
          </option>
          {(
            facilitiesToDisplay as Array<
              Clinic | CompanyListItem | null | undefined
            >
          ).map((facility) => (
            <option key={facility?.id} value={facility?.id}>
              {(facility as Clinic)?.displayName ||
                (facility as CompanyListItem)?.name}
            </option>
          ))}
        </FormField>
      )}
      {isDetailsPage && (
        <>
          <ThemedTextField
            style={formFieldStyle}
            id='facility'
            label={facilitiesSingularAlias}
            value={existingDetails?.clinic?.displayName}
            disabled
          />
          <ThemedTextField
            style={formFieldStyle}
            id='status'
            value='Active'
            label='Status'
            disabled={formDisabled}
            InputProps={{
              endAdornment: (
                <ThemedSwitch
                  checked={!isArchived}
                  onChange={() => updateIsArchived(!isArchived)}
                />
              ),
            }}
          />
        </>
      )}

      {!isDetailsPage && (
        <Button
          style={submitButtonStyle(
            !isFormValid ? colorValues.dust : primaryColor
          )}
          disabled={submitButtonDisabled}
          size='large'
          form='patient-form'
          onClick={handleSubmit}
        >
          {savingForm
            ? `ADDING ${patientsSingularAlias.toUpperCase()}...`
            : `ADD ${patientsSingularAlias.toUpperCase()}`}
        </Button>
      )}
    </form>
  );
};

export default PatientForm;
