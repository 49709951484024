import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

export const FilterContainer = styled.div`
  position: relative;
  margin: 0px;
  display: flex;
  h2 {
    color: ${colorValues.cityblock};
  }
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px 24px;
`;

export const FilterSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const iconClose = {
  position: 'absolute' as 'absolute',
  top: 15,
  right: 15,
  cursor: 'pointer',
};

export const header = {
  backgroundColor: colorValues.earlysteam,
  padding: 20,
  borderBottom: `1px solid ${colorValues.concretejungle}`,
};

export const rangeTextField = {
  width: '109px',
};

export const sectionDates = {
  padding: 20,
  display: 'flex',
  flexDirection: 'row' as 'row',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'space-evenly',
};

export const inputSectionStyle = {
  width: 111,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column' as 'column',
};
