import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { colorValues } from '../helpers/colors';

export const mainTableStyles = (
  tableHeightForLoadingState: number | string | false | undefined
): CSSProperties => ({
  height: tableHeightForLoadingState || 'auto',
  '& .MuiTableCell-root.MuiTableCell-head': {
    fontSize: 12,
  },
  '& .MuiTableCell-root.MuiTableCell-body': {
    padding: 14,
    fontSize: 14,
    color: colorValues.cauldronblack,
    borderBottom: (tableHeightForLoadingState && 'none') || '',
  },
  '& .MuiTableHead-root': {
    borderBottom: `1px solid ${colorValues.concretejungle}`,
  },
  '& .MuiTableCell-root': {
    borderBottom: `1px solid ${colorValues.concretejungle}`,
  },
  '& .MuiTableCell-root.MuiTableCell-body:first-of-type': {
    padding: 14,
    paddingLeft: 27,
  },
  '& .MuiTableCell-root.MuiTableCell-head:first-of-type': {
    padding: 14,
    paddingLeft: 27,
  },
  '& .MuiTableCell-stickyHeader': {
    backgroundColor: colorValues.emptiness,
  },
});
