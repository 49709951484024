import { css, jsx } from '@emotion/react';

import MTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, createStyles, Tooltip } from '@material-ui/core';
import { Checkbox } from '../../../index';

import { cellStyle } from './style';
import { Column, DetailPanel } from '../types';

import IcOpenFilter from '../../Icons/IcOpenFilter';

const useStyles = makeStyles(() =>
  createStyles({
    root: { ...cellStyle, '&:first-of-type': { paddingLeft: '12px!important' } },
  })
);

const TableHead = <RowData extends object>({
  columns,
  onFilterClick,
  filtering,
  detailPanel,
  checkboxSelection,
  handleAllSelection,
  allRecordsSelected
}: {
  columns: Column<RowData>[];
  onFilterClick: () => void;
  filtering: boolean | undefined;
  detailPanel?: DetailPanel<RowData>;
  checkboxSelection?: boolean | undefined;
  handleAllSelection?: (checked?: boolean) => void;
  allRecordsSelected?: boolean;
}) => {
  const cellClass = useStyles();
  return (
    <MTableHead>
      <TableRow>
        {detailPanel && (
          <TableCell
            css={css`
              padding: 0 !important;
            `}
          />
        )}
        {columns.map((col, idx) => (
          <TableCell
            size="small"
            key={idx}
            style={{
              width: col.width ?? 'auto',
              maxWidth: col.width ?? 'none',
              ...(col.cellStyle ?? {}),
            }}
            className={cellClass.root}
          >
            {checkboxSelection && idx === 0 && (
              <Checkbox
                style={{ padding: 0, marginRight: 2 }}
                checked={allRecordsSelected}
                onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleAllSelection?.(e.target?.checked) }
              />
            )}
            {col.title}
          </TableCell>
        ))}
        {filtering && (
          <TableCell align="center" padding="none" size="small">
            <Tooltip title="Open filters">
              <IconButton aria-label="filters" onClick={onFilterClick}>
                <IcOpenFilter style={{ cursor: 'pointer' }} />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>
    </MTableHead>
  );
};

export default TableHead;
