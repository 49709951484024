import React from 'react';
import { Link, useParams } from 'react-router-dom';

//components
import TableView from '../../../../Appointments/InPersonAppointments/InPersonAppointmentsList/components/InPersonAppointmentsTable';
import { GridCard } from '../../../../../Shared/GridCard';
import { ListTitle, CardListBody } from '../../styles/EmployeeDetail.style';
import Can from '../../../../../Can';

//queries
import {
  Order,
  useMeQuery,
  useEmployeeQuery,
  useInPersonAppointmentsQuery,
} from '../../../../../../generated/graphql';

//hooks
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../../../hooks/useSnackbar';

// helpers
import { Roles } from '../../../../../../helpers/rbac-rules';
import { possessiveName } from '../../../../../../helpers';

const InPersonAppointmentsList = () => {
  const snackbar = useSnackbar();
  const { employeeId } = useParams<{ employeeId: string }>();
  const [offset, setOffset] = React.useState(0);
  const [defaultPageIndex, updateDefaultPageIndex] = React.useState(0);
  const pagesize = 10;

  const aliasConfig = useRemoteConfigAliases();

  const employeeString = aliasConfig.employeeSingularAlias ?? 'Employee';
  const facilityString = aliasConfig.facilitiesPluralAlias ?? 'Facilities';

  const queryEmployee = useEmployeeQuery({
    variables: { id: employeeId },
    skip: !employeeId,
  });

  const appointmentsQueryInput = {
    limit: pagesize,
    offset,
    employeeIds: [employeeId],
    order: Order.Desc,
  };

  const meQuery = useMeQuery();

  const inPersonAppointmentsQuery = useInPersonAppointmentsQuery({
    variables: {
      input: appointmentsQueryInput,
    },
    onError: (e) => console.error(e),
    fetchPolicy: 'cache-and-network',
  });

  React.useEffect(() => {
    if (inPersonAppointmentsQuery.error?.message) {
      snackbar?.useGenericErrorMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inPersonAppointmentsQuery.data]);

  const totalCount =
    inPersonAppointmentsQuery.data?.inPersonAppointments?.pager?.total;

  const noAppointmentsCreated =
    inPersonAppointmentsQuery.called &&
    !inPersonAppointmentsQuery.loading &&
    !inPersonAppointmentsQuery.data?.inPersonAppointments?.pager?.total;

  return (
    <>
      <ListTitle>
        {possessiveName(queryEmployee.data?.employee?.firstName)} In-Person
        Appointments
      </ListTitle>
      <GridCard style={{ width: '100%', marginRight: 0 }}>
        <Can
          role={Roles[meQuery.data?.me?.role || 'initial']}
          perform='employee:viewAppointments'
          yes={() => (
            <>
              {noAppointmentsCreated ? (
                <CardListBody>
                  <p>
                    No appointments registered with this{' '}
                    {employeeString.toLowerCase()} at your{' '}
                    {facilityString.toLowerCase()}.
                  </p>
                </CardListBody>
              ) : (
                <TableView
                  tableOptions={{
                    minBodyHeight: 440,
                    maxBodyHeight: 440,
                    overflow: 'auto',
                  }}
                  onChangePage={(pageSize: number, idx: number) => {
                    setOffset(idx * pageSize);
                    updateDefaultPageIndex(idx);
                  }}
                  isLoading={inPersonAppointmentsQuery.loading}
                  totalCount={totalCount ?? 0}
                  appointmentsQuery={inPersonAppointmentsQuery}
                  defaultPageIndex={defaultPageIndex}
                  pagesize={pagesize}
                  onDoneChangingOptionsAppointment={() =>
                    inPersonAppointmentsQuery.refetch({
                      input: appointmentsQueryInput,
                    })
                  }
                />
              )}
            </>
          )}
          no={() => (
            <CardListBody>
              <p>
                You do not have permission to view the appointments of this{' '}
                {employeeString.toLowerCase()}.
                {employeeId === meQuery.data?.me?.id && (
                  <>
                    <br />
                    <br />
                    To view your own appointments, please go to your profile{' '}
                    <Link to='/profile'>here</Link>.
                  </>
                )}
              </p>
            </CardListBody>
          )}
        />
      </GridCard>
    </>
  );
};

export default InPersonAppointmentsList;
