import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { loader } from 'graphql.macro';
import {
  useMeForProfileCheckQuery,
  useCreateEmployeeAndCompanyMutation,
  useCompanyBillingOverviewQuery,
} from '../../../generated/graphql';

import { checkPermissions } from '../../Can';
import { Roles } from '../../../helpers/rbac-rules';

const ME_FOR_PROFILE_CHECK = loader(
  '../../../gql/employee/MeForProfileCheck.graphql'
);

const useProfileCompletionCheck = () => {
  const history = useHistory();
  const meQuery = useMeForProfileCheckQuery();

  const canAccessBilling = checkPermissions(
    Roles[meQuery.data?.me?.role ?? 'initial'],
    'billingOverview:access'
  );

  const billingProfileQuery = useCompanyBillingOverviewQuery({
    variables: { id: meQuery.data?.me?.company?.id ?? '' },
    skip: !canAccessBilling || !meQuery.data?.me?.company?.id,
  });

  const skipBilling =
    (billingProfileQuery.data?.company?.billingOverview
      ?.isInvoiceBillingActiveForTransactionalSub &&
      billingProfileQuery.data.company.billingOverview
        .hasAgreedToSubscriptionTermsForTransactionalSub) ||
    (billingProfileQuery.data?.company?.billingOverview
      ?.isInvoiceBillingActiveForPrepaidSub &&
      billingProfileQuery.data?.company?.billingOverview
        ?.hasAgreedToSubscriptionTermsForPrepaidSub) ||
    billingProfileQuery.data?.company?.billingOverview?.billingPartnership ===
      'Clinicient';

  const [createAccount, { loading: creatingAccount }] =
    useCreateEmployeeAndCompanyMutation({
      refetchQueries: [{ query: ME_FOR_PROFILE_CHECK }],
    });

  React.useEffect(() => {
    if (
      meQuery.called &&
      !meQuery.loading &&
      !billingProfileQuery.called && //In New Apollo client version when query is skipped the called comes false in previous version it was true even for skipped query. Details can be seen here https://github.com/apollographql/apollo-client/pull/9798
      !billingProfileQuery.loading
    ) {
      if (!meQuery.data?.me) {
        createAccount().then(() => {
          history.push('/onboarding/welcome');
        });
      } else if (!meQuery.data.me?.company?.companyName) {
        history.push('/onboarding/company');
      } else if (!meQuery.data.me?.firstName) {
        history.push('/onboarding/profile');
      } else if (
        canAccessBilling &&
        !skipBilling &&
        !!billingProfileQuery.data?.company?.billingOverview &&
        !billingProfileQuery.data?.company?.billingOverview.cardLastFourDigit
      ) {
        history.push('/onboarding/billing');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    meQuery.data,
    billingProfileQuery.data,
    canAccessBilling,
    skipBilling,
    createAccount,
    history,
  ]);

  return { loading: meQuery.loading || creatingAccount };
};

export default useProfileCompletionCheck;
