import { useFlags } from 'launchdarkly-react-client-sdk';
import Flags from '../constants/flags';

const useFeatureFlagWrapper = () => {
  const flags = useFlags();

  return <T>(value: T, flag: Flags, defaultValue?: T) => {
    if (flags[flag]) return value;
    else if (defaultValue) return defaultValue;
  };
};

export default useFeatureFlagWrapper;
