import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

export const ClickableCard = styled.div<{ isListView?: boolean }>`
  margin-bottom: ${(props) => (props.isListView ? '24px' : 0)};
  width: 100%;
  height: min-content;
  cursor: ${(props) => (props.isListView ? 'pointer !important' : 'normal')};
  border-radius: 5px;
  &:hover {
    box-shadow: ${(props) =>
      props.isListView
        ? `0 0 10px 0 rgba(51, 51, 51, 0.24), 0 2px 5px 0 rgba(51, 51, 51, 0.12)`
        : undefined};
  }
  &:focus {
    box-shadow: ${(props) =>
      props.isListView
        ? `0 0 10px 0 rgba(51, 51, 51, 0.24), 0 2px 5px 0 rgba(51, 51, 51, 0.12)`
        : undefined};
  }
`;

export const HeaderOptions = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 33.33%;
`;

export const InfoWithIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const Flag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 48px;
  background-color: ${({ color }) => color || colorValues.cityblock};
  position: absolute;
  top: 0px;
  left: 28px;
  :before {
    content: '';
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: -15px;
    border-left: ${({ color }) =>
      `15px solid ${color || colorValues.cityblock}`};
    border-right: ${({ color }) =>
      `15px solid ${color || colorValues.cityblock}`};
    border-bottom: 15px solid transparent;
  }
`;

export const Title = styled.div`
  margin: 0 35px;
  width: 66.67%;
  h2 {
    font-weight: bold;
    font-size: 16px !important;
  }
  h3 {
    font-weight: bold !important;
    color: ${colorValues.charcoalgray} !important;
    font-size: 12px !important;
    max-width: 100% !important;
  }
`;

export const CardBody = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 28px 33px 28px;
`;

export const InfoSection = styled.div`
  width: 30%;
  border: 1px solid ${colorValues.dust};
  border-radius: 5px;
`;

export const InfoHeader = styled.div`
  background-color: ${colorValues.earlysteam};
  padding: 10px 30px;
  border-bottom: solid 1px ${colorValues.concretejungle};
  border-radius: 5px 5px 0px 0px;
  p {
    color: ${colorValues.charcoalgray};
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
`;

export const ExpireDateText = styled.p`
  font-size: 12px;
  margin: 0;
  margin-top: -20px;
  font-style: italic;
`;

export const AltTimesColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InfoBody = styled.div<{ withIcons?: boolean }>`
  padding: ${({ withIcons }) =>
      (withIcons && '13px 0 18px 0;') || '13px 0 18px 40px;'}
    input {
    font-weight: bold !important;
  }
`;

export const ContactContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const Contacted = styled.p`
  color: ${colorValues.emptiness}!important;
  background-color: ${colorValues.fresco};
  font-weight: bold;
  font-size: 11px!important;
  padding: 2px 16px;
  text-align: center;
  font-weight: bold;
  align-self: center;
`;
