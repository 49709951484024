import styled from '@emotion/styled';
import { colorValues, bestTextColor } from '@betterpt/better-components';

export const DialogBody = styled.div`
  margin: 47px 62px 30px 46px;
  h2 {
    font-size: 20px;
  }
  p {
    font-size: 14px;
  }
`;

export const QRBox = styled.div`
  background-color: ${colorValues.earlysteam};
  padding: 20px 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  input {
    text-align: center !important;
    font-size: 20px !important;
  }
`;

export const DialogButtons = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const dialogButtonStyle = (color?: string) => ({
  backgroundColor: color || colorValues.dust,
  color: bestTextColor(color || colorValues.dust),
  height: 48,
});
