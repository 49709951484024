import * as React from 'react';

//helpers
import noAppointmentsCopy from './noAppointmentsCopy';

//hooks
import { useHistory } from 'react-router-dom';
import useFeatureAccessCheck from '../../../../../hooks/useFeatureAccessCheck';
import useAppointmentFilters from '../../../../../hooks/useVideoAppointmentFilters';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';

//components
import { Animation } from '@betterpt/better-components';
import { NoAppointmentsFoundContainer } from '../../VideoAppointments/VideoAppointmentsList/VideoAppointmentsList.style';

type Props = {
  loading?: boolean;
  isNoMatchingAppointmentsPage?: boolean;
  subject?: string;
  telehealth?: boolean;
};

const NoAppointmentsView: React.FC<React.PropsWithChildren<Props>> = ({
  loading,
  isNoMatchingAppointmentsPage,
  subject,
  telehealth,
}) => {
  const history = useHistory();
  const { resetFilters } = useAppointmentFilters();
  const {
    isOwner,
    hasBillingAccess,
    hasSubscription,
    hasTelehealth,
    isNotWhiteLabel,
    loading: companyFeaturesLoading,
  } = useFeatureAccessCheck();
  const {
    patientsPluralAlias,
    employeePluralAlias,
    facilitiesPluralAlias,
    primaryColor,
    loading: remoteConfigLoading,
  } = useRemoteConfigAliases();
  const localLoading = !!(
    loading ||
    companyFeaturesLoading ||
    remoteConfigLoading
  );

  const {
    generateImage,
    generateHeader,
    generateBody,
    generateButton,
  } = noAppointmentsCopy({
    loading: localLoading,
    subject,
    telehealth,
    isNotWhiteLabel,
    isOwner,
    isNoMatchingAppointmentsPage,
    hasSubscription,
    hasTelehealth,
    hasBillingAccess,
    primaryColor,
    resetFilters,
    history,
    patientsPluralAlias,
    employeePluralAlias,
    facilitiesPluralAlias,
  });

  return (
    <NoAppointmentsFoundContainer
      data-testid='no-appointments-view'
      style={!!subject ? { marginTop: 0 } : {}}
    >
      {localLoading ? (
        <Animation type='providerAppLoader' />
      ) : (
        <div
          data-testid='no-appointments-view-content'
          className='inner-container'
        >
          {generateImage()}
          <h2>{generateHeader()}</h2>
          <p className='Paragraph'>{generateBody()}</p>
        </div>
      )}
    </NoAppointmentsFoundContainer>
  );
};

export default NoAppointmentsView;
