import * as React from 'react';
import { passwordValidation } from '../../../helpers/validations';

const usePasswordState = () => {
  const [password, updatePassword] = React.useState('');
  const [errors, updateErrors] = React.useState<string[]>([]);
  const [touched, updateTouched] = React.useState(false);

  React.useEffect(() => {
    if (password.length > 0) {
      updateTouched(true);
    }
    if (touched) {
      updateErrors(passwordValidation(password));
    }
  }, [password, touched]);

  return {
    value: password,
    updateValue: updatePassword,
    touched,
    updateTouched: () => updateTouched(true),
    errors,
    valid: !errors.length,
  };
};

export type PasswordState = {
  value: string;
  updateValue: (value: string) => void;
  touched: boolean;
  updateTouched: () => void;
  errors: string[];
  valid: boolean;
};

export default usePasswordState;
