import React from 'react';
import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

const QuestionContainer = styled.div`
  border-radius: 5px;
  border: solid 2px ${colorValues.concretejungle};
  margin: 15px 25px 15px 50px;
  padding: 18px 0px 18px 21px;
`;

const QuestionText = styled.p`
  border-bottom: 1px solid ${colorValues.coneyislandsidewalk};
`;

const AnswerText = styled.p`
  border-bottom: 1px solid ${colorValues.coneyislandsidewalk};
`;

const AnswersContainer = styled.div`
  margin-left: 30px;
`;

const QuestionNumber = styled.h3`
  margin: 0px 0px 10px 0px;
  color: ${colorValues.charcoalgray};
`;

interface Props {
  question: any;
  index: number;
}

const DisplayQuestion: React.FC<React.PropsWithChildren<Props>> = ({
  question, index
}) => {

  let { id, questionType, required, options, questionStatement } = question;
  let isMultipleChoice = questionType === "Multiple Choice";

  return (
    <QuestionContainer key={id}>
      <QuestionNumber className='H3'>Question {index + 1}</QuestionNumber>
      <p className='H6'>{questionType}</p>
      <QuestionText className='Body'>
        {questionStatement} ({required ? 'Required' : 'Optional'})
      </QuestionText>
      {isMultipleChoice && (
        <AnswersContainer>
          <p className='Form'>Answers</p>
          {options?.map((option: any) => (
            <AnswerText className='Body' key={option.id}>
              {option.optionStatement}
            </AnswerText>
          ))}
        </AnswersContainer>
      )}
    </QuestionContainer>
  );
};

export default DisplayQuestion;