import React from 'react';

//types
import styled from '@emotion/styled';

//components
import { Header } from '@betterpt/better-components';
import { GridCard } from '../../../Shared/GridCard';

//styles
import { colorValues } from '@betterpt/better-components';

const QuestionContainer = styled.div`
  border-radius: 5px;
  border: solid 2px ${colorValues.concretejungle};
  margin: 15px 25px 15px 50px;
  padding: 18px 0px 18px 21px;
`;

const QuestionText = styled.p`
  border-bottom: 1px solid ${colorValues.coneyislandsidewalk};
`;

const QuestionNumber = styled.h3`
  margin: 0px 0px 10px 0px;
  color: ${colorValues.charcoalgray};
`;

const headerStyle = { padding: '15px 25px 10px 50px' };

const DefaultQuestionCard = () => {

    return (

        <GridCard fullWidth>
            <>
                <Header style={headerStyle} underline>
                    <h2 className='H3'>Industry Questions</h2>
                </Header>
                <QuestionContainer key={"question_1"}>
                    <p className='H6'>Yes or No Question</p>
                    <QuestionText className='Body'>
                        Do you have a physician's referral? (Required)
                    </QuestionText>
                </QuestionContainer>

                <QuestionContainer key={"question_2"}>
                    <p className='H6'>Open-Ended Question</p>
                    <QuestionText className='Body'>
                    If yes, add your referring phsyican's name. (Required for referrals)
                    </QuestionText>
                </QuestionContainer>
            </>
        </GridCard>
    );
};

export default DefaultQuestionCard;
