import * as React from 'react';
import axios from 'axios';
import Auth from '@aws-amplify/auth';

// helpers
import { Roles } from '../../../../../../helpers/rbac-rules';
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';
import { nameValidation } from '../../../../../../helpers';

// hooks
import { useFacilityQuery, useMeQuery, useUpdateFacilityMutation } from '../../../../../../generated/graphql';
import useTextFieldState from '../../../../../../hooks/useTextFieldState';
import useSnackbar from '../../../../../../hooks/useSnackbar';

// components
import { Button, Animation, Header, ImageUploader, TextField, Switch } from '@betterpt/better-components';
import { GridCard } from '../../../../../../components/Shared/GridCard';
import FormField from '../../../../../../components/Shared/FormField';
import Can from '../../../../../../components/Can';

//assets
import AvatarPlaceholder from '../../../../../../assets/user-profile-hero-img-no-clinic-photo.svg';

//styles
import * as sharedStyles from '../../FacilityDetails.style';
import useHandleError from '../../../../../../hooks/useHandleError';

const AboutThisClinic = ({ facilityId }: { facilityId: string }) => {
  // hooks
  const snackbar = useSnackbar();
  const handleError = useHandleError();
  const facilityQuery = useFacilityQuery({
    variables: { id: facilityId },
  });
  const meQuery = useMeQuery();
  const company = meQuery.data?.me?.company;
  const isNotWhiteLabel = company?.providerAppId !== 'acorn' && company?.providerAppId !== 'somatus';

  const [isFormDisabled, updateIsFormDisabled] = React.useState(true);
  const [isSaving, updateSaving] = React.useState(false);

  // form values
  const facilityName = useTextFieldState({
    validation: nameValidation,
  });
  const about = useTextFieldState();
  const [useClinicName, updateUseClinicName] = React.useState(false);
  const [avatarUrl, updateAvatarUrl] = React.useState<string | null>();
  const [hasAvatarBeenUpdated, updateHasAvatarBeenUpdated] = React.useState<boolean>(false);

  // update existing values from the query
  React.useEffect(() => {
    const { data } = facilityQuery;
    if (data) {
      const { clinic } = data;

      facilityName.updateValue(clinic?.clinicName?.trim() ?? '');
      updateUseClinicName(clinic?.useClinicName ?? false);
      about.updateValue(clinic?.about ?? '');
      updateAvatarUrl(clinic?.avatarUrl ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityQuery.data]);

  const [updateFacility] = useUpdateFacilityMutation();

  // values
  const { facilitiesSingularAlias, primaryColor, loading: remoteConfigLoading } = useRemoteConfigAliases();
  const disableButton = !facilityName.valid;

  // functions
  const handleFormSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isFormDisabled) {
      updateIsFormDisabled(false);
    } else {
      updateSaving(true);

      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const endpoint = window.__RUNTIME_CONFIG__.REACT_APP_API_ENDPOINT ?? 'https://api.development.betterpt.com/v0/api';

        if (hasAvatarBeenUpdated) {
          await axios({
            method: 'POST',
            url: `${endpoint}/clinics/${facilityId}/avatar`,
            headers: {
              authorization: token,
              'Content-Type': 'application/json',
            },
            data: {
              file: avatarUrl,
            },
          });
          updateHasAvatarBeenUpdated(false);
        }

        await updateFacility({
          variables: {
            input: {
              clinicId: facilityId,
              clinicName: facilityName.value,
              about: about.value ?? undefined,
              useClinicName: useClinicName ?? false,
            },
          },
        });

        await facilityQuery.refetch();

        updateSaving(false);
        updateIsFormDisabled(true);
        snackbar?.setSuccessMessage(`${facilitiesSingularAlias} updated successfully!`);
      } catch (e) {
        updateSaving(false);
        updateIsFormDisabled(true);
        handleError(e);
      }
    }
  };

  if (facilityQuery.loading || meQuery.loading || remoteConfigLoading) {
    return (
      <GridCard style={sharedStyles.loaderCardStyle} data-testid="about-this-clinic-loader">
        <Animation type="providerAppLoader" />
      </GridCard>
    );
  }

  const facilityIsManagedViaYext = facilityQuery.data?.clinic?.isManagedViaYext;

  return (
    <GridCard style={sharedStyles.cardStyle}>
      <form onSubmit={handleFormSubmit}>
        <Header data-testid="about-this-clinic-header" underline style={sharedStyles.headerStyle}>
          <h3 className="H3">About this clinic</h3>
          <Can
            role={Roles[meQuery.data?.me?.role || 'initial']}
            perform="facility:view"
            data={{
              employeeClinics: meQuery?.data?.me?.clinics,
              clinicToView: facilityQuery.data?.clinic,
            }}
            yes={() => (
              <Can
                role={Roles[meQuery.data?.me?.role || 'initial']}
                perform="facility:edit"
                yes={() => (
                  <Button
                    style={sharedStyles.editButtonStyle(isFormDisabled, primaryColor)}
                    size="small"
                    type="submit"
                    loading={isSaving}
                    disabled={(!isFormDisabled && disableButton) || isSaving}
                  >
                    {isFormDisabled ? 'EDIT' : 'SAVE'}
                  </Button>
                )}
              />
            )}
          />
        </Header>
        <sharedStyles.InnerCard data-testid="about-this-clinic-form">
          <ImageUploader
            type="hero"
            image={avatarUrl ?? AvatarPlaceholder}
            onSubmit={(img) => {
              updateHasAvatarBeenUpdated(true);
              updateAvatarUrl(img.base64Img);
            }}
            disabled={isFormDisabled}
            style={sharedStyles.clinicImage}
            width={456}
            onError={(message) => snackbar?.openSnackbar({ isError: true, message })}
          />
          <sharedStyles.Row>
            <FormField
              style={{
                width: isNotWhiteLabel ? '48%' : '100%',
                marginBottom: '0px',
              }}
              label={`${facilitiesSingularAlias} Name`}
              formHandler={facilityName}
              id="facilityNameInput"
              disabled={facilityIsManagedViaYext || isFormDisabled}
              inputProps={{
                maxLength: 255,
              }}
            />
            {isNotWhiteLabel && (
              <TextField
                id="useClinicName"
                label="Use Clinic Name"
                value={`Clinic Name ${useClinicName ? 'Active' : 'Inactive'}`}
                disabled={isFormDisabled}
                style={{ width: '48%' }}
                InputProps={{
                  endAdornment: (
                    <Switch
                      checked={useClinicName}
                      onChange={() => updateUseClinicName(!useClinicName)}
                      disabled={isFormDisabled}
                      data-testid="use-clinic-name-switch"
                    />
                  ),
                }}
              />
            )}
          </sharedStyles.Row>
          <FormField
            style={sharedStyles.formFieldStyle}
            label={'About'}
            placeholder="None Added"
            formHandler={about}
            id="about"
            disabled={isFormDisabled}
            fullWidth
            multiline
            inputProps={{
              maxLength: 1000,
            }}
          />
        </sharedStyles.InnerCard>
      </form>
    </GridCard>
  );
};

export default AboutThisClinic;
