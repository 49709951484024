import React from 'react';

//components
import AuthWrapper from '../AuthWrapper';
import TextField from '../../Shared/ThemedTextField';
import { Button } from '@betterpt/better-components';

//hooks
import { useParams, useHistory } from 'react-router-dom';
import useAuthentication from '../../../hooks/useAuthentication';
import usePasswordState from '../hooks/usePasswordState';
import { determineVariant } from '../AuthWrapper/loginVariantConfig';

// style
import { loginButtonStyle } from '../shared.style';

const ChangePassword = () => {
  // hooks
  const { variant } = useParams<{ variant: string }>();
  const { push } = useHistory();
  const auth = useAuthentication();
  const { email } = useParams<{ email: string }>();
  const decodedEmail = decodeURIComponent(email);
  const newPassword = usePasswordState();
  const [confirmPassword, updateConfirmPassword] = React.useState('');

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    auth
      ?.forceChangePassword(decodedEmail, newPassword.value.trim())
      .then(() => {
        push('/');
      });
  };

  React.useEffect(() => {
    if (!auth?.user) {
      const loginLink = variant ? `/login/${variant}` : '/login';
      push(loginLink);
    }
  }, [auth, push, variant]);

  // values
  const primaryColor = determineVariant(variant).color;
  const passwordsDontMatch = newPassword.value !== confirmPassword;
  const isButtonDisabled =
    passwordsDontMatch ||
    newPassword.errors.length > 0 ||
    newPassword.value.length < 1 ||
    auth?.isLoading;
  const passwordError =
    newPassword.errors.length > 0
      ? newPassword.errors.map((err) => (
          <React.Fragment key={err}>
            {err}
            <br />
          </React.Fragment>
        ))
      : '';

  return (
    <AuthWrapper>
      <form onSubmit={handleSubmit}>
        <h1 className='H2'>Change Password</h1>
        <p style={{ overflowWrap: 'break-word' }}>
          Please set a new password to login
        </p>
        <TextField
          onChange={(e) => newPassword.updateValue(e.currentTarget.value)}
          label='Password'
          placeholder='Enter your new password here'
          type='password'
          error={newPassword.errors.length > 0}
          helperText={passwordError}
        />
        <TextField
          onChange={(e) => updateConfirmPassword(e.currentTarget.value)}
          label='Confirm Password'
          placeholder='Re-enter your new password'
          type='password'
          error={passwordsDontMatch}
          helperText={passwordsDontMatch ? 'Passwords must match' : ''}
        />
        <Button
          loading={auth?.isLoading}
          type='submit'
          fullWidth
          size='large'
          style={loginButtonStyle(primaryColor)}
          disabled={auth?.isLoading || isButtonDisabled}
        >
          Submit
        </Button>
      </form>
    </AuthWrapper>
  );
};

export default ChangePassword;
