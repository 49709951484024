import axios from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import Auth from '@aws-amplify/auth';
import {
  useCreateCompanyEmployeeMutation,
  useMeQuery,
  useAssociateEmployeeWithClinicsMutation,
  Gender,
  Role,
} from '../../../../../generated/graphql';
import {
  formatPhoneForBackend,
  formatYearsExperience,
} from '../../../../../helpers';
import { TextFieldState } from '../../../../../hooks/useTextFieldState';
import { CognitoCustomUserType } from '../../../../../context/authentication';

export interface CreateEmployeeInput {
  firstNameState: TextFieldState;
  lastNameState: TextFieldState;
  emailState: TextFieldState;
  phoneState: TextFieldState;
  roleState: TextFieldState;
  genderState: TextFieldState;
  clinicsToAssociateEmployee: string[];
  isTherapist: boolean;
  avatar: string;
  bioState: TextFieldState;
  degreeOrCertificationState: TextFieldState;
  yearsExperienceState: TextFieldState;
  shouldSendInvite: boolean;
}

export default function useCreateEmployeeOperations() {
  const meQuery = useMeQuery();
  const [callCreateEmployeeMutation] = useCreateCompanyEmployeeMutation();

  const [associateEmployee] = useAssociateEmployeeWithClinicsMutation();

  const saveEmployeeAvatar = async (id?: string, avatar?: string) => {
    const user: CognitoCustomUserType = await Auth.currentAuthenticatedUser();
    const userSession = user?.getSignInUserSession();

    const token = userSession?.getIdToken();
    if (!avatar || !id || !token) return;
    const endpoint =
      window.__RUNTIME_CONFIG__.REACT_APP_API_ENDPOINT ??
      'https://api.development.betterpt.com/v0/api';
    return axios.post(
      `${endpoint}/employees/${id}/avatar`,
      { file: avatar },
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: token.getJwtToken(),
        },
      }
    );
  };

  const createEmployee = async (payload: CreateEmployeeInput) => {
    const companyId = meQuery?.data?.me?.company?.id;
    const shouldNewEmployeesDefaultToProvider =
      meQuery?.data?.me?.company?.shouldNewEmployeesDefaultToProvider;
    const { clinicsToAssociateEmployee, avatar, ...input } = payload;

    if (!companyId) {
      throw new Error(
        'There was a problem loading your company information. Please refresh the page and try again'
      );
    } else if (!input.firstNameState.valid) {
      throw new Error('Please enter a valid first name.');
    } else if (!input.lastNameState.valid) {
      throw new Error('Please enter a valid last name.');
    } else if (!input.genderState.valid) {
      throw new Error('Please select a gender.');
    } else if (!input.emailState.valid) {
      throw new Error('Please enter a valid email.');
    } else if (!input.phoneState.valid) {
      throw new Error('Please enter a valid phone number.');
    } else if (!input.roleState.valid) {
      throw new Error('Please select a role for your new employee');
    } else if (!input.yearsExperienceState.valid) {
      throw new Error('Please enter a valid practice start date.');
    } else if (!input.bioState.valid) {
      throw new Error('Please enter a valid bio');
    } else if (!input.degreeOrCertificationState.valid) {
      throw new Error('Please enter a valid certification');
    } else if (
      input.isTherapist === undefined &&
      !shouldNewEmployeesDefaultToProvider
    ) {
      throw new Error(
        'Please indicate if this user is a practicing care provivder'
      );
    }

    const employee = await callCreateEmployeeMutation({
      variables: {
        input: {
          companyId,
          firstName: input.firstNameState.value.trim(),
          lastName: input.lastNameState.value.trim(),
          gender: input.genderState.value as Gender,
          email: input.emailState.value,
          phone: formatPhoneForBackend(input.phoneState.value),
          role: input.roleState.value as Role,
          yearsExperience: formatYearsExperience(input.yearsExperienceState),
          bio: input.bioState.value,
          isTherapist: input.isTherapist,
          shouldSendInvite: input.shouldSendInvite,
          degreeOrCertification: input.degreeOrCertificationState.value,
        },
      },
    });
    const employeeId = employee.data?.createCompanyEmployee?.result?.id;
    if (clinicsToAssociateEmployee && employeeId) {
      await associateEmployee({
        variables: {
          input: {
            employeeId,
            clinicIds: clinicsToAssociateEmployee,
          },
        },
      });
    }
    datadogRum.addUserAction('create_new_employee', {
      companyId,
    });

    avatar && (await saveEmployeeAvatar(employeeId, avatar));

    return employeeId;
  };

  return {
    commands: {
      createEmployee,
    },
  };
}
