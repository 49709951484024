import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    width='161'
    height='160'
    viewBox='0 0 161 160'
  >
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g transform='translate(-719 -272) translate(597 272) translate(122.5) translate(1 24)'>
              <path
                fill='#6A564E'
                d='M72.175 31.518c-7.26-8.271-6.558-20.958 1.57-28.346 4.062-3.692 10.296-3.334 13.928.802 3.626 4.132 3.278 10.479-.783 14.17-4.065 3.694-4.416 10.037-.788 14.169 3.591 4.106 9.796 4.492 13.858.864l.071-.065c-8.128 7.388-20.597 6.673-27.855-1.594'
              />
              <path
                fill='#3ECBCB'
                d='M89.236 59.507v23.897H53.158V59.507c0-8.265 5.625-15.224 13.28-17.297.52-.146 1.048-.265 1.587-.359 1.03-.181 2.09-.276 3.172-.276 1.364 0 2.688.15 3.965.434.539.122 1.066.264 1.585.433 2.748.879 5.21 2.4 7.204 4.386 3.267 3.244 5.285 7.729 5.285 12.679'
              />
              <path
                fill='#E2946D'
                d='M55.316 31.34c.252 4.62 2.361 8.701 5.576 11.563 3.21 2.867 7.52 4.51 12.168 4.26 9.297-.5 16.423-8.396 15.919-17.638-.009-.182-.024-.359-.039-.54-.165-1.927-.656-3.746-1.407-5.418-.103-.226-.21-.448-.32-.663-.952-1.884-2.25-3.556-3.81-4.943-3.21-2.867-7.52-4.51-12.168-4.26-3.826.206-7.282 1.661-9.987 3.953-.034.022-.063.049-.095.085-.55.464-1.07.97-1.552 1.505-1.4 1.546-2.508 3.348-3.25 5.323-.558 1.496-.914 3.084-1.022 4.738-.033.49-.045.99-.033 1.492.005.183.01.36.02.542'
              />
              <circle cx='87' cy='33' r='4' fill='#E2946D' fillRule='nonzero' />
              <circle
                cx='57.158'
                cy='33'
                r='4'
                fill='#E2946D'
                fillRule='nonzero'
              />
              <path
                fill='#6A564E'
                d='M89.574 33.336c-8.966-5.6-14.044-11.728-12.553-16.286.215-.652.553-1.253.995-1.772.664-.8 1.58-1.48 2.72-2.04.155-.087.319-.164.493-.238.983.986 1.91 2.033 2.77 3.133 2.435 3.099 4.162 6.39 5.05 9.706.667 2.512.869 5.032.525 7.497M56.286 32.451c4.295-3.26 6.556-7.106 5.522-10.275-.146-.448-.353-.871-.616-1.258-.39-.58-.9-1.093-1.52-1.538-.087-.069-.176-.133-.269-.191-.458.622-.875 1.276-1.246 1.957-1.073 1.933-1.764 4.062-2.038 6.268-.203 1.677-.146 3.377.167 5.037'
              />
              <path
                fill='#6A564E'
                d='M76.61 23.186c-.146-.447-.353-.87-.616-1.258-.39-.58-.9-1.093-1.52-1.538-.087-.069-.176-.132-.269-.191-.458.622-.874 1.277-1.246 1.957-1.072 1.933-1.764 4.063-2.037 6.268-.204 1.677-.147 3.377.167 5.038 4.294-3.262 6.555-7.107 5.52-10.276z'
                transform='rotate(45 73.83 26.83)'
              />
              <path
                fill='#6A564E'
                d='M82.327 22.356c-.146-.448-.353-.871-.616-1.258-.39-.58-.901-1.093-1.52-1.538-.087-.069-.176-.133-.27-.191-.457.622-.874 1.276-1.245 1.957-1.073 1.933-1.764 4.062-2.038 6.268-.203 1.677-.147 3.377.167 5.037 4.294-3.26 6.556-7.106 5.522-10.275z'
                transform='rotate(25 79.547 26)'
              />
              <path
                fill='#6A564E'
                d='M85.148 10.736c.013.155.025.31.03.463.03.948-.11 1.893-.414 2.79l.002.007c-1.842 5.74-9.586 11.958-19.938 15.38-4.052 1.339-8.034 2.097-11.67 2.3.253-3.214 1.186-6.335 2.739-9.15 2.857-5.245 7.774-9.524 14.064-11.603 5.179-1.711 10.476-1.668 15.187-.187'
              />
              <path
                fill='#FFF'
                d='M86.926 20.403c-.12 0-.235-.054-.303-.146-3.831-5.162-10.26-7.847-19.106-7.98-.2 0-.36-.15-.36-.332v-.001c.004-.182.177-.316.37-.322 9.102.137 15.73 2.92 19.702 8.272.11.149.067.35-.095.45l-.005.003c-.061.037-.131.056-.203.056'
              />
              <path
                fill='#FFF'
                d='M86.926 18.403c-.12 0-.235-.054-.303-.146-3.831-5.162-10.26-7.846-19.106-7.98-.2 0-.36-.15-.36-.332v-.002c.004-.18.181-.32.37-.321 9.102.137 15.73 2.92 19.702 8.272.11.148.067.35-.095.45-.002 0-.003.002-.005.003-.061.036-.131.056-.203.056'
              />
              <path
                fill='#F06356'
                d='M86.926 19.403c-.12 0-.235-.054-.303-.146-3.831-5.16-10.26-7.846-19.106-7.98-.2 0-.36-.15-.36-.332v-.001c.005-.181.168-.324.365-.322h.006c9.101.138 15.73 2.921 19.7 8.272.11.148.068.35-.093.45l-.006.004c-.061.036-.131.055-.203.055'
              />
              <path
                fill='#5171EF'
                d='M61.617 101.847L67.683 101.847 67.683 83.404 61.617 83.404z'
              />
              <path
                fill='#B1B1B1'
                d='M55 106.982h13.25V100h-6.226c-3.88 0-7.024 3.126-7.024 6.982'
              />
              <path
                fill='#5171EF'
                d='M74.157 101.847L80.223 101.847 80.223 83.404 74.157 83.404z'
              />
              <path
                fill='#B1B1B1'
                d='M87.25 106.982H74V100h6.226c3.88 0 7.024 3.126 7.024 6.982'
              />
              <path
                fill='#2AB7B3'
                d='M80.247 70.22c-1.488 0-3.093-.09-4.817-.27-2.104-.22-3.63-2.094-3.41-4.186.221-2.09 2.105-3.61 4.211-3.389 6.727.703 9.846-.347 10.474-1.459.996-1.766-1.302-6.634-3.388-9.347-1.286-1.67-.965-4.06.716-5.339 1.682-1.277 4.085-.957 5.37.713 1.346 1.75 7.864 10.827 3.984 17.7-2.11 3.737-6.428 5.578-13.14 5.578M62.852 72.026c-.163 0-.329-.01-.496-.032-2.197-.282-13.271-2.073-15.449-9.652-1.445-5.033 1.451-10.4 8.854-16.41 1.64-1.331 4.053-1.089 5.391.54 1.339 1.63 1.095 4.029-.544 5.36-5.237 4.251-6.684 7.192-6.332 8.418.559 1.948 5.655 3.753 9.062 4.19 2.099.27 3.58 2.18 3.309 4.265-.25 1.92-1.897 3.321-3.795 3.321'
              />
              <path
                fill='#2B4F83'
                d='M66.94 39.521h.03c.725-.006 1.307-.595 1.3-1.315-.006-.72-.598-1.299-1.323-1.292h-.03c-.724.006-1.306.595-1.3 1.315.007.72.6 1.298 1.323 1.292M77.277 39.521h.03c.725-.006 1.306-.595 1.3-1.315-.006-.72-.598-1.299-1.323-1.292h-.03c-.724.006-1.307.595-1.3 1.315.006.72.598 1.298 1.323 1.292'
              />
              <path
                fill='#FFF'
                d='M71.655 51.38h.022c.541-.005.976-.444.972-.983-.005-.537-.448-.97-.989-.965h-.023c-.541.004-.976.444-.972.983.006.538.448.97.99.966M71.655 58.468h.022c.541-.005.976-.445.972-.983-.005-.538-.448-.97-.989-.965h-.023c-.541.004-.976.445-.972.983.006.537.448.97.99.965M71.655 65.556h.022c.541-.005.976-.445.972-.983-.005-.538-.448-.97-.989-.965h-.023c-.541.004-.976.444-.972.983.006.537.448.97.99.965M71.655 72.644h.022c.541-.005.976-.445.972-.984-.005-.537-.448-.97-.989-.965h-.023c-.541.004-.976.445-.972.983.006.538.448.97.99.966M71.655 79.732h.022c.541-.005.976-.445.972-.983-.005-.538-.448-.971-.989-.966h-.023c-.541.005-.976.445-.972.983.006.538.448.97.99.966'
              />
              <path
                fill='#333'
                d='M75.435 62.032l-.415 8.57c-.038.836-.76 1.49-1.609 1.446l-6.577-.31-.983-.045c-.85-.045-1.5-.762-1.463-1.607l.288-5.87.127-2.692c.045-.844.767-1.497 1.61-1.454l7.56.362c.85.039 1.5.756 1.462 1.6'
              />
              <path
                fill='#E2946D'
                d='M66.834 71.737c-.721.66-1.673 1.086-2.727 1.143-1.213.063-2.337-.368-3.173-1.117-.844-.743-1.392-1.81-1.457-3.015 0-.045-.005-.095-.005-.14 0-.134 0-.26.012-.394.026-.43.115-.844.262-1.23.198-.522.486-.991.849-1.391.128-.14.262-.273.41-.394.006-.012.012-.018.018-.025.71-.596 1.61-.978 2.612-1.028.358-.019.702.006 1.04.07l-.286 5.87c-.039.845.613 1.562 1.462 1.607l.983.044zM73.446 66.368c.067 1.205.616 2.27 1.455 3.017.838.748 1.963 1.177 3.175 1.112 2.427-.13 4.286-2.19 4.155-4.602l-.011-.142c-.043-.502-.17-.977-.367-1.413-.026-.06-.055-.117-.084-.174-.248-.49-.587-.928-.994-1.289-.837-.748-1.962-1.177-3.174-1.112-1 .054-1.901.434-2.607 1.032-.009.006-.017.013-.024.022-.145.121-.28.253-.405.392-.365.404-.655.874-.848 1.39-.146.39-.239.804-.267 1.236-.009.128-.012.258-.009.39l.005.14'
              />
              <path
                fill='#4A79B5'
                d='M124.425 15.802l-1.324-3.07 3.075-1.322 1.318 3.068-3.07 1.324zm.645-6.3c.334.132.497.514.364.847-.133.333-.514.497-.847.364-.334-.133-.498-.515-.365-.848.134-.332.515-.497.848-.364zm-2.178 1.939c-.333-.133-.497-.514-.364-.847.133-.334.514-.497.847-.364.333.133.497.514.364.847-.133.333-.514.497-.847.364zm-.728-1.695c-.334-.133-.497-.514-.364-.847.133-.333.514-.497.847-.364.333.133.497.514.364.848-.133.332-.514.496-.847.363zm-1.149-1.89l-1.324-3.07 3.076-1.322 1.318 3.068-3.07 1.324zm3.327-.05c.333.133.497.515.364.848-.133.333-.514.497-.847.364-.334-.132-.497-.514-.364-.847.132-.333.514-.497.847-.364zm2.74 3.215l3.374-1.45c.33-.141.483-.523.341-.854l-1.58-3.677c-.143-.33-.525-.483-.855-.34l-3.373 1.448-1.449-3.373c-.075-.163-.203-.284-.36-.347-.152-.06-.333-.063-.494.006l-3.678 1.581c-.33.142-.482.523-.34.854l1.449 3.373-3.372 1.449c-.331.142-.484.524-.341.854l1.58 3.678c.142.33.523.483.854.34l3.373-1.449 1.449 3.373c.072.17.202.284.36.347.157.063.333.063.5-.004l3.677-1.58c.331-.142.483-.524.342-.854l-1.456-3.375z'
              />
              <path
                fill='#FF6454'
                d='M148.998 37.803L144 37.404 146.717 41.528 144.785 46.057 149.633 44.803 153.437 48 153.717 43.101 158 40.549 153.325 38.775 152.167 34z'
              />
              <path
                fill='#69DF86'
                d='M121.327 63.575l-8.916-4.305 3.35-7.075 8.915 4.305-3.349 7.075zm4.871-10.291l-.636-.308.609-1.286-1.275-.615-.608 1.287-5.094-2.46.608-1.287-1.273-.615-.61 1.286-.636-.307c-.707-.341-1.542-.033-1.877.675l-4.268 9.001c-.334.707-.042 1.56.665 1.901l8.915 4.305c.701.339 1.548.036 1.882-.67l4.263-9.006c.334-.707.036-1.563-.665-1.901zm-3.405 3.887l-1.273-.615-.609 1.287 1.273.615.61-1.287zm-2.547-1.23l-1.274-.615-.608 1.287 1.273.615.609-1.287zm-2.547-1.23l-1.274-.614-.609 1.286 1.274.615.61-1.286z'
              />
              <path
                fill='#FFB1A9'
                d='M10.6 72.073l-.987-1.887-1.913.973-.987-1.886-1.913.973.987 1.886-1.913.973.987 1.887 1.912-.973.988 1.887 1.912-.974-.986-1.887 1.912-.972zm-6.62-5.71L10.33 66l1.776 3.395c1.642 3.142.975 7.2-1.613 9.42-3.334.792-7.074-1.024-8.717-4.165L0 71.255l3.98-4.892z'
              />
              <path
                fill='#2A4E82'
                d='M5.348 31.658l-.4-.647-.646.401-.4-.646-.645.401.4.646-.645.4.4.646.645-.4.4.646.645-.4-.4-.647.646-.4zm-.31-3.386c1.722-1.07 5.697-2.34 6.762-.62L13 29.59 2.677 36l-1.2-1.938c-1.065-1.719 1.838-4.72 3.56-5.79zm-3.6-5.814c-1.427.885-1.866 2.758-.981 4.186.884 1.428 2.755 1.867 4.18.982 1.427-.885 1.865-2.759.981-4.187-.884-1.428-2.755-1.867-4.18-.981z'
              />
              <path
                fill='#FFCC46'
                d='M32.395 29.235c1.151-.551 2.546-.094 3.115 1.02.57 1.115.098 2.465-1.054 3.016-1.15.552-2.546.095-3.115-1.02-.569-1.115-.097-2.465 1.054-3.016m-1.374-2.691c-2.682 1.283-3.785 4.44-2.46 7.037 1.804 3.533 9.33 6.419 9.33 6.419s2.203-7.543.4-11.075c-1.327-2.597-4.587-3.665-7.27-2.381'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
