import React from 'react';
import styled from '@emotion/styled';
import { FieldTemplateProps } from '@rjsf/core';
import { colorValues } from '@betterpt/better-components';

const ErrorContainer = styled.div`
  p {
    color: ${colorValues.messyketchup};
    font-size: 14px;
  }
`;

const errorMap = new Map([
  ['is a required property', 'Required'],
  [
    'should NOT have fewer than 1 items',
    'Please add at least one valid answer to this question',
  ],
  ['should NOT be shorter than 1 characters', 'Please specify a value'],
  [
    'should NOT be longer than 1000 characters',
    'Input cannot be longer than 1000 characters',
  ],
]);

const CustomFieldTemplate = (props: FieldTemplateProps) => {
  const { help, children, rawErrors } = props;
  const deDuplicatedErrors = Array.from(new Set(rawErrors));

  const errorsToRender = deDuplicatedErrors.map((err) => errorMap.get(err));

  return (
    <div>
      {children}
      <ErrorContainer>
        {errorsToRender && <p>{errorsToRender}</p>}
      </ErrorContainer>
      {help}
    </div>
  );
};
export default CustomFieldTemplate;
