import { loader } from 'graphql.macro';

//types
import { TextFieldState } from '../../../../hooks/useTextFieldState';
import { BoolFieldState } from '../../../../hooks/useBoolFieldState';
import { AppointmentMedium } from '../../../../generated/graphql';

//hooks
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import {
  useCreateAppointmentTypeMutation,
  useSetEmployeeAppointmentTypesMutation,
} from '../../../../generated/graphql';
export interface AddAppointmentTypePayload {
  companyId: string;
  displayNameFormState: TextFieldState;
  durationFormState: TextFieldState;
  intervalFormState: TextFieldState;
  medium: string;
  canChooseProviderFormState: BoolFieldState;
  legacyIsInitialEvalFormState: BoolFieldState;
  shouldRequireManualInsuranceFormState: BoolFieldState;
  shouldShowFinalCheckboxFormState: BoolFieldState;
  customFormTemplate?: any;
}

//queries
const QUERY_EMPLOYEE_APPOINTMENT_TYPES = loader(
  '../../../../gql/appointmentTypes/EmployeeAppointmentTypes.graphql'
);
const QUERY_COMPANY_APPOINTMENT_TYPES = loader(
  '../../../../gql/appointmentTypes/CompanyAppointmentTypes.graphql'
);

export interface AssignAppointmentTypesToProviderPayload {
  providerId: string;
  appointmentTypeIds: string[];
}

const useAppointmentTypeOperations = () => {
  const { employeeSingularAlias } = useRemoteConfigAliases();
  const [createAppointmentType] = useCreateAppointmentTypeMutation();
  const [
    setProviderAppointmentTypes,
  ] = useSetEmployeeAppointmentTypesMutation();

  const addAppointmentType = async (payload: AddAppointmentTypePayload) => {
    const {
      companyId,
      displayNameFormState,
      durationFormState,
      intervalFormState,
      canChooseProviderFormState,
      legacyIsInitialEvalFormState,
      shouldRequireManualInsuranceFormState,
      shouldShowFinalCheckboxFormState,
      customFormTemplate
    } = payload;

    if (!companyId) {
      throw new Error(
        'Your company could not be accessed. Please refresh and try again.'
      );
    } else if (!displayNameFormState.valid) {
      throw new Error('Please enter a name for this appointment type.');
    } else if (!durationFormState.valid) {
      throw new Error(
        'Please select a valid duration for this appointment type.'
      );
    } else if (!intervalFormState.valid) {
      throw new Error(
        'Please select a valid interval for this appointment type.'
      );
    }

    let createdAppointment = await createAppointmentType({
      variables: {
        input: {
          companyId,
          displayName: displayNameFormState.value,
          duration: parseInt(durationFormState.value),
          interval: parseInt(intervalFormState.value),
          medium: 'inClinic' as AppointmentMedium,
          shouldHideProviderOption: !canChooseProviderFormState.value,
          legacyIsInitialEval: legacyIsInitialEvalFormState.value,
          shouldHideFinalCheckbox: !shouldShowFinalCheckboxFormState.value,
          shouldRequireManualInsurance: !!shouldRequireManualInsuranceFormState.value,
          customFormTemplate: customFormTemplate
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUERY_COMPANY_APPOINTMENT_TYPES,
        },
      ],
    });
    return createdAppointment?.data;
  };

  const assignProviderToAppointmentTypes = async (
    payload: AssignAppointmentTypesToProviderPayload
  ) => {
    const { providerId, appointmentTypeIds } = payload;

    if (!providerId) {
      throw new Error(
        `This ${employeeSingularAlias.toLowerCase()} could not be accessed. Please refresh and try again.`
      );
    } else if (!appointmentTypeIds.length) {
      throw new Error(
        `Please select at least one appointment type to assign to this ${employeeSingularAlias.toLowerCase()}.`
      );
    } else if (appointmentTypeIds.some((id) => !id)) {
      throw new Error('Something went wrong. Please refresh and try again.');
    }

    await setProviderAppointmentTypes({
      variables: {
        input: {
          employeeId: providerId,
          appointmentTypeIds,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUERY_EMPLOYEE_APPOINTMENT_TYPES,
          variables: {
            id: providerId,
          },
        },
      ],
    });
  };

  return {
    commands: {
      addAppointmentType,
      assignProviderToAppointmentTypes,
    },
  };
};

export default useAppointmentTypeOperations;
