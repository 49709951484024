import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { colorValues, InfoBox } from '@betterpt/better-components';
import { Maybe } from '../../../../../../generated/graphql';

const InfoBoxBody = styled.div`
  h5 {
    color: ${colorValues.charcoalgray};
  }
  a {
    color: ${colorValues.frenchblue};
  }
`;

const StaffHoursInfoBox = ({
  clinicName,
  facilityId,
}: {
  clinicName?: Maybe<string>;
  facilityId: string;
}) => {
  return (
    <InfoBox
      height={225}
      width={275}
      position='right'
      iconStyle={{ verticalAlign: 'middle' }}
    >
      <InfoBoxBody>
        <h5 className='h6'>STAFF HOURS</h5>
        <p className='Body'>
          Adding staff hours here will show the times that this employee works
          at {clinicName}.
        </p>
        <Link to={`/facilities/${facilityId}/config`} className='Body'>
          Click here to view {clinicName}'s appointment settings.
        </Link>
      </InfoBoxBody>
    </InfoBox>
  );
};

export default StaffHoursInfoBox;
