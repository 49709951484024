import styled from '@emotion/styled';
import { colorValues, useBlackText } from '@betterpt/better-components';

export const DialogBody = styled.div`
  position: relative;
  padding: 47px 45px 36px 48px;
  margin: 5px;
`;

export const H1 = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 24px;
`;

export const DialogParagraph = styled.p`
  line-height: 1.5;
  max-width: 480px;
`;

export const RadioButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-left: -9px;
  position: relative;
`;

export const RadioButton = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 14px;
    margin-left: 8px;
  }
`;

export const otherReasonStyle = {
  width: '453px',
  position: 'absolute' as 'absolute',
  right: 2,
  bottom: '-38px',
};

export const reasonRequiredStyle = {
  color: colorValues.messyketchup,
  fontSize: 10,
  bottom: '-42px',
};

export const useButtonStyle = (color?: string) => ({
  backgroundColor: color || colorValues.cityblock,
  color: useBlackText(color ?? colorValues.cityblock)
    ? colorValues.betterptblack
    : colorValues.emptiness,
  fontWeight: 'bold' as 'bold',
  marginTop: '20px',
});
