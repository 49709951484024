import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

export const AppointmentsListContainer = styled.div<{ small?: boolean }>`
  margin: ${(props) => (props.small ? '0' : '31px 33px')};
  position: relative;
  width: 90%;
`;

export const IconButton = styled.button`
  background-color: transparent;
  border: none;
`;

export const NoAppointmentsFoundContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  height: 70vh;
  background-color: ${colorValues.earlysmoke};
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    margin: 25px auto;
    font-size: 20px;
  }
  .inner-container {
    max-width: 400px;
    text-align: center;
  }
  p {
    text-align: left;
  }
  svg {
    height: 137px;
    width: 137px;
  }
`;
