import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    width='161'
    height='161'
    viewBox='0 0 161 161'
  >
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <path
              d='M0 0H160V160H0z'
              transform='translate(-720 -202) translate(598 203) translate(122.5)'
            />
            <path
              fill='#333'
              d='M118.167 99.583L38 99.583 47 46 112.775 46z'
              transform='translate(-720 -202) translate(598 203) translate(122.5)'
            />
            <g>
              <path
                fill='#FF6B5A'
                d='M91 117.5V140H0v-22.5c0-20.97 14.19-38.63 33.5-43.89 1.31-.37 2.64-.67 4-.91 2.6-.46 5.27-.7 8-.7 3.44 0 6.78.38 10 1.1 1.36.31 2.69.67 4 1.1 6.93 2.23 13.14 6.09 18.17 11.13C85.91 93.56 91 104.94 91 117.5'
                transform='translate(-720 -202) translate(598 203) translate(122.5) translate(34 15)'
              />
              <path
                fill='#D2E9F8'
                d='M60 72.5v2.63c0 5.21-3.064 9.703-7.488 11.777-1.674.784-3.541-5.107-5.512-5.107-1.795 0-3.505 5.966-5.061 5.308C37.274 85.135 34 80.515 34 75.13v-3.22c1.31-.37 2.64-.67 4-.91l18 .399c1.36.311 2.69.67 4 1.101'
                transform='translate(-720 -202) translate(598 203) translate(122.5) translate(34 15)'
              />
              <path
                fill='#2B4F83'
                fillRule='nonzero'
                d='M55.876 72.396l-17.799-.394c-1.054.188-2.078.412-3.077.672v2.456c0 4.806 2.851 9.086 7.165 10.986.07-.058.16-.15.265-.276.304-.364.524-.709 1.226-1.876C45.083 81.589 45.735 80.8 47 80.8c1.288 0 2.02.79 3.525 3.032.78 1.162 1.012 1.487 1.341 1.836.133.14.244.235.323.285C56.315 83.972 59 79.794 59 75.13v-1.9c-.993-.305-2.028-.58-3.124-.834zm-3.652 13.577l.018.009-.008-.002-.01-.007zM61 72.5v2.63c0 5.486-3.184 10.395-8.064 12.682-.91.427-1.743.056-2.524-.771-.44-.466-.69-.815-1.548-2.094C47.845 83.429 47.263 82.8 47 82.8c-.161 0-.713.667-1.63 2.194-.769 1.28-1.005 1.649-1.405 2.128-.724.868-1.504 1.292-2.416.907C36.411 85.855 33 80.807 33 75.129v-3.22c0-.447.297-.84.728-.962 1.326-.374 2.687-.683 4.098-.932l.196-.015 18 .399.2.025c1.449.331 2.798.7 4.09 1.126.41.135.688.518.688.95z'
                transform='translate(-720 -202) translate(598 203) translate(122.5) translate(34 15)'
              />
              <path
                fill='#A96648'
                d='M56 60v15c0 4.97-4.03 9-9 9s-9-4.03-9-9V60h18z'
                transform='translate(-720 -202) translate(598 203) translate(122.5) translate(34 15)'
              />
              <path
                fill='#C37D5B'
                d='M79.5 36.5c0 9.39-3.8 17.89-9.96 24.04-6.15 6.16-14.65 9.96-24.04 9.96-18.78 0-34-15.22-34-34 0-.37.01-.73.02-1.1.12-3.92.91-7.66 2.24-11.13.18-.47.37-.93.57-1.38 1.71-3.92 4.14-7.45 7.13-10.43C27.61 6.3 36.11 2.5 45.5 2.5c7.73 0 14.85 2.57 20.55 6.92.07.04.13.09.2.16 1.16.88 2.26 1.85 3.29 2.88 2.99 2.98 5.42 6.51 7.13 10.43 1.29 2.97 2.18 6.15 2.58 9.49.12.99.2 2 .23 3.02.01.37.02.73.02 1.1'
                transform='translate(-720 -202) translate(598 203) translate(122.5) translate(34 15)'
              />
              <path
                fill='#333'
                d='M34 46.909c-1.104 0-2-.664-2-1.481v-2.592c0-.817.896-1.48 2-1.48s2 .663 2 1.48v2.592c0 .817-.896 1.48-2 1.48M56 46.909c-1.104 0-2-.664-2-1.481v-2.592c0-.817.896-1.48 2-1.48s2 .663 2 1.48v2.592c0 .817-.896 1.48-2 1.48M46.232 0c7.262 0 14.146 3.911 17.551 6.324l.094-.13c1.567 1.224 3.052 2.574 4.444 4.007 4.038 4.146 7.319 9.058 9.629 14.511 1.742 4.134 2.944 8.558 3.484 13.204-4.219-1.597-7.953-3.556-11.057-5.797H57.611l-4.37-14.647-3.348 14.647h-30.17c-3.103 2.24-6.837 4.2-11.056 5.797.54-4.646 1.742-9.07 3.485-13.203 2.309-5.454 5.59-10.366 9.629-14.512.64-.659 1.3-1.3 1.978-1.92.085-.29.167-.575.248-.857C30.17 2.757 37.87 0 46.232 0z'
                transform='translate(-720 -202) translate(598 203) translate(122.5) translate(34 15)'
              />
              <path
                fill='#D2E9F8'
                d='M48.75 103.5c0 1.242-1.008 2.25-2.25 2.25s-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25 2.25 1.008 2.25 2.25'
                transform='translate(-720 -202) translate(598 203) translate(122.5) translate(34 15)'
              />
              <path
                fill='#2B4F83'
                fillRule='nonzero'
                d='M47.75 103.5c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25 1.25.56 1.25 1.25 1.25 1.25-.56 1.25-1.25zm2 0c0 1.794-1.456 3.25-3.25 3.25s-3.25-1.456-3.25-3.25 1.456-3.25 3.25-3.25 3.25 1.456 3.25 3.25z'
                transform='translate(-720 -202) translate(598 203) translate(122.5) translate(34 15)'
              />
              <path
                fill='#D2E9F8'
                d='M48.75 118.25c0 1.242-1.008 2.25-2.25 2.25s-2.25-1.008-2.25-2.25S45.258 116 46.5 116s2.25 1.008 2.25 2.25'
                transform='translate(-720 -202) translate(598 203) translate(122.5) translate(34 15)'
              />
              <path
                fill='#2B4F83'
                fillRule='nonzero'
                d='M47.75 118.25c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25 1.25.56 1.25 1.25 1.25 1.25-.56 1.25-1.25zm2 0c0 1.794-1.456 3.25-3.25 3.25s-3.25-1.456-3.25-3.25S44.706 115 46.5 115s3.25 1.456 3.25 3.25z'
                transform='translate(-720 -202) translate(598 203) translate(122.5) translate(34 15)'
              />
              <path
                fill='#D2E9F8'
                d='M48.75 133c0 1.242-1.008 2.25-2.25 2.25s-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25 2.25 1.008 2.25 2.25'
                transform='translate(-720 -202) translate(598 203) translate(122.5) translate(34 15)'
              />
              <path
                fill='#2B4F83'
                fillRule='nonzero'
                d='M47.75 133c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25 1.25.56 1.25 1.25 1.25 1.25-.56 1.25-1.25zm2 0c0 1.794-1.456 3.25-3.25 3.25s-3.25-1.456-3.25-3.25 1.456-3.25 3.25-3.25 3.25 1.456 3.25 3.25z'
                transform='translate(-720 -202) translate(598 203) translate(122.5) translate(34 15)'
              />
              <path
                fill='#C37D5B'
                d='M20.666 42.834c0 1.932-.782 3.682-2.05 4.948-1.265 1.269-3.015 2.052-4.95 2.052-3.866 0-7-3.135-7-7 0-.077.002-.15.004-.228.026-.806.188-1.577.462-2.29.037-.098.077-.192.118-.285.351-.806.851-1.533 1.467-2.148 1.266-1.268 3.016-2.05 4.95-2.05 1.591 0 3.057.53 4.23 1.425.016.008.027.018.042.033.238.181.465.38.677.593.616.614 1.116 1.34 1.468 2.148.265.61.448 1.266.532 1.953.024.203.04.412.047.621.002.078.003.151.003.227M84 42.834c0 1.932-.782 3.682-2.051 4.948-1.265 1.269-3.015 2.052-4.949 2.052-3.866 0-7-3.135-7-7 0-.077.002-.15.004-.228.025-.806.187-1.577.461-2.29.037-.098.076-.192.118-.285.352-.806.852-1.533 1.468-2.148 1.265-1.268 3.015-2.05 4.949-2.05 1.592 0 3.058.53 4.23 1.425.015.008.028.018.042.033.239.181.465.38.677.593.616.614 1.116 1.34 1.468 2.148.266.61.449 1.266.531 1.953.026.203.041.412.048.621.002.078.004.151.004.227'
                transform='translate(-720 -202) translate(598 203) translate(122.5) translate(34 15)'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
