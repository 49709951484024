import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { colorValues } from '../helpers/colors';

const rotateAnimation = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(180deg)' },
});

export const rotateStyles = css`
  .rotationContainer svg {
    animation: ${rotateAnimation} 0.3s ease 1;
  }
`;

export const FilterContainer = styled.div<{ margin?: string }>`
  position: relative;
  margin: ${(props) => props?.margin ?? '12px 4px 0 0'};
`;

export const FilterButton = styled.button<{ open: boolean }>`
  height: 36px;
  border-radius: 23.5px;
  display: flex;
  text-align: left;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${colorValues.emptiness};
  &:hover {
    background-color: ${colorValues.dust};
  }
  box-shadow: ${(props) => {
    return props.open
      ? 'inset 0 0 10px 1px rgba(51,51,51,0.27), inset 0 2px 2px 0 rgba(51,51,51,0.24), inset 0px -1px 2px 0 rgba(51,51,51,0.24)'
      : '0 0 4px 0 rgba(51, 51, 51, 0.47), 0 2px 2px 0 rgba(51, 51, 51, 0.24)';
  }};
`;

export const FilterLabel = styled.div<{ padding?: any }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  margin: ${(props) => props?.padding ?? '0 0px 0 0px'};
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    color: ${colorValues.charcoalgray};
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
  }
`;

export const OptionsContainer = styled.div`
  max-height: 400px;
  overflow: auto;
  overflow-x: hidden;
`;

export const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 35px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RadioLabel = styled.p`
  font-size: 14px;
  font-weight: normal !important;
  margin: 0 !important;
  margin-left: 15px !important;
  padding: 12px 0 !important;
`;

export const radioStyle = css`
color: #333333 !important;
&:hover {
  background-color: rgba(51, 51, 51, 0.04) !important;
}
.MuiRadio-colorSecondary {
  color: #333333 !important;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: #333333 !important;
}
.MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: rgba(51, 51, 51, 0.04) !important;
}
.MuiIconButton-colorSecondary:hover {
  background-color: rgba(51, 51, 51, 0.04) !important;
}
}`;
