import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

export const FilterContainer = styled.div`
  position: relative;
  margin: 0px;
  display: flex;
  h2 {
    color: ${colorValues.cityblock};
  }
`;

export const Filters = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px 0px 24px;
`;

export const FilterSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const FilterRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
