import React from 'react';
import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';
import logosAndColorsMap, { determineConfigKey } from '../../helpers/logosAndColors';
import { Company, useMeQuery } from '../../generated/graphql';

const Logo = styled.div<{
  activePath: boolean;
  isMainNavOpen: boolean;
  shouldDisplayCompanyLogo: boolean;
  queryIsLoading: boolean;
}>`
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${colorValues.concretejungle};
  background-color: ${({ activePath }) => (activePath && 'red') || ''};
  img {
    border-radius: ${({ shouldDisplayCompanyLogo }) => (shouldDisplayCompanyLogo ? '50%' : 'initial')};
    visibility: ${({ queryIsLoading }) => (queryIsLoading ? 'visible' : 'hidden')};
  }
`;

const NavLogoSection = ({ isMainNavOpen }: { isMainNavOpen: boolean }) => {
  const { loading, data } = useMeQuery();
  const companyAvatarUrl = data?.me?.company?.avatarUrl ?? data?.me?.company?.smallAvatarUrl;

  const configKey = determineConfigKey(data?.me?.company as Company);
  const isPartnerApp = configKey === 'acorn' || configKey === 'somatus';
  const shouldDisplayCompanyLogo = !isPartnerApp && !!companyAvatarUrl;

  const organizationLogoUrl = shouldDisplayCompanyLogo ? companyAvatarUrl : logosAndColorsMap.get(configKey)?.organizationUrl;
  const organizationSmallLogoUrl = shouldDisplayCompanyLogo ? companyAvatarUrl : logosAndColorsMap.get(configKey)?.organizationSmallUrl;
  const usesCompanyLogo = organizationLogoUrl.includes('amazon');

  const ImageSmall = styled.img`
    display: ${isMainNavOpen ? 'none' : 'block'};
    width: 70%;
  `;

  const ImageLarge = styled.img`
    display: ${isMainNavOpen ? 'block' : 'none'};
    width: ${usesCompanyLogo ? '50%' : '70%'};
    height: 100%;
  `;

  return (
    <Logo
      id="logo-section-link"
      activePath={false}
      isMainNavOpen={isMainNavOpen}
      shouldDisplayCompanyLogo={shouldDisplayCompanyLogo}
      queryIsLoading={!loading}
    >
      <ImageLarge src={organizationLogoUrl} />
      <ImageSmall src={organizationSmallLogoUrl} />
    </Logo>
  );
};

export default NavLogoSection;
