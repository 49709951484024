import * as React from 'react';

const useCodeState = () => {
  const [code, updateCode] = React.useState('');
  const [error, updateError] = React.useState<string>();
  const [touched, updateTouched] = React.useState(false);

  React.useEffect(() => {
    if (code.length > 0) {
      updateTouched(true);
    }
    if (touched && code.length < 1) {
      updateError('Required');
    } else {
      updateError('');
    }
  }, [code, touched]);

  return { value: code, updateValue: updateCode, error, touched };
};

export default useCodeState;
