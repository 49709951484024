import React from 'react';

//components
import { Dialog } from '@material-ui/core';
import QRCode from 'qrcode.react';
import ThemedTextField from '../../../Shared/ThemedTextField';
import { Button, Animation } from '@betterpt/better-components';
import { IcClose } from '@betterpt/better-icons';

//hooks
import { UseMFAReturnType } from '../hooks/useMfa';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';

//styles
import {
  DialogBody,
  QRBox,
  dialogButtonStyle,
} from '../styles/MFADialogs.style';
import { colorValues } from '@betterpt/better-components';

type Props = {
  open: boolean;
  onClose: () => void;
  mfa: UseMFAReturnType;
};

const EnabeMFADialog = ({ open, onClose, mfa }: Props) => {
  const { primaryColor } = useRemoteConfigAliases();

  const [code, setCode] = React.useState('');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { width: 582 },
      }}
    >
      {(mfa.loading && <Animation type='providerAppLoader' />) || (
        <div data-testid='dialog-mfa'>
          <IcClose
            color={colorValues.cityblock}
            onClick={onClose}
            style={{
              position: 'absolute',
              top: 20,
              right: 20,
              cursor: 'pointer',
            }}
          />
          <DialogBody>
            <h2>Turn on Two-Factor Authentication</h2>
            <p>
              Scan the image below with Authy or Google Authenticator, which can
              be downloaded onto your phone through its app store. <br /> <br />
              You will be prompted with a six-digit code, which you should enter
              on the line just below the image:
            </p>
            <QRBox>
              <QRCode value={mfa.codeUrl} bgColor='transparent' size={150} />
              <ThemedTextField
                id='code'
                style={{ marginTop: 10 }}
                placeholder='Enter your two-factor code here'
                value={code}
                onChange={(e: any) => setCode(e?.target?.value)}
                fullWidth
              />
            </QRBox>
            <Button
              style={dialogButtonStyle(primaryColor)}
              onClick={() => mfa.enableMfa(code)}
              disabled={!code}
              fullWidth
            >
              CONTINUE
            </Button>
          </DialogBody>
        </div>
      )}
    </Dialog>
  );
};

export default EnabeMFADialog;
