import * as React from "react";

//types
import {
  Role,
  NewAppointmentStatus,
} from "../../../../../../generated/graphql";
import { InPersonAppointmentFiltersContextType } from "../../../../../../context/inPersonAppointmentFilters";

//hooks
import { useMeQuery } from "../../../../../../generated/graphql";
import useRemoteConfigAliases from "../../../../../../hooks/useRemoteConfigAliases";

//components
import { Filter, FilterOption } from "@betterpt/better-components";

//helpers
import { pluralNoun } from "../../../../../../helpers";

const AppointmentTypeFilter: React.FC<React.PropsWithChildren<{
  filters: InPersonAppointmentFiltersContextType;
  primaryColor?: string;
}>> = ({ filters, primaryColor }) => {
  const meQuery = useMeQuery();
  const isBasic = meQuery.data?.me?.role === Role.Self;
  const basicAndNotIntegrated =
    isBasic &&
    !meQuery.data?.me?.clinics?.some((clinic) => clinic?.isIntegrated);
  const { facilitiesSingularAlias } = useRemoteConfigAliases();
  const company = meQuery.data?.me?.company;
  const isNotWhiteLabel =
    company?.providerAppId !== "acorn" && company?.providerAppId !== "somatus";

  const handleSubmitAppointmentStatuses = (selected: FilterOption[]) => {
    const appointmentStatuses = selected?.length
      ? selected?.map((option) => option?.value ?? "0")
      : [];

    filters.appointmentStatuses.update(appointmentStatuses);
  };

  const apptTypeFilterOptions = [
    {
      label: "All",
      isResetOption: true,
      selected: !filters.appointmentStatuses.value?.length,
    },
    {
      label: "Upcoming appointments",
      fullTitle: "upcoming",
      hidden: !isNotWhiteLabel || basicAndNotIntegrated,
      value: NewAppointmentStatus.Upcoming,
      selected: filters.appointmentStatuses.value.includes(
        NewAppointmentStatus.Upcoming
      ),
    },
    {
      label: "Requests",
      fullTitle: "requests",
      hidden: !isNotWhiteLabel,
      value: NewAppointmentStatus.Requested,
      selected: filters.appointmentStatuses.value.includes(
        NewAppointmentStatus.Requested
      ),
    },
    {
      label: "Pending patient text confirmation",
      fullTitle: "pending",
      value: NewAppointmentStatus.PendingTextConfirmation,
      selected: filters.appointmentStatuses.value.includes(
        NewAppointmentStatus.PendingTextConfirmation
      ),
    },
    {
      label: "Past appointments",
      fullTitle: "past",
      value: NewAppointmentStatus.Completed,
      selected: filters.appointmentStatuses.value.includes(
        NewAppointmentStatus.Completed
      ),
    },
    {
      label: "Cancelled appointments",
      fullTitle: "cancelled",
      value: NewAppointmentStatus.Cancelled,
      selected: filters.appointmentStatuses.value.includes(
        NewAppointmentStatus.Cancelled
      ),
    },
    {
      label: "Declined by provider",
      fullTitle: "declined",
      hidden: !isNotWhiteLabel,
      value: NewAppointmentStatus.Declined,
      selected: filters.appointmentStatuses.value.includes(
        NewAppointmentStatus.Declined
      ),
    },
    {
      label: "Expired requests",
      fullTitle: "expired",
      hidden: !isNotWhiteLabel,
      value: NewAppointmentStatus.Expired,
      selected: filters.appointmentStatuses.value.includes(
        NewAppointmentStatus.Expired
      ),
    },
    {
      label: "No shows",
      value: NewAppointmentStatus.NoShow,
      selected: filters.appointmentStatuses.value.includes(
        NewAppointmentStatus.NoShow
      ),
    },
    {
      label: `Confirmed by ${facilitiesSingularAlias}`,
      hidden: true,
    },
  ];

  const handleCustomButtonTitle = (selected: FilterOption[]) => {
    const bestCategoryName =
      selected.length > 2 || !selected.length ? pluralNoun("status") : "status";
    const optionName = !!selected.length
      ? selected[0]?.fullTitle ?? selected[0]?.label
      : `all ${bestCategoryName}`;
    return selected.length > 1
      ? `${optionName} +${selected.length - 1} other ${bestCategoryName}`
      : `${optionName}`;
  };

  return (
    <Filter
      type="check"
      name="status"
      sort={false}
      boxTitle="appointment status"
      customButtonTitle={handleCustomButtonTitle}
      options={apptTypeFilterOptions}
      overrideButtonColor={primaryColor}
      onSubmit={handleSubmitAppointmentStatuses}
      style={{
        buttonMargin: "12px 4px 0 0",
        box: { width: "325px" },
        button: { width: "300px" },
      }}
    />
  );
};

export default AppointmentTypeFilter;
