/* refactored 3/12/2021 */
import React from 'react';

//types
import { UpdateProviderDetailsInput } from '../hooks/useProfileOperations';

//helpers
import {
  normalizeMonthYearDate,
  validateExperience,
  validateBio,
} from '../../../../helpers';
import { format, parse } from 'date-fns';

//hooks
import { useMeQuery } from '../../../../generated/graphql';
import useSnackbar from '../../../../hooks/useSnackbar';
import useFeatureAccessCheck from '../../../../hooks/useFeatureAccessCheck';
import useTextFieldState from '../../../../hooks/useTextFieldState';
import useBoolFieldState from '../../../../hooks/useBoolFieldState';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import useHandleError from '../../../../hooks/useHandleError';

//components
import { Header, Button, Switch } from '@betterpt/better-components';
import { GridCard } from '../../../Shared/GridCard';
import FormField from '../../../Shared/FormField';
import ThemedTextField from '../../../Shared/ThemedTextField';

//style
import {
  logoutButtonStyle,
  H4,
  FormBody,
  multilineInputStyle,
} from '../styles/Profile.style';

interface Props {
  updateProviderDetails: (
    input: UpdateProviderDetailsInput
  ) => Promise<boolean>;
}

const ProviderDetails = ({ updateProviderDetails }: Props) => {
  const snackbar = useSnackbar();
  const meQuery = useMeQuery();
  const { primaryColor } = useRemoteConfigAliases();
  const { isNotWhiteLabel } = useFeatureAccessCheck();
  const handleError = useHandleError();

  const [saving, updateSaving] = React.useState(false);
  const [isEditModeActive, setEditModeActive] = React.useState(false);
  const bioFormState = useTextFieldState({
    validation: validateBio,
  });
  const isTherapistFormState = useBoolFieldState();
  const degreeOrCertificationFormState = useTextFieldState();
  const yearsExperienceFormState = useTextFieldState({
    normalizer: normalizeMonthYearDate,
    validation: validateExperience,
  });

  const shouldNewEmployeesDefaultToProvider =
    meQuery?.data?.me?.company?.shouldNewEmployeesDefaultToProvider;
  const showFullProfileFields =
    (isTherapistFormState.value || shouldNewEmployeesDefaultToProvider) &&
    isNotWhiteLabel;

  React.useEffect(() => {
    const me = meQuery.data?.me;
    if (me) {
      isTherapistFormState.updateValue(me?.isTherapist ?? false);
      bioFormState.updateValue(me?.bio ?? '');
      degreeOrCertificationFormState.updateValue(
        me?.degreeOrCertification ?? ''
      );
      const formattedYearsExperience = me?.yearsExperience
        ? format(
            parse(me?.yearsExperience, 'yyyy-MM-dd', new Date()),
            'MM/yyyy'
          )
        : '';
      yearsExperienceFormState.updateValue(formattedYearsExperience);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meQuery.data]);

  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateSaving(true);
    try {
      const savedNewChanges = await updateProviderDetails({
        bioFormState,
        degreeOrCertificationFormState,
        yearsExperienceFormState,
        isTherapistFormState,
      });
      if (savedNewChanges)
        snackbar?.setSuccessMessage('Information updated successfully');
      setEditModeActive(false);
    } catch (e) {
      handleError(e);
    }
    updateSaving(false);
  };

  return (
    <GridCard>
      <form onSubmit={handleSubmit}>
        <Header underline style={{ padding: '22px 23px 13px 42px' }}>
          <H4>Provider Details</H4>
          {isEditModeActive ? (
            <Button
              disabled={saving}
              size='small'
              color='transparent'
              type='submit'
              style={logoutButtonStyle(primaryColor, true)}
              loading={saving}
            >
              SAVE
            </Button>
          ) : (
            <Button
              size='small'
              color='transparent'
              style={logoutButtonStyle(primaryColor)}
              onClick={() => setEditModeActive(true)}
            >
              EDIT
            </Button>
          )}
        </Header>
        <FormBody>
          {!shouldNewEmployeesDefaultToProvider && (
            <ThemedTextField
              disabled
              label='Provider Status'
              value={`Is${
                isTherapistFormState.value ? '' : ' not'
              } a care provider`}
              fullWidth
              InputProps={{
                endAdornment: (
                  <Switch
                    name='providerStatus'
                    value={isTherapistFormState.value}
                    checked={!!isTherapistFormState.value}
                    onChange={() =>
                      isTherapistFormState.updateValue(
                        !isTherapistFormState.value
                      )
                    }
                    disabled={!isEditModeActive}
                    inputProps={{
                      ['data-testid' as any]: `is-provider-toggle`,
                    }}
                  />
                ),
              }}
            />
          )}
          {showFullProfileFields && (
            <>
              <FormField
                label='Degree/Certification'
                fullWidth
                disabled={!isEditModeActive || saving}
                placeholder='Enter your degree or certification'
                formHandler={degreeOrCertificationFormState}
                inputProps={{
                  maxLength: 255,
                }}
              />
              <FormField
                disabled={!isEditModeActive || saving}
                fullWidth
                multiline
                label='Bio'
                placeholder='Enter your bio'
                formHandler={bioFormState}
                helperText={
                  isEditModeActive && !!bioFormState.value
                    ? `${bioFormState.value?.length}/1000`
                    : ''
                }
                error={isEditModeActive && !!bioFormState.error?.length}
                inputProps={{
                  style: multilineInputStyle,
                }}
              />
              <FormField
                disabled={!isEditModeActive || saving}
                fullWidth
                label='Practicing Since'
                placeholder='Enter the date you started practicing (MM/YYYY)'
                formHandler={yearsExperienceFormState}
              />
            </>
          )}
        </FormBody>
      </form>
    </GridCard>
  );
};

export default ProviderDetails;
