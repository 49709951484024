import * as React from 'react';

//hooks
import { useHistory, useRouteMatch } from 'react-router-dom';
import useRemoteConfigAliases from '../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../hooks/useSnackbar';

//components
import { Button } from '@betterpt/better-components';

//assets
import { IcArrowRight, IcArrowLeft } from '@betterpt/better-icons';

//styles
import { colorValues, bestTextColor, Animation } from '@betterpt/better-components';
import {
  Navbar,
  BodySection,
  Numbers,
  numberStyle,
  nextbuttonStyle,
  lowerNextButtonStyle,
  previousButtonStyle,
} from './OnboardingWrapper.style';
import { Circle } from '../Welcome/Welcome.style';
import { Role, useMeQuery } from '../../../generated/graphql';
import useFeatureFlagWrapper from 'apps/provider-app/src/hooks/useFeatureFlagWrapper';
import Flags from 'apps/provider-app/src/constants/flags';

type Props = {
  form?: string;
  disableButton?: boolean | null;
  saving?: boolean;
  goToPreviousStep?: () => void;
  buttonText?: string;
};

const OnboardingWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  form,
  disableButton,
  saving,
  goToPreviousStep,
  buttonText,
}) => {
  const snackbar = useSnackbar();
  const history = useHistory();
  const match = useRouteMatch();
  const featureFlag = useFeatureFlagWrapper();
  const { primaryColor } = useRemoteConfigAliases();
  const meQuery = useMeQuery({
    onError: () => snackbar?.useGenericErrorMessage(),
  });

  const disableBilling = featureFlag(true, Flags.DisableBillingForCompanySetup, false);

  React.useEffect(() => {
    if (meQuery.data) {
      // If the user tries to access onboarding, but they are not an owner, send them to the homescreen
      if (meQuery.data.me?.role !== Role.Owner) {
        history.push('/');
      }
    }
  }, [history, meQuery.data]);

  const NumberText: React.FC<React.PropsWithChildren<any>> = (props) => (
    <h2 style={{ color: bestTextColor(primaryColor) }} className="H5" {...props}>
      {props.children}
    </h2>
  );

  if (meQuery.loading) {
    return <Animation type="providerAppLoader" />;
  }

  return (
    <div>
      <Navbar color={primaryColor}>
        <h1 className="Hero" style={{ marginLeft: '2em' }}>
          Company Setup
        </h1>
      </Navbar>
      <BodySection>
        <Numbers>
          {goToPreviousStep && (
            <Button color="transparent" style={previousButtonStyle} form={form} onClick={goToPreviousStep} disabled={saving} type="button">
              <IcArrowLeft color={saving ? colorValues.emptiness : colorValues.cityblock} /> GO BACK
            </Button>
          )}
          <Circle style={numberStyle(match.path.includes('company'))} color={primaryColor}>
            <NumberText>1</NumberText>
          </Circle>
          <Circle style={numberStyle(match.path.includes('profile'))} color={primaryColor}>
            <NumberText>2</NumberText>
          </Circle>
          {!disableBilling && (
            <Circle style={numberStyle(match.path.includes('billing'))} color={primaryColor}>
              <NumberText>3</NumberText>
            </Circle>
          )}
          <Circle style={numberStyle(match.path.includes('administrator'))} color={primaryColor}>
            <NumberText>{disableBilling ? 3 : 4}</NumberText>
          </Circle>

          <Button
            color="transparent"
            style={nextbuttonStyle(primaryColor)}
            size="medium"
            type="submit"
            form={form}
            loading={saving}
            disabled={!!disableButton}
          >
            {buttonText || 'NEXT'}
          </Button>
        </Numbers>
        {children}
      </BodySection>
    </div>
  );
};

export default OnboardingWrapper;
