import { isValid, isAfter, isBefore, parse } from 'date-fns';
// password check functions
const checkCapital = (string: string) => {
  if (string) {
    let hasCap = false;
    const checkArray = string
      .split('')
      .filter(
        (char) =>
          !'[~`!#$%^&*+=-_[]\';.,/{}()|"\\:<>?]'.includes(char) &&
          isNaN(parseInt(char, 0)) &&
          char === char.toUpperCase()
      );
    hasCap = checkArray.length > 0;
    return hasCap;
  }
  return false;
};

const checkLower = (string: string) => {
  if (string) {
    let hasLow = false;
    const checkArray = string
      .split('')
      .filter(
        (char) =>
          !'[~`!#$@%^&*+=-_[]\';.,/{}()|"\\:<>?]'.includes(char) &&
          isNaN(parseInt(char, 0)) &&
          char === char.toLowerCase()
      );
    hasLow = checkArray.length > 0;
    return hasLow;
  }
  return false;
};

const checkNumber = (string: string) => {
  if (string) {
    let hasNum = false;
    const checkArray = string
      .split('')
      .filter((char: string) => !isNaN(parseInt(char, 0)));
    hasNum = checkArray.length > 0;
    return hasNum;
  }
  return false;
};


const checkSpaces = (string: string) => {
    return /^\s*$/.test(string);
};

const checkSpecialChar = (string: string) => {
  if (string) {
    const str = string.toLowerCase();
    return /[~`!#$%^&*+=\-_[\]\\';.,/{}()|@":<>?]/g.test(str);
  }
  return false;
};

// Return array of errors on password validation
export const passwordValidation = (value: string) => {
  const errors = value ? ['Make sure your password has:'] : [];
  if (value && value.length < 8) {
    errors.push('- 8 characters minimum');
  }
  if (value && !checkCapital(value)) {
    errors.push('- At least one uppercase character');
  }

  if (value && !checkLower(value)) {
    errors.push('- At least one lowercase character');
  }
  if (value && !checkNumber(value)) {
    errors.push('- At least one number');
  }
  if (value && !checkSpecialChar(value)) {
    errors.push('- At least one special character (!@#$, etc.)');
  }

  return errors && errors.length > 1 ? errors : [];
};

export const emailValidation = (value: string, required = true) => {
  let error;
  if (!value && required) {
    error = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)) {
    error = 'Invalid email address';
  } else if (
    /[~`!#$%^&*+=\-_[\]\\';.,/{}()|\\":<>?]/g.test(
      value.split('@')[0][value.split('@')[0].length - 1]
    )
  ) {
    error = 'Invalid email address';
  }
  return error;
};

export const nameValidation = (value?: string,  required = true) => {
  let error;
  if (!value && required) {
    error = 'Required'
  }
  else if (value && checkSpaces(value)) {
    error = 'Cannot have spaces';
  }
  return error;
};

export const phoneValidation = (value?: string) => {
  const length = value?.toString()?.replace(/\D/g, '').length;
  if (!!length && length !== 10) {
    return 'Invalid phone number';
  }
};

export const einValidation = (value: string) => {
  let error;
  if (value && value.length !== 9) {
    error = 'Your EIN must be 9 digits';
  }

  return error;
};

export const urlValidation = (value: string) => {
  let error;
  if (!value) {
    return false;
  } else if (
    // eslint-disable-next-line no-useless-escape
    !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm.test(
      value
    ) &&
    value &&
    !(value.length === 0)
  ) {
    error = 'Invalid URL';
  }
  return error;
};

export const validateBio = (value: string) => {
  if (!value) return undefined;
  if (value.length > 1000) return 'Max 1000 characters';
};

export const validateExperience = (value: string) => {
  if (!value) {
    return undefined;
  }
  const dateArr = value.split('/');
  if (dateArr.length === 1) {
    return 'invalid date';
  }
  const today = new Date();
  if (
    (dateArr.length === 2 && dateArr[1].length !== 4) ||
    (dateArr.length === 2 && +dateArr[1] < 1900) ||
    +dateArr[0] > 12 ||
    +dateArr[1] > today.getFullYear() ||
    (+dateArr[1] === today.getFullYear() &&
      +dateArr[0] > today.getMonth() + 1) ||
    +dateArr[0] === 0
  ) {
    return 'invalid date';
  }
};

export const validateBirthday = (date: string) => {
  const parsedDate = parse(date, 'MM/dd/yyyy', new Date());
  if (date.length === 0 || !isValid(parsedDate)) {
    return 'Please enter a valid date.';
  } else if (isAfter(parsedDate, new Date())) {
    return 'Date of birth must be in the past.';
  } else if (isBefore(parsedDate, new Date('01/01/1900'))) {
    return 'Please enter a valid date.';
  } else {
    return null;
  }
};

export const validateTimePeriod = (type: 'duration' | 'interval') => (
  value: string
) => {
  let error;
  const totalMinutes = parseInt(value);
  if (!!(totalMinutes % 5)) {
    error = `${type.charAt(0).toUpperCase() +
      type.slice(1)} must be set in increments of five minutes`;
  } else if (totalMinutes < 5) {
    error = 'Required';
  } else if (totalMinutes > 300) {
    error = `Max ${type} is 300 minutes`;
  }
  return error;
};
