import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    width='138'
    height='138'
    viewBox='0 0 138 138'
  >
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g transform='translate(-730 -272) translate(597 272) translate(133.5)'>
            <g transform='translate(14.556 .856)'>
              <path fill='#FFCC46' d='M22.262 42.813H76.206V98.469H22.262z' />
              <path
                fill='#A4BBD9'
                d='M90.763 136.566H20.55v-33.694c0-18.015 10.949-33.186 25.847-37.704 1.011-.318 2.037-.576 3.087-.782 2.006-.395 4.066-.602 6.172-.602 2.654 0 5.231.327 7.716.945 1.05.267 2.075.576 3.086.945 5.347 1.916 10.139 5.232 14.02 9.562 6.357 7.07 10.285 16.846 10.285 27.636v33.694z'
              />
              <path
                fill='#FFF'
                d='M64.219 59.938v7.92c0 4.61-4.026 8.348-8.99 8.348-4.966 0-8.992-3.738-8.992-8.348v-7.92H64.22z'
              />
              <path
                fill='#DE8E68'
                d='M16.438 125.183c0 1.654-.91 3.153-2.383 4.237-1.47 1.086-3.505 1.756-5.752 1.756-4.493 0-8.133-2.683-8.133-5.993 0-.066.001-.13.004-.195.034-.783.27-1.526.672-2.205.409-.69.99-1.313 1.706-1.838 1.472-1.087 3.506-1.757 5.751-1.757 2.247 0 4.281.67 5.752 1.757.716.525 1.297 1.148 1.706 1.838.401.68.639 1.422.673 2.205.001.066.004.129.004.195M102.079 130.03c-1.472.895-3.964.976-6.211.976-4.493 0-7.674-1.742-7.674-4.473 0-.054.003-.107.006-.16.034-.646.27-1.26.672-1.82.408-.57.99-1.083 1.705-1.516 1.472-.897 3.504-1.45 5.751-1.45 2.247 0 4.28.553 5.751 1.45.715.433 1.297.947 1.707 1.516.4.56.638 1.174.672 1.82.001.053.005.106.005.16 0 1.365-.91 2.601-2.384 3.496z'
              />
              <path
                fill='#CB7E5C'
                d='M62.506 54.8v11.773c0 3.901-3.259 7.065-7.278 7.065s-7.278-3.164-7.278-7.065V54.8h14.556z'
              />
              <path
                fill='#DE8E68'
                d='M74.857 36.556c2.319 7.076 1.555 14.42-1.569 20.576-3.112 6.161-8.58 11.125-15.656 13.444-14.152 4.64-29.38-3.072-34.02-17.224-.09-.28-.172-.553-.255-.835-.878-2.983-1.207-5.997-1.061-8.94.019-.398.048-.793.088-1.181.32-3.376 1.28-6.636 2.797-9.621 3.113-6.162 8.58-11.124 15.656-13.443 5.825-1.91 11.825-1.731 17.195.139.063.012.121.035.19.07 1.091.378 2.16.837 3.191 1.359 2.99 1.506 5.693 3.567 7.949 6.099 1.706 1.919 3.162 4.096 4.289 6.514.334.716.644 1.458.919 2.219.099.277.196.545.287.824'
              />
              <path
                fill='#DE8E68'
                d='M80.252 46.056c.405 1.235.271 2.518-.274 3.592-.543 1.076-1.498 1.943-2.734 2.348-2.47.81-5.13-.537-5.94-3.008l-.044-.145c-.153-.521-.21-1.047-.185-1.561.003-.07.008-.139.015-.207.056-.59.223-1.158.488-1.68.544-1.075 1.498-1.942 2.734-2.347 1.017-.333 2.065-.302 3.002.025.011.002.021.006.033.012.191.066.378.146.558.237.521.263.994.623 1.387 1.065.298.335.553.715.75 1.137.058.125.112.255.16.388l.05.144'
              />
              <circle cx='77.919' cy='52.231' r='2.569' fill='#D9E8FF' />
              <path
                fill='#DE8E68'
                d='M28.877 49.48c.405 1.236.271 2.519-.274 3.593-.543 1.076-1.498 1.943-2.734 2.348-2.47.81-5.13-.537-5.94-3.008l-.044-.145c-.153-.521-.21-1.047-.185-1.561.003-.07.008-.139.015-.207.056-.59.223-1.158.488-1.68.544-1.075 1.498-1.942 2.734-2.347 1.017-.333 2.065-.302 3.002.025.011.002.021.006.033.012.191.066.378.146.558.237.521.263.994.623 1.387 1.065.298.335.553.715.75 1.137.058.125.112.255.16.388l.05.144'
              />
              <circle cx='22.691' cy='56.084' r='2.141' fill='#D9E8FF' />
              <circle cx='78.347' cy='53.516' r='1.284' fill='#FFF' />
              <circle cx='23.547' cy='56.941' r='1.284' fill='#FFF' />
              <path
                fill='#2A4E82'
                d='M37.218 56.883c-.882.281-1.833-.187-2.122-1.046l-.915-2.719c-.29-.859.191-1.784 1.075-2.065.882-.282 1.833.186 2.122 1.046l.916 2.718c.289.859-.192 1.784-1.076 2.066'
                transform='rotate(15 36.237 53.968)'
              />
              <path
                fill='#2A4E82'
                d='M55.2 56.883c-.883.281-1.834-.187-2.122-1.047l-.916-2.717c-.29-.86.191-1.784 1.075-2.067.883-.28 1.834.187 2.122 1.047l.916 2.718c.29.859-.192 1.784-1.075 2.066'
                transform='rotate(15 54.218 53.968)'
              />
              <path
                fill='#FFCC46'
                d='M82.2 43.669c-10.987-.404-19.414-4.242-21.677-10.874-.317-.933-.507-1.895-.563-2.877-.091-1.454.097-2.94.548-4.44.053-.22.12-.431.196-.647 1.519.51 3.007 1.132 4.44 1.837 4.162 2.036 7.926 4.815 11.072 8.228 2.377 2.586 4.41 5.517 5.984 8.773'
                transform='rotate(17 71.069 34.25)'
              />
              <path
                fill='#FFCC46'
                d='M23.159 53.087c6.702-5.899 9.98-13.189 7.904-19.537-.292-.893-.681-1.743-1.164-2.542-.71-1.185-1.613-2.254-2.689-3.201-.148-.145-.302-.278-.465-.407-.686 1.172-1.293 2.414-1.825 3.696-1.543 3.71-2.446 7.802-2.625 12.074-.132 3.236.137 6.566.864 9.917'
                transform='rotate(5 26.972 40.244)'
              />
              <path
                fill='#FFCC46'
                d='M60.417 17.23c.022.218.045.437.055.65.075 1.274-.069 2.575-.415 3.889l.001.008c-2.079 7.968-11.41 16.39-24.047 20.804-4.948 1.73-9.83 2.646-14.303 2.8.019-.469.049-.933.09-1.39.325-3.977 1.348-7.81 2.978-11.316 3.345-7.237 9.258-13.047 16.94-15.73 6.322-2.208 12.849-1.957 18.701.284'
              />
              <circle cx='61.65' cy='15.412' r='15.412' fill='#FFCC46' />
              <path
                fill='#FEFEFE'
                d='M72.858 29.113c-.249 0-.495-.12-.645-.343-8.985-13.279-22.27-13.432-27.943-13.496l-.691-.01c-.43-.007-.773-.364-.767-.795.008-.432.395-.77.793-.769l.683.01c5.408.061 19.773.226 29.214 14.18.243.36.15.845-.206 1.088-.134.09-.287.134-.438.134'
                transform='rotate(6 58.225 21.406)'
              />
            </g>
            <g>
              <path
                fill='#F9F9FA'
                d='M1.712 50.519H101.893V53.943999999999996H1.712z'
                transform='translate(17.125 82.2)'
              />
              <path
                fill='#294F83'
                d='M1.487 50.996v1.822c.002.3.6.649 1.341.649h97.094c.74 0 1.34-.349 1.34-.638v-1.833H1.488zM99.922 54.8H2.828c-1.58 0-2.822-.862-2.828-1.964v-3.173h102.75v3.155c-.007 1.12-1.25 1.982-2.828 1.982z'
                transform='translate(17.125 82.2)'
              />
              <path
                fill='#A3BBD8'
                d='M58.225 47.094c-.187 1.474-.867 2.569-1.676 2.569H46.202c-.81 0-1.49-1.095-1.677-2.57h13.7z'
                transform='translate(17.125 82.2)'
              />
              <path
                fill='#294F83'
                d='M45.56 47.953c.258.519.711.85 1.215.85h10.913c.503 0 .957-.331 1.215-.85H45.56zm12.128 1.71H46.775c-1.021 0-1.903-.841-2.144-2.046l-.106-.523h15.413l-.106.523c-.243 1.205-1.124 2.046-2.144 2.046z'
                transform='translate(17.125 82.2)'
              />
              <path
                fill='#E5E5E4'
                d='M101.522 53.087H4.62c-1.13 0-2.051-.967-2.051-2.156 0-.216.166-.412.372-.412s.372.153.372.37v.042c0 .757.586 1.374 1.307 1.374h96.902c.205 0 .372.175.372.391 0 .216-.167.392-.372.392'
                transform='translate(17.125 82.2)'
              />
              <path
                fill='#F9F9FA'
                d='M89.324 53.087H13.426c-1.267 0-2.295-1.002-2.295-2.239V4.763c0-2.158 1.792-3.907 4.004-3.907h72.48c2.212 0 4.004 1.75 4.004 3.907v46.085c0 1.237-1.027 2.24-2.295 2.24'
                transform='translate(17.125 82.2)'
              />
              <path
                fill='#294F83'
                d='M14.989 1.487c-1.782 0-3.233 1.455-3.233 3.244v47.04c0 .85.69 1.542 1.536 1.542h75.31c.847 0 1.535-.692 1.535-1.542V4.73c0-1.789-1.449-3.244-3.231-3.244H14.989zM88.6 54.8H13.292c-1.663 0-3.017-1.36-3.017-3.03V4.732C10.275 2.122 12.39 0 14.989 0h71.917c2.599 0 4.713 2.122 4.713 4.731v47.04c0 1.67-1.354 3.029-3.018 3.029z'
                transform='translate(17.125 82.2)'
              />
              <path
                fill='#E5E5E4'
                d='M84.88 52.231H16.671c-2.582 0-4.683-2.09-4.683-4.661V6.735c0-.41.333-.741.744-.741.412 0 .745.331.745.74V47.57c0 1.753 1.433 3.179 3.194 3.179h68.21c.411 0 .744.331.744.741s-.333.741-.745.741'
                transform='translate(17.125 82.2)'
              />
              <path
                fill='#E5E5E4'
                d='M57.369 27.4c0 2.837-2.492 5.138-5.566 5.138s-5.566-2.3-5.566-5.138c0-2.837 2.492-5.137 5.566-5.137s5.566 2.3 5.566 5.137'
                transform='translate(17.125 82.2)'
              />
            </g>
            <circle cx='68.346' cy='55.252' r='1' fill='#FFF' />
            <circle cx='50.365' cy='55.252' r='1' fill='#FFF' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
