import React from "react";

// components
import { colorValues, InfoBox } from "@betterpt/better-components";

//assets
import { IcSuccessCircle } from "@betterpt/better-icons";

const ContactedPatientInfoBox = (
  patientContactedAt?: string | null,
  contactedEmployeeFullName?: string | null
) => (
  <InfoBox
    openWith="hover"
    height={120}
    width={200}
    boxStyle={{ zIndex: 5 }}
    padding={0}
    iconHeight={24}
    iconWidth={24}
    position={"bottom"}
    mainStyle={{ left: "-25px", top: "-2px" }}
    fixedPlacement
    icon={
      <div
        data-private
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IcSuccessCircle
          color={colorValues.fresco}
          aria-label="Patient Contacted"
        />
      </div>
    }
  >
    <div>
      <h3 className="H6">CONTACTED</h3>
      <p className="Body" style={{ fontSize: "14px" }}>
        Patient marked contacted by {contactedEmployeeFullName} (
        {patientContactedAt})
      </p>
    </div>
  </InfoBox>
);

export default ContactedPatientInfoBox;
