import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { colorValues } from '../../helpers/colors';

export const cellStyle: CSSProperties = {
  color: colorValues.charcoalgray,
  fontWeight: 'bold',
  zIndex: 100,
  paddingLeft: '10px',
  fontSize: '13px!important'
};
