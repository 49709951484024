import React from 'react';

// types
import { JSONSchema7 } from 'json-schema';
import { ISubmitEvent } from '@rjsf/core';
import {
  MappedAppointmentTypeDetail,
  UpdateAppointmentQuestionsPayload,
} from '../../hooks/useAppointmentTypeDetailOperations';

// schema
import providerCreateFormSchema from '../../schemas/providerCreateFormSchema.json';

// helpers
import { mapCustomFormSchemaToFormData, checkTemplatePropertiesExist } from '../../helpers/customFormMappers';

// hooks
import useSnackbar from '../../../../../hooks/useSnackbar';
import useRemoteConfigAliaes from '../../../../../hooks/useRemoteConfigAliases';
import useHandleError from '../../../../../hooks/useHandleError';

// components
import Form from '@rjsf/core';
import { Dialog } from '@material-ui/core';
import { Animation, Button, CloseButton } from '@betterpt/better-components';
import CustomCheckboxInput from './CustomCheckboxInput';
import CustomFieldTemplate from '../CustomFieldTemplate';
import CustomFormInput from './CustomFormInput';
import MultipleChoiceAnswersTemplate from './MultipleChoiceAnswersTemplate';
import QuestionsArrayTemplate from './QuestionsArrayTemplate';
import QuestionTypeSelectInput from './QuestionTypeSelectInput';
import SlideTransition from '../../../../Shared/SlideTransition';

//styles
import styled from '@emotion/styled';
const DialogBody = styled.div`
  width: 500px;
  margin: 50px auto 0 auto;
`;

const saveButtonStyle = (primaryColor: string) => ({
  backgroundColor: primaryColor,
  position: 'absolute' as 'absolute',
  top: 40,
  right: 40,
  width: 211,
});
const dialogStyle = { margin: '5vh 5vw 0px' };
const dialogPaperStyle = { borderRadius: '5px 5px 0px 0px' };
const closeButtonStyle = { left: '16px' };

interface Props {
  isOpen: boolean;
  handleClose(): void;
  mappedAppointmentType: MappedAppointmentTypeDetail;
  updateAppointmentTypeQuestions: (
    payload: UpdateAppointmentQuestionsPayload
  ) => Promise<void>;
  loading: boolean;
}

const EditCustomQuestions: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  handleClose,
  mappedAppointmentType,
  updateAppointmentTypeQuestions,
  loading,
}) => {

  const snackbar = useSnackbar();
  const handleError = useHandleError();
  const { primaryColor } = useRemoteConfigAliaes();
  const [saving, updateSaving] = React.useState(false);
  const [formData, updateFormData] = React.useState<any>({});
  const customFormTemplate = mappedAppointmentType.customFormTemplate;
  const customFormUiTemplate = mappedAppointmentType.customFormUISchemaTemplate;
  const [disableSave, updateDisableSave] = React.useState(false);

  const updateSchema = async (e: ISubmitEvent<any>) => {
    if (!e.formData.questions)
      handleClose();
    else {
      updateSaving(true);
      try {
        await updateAppointmentTypeQuestions({
          appointmentTypeId: mappedAppointmentType.id,
          formQuestions: e.formData.questions,
        });
        snackbar?.setSuccessMessage('Appointment type questions saved');
        updateSaving(false);
        handleClose();
      } catch (e) {
        updateSaving(false);
        handleError(e);
      }
    }
  };

  const uiSchema = {
    questions: {
      "ui:title": checkTemplatePropertiesExist(customFormTemplate), // Rather then using an extra state to change the title & description we will just check if properties attribute is empty from the backend
      "ui:description": checkTemplatePropertiesExist(customFormTemplate), //We are checking properties inside customFormTemplate because once user creates a questions template it will exist in the database even after they remove all the questions 
      'ui:ArrayFieldTemplate': QuestionsArrayTemplate,
      items: {
        responses: {
          'ui:ArrayFieldTemplate': MultipleChoiceAnswersTemplate,
        },
        'What type of question do you want to ask?': {
          'ui:widget': QuestionTypeSelectInput,
        },
      },
      'ui:order': [
        'How would you like to be contacted?',
        'How did you hear about us?',
      ],
    },
  };

  React.useEffect(() => {
    if (!loading) {
      updateFormData(mapCustomFormSchemaToFormData(
        customFormTemplate,
        customFormUiTemplate?.['ui:order']
      ) ?? {});
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const widgets = {
    TextWidget: CustomFormInput,
    CheckboxWidget: CustomCheckboxInput,
  };

  const handleFormChange = ({ formData }: any) => {
    let requiredFormData: any = []
    let nonRequiredFormData: any = []
    let hasQuestionsWithOnlyNumbers = false;
    formData['questions']?.forEach((key: any) => {
      if (/^\d+$/.test(key?.questionText)) {
        hasQuestionsWithOnlyNumbers = true;
      }
      key?.required
        ? requiredFormData.push(key)
        : nonRequiredFormData.push(key);
    });
    if (hasQuestionsWithOnlyNumbers) {
      updateDisableSave(true);
      snackbar?.openSnackbar({
        isError: true,
        message: 'Question cannot be a number!',
      });
    } else {
      updateDisableSave(false);
    }
    updateFormData({ questions: [...requiredFormData, ...nonRequiredFormData] })
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullScreen
      style={dialogStyle}
      PaperProps={{ style: dialogPaperStyle }}
      TransitionComponent={SlideTransition}
    >
      <CloseButton onClose={handleClose} style={closeButtonStyle} />
      {loading ? (
        <Animation type='providerAppLoader' />
      ) : (
        <DialogBody>
          {saving ? (
            <>
              <Animation type='providerAppLoader' />
              <Button
                loading={saving}
                style={saveButtonStyle(primaryColor)}
                size='large'
                disabled={disableSave}
              >
                SAVE AND FINISH UP
              </Button>
            </>
          ) : (
            <Form
              name='Create Custom Form'
              schema={providerCreateFormSchema as JSONSchema7}
              onSubmit={updateSchema}
              formData={formData}
              onChange={handleFormChange}
              uiSchema={uiSchema}
              widgets={widgets}
              FieldTemplate={CustomFieldTemplate}
              showErrorList={false}
              disabled={saving}
            >
              <Button
                loading={saving}
                style={saveButtonStyle(primaryColor)}
                size='large'
                disabled={disableSave}
              >
                SAVE AND FINISH UP
              </Button>
            </Form>
          )}
        </DialogBody>
      )
      }
    </Dialog >
  );
};

export default EditCustomQuestions;
