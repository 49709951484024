import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='25'
    height='24'
    viewBox='0 0 25 24'
    {...rest}
  >
    <defs>
      <path
        id='1k7ce8vufa'
        d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z'
      />
    </defs>
    <g fill='none' fillRule='evenodd' transform='translate(.739)'>
      <path d='M0 0L24 0 24 24 0 24z' />
      <mask id='qtvg1384db' fill={color || '#fff'}>
        <use xlinkHref='#1k7ce8vufa' />
      </mask>
      <use fill={color || '#333'} fillRule='nonzero' xlinkHref='#1k7ce8vufa' />
      <g fill={color || '#FFF'} mask='url(#qtvg1384db)'>
        <path d='M0 0H24V24H0z' />
      </g>
    </g>
  </svg>
);
