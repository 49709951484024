import React from 'react';

import { Radio as MRadio, RadioProps } from '@material-ui/core';

import { withStyles } from '@material-ui/styles';
import { colorValues } from '../helpers/colors';

const Radio = withStyles({
  root: {
    color: colorValues.betterptblack,
    '&.MuiRadio-colorSecondary': {
      color: colorValues.betterptblack,
    },
    '&.MuiRadio-colorSecondary.Mui-checked': {
      color: colorValues.betterptblack,
    },
    '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
      backgroundColor: 'rgba(51, 51, 51, 0.04)',
    },
    '&.MuiIconButton-colorSecondary:hover': {
      backgroundColor: 'rgba(51, 51, 51, 0.04)',
    },
  },
})(MRadio);

const Checkbox = ({ ...rest }: RadioProps) => {
  return <Radio {...rest} />;
};

export default Checkbox;
