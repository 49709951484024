import React from 'react';
import styled from '@emotion/styled';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { Button } from '@betterpt/better-components';
import { parseAddressFromString } from '../../helpers';

const P = styled.p`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const PossibleAddressesDialog = ({
  addresses,
  onClose,
}: {
  onClose: (address?: string) => void;
  addresses?: Array<{ formattedAddress: string }>;
}) => {
  const [isDialogOpen, setDialogOpen] = React.useState(false);

  // check if addresses are available to activate popup
  React.useEffect(() => {
    if (addresses) {
      setDialogOpen(true);
    }
  }, [addresses]);

  const handleClose = (address?: string) => {
    setDialogOpen(false);
    onClose(address);
  };

  // makes sure the array of formatted addresses has at least one valid address
  const hasValidAddress = addresses?.find((addr) => {
    const { zipcode, address, city, state } = parseAddressFromString(
      addr.formattedAddress
    );
    return !!zipcode && !!address && !!city && !!state;
  });

  return (
    <Dialog fullWidth open={isDialogOpen}>
      <DialogTitle id='simple-dialog-title'>
        Address Not Recognized {hasValidAddress && ', Did you mean:'}
      </DialogTitle>
      <DialogContent>
        {addresses?.map((addr) => {
          if (hasValidAddress) {
            return (
              <P
                onClick={() => handleClose(addr.formattedAddress)}
                key={addr.formattedAddress}
              >
                {addr.formattedAddress}
              </P>
            );
          } else return null;
        })}
        {!hasValidAddress && (
          <p>Please double check the address you entered and try again</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button color='no-border' onClick={() => handleClose()}>
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PossibleAddressesDialog;
