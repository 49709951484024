import React from 'react';
import styled from '@emotion/styled';
import {
  IcArrowUp,
  IcArrowDown,
  IcAdd,
  IcRemove,
} from '@betterpt/better-icons';
import { colorValues } from '@betterpt/better-components';

const EditButton = styled.button<{ color: string }>`
  border: none;
  background-color: transparent;
  border: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: -7px;
  top: 8px;
`;

const AnswerContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
  margin-left: 20px;
`;

const AddAnotherContainer = styled.div`
  border-bottom: 1px solid ${colorValues.coneyislandsidewalk};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-left: 20px;
`;

const AddAnother = styled.p`
  color: ${colorValues.frenchblue};
  font-size: 14px;
  border-bottom: 1px solid ${colorValues.frenchblue};
  margin: 0px;
`;

const MultipleChoiceAnswersTemplate = (props: any) => {
  return (
    <div style={{ maxWidth: '500px', margin: '0 auto' }}>
      {props.items &&
        props.items.map((element: any) => (
          <AnswerContainer key={element.key}>
            {element.children}
            <ButtonContainer>
              <EditButton
                title='Move answer up'
                color={colorValues.ibizateal}
                onClick={element.onReorderClick(
                  element.index,
                  element.index - 1
                )}
                disabled={!element.hasMoveUp}
              >
                <IcArrowUp
                  opacity={1}
                  height='12px'
                  width='12px'
                  color={
                    element.hasMoveUp ? colorValues.cityblock : colorValues.dust
                  }
                />
              </EditButton>
              <EditButton
                title='Move answer down'
                color={colorValues.ibizateal}
                onClick={element.onReorderClick(
                  element.index,
                  element.index + 1
                )}
                disabled={!element.hasMoveDown}
              >
                <IcArrowDown
                  opacity={1}
                  height='12px'
                  width='12px'
                  color={
                    element.hasMoveDown
                      ? colorValues.cityblock
                      : colorValues.dust
                  }
                />
              </EditButton>
              <EditButton
                title='Delete answer'
                color={colorValues.emptiness}
                onClick={element.onDropIndexClick(element.index)}
              >
                <IcRemove color={colorValues.orangepink} />
              </EditButton>
            </ButtonContainer>
          </AnswerContainer>
        ))}

      {props.canAdd && (
        <AddAnotherContainer
          onClick={props.onAddClick}
          onKeyDown={(e) => e.key === 'Enter' && props.onAddClick()}
          role='button'
          tabIndex={0}
          id='clickToAddResponse'
          aria-label='Click to add another user response'
        >
          <AddAnother>
            Click here to add another answer to this question
          </AddAnother>
          <IcAdd />
        </AddAnotherContainer>
      )}
    </div>
  );
};

export default MultipleChoiceAnswersTemplate;
