import React from 'react';
import { Route } from 'react-router-dom';

//types
import { RouteType } from './types';

export const renderRoutes = (routes: RouteType[]) => {
  return routes
    .filter((route) => !!route.path)
    .map((route) => {
      let moreRoutes =
        route?.routes?.map((sub) => (
          <Route
            key={sub.path}
            path={`${route.path}${sub.path}`}
            exact={sub.exact}
            children={sub.component}
          />
        )) || [];
      return [
        ...moreRoutes,
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          children={route.component}
        />,
      ];
    });
};
