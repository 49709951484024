import React from 'react';
import styled from '@emotion/styled';

import { Card, CardProps } from '@betterpt/better-components';

export const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const Grid = styled.div`
  max-width: 1190px;
  display: flex;
  flex-direction: column;
`;

export const MultiCardGrid = styled.div`
  width: 576px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-right: 28px;
  @media only screen and (max-width: 1400px) {
    width: 100%;
  }
`;

export const GridCard: React.FC<React.PropsWithChildren<{
  style?: React.CSSProperties;
} & CardProps>> = ({ children, style = {}, ...rest }) => {
  return (
    <Card
      style={{
        marginBottom: 25,
        marginRight: 15,
        flex: 1,
        boxShadow:
          '0 2px 10px 0 rgba(51, 51, 51, 0.24), 0 2px 2px 0 rgba(51, 51, 51, 0.12)',
        ...style,
      }}
      width={576}
      fullWidthAtBreakpoint={1400}
      {...rest}
    >
      {children}
    </Card>
  );
};
