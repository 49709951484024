import * as React from 'react';

export default ({ color, opacity, ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='10'
    height='5'
    viewBox='0 0 10 5'
    {...rest}
  >
    <g fill='none' fillRule='evenodd' opacity={opacity || '.38'}>
      <path fill={color || '#333'} fillRule='nonzero' d='M0 0l5 5 5-5z' />
      <path d='M-7-10h24v24H-7z' />
    </g>
  </svg>
);
