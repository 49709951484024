import { ChangeEvent } from 'react';

export const normalizeClock = (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  defaultAMorPM: 'AM' | 'PM'
): string => {
  const value = event?.target?.value;
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');

  // Don't allow number greater than 1 on the first character
  if (onlyNums.length === 1 && +onlyNums > 1) {
    return `0${value}:`;
  }

  // restrict second character to not be more than 2 for 12 hr clock
  if (onlyNums.length === 2 && onlyNums[0] === '1') {
    if (+onlyNums[1] <= 1) {
      return `${onlyNums}:`;
    }
    return '12:';
  }

  //Alow add space after numbers
  if (onlyNums.length === 4 && value[value.length - 1] === ' ') {
    return `${onlyNums.slice(0, 2)}:${onlyNums.slice(2, 4)} `;
  }

  // Add AM or PM
  if (onlyNums.length === 4 && value[value.length - 1].toLowerCase() === 'a') {
    return `${onlyNums.slice(0, 2)}:${onlyNums.slice(2, 4)} AM`;
  }
  if (onlyNums.length === 4 && value[value.length - 1].toLowerCase() === 'p') {
    return `${onlyNums.slice(0, 2)}:${onlyNums.slice(2, 4)} PM`;
  }

  if (value.length > 1 && value[value.length - 1] !== ':') {
    // typing forward
    // Case if typing started with char 0
    if (onlyNums.length === 2) {
      return `${onlyNums}:`;
    }
    if (onlyNums.length === 3) {
      return `${onlyNums.slice(0, 2)}:${onlyNums.slice(2, 4)}`;
    }
  }
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length < 4) {
    return `${onlyNums.slice(0, 2)}:${onlyNums.slice(2)}`;
  }

  return `${onlyNums.slice(0, 2)}:${
    (+onlyNums.slice(2, 4) >= 60 && '59') || onlyNums.slice(2, 4)
  }${
    (value.includes('AM') && ' AM') ||
    (value.includes('PM') && ' PM') ||
    ` ${defaultAMorPM}`
  }`;
};

export const formatMinutesToClock = (value: number) => {
  if (!value) {
    return '';
  }
  const hour = Math.floor(value / 60);
  const minute = value % 60;
  const isAm = hour < 12;

  const isNoon = hour === 12;
  const isMidnight = hour === 24;

  const formatZero = (num: number) => {
    return (num < 10 && `0${num}`) || num;
  };

  return `${isAm || isNoon ? formatZero(hour) : formatZero(hour - 12)}:${
    minute === 0 ? '00' : minute
  } ${isAm || isMidnight ? 'AM' : 'PM'}`;
};

export const formatClockToMinutes = (str: string): number | null => {
  if (!str) {
    return null;
  }
  const [clock, AmOrPm] = str.split(' ');

  const [hour, minute] = clock.split(':');

  const isNoon = AmOrPm === 'PM' && +hour === 12;
  const isMidnight = AmOrPm === 'AM' && +hour === 12;

  return (
    ((AmOrPm === 'AM' && !isMidnight) || isNoon ? +hour : +hour + 12) * 60 +
    +minute
  );
};

type DaysHours = {
  mondayOpen?: number | null | undefined;
  mondayClose?: number | null | undefined;
  tuesdayOpen?: number | null | undefined;
  tuesdayClose?: number | null | undefined;
  wednesdayOpen?: number | null | undefined;
  wednesdayClose?: number | null | undefined;
  thursdayOpen?: number | null | undefined;
  thursdayClose?: number | null | undefined;
  fridayOpen?: number | null | undefined;
  fridayClose?: number | null | undefined;
  saturdayOpen?: number | null | undefined;
  saturdayClose?: number | null | undefined;
  sundayOpen?: number | null | undefined;
  sundayClose?: number | null | undefined;
};

export const weekDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const selectedDaysCheckboxInitialValues = (days: object) => {
  let selectedDays: { [key: string]: boolean | null | undefined } = {};

  weekDays.forEach(
    (day) =>
      (selectedDays[`${day}OpenSelected`] = !!(days as any)[`${day}Open`])
  );

  return selectedDays;
};

export const daysHourOfOperationInitialValues = (days: DaysHours) => {
  const formattedOpenCloseDaysHours: {
    [key: string]: string | null | undefined;
  } = {};
  Object.keys(days).forEach((day) => {
    if (day !== '__typename') {
      formattedOpenCloseDaysHours[day] = formatMinutesToClock(
        (days as any)[day]
      );
    }
  });

  const selectedDays = selectedDaysCheckboxInitialValues(days);
  return { formattedOpenCloseDaysHours, selectedDays };
};

export const daysHoursOfOperationSubmitValues = (days: object) => {
  const formattedDaysHours: { [key: string]: number | null | undefined } = {};
  Object.keys(days).forEach((day) => {
    if (day !== '__typename') {
      formattedDaysHours[day] = formatClockToMinutes((days as any)[day]);
    }
  });
  delete formattedDaysHours.__typename;
  return formattedDaysHours;
};
