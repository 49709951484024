import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='14'
    height='14'
    viewBox='0 0 14 14'
    {...rest}
  >
    <defs>
      <path
        id='2bs7h6lt1a'
        d='M11.334 7.572c.024-.187.041-.374.041-.572 0-.198-.018-.385-.04-.572l1.23-.962c.11-.088.14-.245.07-.373l-1.167-2.019c-.07-.128-.227-.175-.355-.128l-1.453.583c-.303-.233-.63-.426-.986-.571l-.221-1.546c-.018-.14-.14-.245-.286-.245H5.833c-.146 0-.268.105-.285.245l-.222 1.546c-.356.145-.683.344-.986.571l-1.452-.583c-.135-.053-.286 0-.356.128L1.365 5.093c-.076.128-.04.285.07.373l1.23.962c-.022.187-.04.38-.04.572 0 .192.018.385.04.572l-1.23.962c-.11.088-.14.245-.07.373l1.167 2.019c.07.128.227.175.356.128l1.452-.583c.303.233.63.426.986.572l.222 1.545c.017.14.14.245.285.245h2.334c.146 0 .268-.105.286-.245l.221-1.545c.356-.146.683-.345.986-.572l1.453.583c.134.053.285 0 .355-.128l1.167-2.018c.07-.129.04-.286-.07-.374l-1.23-.962zM7 9.042c-1.126 0-2.042-.916-2.042-2.042S5.874 4.958 7 4.958 9.042 5.874 9.042 7 8.126 9.042 7 9.042z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g transform='translate(-539 -627) translate(112 381) translate(0 116) translate(400 123) translate(20) translate(1.167 1.167) translate(5.833 5.833)'>
                    <path d='M0 0L14 0 14 14 0 14z' />
                    <mask id='t41ifubizb' fill='#fff'>
                      <use xlinkHref='#2bs7h6lt1a' />
                    </mask>
                    <use
                      fill={color ?? '#333'}
                      fillRule='nonzero'
                      xlinkHref='#2bs7h6lt1a'
                    />
                    <g fill={color ?? '#333'} mask='url(#t41ifubizb)'>
                      <path d='M0 0H14V14H0z' />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
