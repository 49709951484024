import { colorValues, bestTextColor } from '@betterpt/better-components';
import styled from '@emotion/styled';

export const DialogBody = styled.div`
  position: relative;
  padding: 47px 45px 36px 48px;
  margin: 5px;
`;

export const H1 = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 24px;
`;

export const DialogParagraph = styled.p`
  margin-bottom: 25px;
  line-height: 1.5;
  max-width: 480px;
`;

export const buttonStyle = (color?: string) => ({
  backgroundColor: color || colorValues.cityblock,
  color: bestTextColor(color ?? colorValues.cityblock),
  fontWeight: 'bold' as 'bold',
  marginTop: '20px',
});
