import React from 'react';

//helpers
import { Roles } from '../../../../../helpers/rbac-rules';

// Components
import AboutThisClinic from './AboutThisClinic';
import { Grid, GridRow } from '../../../../Shared/GridCard';
import FacilityContact from './FacilityContact';
import Can from '../../../../Can';
import NotAFacilityMemberCard from '../NotAFacilityMemberCard';
import EmployeesList from './EmployeesList';
import InPersonAppointmentsList from './InPersonAppointmentsList';
import PatientsList from './PatientsList';
import YextMessage from '../YextMessage';

// queryes
import { useMeQuery, useFacilityQuery } from '../../../../../generated/graphql';

// hooks
import useSnackbar from '../../../../../hooks/useSnackbar';

const FacilityInfoTab = ({ facilityId }: { facilityId: string }) => {
  const snackbar = useSnackbar();
  const meQuery = useMeQuery();

  const facilityQuery = useFacilityQuery({
    variables: { id: facilityId },
    onError: () => snackbar?.useGenericErrorMessage(),
    fetchPolicy: 'cache-and-network',
  });

  const facilityIsManagedViaYext = facilityQuery.data?.clinic?.isManagedViaYext;

  const company = meQuery.data?.me?.company;
  const isNotWhiteLabel = !['acorn', 'somatus'].includes(company?.providerAppId ?? '');

  return (
    <Grid>
      {facilityIsManagedViaYext && <YextMessage />}
      {meQuery.data && facilityQuery.data && (
        <Can
          role={Roles[meQuery.data?.me?.role || 'initial']}
          perform="facility:view"
          data={{
            employeeClinics: meQuery?.data?.me?.clinics,
            clinicToView: facilityQuery.data?.clinic,
          }}
          no={() => (
            <GridRow>
              <NotAFacilityMemberCard />
            </GridRow>
          )}
        />
      )}

      <GridRow>
        <AboutThisClinic facilityId={facilityId} />
        <FacilityContact facilityId={facilityId} />
      </GridRow>
      <Can
        role={Roles[meQuery.data?.me?.role || 'initial']}
        perform="facility:view"
        data={{
          employeeClinics: meQuery?.data?.me?.clinics,
          clinicToView: facilityQuery.data?.clinic,
        }}
        yes={() => (
          <React.Fragment>
            <EmployeesList facilityId={facilityId} />
            {isNotWhiteLabel && <InPersonAppointmentsList facilityId={facilityId} />}
            <PatientsList facilityId={facilityId} />
          </React.Fragment>
        )}
      />
    </Grid>
  );
};

export default FacilityInfoTab;
