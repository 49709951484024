import * as React from 'react';

// types
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Appointment } from '../../../../../generated/graphql';

// helpers
import { colorValues, bestTextColor } from '@betterpt/better-components';

import { createTheme } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import {
  setMilliseconds,
  setSeconds,
  setMinutes,
  setHours,
  setDate,
  setMonth,
  setYear,
  getMinutes,
  getHours,
  getDate,
  getMonth,
  getYear,
  isValid,
} from 'date-fns';

// hooks
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';
import { useSendAlternateTimesMutation } from '../../../../../generated/graphql';

// components
import { ThemeProvider } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import { Button, CloseButton } from '@betterpt/better-components';
import ThemedTextField from '../../../../Shared/ThemedTextField';
import SlideTransition from '../../../../Shared/SlideTransition';

import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

//assets
import { IcDropDownSelected } from '@betterpt/better-icons';

//styles
import {
  buttonStyle,
  DialogBody,
  H1,
  inputStyle,
  InputStyleWrapper,
  Row,
  SubHeader,
} from './SuggestAlternateTimesDialog.style';
import useHandleError from '../../../../../hooks/useHandleError';

type Props = {
  appointment: Appointment;
  open: boolean;
  handleComplete: () => void;
  onClose: () => void;
};

const SuggestAlternateTimesDialog = ({
  appointment,
  open,
  handleComplete,
  onClose,
}: Props) => {
  const { primaryColor } = useRemoteConfigAliases();
  const handleError = useHandleError();

  const [alternateDate1, updateAlternateDate1] =
    React.useState<MaterialUiPickersDate | null>(null);
  const [alternateTime1, updateAlternateTime1] =
    React.useState<MaterialUiPickersDate | null>(null);
  const [alternateDate2, updateAlternateDate2] =
    React.useState<MaterialUiPickersDate | null>(null);
  const [alternateTime2, updateAlternateTime2] =
    React.useState<MaterialUiPickersDate | null>(null);
  const [expirationHours, updateExpirationHours] = React.useState<string>('3');
  const [date1Error, updateDate1Error] = React.useState<
    React.ReactNode | undefined
  >(undefined);
  const [time1Error, updateTime1Error] = React.useState<
    React.ReactNode | undefined
  >(undefined);
  const [date2Error, updateDate2Error] = React.useState<
    React.ReactNode | undefined
  >(undefined);
  const [time2Error, updateTime2Error] = React.useState<
    React.ReactNode | undefined
  >(undefined);

  const theme = createTheme({
    palette: {
      primary: {
        main: primaryColor,
        contrastText: bestTextColor(primaryColor),
      },
    },
  });

  const [suggestAlternateTimes, alternateTimesOptions] =
    useSendAlternateTimesMutation();

  const handleSubmit = async () => {
    if (!appointment.id) return;
    try {
      const now = new Date();
      const altTime1 = zonedTimeToUtc(
        setYear(
          setMonth(
            setDate(
              setHours(
                setMinutes(
                  setSeconds(setMilliseconds(now, 0), 0),
                  getMinutes(alternateTime1 ?? now)
                ),
                getHours(alternateTime1 ?? now)
              ),
              getDate(alternateDate1 ?? now)
            ),
            getMonth(alternateDate1 ?? now)
          ),
          getYear(alternateDate1 ?? now)
        ),
        appointment.timeZone ?? 'America/New_York'
      );
      const altTime2 = zonedTimeToUtc(
        setYear(
          setMonth(
            setDate(
              setHours(
                setMinutes(
                  setSeconds(setMilliseconds(now, 0), 0),
                  getMinutes(alternateTime2 ?? now)
                ),
                getHours(alternateTime2 ?? now)
              ),
              getDate(alternateDate2 ?? now)
            ),
            getMonth(alternateDate2 ?? now)
          ),
          getYear(alternateDate2 ?? now)
        ),
        appointment.timeZone ?? 'America/New_York'
      );
      await suggestAlternateTimes({
        variables: {
          input: {
            appointmentId: appointment.id,
            alternateTime1: altTime1,
            alternateTime2: altTime2,
            alternateTimeExpirationHours: +expirationHours,
          },
        },
      });
      handleComplete();
      onClose();
    } catch (e) {
      handleError(e);
    }
  };

  const handleTimeChange = (
    selectedTime: MaterialUiPickersDate,
    altTime: number
  ) => {
    if (selectedTime) {
      switch (altTime) {
        case 1:
          updateAlternateTime1(
            setMinutes(
              selectedTime,
              Math.ceil(getMinutes(selectedTime) / 5) * 5
            )
          );
          if (isValid(selectedTime)) {
            updateTime1Error(undefined);
          }
          break;
        case 2:
          updateAlternateTime2(
            setMinutes(
              selectedTime,
              Math.ceil(getMinutes(selectedTime) / 5) * 5
            )
          );
          if (isValid(selectedTime)) {
            updateTime2Error(undefined);
          }
          break;
        default:
          break;
      }
    }
  };

  const buttonDisabled =
    !alternateDate1 ||
    !alternateTime1 ||
    !alternateDate2 ||
    !alternateTime2 ||
    !!time1Error ||
    !!date1Error ||
    !!time2Error ||
    !!date2Error;

  return (
    <Dialog
      TransitionComponent={SlideTransition}
      open={open}
      onClose={onClose}
      maxWidth='sm'
      PaperProps={{
        style: {
          overflowY: 'visible',
          width: 650,
          maxWidth: 650,
        },
      }}
    >
      <DialogBody>
        <H1>Suggest a new time via SMS</H1>
        <p className='Body' style={{ marginBottom: '24px' }}>
          Choose two times that work better for you than the original time
          requested. These times will be sent out via text for confirmation.
        </p>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={theme}>
            <Row style={{ justifyContent: 'space-between' }}>
              <SubHeader style={{ top: '-22px' }}>New Time 1</SubHeader>
              <KeyboardDatePicker
                disablePast
                autoOk
                style={{ width: '46%' }}
                placeholder='Choose Date'
                InputLabelProps={{ shrink: true }}
                format='MM/dd/yyyy'
                mask='__/__/____'
                id='alternateDate1'
                value={alternateDate1}
                onChange={(newDate) => {
                  updateAlternateDate1(newDate);
                  if (isValid(newDate) && !!date1Error) {
                    updateDate1Error(undefined);
                  }
                }}
                error={Boolean(date1Error)}
                helperText={date1Error}
                variant='inline'
                inputProps={{
                  style: inputStyle,
                }}
                keyboardIcon={
                  <IcDropDownSelected
                    style={{ height: 24, width: 24 }}
                    color={colorValues.betterptblack}
                  />
                }
                onError={(error) => {
                  if (error !== date1Error) {
                    updateDate1Error(error);
                  }
                }}
              />

              <KeyboardTimePicker
                autoOk
                style={{ width: '46%' }}
                format='hh:mm a'
                invalidDateMessage='Invalid: must be 12hr AM/PM format'
                placeholder='Choose Time'
                id='alternateTime1'
                minutesStep={5}
                InputLabelProps={{ shrink: true }}
                value={alternateTime1}
                onChange={(newTime) => handleTimeChange(newTime, 1)}
                error={!!time1Error}
                helperText={time1Error}
                variant='inline'
                inputProps={{
                  style: inputStyle,
                }}
                keyboardIcon={
                  <IcDropDownSelected
                    style={{ height: 24, width: 24 }}
                    color={colorValues.betterptblack}
                  />
                }
                onError={(error) => {
                  if (error !== time1Error) {
                    updateTime1Error(error);
                  }
                }}
              />
            </Row>

            <Row style={{ justifyContent: 'space-between' }}>
              <SubHeader style={{ top: '-22px' }}>New Time 2</SubHeader>
              <KeyboardDatePicker
                disablePast
                autoOk
                style={{ width: '46%' }}
                placeholder='Choose Date'
                InputLabelProps={{ shrink: true }}
                format='MM/dd/yyyy'
                mask='__/__/____'
                id='alternateDate2'
                value={alternateDate2}
                onChange={(newDate) => {
                  updateAlternateDate2(newDate);
                  if (isValid(newDate) && !!date2Error) {
                    updateDate2Error(undefined);
                  }
                }}
                error={!!date2Error}
                helperText={date2Error}
                variant='inline'
                inputProps={{
                  style: inputStyle,
                }}
                keyboardIcon={
                  <IcDropDownSelected
                    style={{ height: 24, width: 24 }}
                    color={colorValues.betterptblack}
                  />
                }
                onError={(error) => {
                  if (error !== date2Error) {
                    updateDate2Error(error);
                  }
                }}
              />
              <KeyboardTimePicker
                autoOk
                style={{ width: '46%' }}
                format='hh:mm a'
                invalidDateMessage='Invalid: must be 12hr AM/PM format'
                placeholder='Choose Time'
                id='alternateTime2'
                minutesStep={5}
                InputLabelProps={{ shrink: true }}
                value={alternateTime2}
                onChange={(newTime) => handleTimeChange(newTime, 2)}
                error={!!time2Error}
                helperText={time2Error}
                variant='inline'
                inputProps={{
                  style: inputStyle,
                }}
                keyboardIcon={
                  <IcDropDownSelected
                    style={{ height: 24, width: 24 }}
                    color={colorValues.betterptblack}
                  />
                }
                onError={(error) => {
                  if (error !== time1Error) {
                    updateTime2Error(error);
                  }
                }}
              />
            </Row>
          </ThemeProvider>
        </MuiPickersUtilsProvider>

        <Row>
          <SubHeader style={{ top: '-14px' }}>
            Hold times on the platform for
          </SubHeader>
          <InputStyleWrapper>
            <ThemedTextField
              select
              fullWidth
              value={expirationHours}
              onChange={(e) => updateExpirationHours(e.target.value)}
              inputProps={{
                style: inputStyle,
              }}
              InputProps={{
                endAdornment: (
                  <IcDropDownSelected
                    style={{ height: 24, width: 24 }}
                    color={colorValues.betterptblack}
                  />
                ),
              }}
              SelectProps={{
                native: true,
              }}
            >
              {new Array(12).fill(0).map((_, i) => (
                <option key={`hour-option-${i}`} value={(i + 1).toString()}>
                  {`${i + 1} hour${i + 1 > 1 ? 's' : ''}`}
                </option>
              ))}
            </ThemedTextField>
          </InputStyleWrapper>
          <SubHeader style={{ bottom: '-8px' }}>
            If you do not receive a response within this time block, it will
            open up again for others to book
          </SubHeader>
        </Row>

        <Button
          fullWidth
          size='large'
          color='transparent'
          style={buttonStyle(primaryColor)}
          onClick={handleSubmit}
          disabled={buttonDisabled}
          loading={alternateTimesOptions.loading}
        >
          SUGGEST NEW TIMES
        </Button>
        <Button
          fullWidth
          size='large'
          color='transparent'
          style={buttonStyle(colorValues.emptiness)}
          onClick={onClose}
        >
          CANCEL
        </Button>
        <CloseButton onClose={onClose} />
      </DialogBody>
    </Dialog>
  );
};

export default SuggestAlternateTimesDialog;
