import React, { Fragment } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { loader } from 'graphql.macro';

//helpers
import { renderDisplayText } from './helpers';

//queries
import {
  useMeQuery,
  useCompanyFacilitiesQuery,
  Clinic,
  useEmployeeQuery,
  useAssociateEmployeeWithClinicsMutation,
  useDisassociateEmployeeWithClinicsMutation,
} from '../../../../generated/graphql';

//components
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ThemedTextField from '../../../Shared/ThemedTextField';
import { Checkbox, Animation, Button } from '@betterpt/better-components';

//icons
import { IcDropDownSelected } from '@betterpt/better-icons';

//styles
import {
  UL,
  DialogHeader,
  SearchSection,
  EmptyStateText,
  ButtonSection,
  buttonStyle,
} from './FacilitySelector.style';

//hooks
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../hooks/useSnackbar';
import useHandleError from '../../../../hooks/useHandleError';

// queries
const FETCH_EMPLOYEE = loader('../../../../gql/employee/Employee.graphql');
const FETCH_ME = loader('../../../../gql/employee/Me.graphql');

type Props = {
  disabled?: boolean;
  isNewEmployee?: boolean;
  initialClinics?: string[];
  onSubmit?: (selectedClinicIds: Array<string>) => void;
};
const FacilitySelector = ({
  onSubmit,
  isNewEmployee,
  disabled,
  initialClinics,
}: Props) => {
  //hooks
  const handleError = useHandleError();
  const snackbar = useSnackbar();
  const aliasConfig = useRemoteConfigAliases();
  const {
    params: { employeeId },
    path,
  } = useRouteMatch<{ employeeId: string }>();
  const isMyProfile = path === '/profile';

  const meQuery = useMeQuery();
  const me = meQuery.data?.me;

  const companyFacilitiesQuery = useCompanyFacilitiesQuery({
    variables: {
      id: me?.company?.id ?? '0',
      input: {
        query: '',
        limit: 10000,
        offset: 0,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !meQuery.data?.me?.company?.id,
  });

  const queryEmployee = useEmployeeQuery({
    variables: { id: isMyProfile ? me?.id ?? '0' : employeeId },
    skip: isMyProfile ? !me?.id : !employeeId || isNewEmployee,
  });

  const queryError =
    meQuery.error || queryEmployee.error || companyFacilitiesQuery.error;

  React.useEffect(() => {
    if (queryError?.message) {
      snackbar?.useGenericErrorMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryError]);

  const [associateEmployee, associateEmployeeOptions] =
    useAssociateEmployeeWithClinicsMutation();

  const [disassociateEmployee, disassociateOptions] =
    useDisassociateEmployeeWithClinicsMutation();

  //state
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isDialogOpen, setDialogOpenTo] = React.useState(false);
  const [isSelectAllChecked, setSelectAllChecked] = React.useState(false);
  const [clinics, setClinics] = React.useState<any>();
  const [clinicsCopy, setClinicsCopy] = React.useState<any>();
  const [selectedClinicIds, updateSelectedClinicsIds] = React.useState<
    Array<string>
  >(initialClinics ?? []);
  const [clinicsToDisassociate, updateClinicsToDisassociate] = React.useState<
    Array<string>
  >([]);

  // update selected clinics from query employee clinics
  React.useEffect(() => {
    if (!isNewEmployee && queryEmployee.data) {
      const clinics = queryEmployee.data?.employee?.clinics;

      clinics &&
        updateSelectedClinicsIds(clinics?.map((clinic: any) => clinic.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryEmployee.data]);

  // creates clinics Array to select from
  React.useEffect(() => {
    const { role, clinics: meClinics } = me ?? {};
    const clinics =
      role === 'owner'
        ? companyFacilitiesQuery.data?.company?.clinics?.result
        : meClinics;
    if (clinics) {
      setClinics(clinics);
      setClinicsCopy(clinics);
    }
  }, [me, companyFacilitiesQuery.data]);

  const fetching = meQuery.loading || companyFacilitiesQuery.loading;

  const handleCheckboxClick = (id: string) => {
    setSelectAllChecked(false);

    // check if selected clinic exists on selectedClinicsIds Array
    const exists = selectedClinicIds.includes(id);

    if (exists) {
      if (!isNewEmployee) {
        // Add Clinic Id to disassociate if it does not exist on array already
        clinicsToDisassociate.indexOf(id) === -1 &&
          updateClinicsToDisassociate([...clinicsToDisassociate, id]);
      }
      // remove clinic from array
      updateSelectedClinicsIds(
        selectedClinicIds.filter((i) => {
          return i !== id;
        })
      );
    } else {
      if (!isNewEmployee) {
        // Remove clinic id from clinics to disassociate array
        updateClinicsToDisassociate(
          clinicsToDisassociate.filter((clinicId) => clinicId !== id)
        );
      }
      // add clinic to selected clinics array
      updateSelectedClinicsIds([...selectedClinicIds, id]);
    }
  };

  const renderClinics = () =>
    clinicsCopy?.map((clinic: Clinic) => {
      if (!clinic?.id) return null;
      return (
        <FormControlLabel
          style={{ padding: '20px 0px', height: '5px' }}
          key={clinic?.id}
          label={clinic?.displayName}
          control={
            <Checkbox
              checked={selectedClinicIds.includes(clinic.id)}
              onChange={() => handleCheckboxClick(clinic.id)}
            />
          }
        />
      );
    });

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
    const clinicsCopy = clinics.filter((clinic: Clinic) =>
      clinic.displayName
        ?.toLocaleLowerCase()
        .includes(e.target.value.toLocaleLowerCase())
    );
    setClinicsCopy(clinicsCopy);
  };

  //  clears or adds all to selected array
  const handleSelectAll = () => {
    if (isSelectAllChecked) {
      updateSelectedClinicsIds([]);
      if (!isNewEmployee) {
        // adds clinics to disassociate from the employee clinics query
        updateClinicsToDisassociate(
          queryEmployee.data?.employee?.clinics?.map(
            (clinic: any) => clinic.id
          ) || []
        );
      }
    } else {
      if (!isNewEmployee) {
        updateClinicsToDisassociate([]);
      }
      updateSelectedClinicsIds(clinics.map((clinic: Clinic) => clinic.id));
    }
    setSelectAllChecked(!isSelectAllChecked);
  };

  const refetchMeOrEmployee = {
    query: isMyProfile ? FETCH_ME : FETCH_EMPLOYEE,
    variables: { id: isMyProfile ? me?.id : employeeId },
  };

  const handleSubmit = async () => {
    if (isNewEmployee && onSubmit) {
      onSubmit(selectedClinicIds);
      setDialogOpenTo(false);
    } else {
      try {
        if (selectedClinicIds.length > 0) {
          await associateEmployee({
            variables: {
              input: {
                employeeId: isMyProfile ? me?.id ?? '0' : employeeId,
                clinicIds: selectedClinicIds,
              },
            },
            refetchQueries: [refetchMeOrEmployee],
          });
        }
        if (clinicsToDisassociate.length > 0) {
          await disassociateEmployee({
            variables: {
              input: {
                employeeId: isMyProfile ? me?.id ?? '0' : employeeId,
                clinicIds: clinicsToDisassociate,
              },
            },
            refetchQueries: [refetchMeOrEmployee],
          });
        }

        await queryEmployee.refetch();
        setDialogOpenTo(false);
      } catch (e) {
        handleError(e);
      }
    }
  };

  const handleDialogOpen = () => {
    if (disabled) return;
    setDialogOpenTo(true);
  };

  return (
    <Fragment>
      <Dialog
        maxWidth='lg'
        open={isDialogOpen}
        onClose={() => setDialogOpenTo(false)}
      >
        <DialogHeader>
          <h1>
            {aliasConfig.facilitiesPluralAlias} ({selectedClinicIds.length}{' '}
            Selected)
          </h1>
        </DialogHeader>
        {!fetching && (
          <SearchSection>
            <p>
              CHOOSE {aliasConfig.employeeSingularAlias.toUpperCase()}{' '}
              {aliasConfig.facilitiesPluralAlias.toUpperCase()} FROM THE LIST
            </p>
            <ThemedTextField
              style={{ width: 233 }}
              placeholder='Quick search'
              value={searchTerm}
              onChange={handleSearch}
            />
          </SearchSection>
        )}
        {fetching ? (
          <Animation type='providerAppLoader' />
        ) : (
          <UL>
            {!searchTerm && (
              <FormControlLabel
                style={{ padding: '20px 0px', height: '5px' }}
                label='Select all'
                control={
                  <Checkbox
                    checked={isSelectAllChecked}
                    onChange={() => handleSelectAll()}
                  />
                }
              />
            )}
            {!fetching && !clinicsCopy?.length && searchTerm && (
              <EmptyStateText>
                no {aliasConfig.facilitiesSingularAlias.toLowerCase()} found
                matching {searchTerm}
              </EmptyStateText>
            )}
            {renderClinics()}
          </UL>
        )}

        <ButtonSection>
          <Button
            style={buttonStyle(aliasConfig.primaryColor)}
            onClick={handleSubmit}
            fullWidth
            disabled={
              (isNewEmployee && selectedClinicIds.length === 0) ||
              (selectedClinicIds.length === 0 &&
                clinicsToDisassociate.length === 0) ||
              associateEmployeeOptions.loading ||
              disassociateOptions.loading
            }
            loading={
              associateEmployeeOptions.loading || disassociateOptions.loading
            }
            color='transparent'
          >
            CONTINUE
          </Button>
        </ButtonSection>
      </Dialog>
      <ThemedTextField
        disabled={disabled}
        fullWidth
        label={aliasConfig.facilitiesPluralAlias}
        value={renderDisplayText({
          employeeClinics: queryEmployee.data?.employee?.clinics,
          isNewEmployee,
          selectedClinicIds,
        })}
        InputProps={{
          endAdornment: (
            <IcDropDownSelected height={25} width={25} opacity={0.4} />
          ),
        }}
        onClick={handleDialogOpen}
      />
    </Fragment>
  );
};

export default FacilitySelector;
