import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

export const cardStyle = {
  marginRight: '25px',
  width: '504px',
  position: 'relative' as 'relative',
  borderRadius: '5px',
  margin: '20px 28px',
  border: `1px solid ${colorValues.dust}`,
  boxShadow: 'none !important',
};

export const ExpandedDetailContent = styled.div`
  border-radius: 0 0 5px 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const AppointmentDetailContainer = styled.div`
  margin-left: 25px;
  padding: 0px 25px;
  h1 {
    margin: 34px 0px;
    svg {
      vertical-align: text-bottom;
      margin-right: 35px;
    }
  }
`;

export const CardContents = styled.div`
  padding: 15px 15px 20px 40px;
`;

export const cardHeader = {
  padding: '25px 15px 25px 58px',
};

export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 504px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  h1 {
    margin-left: 15px;
  }
`;

export const TextFieldRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const link = {
  textDecoration: 'underline',
  color: colorValues.frenchblue,
};

//DIALOG STYLES
export const DialogBody = styled.div`
  position: relative;
  padding: 50px 58px 50px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
`;

export const H1 = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 15px;
  width: 100%;
  text-align: left;
`;

export const buttonStyle = (color?: string) => ({
  backgroundColor: color || colorValues.cityblock,
  fontWeight: 'bold' as 'bold',
  marginTop: '20px',
  height: 48,
});
