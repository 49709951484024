import * as React from 'react';

//hooks
import { useMeQuery } from '../../../../generated/graphql';
import { UpdateProfileImagePayload } from '../hooks/useProfileInfoOperations';

//components
import { ImageUploader } from '@betterpt/better-components';

//assets
import PlaceholderImage from '../../../../assets/img-unknown-user.svg';
import useHandleError from '../../../../hooks/useHandleError';
import useSnackbar from '../../../../hooks/useSnackbar';

//styles
const imageUploaderStyle = {
  display: 'inline-block',
  margin: '60px 74px',
};

interface Props {
  uploadProfileImage: (payload: UpdateProfileImagePayload) => Promise<void>;
}

const OnboardingProfileImageForm = ({ uploadProfileImage }: Props) => {
  const snackbar = useSnackbar();
  const handleError = useHandleError();
  const meQuery = useMeQuery();
  const employeeId = meQuery.data?.me?.id;

  const [image, updateImage] = React.useState<string>('');
  const [isLoadingImage, setLoadingImage] = React.useState(false);

  React.useEffect(() => {
    const me = meQuery.data?.me;
    if (me) {
      updateImage(me?.profileImgUrl ?? '');
    }
  }, [meQuery.data]);

  const handleSubmitImage = async (base64Img: string) => {
    setLoadingImage(true);
    try {
      await uploadProfileImage({ employeeId, base64Img });
      updateImage(base64Img);
      setLoadingImage(false);
    } catch (e) {
      setLoadingImage(false);
      handleError(e);
    }
  };

  return (
    <ImageUploader
      loading={isLoadingImage}
      type='avatar'
      image={image || PlaceholderImage}
      onSubmit={(event) => {
        handleSubmitImage(event.base64Img);
      }}
      style={imageUploaderStyle}
      helperText='Drag or click to upload a photo. Maximum 250x250 px.'
      onError={(message) => snackbar?.openSnackbar({ isError: true, message })}
    />
  );
};

export default OnboardingProfileImageForm;
