import React from 'react';
import { Page } from '../Employees.style';
import EmployeesPageHeader from './EmployeesPageHeader';
import EmployeesTable from './EmployeesTable';

const EmployeesList = () => {
  const [searchTerm, setSearchTerm] = React.useState('');
  return (
    <Page>
      <EmployeesPageHeader
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <EmployeesTable
        searchTerm={searchTerm}
        resetFilters={() => setSearchTerm('')}
      />
    </Page>
  );
};

export default EmployeesList;
