import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='16'
    height='17'
    viewBox='0 0 16 17'
    {...rest}
  >
    <defs>
      <path
        id='ad3ilxcx1a'
        d='M1.298 13.563L14.854 7.75 1.298 1.938 1.292 6.458 10.979 7.75 1.292 9.042z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g transform='translate(-982 -563) translate(748 23) translate(0 516) translate(0 7) translate(7.185 8) translate(219.193 2) translate(.646 .646) translate(7.104 7.104)'>
                      <path d='M0 0L15.5 0 15.5 15.5 0 15.5z' />
                      <mask id='cf3pvw1iab' fill='#fff'>
                        <use xlinkHref='#ad3ilxcx1a' />
                      </mask>
                      <use
                        fill='#333'
                        fillRule='nonzero'
                        xlinkHref='#ad3ilxcx1a'
                      />
                      <g fill={color ?? '#FFF'} mask='url(#cf3pvw1iab)'>
                        <path d='M0 0H15.5V15.5H0z' />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
