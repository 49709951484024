import React from 'react';

//hooks
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';

// components
import { Button, CloseButton, colorValues } from '@betterpt/better-components';

//styles
import {
  buttonStyle,
  DialogBody,
  DialogParagraph,
  H1,
} from './ConfirmContactPatient.style';

type Props = {
  contactPatient: () => Promise<void>;
  closeDialog: () => void;
  saving: boolean;
};

const ConfirmContactPatient = ({
  contactPatient,
  closeDialog,
  saving,
}: Props) => {
  const {
    patientsSingularAlias,
    facilitiesSingularAlias,
  } = useRemoteConfigAliases();

  return (
    <DialogBody>
      <H1>Mark this {patientsSingularAlias} as Contacted</H1>
      <DialogParagraph className='Body'>
        If you are sure you wish to mark that you have contacted this{' '}
        {patientsSingularAlias.toLowerCase()}, click {'"'}MARK{' '}
        {patientsSingularAlias.toUpperCase()} AS CONTACTED{'"'} below. This will
        disable all request reminder emails sent to your{' '}
        {facilitiesSingularAlias.toLowerCase()} for this appointment. <br />
        This action cannot be undone.
      </DialogParagraph>

      <Button
        fullWidth
        size='large'
        style={buttonStyle(colorValues.ibizateal)}
        onClick={contactPatient}
        loading={saving}
      >
        MARK {patientsSingularAlias.toUpperCase()} AS CONTACTED
      </Button>
      <Button
        fullWidth
        size='large'
        style={buttonStyle(colorValues.emptiness)}
        onClick={closeDialog}
      >
        NEVER MIND
      </Button>
      <CloseButton onClose={closeDialog} />
    </DialogBody>
  );
};

export default ConfirmContactPatient;
