// logos
import AptaLogo from '../../../assets/logo-apta-member.svg';
import LogoTelehealthOt from '../../../assets/logo-telehealth-ot@2x.png';
import BetterAccessFullLogoNoTurtle from '../../../assets/companyLogos/BetterAccessFullNoTurtle.svg';
import AcornFullLogo from '../../../assets/companyLogos/AcornFull.svg';
import SomatusFullLogo from '../../../assets/companyLogos/SomatusFull.svg';

// demo images
import TelehealthImage from '../../../assets/mockup-device-better-telehealth-img-bettertelehealth-isometric@2x.png';
import DemoImage from '../../../assets/mockup-device-better-telehealth-img-betteraccess-isometric@2x.png';

import { colorValues } from '@betterpt/better-components';

export type Variant = {
  companyLogo: string;
  demoImage: string;
  header: string;
  subHeader: string;
  color: string;
  darkColorVariant?: string;
  demoButtonLink: string | null;
  demoButtonCopy: string | null;
  showButton: boolean;
  passVariantToBackend: boolean;
  hideSignupPage: boolean;
  showPoweredByCopy: boolean;
  showWatchTutorialLink: boolean;
};

const config = new Map<string, Variant>([
  [
    'apta',
    {
      companyLogo: AptaLogo,
      demoImage: TelehealthImage,
      header: 'BetterAccess for APTA Members is Here',
      subHeader:
        'Receive discount pricing on the BetterAccess platform. No signup fees, no subscription fees. Connect with your patients on your desktop or mobile device to give your practice the ability to acquire new patients and grow your business.',
      demoButtonLink: 'https://calendly.com/apta-betterpt/telehealth',
      color: colorValues.ibizateal,
      showButton: true,
      hideSignupPage: false,
      passVariantToBackend: true,
      showPoweredByCopy: false,
      demoButtonCopy: 'REQUEST A DEMO',
      showWatchTutorialLink: false,
    },
  ],
  [
    'ot-services',
    {
      companyLogo: LogoTelehealthOt,
      demoImage: TelehealthImage,
      header: 'Welcome to the BetterAccess Platform',
      subHeader: 'Treat your patients from virtually anywhere',
      showButton: true,
      color: '#3b92e3',
      demoButtonLink: 'https://calendly.com/telehealth_otservices/demo',
      hideSignupPage: false,
      passVariantToBackend: true,
      showPoweredByCopy: false,
      demoButtonCopy: 'REQUEST A DEMO',
      showWatchTutorialLink: false,
    },
  ],
  [
    'acorn',
    {
      companyLogo: AcornFullLogo,
      demoImage: TelehealthImage,
      header: 'Welcome to the Acorn Virtual Care Platform',
      subHeader: 'Treat your patients from virtually anywhere',
      showButton: false,
      color: '#235d6f',
      demoButtonLink: null,
      hideSignupPage: true,
      passVariantToBackend: false,
      showPoweredByCopy: false,
      demoButtonCopy: null,
      showWatchTutorialLink: false,
    },
  ],
  [
    'somatus',
    {
      companyLogo: SomatusFullLogo,
      demoImage: TelehealthImage,
      header: 'Welcome to the Somatus Virtual Care Platform',
      subHeader: 'Treat your patients from virtually anywhere',
      showButton: false,
      color: '#083050',
      demoButtonLink: null,
      hideSignupPage: true,
      passVariantToBackend: false,
      showPoweredByCopy: false,
      demoButtonCopy: null,
      showWatchTutorialLink: false,
    },
  ],
  [
    'betterhealthcare',
    {
      companyLogo: BetterAccessFullLogoNoTurtle,
      demoImage: DemoImage,
      header: 'BetterAccess is Here',
      subHeader: 'Our new and improved online platform to help healthcare providers manage all of their scheduling needs',
      showButton: true,
      color: colorValues.ibizateal,
      darkColorVariant: colorValues.guajirogreen,
      demoButtonLink: 'https://calendly.com/betterhealthcare/betteraccess-demo',
      passVariantToBackend: false,
      hideSignupPage: false,
      showPoweredByCopy: true,
      demoButtonCopy: 'LEARN MORE',
      showWatchTutorialLink: true,
    },
  ],
]);

export const determineVariant = (variantString: string): Variant => {
  if (window.location.hostname.includes('somatus')) {
    return config.get('somatus')!;
  } else if (window.location.hostname.includes('acorn')) {
    return config.get('acorn')!;
  } else if (config.get(variantString) !== undefined) {
    return config.get(variantString)!;
  } else {
    return config.get('betterhealthcare')!;
  }
};
