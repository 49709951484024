import React, { Fragment } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { TableProps, DetailPanel } from '../types';
import { colorValues } from '../../helpers/colors';
import Row from './Row';

const useStyles = (rowClickable: boolean) =>
  makeStyles(() =>
    createStyles({
      root: {
        cursor: (rowClickable && 'pointer') || '',
        '&:hover': {
          backgroundColor: (rowClickable && colorValues.earlysteam) || '',
        },
      },
    }),
  );

const TableRows = <RowData extends object>({
  columns,
  data,
  onRowClick,
  detailPanel,
  filtering,
  cellPadding,
}: Partial<TableProps<RowData>> & {
  detailPanel?: DetailPanel<RowData>;
  filtering?: boolean;
}) => {
  const classes = useStyles(onRowClick ? true : false)();
  return (
    <Fragment>
      {data?.map((rowData: any, idx) => (
        <Row
          key={idx}
          classes={classes}
          columns={columns}
          onRowClick={onRowClick}
          rowData={rowData}
          detailPanel={detailPanel}
          filtering={filtering}
          cellPadding={cellPadding}
        />
      ))}
    </Fragment>
  );
};

export default TableRows;
