import * as React from 'react';
import { Link } from 'react-router-dom';

import Can from '../../../../../Can';
import { Header, Button } from '@betterpt/better-components';
import { buttonStyle, H4 } from '../../styles/EmployeeDetail.style';

// helpers
import { Roles } from '../../../../../../helpers/rbac-rules';
import { darkenLightColor } from '@betterpt/better-components';

// hooks
import { Employee, useMeQuery } from '../../../../../../generated/graphql';
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';

type Props = {
  isEditModeActive: boolean;
  setEditModeActive: (isEditModeActive: boolean) => void;
  saving: boolean;
  employee?: Employee | null;
  headerText: string | React.ReactNode;
  hideEditButton?: boolean;
};

const CardHeader = ({
  isEditModeActive,
  saving,
  employee,
  setEditModeActive,
  headerText,
  hideEditButton,
}: Props) => {
  const meQuery = useMeQuery();
  const { primaryColor } = useRemoteConfigAliases();

  return (
    <Header underline style={{ padding: '22px 23px 13px 42px' }}>
      <H4>{headerText}</H4>
      {hideEditButton ? null : (
        <>
          {employee?.id === meQuery.data?.me?.id ? (
            <p className='Body'>
              To edit your own details, click{' '}
              <Link
                style={{ color: darkenLightColor(primaryColor) }}
                to='/profile'
              >
                here.
              </Link>
            </p>
          ) : (
            <Can
              role={Roles[meQuery.data?.me?.role || 'initial']}
              perform='employee:edit'
              data={{
                targetRole: employee?.role,
              }}
              yes={() => (
                <>
                  {isEditModeActive ? (
                    <Button
                      size='small'
                      color='transparent'
                      type='submit'
                      style={buttonStyle(primaryColor, true)}
                      loading={saving}
                    >
                      SAVE
                    </Button>
                  ) : (
                    <Button
                      size='small'
                      color='transparent'
                      style={buttonStyle(primaryColor)}
                      onClick={() => setEditModeActive(true)}
                    >
                      EDIT
                    </Button>
                  )}
                </>
              )}
            />
          )}
        </>
      )}
    </Header>
  );
};

export default CardHeader;
