import React from 'react';

// types
import useAppointmentTypeDetailOperations from '../../../Views/AppointmentTypeDetail/hooks/useAppointmentTypeDetailOperations';

// helpers
import _, { cloneDeep } from 'lodash';


// hooks
import useSnackbar from '../../../../hooks/useSnackbar';
import useRemoteConfigAliaes from '../../../../hooks/useRemoteConfigAliases';
import useHandleError from '../../../../hooks/useHandleError';

// components
import { Animation, Button, CloseButton, colorValues } from '@betterpt/better-components';
import ProgressBar from "../../ProgressBar";
import QuestionsArrayTemplateNew from '../../../Views/AppointmentTypeDetail/components/CustomLogicComponents/QuestionsArrayTemplateNew';

//styles
import styled from '@emotion/styled';
import { CompanyAppointmentType, useAppointmentTypeQuery } from '../../../../generated/graphql';
import { Question } from '../../../Views/AppointmentTypeDetail/components/CustomLogicComponents/CustomTypes';
import { Dialog, DialogContent } from '@material-ui/core';
import SlideTransition from '../../SlideTransition';

const DialogBody = styled.div`
  width: 500px;
  margin: 50px auto 0 auto;
`;
const dialogContentStyle = {
    width: '400px',
    minHeight: '250px',
    padding: '19px 20px 31px 52px',
    borderRadius: '5px',
    boxShadow: '0 19px 38px 0 rgba(51, 51, 51, 0.24)',
    backgroundColor: 'var(--emptiness)',
}

const DialogHeading = styled.span`
  width: 344.8px;
  height: 48px;
  margin: 9px 33.2px 16.3px 0;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--betterpt-black);
`;

const DialogText = styled.p`
  width: 376px;
  height: 55px;
  margin: 16.3px 0 20px 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--betterpt-black);
`;

const saveButtonStyle = (primaryColor: string) => ({
    backgroundColor: primaryColor,
    marginTop: '7vh',
    position: 'absolute' as 'absolute',
    top: 40,
    right: 40,
    width: 211,
});

const dialogButtonStyle = (primaryColor: string) => ({
    backgroundColor: primaryColor,
    width: '376px',
    margin: '5px 0'
});

const dialogPaperStyle = { borderRadius: '5px 5px 0px 0px' };

interface Props {
    handleClose(): void;
    appointmentTypeId?: string;
}

const AddQualifyingQuestions: React.FC<React.PropsWithChildren<Props>> = ({
    handleClose,
    appointmentTypeId,
}) => {

    const snackbar = useSnackbar();
    const handleError = useHandleError();
    const { primaryColor } = useRemoteConfigAliaes();
    const [saving, updateSaving] = React.useState(false);
    const [disableSave, updateDisableSave] = React.useState(false);

    const {
        commands: {
            updateAppointmentTypeQuestions,
        },
        queries: { mapAppointmentTypeDetails },
    } = useAppointmentTypeDetailOperations();

    const appointmentTypeQuery = useAppointmentTypeQuery({
        variables: {
            id: appointmentTypeId!,
        },
        skip: !appointmentTypeId,
        onError: () => snackbar?.useGenericErrorMessage(),
        fetchPolicy: 'cache-and-network',
    });

    let loading = appointmentTypeQuery.loading;
    const appointmentType:
        | CompanyAppointmentType
        | null
        | undefined = appointmentTypeQuery.data
            ?.appointmentType as CompanyAppointmentType;

    const mappedAppointmentType = React.useMemo(
        () =>
            mapAppointmentTypeDetails({
                appointmentType,
                loading
            }),
        [appointmentTypeQuery, loading, mapAppointmentTypeDetails]
    );

    const [questions, updateQuestions] = React.useState<any>([]);
    const [childQuestions, setChildQuestions] = React.useState<any>({});
    const [isChangingType, setIsChangingType] = React.useState<any>({ type: false, selectedId: null, value: null });

    //re structure child questions to align with parent questions
    React.useEffect(() => {
        let groupedQuestions = _(questions.filter((item: any) => item?.level === 1)).groupBy(item => item.parentId).value();
        setChildQuestions(groupedQuestions);
    }, [questions]);

    // add new question template to the state with default values.
    const addCustomQuestion = () => {
        let questionsCount = _.maxBy(questions, 'id');
        let count = questions.filter((item: any) => item?.level === 0).length; // calculate count for only the parent questions
        updateQuestions([
            ...questions,
            {
                level: 0,
                id: questionsCount ? questionsCount['id'] + 1 : questions.length + 1,
                questionType: "",
                questionStatement: "",
                options: [{ id: 1, optionStatement: "" }],
                required: false,
                hasDependency: false,
                parentId: null,
                parentType: null,
                sortOrder: count + 1,
            }]);
    }

    const addDependentCustomQuestion = (parentId: number, required: boolean) => {
        let questionsCount = _.maxBy(questions, 'id');
        let count = questions.filter((item: any) => item?.level === 1 && item.parentId === parentId).length; // calculate count for only the child questions that share the parent id
        updateQuestions([
            ...questions,
            {
                level: 1,
                id: questionsCount ? questionsCount['id'] + 1 : questions.length + 1,
                questionType: "",
                questionStatement: "",
                options: [{ id: 1, optionStatement: "" }],
                required: required,
                hasDependency: false,
                parentId: parentId,
                parentType: '',
                sortOrder: count + 1
            }]);
    }

    // filter out element to delete and then re index the questions.
    const deleteCustomQuestion = (id: number, level: number, parentId?: number) => {

        // filter out the concerned question and children questions as well in case of it being a parent question.
        let updatedQuestions = questions.filter((item: Question) => {
            if (item.id === id && (item.level === 0 || item.level === 1)) return false;
            else if (item.id !== id && item.parentId === id) return false;
            else return true;
        });

        // in case the question to update belongs to the parent level i.e. 0
        if (level === 0) {
            let count = 0; // re indexing of the questions of level 0
            let indexedQuestions = updatedQuestions.map((item: Question) => {
                if (item?.level === 0) {
                    count = count + 1;
                    return item = {
                        ...item, sortOrder: count
                    }
                }
                else {
                    return item;
                }
            });
            updateQuestions([...indexedQuestions]);
        }
        else if (level === 1) {
            let count = 0; // re indexing questions of level 1 that share the same parent
            let indexedQuestions = updatedQuestions.map((item: Question) => {
                if (item?.level === 1 && item.parentId === parentId) {
                    count = count + 1;
                    return item = {
                        ...item, sortOrder: count
                    }
                }
                else {
                    return item;
                }
            });
            updateQuestions([...indexedQuestions]);
        }
    }

    // swaps the indexes of elements in case of up or down movement in the deep copy of state. Then updates the state with the deep copy.
    const orderCustomQuestion = (currIndex: number, newIndex: number) => {

        let firstQuestion = { index: 0, question: {}, sortOrder: currIndex }
        let secondQuestion = { index: 0, question: {}, sortOrder: newIndex }
        let questionsCopy = questions.map((question: any, index: number) => {
            if (question.level === 0) {
                if (question.sortOrder === currIndex) {
                    firstQuestion = { ...firstQuestion, index: index, question: question }
                    return question;
                }
                else if (question.sortOrder === newIndex) {
                    secondQuestion = { ...secondQuestion, index: index, question: question }
                    return question;
                }
                else return question;
            }
            else return question;
        })
        questionsCopy[firstQuestion.index] = { ...secondQuestion.question, sortOrder: firstQuestion.sortOrder };
        questionsCopy[secondQuestion.index] = { ...firstQuestion.question, sortOrder: secondQuestion.sortOrder };
        updateQuestions([...questionsCopy]);
    };

    // takes the id of a particular question to access it's index and update it's values against a particular key.
    const updateQuestionKeys = (id: number, questionKey: string, keyValue: any, level?: number) => {

        if (questionKey === "questionStatement" && /^\d+$/.test(keyValue)) {
            updateDisableSave(true);
            snackbar?.openSnackbar({
                isError: true,
                message: 'Question cannot be a number!',
            });
        } else {
            updateDisableSave(false);
        }

        let updatedQuestions = [];

        if (questionKey === 'required' && keyValue === false && level === 0) {
            updatedQuestions = questions.map((item: any) => (item.id === id || item.parentId === id) ? { ...item, [questionKey]: keyValue } : item);
        }
        else if (questionKey === "hasDependency" && keyValue === false) {
            updatedQuestions = questions.filter((item: any) => item.parentId !== id).map((item: any) => item.id === id ? { ...item, [questionKey]: keyValue } : item)
        }
        else {
            updatedQuestions = questions.map((item: any) => item.id === id ? { ...item, [questionKey]: keyValue } : item);
        }

        if (questionKey === "required" && level === 0) {
            // making child questions required/not-required in case of parent question being required/not-required
            setRequired(updatedQuestions);
        }
        else updateQuestions([...updatedQuestions]);
    }

    const setRequired = (updatedQuestions: Question[]) => {

        // group the questions into two groups: required and non required
        let groups = _(updatedQuestions).groupBy(item => `${item.required}+${item.level}`).value()

        // runs in case of groups having both required and un required questions.
        let trueCount = 0;
        let falseCount = groups["true+0"] ? groups["true+0"].length : 0; // finding length of required questions for indexing unrequired questions.

        updatedQuestions = [...(groups["true+0"] ?? []), ...(groups["true+1"] ?? []), ...(groups["false+0"] ?? []), ...(groups["false+1"] ?? [])];
        // re indexing the questions
        let indexedQuestions = updatedQuestions.map((item: Question, index: number) => {
            if (item.level === 0) {
                if (item.required) {
                    trueCount++;
                    return { ...item, sortOrder: trueCount };
                }
                else if (!item.required) {
                    falseCount++;
                    return { ...item, sortOrder: falseCount };
                }
            }
            else return item;
        })
        updateQuestions([...indexedQuestions]);
        return;
    }

    // checks to see if a question can move up in the sorting order
    const canMoveUp = (sortOrder: number) => {
        const condition = questions.filter((item: any) => item.level === 0 && (item.sortOrder == sortOrder || item.sortOrder == sortOrder - 1))
        return condition.length > 1 && (condition[0]['required'] === condition[1]['required']) ? true : false
    }
    // checks to see if a question can move down in the sorting order
    const canMoveDown = (sortOrder: number) => {
        const condition = questions.filter((item: any) => item.level === 0 && (item.sortOrder == sortOrder || item.sortOrder == sortOrder + 1))
        return condition.length > 1 && (condition[0]['required'] === condition[1]['required']) ? true : false
    }

    const handleTypeChange = () => {
        let questionsCopy = questions.filter((item: any) => item.parentId !== isChangingType.selectedId);
        questionsCopy.forEach((item: any) => {
            if (item.id === isChangingType.selectedId) {
                item.questionType = isChangingType.value;
                item.hasDependency = false;
            }
        });

        updateQuestions([...questionsCopy]);
        setIsChangingType({ ...isChangingType, type: false, selectedId: null, value: null })
    }


    let actions = {
        addQuestion: addCustomQuestion,
        deleteQuestion: deleteCustomQuestion,
        reOrderQuestions: orderCustomQuestion,
        updateQuestion: updateQuestionKeys,
        canMoveUp: canMoveUp,
        canMoveDown: canMoveDown,
        addDependentQuestion: addDependentCustomQuestion,
        changingTypePrompt: setIsChangingType,
        changeTypeState: isChangingType,
    };


    const validatedOptions = (element: any) => {
        let hasError = false;
        let errorOptions = element.options.map((option: any) => {
            if (option.optionStatement === "") {
                hasError = true;
                return option = { ...option, error: true };
            }
            else {
                let { error, ...remainingElement } = option;
                return remainingElement;
            }
        })
        return { returnedElement: { ...element, options: errorOptions }, error: hasError };
    }



    const updateSchema = async () => {
        // avoid calling mutation if the new questions state is the same as old questions state.
        if (questions.length < 1 && mappedAppointmentType.customFormTemplate.questions < 1) handleClose();
        else {

            let isValid = true;

            let validatedQuestions = questions.map(
                (element: any) => {
                    let errors: string[] = [];
                    if (element.questionType === "Multiple Choice" && element.options.length > 0) {
                        let { error, returnedElement } = validatedOptions(element);
                        isValid = error ? false : true;
                        element = { ...returnedElement };
                    }
                    if (element.level === 1 && element.parentType === "") {
                        errors = [...errors, 'parentType'];
                        isValid = false
                        return element = { ...element, errors }
                    }
                    if (element.questionType === "") {
                        errors = [...errors, 'questionType'];
                        isValid = false
                        return element = { ...element, errors }
                    }
                    if (element.questionStatement === "") {
                        errors = [...errors, 'questionStatement'];
                        isValid = false
                        return element = { ...element, errors }
                    }
                    else if (isValid) {
                        let { errors, ...remainingElement } = element;
                        return remainingElement;
                    };
                    return element;
                }
            )

            updateQuestions([...validatedQuestions]);

            if (!isValid) {
                snackbar?.openSnackbar({
                    isError: true,
                    message: 'You must enter the text of all qualifying questions before saving.',
                });
                return;
            }

            let schema = {
                title: mappedAppointmentType?.customFormTemplate?.title,
                description: mappedAppointmentType?.customFormTemplate?.description,
                schemaVersion: '1.0.0',
                questions: questions,
            }
            updateSaving(true);
            try {
                await updateAppointmentTypeQuestions({
                    appointmentTypeId: mappedAppointmentType.id,
                    formQuestions: schema,
                });
                snackbar?.setSuccessMessage('Appointment type questions saved');
                updateSaving(false);
                handleClose();
            } catch (e) {
                updateSaving(false);
                handleError(e);
            }
        }
    };

    return (
        loading ? (
            <Animation type='providerAppLoader' />
        ) : (
            <DialogBody>
                <ProgressBar width={100} style={{ marginTop: 20 }} />
                {saving ? (
                    <>
                        <Animation type='providerAppLoader' />
                        <Button
                            loading={saving}
                            style={saveButtonStyle(primaryColor)}
                            size='large'
                            disabled={disableSave}
                        >
                            SAVE AND FINISH UP
                        </Button>
                    </>
                ) : (
                    <>
                        <Dialog
                            open={isChangingType.type}
                            onClose={() => setIsChangingType({ ...isChangingType, type: false, selectedId: null, value: null })}
                            PaperProps={{ style: dialogPaperStyle }}
                            TransitionComponent={SlideTransition}
                        >
                            <CloseButton onClose={() => setIsChangingType({ ...isChangingType, type: false, selectedId: null, value: null })} />


                            <DialogContent style={dialogContentStyle}>

                                <DialogHeading>
                                    Are you sure you want to delete this question?
                                </DialogHeading>
                                <DialogText>
                                    By deleting this question, you will also delete any secondary cascading questions that the question triggers on the patient booking flow. Are you sure you want to continue?

                                </DialogText>

                                <Button
                                    loading={saving}
                                    style={dialogButtonStyle(colorValues.messyketchup)}
                                    size='large'
                                    onClick={() => handleTypeChange()}
                                >
                                    CONTINUE AND DELETE QUESTION
                                </Button>
                                <Button
                                    loading={saving}
                                    style={dialogButtonStyle(colorValues.emptiness)}
                                    size='large'
                                    onClick={() => setIsChangingType({ ...isChangingType, type: false, selectedId: null, value: null })}
                                >
                                    NEVER MIND
                                </Button>
                            </DialogContent>
                        </Dialog>

                        <QuestionsArrayTemplateNew
                            prefix='Add'
                            questions={questions}
                            actions={actions}
                            childQuestions={childQuestions}
                        />
                        <Button
                            loading={saving}
                            style={saveButtonStyle(primaryColor)}
                            size='large'
                            disabled={disableSave}
                            onClick={updateSchema}
                        >
                            SAVE AND FINISH UP
                        </Button>
                    </>
                )}
            </DialogBody>
        )
    );
};

export default AddQualifyingQuestions;