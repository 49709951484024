import * as React from 'react';
import Auth from '@aws-amplify/auth';
import axios from 'axios';

// hooks
import { useHistory, useParams } from 'react-router-dom';
import {
  useUpdateFacilityMutation,
  useUpdateFacilitySpecialBillingStatusMutation,
  useUpdateFacilityBookingConfigMutation,
  useCompanyBillingOverviewQuery,
  useFacilityQuery,
} from '../../../../../../../generated/graphql';
import useHandleError from '../../../../../../../hooks/useHandleError';

// helpers
import { daysHoursOfOperationSubmitValues } from '@betterpt/better-components';

type HoursAndAboutInput = {
  about: string;
  inPersonHoursOfOperation: any;
  telehealthHoursOfOperation: any;
  isHSSAffiliate?: boolean;
  logoUrl?: string;
  avatarUrl?: string;
};

const useHandleSubmit = () => {
  const handleError = useHandleError();
  const history = useHistory();
  const { facilityId } = useParams<{ facilityId: string }>();
  const [isSavingForm, updateIsSavingForm] = React.useState(false);
  const facilityQuery = useFacilityQuery({
    variables: { id: facilityId },
  });

  const billingQuery = useCompanyBillingOverviewQuery({
    variables: { id: facilityId },
  });

  const [updateFacility] = useUpdateFacilityMutation();
  const [updateFacilitySpecialBillingStatus] =
    useUpdateFacilitySpecialBillingStatusMutation();
  const [updateFacilityBookingConfig] =
    useUpdateFacilityBookingConfigMutation();

  const handleSubmit = async (input: HoursAndAboutInput) => {
    updateIsSavingForm(true);
    const {
      about,
      isHSSAffiliate,
      inPersonHoursOfOperation,
      telehealthHoursOfOperation,
      logoUrl,
      avatarUrl,
    } = input;

    // in here we need to:

    // updateClinic

    try {
      await updateFacility({
        variables: {
          input: {
            clinicId: facilityId,
            about: about,
          },
        },
      });

      // update HSS status but only if company is allowed to add HSS Clinics
      if (
        billingQuery?.data?.company?.billingOverview?.isAllowedToAddHSSClinics
      ) {
        await updateFacilitySpecialBillingStatus({
          variables: {
            input: {
              clinicId: facilityId,
              isHSSAffiliate: isHSSAffiliate ?? false,
              isOnTraditionalPilot: false,
            },
          },
        });
      }

      // update in person and telehealth hours
      await updateFacilityBookingConfig({
        variables: {
          input: {
            clinicId: facilityId,
            hours: daysHoursOfOperationSubmitValues(
              inPersonHoursOfOperation.formattedOpenCloseDaysHours
            ),
            videoHours: daysHoursOfOperationSubmitValues(
              telehealthHoursOfOperation.formattedOpenCloseDaysHours
            ),
          },
        },
      });

      // get the token for the image requests

      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const endpoint =
        window.__RUNTIME_CONFIG__.REACT_APP_API_ENDPOINT ??
        'https://api.development.betterpt.com/v0/api';

      // POST request to upload the logo image for the facility
      if (logoUrl) {
        await axios({
          method: 'POST',
          url: `${endpoint}/clinics/${facilityId}/logo`,
          headers: {
            authorization: token,
            'Content-Type': 'application/json',
          },
          data: {
            file: logoUrl,
          },
        });
      }

      // POST request to upload the avatar image for the facility

      if (avatarUrl) {
        await axios({
          method: 'POST',
          url: `${endpoint}/clinics/${facilityId}/avatar`,
          headers: {
            authorization: token,
            'Content-Type': 'application/json',
          },
          data: {
            file: avatarUrl,
          },
        });
      }

      await facilityQuery.refetch();

      updateIsSavingForm(false);
      history.push(`/facilities/${facilityId}`, {
        activateClinicDialogOpen: true,
      });
    } catch (e) {
      updateIsSavingForm(false);
      handleError(e);
    }
  };

  return { handleSubmit, isSaving: isSavingForm };
};

export default useHandleSubmit;
