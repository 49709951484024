import React from 'react';

//helpers
import { format, utcToZonedTime } from 'date-fns-tz';

// hooks
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';
import {
  useInPersonAppointmentDetailsQuery,
  useVideoAppointmentDetailsQuery,
} from '../../../../../generated/graphql';

// components
import { Button, colorValues } from '@betterpt/better-components';
import { GeneralizedEmployeePicker } from '../EmployeePicker';
import ThemedTextField from '../../../../Shared/ThemedTextField';

//styles
import {
  buttonStyle,
  DialogBody,
  H1,
  Row,
} from './ConfirmOrReschedulePage.style';

type Props = {
  handleConfirmSubmit: () => void;
  closeDialog: () => void;
  saving: boolean;
  employee: {
    value: string;
    updateValue: (id: string) => void;
  };
  appointmentId?: string;
  telehealth?: boolean;
};

const ConfirmPage = ({
  handleConfirmSubmit,
  closeDialog,
  saving,
  employee,
  appointmentId,
  telehealth,
}: Props) => {
  const { primaryColor, employeeSingularAlias } = useRemoteConfigAliases();

  const inPersonAppointmentQuery = useInPersonAppointmentDetailsQuery({
    variables: { id: appointmentId! },
    skip: !appointmentId || telehealth,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const telehealthAppointmentQuery = useVideoAppointmentDetailsQuery({
    variables: { id: appointmentId! },
    skip: !appointmentId || !telehealth,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const loading =
    inPersonAppointmentQuery.loading || telehealthAppointmentQuery.loading;
  const facilityId = telehealth
    ? telehealthAppointmentQuery.data?.videoAppointment?.clinic?.id
    : inPersonAppointmentQuery.data?.appointment?.clinic?.id;
  const appointment = telehealth
    ? telehealthAppointmentQuery.data?.videoAppointment
    : inPersonAppointmentQuery.data?.appointment;
  const appointmentTime = new Date(appointment?.startTime ?? new Date());
  const timeZone =
    (appointment as any)?.clinic?.timeZone ??
    (appointment as any)?.timeZone ??
    'America/New_York';
  const formattedDate = loading
    ? 'loading...'
    : format(utcToZonedTime(appointmentTime, timeZone), 'MM/dd/yyyy');
  const formattedTime = loading
    ? 'loading...'
    : format(utcToZonedTime(appointmentTime, timeZone), 'h:mm a');

  return (
    <DialogBody>
      <H1>Confirm Appointment</H1>
      <p className='Body' style={{ marginBottom: '24px' }}>
        Please add the email of the {employeeSingularAlias.toLowerCase()} who
        will be attending this session.
        <br />
        <br />
        By clicking {`"Confirm${
          telehealth ? ' & Send Telehealth Link' : ''
        },"`}{' '}
        you acknowledge that you have contacted all parties involved and that
        they{' '}
        {telehealth
          ? 'can make it to the telehealth appointment at the following time:'
          : 'agree to the appointment at the time listed below.'}
      </p>

      <Row style={{ justifyContent: 'space-between' }}>
        <ThemedTextField
          disabled
          id='confirm-field-date'
          label='Appointment Date'
          style={{ width: '45%' }}
          value={formattedDate}
          inputProps={{
            'data-testid': 'confirm-field-date',
          }}
        />
        <ThemedTextField
          disabled
          id='confirm-field-time'
          label='Appointment Time'
          style={{ width: '45%' }}
          value={formattedTime}
          inputProps={{
            'data-testid': 'confirm-field-time',
          }}
        />
      </Row>
      <GeneralizedEmployeePicker
        employee={employee}
        facilityId={facilityId}
        telehealth={telehealth}
      />
      <Button
        fullWidth
        size='large'
        color='transparent'
        style={buttonStyle(primaryColor)}
        onClick={handleConfirmSubmit}
        disabled={!employee?.value}
        loading={saving}
        data-cy='confirm-appointment-button'
      >
        CONFIRM {telehealth ? 'AND SEND TELEHEALTH LINK' : 'APPOINTMENT TIME'}
      </Button>
      <Button
        fullWidth
        size='large'
        color='transparent'
        style={buttonStyle(colorValues.emptiness)}
        onClick={closeDialog}
      >
        CANCEL
      </Button>
    </DialogBody>
  );
};

export default ConfirmPage;
