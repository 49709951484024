import * as React from 'react';

//components
import { ClickAwayListener } from '@material-ui/core';

//assets
import IcClose from '../Icons/IcClose';
import IcInfoOutline from '../Icons/IcInfoOutline';

//styles
import { colorValues } from '../helpers/colors';
import {
  closeButtonStyle,
  InfoBoxArrow,
  InfoBoxContent,
  InfoBoxIcon,
  InfoBoxMain,
} from './style';

type Props = {
  children: any;
  height: number;
  width: number;
  openWith?: 'hover' | 'click';
  isOpen?: boolean;
  toggleBox?: (open: boolean) => void;
  icon?: React.ReactNode;
  arrowStyle?: any;
  boxStyle?: any;
  iconStyle?: any;
  mainStyle?: any;
  arrowSize?: number;
  padding?: string | number;
  iconHeight?: number;
  iconWidth?: number;
  position?: 'left' | 'right' | 'top' | 'bottom' | undefined | null;
  fixedPlacement?: boolean;
  fullWidth?: boolean;
  testId?: string;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const InfoBox = ({
  children,
  icon,
  mainStyle,
  iconStyle,
  boxStyle,
  arrowStyle,
  arrowSize,
  padding = 10,
  openWith,
  isOpen,
  toggleBox,
  height,
  width,
  iconHeight = 24,
  iconWidth = 24,
  position,
  fixedPlacement,
  fullWidth,
  testId,
  ...rest
}: Props) => {
  const openWithClick = !openWith || openWith === 'click';
  const [internalOpen, toggleInternalOpen] = React.useState(isOpen ?? false);
  const [closeHovering, toggleCloseHovering] = React.useState(false);

  const bestArrowSize = arrowSize || 20;
  const halfWidth = width / 2;
  const halfHeight = height / 2;
  const computedPadding =
    typeof padding === 'number' ? padding + 'px' : padding;
  const mainPadding = typeof padding === 'number' ? padding : 10;

  const alignTop = position === 'top';
  const alignLeft = position === 'left';
  const alignRight = position === 'right';
  const alignBottom = position === 'bottom' || !position;

  const iconPlusPaddingAndBuffer = iconWidth + mainPadding * 2 + 10;
  const boxSizePlusPaddingAndIcon = (
    boxDimension: number,
    iconDimension?: number
  ) => -boxDimension + mainPadding + (iconDimension ? iconDimension / 2 : 0);

  const boxTop = alignBottom
    ? 65
    : boxSizePlusPaddingAndIcon(halfHeight, iconHeight);
  const boxRight = alignLeft ? iconPlusPaddingAndBuffer : 65;
  const boxLeft =
    alignBottom || alignTop
      ? boxSizePlusPaddingAndIcon(halfWidth, iconWidth)
      : alignRight
      ? iconPlusPaddingAndBuffer
      : 65;
  const boxBottom = 65;

  const arrowVertical =
    alignLeft || alignRight ? halfHeight - bestArrowSize / 2 : -8;
  const arrowHorizontal =
    alignLeft || alignRight
      ? -8
      : alignBottom || alignTop
      ? halfWidth - bestArrowSize / 2
      : 0;

  React.useEffect(() => {
    return () => {
      toggleInternalOpen(false);
      toggleCloseHovering(false);
    };
  }, []);

  return (
    <span style={fullWidth ? { width: '100%' } : {}}>
      <ClickAwayListener
        onClickAway={() =>
          toggleBox ? toggleBox(false) : toggleInternalOpen(false)
        }
      >
        <InfoBoxMain
          fixedPlacement={fixedPlacement}
          fullWidth={fullWidth}
          style={mainStyle || { top: 0, right: 0 }}
        >
          <InfoBoxIcon
            padding={computedPadding}
            role='button'
            tabIndex={0}
            aria-label={`${openWith || 'click'} to toggle this box`}
            data-testid={`${testId || ''}-infobox-icon`}
            data-cy={`${testId || ''}-infobox-icon`}
            style={
              fullWidth ? { width: '100%', ...iconStyle } : { ...iconStyle, position:"relative", zIndex:1 }
            }
            onMouseEnter={() => !openWithClick && toggleInternalOpen(true)}
            onMouseLeave={() => !openWithClick && toggleInternalOpen(false)}
            onClick={() => {
              if (openWithClick) {
                toggleBox
                  ? toggleBox(!isOpen)
                  : toggleInternalOpen(!internalOpen);
              }
            }}
            onKeyDown={(e) => {
              if (e.key && e.key === 'Enter' && openWithClick) {
                toggleBox
                  ? toggleBox(!isOpen)
                  : toggleInternalOpen(!internalOpen);
              }
            }}
            {...rest}
          >
            {icon || <IcInfoOutline color={colorValues.concretejungle} />}
          </InfoBoxIcon>
          <InfoBoxContent
            className='boxContent'
            alignTop={alignTop}
            alignLeft={alignLeft}
            boxTop={boxTop}
            boxBottom={boxBottom}
            boxLeft={boxLeft}
            boxRight={boxRight}
            isOpen={isOpen || internalOpen}
            data-testid={`${testId}-infobox-content-${
              isOpen || internalOpen ? 'open' : 'closed'
            }`}
            style={{
              width: width,
              maxHeight: height,
              ...(boxStyle || {}),
            }}
          >
            {children}
            {openWithClick && (
              <IcClose
                role='button'
                tabIndex={0}
                aria-label='close'
                color={
                  closeHovering
                    ? colorValues.cauldronblack
                    : colorValues.cityblock
                }
                onMouseEnter={() => toggleCloseHovering(true)}
                onMouseLeave={() => toggleCloseHovering(false)}
                onClick={() =>
                  toggleBox ? toggleBox(false) : toggleInternalOpen(false)
                }
                onKeyDown={(e) => {
                  if (e.key && e.key === 'Enter') {
                    toggleBox ? toggleBox(false) : toggleInternalOpen(false);
                  }
                }}
                style={closeButtonStyle}
              />
            )}
            <InfoBoxArrow
              bestArrowSize={bestArrowSize}
              alignTop={alignTop}
              alignLeft={alignLeft}
              arrowVertical={arrowVertical}
              arrowHorizontal={arrowHorizontal}
              style={{ ...(arrowStyle || {}) }}
            />
          </InfoBoxContent>
        </InfoBoxMain>
      </ClickAwayListener>
    </span>
  );
};

export default InfoBox;
