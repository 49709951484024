import React from 'react';

//types

// components
import { Dialog } from '@material-ui/core';
import { CloseButton } from '@betterpt/better-components';
import SlideTransition from '../../SlideTransition';

//styles
import { pinnedDialogStyle } from '../AppointmentTypes.style';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ApptDialogWrapper: React.FC<React.PropsWithChildren<Props>> = ({ isOpen, onClose, children }) => {
  return (
    <Dialog
      fullScreen
      open={isOpen}
      style={pinnedDialogStyle}
      TransitionComponent={SlideTransition}
      PaperProps={{ style: { borderRadius: 5, position: 'relative' } }}
      onClose={onClose}
    >
      {children}
      <CloseButton onClose={onClose} />
    </Dialog>
  );
};

export default ApptDialogWrapper;
