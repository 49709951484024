import * as React from 'react';

// types
import {
  PreferredContactMethod,
  useCompanyBookingFlowQuery,
  useMeQuery,
} from '../../../../generated/graphql';

// helpers
import { normalizePhone } from '../../../../helpers';

// components
import { GridCard } from '../../../Shared/GridCard';
import { InfoHeader } from '../../Appointments/InPersonAppointments/InPersonAppointmentsList/components/InPersonAppointmentCard/InPersonAppointmentCard.style';
import CardTextField from '../../Appointments/Shared/CardTextField';

// style
import * as styles from './PatientDetail.style';

export type MappedPatientInfoData = {
  firstName: string;
  lastName: string;
  gender: string;
  birthday: string;
  email: string;
  phone: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  preferredContactMethod: PreferredContactMethod | null | undefined;
};

type Props = {
  data: MappedPatientInfoData;
  showAllData?: boolean;
};

const PatientInfo = ({ data, showAllData }: Props) => {
  const {
    firstName,
    lastName,
    gender,
    birthday,
    email,
    phone,
    address,
    address2,
    city,
    state,
    zipcode,
    preferredContactMethod,
  } = data;
  const meQuery = useMeQuery();

  const companyBookingFlowQuery = useCompanyBookingFlowQuery({
    variables: {
      id: meQuery.data?.me?.company?.id || '0',
    },
    skip: !meQuery.data?.me?.company?.id,
  });

  const askPatientAddress =
    companyBookingFlowQuery.data?.company?.bookingFlow?.askPatientAddress;
  const askPreferredPatientContact =
    companyBookingFlowQuery.data?.company?.bookingFlow
      ?.askPreferredPatientContact;

  const phoneLabel = `Patient Phone${
    askPreferredPatientContact && preferredContactMethod === 'phone'
      ? ' (Preferred Contact Method)'
      : ''
  }`;

  const emailLabel = `Patient Email${
    askPreferredPatientContact && preferredContactMethod === 'email'
      ? ' (Preferred Contact Method)'
      : ''
  }`;

  return (
    <GridCard style={{ ...styles.cardStyle, width: '48%' }} shadow='none'>
      <InfoHeader>
        <p>Patient Info</p>
      </InfoHeader>
      <styles.CardContents>
        <styles.TextFieldRow>
          <CardTextField
            value={firstName}
            label='Patient First Name'
            data-cy='patient-info-firstName'
          />
          <CardTextField
            value={lastName}
            label='Patient Last Name'
            data-cy='patient-info-lastName'
          />
        </styles.TextFieldRow>
        <styles.TextFieldRow>
          <CardTextField
            value={gender === 'F' ? 'Female' : 'Male'}
            label='Sex Assigned at Birth'
            data-cy='patient-info-gender'
          />
          <CardTextField
            value={birthday}
            label='Patient DOB'
            data-cy='patient-info-birthday'
          />
        </styles.TextFieldRow>
        <styles.TextFieldRow>
          <CardTextField
            value={email}
            label={emailLabel}
            data-cy='patient-info-email'
          />
          <CardTextField
            value={normalizePhone(phone ?? '') ?? 'Not Provided'}
            label={phoneLabel}
            data-cy='patient-info-phone'
          />
        </styles.TextFieldRow>
        {!!(showAllData || askPatientAddress) && (
          <>
            <CardTextField
              value={address}
              label='Address'
              fullWidth
              data-cy='patient-info-address'
            />
            {address2 && (
              <CardTextField
                value={address2}
                label='Address Line 2'
                fullWidth
                data-cy='patient-info-address2'
              />
            )}
            <CardTextField
              value={city}
              label='City'
              fullWidth
              data-cy='patient-info-city'
            />
            <styles.TextFieldRow>
              <CardTextField
                value={state}
                label='State'
                data-cy='patient-info-state'
              />
              <CardTextField
                value={zipcode}
                label='Zip'
                data-cy='patient-info-zipcode'
              />
            </styles.TextFieldRow>
          </>
        )}
      </styles.CardContents>
    </GridCard>
  );
};

export default PatientInfo;
