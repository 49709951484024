import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

export const CardContents = styled.div`
  padding: 15px 15px 20px 40px;
`;

export const cardStyle = {
    marginRight: '25px',
    width: '48%',
    position: 'relative' as 'relative',
    borderRadius: '5px',
    margin: '20px 28px',
    border: `1px solid ${colorValues.dust}`,
    boxShadow: 'none !important',
};

export const TextFieldRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InfoHeader = styled.div`
background-color: ${colorValues.earlysteam};
padding: 10px 10px 10px 39px;
border-bottom: solid 1px ${colorValues.concretejungle};
border-radius: 5px 5px 0px 0px;
p {
  color: ${colorValues.charcoalgray};
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}
a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  p {
    color: ${colorValues.frenchblue};
    text-decoration: underline;
  }
}
`;