import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='54'
    height='54'
    viewBox='0 0 54 54'
    {...rest}
  >
    <defs>
      <path
        id='8lam3cah4a'
        d='M42.75 20.25h-9V6.75h-13.5v13.5h-9L27 36l15.75-15.75zM11.25 40.5V45h31.5v-4.5h-31.5z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0L54 0 54 54 0 54z' />
      <mask id='uedgnb2swb' fill='#fff'>
        <use xlinkHref='#8lam3cah4a' />
      </mask>
      <use fill='#333' fillRule='nonzero' xlinkHref='#8lam3cah4a' />
      <g fill={color ?? '#12A7A3'} mask='url(#uedgnb2swb)'>
        <path d='M0 0H54V54H0z' />
      </g>
    </g>
  </svg>
);
