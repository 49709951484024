import * as React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...rest}
  >
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0L24 0 24 24 0 24z' />
      <path
        fill={color ?? '#333'}
        fillRule='nonzero'
        d='M20 6H4c-1.11 0-1.99.779-1.99 1.75L2 18.25c0 .971.89 1.75 2 1.75h16c1.11 0 2-.779 2-1.75V7.75C22 6.779 21.11 6 20 6zm0 12H4V8h16v10z'
      />
      <path
        fill={color ?? '#333'}
        fillRule='nonzero'
        d='M7 16h2v-2H7v2zm0-4h2v-2H7v2zM11 16h2v-2h-2v2zm0-4h2v-2h-2v2zM15 16h2v-2h-2v2zm0-4h2v-2h-2v2z'
      />
    </g>
  </svg>
);
