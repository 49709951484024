import React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/remote-config';

export const RemoteConfigContext = React.createContext<{
  info: firebase.remoteConfig.RemoteConfig | undefined;
  loading: boolean;
} | null>(null);

export const RemoteConfigProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [remoteConfig, updateRemoteConfig] = React.useState<firebase.remoteConfig.RemoteConfig>();
  const [loading, updateLoading] = React.useState(true);

  React.useEffect(() => {
    if (!remoteConfig) {
      updateLoading(true);
      const remoteConfigInstance = firebase.remoteConfig();

      remoteConfigInstance.settings = {
        minimumFetchIntervalMillis: 43200000,
        fetchTimeoutMillis: 60000,
      };

      remoteConfigInstance.defaultConfig = {
        organizationName: '',
        employeesAlias: '{"plural":"Employees","singular":"Employee"}',
        facilitiesAlias: '{"plural":"Facilities","singular":"Facility"}',
        patientsAlias: '{"plural":"Patients","singular":"Patient"}',
        alternateEmployeeCopy: `{
          "primary": {
            "plural": "Employees 1",
            "singular": "Employee 1"
          },
          "secondary": {
            "plural": "Employees 2",
            "singular": "Employee 2"
          }
        }`,
        loginPageCopy: `{
        "header": "BetterAccess is Here",
        "subHeader": "Our new and improved online platform to help healthcare providers manage all of their scheduling needs",
        "showButton": true,
        "companyLogo": "https://betterpt-external-assets.s3.amazonaws.com/img/logo-betterhealthcare.svg"
      }`,
        hideSignupPage: false,
      };

      remoteConfigInstance.fetchAndActivate().then(() => {
        updateRemoteConfig(remoteConfigInstance);
        updateLoading(false);
      });
    }
  }, [remoteConfig]);

  const values = {
    info: remoteConfig,
    loading,
  };

  return <RemoteConfigContext.Provider value={values}>{children}</RemoteConfigContext.Provider>;
};

// primaryColor
// secondaryColor
// tertiaryColor
// organizationLogoUrl
// organizationSmallLogoUrl
