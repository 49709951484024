import * as React from 'react';

//types
import { VideoAppointmentFiltersContextType } from '../../../context/videoAppointmentFilters';
import { InPersonAppointmentFiltersContextType } from '../../../context/inPersonAppointmentFilters';
import { TimeDisplayValue } from '../../../helpers/telehealthAppointmentsFilterHelper';

// helpers
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  parse,
} from 'date-fns';

// components
import { Filter } from '@betterpt/better-components';
import DatePickerDialog from './DatePickerDialog';

//styles
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './DateFilter.css';

type Props = {
  filters:
    | VideoAppointmentFiltersContextType
    | InPersonAppointmentFiltersContextType;
  primaryColor?: string;
  isTelehealth: boolean;
};

const DateFilter: React.FC<React.PropsWithChildren<Props>> = ({
  filters,
  primaryColor,
  isTelehealth,
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState<boolean>(
    false
  );

  const formatStartEndDate = (dateString: string) =>
    format(parse(dateString, 'yyyy-MM-dd', new Date()), 'iii M/d');

  const handleRadioSelect = (
    start: Date,
    end: Date,
    display: TimeDisplayValue
  ) => {
    filters.start.update(format(start, 'yyyy-MM-dd'));
    filters.end.update(format(end, 'yyyy-MM-dd'));
    filters.timeDisplayValue.update(display);
  };

  const dateFilterOptions = [
    {
      label: 'Today',
      fullTitle: `all appts. ${filters.timeDisplayValue.value} (${formatStartEndDate(
        filters.start.value
      )})`,
      selected: filters.timeDisplayValue.value === TimeDisplayValue.Today,
      callback: () =>
        handleRadioSelect(new Date(), new Date(), TimeDisplayValue.Today),
    },
    {
      label: 'This week',
      selected: filters.timeDisplayValue.value === TimeDisplayValue.Week,
      fullTitle: `all appts. ${filters.timeDisplayValue.value}`,
      callback: () =>
        handleRadioSelect(
          startOfWeek(new Date()),
          endOfWeek(new Date()),
          TimeDisplayValue.Week
        ),
    },
    {
      label: 'This month',
      selected: filters.timeDisplayValue.value === TimeDisplayValue.Month,
      fullTitle: `all appts. ${filters.timeDisplayValue.value}`,
      callback: () =>
        handleRadioSelect(
          startOfMonth(new Date()),
          endOfMonth(new Date()),
          TimeDisplayValue.Month
        ),
    },
    {
      label: 'This year',
      selected: filters.timeDisplayValue.value === TimeDisplayValue.Year,
      fullTitle: `all appts. ${filters.timeDisplayValue.value}`,
      callback: () =>
        handleRadioSelect(
          startOfYear(new Date()),
          endOfYear(new Date()),
          TimeDisplayValue.Year
        ),
    },
    {
      label: 'Choose date range',
      fullTitle: `all appts. ${formatStartEndDate(
        filters.start.value
      )} - ${formatStartEndDate(filters.end.value)}`,
      selected: filters.timeDisplayValue.value === TimeDisplayValue.Range,
      callback: () => {
        setIsDatePickerOpen(true);
        filters.timeDisplayValue.update(TimeDisplayValue.Range);
      },
    },
  ];

  const wideLabel =
    filters.timeDisplayValue.value === TimeDisplayValue.Range ||
    filters.timeDisplayValue.value === TimeDisplayValue.Today;

  return (
    <>
      <DatePickerDialog
        isDatePickerOpen={isDatePickerOpen}
        setIsDatePickerOpen={setIsDatePickerOpen}
        telehealth={isTelehealth}
        isCreatedDateFilter={false}
      />
      <Filter
        type='radio'
        name='time frame'
        options={dateFilterOptions}
        overrideButtonColor={primaryColor}
        style={{
          box: { left: 15, top: 65 },
          arrow: { left: 55 },
          labelPadding: wideLabel ? '0 10px 0 20px' : undefined,
          buttonMargin: '12px 2px 0 0',
        }}
      />
    </>
  );
};

export default DateFilter;
