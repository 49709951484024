import * as React from 'react';
import FormField from '../../../../../Shared/FormField';
import { TextFieldState } from '../../../../../../hooks/useTextFieldState';
import states from '../../../../../../constants/states';

type Props = {
  address: TextFieldState;
  address2: TextFieldState;
  city: TextFieldState;
  state: TextFieldState;
  zipcode: TextFieldState;
  phone: TextFieldState;
  email: TextFieldState;
  isFormDisabled: boolean;
  shouldDisableYextFields: boolean;
};

const TraditionalAddressFields: React.FC<React.PropsWithChildren<Props>> = ({
  address,
  address2,
  city,
  state,
  zipcode,
  phone,
  email,
  isFormDisabled,
  shouldDisableYextFields,
}) => {
  return (
    <>
      <FormField
        style={{ margin: '20px 0px' }}
        label='Address'
        formHandler={address}
        id='addressInput'
        disabled={shouldDisableYextFields || isFormDisabled}
        fullWidth
        inputProps={{
          maxLength: 255,
        }}
      />
      <FormField
        style={{ margin: '20px 0px' }}
        label='Address Line 2'
        formHandler={address2}
        placeholder='Enter floor, suite number, etc. (optional)'
        id='address2Input'
        disabled={shouldDisableYextFields || isFormDisabled}
        fullWidth
        inputProps={{
          maxLength: 255,
        }}
      />
      <FormField
        style={{ margin: '20px 0px' }}
        label='City'
        formHandler={city}
        id='cityInput'
        disabled={shouldDisableYextFields || isFormDisabled}
        fullWidth
        inputProps={{
          maxLength: 255,
        }}
      />
      <div
        style={{
          margin: '20px 0px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <FormField
          style={{ width: '48%', marginBottom: '0px' }}
          label='State'
          formHandler={state}
          id='stateInput'
          disabled={shouldDisableYextFields || isFormDisabled}
          fullWidth
          select
          SelectProps={{ native: true }}
        >
          {states.map((state) => (
            <option key={state.value} value={state.value}>
              {state.text}
            </option>
          ))}
        </FormField>
        <FormField
          style={{ width: '48%', marginBottom: '0px' }}
          label='Zipcode'
          formHandler={zipcode}
          id='zipcodeInput'
          disabled={shouldDisableYextFields || isFormDisabled}
          fullWidth
        />
      </div>
      <FormField
        style={{ margin: '20px 0px' }}
        label='Phone Number'
        formHandler={phone}
        id='phoneInput'
        disabled={shouldDisableYextFields || isFormDisabled}
        fullWidth
      />
      <FormField
        style={{ margin: '20px 0px' }}
        label='Contact Email'
        formHandler={email}
        id='emailInput'
        disabled={isFormDisabled}
        fullWidth
      />
    </>
  );
};

export default TraditionalAddressFields;
