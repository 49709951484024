import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { colorValues } from '../helpers/colors';
import { changeHexColor } from '../helpers/functions';

export const ActionButton = styled.div<{ open: boolean; color?: string }>`
  height: 28px;
  width: 40px;
  min-width: 20px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: ${({ open, color }) => {
    if (open) {
      return color
        ? changeHexColor(color || colorValues.dust, -15)
        : colorValues.coneyislandsidewalk;
    } else {
      return color || colorValues.dust;
    }
  }};
  &:hover,
  &:focus {
    background-color: ${({ color }) =>
      color
        ? changeHexColor(color || colorValues.dust, -15)
        : colorValues.coneyislandsidewalk};
  }
`;

export const RadioLabel = styled.p`
  font-size: 14px;
  font-weight: normal !important;
  margin: 0 !important;
  margin-left: 15px !important;
  padding: 12px 0 !important;
`;

export const radioStyle = css`
color: #333333 !important;
&:hover {
  background-color: rgba(51, 51, 51, 0.04) !important;
}
.MuiRadio-colorSecondary {
  color: #333333 !important;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: #333333 !important;
}
.MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: rgba(51, 51, 51, 0.04) !important;
}
.MuiIconButton-colorSecondary:hover {
  background-color: rgba(51, 51, 51, 0.04) !important;
}
}`;
