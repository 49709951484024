import { format, parse } from 'date-fns';
import { CompanyAppointmentType, RecurringSeries } from '../generated/graphql';

// Checks for values of existing keys and compares to new object
export const hasValueChangedInObject = (object: any, newObject: any) => {
  let isSame = true;
  Object.keys(object).forEach((key) => {
    if (
      (newObject as any)[key] &&
      (newObject as any)[key] !== (object as any)[key]
    ) {
      isSame = false;
      return false;
    }
  });
  return !isSame;
};

//adds approriate "a" vs "an" article
export const addIndefiniteArticle = (word: string) => {
  const startsWithVowel = /^[aeiou]/i.test(word || '');
  return `a${startsWithVowel ? 'n' : ''} ${word}`;
};

export const parseAddressFromString = (formattedAddress: string) => {
  const [address, city, stateAndZip] = formattedAddress.split(',');
  const [state, zipcode] = stateAndZip?.trim().split(' ');
  return {
    address: address?.trim(),
    city: city?.trim(),
    state: state?.trim(),
    zipcode: zipcode?.trim(),
  };
};

const endsWithS = (name: string) =>
  name.charAt(name.length - 1).toLowerCase() === 's';

export const possessiveName = (rawName?: string | null) => {
  if (rawName) {
    return endsWithS(rawName) ? rawName + `'` : rawName + `'s`;
  }
};

export const pluralNoun = (noun?: string | null) => {
  if (noun) {
    const endsWithY = noun.charAt(noun.length - 1).toLowerCase() === 'y';
    if (endsWithY) {
      return noun.slice(0, noun.length - 1) + 'ies';
    }
    return endsWithS(noun) ? noun + `es` : noun + `s`;
  } else return 'Items';
};

// functions for recurring interval copy
export const generateIntervalCopy = (recurringSeries: RecurringSeries) =>
  recurringSeries.recurringInterval === 1
    ? ''
    : recurringSeries.recurringInterval;

export const generateFrequencyCopy = (recurringSeries: RecurringSeries) =>
  recurringSeries.recurringInterval === 1
    ? recurringSeries.recurringFrequency
    : recurringSeries.recurringFrequency + 's';

export const generateFormattedEndDate = (recurringSeries: RecurringSeries) =>
  recurringSeries.endDate
    ? format(
        parse(recurringSeries.endDate, 'yyyy-MM-dd', new Date()),
        'MM/dd/yyyy'
      )
    : '';

export const parseAppointmentTypeField = ({
  apptType,
  wasRequested,
  facilitiesSingularAlias,
  isInitialEval,
  isTelehealth,
}: {
  facilitiesSingularAlias: string;
  apptType?: Partial<CompanyAppointmentType> | null;
  wasRequested?: boolean | null;
  isInitialEval?: boolean | null;
  isTelehealth?: boolean | null;
}) => {
  if (apptType?.displayName) {
    return apptType.displayName;
  } else if (isTelehealth) {
    return 'Telehealth';
  } else if (!wasRequested) {
    return `Booked by ${facilitiesSingularAlias.toLowerCase()}`;
  } else if (isInitialEval) {
    return 'Initial Evaluation';
  } else {
    return 'Follow Up';
  }
};