import React from 'react';

//helpers
import { colorValues } from '@betterpt/better-components';

//hooks
import { useParams } from 'react-router-dom';
import useSnackbar from '../../../../hooks/useSnackbar';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import useAppointmentTypeDetailOperations from '../hooks/useAppointmentTypeDetailOperations';
import useHandleError from '../../../../hooks/useHandleError';

//components
import { TableAction } from '@betterpt/better-components';

interface Props {
  employeeId: string;
  canUserRemoveProviders: boolean;
  firstInList: boolean;
  openStatus: {
    value: boolean;
    update: (isOpen: boolean) => void;
  };
}

const ProviderAction = ({
  employeeId,
  canUserRemoveProviders,
  firstInList,
  openStatus,
}: Props) => {
  const snackbar = useSnackbar();
  const { appointmentTypeId } = useParams<{ appointmentTypeId: string }>();
  const { employeeSingularAlias } = useRemoteConfigAliases();
  const handleError = useHandleError();
  const {
    commands: { removeProviderFromAppointmentType },
  } = useAppointmentTypeDetailOperations();
  const [saving, updateSaving] = React.useState(false);

  const handleRemoveProvider = async () => {
    try {
      updateSaving(true);
      await removeProviderFromAppointmentType({
        employeeId,
        appointmentTypeId,
      });
      snackbar?.setSuccessMessage(
        `Successfully removed ${employeeSingularAlias.toLowerCase()} from this appointment type.`
      );
      updateSaving(false);
    } catch (e) {
      updateSaving(false);
      handleError(e);
    }
  };

  const actions = [
    {
      label: canUserRemoveProviders
        ? 'Remove from this appointment type'
        : `You do not have permission to edit this ${employeeSingularAlias.toLowerCase()}`,
      callback: handleRemoveProvider,
      disabled: !canUserRemoveProviders,
      color: canUserRemoveProviders ? undefined : colorValues.messyketchup,
    },
  ];

  const tableActionStyles = (() => {
    if (firstInList) {
      return {
        boxStyle: { top: '-60px' },
        arrowStyle: { top: 70 },
      };
    } else return {};
  })();

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <TableAction
        options={actions}
        title='REMOVE FROM APPT. TYPE'
        buttonColor={colorValues.messyketchup}
        loading={saving}
        openStatus={openStatus}
        {...tableActionStyles}
      />
    </div>
  );
};

export default ProviderAction;
