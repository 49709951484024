import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='54'
    height='54'
    viewBox='0 0 54 54'
    {...rest}
  >
    <defs>
      <path
        id='8grwj31hsa'
        d='M42.75 6.75l-9.405.003C32.4 4.14 29.925 2.25 27 2.25s-5.4 1.891-6.345 4.503H11.25c-2.475 0-4.5 2.026-4.5 4.503V42.71c0 2.477 2.025 4.503 4.5 4.503h31.5c2.475 0 4.5-2.026 4.5-4.503V11.256c0-2.477-2.025-4.503-4.5-4.506zM27 6.75c1.238 0 2.25 1.013 2.25 2.25 0 1.238-1.012 2.25-2.25 2.25S24.75 10.238 24.75 9c0-1.237 1.012-2.25 2.25-2.25zM42.75 42.8h-31.5V11.25h4.5V18h22.5v-6.75h4.5V42.8zm-4.5-11.3V36h-22.5v-4.5h22.5zm0-9V27h-22.5v-4.5h22.5z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0L54 0 54 54 0 54z' />
      <mask id='nk2lah9c2b' fill='#fff'>
        <use xlinkHref='#8grwj31hsa' />
      </mask>
      <use fill='#333' fillRule='nonzero' xlinkHref='#8grwj31hsa' />
      <g fill={color ?? '#12A7A3'} mask='url(#nk2lah9c2b)'>
        <path d='M0 0H54V54H0z' />
      </g>
    </g>
  </svg>
);
