import * as React from 'react';

const TermsOfUse = () => {
  return (
    <div>
      <div className='section'>
        <p className='p1'>&nbsp;</p>
        <p className='p2'>
          <span className='s2'>
            <strong>General Terms and Conditions</strong>
          </span>
        </p>
        <p className='p3'>&nbsp;</p>
        <p className='p2'>
          <span className='s2'>Updated November 8, 2018</span>
        </p>
        <p className='p4'>
          <span className='s2'>&nbsp;</span>
        </p>
        <p className='p4'>
          <span className='s1'>
            These Terms and Conditions, along with the Business Associate
            Addendum and Order Form (each defined below), are together referred
            to herein as the &ldquo;<strong>Agreement.</strong>&rdquo; This
            Agreement governs use of the Services that are being offered by
            BetterPT, Inc. (&ldquo;<strong>BetterPT</strong>
            &rdquo;, &ldquo;
            <strong>we</strong>&rdquo; and &ldquo;<strong>us</strong>&rdquo;) to
            the customer identified on the Order Form (&ldquo;
            <strong>Customer</strong>&rdquo;, &ldquo;<strong>you</strong>&rdquo;
            and &ldquo;<strong>your</strong>&rdquo;). This Agreement is
            effective upon the date that BetterPT makes the Services available
            to you pursuant to the Order Form (the &ldquo;
            <strong>Effective Date</strong>&rdquo;).&nbsp;
          </span>
        </p>
        <p className='p5'>&nbsp;</p>
        <p className='p4'>
          <span className='s2'>
            BY ACCEPTING THIS AGREEMENT, EITHER BY CLICKING BELOW INDICATING
            ACCEPTANCE OR BY EXECUTING AN ORDER FORM THAT REFERENCES THESE TERMS
            OF USE, OR BY USING THE BETTERPT SERVICES, YOU AGREE THAT YOU HAVE
            READ AND UNDERSTAND, AND AGREE TO, THE TERMS OF THIS AGREEMENT, AND
            THAT YOU HAVE THE AUTHORITY TO ENTER INTO THIS AGREEMENT, EITHER
            INDIVIDUALLY OR AS AN EMPLOYEE OR AGENT OF THE ENTITY ON WHOSE
            BEHALF YOU ARE ACTING.
            <span className='Apple-converted-space'>&nbsp; </span>If you do not
            accept this Agreement, you must click &ldquo;DECLINE&rdquo; below
            and not use or access the Services.&nbsp;
          </span>
        </p>
        <p className='p6'>
          <span className='s2'>&nbsp;</span>
        </p>
        <ol className='ol1'>
          <li className='li6'>
            <span className='s1'>
              <strong>Definitions.&nbsp;&nbsp;</strong>
            </span>
          </li>
        </ol>
        <p className='p4'>
          <span className='s1'>
            For the purposes of this Agreement, the terms set forth in this
            Section 1 have the meanings assigned to them below.
          </span>
        </p>
        <p className='p4'>
          <span className='s2'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>Authorized Workforce</strong>&rdquo; means those
            natural persons, including Providers, who are members of your
            Workforce and who you have identified and added in your BetterPT
            account as Users whom you authorize to access and use the Services
            on your behalf.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>Clinic</strong>&rdquo; means a professional
            corporation or other entity that is legally authorized to furnish
            Physical Therapy Treatments through its employed and/or contracted
            physical therapists.
          </span>
        </p>
        <p className='p8'>&nbsp;</p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>Clinic of Record</strong>&rdquo; has the meaning
            given in Section 3.1.1.
          </span>
        </p>
        <p className='p8'>&nbsp;</p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>Confidential Information</strong>&rdquo; means any
            information relating to our business, financial affairs, current or
            future products or technology, trade secrets, workforce, customers,
            or any other information that is treated or designated by us as
            confidential or proprietary, or would reasonably be viewed as
            confidential or proprietary, or as having value to our competitors.
            Confidential Information does not include information that we make
            publicly available or that becomes known to the general public other
            than as a result of a breach of an obligation by you. Confidential
            Information does not include individuals&rsquo; health information.
            Content that is not generally available to persons other than Users
            constitutes our Confidential Information.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>Content</strong>&rdquo; means all content, text,
            data, graphics, images, photographs, video, audio, information,
            suggestions, guidance, and other materials and information provided,
            made available or otherwise found through the Services.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>Credentials</strong>&rdquo; means any unique
            identifier, password, token, credential, any combination thereof, or
            other means we may utilize from time to time for authorizing access
            to all or any portion of the Services.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>De-Identified Health Information</strong>&rdquo;
            means health information that has been de-identified in accordance
            with HIPAA.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>De-Identified Information</strong>&rdquo; means,
            collectively, De-Identified Health Information and De-Identified
            Personal Information.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>De-Identified Personal Information</strong>&rdquo;
            means Personal Information from which a user&rsquo;s name and other
            unique identifiers have been removed, and from which the user cannot
            reasonably be identified.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>De-Identify</strong>,&rdquo; with respect to Personal
            Information, means to make such information into De-Identified
            Personal Information, and with respect to health information, means
            to make such health information into De-Identified Health
            Information.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>HIPAA</strong>&rdquo; means the administrative
            simplification provisions of the Health Insurance Portability and
            Accountability Act of 1996, and the regulations promulgated
            thereunder, as amended.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>Mobile App</strong>&rdquo; means the
            software-as-a-service and other applications made available by
            BetterPT to Users through a mobile device.
          </span>
        </p>
        <p className='p8'>&nbsp;</p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>Order Form</strong>&rdquo; means a BetterPT order
            form provided by BetterPT that describes the Services and executed
            by Customer.
          </span>
        </p>
        <p className='p8'>&nbsp;</p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>Patient</strong>&rdquo; means a patient under your
            care.&nbsp; &nbsp;
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>Personal Information</strong>&rdquo; means
            information that identifies you personally.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>Physical Therapy Treatment</strong>&rdquo; means the
            provision of physical therapy treatment and other related services
            to Patients who book their appointment for such services through the
            Services.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>Protected Health Information</strong>&rdquo; has the
            meaning given it in HIPAA.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>Provider</strong>&rdquo; means a physical therapist
            that is duly licensed to provide Physical Therapy Treatment in the
            state in which he or she furnishes said treatments.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>Services</strong>&rdquo; means the
            software-as-a-service offerings made available by BetterPT to you as
            identified in the Order Form, whether made available through
            websites and/or a Mobile App, and including, without limitation,
            appointment booking services and any other operations workflow
            solutions that we make available to you.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>User</strong>&rdquo; means (i) a natural person in
            your Authorized Workforce who has been authorized pursuant to this
            Agreement to access the Services on your behalf and (ii) any Patient
            that uses the Services.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>Workforce</strong>&rdquo; means a Clinic&rsquo;s
            employees, contractors, volunteers, trainees, and other persons
            whose conduct, in the performance of work for Clinic, is under the
            direct control of such Clinic, whether or not they are paid by the
            Clinic, including the Clinic&rsquo;s Providers.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>Your Health Information</strong>&rdquo; means health
            information pertaining to Patients that you or your Workforce inputs
            or uploads onto the Services or that we receive from your Patients,
            or our third party partners pursuant to the Business Associate
            Addendum.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>Your Information</strong>&rdquo; means information
            that you or your Workforce input or upload onto the Services,
            including Your Personal Information and Your Health Information.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p7'>
          <span className='s1'>
            &ldquo;<strong>Your Personal Information</strong>&rdquo; means
            Personal Information that you or your Workforce enters or uploads
            onto the Services.
          </span>
        </p>
        <p className='p7'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p9'>
          <span className='s1'>
            In addition, the words &ldquo;include,&rdquo; &ldquo;includes&rdquo;
            and &ldquo;including&rdquo; shall be deemed to be followed by the
            phrase &ldquo;without limitation.&rdquo; The word &ldquo;will&rdquo;
            shall be construed to have the same meaning and effect as the word
            &ldquo;shall.&rdquo; The words &ldquo;herein,&rdquo;
            &ldquo;hereof&rdquo; and &ldquo;hereunder,&rdquo; and words of
            similar import, shall be construed to refer to this Agreement. The
            headings used in this Agreement are used for convenience only and
            are not to be considered in construing or interpreting this
            Agreement.
          </span>
        </p>
        <ol className='ol1'>
          <li className='li6'>
            <span className='s1'>
              <strong>Grant of Right to Use the Services</strong>
            </span>
          </li>
          <ol className='ol1'>
            <li className='li6'>
              <span className='s1'>
                <strong>General.</strong> We grant to you and you accept a
                non-exclusive, personal, non-transferable (except as expressly
                permitted in Section 14.1), limited right to access and use the
                Services during the Term, subject to your full compliance with
                the terms and conditions set forth in this Agreement.{' '}
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Third-Party Services.</strong> The Services may include
                certain third-party software, services, and applications that
                may require that you enter into separate agreements with third
                parties. We may also make available optional services provided
                by third parties (&ldquo;Third-Party Services&rdquo;). You will
                comply with and, upon request, execute, any agreements that may
                be required for the use of such Third-Party Services, and hereby
                agree to comply with the terms of any license or other agreement
                relating to Third-Party Services included in the Services or
                made accessible to you through the Services. Additionally, your
                use of the Services or of such Third-Party Services will
                constitute your agreement to be bound by the terms of all
                licensing, subscription and similar agreements relating to such
                use. We do not endorse any Third-Party Services and you are
                responsible for evaluating any Third-Party Services prior to
                signing-up for, accessing, or integrating them with the Services
                you receive from us. We are not responsible for the quality or
                efficacy of any Third-Party Services, or their information
                privacy or security practices, and we have no responsibility for
                the information, goods or services offered or provided by the
                operators of such Third-Party Services, or for the manner in
                which they conduct their operations. Your use of Third-Party
                Services and the materials, goods and services offered by them
                is entirely at your own risk, and is subject to the terms of use
                of the third parties operating or providing them. You should
                assume that any website, application or other material that does
                not bear the official BetterPT logo is provided by a third
                party. You should review the applicable terms of use and privacy
                policies of any Third-Party Services, since they are subject
                only to the agreements you have with the operators of such
                Third-Party Services, and not covered by this Agreement.
                Further, the Third-Party Services may use Your Information in a
                way that we would not. You further acknowledge that your use of
                any Third-Party Services is on an &ldquo;as-is&rdquo; basis.
                Please see BetterPT&rsquo;s Privacy Policy for further
                information regarding Third-Party Services.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Your Relationship with BetterPT.&nbsp;</strong>
              </span>
            </li>
            <ol className='ol1'>
              <li className='li6'>
                <span className='s1'>
                  You acknowledge and agree that BetterPT is a technology
                  services provider that does not provide Physical Therapy
                  Treatments.&nbsp;&nbsp;As between BetterPT and you, you
                  acknowledge and agree that: (a) you shall be solely
                  responsible for determining the most effective, efficient and
                  safe manner to perform each instance of Physical Therapy
                  Treatment; and (b) except for the Services, you shall provide
                  all necessary equipment, tools and other materials, at your
                  own expense, necessary to perform Physical Therapy Treatment.
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  You acknowledge and agree that BetterPT&rsquo;s provision to
                  you of the Services creates a direct business relationship
                  between BetterPT and you. BetterPT does not, and shall not be
                  deemed to, direct or control you generally or in your
                  performance under this Agreement specifically, including in
                  connection with the provision of Physical Therapy Treatment,
                  your acts or omissions, your operation and maintenance of your
                  facilities, or your oversight of your Workforce (including
                  your Authorized Workforce, Users, and Providers). You retain
                  the sole right to determine when, where, and for how long you
                  will utilize the Services. You retain the option to attempt to
                  accept or to decline or ignore any user&rsquo;s request for
                  Physical Therapy Treatment via the Services, or to cancel an
                  accepted request for Physical Therapy Treatment, subject to
                  the then in-effect cancellation policies that you have set
                  through the Services. You acknowledge and agree that you have
                  complete discretion to provide services or otherwise engage in
                  other business or employment activities. For the sake of
                  clarity, and without limiting the foregoing, you understand
                  that you retain the complete right to (i) use other software
                  application services in addition to the Services; and (ii)
                  engage in any other occupation or business.
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  BetterPT does not track or monitor you or your Authorized
                  Workforce&rsquo;s compliance with direct access rules or other
                  any other health care laws or insurer policies (e.g., whether
                  a referral or prescription is needed for Physical Therapy
                  Treatment), which may vary over time and from state to
                  state.&nbsp;&nbsp;Please note that a Patient&rsquo;s insurance
                  company may limit or decline to reimburse you for otherwise
                  reimbursable physical therapy services received without a
                  prescription or a referral from a physician.
                </span>
              </li>
            </ol>
            <li className='li6'>
              <span className='s1'>
                <strong>Your Relationship with Patients.</strong>
              </span>
            </li>
            <ol className='ol1'>
              <li className='li6'>
                <span className='s1'>
                  You acknowledge and agree that your provision of Physical
                  Therapy Treatment to Patients creates a direct business
                  relationship between you and the Patient.
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  BetterPT is not responsible or liable for the actions or
                  inactions of a User or Patient in relation to you, your
                  activities or your treatment facilities. You shall have the
                  sole responsibility for any obligations or liabilities to
                  Users, Patients or third parties that arise from your
                  provision of Physical Therapy Treatment.
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  You acknowledge and agree that you are solely responsible for
                  taking such precautions as may be reasonable and proper
                  (including maintaining adequate insurance that meets the
                  requirements of all applicable laws) regarding any acts or
                  omissions of a user, Provider, Patient or third party.
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  You acknowledge and agree that BetterPT may release your
                  contact information to a Patient upon such Patient&rsquo;s
                  reasonable request.
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  You acknowledge and agree that you may only perform services
                  that have been specifically authorized by a Patient, during
                  the performance of Physical Therapy Treatment for such
                  Patient.
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  You acknowledge and agree that you are responsible for
                  providing Patients with accurate information regarding your
                  Physical Therapy Treatment Fees, cancellation policy, accepted
                  insurance and the Physical Therapy Treatments that you can
                  provide.
                </span>
              </li>
            </ol>
            <li className='li6'>
              <span className='s1'>
                <strong>Modification of Services</strong>. We may update or
                change the Services from time to time, including to modify,
                delete or add features and functions. Without limiting the
                foregoing, if we make a change to the Agreement that materially
                and adversely affects your use of the Services, we may post
                notice or notify you via email or our website(s) of any such
                change.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Termination, Suspension or Amendment. </strong>
              </span>
            </li>
            <ol className='ol1'>
              <li className='li6'>
                <span className='s1'>
                  Notwithstanding anything to the contrary in this Agreement, we
                  have the right, upon notice to you, to immediately terminate,
                  suspend, or amend this Agreement, without liability: (a) to
                  comply with any order issued or proposed to be issued by any
                  governmental agency; (b) to comply with any provision of law,
                  any standard of participation in any reimbursement program, or
                  any accreditation standard; or (c) if performance of any term
                  of this Agreement by either party would cause it to be in
                  violation of law.
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  We may terminate this Agreement immediately upon notice to
                  you: (a) if we determine (in our sole discretion) that you are
                  or may be in violation of applicable federal or state law or
                  regulations; (b) if a finding or stipulation is made or
                  entered into that you have violated any standard or
                  requirement of federal or state law relating to the privacy or
                  security of health information is made in any administrative
                  or civil proceeding; (c) you are excluded from participation
                  in a federal or state health care program; or (d) you or any
                  physical therapist in your Authorized Workforce cease to be
                  qualified to provide services as a physical therapist, or we
                  are unable to verify your qualifications as such.
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  We may suspend access to the Services by you or any member of
                  your Workforce immediately pending your cure of any breach of
                  this Agreement, or in the event we determine in our sole
                  discretion that access to or use of the Services by you or the
                  member of your Workforce may jeopardize the Services or the
                  confidentiality, privacy, security, integrity or availability
                  of information within the Services, or that you or the member
                  of your Workforce has violated or may violate this Agreement,
                  or has jeopardized or may jeopardize the rights of any third
                  party, or that any person is or may be making unauthorized use
                  of the Services with any Credentials assigned to you or a
                  member of your Workforce. We may terminate the access of any
                  member of your Authorized Workforce upon termination or change
                  in status of his or her employment with you. Our election to
                  suspend the Services shall not waive or affect our rights to
                  terminate this Agreement as permitted under this Agreement.
                </span>
              </li>
            </ol>
          </ol>
          <li className='li6'>
            <span className='s1'>
              <strong>Access to the Services</strong>
            </span>
          </li>
          <ol className='ol1'>
            <li className='li6'>
              <span className='s1'>
                <strong>
                  Access Rights of Providers and their Authorized Workforce
                </strong>
              </span>
            </li>
            <ol className='ol1'>
              <li className='li6'>
                <span className='s1'>
                  <strong>Clinic of Record</strong>. As part of the sign up
                  process, you must furnish, among other things, the full legal
                  name and any associated business name(s) (i.e., trade name,
                  d/b/a or &ldquo;doing business as&rdquo;) of the Clinics that
                  will be using the Services. We treat the Clinics in whose name
                  the account is established as the respective owners of all
                  User accounts associated with such account, and we call each
                  such entity a &ldquo;Clinic of Record&rdquo; and collectively
                  the &ldquo;Clinics of Record.&rdquo; The Clinic of Record may
                  be changed in accordance with Section 3.1.3, below.&nbsp;The
                  Clinic of Record is a party to this Agreement for all
                  purposes. Although a member of a Clinic of Record&rsquo;s
                  Authorized Workforce may have signed up for an account or
                  electronically entered into this Agreement, or may continue to
                  administer the Services on the Clinic of Record&rsquo;s
                  behalf, only the Clinic of Record is entitled to any of the
                  rights, remedies or benefits under this Agreement and to
                  control over the use of the Services. The Clinic of Record is
                  likewise subject to all of the covenants, restrictions,
                  limitations, representations, warranties, waivers and releases
                  included in this Agreement. The Clinic of Record may delegate
                  the administration of the Services to one or more members of
                  the Clinic of Record&rsquo;s Authorized Workforce, but the
                  Clinic of Record remains responsible for all activity
                  occurring thereunder.
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  <strong>
                    Incomplete or Inaccurate Registration Information
                  </strong>
                  . A Clinic that has failed to complete the registration
                  information sufficient to establish itself as a Clinic of
                  Record may not be able to access all of the Services. In
                  addition, until such Clinic completes such registration
                  information, such Clinic agrees and acknowledges that it is
                  subject to, and we may enforce against it, all of the
                  covenants, obligations, restrictions, limitations,
                  acknowledgements, representations and warranties set forth in
                  this Agreement that are applicable to the entity and/or
                  persons addressed as &ldquo;you&rdquo; in this Agreement, and
                  such Clinic hereby grants and makes all rights, waivers and
                  releases set forth in this Agreement that are granted and made
                  by the entity or person addressed as &ldquo;you&rdquo; in this
                  Agreement, but such Clinic is entitled to none of, and hereby
                  waives and agrees not to assert any of, the rights, remedies
                  or benefits under this Agreement. Once a Clinic registration
                  has been submitted in a manner sufficient to establish its
                  status as the Clinic of Record, this provision shall cease to
                  apply.
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  <strong>Amending Provider Information</strong>. If you are a
                  Clinic of Record, you are required to submit to us all
                  information necessary to confirm yourself as the Clinic of
                  Record, and maintain the accuracy of such information, in a
                  timely fashion, during the term of this Agreement. You are
                  also required to maintain the accuracy of all information
                  associated with all Providers listed through the Services as
                  well as any User that receives a Credential. We shall be
                  entitled to rely on all information you submit to us. In the
                  event that you contact us and assert that you have authority
                  to act on behalf of a Clinic or any of its account(s) or data,
                  you hereby agree to submit to us such written certifications,
                  assurances (which may include a written opinion of your
                  counsel identifying us as beneficiaries entitled to rely on
                  such opinion), instruments or judicial process as we, in our
                  sole discretion, may request.
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  <strong>Authorized Representatives</strong>. An authorized
                  representative of a Clinic may sign up for an account on
                  behalf of such Clinic, and may have administrative privileges
                  on the account. We call the person(s) authorized to act on
                  behalf of a Clinic the &ldquo;
                  <strong>Authorized Representative(s)</strong>&rdquo; of such
                  Clinic. The Clinic and Authorized Representative may be the
                  same person. If you are establishing an account or taking any
                  action with respect to a Clinic&rsquo;s account, you represent
                  and warrant that (a) you have the authority to act on such
                  Clinic&rsquo;s behalf either as owner/principal or as a member
                  of such Clinic&rsquo;s Authorized Workforce, (b) the
                  information you submit is complete and accurate, and (c) you
                  have the authority to enter into this Agreement on behalf of
                  such Clinic and bind such Clinic to the covenants,
                  obligations, restrictions, limitations, acknowledgements,
                  representations, warranties, grants, waivers and releases
                  contained in this Agreement. If you are an Authorized
                  Representative, you recognize that you have no personal rights
                  with respect to such Clinic&rsquo;s account, and that such
                  Clinic may change the Authorized Representative at any time,
                  for any or no reason, with or without notice.
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  <strong>Authorized Workforce</strong>. If you are a member of
                  a Clinic&rsquo;s Authorized Workforce, and such Clinic has
                  authorized you to access the Services on its behalf by
                  authorizing a Credential for you such that you possess a User
                  account,&nbsp;then&nbsp;you are authorized under this
                  Agreement to access the Services solely on behalf of and at
                  the direction of such Clinic. You consent to and authorize the
                  disclosure to such Clinic of any content related to, or
                  otherwise generated by your use of the Services, including
                  secure messages. You hereby agree and acknowledge that you are
                  subject to, and we may enforce against you, all of the
                  covenants, obligations, restrictions, limitations,
                  acknowledgements, representations and warranties set forth in
                  this Agreement that are applicable to the person addressed as
                  &ldquo;you&rdquo; in this Agreement, and you hereby grant and
                  make all rights, waivers and releases set forth in this
                  Agreement that are granted and made by the person addressed as
                  &ldquo;you&rdquo; in this Agreement, but you are entitled to
                  none of, and hereby waive and agree not to exercise or assert
                  any of, the rights, remedies or benefits under this Agreement
                  other than the limited, non-exclusive, non-transferable,
                  personal right under this Section 3.1.5 to sign in and use the
                  functionality of the Services solely on behalf and direction
                  of such Clinic. Notwithstanding the applicable provisions at
                  Section 11, you acknowledge that your access to the Services
                  may be terminated by the Clinic or us at any time, for any
                  reason or no reason at all, with or without notice. By (i)
                  accessing any of the Services under a Clinic&rsquo;s
                  account(s), or (ii) contacting us by any means and requesting
                  or directing us to take any action with respect to any
                  Clinic&rsquo;s account(s) or data held by such account(s), or
                  (iii) asserting any right or authority with respect to such
                  account(s) or data, you represent and warrant that you have
                  the authority to act on such Clinic&rsquo;s behalf and that
                  you are not using the Services, or otherwise engaging in the
                  activities described in clauses (i) through (iii) above, for
                  the benefit or at the direction, of any person or entity other
                  than such Clinic.
                </span>
              </li>
            </ol>
            <li className='li6'>
              <span className='s1'>
                <strong>Trial Use</strong>. We may also offer the Services on a
                limited basis to Clinics for trial use under this Section 3.2.
                If you have signed-up for an account for the purpose of
                evaluating the Services (collectively, &ldquo;
                <strong>Trial Use</strong>&rdquo;), you may use the Services
                only in connection with such Trial Use and solely during the
                Trial Period. As such, you hereby agree and acknowledge that you
                are subject to, and we may enforce against you, all of the
                covenants, obligations, restrictions, limitations,
                acknowledgements, representations and warranties set forth in
                this Agreement that are applicable to the person addressed as
                &ldquo;you&rdquo; in this Agreement, and you hereby grant and
                make all rights, waivers and release set forth in this Agreement
                that are granted and made by the person addressed as
                &ldquo;you&rdquo; in this Agreement, but you are entitled to
                none of, and hereby waive and agree not to exercise or assert
                any of, the rights, remedies or benefits under this Agreement
                other than the limited, non-exclusive, non-transferable,
                personal right under this Section 3.2 to sign-in and make Trial
                Use of the functionality of the Services during the Trial
                Period. Notwithstanding the applicable provisions at Section 16,
                you acknowledge that your access to the Services during a Trial
                Period may be terminated by us at any time, for any reason or no
                reason at all, with or without notice. You also hereby
                acknowledge and agree that in the event that you at any time use
                the Services to schedule actual Physical Therapy Treatments or
                you enter any information that would satisfy the definition of
                Your Health Information, (a) your Trial Use license will
                immediately convert, without further action by either Party, to
                a paid license in accordance with the terms of the Order Form.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Verification</strong>. You agree that your use of the
                Services is subject to verification by us of you and your
                Authorized Workforce&rsquo;s identity and credentials to manage
                and/or operate physical therapy clinics, your inclusion for
                participation in the Medicare and Medicaid programs, and your
                ongoing qualification as such. You agree that we may use and
                disclose Your Personal Information for such purposes, including
                making inquiry of third parties concerning your identity and
                professional and practice credentials. You authorize such third
                parties to disclose to us such information as we may request for
                such purposes, and you agree to hold them and us harmless from
                any claim or liability arising from the request for or
                disclosure of such information. Notwithstanding the applicable
                provisions at Section 16, you agree that we may terminate your
                access to or use of the Services at any time if we are unable at
                any time to determine or verify such qualifications or
                credentials. You hereby represent and warrant that every
                Provider listed through the Services has valid, ongoing
                qualifications and credentials to provide such Physical Therapy
                Treatment.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Permitted Uses.</strong>
              </span>
            </li>
          </ol>
        </ol>
        <p className='p10'>
          <span className='s1'>
            Subject to the terms of this Agreement, you may use the Services for
            any purpose permitted by applicable law, including for the
            scheduling of Physical Therapy Treatments.
          </span>
        </p>
        <ol className='ol1'>
          <ol className='ol1'>
            <li className='li6'>
              <span className='s1'>
                <strong>Use Restrictions.</strong>
              </span>
            </li>
            <ol className='ol1'>
              <li className='li6'>
                <span className='s1'>
                  You may not use, or allow others to use, the BetterPT Services
                  in any manner other than as expressly allowed in this
                  Agreement.
                  <span className='Apple-converted-space'>&nbsp; </span>You may
                  not (i) reverse engineer, decompile, disassemble, re-engineer
                  or otherwise create or attempt to create or permit, allow, or
                  assist others to create the source code of the BetterPT
                  Services or its structural framework, (ii) sublicense,
                  subcontract, translate, license or grant any rights to the
                  BetterPT Services (including without limitation allowing any
                  distribution or sublicense of the BetterPT Services or other
                  access to the BetterPT Services by any person or entity that
                  is not a User, or processing data using the BetterPT Services
                  on behalf of third parties), (iii) use any robot, spider, site
                  search or retrieval mechanism or other manual or automatic
                  device or process to retrieve, index, data mine, or in any way
                  reproduce or circumvent the navigational structure or
                  presentation of the BetterPT Services, (iv) harvest or collect
                  information about or from other users of the BetterPT Services
                  (v) probe, scan or test the vulnerability of the BetterPT
                  Services, or breach the security or authentication measures on
                  the BetterPT Services, or take any action that imposes an
                  unreasonable or disproportionately large load on the
                  infrastructure of the Services (vi) modify or create
                  derivative works of the Services, (vii) attempt to gain
                  unauthorized access to the Services or its related systems or
                  networks,
                  <span className='Apple-converted-space'>&nbsp; </span>(viii)
                  use the Services in whole or in part for any illegal purpose,
                  or (ix) facilitate or encourage any violations of this Section
                  3.5.1.<span className='Apple-converted-space'>&nbsp; </span>
                  You shall (a) take all reasonable precautions to prevent
                  unauthorized or improper use of the Services, (b) not
                  interfere with or disrupt the integrity or performance of the
                  Services, (c) not attempt to gain unauthorized access to the
                  Services or its related systems or networks, and (d) not
                  create Internet &ldquo;links&rdquo; to the Services or
                  &ldquo;frame&rdquo; or &ldquo;mirror&rdquo; any content
                  therein.
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  You will not: (a) use the Services for time-sharing, rental or
                  service bureau purposes; (b) make the Services, in whole or in
                  part, available to any other person, entity or business; (c)
                  copy, reverse engineer, decompile or disassemble the Services,
                  in whole or in part, or otherwise attempt to discover the
                  source code to the software used by the Services; or (d)
                  modify, combine, integrate, render interoperable, or otherwise
                  access for purposes of automating data conversion or transfer,
                  the Services or associated software with any other software or
                  services not provided or approved by us. You will obtain no
                  rights to the Services except for the limited rights to use
                  the Services expressly granted by this Agreement.
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  You acknowledge that BetterPT engages third party vendors to
                  host the Services, and may change such vendors from time to
                  time.
                  <span className='Apple-converted-space'>&nbsp; </span>You
                  shall at all times use the Services in accordance with such
                  vendors&rsquo; acceptable use policy as made available by
                  BetterPT to you from time to time (&ldquo;AUP&rdquo;), and the
                  AUP is incorporated into this Agreement in its entirety as if
                  fully set forth herein.
                  <span className='Apple-converted-space'>&nbsp; </span>As of
                  the Effective Date, BetterPT&rsquo;s hosting provider is
                  Amazon Web Services, and you shall comply with Amazon Web
                  Services&rsquo; AUP found at https://aws.amazon.com/aup/, and
                  BetterPT will notify you if a different AUP applies as a
                  result of a change in service providers.
                  <span className='Apple-converted-space'>&nbsp; </span>You
                  acknowledge that the vendor&rsquo;s AUP may change from time
                  to time, and that it is your responsibility to check the
                  current vendor&rsquo;s AUP regularly to ensure its continued
                  compliance.
                </span>
              </li>
            </ol>
            <li className='li6'>
              <span className='s1'>
                <strong>Safeguards</strong>. You will implement and maintain
                appropriate administrative, physical and technical safeguards on
                the systems and devices that you use to access the
                Services.&nbsp;&nbsp;Such safeguards shall comply with federal,
                state, and local requirements, including HIPAA, whether or not
                you are otherwise subject to HIPAA. You will maintain
                appropriate security with regard to all personnel, systems, and
                administrative processes used by you or members of your
                Workforce to transmit, store and process electronic health
                information through the use of the Services.
                <span className='Apple-converted-space'>&nbsp; </span>You will
                obtain and maintain such policies of general liability, errors
                and omissions, and professional liability insurance with
                reputable insurance companies as is usually carried by persons
                engaged in your business covering the term of this Agreement.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>User Identification</strong>. We authorize you and your
                Authorized Workforce to use the Credentials uniquely assigned
                to, or selected by, each such individual User. You acquire no
                ownership rights in any such Credentials, and such Credentials
                may be revoked or changed at any time in the discretion of us or
                the Clinic of Record. You will adopt and maintain reasonable and
                appropriate security precautions for your Credentials to prevent
                their disclosure to or use by unauthorized persons. Each member
                of your Authorized Workforce shall have and use a unique
                identifier. You will ensure that no member of your Workforce
                uses Credentials assigned to another Workforce member.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>No Third-Party Access</strong>.&nbsp;&nbsp;Except as
                required by law, you will not permit any third party (other than
                persons who satisfy the definition of Authorized Representative
                and meet the requirements of Section 3.1.4) to use or access the
                Services without our prior written agreement. Nor will you
                authorize or assist any person or entity in accessing, or
                attempting to access, any portion of the Services via any means
                other than a commercial browser (such as Internet Explorer,
                Mozilla Firefox, Safari or Chrome) or the Mobile App. You will
                promptly notify us of any order or demand for compulsory
                disclosure of information if the disclosure requires access to
                or use of the Services. You will cooperate fully with us in
                connection with any such demand. You will also notify us in the
                event that any person or entity, whether or not a member of your
                Authorized Workforce, (a) attempts to access the Services by any
                means other than a commercial browser, (b) claims to offer a
                service or system that &ldquo;integrates with&rdquo; or
                otherwise connects to our Services or (c) requests to use your
                Credentials, or requests that you obtain Credentials, in order
                for the third party to access the Services in a manner that
                would violate this Agreement if you engaged in such activity.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Your Workforce</strong>. You may permit your Authorized
                Workforce to use the Services on your behalf, subject to the
                terms of this Agreement. You will require each member of your
                Authorized Workforce to have unique Credentials, and will
                provide the legal name(s) of each such member for which you are
                seeking Credentials; require each member of your Authorized
                Workforce to acknowledge this Agreement and indicate that they
                agree to comply with all applicable requirements of this
                Agreement, train all members of your Authorized Workforce in the
                requirements of this Agreement relating to their access to and
                use of the Services, and ensure that each member of your
                Authorized Workforce complies with this Agreement; take
                appropriate disciplinary action against any member of your
                Workforce who violates the terms of this Agreement; ensure that
                only the person to whom a specific set of Credentials have been
                assigned accesses the Services with such Credentials; and
                immediately notify us of the termination of employment or
                engagement of any member of your Authorized Workforce, or of
                your withdrawal of authorization for any such person to access
                the Services.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Patient-to-Provider-Messaging</strong>. We may offer a
                communications portal (&ldquo;
                <strong>Patient-to-Provider-Messaging</strong>&rdquo;) between
                members of your Authorized Workforce and your Patients. You and
                your Authorized Workforce are solely responsible for your
                communications through Patient-to-Provider Messaging, including
                without limitation, responding to such messages, forwarding such
                messages, and incorporating any such messages into the
                Patient&rsquo;s medical record, when appropriate. We will
                require Patients to accept our Terms of Use prior to them using
                the Services, as we may set those terms from time to time.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Compliance with Law</strong>. You are solely responsible
                for ensuring that your use of the Services complies with
                applicable laws, rules and regulations, including laws relating
                to the maintenance of the privacy, security, and confidentiality
                of Patient and other health information, and otherwise
                applicable to your business and/or your use of Services (&ldquo;
                <strong>Applicable Laws</strong>&rdquo;). You will not grant any
                User, including members of your Authorized Workforce, any rights
                to access or use of our Services that they would not be allowed
                to have under Applicable Laws. We offer no assurance that your
                use of the Services under the terms of this Agreement will not
                violate any Applicable Laws.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Cooperation</strong>. You will cooperate with us in the
                administration of the Services as we may reasonably request from
                time to time, including providing reasonable assistance in
                evaluating the Services and collecting and reporting data
                requested by us for purposes of administering the Services.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Implementation</strong>. You will acquire, install,
                configure and maintain all hardware, software and communications
                systems necessary to access the Services (your
                &ldquo;Implementation&rdquo;). Your Implementation will comply
                with the specifications from time to time established by us. You
                will ensure that your Implementation is compatible with the
                Services. If we notify you that your Implementation is
                incompatible with the Services, you will eliminate the
                incompatibility, and we may suspend Services to you until you do
                so.
              </span>
            </li>
          </ol>
          <li className='li6'>
            <span className='s1'>
              <strong>Use and Disclosure of Information</strong>
            </span>
          </li>
          <ol className='ol1'>
            <li className='li6'>
              <span className='s1'>
                <strong>Use of Your Information</strong>. Except as otherwise
                provided in this Agreement, we will keep Your Information
                private and will not share it with third parties, unless we
                believe in good faith that disclosure of Your Information is
                necessary to (i) comply with a court order, warrant or other
                legal process, (ii) protect the rights, property or safety of
                BetterPT or others, (iii) investigate or enforce suspected
                breaches of this Agreement (iv) allow our third-party partners
                to comply with their obligations under federal or state law; or
                (vi) any other use or disclosure that is permissible under our
                Privacy Policy and, if applicable, the Business Associate
                Addendum.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Use of Your Health Information</strong>. You retain all
                rights to Your Health Information, and we will only use Your
                Health Information (i) to make it available to you and your
                Authorized Workforce for any legal purpose; (ii) to make
                appointment-related information available to your Patients; and
                (iii) any other use that is in accordance with the terms of the
                Business Associate Addendum.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Use of De-Identified Information</strong>. In
                consideration of our provision of the Services, you hereby
                transfer and assign to us all right, title and interest in and
                to all De-Identified Information that we make from Your Health
                Information or Your Personal Information pursuant to the
                Business Associate Addendum, when applicable. You agree that we
                may use, disclose, market, license and sell such De-Identified
                Information for any purpose without restriction, and that you
                have no interest in such information, or in the proceeds of any
                sale, license, or other commercialization thereof. You
                acknowledge that the rights conferred by this Section are the
                principal consideration for the provision of the Services,
                without which we would not enter into this Agreement.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Use of Services-Related Data</strong>. BetterPT may
                monitor any and all use of the BetterPT Services by you and your
                Users.
                <span className='Apple-converted-space'>&nbsp; </span>
                BetterPT may gather Customer system and usage data for the
                purpose of optimizing the BetterPT Services.
                <span className='Apple-converted-space'>&nbsp; </span>This
                information includes, but is not limited to, data regarding
                memory usage, connection speed and efficiency. BetterPT may use
                such data for its business purposes, including, but not limited
                to, the identification of trends and the formulation of
                statistics, and may disclose the same, provided that in
                connection with such use or disclosure, (i) such data and
                information are aggregated and do not identify individuals or
                Customer, and (ii) such data and information shall not be
                identifiable as originating from Customer.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Responsibility for Misuse by Other Users</strong>. You
                acknowledge that in granting access to the Services for the
                purposes set forth in Section 3.4, we will rely on the
                assurances of the recipients of the information as to (i) their
                identity and credentials, (ii) the purposes for which they are
                accessing the system, and (iii) the nature and extent of the
                information to which they will have access. You acknowledge
                that, while the Services will contain certain technical
                safeguards against misuse of the Services, it will rely to a
                substantial extent on the representations and undertakings of
                users of the Services. You agree that we will not be responsible
                for any unlawful access to or use of Your Health Information by
                any User resulting from the User&rsquo;s misrepresentation to
                us, or breach of the User&rsquo;s Agreement.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Specially Protected Information</strong>.&nbsp;You
                acknowledge that federal and state laws impose restrictions on
                the use and disclosure of certain types of health information.
                The parties&rsquo; respective rights and obligations under HIPAA
                are set forth in the Business Associate Addendum that is
                incorporated and made a part of this Agreement. You agree that
                you are solely responsible for ensuring that Your Health
                Information may properly be disclosed to us pursuant to the
                Business Associate Addendum and applicable law.&nbsp;&nbsp;In
                particular, you will:&nbsp;not make available to other users
                through the Services any information in violation of any
                restriction on use or disclosure; obtain all necessary consents,
                authorizations or releases from individuals required for making
                their health information available through the Services for the
                purposes set forth in Section 3.4; include such statements (if
                any) in your notice of privacy practices as may be required in
                connection with your use of the Services; and not place in the
                Services any information that you know or have reason to believe
                is false or materially inaccurate.&nbsp; You are solely
                responsible for affording individuals their rights with respect
                to relevant portions of Your Health Information, such as the
                rights of access and amendment.{' '}
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Provider Directories; Opt-Out</strong>.&nbsp;We may
                include your Directory Information (defined below) in our (a)
                &ldquo;Public Provider Directories,&rdquo; which are electronic
                directories for Patients and the general public; and (b)
                &ldquo;Professional Provider Directories,&rdquo; which are
                electronic directories for Providers and other members of the
                healthcare community ((a) and (b) collectively, &ldquo;Provider
                Directories&rdquo;).&nbsp;&nbsp;Provider Directories may be made
                available in various electronic formats, including searchable
                databases, Provider landing pages, interactive reference tools,
                reference lists, and integrated look-up features, among others.
                They may also incorporate information designed to help users,
                such as integrated maps, and licensure confirmation tools, among
                others. Provider Directories may include a &ldquo;contact&rdquo;
                feature that allows users to contact other users directly
                through the Services. Our Public Provider Directory may be made
                available to public search engines to aid Provider discovery.
                Listing in the Provider Directories is subject to eligibility
                criteria, which may differ between the Public Provider Directory
                and Professional Provider Directory. If you meet the applicable
                criteria, some or all of your Directory Information will be
                automatically included in the applicable Provider
                Directory(ies), unless you utilize our &ldquo;opt out&rdquo;
                feature or otherwise inform us in writing that you wish to be
                excluded. In addition, unless you utilize our &ldquo;opt
                out&rdquo; feature or otherwise inform us in writing that you
                wish to be excluded from the Provider Directories, we may
                provide your Directory Information and your Patients&rsquo;
                reviews of your services to our third party partners who may
                include your Directory Information and the Patient review
                information on their websites. A Provider&rsquo;s
                &ldquo;Directory Information&rdquo; includes the
                Provider&rsquo;s name, name(s) of physical therapists or other
                treatment professionals associated with a Provider,
                Provider&rsquo;s business telephone number(s) and physical
                address(es), and the Provider&rsquo;s available appointment
                slots, as each is indicated from information a Provider has
                inputted or imported into the Services. The Directory
                Information may include additional information you input or
                upload into profile tools we make available in the Services
                (such as a profile photograph, accepted insurance, available
                office hours, a front desk email address, and the like), as and
                when such tools are available.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Ratings. </strong>You acknowledge and agree to the
                following:
              </span>
            </li>
            <ol className='ol1'>
              <li className='li6'>
                <span className='s1'>
                  After receiving Physical Therapy Treatments from a visit
                  booked using the Services, a Patient may be prompted by
                  BetterPT&rsquo;s mobile application to provide a rating of
                  such Physical Therapy Treatments and, optionally, to provide
                  comments or feedback about you and such Physical Therapy
                  Treatments.
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  BetterPT desires that patients have access to high-quality
                  services via the Services.&nbsp;&nbsp;Accordingly, BetterPT
                  reserves the right to deactivate your access, deactivate the
                  access of any member of your Authorized Workforce, or remove
                  the listings of specific Providers, in response to: (a)
                  feedback that users provide through ratings: (b) repeated
                  failure to accept patient requests for Physical Therapy
                  Treatments during time slots that you have indicated are
                  available for appointments creates a negative experience for
                  patients.{' '}
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  BetterPT is a distributor (without any obligation to verify)
                  and not a publisher of user ratings and comments.
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  BetterPT reserves the right to edit or remove comments in the
                  event that such comments include obscenities or other
                  objectionable content, include an individual&rsquo;s name or
                  other personal information, or violate any privacy laws, other
                  applicable laws or BetterPT&rsquo;s content policies.
                </span>
              </li>
            </ol>
            <li className='li6'>
              <span className='s1'>
                <strong>Content</strong>
              </span>
            </li>
            <ol className='ol1'>
              <li className='li6'>
                <span className='s1'>
                  You acknowledge and agree to ensure that all Content you
                  provide to or through the Services (including your Clinic
                  profile, Provider profiles, and User profiles associated with
                  your Authorized Workforce) is accurate and up to date, and you
                  represent and warrant that you have the right to make such
                  Content available to us and that we may use such Content to
                  perform the Services.
                </span>
              </li>
              <li className='li6'>
                <span className='s1'>
                  All insurance and insurance-related Content (including,
                  without limitation, insurance coverage and benefit Content) is
                  intended for general reference purposes and for your
                  convenience only (&ldquo;<strong>Insurance Content</strong>
                  &rdquo;). Such Insurance Content is based on Personal
                  Information provided to us by both you and the Patients,
                  either directly, through the insurance provider identified by
                  a Patient or via a third-party clearinghouse. Insurance
                  Content often changes frequently and may become out of date,
                  incomplete or inaccurate. In order to improve (but not
                  guarantee) the accuracy of the Insurance Content, you
                  acknowledge and agree that you will (a) provide accurate and
                  complete Insurance Content related to your practice, and (b)
                  verify and update your Insurance Content. BetterPT will not be
                  responsible for your failure or a Patient&rsquo;s failure to
                  comply with subparts (a) or (b) hereof, nor for any
                  inaccurate, incomplete or outdated Insurance Content,
                  regardless of the reason.
                </span>
              </li>
            </ol>
          </ol>
          <li className='li6'>
            <span className='s1'>
              <strong>Intellectual Property</strong>
            </span>
          </li>
          <ol className='ol1'>
            <li className='li6'>
              <span className='s1'>
                <strong>Reservation of Rights</strong>.
                <span className='Apple-converted-space'>&nbsp; </span>All rights
                not expressly granted to Customer herein are expressly reserved
                by BetterPT.
                <span className='Apple-converted-space'>&nbsp; </span>As between
                the parties, the BetterPT Services are and will remain the
                exclusive property of BetterPT, and BetterPT will retain
                ownership of all copyrights, patents, trademarks, trade secrets,
                know-how, databases, and other intellectual property rights
                relating to or residing in the BetterPT Platform and any
                updates, improvements, modifications and enhancements (including
                error corrections and enhancements) thereto, and all derivative
                works thereof, and Customer will have no right, title, or
                interest in or to the same.
                <span className='Apple-converted-space'>&nbsp; </span>Nothing in
                this Agreement will be deemed to grant, by implication,
                estoppel, or otherwise, a license under any of BetterPT&rsquo;s
                or its licensors&rsquo; existing or future rights in or to the
                BetterPT Services.
                <span className='Apple-converted-space'>&nbsp; </span>
                BetterPT trade names, trademarks, service marks, titles, and
                logos, and any goodwill appurtenant thereto, shall be owned
                exclusively by BetterPT and shall inure solely to the benefit of
                BetterPT.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Other Works and Information</strong>. You hereby grant
                to us a nonexclusive, royalty-free, fully paid-up, perpetual,
                irrevocable, worldwide and fully sublicensable right to use,
                reproduce, modify, adapt, publish, translate, create derivative
                works from, distribute, and display any information, material or
                work product, including Content &ndash; other than Your Health
                Information and Your Personal Information that has not been
                De-Identified &ndash; that you provide us through the Services.
                You agree that we may use, disclose, market, license, and sell
                such information and works, including derivative products,
                without restriction. This includes, for example, information and
                content (other than Your Health Information or Your Personal
                Information which has not been De-Identified) that you
                contribute through your use of the Services. You warrant and
                agree that any material you provide will not infringe or
                otherwise violate the intellectual property or other rights of
                others, and will not be otherwise unlawful, infringing,
                threatening, libelous, defamatory, obscene, pornographic, or in
                violation of any law.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Defense of Intellectual Property</strong>.
                <span className='Apple-converted-space'>&nbsp; </span>
                BetterPT, at its own expense, shall: (i)&nbsp;defend, or at its
                option settle, any claim, suit or proceeding brought by a third
                party against the Customer and its affiliates and its and their
                licensors, suppliers, officers directors, employees and agents
                alleging that the BetterPT Services (other than Your
                Information) infringes an existing United States copyright or
                trademark; and (ii) pay any final and non-appealable judgment
                entered or settlement against Customer thereon; provided,
                however, that BetterPT shall not be responsible for any
                compromise or settlement made without its prior consent.
                <span className='Apple-converted-space'>&nbsp; </span>If the
                BetterPT Services are or may become the subject of such a claim,
                BetterPT may, at its option: (1)&nbsp;modify or replace the
                affected parts so the BetterPT Services become non-infringing or
                (2) terminate this Agreement and refund Customer for any prepaid
                and unused fees.
                <span className='Apple-converted-space'>&nbsp; </span>
              </span>
              <span className='s3'>
                BetterPT shall have no obligation with respect to any
                infringement claim based upon (a) Your Information or (b)
                Customer or Customer&rsquo;s Users&rsquo; combination, operation
                or use of the BetterPT Services with non-BetterPT applications,
                information or services if the infringement claim would have
                been avoided had such combination, operation or use not
                occurred.
              </span>
              <span className='s1'>
                <span className='Apple-converted-space'>&nbsp; </span>Where
                infringement claims arise with respect to third party products,
                BetterPT&rsquo;s sole obligation is to pass through to Customer
                any indemnity that may be available to Customer under the terms
                and conditions of the agreement between BetterPT and such third
                party vendor.
                <span className='Apple-converted-space'>&nbsp; </span>THIS
                SECTION STATES THE ENTIRE LIABILITY OF BETTERPT, AND
                CUSTOMER&rsquo;S SOLE AND EXCLUSIVE REMEDY, FOR ANY INFRINGEMENT
                INVOLVING THE BETTERPT SERVICES.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Process</strong>.
                <span className='Apple-converted-space'>&nbsp; </span>
                BetterPT&rsquo;s indemnification obligation is conditional upon:
                (a) Customer giving BetterPT prompt notice upon becoming aware
                the claim; (b) Customer giving BetterPT the right to solely
                control and direct the investigation, preparation, defense and
                settlement of the claim; and (c) Customer fully cooperating with
                BetterPT, at BetterPT&rsquo;s expense, in such defense and
                settlement.
                <span className='Apple-converted-space'>&nbsp; </span>Customer
                will have the right, at its cost, to employ counsel of its
                choice to participate in the defense of such claim.
              </span>
            </li>
          </ol>
          <li className='li6'>
            <span className='s1'>
              <strong>Indemnification.</strong>
            </span>
          </li>
        </ol>
        <p className='p6'>
          <span className='s1'>
            You hereby agree to indemnify, defend, and hold harmless us and
            other users, and our and their respective affiliates, officers,
            directors, employees and agents, from and against any and all
            claims, losses, damages, expenses, costs and liabilities, including
            reasonable attorneys&rsquo; fees, arising out of or relating to: (a)
            the use of the Services by you or your Workforce; (b) any breach by
            you or your Workforce of any representations, warranties or
            agreements contained in this Agreement; (c) the actions of any
            person gaining access to the Services under Credentials assigned to
            you or a member of your Workforce; (d) the actions of anyone using
            Credentials assigned to you or any member of your Workforce that
            adversely affects the Services or any information accessed through
            the Services; (e) your negligent or willful misconduct, or that of
            any member of your Workforce; (f) any violation of HIPAA or
            Applicable Laws that is caused by your or your Workforce&rsquo;s
            acts or omissions; (g) our maintenance of Provider Directories; (h)
            any action we take in reliance on any information, certification,
            assurance or instrument you provide to us, as well as any action we
            take that complies with any request or direction from you; and (i)
            Content that you submit to the Services. Your indemnification
            obligations in this Agreement are cumulative, and are not intended
            to, nor do they, limit your indemnification obligations elsewhere in
            this Agreement or at law, even if such obligations arise or are
            occasioned or triggered by a single assertion, claim, circumstance,
            action, event or transaction.
          </span>
        </p>
        <ol className='ol1'>
          <li className='li6'>
            <span className='s1'>
              <strong>Fees and Charges</strong>
            </span>
          </li>
          <ol className='ol1'>
            <li className='li6'>
              <span className='s1'>
                <strong>Payment of Fees</strong>. In consideration of your
                access to and use of the Services, you agree to pay us the fees
                and expenses as calculated and invoiced in accordance with this
                Section 7 and the applicable Order Form (the &ldquo;
                <strong>Fees</strong>&rdquo;). We may choose to bill through an
                invoice, in which case, full payment for invoices issued in any
                given month must be received by Company thirty&nbsp;(30) days
                after the mailing date of the invoice.
                <span className='Apple-converted-space'>&nbsp; </span>Unpaid
                amounts are subject to a finance charge of 1.5% per month on any
                outstanding balance, or the maximum permitted by law, whichever
                is lower, plus all expenses of collection and may result in
                immediate termination of Services. All invoices shall be paid in
                full and without deduction for any sales, withholding, use or
                other taxes or similar charges, which shall be borne exclusively
                by you and, to the extent required by applicable law, collected
                by us from you. Payment of fees is non-refundable and
                non-cancelable. We reserve the right to change the Fees and to
                institute new Fees upon thirty&nbsp;(30) days prior notice to
                you (which may be sent by email).{' '}
                <span className='Apple-converted-space'>&nbsp; </span>If you do
                not pay Fees when due then we may, without limiting our other
                available remedies, suspend provision of the Services to you and
                to Users.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Physical Therapy Treatment Fees</strong>. You are
                responsible for setting the fees that you charge Patients for
                Physical Therapy Treatments booked through the Services (&ldquo;
                <strong>Physical Therapy Treatment Fees</strong>&rdquo;), and
                also for communicating your cancellation policy, which will be
                disclosed to Patients through the Services and apply to the
                appointments booked by you or your Authorized Workforce. You and
                your Authorized Workforce are required to provide the applicable
                Physical Therapy Treatments at or below the Physical Therapy
                Treatment Fee and subject to the terms of the cancellation
                policy that is displayed at the Patient&rsquo;s time of booking,
                unless you separately agree otherwise. You must resolve any
                questions or disputes, including any dispute relating to
                Physical Therapy Treatment Fees directly with the
                Patient.&nbsp;You and the Patient are responsible for agreeing
                to any additional or different services, and the fees associated
                with such modifications. WE ARE NOT RESPONSIBLE FOR ANY
                PATIENT&rsquo;S FAILURE TO PAY THE PHYSICAL THERAPY TREATMENT
                FEES, OR YOUR OR ANY PATIENT&rsquo;S AGREEMENT OR FAILURE TO
                REACH AGREEMENT ON ANY ADDITIONAL OR DIFFERENT SERVICE.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>BetterPT Service Fees</strong>. We reserve the right to
                charge Patients a separate service fee for facilitating Physical
                Therapy Treatments between Clinics (and Providers) and Patients
                through the Services (&ldquo;
                <strong>BetterPT Service Fee</strong>&rdquo;). The BetterPT
                Service Fee will be charged and collected by BetterPT directly
                from the Patient. We reserve the right to change or to add new
                fees or charges.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Payment Processing</strong>. Patients may be permitted
                to use the Services to pay for Physical Therapy Treatments. In
                connection with such payments, BetterPT&rsquo;s payment
                processing partner processes Patients&rsquo; payment card
                information in accordance with our{' '}
                <a href='https://clinics.betterpt.com/privacy-policy'>
                  <span className='s4'>Privacy Policy</span>
                </a>
                . While BetterPT may provide assistance with Insurance Content
                to assist the processing of insurance claims, Physical Therapy
                Treatments may or may not be covered by the Patient&rsquo;s
                insurance, and it is solely the responsibility of the Clinic and
                Patient to determine whether coverage applies and to submit a
                claim for coverage.
              </span>
            </li>
          </ol>
          <li className='li6'>
            <span className='s1'>
              <strong>Confidential Information</strong>
            </span>
          </li>
          <ol className='ol1'>
            <li className='li6'>
              <span className='s1'>
                You may not disclose our Confidential Information to any other
                person, and you may not use any Confidential Information except
                for the purpose of this Agreement. Except as otherwise provided
                in this Agreement, you may not, without our prior written
                consent, at any time, during or after the Term of this
                Agreement, directly or indirectly, divulge or disclose
                Confidential Information for any purpose. In addition, except
                for the purposes of using the Services, you will not use
                Confidential Information for any other purposes. You will hold
                all Confidential Information in strict confidence and to take
                all measures necessary to prevent unauthorized copying, use, or
                disclosure of Confidential Information, and to keep the
                Confidential Information from falling into the public domain or
                into the possession of persons not bound to maintain its
                confidentiality. You will disclose Confidential Information only
                to members of your Workforce who have a need to use it for the
                purposes of this Agreement. You will inform all such recipients
                of the confidential nature of Confidential Information and will
                instruct them to deal with Confidential Information in
                accordance with the terms of this Agreement. You will promptly
                advise us in writing of any improper disclosure,
                misappropriation, or misuse of the Confidential Information by
                any person, which may come to your attention.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                You agree that we will suffer irreparable harm if you fail to
                comply with your obligations set forth in Section 8.1, and you
                further agree that monetary damages may be inadequate to
                compensate us for any such breach. Accordingly, you agree that
                we will, in addition to any other remedies available to us at
                law or in equity, be entitled to the issuance of injunctive
                relief to enforce the provisions hereof, immediately and without
                the necessity of posting a bond.
              </span>
            </li>
          </ol>
          <li className='li6'>
            <span className='s1'>
              <strong>Disclaimers.</strong>
            </span>
          </li>
          <ol className='ol1'>
            <li className='li6'>
              <span className='s1'>
                <strong>Carrier Lines</strong>. YOU ACKNOWLEDGE THAT ACCESS TO
                THE SERVICES WILL BE PROVIDED OVER VARIOUS FACILITIES AND
                COMMUNICATIONS LINES, AND INFORMATION WILL BE TRANSMITTED OVER
                LOCAL EXCHANGE AND INTERNET BACKBONE CARRIER LINES AND THROUGH
                ROUTERS, SWITCHES, AND OTHER DEVICES (COLLECTIVELY,
                &ldquo;CARRIER LINES&rdquo;) OWNED, MAINTAINED, AND SERVICED BY
                THIRD-PARTY CARRIERS, UTILITIES, AND INTERNET SERVICE PROVIDERS,
                ALL OF WHICH ARE BEYOND OUR CONTROL. NEITHER BETTERPT NOR ITS
                LICENSORS OR SUPPLIERS ASSUME LIABILITY FOR, OR RELATING TO, THE
                INTEGRITY, PRIVACY, SECURITY, CONFIDENTIALITY, OR USE OF ANY
                INFORMATION WHILE IT IS TRANSMITTED ON THE CARRIER LINES, OR ANY
                DELAY, FAILURE, INTERRUPTION, INTERCEPTION, LOSS, TRANSMISSION,
                OR CORRUPTION OF ANY DATA OR OTHER INFORMATION ATTRIBUTABLE TO
                TRANSMISSION ON THE CARRIER LINES. USE OF THE CARRIER LINES IS
                SOLELY AT YOUR RISK AND IS SUBJECT TO ALL APPLICABLE LOCAL,
                STATE, NATIONAL, AND INTERNATIONAL LAWS.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>No Warranties</strong>. ACCESS TO THE SERVICES IS
                PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo;
                WITHOUT ANY WARRANTY OF ANY KIND, AND BETTERPT AND ITS LICENSORS
                AND SUPPLIERS DISCLAIM ALL WARRANTIES, EXPRESSED OR IMPLIED,
                STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO THE IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                NON-INFRINGEMENT AND TITLE. YOU ARE SOLELY RESPONSIBLE FOR ANY
                AND ALL ACTS OR OMISSIONS TAKEN OR MADE IN RELIANCE ON THE
                SERVICES OR THE INFORMATION IN THE SERVICES, INCLUDING
                INACCURATE OR INCOMPLETE INFORMATION.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Additional Disclaimers</strong>.
                <span className='Apple-converted-space'>&nbsp; </span>
                BETTERPT AND ITS LICENSOR AND SUPPLIERS DISCLAIM ANY AND ALL
                LIABILITY FOR ERRONEOUS TRANSMISSIONS AND LOSS OF SERVICE
                RESULTING FROM COMMUNICATION FAILURES BY TELECOMMUNICATION
                SERVICE PROVIDERS OR THE SERVICES. We have no liability for the
                consequences to your or your Patients&rsquo; use of the
                Services.
              </span>
            </li>
          </ol>
          <li className='li6'>
            <span className='s1'>
              <strong>Limitations of Liability</strong>
            </span>
          </li>
          <ol className='ol1'>
            <li className='li6'>
              <span className='s1'>
                <strong>Exclusion of Damages</strong>.
                <span className='Apple-converted-space'>&nbsp; </span>TO THE
                MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, IT IS EXPRESSLY
                AGREED THAT IN NO EVENT SHALL BETTERPT OR ITS LICENSORS OR
                SUPPLIERS BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT,
                CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED
                TO, LOSS OF PROFITS OR REVENUES, LOSS OF USE, LOSS OF GOODWILL,
                OR LOSS OF INFORMATION OR DATA, WHETHER A CLAIM FOR ANY SUCH
                LIABILITY OR DAMAGES IS PREMISED UPON BREACH OF CONTRACT, BREACH
                OF WARRANTY, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER THEORY
                OF LIABILITY, EVEN IF WE HAVE BEEN APPRISED OF THE POSSIBILITY
                OR LIKELIHOOD OF SUCH DAMAGES.{' '}
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Other Users</strong>. YOU ACKNOWLEDGE THAT OTHER USERS
                (INCLUDING OTHER USERS IN YOUR AUTHORIZED WORKFORCE) HAVE ACCESS
                TO AND ARE USING OUR SERVICES AND THE ACTIONS OF SUCH OTHER
                USERS ARE BEYOND OUR CONTROL. ACCORDINGLY, WE DO NOT ASSUME ANY
                LIABILITY FOR OR RELATING TO LOSSES THAT ARISE FROM ANY
                USER&rsquo;S ACTIONS OR FAILURES TO ACT.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Unauthorized Access; Lost or Corrupt Data</strong>.
                NEITHER BETTERPT NOR ITS LICENSOR OR SUPPLIERS ARE RESPONSIBLE
                FOR UNAUTHORIZED ACCESS TO YOUR DATA, FACILITIES OR EQUIPMENT BY
                PERSONS USING THE SERVICES OR FOR UNAUTHORIZED ACCESS TO,
                ALTERATION, THEFT, CORRUPTION, LOSS OR DESTRUCTION OF YOUR DATA
                FILES, PROGRAMS, PROCEDURES, OR INFORMATION THROUGH THE
                SERVICES, WHETHER BY ACCIDENT, FRAUDULENT MEANS OR DEVICES, OR
                ANY OTHER MEANS. YOU ARE SOLELY RESPONSIBLE FOR VALIDATING THE
                ACCURACY OF ALL INFORMATION, AND FOR PROTECTING YOUR DATA AND
                PROGRAMS FROM LOSS BY IMPLEMENTING APPROPRIATE SECURITY
                MEASURES. YOU HEREBY WAIVE ANY DAMAGES OCCASIONED BY LOST OR
                CORRUPT DATA, INCORRECT REPORTS, OR INCORRECT DATA FILES
                RESULTING FROM PROGRAMMING ERROR, OPERATOR ERROR, EQUIPMENT OR
                SOFTWARE MALFUNCTION, SECURITY VIOLATIONS, OR THE USE OF
                THIRD-PARTY SOFTWARE. WE ARE NOT RESPONSIBLE FOR THE CONTENT OF
                ANY INFORMATION TRANSMITTED OR RECEIVED THROUGH OUR PROVISION OF
                THE SERVICES.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Limitation of Liability</strong>. TO THE MAXIMUM EXTENT
                PERMITTED UNDER APPLICABLE LAW, BETTERPT&rsquo;S TOTAL,
                AGGREGATE LIABILITY UNDER THIS AGREEMENT, REGARDLESS OF THEORY
                OF LIABILITY, SHALL BE LIMITED TO THE AGGREGATE FEES ACTUALLY
                PAID BY YOU UNDER THE ORDER FORM UNDER WHICH THE CLAIM(S) AROSE
                DURING THE SIX (6) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT
                FIRST GIVING RISE TO THE CLAIM.
              </span>
            </li>
          </ol>
          <li className='li6'>
            <span className='s1'>
              <strong>Term and Termination</strong>
            </span>
          </li>
          <ol className='ol1'>
            <li className='li6'>
              <span className='s1'>
                <strong>Term</strong>. The term of this Agreement is as set
                forth in the Order Form unless terminated earlier as provided
                herein.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Termination upon Notice</strong>. We or you may
                terminate this Agreement at any time without cause upon thirty
                (30) days&rsquo; prior written notice to the other party.
                <span className='Apple-converted-space'>&nbsp; </span>We may
                terminate this Agreement as otherwise expressly specified
                herein.<span className='Apple-converted-space'>&nbsp; </span>If
                you breach this Agreement and do not cure such breach within
                fifteen (15) days of our notice, we may terminate this Agreement
                upon notice.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Effect of Termination</strong>. At termination of this
                Agreement we will provide the Clinic of Record with a copy of
                (i) Your Personal Information in an electronic form that is
                accessible through commercially available hardware and software;
                and (ii) Your Health Information in accordance with the Business
                Associate Addendum. You may have to purchase such hardware and
                software from third parties in order to access your data, and
                you may have to configure your systems in order to use your data
                in your practice. You acknowledge that if you have approved, in
                accordance with the terms of this Agreement, other users of our
                services (such as your Patients), we will continue to make such
                information and data available to such users pursuant to the
                terms of the agreements we have with them.
                <span className='Apple-converted-space'>&nbsp; </span>Upon
                termination of this Agreement, you will (i) cease all use of the
                Services, (ii) pay the outstanding balance of any Fees due to
                us, and (iii) remove all software provided under this Agreement
                from your computer systems and other devices. All provisions of
                the Agreement which, by their nature, should survive termination
                shall survive termination, including Sections 1, 2.2, 3.6, 5
                through 10, 11.3, 12, 13, 14.2, and 15 through 20.&nbsp;
              </span>
            </li>
          </ol>
          <li className='li6'>
            <span className='s1'>
              <strong>Governing Law&nbsp;&nbsp;</strong>
            </span>
          </li>
        </ol>
        <p className='p6'>
          <span className='s1'>
            The interpretation of this Agreement and the resolution of any
            disputes arising under this Agreement shall be governed by the laws
            of the State of New York, without regards to its conflicts of laws
            provisions. If any action or other proceeding is brought on or in
            connection with this Agreement, and except as set forth in Section
            15, the venue of such action shall be exclusively in the courts of
            the City and County of New York, NY and each party hereby consents
            to the personal jurisdiction of such courts.
          </span>
        </p>
        <ol className='ol1'>
          <li className='li6'>
            <span className='s1'>
              <strong>Arbitration</strong>
            </span>
          </li>
          <ol className='ol1'>
            <li className='li6'>
              <span className='s1'>
                Except for claims by either party under any of the sections of
                this Agreement listed in section 13.2, any dispute, claim or
                controversy arising out of or relating to this Agreement or the
                breach, termination, enforcement, interpretation or validity
                thereof, including the determination of the scope or
                applicability of this agreement to arbitrate, shall be subject
                to final and binding arbitration governed by the federal
                arbitration act (9 U.S.C. &sect;&sect; 1 et seq.). The
                arbitration shall be conducted before a single arbitrator in
                accordance with the commercial dispute resolution procedures and
                the supplementary procedures for consumer related disputes of
                the American Arbitration Association (the &ldquo;AAA&rdquo;)
                then in effect, as modified by this Agreement, and will be
                administered by the AAA. Judgment on the award may be entered in
                any court having jurisdiction. This clause shall not preclude
                either party from seeking temporary or preliminary injunctive
                relief in connection with an arbitrable controversy, but only
                upon the ground that the award to which that party may be
                entitled may be rendered ineffectual without such provisional
                relief.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                This agreement to arbitrate shall not apply to claims by any
                party brought under and to enforce any one or more of the
                following sections of this Agreement: 3.2; 3.1.4; 3.1.5; 3.5;
                3.8; 3.9; or, to the extent applicable to the foregoing
                sections, 11.3.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                The Parties agree that each may bring claims against the other
                only in an individual capacity, and not as a plaintiff, class
                member, or private attorney general in any purported class,
                representative, or private-attorney-general proceeding. By
                entering into this Agreement, you and we are each waiving the
                right to a jury trial or to participate in a class action. The
                arbitrator may not consolidate more than one person&rsquo;s
                claim with your claims or our claims and may not otherwise
                preside over any representative, class, or
                private-attorney-general proceeding. The arbitrator may award
                declaratory or injunctive relief only in favor of the individual
                party seeking relief and only to the extent necessary to provide
                relief warranted by that party&rsquo;s individual claim.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                Any part of this Agreement to arbitrate that shall prove to be
                invalid, void, or illegal shall in no way affect, impair, or
                invalidate any other provision of this Agreement to arbitrate,
                and such other provisions shall remain in full force and effect.
              </span>
            </li>
          </ol>
          <li className='li6'>
            <span className='s1'>
              <strong>Assignability of this Agreement; Assurances</strong>
            </span>
          </li>
          <ol className='ol1'>
            <li className='li6'>
              <span className='s1'>
                <strong>Assignments</strong>. This Agreement may be transferred
                in its entirety by a Clinic of Record in connection with the
                sale, transfer or reorganization of all or substantially all of
                the practice or business to which this Agreement
                relates;&nbsp;provided&nbsp;that&nbsp;each of the following
                conditions are satisfied in full: (a) an authorized
                representative of the transferor or transferee notifies us in
                writing of the transfer, the legal name of the transferee, and
                date of transfer; (b) the transferor or transferee submits to us
                such written certifications, assurances (which may include a
                written opinion of your counsel identifying us as beneficiaries
                entitled to rely on such opinion) or instruments as we, in our
                sole discretion, may request; and (c) we are satisfied, in our
                sole discretion, of the validity of the certifications,
                assurances or instruments submitted pursuant to clause (b). Upon
                our recognition of a transfer by a Clinic of Record, all User
                accounts of such Clinic of Record&rsquo;s Authorized Workforce
                shall automatically transfer to such Clinic of Record&rsquo;s
                recognized transferee. Except as expressly set forth in this
                Section 14.1, you may not assign or transfer this Agreement, in
                whole or in part, without our prior written consent, which may
                be withheld at our sole discretion. We may freely assign this
                Agreement in connection with a merger, acquisition, or sale of
                assets, or by operation of law or otherwise.
              </span>
            </li>
            <li className='li6'>
              <span className='s1'>
                <strong>Assurances</strong>. By requesting or directing us to
                take any action described in Section 3.1.3 or Section 14.1 with
                respect to any Provider or any account(s) or data held by such
                account(s), you represent and warrant that (i) you have the
                authority to act on such Clinic&rsquo;s behalf or to control
                such account(s) or data, and (ii) your request or direction is
                not in furtherance of any purpose or action that would violate
                any provision of this Agreement, applicable law or the rights of
                any person or entity. YOU HEREBY WAIVE AND UNCONDITIONALLY
                RELEASE US, OUR AFFILIATES, AND OUR AND THEIR RESPECTIVE
                OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS, FROM ANY AND ALL
                CLAIMS, DEMANDS, DAMAGES, DEBTS, LIABILITIES, EXPENSES, ACTIONS
                AND CAUSES OF ACTIONS OF EVERY KIND AND NATURE, WHETHER NOW
                KNOWN OR UNKNOWN, ARISING OUT OF OR IN CONNECTION WITH ANY
                ACTION WE TAKE OR DO NOT TAKE IN RESPONSE TO ANY REQUEST,
                DIRECTION, INFORMATION, CERTIFICATION, ASSURANCE OR INSTRUMENTS
                WE RECEIVE FROM YOU IN ACCORDANCE WITH SECTION 3.1.3 OR SECTION
                14.1. ACCORDINGLY, YOU AGREE TO WAIVE THE BENEFIT OF ANY LAW,
                INCLUDING, TO THE EXTENT APPLICABLE, CALIFORNIA CIVIL CODE
                &sect; 1542 (OR SIMILAR PROVISIONS OF THE LAWS OF OTHER STATES),
                WHICH STATES,
              </span>
            </li>
          </ol>
          <li className='li6'>
            <span className='s1'>
              <strong>Supervening Circumstances.&nbsp;&nbsp;</strong>Except with
              respect to your payment obligations, neither party shall be deemed
              in violation of this Agreement if it is prevented from performing
              any of the obligations under this Agreement by reason of: (a)
              severe weather and storms; (b) earthquakes or other natural
              occurrences; (c) strikes or other labor unrest; (d) power
              failures; (e) nuclear or other civil or military emergencies; (f)
              acts of legislative, judicial, executive, or administrative
              authorities; or (g) any other circumstances that are not within
              its reasonable control.
            </span>
          </li>
          <li className='li6'>
            <span className='s1'>
              <strong>Severability.&nbsp;&nbsp;</strong>Any provision of this
              Agreement that shall prove to be invalid, void, or illegal, shall
              in no way affect, impair, or invalidate any other provision of
              this Agreement, and such other provisions shall remain in full
              force and effect.
            </span>
          </li>
          <li className='li6'>
            <span className='s1'>
              <strong>Notices.&nbsp;&nbsp;</strong>Any and all notices required
              or permitted under this Agreement shall be in writing and sent by
              United States mail or by commercial courier to the address
              provided below or to such other and different addresses as the
              parties may designate by notice. If you supply us with an
              electronic mail address, we may give notice by email message
              addressed to such address; provided that if we receive notice that
              the email message was not delivered, we will give the notice by
              United States mail or fax.
            </span>
          </li>
        </ol>
        <p className='p11'>
          <span className='s1'>To us: Better PT, Inc.</span>
        </p>
        <p className='p11'>
          <span className='s1'>25 West 36th Street </span>
        </p>
        <p className='p11'>
          <span className='s1'>New York, New York 10018</span>
        </p>
        <p className='p11'>
          <span className='s1'>Phone: 212-634-7833</span>
        </p>
        <p className='p11'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p12'>
          <span className='s1'>
            To you, at the current contact information on file with us at the
            time notice is given.
          </span>
        </p>
        <ol className='ol1'>
          <li className='li6'>
            <span className='s1'>
              <strong>Waiver.&nbsp;&nbsp;</strong>No term of this Agreement
              shall be deemed waived and no breach excused, unless such waiver
              or consent shall be in writing and signed by the party claimed to
              have waived or consented. Any consent by any party to, or waiver
              of a breach by the other, whether expressed or implied, shall not
              constitute consent to, waiver of, or excuse for any other
              different or subsequent breach.
            </span>
          </li>
          <li className='li6'>
            <span className='s1'>
              <strong>
                Complete Understanding;&nbsp;Amendments.&nbsp;&nbsp;
              </strong>
              This Agreement contains the entire understanding of the parties,
              and there are no other written or oral understandings or promises
              between the parties, with respect to the subject matter of this
              Agreement other than those contained or referenced in this
              Agreement.
            </span>
          </li>
          <li className='li6'>
            <span className='s1'>
              <strong>No Third-Party Beneficiaries.&nbsp;</strong>&nbsp;Except
              as expressly provided for in Sections 6, 9 and 14.2, nothing
              express or implied in this Agreement is intended to confer, nor
              shall confer, upon any person or entity other than the parties and
              their respective successors or assigns any rights, remedies,
              obligations, or liabilities whatsoever.
            </span>
          </li>
        </ol>
        <p className='p5'>&nbsp;</p>
        <p className='p5'>&nbsp;</p>
        <p className='p5'>&nbsp;</p>
        <p className='p5'>&nbsp;</p>
        <p className='p13'>
          <span className='s1'>
            <strong>
              PLEASE PRINT THESE TERMS OF SERVICE FOR YOUR RECORDS.
            </strong>
          </span>
        </p>
        <p className='p14'>&nbsp;</p>
        <p className='p4'>
          <span className='s1'>
            {' '}
            <strong> BUSINESS ASSOCIATE ADDENDUM</strong>
          </span>
        </p>
        <p className='p15'>
          <span className='s1'>
            {' '}
            THIS BUSINESS ASSOCIATE ADDENDUM (this &ldquo;BAA&rdquo;) is made by
            and between you (&ldquo;you&rdquo; or &ldquo;your&rdquo;) and
            BetterPT, Inc. (&ldquo;BetterPT&rdquo; or &ldquo;us&rdquo; or
            &ldquo;our&rdquo;) and will be in effect during the time that you
            have subscribed to our Services. During the course of providing the
            Services, we may have access to certain PHI (as defined herein)
            regulated by the Health Insurance Portability and Accountability Act
            of 1996, Public Law 104 191 as amended by the Health Information
            Technology for Economic and Clinical Health Act, and the privacy
            rule and security standards promulgated thereunder by the U.S.
            Department of Health and Human Services, as amended from time to
            time (collectively, &ldquo;HIPAA&rdquo;).{' '}
          </span>
        </p>
        <ol className='ol1'>
          <li className='li16'>
            <span className='s1'>
              <strong>Definitions</strong>
            </span>
          </li>
          <ol className='ol2'>
            <li className='li16'>
              <span className='s1'>
                &ldquo;PHI&rdquo; and &ldquo;e-PHI&rdquo; are understood to
                refer to your Protected Health Information and Electronic
                Protected Health Information, respectively, as such terms are
                defined in HIPAA.
              </span>
            </li>
            <li className='li16'>
              <span className='s1'>
                Capitalized terms not defined in this BAA shall have the meaning
                set forth in HIPAA.
              </span>
            </li>
          </ol>
          <li className='li16'>
            <span className='s1'>
              <strong>
                Obligations and Activities of BetterPT as a Business Associate
              </strong>
            </span>
          </li>
        </ol>
        <p className='p17'>
          <span className='s1'>BetterPT agrees:</span>
        </p>
        <ol className='ol1'>
          <ol className='ol2'>
            <li className='li16'>
              <span className='s1'>
                Not to use or disclose PHI other than as permitted or required
                by this BAA or as required by law;{' '}
              </span>
            </li>
            <li className='li16'>
              <span className='s1'>
                To use appropriate safeguards and comply, when applicable, with
                subpart C of HIPAA with respect to e-PHI, to prevent use or
                disclosure of PHI other than as provided for in this BAA;{' '}
              </span>
            </li>
            <li className='li16'>
              <span className='s1'>
                To report to you any use or disclosure of the PHI not provided
                for in this BAA of which it becomes aware;{' '}
              </span>
            </li>
            <li className='li16'>
              <span className='s1'>
                To send written notice to you of any Breach of Unsecured PHI,
                without unreasonable delay, and in no case later than 30
                calendar days after the Breach is Treated as Discovered;
              </span>
            </li>
            <li className='li16'>
              <span className='s1'>
                To ensure that any subcontractor of BetterPT that creates,
                receives, maintains or transmits PHI on behalf of BetterPT
                agrees to the same restrictions and conditions in this BAA with
                respect to such information;{' '}
              </span>
            </li>
            <li className='li16'>
              <span className='s1'>
                To promptly provide you access to PHI in a Designated Record Set
                in order to meet the requirements under 45 C.F.R. &sect;
                164.524;{' '}
              </span>
            </li>
            <li className='li16'>
              <span className='s1'>
                To make any amendments to PHI in a Designated Record Set, as
                directed by you, to ensure compliance with requests made by you
                or an Individual pursuant to 45 C.F.R. &sect;&nbsp;164.526;{' '}
              </span>
            </li>
            <li className='li16'>
              <span className='s1'>
                To the extent that BetterPT is carrying out any of your
                obligations under HIPAA, to comply with the provisions of HIPAA
                that apply to you in the performance of such obligations;
              </span>
            </li>
            <li className='li16'>
              <span className='s1'>
                To make internal practices, books, and records, including
                policies and procedures, relating to the use and disclosure of
                PHI received from, or created or received by BetterPT or any
                business associate (as defined in HIPAA) of BetterPT on your
                behalf, available to the Secretary for purposes of the Secretary
                determining your compliance with HIPAA;{' '}
              </span>
            </li>
            <li className='li16'>
              <span className='s1'>
                To make available the information required to provide an
                accounting of disclosures in accordance with 45 C.F.R.
                &sect;&nbsp;164.528;{' '}
              </span>
            </li>
          </ol>
          <li className='li16'>
            <span className='s1'>
              <strong>
                Permitted Uses and Disclosures by BetterPT as a Business
                Associate
              </strong>
            </span>
          </li>
          <ol className='ol2'>
            <li className='li16'>
              <span className='s1'>
                Except as otherwise limited in this BAA, BetterPT may use or
                disclose PHI on behalf of, or to provide Services to, you if
                such use or disclosure of PHI would not violate HIPAA if done by
                you, except that, unless otherwise limited in this BAA, BetterPT
                may:
              </span>
            </li>
            <ol className='ol3'>
              <li className='li16'>
                <span className='s1'>
                  Use the PHI in its possession for its proper management and
                  administration and to fulfill any of its present or future
                  legal responsibilities provided that such uses are permitted
                  under state and federal confidentiality laws;{' '}
                </span>
              </li>
              <li className='li16'>
                <span className='s1'>
                  Use the PHI in its possession to provide data aggregation
                  services relating to the health care operations of you; and
                </span>
              </li>
              <li className='li16'>
                <span className='s1'>
                  De-identify PHI in its possession and use and disclose such
                  de-identified data for any purposes that comports with
                  applicable law.{' '}
                </span>
              </li>
              <li className='li16'>
                <span className='s1'>
                  Disclose the PHI in its possession to third parties for the
                  purpose of its proper management and administration or to
                  fulfill any of its present or legal responsibilities provided
                  that: (i) the disclosures are required by law, or (ii)
                  BetterPT has received from the third party reasonable written
                  assurances that the PHI will be held confidentially, that it
                  will only be used or further disclosed as required by law or
                  for the purpose for which it was disclosed to the third party,
                  and that the third party will notify BetterPT of any instances
                  of which it is aware in which the confidentiality of the
                  information has been breached, as required under
                  45&nbsp;C.F.R.&nbsp;&sect;&nbsp;164.504(e)(4).{' '}
                </span>
              </li>
            </ol>
          </ol>
          <li className='li16'>
            <span className='s1'>
              <strong>Obligations of YOU as a Covered Entity</strong>
            </span>
          </li>
        </ol>
        <p className='p17'>
          <span className='s1'>You shall:</span>
        </p>
        <ol className='ol1'>
          <ol className='ol2'>
            <li className='li16'>
              <span className='s1'>
                Notify BetterPT of any limitation(s) in its notice of privacy
                practices in accordance with 45 C.F.R. &sect; 164.520, to the
                extent that such limitation may affect BetterPT&apos;s use or
                disclosure of PHI;{' '}
              </span>
            </li>
            <li className='li16'>
              <span className='s1'>
                Notify BetterPT of any changes in, or revocation of, permission
                by an Individual to use or disclose PHI, to the extent that such
                changes may affect BetterPT&apos;s use or disclosure of PHI; and{' '}
              </span>
            </li>
            <li className='li16'>
              <span className='s1'>
                Notify BetterPT of any restriction to the use or disclosure of
                PHI that you have agreed to in accordance with 45 C.F.R. &sect;
                164.522, to the extent that such restriction may affect
                BetterPT&apos;s use or disclosure of PHI.
              </span>
            </li>
            <li className='li16'>
              <span className='s1'>
                Be responsible for obtaining and documenting authorizations or
                requests from patients to communicate through the Services, if
                you use such functionality, and inform patient of risks
                associated with such communications as applicable. You are
                responsible for determining what permissions, authorizations or
                consents shall be documented and maintained for HIPAA compliance
                purposes. We do not obtain consents, authorizations or
                permissions from patients and the parties agree that it is not
                our obligation to do so or to document or maintain any consent,
                authorization or permission obtained from patients.
              </span>
            </li>
            <li className='li16'>
              <span className='s1'>Comply with the HIPAA Security Rule.</span>
            </li>
          </ol>
          <li className='li16'>
            <span className='s1'>
              <strong>Permissible Requests by you as a Covered Entity</strong>
            </span>
          </li>
        </ol>
        <p className='p18'>
          <span className='s1'>
            You shall not request BetterPT to use or disclose PHI in any manner
            that would not be permissible under HIPAA if done by you.&nbsp;
          </span>
        </p>
        <ol className='ol1'>
          <li className='li16'>
            <span className='s1'>
              <strong>Term and Termination</strong>
            </span>
          </li>
          <ol className='ol2'>
            <li className='li16'>
              <span className='s5'>Term</span>
              <span className='s1'>
                .<span className='Apple-converted-space'>&nbsp; </span>
                Notwithstanding any term or provision or agreement to the
                contrary, this BAA shall be effective as of the first date that
                BetterPT provides you with Services and shall terminate when all
                of the PHI, including all ePHI, provided by you to BetterPT, or
                created or received by BetterPT on your behalf, is destroyed or
                returned to you.{' '}
              </span>
            </li>
            <li className='li16'>
              <span className='s5'>Your Termination of BAA for Cause</span>
              <span className='s1'>
                .<span className='Apple-converted-space'>&nbsp; </span>In the
                event of a material breach by BetterPT of this BAA, you shall
                either at your option:{' '}
              </span>
            </li>
            <ol className='ol3'>
              <li className='li16'>
                <span className='s1'>
                  Provide written notice of the breach to BetterPT and an
                  opportunity for BetterPT to cure the breach, and terminate
                  this BAA if BetterPT does not cure the breach within the time
                  specified by you; or
                </span>
              </li>
              <li className='li19'>
                <span className='s1'>
                  Immediately terminate the parties&rsquo; sharing of PHI and
                  this BAA if BetterPT has breached a material term of this BAA
                  and cure is not possible.
                </span>
              </li>
            </ol>
          </ol>
        </ol>
        <p className='p20'>
          <span className='s1'>
            In either case you shall have the right to report the violation to
            the Secretary.
          </span>
        </p>
        <ol className='ol1'>
          <ol className='ol2'>
            <li className='li16'>
              <span className='s5'>
                BetterPT&rsquo;s Termination of BAA for Cause
              </span>
              <span className='s1'>
                .<span className='Apple-converted-space'>&nbsp; </span>In the
                event of a material breach by you of this BAA, BetterPT shall
                either at its option:
              </span>
            </li>
            <ol className='ol3'>
              <li className='li16'>
                <span className='s1'>
                  Provide written notice of the breach to you and an opportunity
                  for you to cure the breach, and terminate this BAA if you do
                  not cure the breach within the time specified by BetterPT; or
                </span>
              </li>
              <li className='li19'>
                <span className='s1'>
                  Immediately terminate the parties&rsquo; sharing of PHI and
                  this BAA if you have breached a material term of this BAA and
                  cure is not possible.
                </span>
              </li>
            </ol>
          </ol>
        </ol>
        <p className='p20'>
          <span className='s1'>
            In either case BetterPT shall have the right to report the violation
            to the Secretary.{' '}
          </span>
        </p>
        <ol className='ol1'>
          <ol className='ol2'>
            <li className='li16'>
              <span className='s5'>Effect of Termination</span>
              <span className='s1'>. </span>
            </li>
            <ol className='ol3'>
              <li className='li16'>
                <span className='s1'>
                  Except as provided in subparagraph (ii) of this Section 6(d),
                  upon termination of this BAA for any reason, BetterPT shall
                  return or destroy all PHI, including all e-PHI, received from
                  you, or created or received by BetterPT on behalf of you.
                  <span className='Apple-converted-space'>&nbsp; </span>This
                  provision shall apply to all PHI (including e-PHI) that is in
                  the possession of subcontractors or agents of BetterPT.
                  <span className='Apple-converted-space'>&nbsp; </span>
                  BetterPT shall retain no copies of the PHI.{' '}
                </span>
              </li>
              <li className='li16'>
                <span className='s1'>
                  In the event that BetterPT determines that returning or
                  destroying the PHI is infeasible, BetterPT shall provide to
                  you notification of the conditions that make return or
                  destruction infeasible.
                  <span className='Apple-converted-space'>&nbsp; </span>Upon
                  your confirmation that return or destruction of PHI is
                  infeasible, BetterPT shall extend the protections of this BAA
                  to such PHI and limit further uses and disclosures of such PHI
                  to those purposes that make the return or destruction
                  infeasible, for so long as BetterPT maintains such PHI.{' '}
                </span>
              </li>
            </ol>
          </ol>
          <li className='li16'>
            <span className='s1'>
              <strong>Miscellaneous</strong>
            </span>
          </li>
          <ol className='ol2'>
            <li className='li16'>
              <span className='s5'>Amendment</span>
              <span className='s1'>
                .<span className='Apple-converted-space'>&nbsp; </span>The
                Parties acknowledge that state and federal laws relating to
                electronic data security and privacy are rapidly evolving and
                that amendment of this BAA may be required to ensure compliance
                with such developments. Applicable HIPAA provisions, together
                with any guidance issued by the Secretary, and any applicable
                amendments to federal and state privacy law, are hereby
                incorporated by reference and will become part of this BAA as if
                set forth in their entirety, effective as of the applicable
                effective date(s).
              </span>
            </li>
            <li className='li16'>
              <span className='s5'>Interpretation</span>
              <span className='s1'>
                . Any ambiguity in this BAA shall be resolved to permit the
                parties to comply with HIPAA.
              </span>
            </li>
          </ol>
        </ol>

        <p className='p3'>&nbsp;</p>
        <p className='p13'>
          <span className='s1'>
            <strong>
              PLEASE PRINT THESE TERMS OF SERVICE FOR YOUR RECORDS.
            </strong>
          </span>
        </p>
      </div>
      <style>
        {`
      .section {
        width: 900px;
        margin: 0 auto;
        text-align: left;
        margin-top: 50px;
        margin-bottom: 100px;
      }

      .logo {
        text-align: center;
        padding-top: 15px;
      }

      .section h1,
      .section h2,
      .section h3,
      .section h4 {
        color: #00207f;
      }

      .section h1 {
        font-size: 30px;
        line-height: 33px;
      }

      .section h2 {
        font-size: 24px;
        line-height: 28px;
      }

      .section h3 {
        font-size: 22px;
        line-height: 28px;
      }

      .section h4 {
        font-size: 20px;
        line-height: 28px;
      }

      .section ul, .section ol {
        list-style-type: circle;
        margin-left: 20px;
        margin-bottom: 20px;
      }

      `}
      </style>
    </div>
  );
};

export default TermsOfUse;
