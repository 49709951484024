import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

export const WelcomeContainer = styled.div`
  margin: 0px auto;
  background-color: ${colorValues.earlysteam};
  height: 100vh;
  width: 100vw;
  h1 {
    text-align: center;
    padding-top: 8vh;
  }
`;

export const BodySection = styled.div`
  border-top: ${colorValues.concretejungle} 1px solid;
  border-bottom: ${colorValues.concretejungle} 1px solid;
  height: 539px;
  width: 770px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const WhiteBox = styled.div`
  width: 564px;
  height: 460px;
  background-color: ${colorValues.emptiness};
  border: 2px solid ${colorValues.dust};
  position: relative;
`;

export const Circle = styled.div`
  background-color: ${(props) => props.color};
  height: ${(props) => props?.style?.height};
  width: ${(props) => props?.style?.width};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 38px;
    width: 38px;
  }
`;

export const logoCircleStyle = {
  height: '64px',
  width: '64px',
  position: 'absolute' as 'absolute',
  top: '-32px',
  left: '50%',
  marginLeft: '-32px',
  zIndex: 1,
};

export const GrayLine = styled.div`
  margin-top: 60px;
  margin-bottom: 50px;
  width: 100%;
  border-bottom: 1px solid ${colorValues.concretejungle};
`;

export const numberCircleStyle = {
  height: '38px',
  width: '38px',
  marginRight: '44px',
};

export const StepsContainer = styled.div`
  height: 275px;
  width: 350px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 2;
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
`;

export const VerticalLine = styled.div`
  height: 250px;
  width: 1px;
  background-color: ${colorValues.dust};
  position: absolute;
  left: 19px;
  top: 28px;
  z-index: -1;
`;

export const FooterSection = styled.div`
  text-align: center;
  margin: 40px;
`;
