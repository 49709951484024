import React, { SetStateAction, Dispatch } from 'react';
import { colorValues, TextField } from '@betterpt/better-components';

const textFieldStyle = { margin: '10px 0px 20px 0px' };


type Props = {
    value?: string;
    label: string;
    onChange: any;
    options: string[]; // override default options for selector
    elementId?: Number;
    elementKey?: string;
    prompt?: any;
    changeTypeState?: any;
    hasDependency?: boolean;
}

const QuestionTypeSelectInputNew = (props: Props) => {
    const {
        value = '',
        label,
        onChange,
        options,
        elementId,
        elementKey,
        prompt,
        hasDependency,
        changeTypeState } = props;

    const styleOverride = {
        '& label.Mui-focused': {
            color: colorValues.guajirogreen,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: colorValues.guajirogreen,
        },
    };
    return (
        <TextField
            value={value}
            label={label}
            onChange={(e) => {
                if (value === "Yes/No" && e.target.value !== "Yes/No" && hasDependency) {
                    prompt({ ...changeTypeState, type: true, selectedId: elementId, value: e.target.value });
                } else {
                    onChange(elementId, elementKey, e.target.value)
                }
            }}
            fullWidth
            select
            SelectProps={{ native: true }}
            style={textFieldStyle}
            overrideStyle={styleOverride}
            inputProps={{
                style: {
                    fontWeight: 'bold',
                    fontSize: '12px',
                },
            }}
        >
            <option disabled value={''}></option>
            {options?.map((choice) => (
                <option key={choice as string} value={choice as string}>
                    {choice}
                </option>
            ))}
        </TextField>
    );
};

export default QuestionTypeSelectInputNew;
