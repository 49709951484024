import * as React from 'react';

//types
import { Role, Employee } from 'apps/provider-app/src/generated/graphql';

//helpers
import { colorValues } from '@betterpt/better-components';
import { addIndefiniteArticle, normalizePhone } from 'apps/provider-app/src/helpers';

//hooks
import { useMeQuery, useEmployeeQuery, useFacilityEmployeesQuery } from 'apps/provider-app/src/generated/graphql';
import useRemoteConfigAliases from 'apps/provider-app/src/hooks/useRemoteConfigAliases';

//components
import Autocomplete from '@material-ui/lab/Autocomplete';
import ThemedTextField from 'apps/provider-app/src/components/Shared/ThemedTextField';

//assets
import { IcClose, IcDropDownSelected } from '@betterpt/better-icons';

//styles
import { GeneralizedAutocompleteStyleWrapper, generalizedEmployeeDrawerContainer } from './EmployeePicker.style';

type Props = {
  employee: {
    value: string;
    updateValue: (id: string) => void;
  };
  secondaryEmployee?: {
    value: string;
    updateValue: (id: string) => void;
  };
  facilityId?: string;
  telehealth?: boolean;
};

const placeholderEmployee: Partial<Employee> = {
  id: '0',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  hasValidatedMobileNumber: false,
  role: Role.Self,
  hasLoggedIn: true,
  isTherapist: true,
  clinics: [],
}; //NOTE: this placeholder is used to prevent the Autocomplete
//controlled/uncontrolled error when no employee is selected yet

const GeneralizedEmployeePicker: React.FC<React.PropsWithChildren<Props>> = ({ facilityId, employee }) => {
  const [open, setOpen] = React.useState(false);
  const [nameInput, updateNameInput] = React.useState('');
  const [selectedPrimary, updateSelectedPrimary] = React.useState<Partial<Employee>>(placeholderEmployee);

  const { employeeSingularAlias, facilitiesSingularAlias } = useRemoteConfigAliases();

  const queryMe = useMeQuery();

  const queryEmployees = useFacilityEmployeesQuery({
    variables: {
      id: facilityId || '',
    },
    fetchPolicy: 'cache-and-network',
    skip: !facilityId,
  });

  const employeeDetails = useEmployeeQuery({
    variables: {
      id: employee.value,
    },
    skip: !employee.value,
  });

  // values
  const isBasicAccount = queryMe.data?.me?.role === Role.Self;
  const employees: any[] = isBasicAccount ? [queryMe.data?.me] : queryEmployees.data?.clinic?.employees ?? [];
  const providers = employees.filter((staff) => staff?.isTherapist);

  const primaryEmployeeCopy = employeeSingularAlias;

  const sharedProps = {
    selectOnFocus: true,
    clearOnBlur: true,
    blurOnSelect: true,
    fullWidth: true,
  };

  React.useEffect(() => {
    const existingPrimary = employeeDetails.data?.employee;

    const existingPrimaryName = `${existingPrimary?.firstName || ''} ${existingPrimary?.lastName || ''}`.trim();

    if (providers.length) {
      if (existingPrimary) {
        updateNameInput(existingPrimaryName);
        updateSelectedPrimary(existingPrimary as Employee);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeDetails.data, providers]);

  const renderDropdown = (employee: any) => {
    if (employee?.id === '0') {
      if (providers.length === 0) {
        return `You need at least one ${primaryEmployeeCopy.toLowerCase()} at your ${facilitiesSingularAlias.toLowerCase()} to continue`;
      } else {
        return `Select ${addIndefiniteArticle(primaryEmployeeCopy.toLowerCase())}...`;
      }
    }
    return `${employee?.firstName} ${employee?.lastName} (
        ${employee?.email}
        ${employee?.phone ? `, ${normalizePhone(employee?.phone)}` : ''}
        )`;
  };

  if (queryMe.loading || queryEmployees.loading) {
    return (
      <div style={generalizedEmployeeDrawerContainer}>
        <ThemedTextField label={primaryEmployeeCopy} style={{ maxWidth: '521px' }} disabled fullWidth value={'Loading...'} />
      </div>
    );
  }

  return (
    <GeneralizedAutocompleteStyleWrapper text={nameInput}>
      <div style={generalizedEmployeeDrawerContainer}>
        <div style={{ width: '100%' }}>
          <Autocomplete
            {...sharedProps}
            options={[placeholderEmployee, ...providers]}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            disabled={!facilityId}
            style={{ maxWidth: '521px' }}
            loading={queryEmployees.loading}
            loadingText="Loading..."
            inputValue={nameInput}
            onInputChange={(_, newInputValue, reason) => {
              if (reason !== 'reset') {
                updateNameInput(newInputValue);
              }
            }}
            getOptionLabel={(option: any) => `${option?.firstName} ${option?.lastName}`}
            getOptionDisabled={(option) => option?.id === '0'}
            getOptionSelected={(option: any) => {
              const optionName = `${option?.firstName ?? ''} ${option?.lastName ?? ''}`;
              const selectedPrimaryName = `${selectedPrimary?.firstName ?? ''} ${selectedPrimary?.lastName ?? ''}`;
              const normalizedOptionName = optionName.toLowerCase().trim();
              const normalizedSelectedName = selectedPrimaryName.toLowerCase().trim();
              return normalizedOptionName === normalizedSelectedName;
            }}
            value={selectedPrimary}
            onChange={(_, value) => {
              if (!value) {
                employee.updateValue('');
              } else {
                if (typeof value === 'string') {
                  updateSelectedPrimary(JSON.parse(value));
                  employee.updateValue(JSON.parse(value)?.id);
                } else {
                  updateSelectedPrimary(value);
                  employee.updateValue(value?.id);
                }
              }
            }}
            data-testid="create-appointment-employee-info"
            renderInput={(params) => (
              <ThemedTextField
                {...params}
                label={primaryEmployeeCopy}
                placeholder={`Type to search for ${addIndefiniteArticle(primaryEmployeeCopy).toLowerCase()}`}
                data-cy="generalized-employee-picker"
              />
            )}
            renderOption={(employee) => {
              return <p style={{ margin: '0px' }}>{renderDropdown(employee)}</p>;
            }}
            popupIcon={<IcDropDownSelected style={{ padding: 12 }} color={colorValues.betterptblack} />}
            closeIcon={<IcClose color={colorValues.betterptblack} />}
          />
        </div>
      </div>
    </GeneralizedAutocompleteStyleWrapper>
  );
};

export default GeneralizedEmployeePicker;
