// Paths for Tableau.
export const TABLEAU_PATHS = {
  production: {
    appointment_modification: 'appointment_modification_16813175782060/appointment_modification',
    appointment_volume: 'appointment_requests_by_volume_16835633953240/appointment_requests_by_volume',
    channel_attribution: 'channel_attribution_16756970181780/channel_attribution'
  },
  staging: {
    appointment_modification: 'appointment_modification_16800848901310/appointment_modification',
    appointment_volume: 'appointment_requests_by_volume_16800849143980/appointment_requests_by_volume',
    channel_attribution: 'channel_attribution_16728326191770/channel_attribution'
  },
  development: {
    appointment_modification: 'appointment_modification_16800848901310/appointment_modification',
    appointment_volume: 'appointment_requests_by_volume_16800849143980/appointment_requests_by_volume',
    channel_attribution: 'channel_attribution_16728326191770/channel_attribution'
  },
  test: {
    appointment_modification: 'appointment_modification_16800848901310/appointment_modification',
    appointment_volume: 'appointment_requests_by_volume_16800849143980/appointment_requests_by_volume',
    channel_attribution: 'channel_attribution_16728326191770/channel_attribution'
  }
} as const