import * as React from 'react';
import styled from '@emotion/styled';

//types
import { TimeDisplayValue } from '../../../../../../helpers/telehealthAppointmentsFilterHelper';
import {
  NewAppointmentStatus
} from '../../../../../../generated/graphql';
import Flags from '../../../../../../constants/flags';
import { AnalyticsListType } from '../../../../Company/AnalyticsTab';

//helpers
import { endOfYear, format, subYears } from 'date-fns';

// hooks
import { useHistory } from 'react-router-dom';
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';
import useInPersonVideoAppointmentFilters from '../../../../../../hooks/useInPersonAppointmentFilters';

// components
import {
  DateFilter,
  CreatedDateFilter,
  FacilitiesFilter,
  PatientFilter,
  DateOrderToggle,
  EmployeesFilter,
  CardListToggle,
  SaveFilters,
  ScheduleStatusFilter,
} from '../../../../../Shared/Filters';
import InPersonAppointmentTypeFilter from './InPersonAppointmentTypeFilter';
import { Button } from '@betterpt/better-components';
import FeatureFlagCheck from '../../../../../../components/Shared/FeatureFlagCheck';

// assets
import { IcLoading } from '@betterpt/better-icons';

// style
import * as styles from './Filters.style';
import { colorValues } from '@betterpt/better-components';

interface LocationState {
  analytics: AnalyticsListType;
}

const Filters = () => {
  const history = useHistory<LocationState>();

  const filters = useInPersonVideoAppointmentFilters();

  const { primaryColor } = useRemoteConfigAliases();

  React.useEffect(() => {
    const analyticsType = history?.location?.state?.analytics;

    if (!!analyticsType) {
      const now = new Date();
      const start = subYears(now, 1);
      //set the date for previous year
      const setRangeToPreviousYear = () => {
        filters?.start.update(format(start, 'yyyy-MM-dd'));
        filters?.end.update(format(now, 'yyyy-MM-dd'));
        filters.timeDisplayValue.update(TimeDisplayValue.Range);
      };
      switch (analyticsType) {
        case 'requests':
          filters.appointmentStatuses.update([NewAppointmentStatus.Requested]);
          filters.isAssigned?.update(null);
          //set the date for this entire last year until end of the current
          filters?.start.update(format(subYears(now, 1), 'yyyy-MM-dd'));
          filters?.end.update(format(endOfYear(now), 'yyyy-MM-dd'));
          filters.timeDisplayValue.update(TimeDisplayValue.Year);
          break;
        case 'total':
          filters.appointmentStatuses.update([]);
          filters.isAssigned?.update(null);
          setRangeToPreviousYear();
          break;
        case 'confirmed':
          filters.appointmentStatuses.update([NewAppointmentStatus.Upcoming]);
          filters.isAssigned?.update(null);
          setRangeToPreviousYear();
          break;
        case 'declined':
          filters.appointmentStatuses.update([NewAppointmentStatus.Declined]);
          filters.isAssigned?.update(null);
          setRangeToPreviousYear();
          break;
        default:
          filters.appointmentStatuses.update([]);
          filters.isAssigned?.update(null);
          setRangeToPreviousYear();
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history?.location?.state?.analytics]);

  return (
    <styles.Filters data-testid='filters-container'>
      <styles.FilterRow>
        <styles.FilterSection>
          <CreatedDateFilter
            filters={filters}
            primaryColor={primaryColor}
            isTelehealth={false}
          />
          <DateFilter
            filters={filters}
            primaryColor={primaryColor}
            isTelehealth={false}
          />
          <PatientFilter filters={filters} primaryColor={primaryColor}/>
          <EmployeesFilter filters={filters} primaryColor={primaryColor} />
          <DateOrderToggle filters={filters} />
          <FacilitiesFilter filters={filters} primaryColor={primaryColor} />
          <InPersonAppointmentTypeFilter
            filters={filters}
            primaryColor={primaryColor}
          />
          <FeatureFlagCheck
            flag={Flags.AssignAppointments}
            yes={() => (
              <ScheduleStatusFilter
                filters={filters}
                primaryColor={primaryColor}
              />
            )}
          />
          <CardListToggle />
          <SaveFilters onSave={filters.saveDefaultFilterURL} />
          <Button
          className="activeButtonNone"
          size='small'
          color='transparent'
          onClick={filters?.resetFilters}
          style={{
            color: colorValues.cityblock,
            marginTop: 14,
            padding: 0,
          }}
        >
          <IcLoading color={colorValues.cityblock} style={{ flexShrink: 0 }} />
          <p style={{fontSize: 12}}>RESET FILTERS</p>
        </Button>
        </styles.FilterSection>
      </styles.FilterRow>
    </styles.Filters>
  );
};

export default Filters;