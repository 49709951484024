// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app';

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

const firebaseConfig = {
  apiKey:
    window.__RUNTIME_CONFIG__.REACT_APP_FIREBASE_API_KEY ??
    'AIzaSyB7LSWZwqSyBXHkwRnvo2v0SrETll9A7do', // same
  authDomain:
    window.__RUNTIME_CONFIG__.REACT_APP_FIREBASE_AUTH_DOMAIN ??
    'genericpractitionerapp-staging.firebaseapp.com', // same
  databaseURL:
    window.__RUNTIME_CONFIG__.REACT_APP_FIREBASE_DATABASE_URL ??
    'https://genericpractitionerapp-staging.firebaseio.com', // same
  projectId:
    window.__RUNTIME_CONFIG__.REACT_APP_FIREBASE_PROJECT_ID ??
    'genericpractitionerapp-staging', // same
  storageBucket:
    window.__RUNTIME_CONFIG__.REACT_APP_FIREBASE_STORAGE_BUCKET ??
    'genericpractitionerapp-staging.appspot.com', // same
  messagingSenderId:
    window.__RUNTIME_CONFIG__.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ?? '715457607229', // same
  appId:
    window.__RUNTIME_CONFIG__.REACT_APP_FIREBASE_APP_ID ??
    '1:715457607229:web:854ed1908ef632930c5c54', // different
  measurementId:
    window.__RUNTIME_CONFIG__.REACT_APP_FIREBASE_MEASUREMENT_ID ?? 'G-CH61M8KDXB', // different
};

export let firebaseAnalytics: firebase.analytics.Analytics;
const configureFirebase = () => {
  firebase.initializeApp(firebaseConfig);
  firebaseAnalytics = firebase.analytics();
};

export default configureFirebase;

/*

    // staging

    apiKey: "AIzaSyB7LSWZwqSyBXHkwRnvo2v0SrETll9A7do",
    authDomain: "genericpractitionerapp-staging.firebaseapp.com",
    databaseURL: "https://genericpractitionerapp-staging.firebaseio.com",
    projectId: "genericpractitionerapp-staging",
    storageBucket: "genericpractitionerapp-staging.appspot.com",
    messagingSenderId: "715457607229",
    appId: "1:715457607229:web:854ed1908ef632930c5c54",
    measurementId: "G-CH61M8KDXB"

*/
