import React from 'react';

//hooks
import { useHistory } from 'react-router-dom';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';

//components
import { Animation, Button } from '@betterpt/better-components';

//assets
import baTablet from '../../../../../assets/img-better-access-tablet.png';
import { IcEmail } from '@betterpt/better-icons';

//styles
import { Loader } from '../../../../Home/Home.style';
import {
  CSVDownloadBody,
  CVSDownloadCard,
  CSVDownloadHeader,
  CSVDownloadHeaderSection,
  CSVDownloadIcon,
  ExternalAccessImage,
  ExternalAnalayticsCard,
  ExternalAnalyticsContainer,
  ExternalAnalyticsHeader,
  ExternalAnalyticsMessage,
} from '../AnalyticsCSVTab.style';
import { colorValues, bestTextColor } from '@betterpt/better-components';
const linkStyle = { width: '100%' };
const buttonStyle = { backgroundColor: colorValues.ibizateal };
const csvButtonStyle = (tertiaryColor: string) => ({
  backgroundColor: tertiaryColor,
  color: tertiaryColor === colorValues.frost ? colorValues.eveningsky : bestTextColor(tertiaryColor),
});

interface Props {
  loading: boolean;
  externalDataPlatformLink?: string | null;
}

const ExternalAnalytics = ({ loading, externalDataPlatformLink }: Props) => {
  const { push } = useHistory();
  const { patientsPluralAlias, tertiaryColor } = useRemoteConfigAliases();

  return loading ? (
    <Loader>
      <Animation type="providerAppLoader" />
    </Loader>
  ) : (
    <ExternalAnalyticsContainer>
      <ExternalAnalayticsCard>
        <ExternalAccessImage>
          <img src={baTablet} alt="BetterAccess App" />
        </ExternalAccessImage>
        <ExternalAnalyticsHeader>BetterAccess Data and Analytics</ExternalAnalyticsHeader>
        <ExternalAnalyticsMessage>
          Your pricing package allows for in-depth analytics. Click the button below to log in with the Looker username and password sent
          from Customer Support for actionable insights about your appointments, {patientsPluralAlias.toLowerCase()}, and lead sources.
        </ExternalAnalyticsMessage>
        <a href={externalDataPlatformLink ?? ''} style={linkStyle} target="_blank" rel="noreferrer">
          <Button fullWidth size="large" style={buttonStyle}>
            GO TO DATA AND ANALYTICS
          </Button>
        </a>
      </ExternalAnalayticsCard>
      <CVSDownloadCard>
        <CSVDownloadHeaderSection>
          <CSVDownloadIcon>
            <IcEmail color={colorValues.emptiness} />
          </CSVDownloadIcon>
          <CSVDownloadHeader>CSV Download</CSVDownloadHeader>
        </CSVDownloadHeaderSection>
        <CSVDownloadBody>
          <p>
            Recieve a CSV of all data on in-person appointments to the email associated with your account. For security reasons, you will
            have a ten minute window to download this file. You will also not be allowed to download more than three months{'’'} data at a
            time.
          </p>
          <Button fullWidth style={csvButtonStyle(tertiaryColor)} onClick={() => push('/embedded-analytics/in-person-csv')}>
            GET IN-PERSON CSV
          </Button>
        </CSVDownloadBody>
      </CVSDownloadCard>
    </ExternalAnalyticsContainer>
  );
};

export default ExternalAnalytics;
