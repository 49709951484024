import { jsx } from '@emotion/react';
import styled from '@emotion/styled';

import { numberStyle, numbersSection } from './style';

const Div = styled.div``;
const Button = styled.button``;

type Props = {
  pages: number;
  activePage: number;
  sections: number;
  activeSection: number;
  total: number;
  pageSize: number;
  pagesInSection: number;
  activePageClick: (index: number) => void;
  primaryColor?: string;
};
export default ({
  sections,
  pages,
  activeSection,
  total,
  pageSize,
  pagesInSection,
  activePage,
  activePageClick,
  primaryColor,
}: Props) => {
  let number;
  if (sections === 1) {
    number = pages;
  } else if (activeSection === sections) {
    const extra = total - (sections - 1) * pageSize * pagesInSection;
    number = Math.ceil(extra / pageSize);
  } else {
    number = pagesInSection;
  }

  return (
    <Div css={numbersSection}>
      {Array(number)
        .fill(0, 0, number)
        .map((_num, idx) => {
          let realIndex: number;
          if (activeSection === 1) {
            realIndex = idx;
          } else if (activeSection === 2) {
            realIndex = pagesInSection + idx;
          } else {
            realIndex = pagesInSection * (activeSection - 1) + idx;
          }
          const isActive = activePage === realIndex;

          return (
            <Button
              css={numberStyle(isActive, primaryColor)}
              key={realIndex}
              onClick={() => activePageClick(realIndex)}
            >
              <p>{realIndex + 1}</p>
            </Button>
          );
        })}
    </Div>
  );
};
