import styled from '@emotion/styled';

export const DialogTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  padding: 50px 50px 0 50px;
`;

export const DialogBody = styled.div`
  padding: 40px 50px 50px;
  position: relative;
  width: 500px;
`;
