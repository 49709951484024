import { Roles, permissionsToEditByRole } from './rbac-rules';

import { Clinic } from '../generated/graphql';

export const canEditRole = ({
  currentUserRole,
  targetRole,
}: {
  currentUserRole: Roles;
  targetRole: Roles;
}) => {
  return permissionsToEditByRole[currentUserRole]?.includes(Roles[targetRole]);
};

export const canViewFacility = ({
  employeeClinics,
  clinicToView,
}: {
  employeeClinics: Clinic[];
  clinicToView: Clinic;
}) => {
  const viewableFacility = employeeClinics.find((clinic) => {
    return clinic?.id === clinicToView?.id;
  });

  return !!viewableFacility;
};
