import React from 'react';

//types
import { Role } from '../../../generated/graphql';

//hooks
import { useRouteMatch } from 'react-router-dom';
import {
  useMeQuery,
  useTotalFacilitiesQuery,
  useTotalPatientsQuery,
} from '../../../generated/graphql';
import useFeatureAccessCheck from '../../../hooks/useFeatureAccessCheck';
import useSnackbar from '../../../hooks/useSnackbar';

// components
import { Switch, Route } from 'react-router-dom';
import PatientList from './PatientList';
import CreatePatient from './CreatePatient';
import PatientContactDetail from './PatientContactDetail';
import PatientDetail from './PatientDetail';
import NoPatientsFound from './components/NoPatientsFound';

const Patients = () => {
  const snackbar = useSnackbar();
  const { url } = useRouteMatch();
  const {
    canAssignSelfToFacilities,
    loading: companyFeaturesLoading,
  } = useFeatureAccessCheck();
  const meQuery = useMeQuery();
  const totalFacilities = useTotalFacilitiesQuery({
    variables: {
      id: meQuery?.data?.me?.company?.id as string,
    },
    skip: !meQuery?.data?.me?.company?.id,
    fetchPolicy: 'cache-and-network',
  });
  const totalPatients = useTotalPatientsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const queryError =
    meQuery.error || totalFacilities.error || totalPatients.error;

  React.useEffect(() => {
    if (queryError?.message) {
      snackbar?.useGenericErrorMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryError]);

  const loading =
    companyFeaturesLoading ||
    meQuery.loading ||
    totalFacilities.loading ||
    totalPatients.loading;
  const role = meQuery.data?.me?.role;
  const hasBillingAccess = role === Role.Owner || role === Role.Admin;
  const noAssignedFacilities = !meQuery.data?.me?.clinics?.length;
  const companyHasNoFacilities =
    hasBillingAccess &&
    (!totalFacilities.data ||
      totalFacilities.data?.company?.clinics?.pager?.total === 0) &&
    !totalFacilities.loading;
  const noFacilities =
    (noAssignedFacilities && !canAssignSelfToFacilities) ||
    companyHasNoFacilities;
  const noPatients =
    (!totalPatients.data || totalPatients.data?.contacts?.pager?.total === 0) &&
    !totalPatients.loading;

  const copyOptions = {
    noFacilities,
    companyHasNoFacilities,
    noAssignedFacilities,
    noPatients,
  };

  const showNoPatientsView = (noFacilities || noPatients) && !loading;

  return (
    <Switch>
      <Route path={`${url}/new`} exact>
        <CreatePatient />
      </Route>
      {showNoPatientsView ? (
        <NoPatientsFound copyOptions={copyOptions} />
      ) : (
        <>
          <Route path={`${url}`} exact>
            <PatientList />
          </Route>
          <Route path={`${url}/:patientId`} exact>
            <PatientContactDetail />
          </Route>
          <Route path={`${url}/:patientId/access-details`} exact>
            <PatientDetail />
          </Route>
        </>
      )}
    </Switch>
  );
};

export default Patients;
