import React from 'react';
import { useHistory } from 'react-router-dom';

//components
import { colorValues, Table } from '@betterpt/better-components';
import { GridCard } from '../../../Shared/GridCard';
import { ListTitle, CardListBody } from '../styles/Profile.style';

//queries
import { useMeQuery } from '../../../../generated/graphql';

//hooks
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';

// assets
import { IcSuccessCircle } from '@betterpt/better-icons';

const FacilitiesList = () => {
  const history = useHistory();

  const {
    facilitiesPluralAlias,
    facilitiesSingularAlias,
  } = useRemoteConfigAliases();

  const meQuery = useMeQuery();

  const noFacilities =
    meQuery.called && !meQuery.loading && !meQuery.data?.me?.clinics?.length;

  const tableColumns = [
    { field: 'facilityName', title: 'NAME' },
    { field: 'id', title: 'ID' },
    { field: 'timeZone', title: 'TIME ZONE' },
    { field: 'address', title: 'ADDRESS' },
    { field: 'city', title: 'CITY' },
    { field: 'state', title: 'STATE' },
    {
      field: 'isMyFacility',
      title: `IS MY ${facilitiesSingularAlias.toUpperCase()}`,
    },
  ];

  const rowData =
    meQuery.data?.me?.clinics?.map((clinic) => ({
      facilityName: clinic?.clinicName ?? '-',
      id: clinic?.id ?? '-',
      timeZone: clinic?.timeZone ?? '-',
      address: clinic?.location?.address ?? '-',
      city: clinic?.location?.city ?? '-',
      state: clinic?.location?.state ?? '-',
      isMyFacility: (
        <p
          style={{
            margin: 0,
            color: colorValues.betterptblack,
            position: 'relative',
          }}
        >
          <span style={{ position: 'absolute', left: '-27px', bottom: '-7px' }}>
            <IcSuccessCircle color={colorValues.guajirogreen} />
          </span>{' '}
          is my {facilitiesSingularAlias.toLowerCase()}
        </p>
      ),
    })) ?? [];

  return (
    <>
      <ListTitle>
        {noFacilities ? '' : 'My '}
        {facilitiesPluralAlias}
      </ListTitle>
      <GridCard style={{ width: '100%', marginRight: 0 }}>
        <>
          {noFacilities ? (
            <CardListBody>
              <p>
                You don{`'`}t belong to any{' '}
                {facilitiesPluralAlias.toLowerCase()}.
              </p>
            </CardListBody>
          ) : (
            <Table
              columns={tableColumns}
              data={rowData}
              isLoading={meQuery.loading}
              onRowClick={(_e, rowData) =>
                history.push(`/facilities/${rowData?.id}`)
              }
              options={{
                minBodyHeight: 440,
                maxBodyHeight: 440,
                overflow: 'auto',
                searchNotFoundText: `${facilitiesSingularAlias} Not Found`,
              }}
            />
          )}
        </>
      </GridCard>
    </>
  );
};

export default FacilitiesList;
