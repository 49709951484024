import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    width='161'
    height='160'
    viewBox='0 0 161 160'
  >
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <path
                fill='#FF6454'
                d='M97 169.176H6v-15.292c0-20.97 14.19-38.63 33.5-43.89 1.31-.37 2.64-.67 4-.91 2.6-.46 5.27-.7 8-.7 3.44 0 6.78.38 10 1.1 1.36.31 2.69.67 4 1.1 6.93 2.23 13.14 6.09 18.17 11.13 8.24 8.23 13.33 19.61 13.33 32.17v15.292z'
                transform='translate(-719 -263) translate(597 263) translate(122.5) translate(29)'
              />
              <path
                fill='#FEFEFE'
                d='M65.5 110.584v2.63c0 5.21-3.064 9.703-7.488 11.777-1.674.784-3.542-5.107-5.512-5.107-1.795 0-3.505 5.966-5.061 5.308-4.665-1.973-7.939-6.593-7.939-11.978v-3.22c1.31-.37 2.64-.67 4-.91l18 .4c1.36.31 2.69.67 4 1.1'
                transform='translate(-719 -263) translate(597 263) translate(122.5) translate(29)'
              />
              <path
                fill='#2B4F83'
                d='M40.5 110.759v2.455c0 4.775 2.808 9.069 7.169 10.988.368-.291 1.01-1.359 1.406-2.019 1.018-1.696 1.98-3.299 3.425-3.299 1.492 0 2.575 1.615 3.622 3.178.446.666 1.177 1.756 1.563 1.977 4.144-1.988 6.815-6.225 6.815-10.825v-1.898c-.987-.305-2.016-.579-3.124-.835l-17.799-.395c-1.052.189-2.084.414-3.077.673m7.218 15.498c-.213 0-.435-.045-.668-.144-5.194-2.197-8.55-7.26-8.55-12.899v-3.22c0-.447.297-.84.728-.962 1.308-.37 2.687-.683 4.098-.932.065-.012.132-.016.196-.016l18 .4c.068.002.135.009.201.025 1.467.335 2.805.703 4.089 1.125.411.135.688.519.688.95v2.63c0 5.408-3.165 10.386-8.064 12.683-1.637.769-2.879-1.087-3.976-2.722-.495-.739-1.526-2.276-1.96-2.291-.384.118-1.247 1.556-1.71 2.328-.901 1.5-1.828 3.045-3.072 3.045'
                transform='translate(-719 -263) translate(597 263) translate(122.5) translate(29)'
              />
              <path
                fill='#E2956F'
                d='M63.5 95.884v15c0 4.97-4.926 9-11 9s-11-4.03-11-9v-15h22z'
                transform='translate(-719 -263) translate(597 263) translate(122.5) translate(29)'
              />
              <path
                fill='#F8AA7E'
                d='M85.5 64.884c0 9.39-3.8 17.89-9.96 24.04-6.15 6.16-14.65 9.96-24.04 9.96-18.78 0-34-15.22-34-34 0-.37.01-.73.02-1.1.12-3.92.91-7.66 2.24-11.13.18-.47.37-.93.57-1.38 1.71-3.92 4.14-7.45 7.13-10.43 6.15-6.16 14.65-9.96 24.04-9.96 7.73 0 14.85 2.57 20.55 6.92.07.04.13.09.2.16 1.16.88 2.26 1.85 3.29 2.88 2.99 2.98 5.42 6.51 7.13 10.43 1.29 2.97 2.18 6.15 2.58 9.49.12.99.2 2 .23 3.02.01.37.02.73.02 1.1'
                transform='translate(-719 -263) translate(597 263) translate(122.5) translate(29)'
              />
              <g>
                <path
                  fill='#FFF'
                  d='M7.122 17.663L8.122 17.663 8.122 -1.337 7.122 -1.337z'
                  transform='translate(-719 -263) translate(597 263) translate(122.5) translate(29) translate(20.878 68.337) rotate(-42 7.622 8.163)'
                />
                <path
                  fill='#FFF'
                  d='M54.122 17.663L55.122 17.663 55.122 -1.337 54.122 -1.337z'
                  transform='translate(-719 -263) translate(597 263) translate(122.5) translate(29) translate(20.878 68.337) scale(-1 1) rotate(-42 0 150.458)'
                />
                <path
                  fill='#A4BCDA'
                  d='M54.662 20.587C48.512 26.747 45.622 31 31 31s-17.694-4.005-23.79-9.796C5.156 19.254 18.57 6.7 30.335 6.547c11.765-.153 26.227 12.144 24.327 14.04z'
                  transform='translate(-719 -263) translate(597 263) translate(122.5) translate(29) translate(20.878 68.337)'
                />
                <path
                  stroke='#717171'
                  strokeLinecap='round'
                  d='M17.622 16.663l3.695-1.403c5.832-2.216 12.25-2.343 18.166-.361l3.139 1.051h0M17.622 21.493c4.582-.871 8.82-1.362 12.713-1.473 3.894-.111 7.99.142 12.287.76'
                  transform='translate(-719 -263) translate(597 263) translate(122.5) translate(29) translate(20.878 68.337)'
                />
                <path
                  stroke='#FFF'
                  strokeLinecap='round'
                  d='M26.5 9.5L30.335 8.163 33.967 9.5'
                  transform='translate(-719 -263) translate(597 263) translate(122.5) translate(29) translate(20.878 68.337)'
                />
              </g>
              <path
                fill='#6A564E'
                d='M86.58 56.135c.228 1.226.213 2.506-.07 3.782-.6 2.706-2.285 4.891-4.546 6.261-.929 2.543-2.21 4.99-3.845 7.295-.035-.053-.06-.113-.097-.166-.155.607-.326 1.211-.517 1.81-.962 2.997-2.379 5.884-4.29 8.57-3.995-6.79-5.433-13.33-3.708-18.128-2.994-2.21-4.685-5.795-4.197-9.466-1.783-.658-3.317-1.75-4.476-3.1-1.834.606-3.87.742-5.928.286-4.41-.978-7.613-4.374-8.374-8.34-.684-.034-1.376-.116-2.071-.27-2.05-.453-3.831-1.427-5.222-2.73-.143-.132-.285-.272-.426-.412-.042.018-.085.036-.137.052-.55-.376-1.062-.796-1.526-1.252-1.642.694-3.502.986-5.396.784-.077.218-.164.433-.25.65-1.351 3.153-4.306 5.347-7.752 5.957-.603 5.056-4.812 10.271-11.429 14.573-.506-3.258-.484-6.473-.007-9.577.404-2.596 1.126-5.122 2.156-7.515-2.917-2.473-4.351-6.309-3.396-10.094 1.24-4.942 6.122-8.082 11.338-7.54 1.018-2.948 3.398-5.15 6.309-6.16.013-.62.085-1.246.225-1.876 1.214-5.481 6.91-8.874 12.73-7.585 2.205.49 4.103 1.58 5.542 3.056.033.025.063.06.094.094.052-.016.105-.04.157-.056 1.917-.724 4.087-.902 6.292-.414 1.765.392 3.339 1.175 4.644 2.215 2.382-1.56 5.433-2.204 8.524-1.519 4.767 1.058 8.131 4.932 8.525 9.305.053.582.05 1.16-.005 1.754.241.034.472.077.71.13.86.19 1.671.47 2.428.827 4.116 1.953 6.63 6.2 6.05 10.583 4.913 1.831 7.879 6.81 6.776 11.784-.626 2.817-2.433 5.083-4.834 6.432'
                transform='translate(-719 -263) translate(597 263) translate(122.5) translate(29) rotate(-20 51.214 47.68)'
              />
              <path
                fill='#F8AA7E'
                d='M89.5 69.384c0 1.703-.689 3.245-1.807 4.36-1.115 1.118-2.656 1.807-4.36 1.807-3.406 0-6.166-2.761-6.166-6.167 0-.067.001-.132.003-.2.022-.711.165-1.389.407-2.018.032-.085.067-.169.103-.25.31-.712.751-1.352 1.293-1.892 1.115-1.118 2.657-1.807 4.36-1.807 1.402 0 2.694.466 3.728 1.255.012.008.023.017.036.03.21.159.41.335.596.522.543.54.984 1.18 1.294 1.892.234.538.395 1.115.468 1.721.022.179.036.363.042.547.001.068.003.133.003.2'
                transform='translate(-719 -263) translate(597 263) translate(122.5) translate(29)'
              />
              <path
                fill='#6A564E'
                d='M77.167 77L79.917 77 79.917 62.217 77.167 62.217z'
                transform='translate(-719 -263) translate(597 263) translate(122.5) translate(29)'
              />
              <path
                fill='#F8AA7E'
                d='M24.166 69.384c0 1.703-.689 3.245-1.806 4.36-1.116 1.118-2.657 1.807-4.36 1.807-3.406 0-6.166-2.761-6.166-6.167 0-.067 0-.132.002-.2.022-.711.165-1.389.407-2.018.032-.085.067-.169.104-.25.31-.712.75-1.352 1.293-1.892 1.115-1.118 2.657-1.807 4.36-1.807 1.401 0 2.694.466 3.727 1.255.012.008.023.017.037.03.21.159.41.335.596.522.543.54.983 1.18 1.294 1.892.233.538.395 1.115.468 1.721.021.179.035.363.041.547.003.068.003.133.003.2'
                transform='translate(-719 -263) translate(597 263) translate(122.5) translate(29)'
              />
              <path
                fill='#323232'
                d='M39.5 74.884c-1.104 0-2-.896-2-2v-3.333c0-1.105.896-2 2-2s2 .895 2 2v3.333c0 1.104-.896 2-2 2M61.5 74.884c-1.104 0-2-.896-2-2v-3.333c0-1.105.896-2 2-2s2 .895 2 2v3.333c0 1.104-.896 2-2 2'
                transform='translate(-719 -263) translate(597 263) translate(122.5) translate(29)'
              />
              <path
                fill='#6A564E'
                d='M23 77L25.75 77 25.75 62.217 23 62.217z'
                transform='translate(-719 -263) translate(597 263) translate(122.5) translate(29)'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
