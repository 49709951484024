import * as React from 'react';
import { Button } from '@betterpt/better-components';
import {
  ImgAndrewTelehealth,
  ImgBlondeGirlComputer,
  ImgBrunetteGirlComputer,
  ImgCovidManager,
  ImgGirlYellowDressPhone,
  ImgSmallGirlPhoneSearch,
  ImgSuitLadyComputer,
} from '@betterpt/better-icons';

type Params = {
  loading: boolean;
  patientsPluralAlias: string;
  employeePluralAlias: string;
  facilitiesPluralAlias: string;
  isNoMatchingAppointmentsPage?: boolean;
  subject?: string;
  telehealth?: boolean;
  isNotWhiteLabel?: boolean;
  isOwner?: boolean;
  hasSubscription?: boolean;
  hasTelehealth?: boolean;
  hasBillingAccess?: boolean;
  primaryColor?: string;
  resetFilters?: () => void;
  history?: any;
};

const noAppointmentsCopy = ({
  loading,
  subject,
  telehealth,
  isNoMatchingAppointmentsPage,
  isNotWhiteLabel,
  isOwner,
  hasSubscription,
  hasTelehealth,
  hasBillingAccess,
  primaryColor,
  resetFilters,
  history,
  patientsPluralAlias,
  employeePluralAlias,
  facilitiesPluralAlias,
}: Params) => {
  const tryingInPersonButNotPaying = !telehealth && !hasSubscription;
  const tryingTelehealthButNotPaying = telehealth && !hasTelehealth;
  const wrongVerticalForInPerson = !isNotWhiteLabel && !telehealth;

  const generateCopy = () => {
    let icon: any;
    let title: string | JSX.Element = '';
    let message: string | JSX.Element = '';
    let onClick: () => void = () => null;
    let dataTestId = '';
    let buttonText = '';
    const hideButtonText = true;
    if (loading) {
      return {
        icon,
        title,
        message,
        onClick,
        dataTestId,
        hideButtonText,
      };
    }
    if (wrongVerticalForInPerson) {
      //NON PT COMPANY TRYING IN PERSON APPTS
      icon = <ImgBrunetteGirlComputer />;
      title = `We are hard at work on in-person scheduling`;
      message = `In-person scheduling is only available for our physical therapy customers right now. However, rest assured that we are hard at work building something that works for your healthcare industry :)`;
      onClick = () => history?.push('/appointments/telehealth/');
      dataTestId = 'return-to-telehealth-button';
      buttonText = 'RETURN TO TELEHEALTH APPOINTMENTS';
    } else if (isNoMatchingAppointmentsPage) {
      //NO MATCHING APPOINTMENTS FOUND
      icon = <ImgSmallGirlPhoneSearch style={{ height: 160, width: 160 }} />;
      title = 'No matching appointments found!';
      message = `It looks like you are searching for an appointment that ${isOwner ? '' : 'either'} isn’t
      registered on our system${isOwner ? '' : ', or that you don’t have permissions to view'}.${
        resetFilters
          ? ` Click the button below to reset your filters back to upcoming
      appointments for all your ${patientsPluralAlias.toLowerCase()}, ${employeePluralAlias.toLowerCase()} and ${facilitiesPluralAlias.toLowerCase()} this month.`
          : ''
      }`;
      onClick = () => resetFilters?.();
      dataTestId = 'reset-filters-button';
      buttonText = 'RESET APPOINTMENT FILTERS';
    } else if (tryingInPersonButNotPaying) {
      //TRYING IN-PERSON BUT NOT PAYING
      title = (
        <>
          Your company needs a subscription
          <br />
          for in-person appointments
        </>
      );
      //NO BILLING ACCESS
      icon = <ImgSuitLadyComputer />;
      message =
        'Contact support@betterhealthcare.co to start adding more facilities. You will then be able to add staff and appointments, and gain access to analytics and more!';
      if (hasTelehealth) {
        onClick = () => history?.push('/appointments/telehealth/');
        dataTestId = 'return-to-telehealth-button';
        buttonText = 'VIEW TELEHEALTH APPOINTMENTS';
      } else {
        onClick = () => history?.goBack();
        dataTestId = 'go-back-button';
        buttonText = 'THANK YOU';
      }
    } else if (tryingTelehealthButNotPaying) {
      //TRYING TELEHEALTH BUT NOT PAYING
      title = (
        <>
          Your company needs to sign up
          <br />
          for telehealth appointments
        </>
      );
      if (hasBillingAccess) {
        icon = <ImgGirlYellowDressPhone />;
        message = `You can't view or create telehealth appointments with us because your company hasn't signed up. Sign up to activate virtual appointments today!`;
        onClick = () => history?.push('/company/payment');
        dataTestId = 'go-to-payment-page';
        buttonText = 'SIGN UP FOR BETTERTELEHEALTH';
      } else {
        //NO BILLING ACCESS
        icon = <ImgAndrewTelehealth />;
        message = `None of the ${facilitiesPluralAlias.toLowerCase()} you belong to are signed up for our telehealth services. Please contact your company owner or administrator if you would like to activate virtual appointments.`;
        if (hasSubscription) {
          onClick = () => history?.push('/appointments/');
          dataTestId = 'return-to-inperson-button';
          buttonText = 'VIEW IN-PERSON APPOINTMENTS';
        } else {
          onClick = () => history?.goBack();
          dataTestId = 'go-back-button';
          buttonText = 'THANK YOU';
        }
      }
    } else {
      //DEFAULT
      icon = <ImgBlondeGirlComputer style={{ height: 138, width: 137 }} />;
      title = 'No appointments yet!';
      message = (
        <>
          It looks like there aren’t any appointments registered with {subject ?? 'you'} on our system. Click the button on the upper right{' '}
          {subject ? 'of this page' : ''} to add an appointment.
          {!isOwner && (
            <>
              <br />
              <br />
              If you need to update your permissions on the platform, for example, to view appointments associated with specific{' '}
              {facilitiesPluralAlias.toLowerCase()}, contact your company administrator to make the request.
            </>
          )}
        </>
      );
      dataTestId = 'reset-filters-button';
    }

    return {
      icon,
      title,
      message,
      onClick,
      dataTestId,
      hideButtonText,
    };
  };

  const copy = generateCopy();

  const generateImage = () => copy.icon;

  const generateHeader = () => copy.title;

  const generateBody = () => copy.message;

  const generateButton = () => {
    console.log('no button');
  };

  return {
    generateImage,
    generateHeader,
    generateBody,
    generateButton,
  };
};

export default noAppointmentsCopy;
