import useSnackbar from './useSnackbar';

export default function useHandleError() {
  const snackbar = useSnackbar();

  function handleError(err: unknown) {
    console.error(err);
    snackbar?.setErrorMessage(
      err instanceof Error ? err.message : 'Something went wrong.'
    );
  }

  return handleError;
}
