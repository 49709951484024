import styled from '@emotion/styled';
import { bestTextColor } from '@betterpt/better-components';

export const CreateFacilityBody = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 10px;
`;

export const Form = styled.form`
  position: relative;
  width: 482px;
  margin-bottom: 50px;
`;

export const CreateFacilityForm = styled.form`
`;

export const ButtonStyle = (color?: string) => ({
  backgroundColor: color,
  color: bestTextColor(color),
  position: 'absolute' as 'absolute',
  right: '0px',
  top: '10px',
  width: '150px',
  height: '48px',
});
