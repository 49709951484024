import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@betterpt/better-components';
import TextField from '../../Shared/ThemedTextField';
import useAuthentication from '../../../hooks/useAuthentication';
import { loginButtonStyle } from '../shared.style';
import AuthWrapper from '../AuthWrapper';
import { determineVariant } from '../AuthWrapper/loginVariantConfig';

const ConfirmEmail = () => {
  const { variant } = useParams<{ variant: string }>();
  const { push } = useHistory();
  const { email } = useParams<{ email: string }>();
  const decodedEmail = decodeURIComponent(email);
  const [code, updateCode] = React.useState('');
  const auth = useAuthentication();

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    const loginLink = variant
      ? `/login/${variant}?confirmedEmail=${email}`
      : `/login?confirmedEmail=${email}`;
    e.preventDefault();
    auth?.confirmEmail(decodedEmail, code.trim()).then(() => {
      push(loginLink);
    });
  };

  const primaryColor = determineVariant(variant).color;

  return (
    <AuthWrapper>
      <form onSubmit={handleSubmit}>
        <h1 className='H2'>Confirm your email</h1>
        <p>
          Please check your email for your verification code and enter it below.
        </p>
        <TextField
          onChange={(e) => updateCode(e.currentTarget.value)}
          label='Code'
          placeholder='Enter your code'
        />
        <Button
          loading={auth?.isLoading}
          disabled={auth?.isLoading}
          type='submit'
          fullWidth
          size='large'
          style={loginButtonStyle(primaryColor)}
        >
          Submit
        </Button>
      </form>
    </AuthWrapper>
  );
};

export default ConfirmEmail;
