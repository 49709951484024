import React from 'react';

export default ({ color, ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
  >
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        fill={color ?? '#2A4E82'}
        fillRule='nonzero'
        d='M18.192 7.906c.674.665.712 1.727.112 2.44l-.112.121-3.88 3.845 3.876 3.846a1.8 1.8 0 0 1 0 2.561 1.838 1.838 0 0 1-2.46.112l-.124-.112L12 17.146l-3.607 3.573a1.838 1.838 0 0 1-2.46.112l-.124-.112a1.799 1.799 0 0 1-.112-2.44l.112-.121 3.88-3.846-3.876-3.845a1.8 1.8 0 0 1 0-2.561 1.838 1.838 0 0 1 2.46-.112l.124.112L12 11.478l3.608-3.572a1.838 1.838 0 0 1 2.584 0zM10.345 3.41a2.327 2.327 0 0 1 3.249 0l.256.252a2.23 2.23 0 0 1-.222 2.935l-1.645 1.618-1.638-1.61a2.234 2.234 0 0 1 0-3.195z'
      />
    </g>
  </svg>
);
