//utils
import { createFiltersContext, useFilters } from './FilterContext';

//components
import Filter from './Filter';
export { default as FilterButtonOnly } from './FilterButtonOnly';
export { default as FilterScrollAndSearch } from './FilterScrollAndSearch';

export { createFiltersContext, useFilters };

export type FilterOption = {
  label: string | undefined;
  fullTitle?: string;
  icon?: JSX.Element;
  value?: any;
  isResetOption?: boolean;
  disabled?: boolean;
  selected?: boolean;
  hidden?: boolean | null;
  update?: (value: any) => void;
  callback?: (...args: any[]) => void;
};

export type FilterSelectionMode = 'check' | 'radio' | 'hybrid';

export type FilterContextType = {
  activeFilter: { [key: string]: any };
  updateFilter: (newFilter: any) => void;
};

export default Filter;
