import { jsx } from '@emotion/react';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { card } from './style';
import styled from '@emotion/styled';

export type ShadowType = 'short' | 'deep' | 'none' | 'line' | undefined;

export type CardProps = {
  children: React.ReactNode;
  width?: number;
  fullWidth?: boolean;
  shadow?: ShadowType;
  fullWidthAtBreakpoint?: number;
};

const Div = styled.div``;

/**
 * Card Component
 *
 * @param {CardProps} { children: React.ReactNode; width?: number; fullWidth?: boolean; style?: CSSProperties; }
 */
const Card = ({
  children,
  width,
  fullWidth,
  shadow,
  fullWidthAtBreakpoint,
  ...rest
}: CardProps &
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <Div
    css={card({ width, fullWidth, shadow, fullWidthAtBreakpoint })}
    {...rest}
  >
    {children}
  </Div>
);

export default Card;
