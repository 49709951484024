import * as React from 'react';
import { UserContext } from '../context/authentication';

export default function useAuthentication() {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error(
      '`useUser` hook must be used within a `UserProvider` component'
    );
  }
  return context;
}
