import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import amplify from './config/amplify';
import firebase from './config/firebase';
import initDataDog from './config/datadog';

amplify();
firebase();
initDataDog();

ReactDOM.render(<App />, document.getElementById('root'));

// TODO: We'll have to figure this out eventually, but for now, it appears to be broken, so we're going to comment it out.

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/service-worker.js');
//   });
// }
