import styled from '@emotion/styled';
import { colorValues, bestTextColor } from '@betterpt/better-components';

// Main View Styles
export const Page = styled.div`
  margin: 20px;
`;

// Header Styles
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 38px;
  margin-left: 17px;
  max-width: 1170px;
`;

export const TabCTAs = styled.div`
  display: flex;
  justify-content: space-between;
  height: max-content;
  margin-bottom: 10px;
`;

export const PhotoAndNameSection = styled.div`
  display: flex;
  align-items: center;
  h1 {
    font-size: 24px;
  }
`;

export const headerCtaButton = {
  backgroundColor: colorValues.emptiness,
  color: colorValues.betterptblack,
  boxShadow: '0 2px 10px 0 rgba(51, 51, 51, 0.24)',
  marginLeft: 21,
};

export const H1 = styled.h1`
  color: ${colorValues.betterptblack} !important;
  font-size: 16px;
`;

export const H3 = styled.h3`
  color: ${colorValues.betterptblack} !important;
  font-size: 16px;
`;

export const FormBody = styled.div`
  padding: 20px 23px 30px 42px;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Paragraph = styled.p`
  font-size: 14px;
  line-height: 1.43;
  margin: 5px 0;
`;

export const buttonStyle = (color?: string, primary?: boolean) => {
  const bgColor = (primary && color) || colorValues.dust;
  const textColor = bestTextColor(bgColor);
  return {
    backgroundColor: bgColor,
    color: textColor,
    width: 110,
  };
};

export const InvoiceBillingMessage = styled.p`
  font-size: 16px;
  margin: 41px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  a {
    color: ${colorValues.frenchblue};
    font-style: underline;
  }
`;

export const NoAnalyticsPage = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
