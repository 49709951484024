import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

export const CardSection = styled.div`
  padding: 18px 25px 5px;
  border-bottom: 1px solid ${colorValues.concretejungle};
  position: relative;
`;

export const DialogBody = styled.div`
  padding: 52px;
`;

export const DialogTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 35px;
`;

export const DialogMessage = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
`;

export const generalizedFacilityDrawerContainer = {
  position: 'relative' as 'relative',
  maxWidth: '521px',
  width: '100%',
  marginTop: '20px',
};

export const GeneralizedAutocompleteStyleWrapper = styled.div<{
  text?: string;
}>`
  .MuiAutocomplete-endAdornment {
    top: -16px;
  }
  .MuiAutocomplete-input {
    height: 19px;
    font-size: ${(props) => (props.text ? '16px' : '14px')};
    font-weight: ${(props) => (props.text ? 'bold' : 'normal')};
  }
  .MuiAutocomplete-inputRoot[class*='MuiInput-root'][class*='MuiInput-marginDense']
    .MuiAutocomplete-input:first-of-type {
    padding: 6px 0;
  }
`;
