import React from 'react';

import ThemedTextField from '../../../../../../../Shared/ThemedTextField';

const InPersonAppointmentCardInput = ({
  id,
  label,
  testId,
  isListView,
  value,
  ...rest
}: {
  id: string;
  label: string;
  testId: string;
  isListView: boolean;
  value: string;
}) => {
  return (
    <ThemedTextField
      {...rest}
      id={id}
      label={label}
      inputProps={{
        'data-testid': testId,
        style: {
          cursor: isListView ? 'pointer' : 'default',
          overflowX: 'auto',
          fontSize: '16px',
        },
      }}
      InputProps={{
        disableUnderline: true,
      }}
      fullWidth
      value={value}
      disabled
    />
  );
};

export default InPersonAppointmentCardInput;
