import * as React from 'react';
import styled from '@emotion/styled';

// components
import NotificationPanel from './components/NotificationsPanel';

// styles
export const Page = styled.div`
  margin: 31px 28px;
`;

const Notifications = () => {
  return (
    <Page>
      <h1 className='H1'>Notification Center</h1>
      <NotificationPanel />
    </Page>
  );
};

export default Notifications;
