import React from 'react';

type Filter<T> = {
  value: T;
  update: (value: T) => void;
};

export type PatientFiltersContextType = {
  isArchived: Filter<boolean | undefined>;
  clinicIds: Filter<string[]>;
  offset: Filter<number>;
  searchTerm: Filter<string>;
  defaultPageIndex: Filter<number>;
  defaultTableSection: Filter<number>;
  resetFilters: () => void;
};

const defaultContextValue: PatientFiltersContextType = {
  isArchived: {
    value: undefined,
    update: () => {
      return;
    },
  },
  clinicIds: {
    value: [],
    update: () => {
      return;
    },
  },
  offset: {
    value: 0,
    update: () => {
      return;
    },
  },
  defaultPageIndex: {
    value: 0,
    update: () => {
      return;
    },
  },
  searchTerm: {
    value: '',
    update: () => {
      return;
    },
  },
  defaultTableSection: {
    value: 1,
    update: () => {
      return;
    },
  },
  resetFilters: () => {
    return;
  },
};

export const PatientFiltersContext = React.createContext<
  PatientFiltersContextType
>(defaultContextValue);

export const PatientFiltersProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [isArchived, updateStatus] = React.useState<boolean | undefined>(false);
  const [clinicIds, updateClinicIds] = React.useState<string[]>([]);

  const [offset, setOffset] = React.useState(0);
  const [defaultPageIndex, updateDefaultPageIndex] = React.useState(0);
  const [defaultTableSection, updateDefaultTableSection] = React.useState(1);
  const [searchTerm, updateSearchTerm] = React.useState('');

  const resetFilters = () => {
    updateSearchTerm('');
    updateStatus(false);
    updateClinicIds([]);
    setOffset(0);
    updateDefaultPageIndex(0);
    updateDefaultTableSection(1);
  };

  React.useEffect(() => {
    setOffset?.(0);
    updateDefaultPageIndex?.(0);
    updateDefaultTableSection?.(1);
  }, [isArchived, clinicIds]);

  const values = {
    isArchived: {
      value: isArchived,
      update: updateStatus,
    },
    clinicIds: {
      value: clinicIds,
      update: updateClinicIds,
    },
    offset: {
      value: offset,
      update: setOffset,
    },
    defaultPageIndex: {
      value: defaultPageIndex,
      update: updateDefaultPageIndex,
    },
    defaultTableSection: {
      value: defaultTableSection,
      update: updateDefaultTableSection,
    },
    searchTerm: {
      value: searchTerm,
      update: updateSearchTerm,
    },
    resetFilters,
  };

  return (
    <PatientFiltersContext.Provider value={values}>
      {children}
    </PatientFiltersContext.Provider>
  );
};
