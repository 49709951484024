import React from 'react';
import styled from '@emotion/styled';

//components
import { GridCard } from '../../../../../Shared/GridCard';
import {
  Header,
  colorValues,
} from '@betterpt/better-components';
import { IcDateRange, IcEdit } from '@betterpt/better-icons';

// styles
import * as sharedStyles from '../../FacilityDetails.style';

const Body = styled.div`
  margin: 17px 52px 30px 60px;
`;

const DirectScheduling = () => {

  return (
    <GridCard style={{marginRight: 28}}>
        <Header underline style={sharedStyles.headerStyle}>
          <h3 className='H3'> <IcDateRange 
          color={colorValues.betterptblack}
        /> Direct Scheduling</h3>
        </Header>
        <Body style={{fontSize: 16, lineHeight: 1.4}}>
          This facility uses direct scheduling, which means that any patient bookings on the BetterAccess booking flow are automatically 
          confirmed on both BetterAccess and your EHR. To switch from direct scheduling to an appointment request model, please contact your EHR. 
        </Body>
    </GridCard>
  );
};

export default DirectScheduling;