import {
  Role,
  BetterAccessMigrationStatus,
  CompanyListOptionType,
  HealthcareVertical,
  useMeQuery,
  useCompanyListOptionsQuery,
  useCompanyBillingOverviewQuery,
} from '../generated/graphql';
import { checkPermissions } from '../components/Can';
import { Roles } from '../helpers/rbac-rules';

const useFeatureAccessCheck = () => {
  const meQuery = useMeQuery({
    fetchPolicy: 'cache-first',
  });
  const hasBillingAccess = checkPermissions(
    Roles[meQuery.data?.me?.role ?? 'initial'],
    'billingOverview:access'
  );
  const canAssignSelfToFacilities = checkPermissions(
    Roles[meQuery.data?.me?.role ?? 'initial'],
    'facility:associateToSelf'
  );
  const canCreateFacilities = checkPermissions(
    Roles[meQuery.data?.me?.role ?? 'initial'],
    'facility:create'
  );

  const billingOverviewQuery = useCompanyBillingOverviewQuery({
    variables: { id: meQuery.data?.me?.company?.id ?? '' },
    skip: !hasBillingAccess || !meQuery.data?.me?.company?.id,
  });

  const inPersonFacilities = useCompanyListOptionsQuery({
    variables: {
      input: {
        companyId: meQuery.data?.me?.company?.id as string,
        type: CompanyListOptionType.InPersonFacilities,
      },
    },
    skip: !meQuery.data?.me?.company?.id,
  });

  const telehealthFacilities = useCompanyListOptionsQuery({
    variables: {
      input: {
        companyId: meQuery.data?.me?.company?.id as string,
        type: CompanyListOptionType.TelehealthFacilities,
      },
    },
    skip: !meQuery.data?.me?.company?.id,
  });

  const loading =
    meQuery.loading ||
    billingOverviewQuery.loading ||
    inPersonFacilities.loading ||
    telehealthFacilities.loading;

  const company = meQuery.data?.me?.company;
  const billingOverview = billingOverviewQuery.data?.company?.billingOverview;

  const hasSubscription = hasBillingAccess
    ? !!billingOverview?.prepaidSubscriptionPlans?.length ||
      !!billingOverview?.hasAgreedToSubscriptionTermsForPrepaidSub
    : !!inPersonFacilities.data?.companyListOptions?.length;

  const hasTelehealth = hasBillingAccess
    ? !!billingOverview?.transactionalSubscriptionPlans?.length ||
      !!billingOverview?.hasAgreedToSubscriptionTermsForTransactionalSub
    : !!telehealthFacilities.data?.companyListOptions?.length;

  const hasRecording = !!billingOverview?.isArchiveOptionActive;

  const recordingActive = !!billingOverview?.isArchiveOptionActive;

  const hasHSS = hasBillingAccess
    ? !!billingOverview?.isAllowedToAddHSSClinics
    : null;

  const isExemptFromInternalBilling =
    billingOverview?.billingPartnership === 'Clinicient';

  const isPT =
    company?.healthcareVertical === HealthcareVertical.PhysicalTherapy;
  const isMigratedToNewBookingFlow =
    company?.betterAccessMigrationStatus ===
    BetterAccessMigrationStatus.CompletedBookingFlowMigration;
  const isNotWhiteLabel =
    company?.providerAppId !== 'acorn' && company?.providerAppId !== 'somatus';

  const hasCreditCard = billingOverview?.cardLastFourDigit;

  return {
    isOwner: meQuery.data?.me?.role === Role.Owner,
    canAssignSelfToFacilities,
    canCreateFacilities,
    hasBillingAccess,
    hasSubscription,
    hasTelehealth,
    hasRecording,
    recordingActive,
    isExemptFromInternalBilling,
    hasHSS,
    isPT,
    isMigratedToNewBookingFlow,
    isNotWhiteLabel,
    loading,
    hasCreditCard,
  };
};

export default useFeatureAccessCheck;
