import {
  Appointment,
  usePendingAppointmentsQuery,
  usePendingVideoAppointmentsQuery,
  VideoAppointment,
} from '../generated/graphql';
import {
  mapPendingAppointmentsToNotificationData,
} from '../helpers/mapDataToNotifications';

const useTotalNotificationsCount = () => {
  const pendingInPersonAppointmentsQuery = usePendingAppointmentsQuery({
    variables: {
      input: {
        limit: 100,
      },
    },
    pollInterval: 30000,
    fetchPolicy: 'cache-and-network',
  });

  const pendingVideoAppointmentsQuery = usePendingVideoAppointmentsQuery({
    variables: {
      input: {
        limit: 100,
      },
    },
    pollInterval: 30000,
    fetchPolicy: 'cache-and-network',
  });
  const pendingVideoAppointments =
    mapPendingAppointmentsToNotificationData({
      appointmentData: pendingVideoAppointmentsQuery.data
        ?.pendingVideoAppointments?.result as VideoAppointment[],
      isInPerson: true,
    }) ?? [];

  const pendingInPersonAppointments =
    mapPendingAppointmentsToNotificationData({
      appointmentData: pendingInPersonAppointmentsQuery.data
        ?.pendingAppointments?.result as Appointment[],
      isInPerson: true,
    }) ?? [];

  return [
    ...pendingInPersonAppointments!,
    ...pendingVideoAppointments!,
  ].length;
};

export default useTotalNotificationsCount;
