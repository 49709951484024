import { css, jsx } from '@emotion/react';
import { Fragment, useState } from 'react';

import MTableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { TableProps, DetailPanel } from '../types';

//Icons
import IcDropdownUnselected from '../../Icons/IcDropdownUnselected';
import IcDropDownSelected from '../../Icons/IcDropDownSelected';

import { Tooltip, IconButton } from '@material-ui/core';

type RowProps<RowData extends object> = {
  classes: any;
  rowData: RowData;
  detailPanel?: DetailPanel<RowData>;
  filtering?: boolean;
  cellPadding?: number;
};

const Row = <RowData extends object>({
  columns,
  onRowClick,
  classes,
  rowData,
  detailPanel,
  filtering,
  cellPadding,
}: Partial<TableProps<RowData>> & RowProps<RowData>) => {
  const [isDetailedPanelOpen, setDetailedPanelOpen] = useState(false);
  const paddingLeft = (index: number) => {
    if (index === 0) {
      return 27;
    } else return cellPadding ? cellPadding : 14;
  };

  return (
    <Fragment>
      <MTableRow
        className={classes.root}
        onClick={onRowClick ? (e) => onRowClick(e, rowData) : undefined}
      >
        {detailPanel && (
          <TableCell
            css={css`
              padding: 0 !important;
              width: 20px !important;
            `}
          >
            <Tooltip title='View Details'>
              <IconButton
                css={css`
                  height: 36px;
                  width: 36px;
                  span {
                    display: block !important;
                    position: relative;
                    top: 3px;
                    left: -12px;
                  }
                `}
                aria-label='filters'
                onClick={(e) => {
                  e.stopPropagation();
                  setDetailedPanelOpen(!isDetailedPanelOpen);
                }}
              >
                {isDetailedPanelOpen ? (
                  <IcDropDownSelected
                    style={{ cursor: 'pointer', opacity: 0.35 }}
                  />
                ) : (
                  <IcDropdownUnselected
                    style={{ cursor: 'pointer', opacity: 0.35 }}
                  />
                )}
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
        {columns?.map((col, idx) => (
          <TableCell
            css={css`
              padding: ${cellPadding ? cellPadding : 14}px !important};
              padding-left: ${paddingLeft(idx)}px !important;
              width: ${col.width ? col.width + 'px' : 'auto'};
              max-width: ${col.width ? col.width + 'px' : 'none'};
              ${col.cellStyle ? Object.assign(col.cellStyle) : ''}
            `}
            key={idx}
          >
            {(rowData as any)[col.field]}
          </TableCell>
        ))}
        {filtering && <TableCell />}
      </MTableRow>
      {isDetailedPanelOpen && detailPanel && (
        <MTableRow
          css={css`
            padding: 0;
          `}
        >
          <TableCell
            css={css`
              padding: 0 !important;
            `}
            colSpan={columns?.length ? columns.length + 2 : 1}
          >
            {detailPanel(rowData)}
          </TableCell>
        </MTableRow>
      )}
    </Fragment>
  );
};

export default Row;
