import * as React from 'react';

//types
import { UpdateProfileInfoFormData } from '../hooks/useProfileInfoOperations';

//hooks
import { useHistory } from 'react-router-dom';
import useTextFieldState from '../../../../hooks/useTextFieldState';
import useSnackbar from '../../../../hooks/useSnackbar';
import { useMeQuery } from '../../../../generated/graphql';
import useHandleError from '../../../../hooks/useHandleError';

//components
import FormField from '../../../Shared/FormField';
import ThemedTextField from '../../../Shared/ThemedTextField/ThemedTextField';

//styles
import { ProfileForm } from '../ProfileInfo.style';
import useFeatureFlagWrapper from 'apps/provider-app/src/hooks/useFeatureFlagWrapper';
import Flags from 'apps/provider-app/src/constants/flags';
const profileHeaderStyle = { margin: '40px 0px 32px 0px' };

interface Props {
  updateSaving: (value: boolean) => void;
  updateProfileInfo: (payload: UpdateProfileInfoFormData) => Promise<void>;
}

const OnboardingProfileForm = ({ updateSaving, updateProfileInfo }: Props) => {
  const history = useHistory();
  const snackbar = useSnackbar();
  const handleError = useHandleError();
  const featureFlagWrapper = useFeatureFlagWrapper();
  const meQuery = useMeQuery();
  const employeeId = meQuery.data?.me?.id;

  const firstNameFormState = useTextFieldState({ required: true });
  const lastNameFormState = useTextFieldState({ required: true });
  const genderFormState = useTextFieldState({ required: true });

  const nextPageURL = featureFlagWrapper(
    '/onboarding/add-administrator',
    Flags.DisableBillingForCompanySetup,
    '/onboarding/billing'
  ) ?? '';

  React.useEffect(() => {
    const me = meQuery.data?.me;
    if (meQuery.data) {
      //cache normalization causes the updateProfileInfo mutation to change the meQuery data,
      //so these checks, rather than field.updateValue(data ?? ''), prevent fields reseting on submit
      me?.firstName && firstNameFormState.updateValue(me?.firstName);
      me?.lastName && lastNameFormState.updateValue(me?.lastName);
      me?.gender && genderFormState.updateValue(me?.gender);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meQuery.data]);

  const handleSubmitProfileInfoForm = async (
    e: React.SyntheticEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    updateSaving(true);
    try {
      await updateProfileInfo({
        employeeId,
        firstNameFormState,
        lastNameFormState,
        genderFormState,
      });
      snackbar?.setSuccessMessage('Profile saved');
      updateSaving(false);
      history.push(nextPageURL);
    } catch (e) {
      updateSaving(false);
      handleError(e);
    }
  };

  return (
    <ProfileForm
      onSubmit={handleSubmitProfileInfoForm}
      id='profileOnboardingForm'
    >
      <h2 className='H4' style={profileHeaderStyle}>
        Create your Account Owner Profile
      </h2>
      <FormField
        fullWidth
        label='First Name'
        placeholder='Enter your first name here'
        formHandler={firstNameFormState}
        inputProps={{
          maxLength: 49,
        }}
      />
      <FormField
        fullWidth
        label='Last Name'
        placeholder='Enter your last name here'
        formHandler={lastNameFormState}
        inputProps={{
          maxLength: 49,
        }}
      />
      <FormField
        select
        fullWidth
        label='Gender'
        formHandler={genderFormState}
        SelectProps={{ native: true }}
      >
        <option value=''>Please select from the dropdown</option>
        <option value='M'>Male</option>
        <option value='F'>Female</option>
      </FormField>
      <h2 className='H4' style={profileHeaderStyle}>
        Confirm Your Login Information
      </h2>
      <ThemedTextField
        label='Account Owner Email'
        placeholder='Email'
        fullWidth
        helperText={
          'If you need to change your email please contact us at support@betterhealthcare.co'
        }
        value={meQuery.data?.me?.email}
        inputProps={{ readOnly: true }}
        disabled
      />
    </ProfileForm>
  );
};

export default OnboardingProfileForm;
