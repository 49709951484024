import * as React from 'react';

export default ({ color, ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='25'
    height='25'
    viewBox='0 0 25 25'
    {...rest}
  >
    <defs>
      <path
        id='xvmy87ohja'
        d='M20 11L7.83 11 13.42 5.41 12 4 4 12 12 20 13.41 18.59 7.83 13 20 13z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g transform='translate(-1352 -302) translate(245 80.5) translate(.5 77.5) translate(0 132.5) translate(1095) translate(12 12)'>
                  <path d='M0 0L24 0 24 24 0 24z' />
                  <mask id='43oik4yv3b' fill={color ?? '#fff'}>
                    <use xlinkHref='#xvmy87ohja' />
                  </mask>
                  <use
                    fill={color ?? '#333'}
                    fillRule='nonzero'
                    transform='rotate(180 12 12)'
                    xlinkHref='#xvmy87ohja'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
