import { useFlags } from 'launchdarkly-react-client-sdk';
import Flags from '../../constants/flags';

interface Props {
  yes: () => JSX.Element;
  no?: () => JSX.Element;
  flag: Flags;
}

const FeatureFlagCheck = ({ yes, no, flag }: Props) => {
  const flags = useFlags();

  if (flags[flag]) return yes();
  else if (no) return no();
  else return null;
};

export default FeatureFlagCheck;
