import React from 'react';

//helpers
import { Roles } from '../../../helpers/rbac-rules';

//hooks
import { useMeQuery } from '../../../generated/graphql';
import { useRouteMatch } from 'react-router-dom';
import useSnackbar from '../../../hooks/useSnackbar';

// components
import { Switch, Route } from 'react-router-dom';
import FacilitiesListView from './FacilitiesListView';
import CreateFacility from './CreateFacility';
import FacilityDetails from './FacilityDetails';
import BlockedPageMessage from '../../Shared/BlockedPageMessage';
import Can from '../../Can';

//assets
import { ImgBoyShrug } from '@betterpt/better-icons';

const Facilities = () => {
  const snackbar = useSnackbar();
  const { url } = useRouteMatch();
  const meQuery = useMeQuery({
    onError: () => snackbar?.useGenericErrorMessage(),
  });

  return (
    <Switch>
      <Route path={`${url}`} exact>
        <FacilitiesListView />
      </Route>
      <Route path={`${url}/new`}>
        <Can
          perform='facility:create'
          role={Roles[meQuery.data?.me?.role || 'initial']}
          yes={() => <CreateFacility />}
          no={() => (
            <BlockedPageMessage
              icon={<ImgBoyShrug />}
              title='Permission Required'
              message={`Sorry, you don't have permission to access this information. Please contact your account owner or administrator to update your permissions.`}
              hideButton={true}
            />
          )}
        />
      </Route>
      <Route path={`${url}/:facilityId`}>
        <FacilityDetails />
      </Route>
    </Switch>
  );
};

export default Facilities;
