import * as React from 'react';

//types
import { Appointment } from '../../../../../../generated/graphql';

//helpers
import {
  specialtyDictionary,
  therapyTypeDictionary,
} from '@betterpt/better-components';

// hooks
import {
  useCompanyBookingFlowQuery,
  useFacilityBookingFlowQuery,
  useMeQuery,
} from '../../../../../../generated/graphql';

// components
import { GridCard } from '../../../../../Shared/GridCard';
import { InfoHeader } from '../../InPersonAppointmentsList/components/InPersonAppointmentCard/InPersonAppointmentCard.style';
import CardTextField from '../../../Shared/CardTextField';

// style
import * as styles from '../InPersonAppointmentDetails.style';

type Props = {
  appointment: Appointment;
};

//NOTE: helper accounts for old formatting in past appts
const formatStringForMaps = (string?: string | null) =>
  string
    ?.split(' ')
    .map((word) => word.toLowerCase())
    .join('_') ?? '';

const FullAppointmentInfo = ({ appointment }: Props) => {
  const meQuery = useMeQuery();

  const companyBookingFlowQuery = useCompanyBookingFlowQuery({
    variables: {
      id: meQuery.data?.me?.company?.id || '0',
    },
    skip: !meQuery.data?.me?.company?.id,
  });
  const facilityBookingFlowQuery = useFacilityBookingFlowQuery({
    variables: {
      id: appointment?.clinic?.id || appointment?.clinicId || '0',
    },
    skip: !appointment?.clinic?.id && !appointment?.clinicId,
  });

  const medicareStatus = appointment?.isMedicare ? 'Medicare' : 'Non-Medicare';
  const postOpStatus = appointment?.isPostOp ? 'Post-Op' : 'Non-Post-Op';
  const surgeryDate = appointment?.isPostOp
    ? appointment?.medicalProcedureDate ?? 'Not Provided'
    : 'N/A';
  const condition =
    appointment?.condition?.injuries?.map((inj) => inj?.area)?.join(', ') ??
    'Not Provided';
  const patientSelectedOtherCondition = appointment?.condition?.injuries?.some(
    (inj) => inj?.area?.toLowerCase() === 'other'
  );
  const firstTimeAtClinic = appointment?.isFirstTimeAtClinic ? 'Yes' : 'No';
  const howPatientHeardAboutYou =
    appointment?.marketingQuestionResponse || 'Not Provided';
  const therapyTypeString = formatStringForMaps(appointment?.therapyType);
  const specialtyString = formatStringForMaps(
    appointment?.physicalTherapySpecialty
  );
  const therapyType =
    therapyTypeDictionary.get(therapyTypeString) || 'Not Provided';
  const specialty = specialtyDictionary.get(specialtyString) || 'Not Provided';

  const askMedicare = !!companyBookingFlowQuery.data?.company?.bookingFlow
    ?.askMedicare;
  const askPostOp = !!companyBookingFlowQuery.data?.company?.bookingFlow
    ?.askPostOp;
  const askMarketingSource = !!companyBookingFlowQuery.data?.company
    ?.bookingFlow?.askMarketingSource;
  const askReturningPatient = !!companyBookingFlowQuery.data?.company
    ?.bookingFlow?.askReturningPatient;
  const askTherapyTypes = !!facilityBookingFlowQuery.data?.clinic
    ?.therapyTypeOptions?.length;
  const askSpecialties = !!facilityBookingFlowQuery.data?.clinic
    ?.physicalTherapySpecialtyOptions?.length;

  return (
    <GridCard style={styles.cardStyle} shadow='none'>
      <InfoHeader>
        <p>Qualifying Questions</p>
      </InfoHeader>
      <styles.CardContents>
        {askMedicare && (
          <styles.TextFieldRow>
            <CardTextField
              label='Medicare Status'
              value={medicareStatus}
              fullWidth
            />
          </styles.TextFieldRow>
        )}
        {askPostOp && (
          <styles.TextFieldRow>
            <CardTextField label='Post-Op Status' value={postOpStatus} />
            <CardTextField label='Surgery Date' value={surgeryDate} />
          </styles.TextFieldRow>
        )}
        <styles.TextFieldRow>
          <CardTextField
            label='Condition'
            value={condition}
            fullWidth={!patientSelectedOtherCondition}
          />
          {patientSelectedOtherCondition && (
            <CardTextField
              label='Condition Details'
              value={appointment?.otherExplanation || 'Not Provided'}
            />
          )}
        </styles.TextFieldRow>
        <styles.TextFieldRow>
          {askTherapyTypes && (
            <CardTextField
              label='Requested Therapy Type'
              value={therapyType}
              fullWidth={!askSpecialties}
            />
          )}
          {askSpecialties && (
            <CardTextField
              label='Requested Specialty'
              value={specialty}
              fullWidth={!askTherapyTypes}
            />
          )}
        </styles.TextFieldRow>
        <styles.TextFieldRow>
          {askReturningPatient && (
            <CardTextField
              label='First Time At Clinic'
              value={firstTimeAtClinic}
              fullWidth={!askMarketingSource}
            />
          )}
          {askMarketingSource && (
            <CardTextField
              label='How Patient Heard About You'
              value={howPatientHeardAboutYou}
              fullWidth={!askReturningPatient}
            />
          )}
        </styles.TextFieldRow>
      </styles.CardContents>
    </GridCard>
  );
};

export default FullAppointmentInfo;
