import React from 'react';

const IcCamera = ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='48'
    height='48'
    viewBox='0 0 48 48'
    {...rest}
  >
    <defs>
      <path
        id='dfb2rz96qa'
        d='M23.5 29.767c3.461 0 6.267-2.806 6.267-6.267 0-3.461-2.806-6.267-6.267-6.267-3.461 0-6.267 2.806-6.267 6.267 0 3.461 2.806 6.267 6.267 6.267zm-5.875-25.85l-3.584 3.916H7.833c-2.154 0-3.916 1.763-3.916 3.917v23.5c0 2.154 1.762 3.917 3.916 3.917h31.334c2.154 0 3.916-1.763 3.916-3.917v-23.5c0-2.154-1.762-3.917-3.916-3.917h-6.208l-3.584-3.916h-11.75zM23.5 33.292c-5.405 0-9.792-4.387-9.792-9.792s4.387-9.792 9.792-9.792 9.792 4.387 9.792 9.792-4.387 9.792-9.792 9.792z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <g transform='translate(-828 -467) translate(444 296) translate(48.62 142) translate(265 10.5) translate(70.8 19.153)'>
                <path d='M0 0L47 0 47 47 0 47z' />
                <mask id='89c34ckweb' fill={color ?? '#fff'}>
                  <use xlinkHref='#dfb2rz96qa' />
                </mask>
                <use
                  fill={color ?? '#333'}
                  fillRule='nonzero'
                  xlinkHref='#dfb2rz96qa'
                />
                <g fill={color ?? '#FFF'} mask='url(#89c34ckweb)'>
                  <path d='M0 0H47V47H0z' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IcCamera;
