import React from 'react';

//helpers
import { Roles } from '../../../../../helpers/rbac-rules';
import Can from '../../../../Can';

//hooks
import { useFacilityQuery, useMeQuery } from '../../../../../generated/graphql';
import useSnackbar from '../../../../../hooks/useSnackbar';
import useFacilityDetailOperations from '../hooks/useFacilityDetailOperations';

//types
import { Todo } from '../../FacilitiesListView';

// componennts
import { Grid, GridRow } from '../../../../Shared/GridCard';
import InPersonSettings from './InPersonSettings';
import NotAFacilityMemberCard from '../NotAFacilityMemberCard';
import YextMessage from '../YextMessage';
import HourNote from './HourNotes';
import DirectScheduling from './DirectScheduling';

//styles
import * as styles from '../FacilityDetails.style';

const AppointmentConfigTab = ({ facilityId }: { facilityId: string }) => {
  const snackbar = useSnackbar();
  const meQuery = useMeQuery();
  const facilityQuery = useFacilityQuery({
    variables: { id: facilityId },
    onError: () => snackbar?.useGenericErrorMessage(),
    fetchPolicy: 'cache-and-network',
  });

  console.log('Facility Auto Confirmed Status', facilityQuery.data?.clinic?.shouldUseAutoconfirmedAppointments);

  const company = meQuery.data?.me?.company;

  const autoConfirmedAppointments = facilityQuery.data?.clinic?.shouldUseAutoconfirmedAppointments;

  const isNotWhiteLabel = company?.providerAppId !== 'acorn' && company?.providerAppId !== 'somatus';

  const facilityIsManagedViaYext = facilityQuery.data?.clinic?.isManagedViaYext;

  const parseTodo = () => {
    const todoItems = facilityQuery.data?.clinic?.actionItems;
    if (todoItems) {
      if (todoItems?.addInsurance) {
        return Todo.addInsurance;
      } else if (todoItems?.updateInsurance) {
        return Todo.updateInsurance;
      }
    }
  };

  const todo = parseTodo();

  const {
    commands: { addHoursOfOperationNote },
  } = useFacilityDetailOperations(facilityId);

  return (
    <Grid>
      {facilityIsManagedViaYext && <YextMessage />}
      {meQuery.data && facilityQuery.data && (
        <Can
          role={Roles[meQuery.data?.me?.role || 'initial']}
          perform="facility:view"
          data={{
            employeeClinics: meQuery?.data?.me?.clinics,
            clinicToView: facilityQuery.data?.clinic,
          }}
          no={() => (
            <GridRow>
              <NotAFacilityMemberCard />
            </GridRow>
          )}
        />
      )}

      <Can
        role={Roles[meQuery.data?.me?.role || 'initial']}
        perform="facility:view"
        data={{
          employeeClinics: meQuery?.data?.me?.clinics,
          clinicToView: facilityQuery.data?.clinic,
        }}
        yes={() => (
          <React.Fragment>
            <h2 className="H1">Appointment Settings, Hours, and Terms</h2>
            <GridRow>{autoConfirmedAppointments && <DirectScheduling />}</GridRow>
            <GridRow>
              {isNotWhiteLabel && <InPersonSettings facilityId={facilityId} />}
              {!isNotWhiteLabel && <styles.InvisiblePlaceholder />}
            </GridRow>
            <GridRow>
              <HourNote facilityId={facilityId} addHoursOfOperationNote={addHoursOfOperationNote} />
            </GridRow>
          </React.Fragment>
        )}
      />
    </Grid>
  );
};

export default AppointmentConfigTab;
