import React from 'react';

import CreateEmployeeForm from './components/CreateEmployeeForm';
import useCreateEmployeeOperations from './hooks/useCreateEmployeeOperations';

const CreateEmployee = () => {
  const {
    commands: { createEmployee },
  } = useCreateEmployeeOperations();

  return <CreateEmployeeForm createEmployee={createEmployee} />;
};

export default CreateEmployee;
