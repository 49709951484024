import * as React from 'react';

// types
import { TextFieldState } from '../../../../../../../hooks/useTextFieldState';

// helpers
import { parseAddressFromString } from '../../../../../../../helpers';

type FormValues = {
  [s: string]: TextFieldState;
};

const useHandleAddress = (formValues: FormValues) => {
  const [addresses, setAddresses] = React.useState();

  const handleAddressesDone = (formattedAddress?: string) => {
    if (formattedAddress) {
      const {
        zipcode: newZipcode,
        address: newAddress,
        city: newCity,
        state: newState,
      } = parseAddressFromString(formattedAddress);

      formValues.zipcode.updateValue(newZipcode);
      formValues.address.updateValue(newAddress);
      formValues.city.updateValue(newCity);
      formValues.state.updateValue(newState);
    }
    setAddresses(undefined);
  };

  return { handleAddressesDone, addresses };
};

export default useHandleAddress;
