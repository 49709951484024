import styled from '@emotion/styled';

export const CompanyFormContainer = styled.div`
  display: flex;
`;

export const CompanyFormWrapper = styled.form`
  width: 450px;
  h2 {
    margin: 20px 0px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
`;
