import styled from '@emotion/styled';

export const SelectItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  p {
    width: 80px;
    font-size: 14px;
  }
`;

export const SelectSection = styled.div`
  font-family: Roboto, sans-serif;
`;
