import React from 'react';

//types
import { MappedAppointmentTypeDetail } from '../hooks/useAppointmentTypeDetailOperations';

//helpers
import { alphabeticalSort } from '@betterpt/better-components';

//hooks
import { useHistory } from 'react-router-dom';
import useDebounce from '../../../../hooks/useDebounce';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';

//components
import { Card, Table } from '@betterpt/better-components';
import ThemedTextField from '../../../Shared/ThemedTextField';

//styles
import {
  DetailSubheader,
  DetailTableHeaderSection,
  NoProvidersOrFacilities,
  TableStyleWrapper,
  detailTableCardStyle,
  killMargin,
} from '../../../Shared/AppointmentTypes/AppointmentTypes.style';

interface Props {
  mappedAppointmentType: MappedAppointmentTypeDetail;
}

const AppointmentTypeFacilities = ({ mappedAppointmentType }: Props) => {
  const history = useHistory();
  const { facilitiesPluralAlias } = useRemoteConfigAliases();
  const { loading, facilities } = mappedAppointmentType;

  const [workingFacilitiesList, updateWorkingFacilitiesList] = React.useState(
    alphabeticalSort(facilities, 'name')
  );
  const [searchText, updateSearchText] = React.useState('');
  const debouncedSearchText = useDebounce(searchText, 500);

  React.useEffect(() => {
    const filteredFacilities = debouncedSearchText.length
      ? facilities.filter((facility) =>
          (
            facility.name.toLowerCase() +
            facility.address.toLowerCase() +
            facility.city.toLowerCase() +
            facility.state.toLowerCase()
          ).includes(debouncedSearchText.toLowerCase())
        )
      : facilities;
    updateWorkingFacilitiesList(alphabeticalSort(filteredFacilities, 'name'));
  }, [debouncedSearchText, facilities]);

  const columns = [
    {
      field: 'name',
      title: 'NAME',
    },
    {
      field: 'address',
      title: 'ADDRESS',
    },
    {
      field: 'city',
      title: 'CITY',
    },
    {
      field: 'state',
      title: 'STATE',
    },
  ];

  const data = workingFacilitiesList.map((facility) => ({
    id: facility?.id,
    name: facility?.name,
    address: facility?.address,
    city: facility?.city,
    state: facility?.state,
  }));

  const tableOptions = {
    cellPadding: 23,
    minBodyHeight: 500,
    maxBodyHeight: 500,
    overflow: 'auto' as 'auto',
    searchNotFoundText: 'No results',
  };

  return (
    <>
      <DetailTableHeaderSection>
        <DetailSubheader>
          {facilitiesPluralAlias} That Use this Appointment Type
        </DetailSubheader>
        {(facilities.length || loading) && (
          <ThemedTextField
            label={`Search ${facilitiesPluralAlias.toLowerCase()}`}
            placeholder='Enter name or location'
            value={searchText}
            onChange={(e) => {
              updateSearchText(e.target.value);
            }}
            style={killMargin}
          />
        )}
      </DetailTableHeaderSection>
      <Card fullWidth style={detailTableCardStyle}>
        {facilities.length || loading ? (
          <TableStyleWrapper>
            <Table
              onRowClick={(_e, facility) =>
                history.push(`/facilities/${facility?.id}`)
              }
              columns={columns}
              data={data}
              isLoading={loading}
              isSearching={!!searchText}
              options={tableOptions}
            />
          </TableStyleWrapper>
        ) : (
          <NoProvidersOrFacilities>
            No {facilitiesPluralAlias.toLowerCase()} use this appointment type
            or you do not have permission to view them.
          </NoProvidersOrFacilities>
        )}
      </Card>
    </>
  );
};

export default AppointmentTypeFacilities;
