import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='161'
    height='161'
    viewBox='0 0 161 161'
    {...rest}
  >
    <defs>
      <path id='46uae8lcma' d='M35 157.013L126 157.013 126 11 35 11z' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g transform='translate(-720 -202) translate(598 203) translate(122.5)'>
            <path d='M0 0H160V160H0z' />
            <path
              fill='#D2E9F8'
              d='M126 125.656v22.5H35v-22.5c0-20.97 14.19-38.63 33.5-43.89 1.31-.37 2.64-.67 4-.91 2.6-.46 5.27-.7 8-.7 3.44 0 6.78.38 10 1.1 1.36.31 2.69.67 4 1.1 6.93 2.23 13.14 6.09 18.17 11.13 8.24 8.23 13.33 19.61 13.33 32.17'
            />
            <path
              fill='#ED4B59'
              d='M80.103 155.756l-2.906-4.716c-.29-.47-.427-1.02-.388-1.572l2.53-48.315c.008-.1.022-.2.038-.299.532-2.844 4.588-2.894 5.187-.063l.015.07c.02.096.036.195.046.294l3.48 48.568c.06.632-.109 1.263-.477 1.781L84.51 155.9c-1.097 1.543-3.413 1.468-4.407-.144'
            />
            <path
              fill='#ED4B59'
              d='M81.813 100.517L76.951 95.656 81.813 90.794 86.674 95.656z'
            />
            <path
              fill='#FEFEFE'
              d='M94.5 82.356v2.63c0 5.209-3.064 9.703-7.488 11.777-1.674.784-3.541-5.107-5.512-5.107-1.795 0-3.505 5.966-5.061 5.308C71.773 94.99 68.5 90.37 68.5 84.986v-3.22c1.31-.37 2.64-.67 4-.91l18 .4c1.36.31 2.69.67 4 1.1'
            />
            <path
              stroke='#2B4F83'
              strokeLinecap='round'
              strokeLinejoin='round'
              stroke-width='2'
              d='M94.5 82.356v2.63c0 5.209-3.064 9.703-7.488 11.777-1.674.784-3.541-5.107-5.512-5.107-1.795 0-3.505 5.966-5.061 5.308C71.773 94.99 68.5 90.37 68.5 84.986v-3.22c1.31-.37 2.64-.67 4-.91l18 .4c1.36.31 2.69.67 4 1.1z'
            />
            <path
              fill='#E89972'
              d='M90.5 67.656v15c0 4.97-4.03 9-9 9s-9-4.03-9-9v-15h18z'
            />
            <path
              fill='#F8AA7E'
              d='M113.15 49.8c-.699 9.363-5.122 17.556-11.724 23.23-6.59 5.685-15.35 8.841-24.714 8.141-18.728-1.399-32.772-17.71-31.373-36.439.028-.369.065-.727.103-1.095.411-3.9 1.478-7.57 3.062-10.932.215-.456.438-.9.671-1.334 1.998-3.782 4.684-7.12 7.888-9.87 6.592-5.684 15.352-8.84 24.715-8.14 7.71.575 14.617 3.668 19.977 8.431.067.045.124.1.188.174 1.091.964 2.116 2.014 3.066 3.118 2.76 3.194 4.92 6.895 6.333 10.932 1.066 3.058 1.716 6.295 1.866 9.656.046.996.05 2.01.004 3.028-.017.37-.034.73-.062 1.1'
            />
            <path
              fill='#2B4F83'
              d='M66.757 53.352c-1.102-.082-1.928-1.042-1.846-2.143l.263-3.506c.082-1.101 1.042-1.928 2.144-1.846 1.1.083 1.927 1.042 1.844 2.144l-.261 3.506c-.082 1.1-1.042 1.928-2.144 1.845M88.696 54.992c-1.102-.083-1.928-1.042-1.846-2.144l.262-3.505c.082-1.102 1.042-1.928 2.144-1.846 1.101.082 1.927 1.042 1.845 2.143l-.262 3.506c-.082 1.102-1.04 1.928-2.143 1.846'
            />
            <path
              fill='#BA7155'
              d='M59.798 16.88c.035.25.07.502.116.743.27 1.445.813 2.855 1.606 4.22l-.001.01c4.775 8.287 18.473 14.964 35.049 16.202 6.49.485 12.67.083 18.138-1.051-.155-.52-.322-1.027-.501-1.526-1.52-4.343-3.844-8.325-6.813-11.761-6.105-7.102-14.92-11.86-24.992-12.613-8.294-.62-16.138 1.567-22.602 5.777'
            />
            <path
              fill='#BA7155'
              d='M41.533 47.122c11.408-3.988 19.26-11.534 19.944-20.686.096-1.287.05-2.571-.141-3.837-.275-1.878-.85-3.708-1.704-5.475-.11-.261-.234-.51-.37-.76-1.461 1.13-2.861 2.39-4.185 3.74-3.841 3.907-7.078 8.632-9.506 13.966-1.834 4.042-3.217 8.414-4.038 13.052M113.38 53.559c-9.997-6.621-15.657-15.577-13.99-24.137.235-1.204.606-2.368 1.112-3.48.743-1.648 1.765-3.174 3.037-4.57.174-.21.357-.406.55-.6 1.125 1.415 2.155 2.934 3.09 4.515 2.714 4.578 4.637 9.748 5.624 15.266.742 4.18.965 8.544.578 13.006'
            />
            <path
              fill='#F8AA7E'
              d='M117.581 58.048c-.147 1.968-1.077 3.69-2.464 4.883-1.386 1.195-3.226 1.858-5.195 1.71-3.937-.293-6.888-3.722-6.594-7.658.006-.078.014-.153.022-.23.085-.82.31-1.592.643-2.298.045-.096.092-.19.142-.281.42-.794.984-1.496 1.657-2.074 1.386-1.195 3.226-1.858 5.195-1.711 1.62.12 3.073.77 4.198 1.772.015.01.027.02.041.037.23.203.444.423.644.655.58.672 1.034 1.449 1.331 2.298.224.643.36 1.323.392 2.029.01.21.01.423.001.637-.004.078-.007.153-.013.23M55.914 52.214c-.146 1.968-1.077 3.69-2.464 4.883-1.386 1.195-3.226 1.858-5.195 1.711-3.936-.294-6.888-3.723-6.594-7.659.006-.078.014-.153.022-.23.086-.82.311-1.592.644-2.298.045-.096.092-.189.141-.28.42-.795.984-1.497 1.657-2.075 1.386-1.195 3.227-1.858 5.195-1.71 1.62.12 3.073.77 4.2 1.771.013.01.025.021.04.037.228.203.443.423.643.655.58.672 1.034 1.45 1.331 2.298.224.643.36 1.323.393 2.03.008.21.01.422.001.636-.004.078-.008.153-.014.231'
            />
            <mask id='u4wx0g3u0b' fill='#fff'>
              <use xlinkHref='#46uae8lcma' />
            </mask>
            <path
              fill='#BA7155'
              d='M105.417 56.239L109.25 56.239 109.25 46.072 105.417 46.072zM47.25 52.472L51.083 52.472 51.083 42.305 47.25 42.305z'
              mask='url(#u4wx0g3u0b)'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
