import styled from '@emotion/styled';
import { bestTextColor } from '@betterpt/better-components';

export const addFacilityButton = (color: string) => ({
  backgroundColor: color,
  color: bestTextColor(color),
  width: 282,
});

export const TitleAndSearchSection = styled.div`
  display: flex;
  align-items: center;
`;

export const TableStyleWrapper = styled.div`
  .MuiTableCell-root p {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
`;
