import styled from '@emotion/styled';
import { colorValues } from '../helpers/colors';

export const InfoBoxMain = styled.span<{
  fixedPlacement?: boolean;
  fullWidth?: boolean;
}>`
  position: ${(props) => (props.fixedPlacement ? 'absolute' : 'relative')};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
`;

export const InfoBoxIcon = styled.span<{
  padding?: string | number;
}>`
  cursor: pointer;
  display: inline-block;
  padding: ${(props) => props.padding};
`;

export const InfoBoxContent = styled.span<{
  alignTop: boolean;
  alignLeft: boolean;
  boxTop: number;
  boxBottom: number;
  boxLeft: number;
  boxRight: number;
  isOpen?: boolean;
}>`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  z-index: ${(props) => (props.isOpen ? 1000 : -1)};
  top: ${(props) => !props.alignTop && props.boxTop + 'px'};
  bottom: ${(props) => props.alignTop && props.boxBottom + 'px'};
  left: ${(props) => !props.alignLeft && props.boxLeft + 'px'};
  right: ${(props) => props.alignLeft && props.boxRight + 'px'};
  position: absolute;
  padding: 20px 35px;
  background-color: white;
  box-shadow: 0 2px 40px 0 rgba(51, 51, 51, 0.71),
    0 2px 10px 0 rgba(51, 51, 51, 0.24);
  text-align: left;
  border-radius: 5px;
  &:hover {
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    z-index: ${(props) => (props.isOpen ? 1000 : -1)};
  }
  p {
    font-size: 14px;
    line-height: 1.43;
    color: ${colorValues.betterptblack};
  }
  h4 {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.17;
    margin: 10px 0 15px;
    letter-spacing: normal;
    color: ${colorValues.charcoalgray};
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
  }
`;

export const InfoBoxArrow = styled.span<{
  bestArrowSize: number;
  alignTop: boolean;
  alignLeft: boolean;
  arrowVertical: number;
  arrowHorizontal: number;
}>`
  width: ${(props) => props.bestArrowSize}px;
  height: ${(props) => props.bestArrowSize}px;
  top: ${(props) => !props.alignTop && props.arrowVertical + 'px'};
  bottom: ${(props) => props.alignTop && props.arrowVertical + 'px'};
  right: ${(props) => props.alignLeft && props.arrowHorizontal + 'px'};
  left: ${(props) => !props.alignLeft && props.arrowHorizontal + 'px'};
  position: absolute;
  background-color: white;
  border: none;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
`;

export const closeButtonStyle: React.CSSProperties = {
  position: 'absolute',
  top: 15,
  right: 15,
  height: 20,
  width: 20,
  cursor: 'pointer',
};
