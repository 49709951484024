import React from 'react';

//hooks
import useRemoteConfigAliases from '../../hooks/useRemoteConfigAliases';
import { useHistory } from 'react-router-dom';

//components
import { Button } from '@betterpt/better-components';

//styles
import styled from '@emotion/styled';
const Header = styled.h1<{ wideText?: boolean }>`
  width: ${({ wideText }) => (wideText ? '100%' : '333px')};
  font-size: 20px;
  font-weight: bold;
  margin-top: 35px;
  text-align: center;
  margin-bottom: 0;
`;

const Message = styled.h2<{ wideText?: boolean }>`
  width: ${({ wideText }) => (wideText ? '100%' : '387px')};
  font-size: 16px;
  margin: 29px 9px 35px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
`;

const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    height: 137px;
    width: 137px;
  }
`;

type Props = {
  icon: React.ReactNode;
  title: string;
  message: string;
  buttonText?: string;
  onClick?: () => void;
  pathname?: string;
  locationState?: { [key: string]: any };
  hideButton?: boolean;
  wideText?: boolean;
  style?: { [key: string]: any };
};

const BlockedPageMessage = ({
  icon,
  title,
  message,
  buttonText,
  onClick,
  pathname,
  locationState,
  hideButton,
  wideText,
  style,
}: Props) => {
  const history = useHistory();
  const { primaryColor } = useRemoteConfigAliases();

  const buttonStyle = {
    backgroundColor: primaryColor,
    width: 405,
  };

  return (
    <Page style={style ?? {}}>
      <ContentSection>
        {icon}
        <Header wideText={wideText}>{title}</Header>
        <Message wideText={wideText}>{message} </Message>

        {!hideButton && (
          <Button
            size='large'
            style={buttonStyle}
            onClick={() => {
              if (onClick) {
                onClick?.();
              } else if (pathname?.length) {
                history.push({
                  pathname,
                  state: { ...locationState },
                });
              } else history.goBack();
            }}
          >
            {buttonText || 'THANK YOU'}
          </Button>
        )}
      </ContentSection>
    </Page>
  );
};

export default BlockedPageMessage;
