import React from 'react';

import styled from '@emotion/styled';

// components
import {
  colorValues,
  Button,
  bestTextColor,
  CloseButton,
} from '@betterpt/better-components';
import { IcSave } from '@betterpt/better-icons';
import { Dialog } from '@material-ui/core';

//hooks
import useRemoteConfigAliases from '../../../hooks/useRemoteConfigAliases';
import { useLocation } from 'react-router-dom';
import { useMeQuery } from '../../../generated/graphql';
import useSnackbar from '../../../hooks/useSnackbar';
import useHandleError from '../../../hooks/useHandleError';

const SaveButton = styled.button<{
  textColor: string;
  backgroundColor: string;
  disabled: boolean;
}>`
  height: 36px;
  border-radius: 23.5px;
  display: flex;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: 0 0 4px 0 rgb(51 51 51 / 47%), 0 2px 2px 0 rgb(51 51 51 / 24%);
  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? colorValues.earlysmoke : colorValues.dust};
  }
  p {
    color: ${({ textColor }) => textColor};
    font-weight: bold;
    margin-left: 5px;
    font-size: 14px;
  }
`;

const FilterContainer = styled.div<{ margin?: string }>`
  position: relative;
  margin: ${(props) => props?.margin ?? '12px 6px 0 4px'};
`;

const DialogBody = styled.div`
  width: 376px;
  padding: 45px 52px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Tip = styled.p`
  margin-top: 30px;
  width: 345px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  margintop: 30px;
`;

const DialogTitle = styled.p`
  width: 376px;
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 20px 0;
`;

const DialogContent = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  margin-bottom: 35px;
`;

const SaveFilters = ({
  onSave,
}: {
  onSave: (filterUrl: string) => Promise<void>;
}) => {
  const [isSaveDialogOpen, updateSaveDialogOpen] = React.useState(false);
  const [saving, updateSaving] = React.useState(false);
  const location = useLocation();
  const meQuery = useMeQuery();
  const snackbar = useSnackbar();
  const handleError = useHandleError();

  const searchParams = meQuery.data?.me?.customSearchTemplates;

  const isActiveFilterSameAsDefault =
    searchParams?.telehealthAppointmentSearchTemplate?.default ===
      location.search ||
    searchParams?.traditionalAppointmentSearchTemplate?.default ===
      location.search;

  // variables
  const { primaryColor } = useRemoteConfigAliases();
  const textColor = isActiveFilterSameAsDefault
    ? colorValues.charcoalgray
    : colorValues.eveningsky;
  const backgroundColor = isActiveFilterSameAsDefault
    ? colorValues.earlysmoke
    : colorValues.frost;

  const DialogButtonStyle = {
    backgroundColor: primaryColor,
    color: bestTextColor(primaryColor),
    width: 376,
    fontWeight: 'bold' as 'bold',
  };

  const handleSaveDefault = async () => {
    updateSaving(true);
    try {
      await onSave(location.search);
      updateSaving(false);
      updateSaveDialogOpen(false);
      snackbar?.openSnackbar({
        isError: false,
        message: 'Filter saved as default',
      });
    } catch (e) {
      handleError(e);
      updateSaving(false);
    }
  };
  return (
    <FilterContainer>
      <Dialog
        open={isSaveDialogOpen}
        onClose={() => updateSaveDialogOpen(false)}
      >
        <DialogBody>
          <CloseButton onClose={() => updateSaveDialogOpen(false)} />
          <DialogTitle>Save as Default Appointment Filter</DialogTitle>
          <DialogContent>
            Save this appointment filter as your default in-person filter for
            easy access in the future. Please note that this will override your
            current default filter settings.
          </DialogContent>
          <Button
            size='large'
            style={DialogButtonStyle}
            onClick={handleSaveDefault}
            loading={saving}
          >
            SAVE AS DEFAULT APPOINTMENT FILTER
          </Button>
          <Tip>
            Pro Tip: If you want to save multiple filters, simply bookmark the
            URL in your browser when you select a new filter configuration. This
            allows for easy in-browser access to a custom filter list in
            addition to your saved default filter.
          </Tip>
        </DialogBody>
      </Dialog>
      <SaveButton
        textColor={textColor}
        backgroundColor={backgroundColor}
        onClick={() => updateSaveDialogOpen(true)}
        disabled={isActiveFilterSameAsDefault}
      >
        <IcSave color={textColor} />
        <p style={{fontSize: 10}}>SAVE</p>
      </SaveButton>
    </FilterContainer>
  );
};

export default SaveFilters;