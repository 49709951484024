import * as React from 'react';

//types
import { AlternateTimeResponseStatus, Appointment, Role, NewAppointmentStatus } from '../../../../../generated/graphql';

//helpers
import { mapAppointmentToInsuranceInfo, mapAppointmentToPatientInfo } from '../../../../../helpers';
import { useAppointmentCopy } from '../InPersonAppointmentsList/components/InPersonAppointmentStatus';
import { isBefore } from 'date-fns';

//hooks
import { useParams } from 'react-router-dom';
import { useMeQuery, useInPersonAppointmentDetailsQuery, usePatientDetailsQuery } from '../../../../../generated/graphql';
import useSnackbar from '../../../../../hooks/useSnackbar';
import useFeatureAccessCheck from '../../../../../hooks/useFeatureAccessCheck';

//components
import { Link } from 'react-router-dom';
import { Animation, Card } from '@betterpt/better-components';
import ContactThisPatientDialog from './components/ContactThisPatientDialog';
import FullAppointmentInfo from './components/FullAppointmentInfo';
import InPersonAppointmentCard from '../InPersonAppointmentsList/components/InPersonAppointmentCard';
import LinkContactAndPatientDialog from '../../Shared/LinkContactAndPatientDialog';
import NotFoundPage from '../../../../NotFoundPage';
import InsuranceInfo from '../../../Patients/PatientDetail/InsuranceInfo';
import PatientInfo from '../../../Patients/PatientDetail/PatientInfo';
import CustomFormResponse from '../../Shared/CustomFormResponse';
import IndustrySpecificQuestionsPT from '../../Shared/IndustrySpecificQuestionsPT/IndustrySpecificQuestionsPT';

//assets
import { IcArrowLeft } from '@betterpt/better-icons';

//styles
import { colorValues } from '@betterpt/better-components';
import * as styles from './InPersonAppointmentDetails.style';

const AppointmentDetails = () => {
  const [isOptionsOpen, setIsOptionsOpen] = React.useState(false);
  const snackbar = useSnackbar();
  const { appointmentId } = useParams<{ appointmentId: string }>();
  const meQuery = useMeQuery();
  const appointmentQuery = useInPersonAppointmentDetailsQuery({
    variables: { id: appointmentId },
    fetchPolicy: 'cache-and-network',
  });
  const { isPT } = useFeatureAccessCheck();
  // we have to fetch the patient separately, because we need to get the federated contact on patient
  // in order to know if this patient has been linked to a contact or not. In order to do this query,
  // we have to know the clinicId, which we don't know until the appointment has been fetched.
  const patientQuery = usePatientDetailsQuery({
    variables: {
      id: appointmentQuery.data?.appointment?.patientId!,
      clinicId: appointmentQuery.data?.appointment?.clinicId!,
    },
    skip: !appointmentQuery.data?.appointment?.patientId || !appointmentQuery.data?.appointment?.clinicId,
  });

  const [isLinkDialogOpen, updateIsLinkDialogOpen] = React.useState(false);
  const [isContactPatientDialogOpen, updateIsContactPatientDialogOpen] = React.useState(false);

  const loading = appointmentQuery.loading || patientQuery.loading;
  const appointment = appointmentQuery.data?.appointment;
  const facilityHasBeenMigrated = appointment?.clinic?.isBetterAccessOnly;
  const isBasic = meQuery.data?.me?.role === Role.Self;
  const patient = patientQuery.data?.patient;
  // Here we combine the data so we can pass it into the components below
  const fullAppointment = { ...appointment, patient } as Appointment;
  const isRequest = appointment?.appointmentStatus === NewAppointmentStatus.Requested;
  const requestReceivedSMS = isRequest && !!appointment?.alternateTimesExpireAt;
  const timesExpired = requestReceivedSMS && !isBefore(new Date(), new Date(appointment?.alternateTimesExpireAt));
  const requestedNewTime = appointment?.alternateTimeResponseStatus === AlternateTimeResponseStatus.RequestedNewTime;
  const needsFollowUp =
    appointment?.alternateTimeResponseStatus === AlternateTimeResponseStatus.NeedsFollowUp ||
    (timesExpired && !appointment?.alternateTimeResponseStatus);
  const { detailHeaderCopy } = useAppointmentCopy(appointment as Appointment);

  const queryError = meQuery.error || appointmentQuery.error || patientQuery.error;

  React.useEffect(() => {
    if (queryError?.message) {
      snackbar?.useGenericErrorMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryError]);

  React.useEffect(() => {
    if (!isBasic && !patientQuery.loading && patientQuery.called && patientQuery.data) {
      if (!patientQuery.data?.patient?.contact?.id && facilityHasBeenMigrated) {
        updateIsLinkDialogOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityHasBeenMigrated, isBasic, patientQuery.data]);

  React.useEffect(() => {
    if (!isBasic && !appointmentQuery.loading && appointmentQuery.called && appointmentQuery.data) {
      if (isRequest && (requestedNewTime || needsFollowUp)) {
        updateIsContactPatientDialogOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentQuery.data, isBasic, isRequest, needsFollowUp, requestedNewTime]);

  const handleLinkDialogClose = () => updateIsLinkDialogOpen(false);
  const handleContactThisPatientDialogClose = () => updateIsContactPatientDialogOpen(false);
  const handleChangingAppointmentOptions = () => appointmentQuery.refetch();

  if (loading) {
    return <Animation type="providerAppLoader" />;
  }

  if (!appointment) {
    return <NotFoundPage />;
  }

  return (
    <styles.AppointmentDetailContainer data-testid="appointment-container">
      {isLinkDialogOpen && (
        <LinkContactAndPatientDialog
          appointment={fullAppointment as Appointment}
          isOpen={isLinkDialogOpen}
          handleClose={handleLinkDialogClose}
        />
      )}
      {isContactPatientDialogOpen && (
        <ContactThisPatientDialog
          appointment={fullAppointment as Appointment}
          open={isContactPatientDialogOpen}
          onClose={handleContactThisPatientDialogClose}
        />
      )}
      <styles.PageHeader>
        <styles.TitleContainer>
          <Link to="/appointments/list" style={styles.link}>
            <IcArrowLeft />
          </Link>
          <h1 className="H1">
            <Link to="/appointments/list" className="button-or-link" style={{ color: colorValues.frenchblue }}>
              In-Person Appointments Overview
            </Link>{' '}
            / {detailHeaderCopy}
          </h1>
        </styles.TitleContainer>
      </styles.PageHeader>
      <Card
        style={{
          boxShadow: `0 0 10px 0 rgba(51, 51, 51, 0.24), 0 2px 5px 0 rgba(51, 51, 51, 0.12)`,
          marginBottom: 50,
          width: '100%',
        }}
      >
        <InPersonAppointmentCard
          onDoneChangingOptionsAppointment={handleChangingAppointmentOptions}
          appointment={fullAppointment}
          detailPage
          openStatus={{
            value: isOptionsOpen,
            update: setIsOptionsOpen,
          }}
        />
        {/* Industry Specific Questions Section shown ony for PT */}
        {isPT && fullAppointment.isDirectAccess !== null && (
          <styles.ExpandedDetailContent>
            <IndustrySpecificQuestionsPT appointment={fullAppointment} />
          </styles.ExpandedDetailContent>
        )}
        <styles.ExpandedDetailContent>
          {fullAppointment.customFormResponse ? (
            <CustomFormResponse customFormResponse={fullAppointment.customFormResponse} />
          ) : (
            <FullAppointmentInfo appointment={fullAppointment} />
          )}
        </styles.ExpandedDetailContent>
        <styles.ExpandedDetailContent style={{ alignItems: 'flex-start' }}>
          <PatientInfo data={mapAppointmentToPatientInfo(fullAppointment)} />
          <InsuranceInfo data={mapAppointmentToInsuranceInfo(fullAppointment)} />
        </styles.ExpandedDetailContent>
      </Card>
    </styles.AppointmentDetailContainer>
  );
};

export default AppointmentDetails;
