import { ServerHours } from './convertHours';

export const validateTimes = (hours: ServerHours) => {
  Object.values(hours).forEach((value) => {
    const open = value?.[0]?.open ?? 0;
    const close = value?.[0]?.close ?? 0;
    if (open > close) {
      throw new Error('Open time cannot be after close time.');
    }
    if (open % 5 !== 0 || close % 5 !== 0) {
      throw new Error(
        'Open and close times should fall on a minute that ends in a 5 or 0.'
      );
    }
  });
};
