import React from 'react';
import FormField from '../../../../Shared/FormField';
import { TextFieldState } from '../../../../../hooks/useTextFieldState';

interface Props {
  bioState: TextFieldState;
  degreeOrCertificationState: TextFieldState;
  yearsExperienceState: TextFieldState;
  showProviderFields: boolean;
}

const ProviderFields: React.FC<React.PropsWithChildren<Props>> = ({
  bioState,
  degreeOrCertificationState,
  yearsExperienceState,
  showProviderFields,
}) => {
  if (!showProviderFields) return null;
  return (
    <>
      <h4>Provider Information</h4>
      <FormField
        fullWidth
        label='Bio'
        formHandler={bioState}
        placeholder="Enter this provider's bio."
        multiline
        helperText={`${bioState.value.length}/1000`}
        error={!!bioState.error?.length}
      />
      <FormField
        fullWidth
        label='Degree Or Certification'
        placeholder="Enter this provider's degree or certification here"
        formHandler={degreeOrCertificationState}
        inputProps={{
          maxLength: 255,
        }}
      />
      <FormField
        fullWidth
        label='Practicing Since'
        placeholder='Enter when this user started practicing (MM/YYYY)'
        formHandler={yearsExperienceState}
      />
    </>
  );
};

export default ProviderFields;
