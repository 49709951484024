import * as React from 'react';

//helpers
import { colorValues } from '@betterpt/better-components';

//components
import Autocomplete from '@material-ui/lab/Autocomplete';
import ThemedTextField from 'apps/provider-app/src/components/Shared/ThemedTextField';

//assets
import { IcClose, IcDropDownSelected } from '@betterpt/better-icons';

// styles
import { AutocompleteDropdownStyleWraper, AutocompleteDropdownDrawerContainer } from './AutocompleteDropdown.style';

type Props<T> = {
  options: T[];
  getOptionLabel: (option: T) => string;
  getOptionSelected?: (option: T, value: T) => boolean;
  onOptionSelect: (option: T | null) => void;
  onInputChange: (inputValue: string | null) => void;
  selectedOption: T | null;
  isLoading: boolean;
  placeholder?: any;
  label?: string;
  placeholderOption: T;
  renderOption: (option: T) => React.ReactNode;
};

const AutocompleteDropdown: React.FC<Props<any>> = ({
  options,
  getOptionLabel,
  getOptionSelected,
  onOptionSelect,
  onInputChange,
  selectedOption,
  isLoading,
  placeholder,
  label,
  placeholderOption,
  renderOption,
}) => {
  const [open, setOpen] = React.useState(false);
  const [inputValue, updateInputValue] = React.useState('');

  const sharedProps = {
    selectOnFocus: true,
    clearOnBlur: true,
    blurOnSelect: true,
    fullWidth: true,
  };

  if (isLoading) {
    return (
      <div style={AutocompleteDropdownDrawerContainer}>
        <ThemedTextField label={label} style={{ maxWidth: '521px' }} disabled fullWidth value={'Loading...'} />
      </div>
    );
  }

  return (
    <AutocompleteDropdownStyleWraper text={inputValue}>
      <div style={AutocompleteDropdownDrawerContainer}>
        <div style={{ width: '100%' }}>
          <Autocomplete
            {...sharedProps}
            options={[placeholderOption, ...options]}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            style={{ maxWidth: '521px' }}
            loading={isLoading}
            loadingText="Loading..."
            inputValue={inputValue}
            onInputChange={(_, newInputValue) => {
              updateInputValue(newInputValue);
            }}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            value={selectedOption}
            onChange={(_, selectedValue) => {
              onOptionSelect(selectedValue);
              if (selectedValue === null) {
                onInputChange(null);
              } else {
                onInputChange(selectedValue);
              }
            }}
            data-testid={`autocomplete-picker-${label}`}
            renderInput={(params) => <ThemedTextField {...params} label={label} placeholder={placeholder} data-cy="autocomplete-picker" />}
            renderOption={(option) => {
              return <p style={{ margin: '0px' }}>{renderOption(option)}</p>;
            }}
            popupIcon={<IcDropDownSelected style={{ padding: 12 }} color={colorValues.betterptblack} />}
            closeIcon={<IcClose color={colorValues.betterptblack} />}
          />
        </div>
      </div>
    </AutocompleteDropdownStyleWraper>
  );
};

export default AutocompleteDropdown;
