import React from 'react';

import {
  Switch as MSwitch,
  makeStyles,
  createStyles,
  RadioProps,
} from '@material-ui/core';
import { colorValues } from '../helpers/colors';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 24,
      height: 12,
      padding: 0,
      display: 'flex',
      '& .MuiSwitch-switchBase': {
        height: 8,
        padding: 2,
        color: colorValues.emptiness,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(12px)',
      },
      '& .MuiSwitch-track': {
        backgroundColor: colorValues.cityblock,
        opacity: 1,
      },
      '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: `${colorValues.cityblock} !important`,
        opacity: '0.5 !important',
      },
      '& .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: `${colorValues.cityblock} !important`,
        opacity: '0.5 !important',
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: (props: any) =>
          props?.customColor || colorValues.guajirogreen,
        opacity: 1,
      },
      '& .MuiSwitch-colorSecondary.Mui-checked:hover': {
        backgroundColor: (props: any) =>
          props?.customColor || colorValues.guajirogreen,
        opacity: 1,
      },
      '& .MuiSwitch-thumb': {
        width: 8,
        height: 8,
        boxShadow: 'none',
      },
    },
  })
);

type OptionalProps = {
  customColor?: string;
};
const optionalPropTypes = ['customColor'];

const removePropsFromInput = (rest: any) => {
  let actualProps = {};
  Object.keys(rest).forEach((key) => {
    if (optionalPropTypes.indexOf(key) === -1) {
      actualProps[key] = rest[key];
    }
  });
  return actualProps;
};

const Switch = ({ ...rest }: RadioProps & OptionalProps) => {
  const classes = useStyles(rest);
  const actualProps = removePropsFromInput({ ...rest });
  return <MSwitch className={classes.root} {...actualProps} />;
};

export default Switch;
