import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='161'
    height='160'
    viewBox='0 0 161 160'
    {...rest}
  >
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g transform='translate(-719 -272) translate(597 272) translate(122.5) translate(30)'>
              <path
                fill='#0088CF'
                d='M69.357 121H28.504c1.67-12.51 2.554-20.976 2.656-25.399.156-6.817-.342-15.21-1.495-25.182 0-14.956 6-19.56 14.164-23.312.553-.264 1.116-.478 1.69-.65 1.1-.327 2.229-.498 3.383-.498 1.454 0 2.867.27 4.228.784.575.221 1.137.478 1.691.785 2.93 1.59 5.944 2.262 8.071 5.856 3.484 5.87 5.247 8.077 5.247 17.035-1.525 9.913-2.198 18.603-2.018 26.07.09 3.773 1.17 11.943 3.236 24.511z'
              />
              <circle
                cx='42'
                cy='50.342'
                r='1'
                fillRule='nonzero'
                stroke='#FFCC46'
              />
              <circle
                cx='43'
                cy='53.342'
                r='1'
                fillRule='nonzero'
                stroke='#FFCC46'
              />
              <circle
                cx='45'
                cy='56.342'
                r='1'
                fillRule='nonzero'
                stroke='#FFCC46'
              />
              <circle
                cx='48'
                cy='58.342'
                r='1'
                fillRule='nonzero'
                stroke='#FFCC46'
              />
              <circle
                cx='52'
                cy='58.342'
                r='1'
                fillRule='nonzero'
                stroke='#FFCC46'
              />
              <circle
                cx='54'
                cy='55.342'
                r='1'
                fillRule='nonzero'
                stroke='#FFCC46'
              />
              <circle
                cx='56'
                cy='52.342'
                r='1'
                fillRule='nonzero'
                stroke='#FFCC46'
              />
              <circle
                cx='56'
                cy='49.342'
                r='1'
                fillRule='nonzero'
                stroke='#FFCC46'
              />
              <path
                fill='#4D4D4D'
                d='M47.495 33.693c-7.952-9.02-7.182-22.855 1.72-30.912 4.447-4.025 11.274-3.635 15.25.874 3.973 4.507 3.591 11.428-.856 15.453-4.45 4.03-4.836 10.947-.863 15.453 3.976 4.51 10.803 4.9 15.254.87-8.901 8.058-22.557 7.279-30.505-1.738'
              />
              <path
                fill='#F8AA7E'
                d='M31.432 31.551c.27.821.18 1.674-.183 2.388-.362.716-.998 1.292-1.821 1.561-1.647.538-3.42-.357-3.96-2l-.03-.097c-.101-.346-.14-.695-.123-1.037l.01-.137c.038-.392.15-.77.326-1.117.362-.715.998-1.291 1.822-1.56.678-.222 1.376-.202 2.001.016.007.001.014.004.022.008.127.044.252.097.372.158.347.174.662.414.925.707.198.223.367.476.499.757.038.083.075.169.106.257l.034.096'
              />
              <path
                fill='#A96648'
                d='M53.72 40.868v10.626c0 2.677-2.176 4.848-4.86 4.848S44 54.172 44 51.494V40.868h9.72z'
              />
              <path
                fill='#C37D5B'
                d='M24.746 84.23L20 50.37 26.473 49l4.747 33.859c.308 2.197-.892 4.285-2.68 4.664-1.787.378-3.486-1.096-3.794-3.293z'
              />
              <ellipse
                cx='21.403'
                cy='44.721'
                fill='#C37D5B'
                fillRule='nonzero'
                rx='6.655'
                ry='6.637'
              />
              <path
                fill='#C37D5B'
                d='M70.06 106.082l9.527-53.703-6.731-1.37-9.527 53.702c-.32 2.197.927 4.285 2.786 4.663 1.859.379 3.625-1.095 3.945-3.292z'
                transform='matrix(1 0 0 -1 0 160.442)'
              />
              <g fillRule='nonzero'>
                <path
                  fill='#333'
                  d='M.84 8.646L8.694.56C9.078.163 9.711.154 10.107.539l.012.011L23.4 13.872c.386.387.39 1.012.009 1.403l-7.978 8.212c-.385.396-1.018.405-1.414.02l-.018-.018L.843 10.042C.464 9.654.463 9.035.841 8.646z'
                  transform='rotate(180 18.856 24.996)'
                />
                <path
                  fill='#FFF'
                  d='M2.407 9.177l6.691-6.64c.39-.386 1.02-.386 1.409 0l11.307 11.22c.392.39.395 1.023.006 1.415l-.006.005-1.137 1.129c-.49-.272-1.1-.187-1.498.207l-2.802 2.781c-.377.374-.428.966-.121 1.398l-1.244 1.234c-.392.39-1.025.387-1.414-.005l-.001-.002L2.4 10.59c-.388-.392-.385-1.024.007-1.413z'
                  transform='rotate(180 18.856 24.996)'
                />
              </g>
              <path
                fill='#C37D5B'
                d='M66.26 20.24c.27.82.182 1.673-.182 2.387-.362.716-.998 1.292-1.821 1.561-1.648.538-3.42-.357-3.96-2l-.029-.096c-.103-.347-.14-.696-.124-1.038.002-.047.006-.092.01-.137.038-.392.15-.77.326-1.117.362-.715.998-1.291 1.822-1.56.678-.222 1.376-.202 2 .016.008.001.015.004.023.008.127.044.251.097.371.158.348.174.663.414.925.707.199.223.368.476.5.757.038.083.074.169.106.257l.034.096'
              />
              <path
                fill='#C37D5B'
                d='M63.476 20.168c1.575 4.796 1.056 9.774-1.066 13.946-2.115 4.176-5.83 7.54-10.637 9.112-9.616 3.145-19.963-2.082-23.114-11.674-.062-.19-.117-.375-.174-.565-.597-2.023-.82-4.065-.721-6.06.013-.27.033-.537.06-.8.217-2.289.87-4.499 1.9-6.522 2.116-4.176 5.83-7.54 10.638-9.111 3.958-1.294 8.034-1.173 11.682.094.043.009.083.024.13.048.741.256 1.467.567 2.168.92 2.03 1.022 3.868 2.418 5.4 4.135 1.16 1.3 2.15 2.776 2.914 4.415.228.485.438.988.625 1.504.067.187.133.369.195.558'
                transform='rotate(3 46.067 25.86)'
              />
              <path
                fill='#2B4F83'
                d='M40.848 32.7c-.629.128-1.255-.223-1.4-.784l-.419-1.642c-.143-.562.25-1.12.88-1.248.63-.127 1.255.223 1.4.785l.419 1.642c.143.561-.251 1.12-.88 1.247'
              />
              <circle
                cx='39.5'
                cy='30.5'
                r='1'
                fill='#FFF'
                fillRule='nonzero'
              />
              <path
                fill='#2B4F83'
                d='M52.848 29.7c-.629.128-1.255-.223-1.4-.784l-.419-1.642c-.143-.562.25-1.12.88-1.248.63-.127 1.255.223 1.4.785l.419 1.642c.143.561-.251 1.12-.88 1.247'
              />
              <circle
                cx='51.5'
                cy='27.5'
                r='1'
                fill='#FFF'
                fillRule='nonzero'
              />
              <path
                fill='#4D4D4D'
                d='M57.63 9.596c-.22.851-.595 1.67-1.115 2.45-3.13 4.746-11.367 8.124-21.037 8.124-3.787 0-7.355-.515-10.478-1.437.113-.303.232-.6.357-.89 1.07-2.527 2.591-4.803 4.463-6.725 3.849-3.972 9.17-6.422 15.047-6.422 4.838 0 9.295 1.656 12.862 4.462-.03.148-.062.296-.1.438z'
              />
              <path
                fill='#4D4D4D'
                d='M54 11.857c0-.634.088-1.262.262-1.876.254-.91.69-1.788 1.294-2.622.08-.125.167-.242.262-.359.843.606 1.643 1.276 2.391 1.987 2.174 2.056 3.94 4.492 5.183 7.197.938 2.05 1.585 4.244 1.876 6.548C58.464 20.36 54 16.37 54 11.857z'
              />
              <path
                fill='#4D4D4D'
                d='M23 19.857c0-.634.088-1.262.262-1.876.254-.91.69-1.788 1.294-2.622.08-.125.167-.242.262-.359.843.606 1.643 1.276 2.391 1.987 2.174 2.056 3.94 4.492 5.183 7.197.938 2.05 1.585 4.244 1.876 6.548C27.464 28.36 23 24.37 23 19.857z'
                transform='scale(-1 1) rotate(32 0 -76.992)'
              />
              <ellipse
                cx='76.512'
                cy='107.938'
                fill='#C37D5B'
                fillRule='nonzero'
                rx='5.467'
                ry='5.454'
              />
              <ellipse
                cx='72.123'
                cy='111.632'
                fill='#C37D5B'
                fillRule='nonzero'
                rx='2.338'
                ry='2.332'
              />
              <path
                fill='#FEFEFE'
                d='M0 35.859L11.137 35.859M0 38.485L9.517 38.485M0 41.111L7.29 41.111'
              />
              <path
                fill='#C37D5B'
                fillRule='nonzero'
                d='M38 121L45 121 45 152 38 152zM54 121L61 121 61 152 54 152z'
              />
              <path
                fill='#4D4D4D'
                fillRule='nonzero'
                d='M31 152h14v7H24c0-3.866 3.134-7 7-7z'
              />
              <path
                fill='#4D4D4D'
                fillRule='nonzero'
                d='M61 152h14v7H54c0-3.866 3.134-7 7-7z'
                transform='matrix(-1 0 0 1 129 0)'
              />
            </g>
            <path
              fillRule='nonzero'
              stroke='#333'
              d='M80 26c0 .916.746 1.073 2.237.472M68 29c0 .916.746 1.073 2.237.472'
              transform='translate(-719 -272) translate(597 272) translate(122.5)'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
