import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...rest}
  >
    <defs>
      <path
        id='prk6z3hf1a'
        d='M20 18c1.1 0 1.99-.9 1.99-2L22 6c0-1.11-.9-2-2-2H4c-1.11 0-2 .89-2 2v10c0 1.1.89 2 2 2H0v2h24v-2h-4zm-7-3.53v-2.19c-2.78 0-4.61.85-6 2.72.56-2.67 2.11-5.33 6-5.87V7l4 3.73-4 3.74z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0L24 0 24 24 0 24z' />
      <mask id='wihloqps5b' fill='#fff'>
        <use xlinkHref='#prk6z3hf1a' />
      </mask>
      <use fill={color ?? '#333'} fillRule='nonzero' xlinkHref='#prk6z3hf1a' />
      <g fill={color ?? '#333'} mask='url(#wihloqps5b)'>
        <path d='M0 0H24V24H0z' />
      </g>
    </g>
  </svg>
);
