import React from 'react';

import { InfoBox, Button, colorValues } from '@betterpt/better-components';

import { IcCopyPaste } from '@betterpt/better-icons';

const CopyTimesToAll = ({
  onClick,
  hoursTypeText,
}: {
  onClick: () => void;
  hoursTypeText: string;
}) => {
  return (
    <InfoBox
      height={110}
      width={230}
      openWith='hover'
      position='left'
      fixedPlacement
      mainStyle={{ top: 0, right: 30, zIndex: 10 }}
      icon={
        <Button
          style={{ minWidth: 0, width: 32, height: 28, padding: 0 }}
          onClick={onClick}
          color='transparent'
        >
          <IcCopyPaste color={colorValues.frenchblue} />
        </Button>
      }
    >
      <h4>COPY TIMES TO ALL</h4>
      <p>
        Click here to copy times for this day to all days of the week for your{' '}
        {hoursTypeText} hours.
      </p>
    </InfoBox>
  );
};

export default CopyTimesToAll;
