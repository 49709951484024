import * as React from 'react';

//types
import { VideoAppointmentFiltersContextType } from '../../../context/videoAppointmentFilters';
import { InPersonAppointmentFiltersContextType } from '../../../context/inPersonAppointmentFilters';
import { CreatedTimeDisplayValue } from '../../../helpers/telehealthAppointmentsFilterHelper';

// helpers
import {
  format,
  subDays,
  parse,
} from 'date-fns';

// components
import { Filter } from '@betterpt/better-components';
import DatePickerDialog from './DatePickerDialog';

//styles
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './DateFilter.css';

type Props = {
  filters:
    | VideoAppointmentFiltersContextType
    | InPersonAppointmentFiltersContextType;
  primaryColor?: string;
  isTelehealth: boolean;
};

const CreatedDateFilter: React.FC<React.PropsWithChildren<Props>> = ({
  filters,
  primaryColor,
  isTelehealth,
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState<boolean>(
    false
  );

  const formatStartEndDate = (dateString: string) =>
    format(parse(dateString, 'yyyy-MM-dd', new Date()), 'iii M/d');

  const handleRadioSelect = (
    start: Date,
    end: Date,
    display: CreatedTimeDisplayValue
  ) => {
    filters.createdAtStart.update(format(start, 'yyyy-MM-dd'));
    filters.createdAtEnd.update(format(end, 'yyyy-MM-dd'));
    filters.createdTimeDisplayValue.update(display);
  };

  const dateFilterOptions = [
    {
      label: 'Today',
      fullTitle: `all requests ${filters.createdTimeDisplayValue.value} (${formatStartEndDate(
        filters.createdAtStart.value
      )})`,
      selected: filters.createdTimeDisplayValue.value === CreatedTimeDisplayValue.Today,
      callback: () =>
        handleRadioSelect(new Date(), new Date(), CreatedTimeDisplayValue.Today),
    },
    {
      label: 'Last 7 days',
      fullTitle: `all requests ${filters.createdTimeDisplayValue.value}`,
      selected: filters.createdTimeDisplayValue.value === CreatedTimeDisplayValue.Week,
      callback: () =>
        handleRadioSelect(
          subDays(new Date(),7),
          new Date(),
          CreatedTimeDisplayValue.Week
        ),
    },
    {
      label: 'Last 30 days',
      selected: filters.createdTimeDisplayValue.value === CreatedTimeDisplayValue.Month,
      fullTitle: `all requests ${filters.createdTimeDisplayValue.value}`,
      callback: () =>
        handleRadioSelect(
          subDays(new Date(),30),
          new Date(),
          CreatedTimeDisplayValue.Month
        ),
    },
    {
      label: 'Last 365 days',
      selected: filters.createdTimeDisplayValue.value === CreatedTimeDisplayValue.Year,
      fullTitle: `all requests ${filters.createdTimeDisplayValue.value}`,
      callback: () =>
        handleRadioSelect(
          subDays(new Date(),365),
          new Date(),
          CreatedTimeDisplayValue.Year
        ),
    },
    {
      label: 'Choose date range',
      fullTitle: `all requests ${formatStartEndDate(
        filters.createdAtStart.value
      )} - ${formatStartEndDate(filters.createdAtEnd.value)}`,
      selected: filters.createdTimeDisplayValue.value === CreatedTimeDisplayValue.Range,
      callback: () => {
        setIsDatePickerOpen(true);
        filters.createdTimeDisplayValue.update(CreatedTimeDisplayValue.Range);
      },
    },
  ];

  const wideLabel =
    filters.createdTimeDisplayValue.value === CreatedTimeDisplayValue.Range ||
    filters.createdTimeDisplayValue.value === CreatedTimeDisplayValue.Today;

  return (
    <>
      <DatePickerDialog
        isDatePickerOpen={isDatePickerOpen}
        setIsDatePickerOpen={setIsDatePickerOpen}
        telehealth={isTelehealth}
        isCreatedDateFilter={true}
      />
      <Filter
        type='radio'
        name='time frame'
        options={dateFilterOptions}
        overrideButtonColor={primaryColor}
        style={{
          box: { left: 15, top: 65 },
          arrow: { left: 55 },
          labelPadding: wideLabel ? '0 10px 0 20px' : undefined,
          buttonMargin: '12px 2px 0 0',
        }}
      />
    </>
  );
};

export default CreatedDateFilter;