/* Refactored 3/22/2021 */

import * as React from 'react';

// hooks
import { useHistory } from 'react-router-dom';
import { useMeQuery } from '../../../generated/graphql';
import useProfileInfoOperations from './hooks/useProfileInfoOperations';
import useSnackbar from '../../../hooks/useSnackbar';

// components
import { Animation } from '@betterpt/better-components';
import OnboardingWrapper from '../OnboardingWrapper';
import OnboardingProfileForm from './components/OnboardingProfileForm';
import OnboardingProfileImageForm from './components/OnboardingProfileImageForm';

// styles
import { Loader } from '../../Home/Home.style';
import { ProfileFormContainer } from './ProfileInfo.style';

const ProfileInfo = () => {
  const snackbar = useSnackbar();
  const history = useHistory();
  const meQuery = useMeQuery({
    onError: () => snackbar?.useGenericErrorMessage(),
  });
  const [saving, updateSaving] = React.useState(false);

  const {
    commands: { updateProfileInfo, uploadProfileImage },
  } = useProfileInfoOperations();

  if (meQuery?.loading) {
    return (
      <Loader>
        <Animation type='providerAppLoader' />
      </Loader>
    );
  }

  return (
    <OnboardingWrapper
      form='profileOnboardingForm'
      saving={saving}
      goToPreviousStep={() => history.push('/onboarding/company')}
    >
      <ProfileFormContainer>
        <OnboardingProfileImageForm uploadProfileImage={uploadProfileImage} />
        <OnboardingProfileForm
          updateSaving={updateSaving}
          updateProfileInfo={updateProfileInfo}
        />
      </ProfileFormContainer>
    </OnboardingWrapper>
  );
};

export default ProfileInfo;
