import * as React from 'react';

// types
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Clinic, Employee } from 'apps/provider-app/src/generated/graphql';

// helpers
import DateFnsUtils from '@date-io/date-fns';
import { createTheme } from '@material-ui/core';
import { setMinutes, getMinutes, isValid } from 'date-fns';
import { colorValues, bestTextColor } from '@betterpt/better-components';
import { normalizePhone } from 'apps/provider-app/src/helpers';

// hooks
import useRemoteConfigAliases from 'apps/provider-app/src/hooks/useRemoteConfigAliases';
import {
  useMeQuery,
  useEmployeeQuery,
  useFacilityEmployeesQuery,
  useFacilityQuery,
  useInPersonAppointmentDetailsQuery,
  useVideoAppointmentDetailsQuery,
  Role,
} from 'apps/provider-app/src/generated/graphql';

// components
import { Checkbox, Button, CloseButton } from '@betterpt/better-components';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import AutocompleteDropdown from '../AutocompleteDropdown';

//assets
import { IcDropDownSelected } from '@betterpt/better-icons';

//styles
import { buttonStyle, DialogBody, H1, Row } from './ConfirmOrReschedulePage.style';
import { addIndefiniteArticle } from 'apps/provider-app/src/helpers';

type Props = {
  handleRescheduleSubmit: () => void;
  closeDialog: () => void;
  saving: boolean;
  date: {
    value?: MaterialUiPickersDate;
    updateValue: (date: MaterialUiPickersDate) => void;
  };
  time: {
    value?: MaterialUiPickersDate;
    updateValue: (time: MaterialUiPickersDate) => void;
  };
  employee?: {
    value: string;
    updateValue: (id: string) => void;
  };
  clinic?: {
    value: {
      id?: string | undefined | null;
      clinicName?: string | undefined | null;
    };
    updateValue: (id: string) => void;
  };
  telehealth?: boolean;
  appointmentId?: string;
  isRequest?: boolean;
};

const ReschedulePage = ({
  handleRescheduleSubmit,
  closeDialog,
  saving,
  date,
  time,
  employee,
  clinic,
  telehealth,
  appointmentId,
  isRequest,
}: Props) => {
  const placeholderEmployee: Partial<Employee> = {
    id: '0',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    hasValidatedMobileNumber: false,
    role: Role.Self,
    hasLoggedIn: true,
    isTherapist: true,
    clinics: [],
  }; //NOTE: this placeholder is used to prevent the Autocomplete
  //controlled/uncontrolled error when no employee is selected yet

  const placeHolderFacility: any = {
    clinicName: '',
    displayName: '',
    id: '',
    isTelehealthActive: false,
    location: {
      address: '',
      city: '',
      id: '',
      state: '',
    },
    timeZone: '',
  }; //NOTE: this placeholder is used to prevent the Autocomplete
  //controlled/uncontrolled error when no facility is selected yet

  const { primaryColor, employeeSingularAlias, facilitiesSingularAlias } = useRemoteConfigAliases();
  const meQuery = useMeQuery();
  const isBasic = meQuery.data?.me?.role === Role.Self;

  const [confirmContact, updateConfirmContact] = React.useState<boolean>(false);
  const [dateError, updateDateError] = React.useState<React.ReactNode | undefined>(undefined);
  const [timeError, updateTimeError] = React.useState<React.ReactNode | undefined>(undefined);
  const [selectedEmployee, setSelectedEmployee] = React.useState<Partial<Employee>>(placeholderEmployee);
  const [selectedFacility, setSelectedFacility] = React.useState<Partial<Clinic>>(placeHolderFacility);

  const theme = createTheme({
    palette: {
      primary: {
        main: primaryColor,
        contrastText: bestTextColor(primaryColor),
      },
    },
  });

  const inPersonAppointmentQuery = useInPersonAppointmentDetailsQuery({
    variables: { id: appointmentId! },
    skip: !appointmentId || telehealth,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const telehealthAppointmentQuery = useVideoAppointmentDetailsQuery({
    variables: { id: appointmentId! },
    skip: !appointmentId || !telehealth,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const facilityId = telehealth
    ? telehealthAppointmentQuery.data?.videoAppointment?.clinic?.id
    : inPersonAppointmentQuery.data?.appointment?.clinic?.id;

  const queryEmployees = useFacilityEmployeesQuery({
    variables: {
      id: facilityId as string,
    },
    fetchPolicy: 'cache-and-network',
    skip: !facilityId,
  });

  const employeeDetails = useEmployeeQuery({
    variables: {
      id: employee?.value as string,
    },
    skip: !employee?.value,
  });

  const facilityDetails = useFacilityQuery({
    variables: {
      id: clinic?.value?.id as string,
    },
    skip: !clinic?.value,
  });

  const handleTimeChange = (selectedTime: MaterialUiPickersDate) => {
    if (selectedTime) {
      time.updateValue(setMinutes(selectedTime, Math.ceil(getMinutes(selectedTime) / 5) * 5));
      if (isValid(selectedTime)) {
        updateTimeError(undefined);
      }
    }
  };

  const employees: any[] = isBasic ? [meQuery.data?.me] : queryEmployees.data?.clinic?.employees ?? [];
  const providers = employees.filter((staff) => staff?.isTherapist);
  const clinics = employeeDetails.data?.employee?.clinics ?? [];

  React.useEffect(() => {
    const existingPrimary = employeeDetails.data?.employee;

    if (providers.length && existingPrimary) {
      setSelectedEmployee(existingPrimary as Employee);
    }
  }, [employeeDetails.data, providers]);

  React.useEffect(() => {
    const existingClinic = facilityDetails.data?.clinic;
    if (existingClinic) {
      setSelectedFacility(existingClinic as Clinic);
    }
  }, [facilityDetails.data, clinics]);

  const handleEmployeeSelect = (value) => {
    if (!value) {
      employee?.updateValue('');
    } else {
      setSelectedEmployee(value);
      employee?.updateValue(value.id);
    }
  };

  const handleClinicSelect = (value) => {
    if (!value) {
      clinic?.updateValue('');
    } else {
      setSelectedFacility(value);
      clinic?.updateValue(value.id);
    }
  };

  const employeePickerPlaceholder = `Type to search for ${employeeSingularAlias.toLowerCase()}`;

  const facilityPickerPlaceholder = `Type to search for a ${facilitiesSingularAlias.toLowerCase()}`;

  const renderEmployeeOption = (employee) => {
    if (employee?.id === '0') {
      if (providers.length === 0) {
        return `You need at least one ${employeeSingularAlias.toLowerCase()} at your ${facilitiesSingularAlias.toLowerCase()} to continue`;
      } else {
        return `Select ${addIndefiniteArticle(employeeSingularAlias.toLowerCase())}...`;
      }
    }
    return `${employee?.firstName} ${employee?.lastName} (
          ${employee?.email}
          ${employee?.phone ? `, ${normalizePhone(employee?.phone)}` : ''}
          )`;
  };

  const renderFacilityOption = (clinic) => {
    if (clinics?.length === 0) {
      return `You need at least one clinic associated with this provider to continue`;
    } else if (clinics?.length === 1) {
      return `Select ${addIndefiniteArticle('this clinic')}`;
    } else {
      return `${clinic.clinicName}`;
    }
  };

  return (
    <DialogBody>
      <H1>Change Time or Location and Confirm</H1>
      <p className="Body" style={{ marginBottom: '24px' }}>
        Please add the new time or location and the email of the {employeeSingularAlias.toLowerCase()} who will be attending this session.
      </p>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={theme}>
          <Row style={{ justifyContent: 'space-between' }}>
            <KeyboardDatePicker
              disablePast
              autoOk
              style={{ width: '232px' }}
              placeholder="Enter date here"
              label="Appointment Date"
              InputLabelProps={{ shrink: true }}
              format="MM/dd/yyyy"
              mask="__/__/____"
              id="rescheduleDate"
              value={date.value}
              onChange={(newDate) => {
                date.updateValue(newDate);
                if (isValid(newDate) && !!dateError) {
                  updateDateError(undefined);
                }
              }}
              error={!!dateError}
              helperText={dateError}
              variant="inline"
              keyboardIcon={<IcDropDownSelected color={colorValues.betterptblack} />}
              onError={(error) => {
                if (error !== dateError) {
                  updateDateError(error);
                }
              }}
            />
            <KeyboardTimePicker
              autoOk
              style={{ width: '232px' }}
              label="Appointment Time"
              format="hh:mm a"
              invalidDateMessage="Invalid: must be 12hr AM/PM format"
              placeholder="Enter time here"
              id="rescheduleTime"
              minutesStep={5}
              InputLabelProps={{ shrink: true }}
              value={time.value}
              onChange={handleTimeChange}
              error={!!timeError}
              helperText={timeError}
              variant="inline"
              keyboardIcon={<IcDropDownSelected color={colorValues.betterptblack} />}
              onError={(error) => {
                if (error !== timeError) {
                  updateTimeError(error);
                }
              }}
            />
          </Row>
          {employee && !isBasic && isRequest && (
            <AutocompleteDropdown
              isLoading={queryEmployees.loading || employeeDetails.loading}
              label="Employees"
              placeholderOption={placeholderEmployee}
              options={providers}
              onOptionSelect={handleEmployeeSelect}
              onInputChange={handleEmployeeSelect}
              getOptionSelected={(option: any, value) => option.id === value.id}
              getOptionLabel={(option: any) => `${option?.firstName} ${option?.lastName}`}
              selectedOption={selectedEmployee}
              placeholder={employeePickerPlaceholder}
              renderOption={renderEmployeeOption}
            />
          )}
          {employee && !isBasic && (
            <AutocompleteDropdown
              isLoading={facilityDetails.loading}
              label="Facilities"
              placeholderOption={placeHolderFacility}
              options={clinics}
              onOptionSelect={handleClinicSelect}
              onInputChange={handleClinicSelect}
              getOptionSelected={(option: any, value) => option.id === value.id}
              getOptionLabel={(option: any) => option.clinicName}
              selectedOption={selectedFacility}
              placeholder={facilityPickerPlaceholder}
              renderOption={renderFacilityOption}
            />
          )}
        </ThemeProvider>
      </MuiPickersUtilsProvider>
      <div className="Body" style={{ display: 'flex' }}>
        <Checkbox
          checked={confirmContact}
          onChange={() => updateConfirmContact(!confirmContact)}
          style={{ padding: '0', alignSelf: 'flex-start' }}
        />
        By checking this box{`${telehealth ? ' & Send Telehealth Link' : ''},`} you acknowledge that you have contacted all parties involved
        and that they{' '}
        {telehealth
          ? 'can make it to the telehealth appointment at the following time:'
          : 'agree to the appointment at the time listed below.'}
      </div>
      <Button
        fullWidth
        size="large"
        color="transparent"
        style={buttonStyle(primaryColor)}
        onClick={handleRescheduleSubmit}
        disabled={
          !date.value || !time.value || !!timeError || !!dateError || !confirmContact || (isRequest && employee && !employee?.value)
        }
        loading={saving}
      >
        CONFIRM {telehealth ? 'AND SEND TELEHEALTH LINK' : 'NEW APPOINTMENT TIME'}
      </Button>
      <Button fullWidth size="large" color="transparent" style={buttonStyle(colorValues.emptiness)} onClick={closeDialog}>
        CANCEL
      </Button>
      <CloseButton onClose={closeDialog} />
    </DialogBody>
  );
};

export default ReschedulePage;
