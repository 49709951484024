import React from 'react';
import {
  TextField as MTextField,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

// styles
import { buildInputStyle } from './styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

type Props = {
  overrideStyle?: CSSProperties;
};
const useStyles = (overrideStyle: CSSProperties, hasStartAdornment: boolean) =>
  makeStyles(() =>
    createStyles({
      root: buildInputStyle(overrideStyle, hasStartAdornment),
    }),
  );

/**
 * TextField Component
 *
 * @param {(Props & TextFieldProps)} { overrideStyle = {}, ...rest }
 * @returns
 */
const TextField = ({ overrideStyle = {}, ...rest }: Props & TextFieldProps) => {
  const hasStartAdornment =
    !!rest.InputProps?.startAdornment || !!rest.SelectProps?.startAdornment;
  const classes = useStyles(overrideStyle, hasStartAdornment)();

  return (
    <Grid
      container
      spacing={rest.InputProps?.startAdornment ? 2 : 0}
      alignItems='flex-end'
      className={classes.root}
      style={{ width: rest.fullWidth ? '100%' : '', ...rest.style }}
    >
      {hasStartAdornment && (
        <Grid item>
          {rest.InputProps?.startAdornment || rest.SelectProps?.startAdornment}
        </Grid>
      )}
      <Grid item>
        <MTextField
          {...rest}
          style={{}}
          InputProps={{ ...rest.InputProps, startAdornment: null }}
          SelectProps={{ ...rest.SelectProps, startAdornment: null }}
        />
      </Grid>
    </Grid>
  );
};

export default TextField;
