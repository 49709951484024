import * as React from 'react';

//helpers
import { determineVariant } from '../AuthWrapper/loginVariantConfig';

//hooks
import { useHistory, useParams } from 'react-router-dom';
import useAuthentication from '../../../hooks/useAuthentication';
import useEmailState from '../hooks/useEmailState';

//components
import { Button } from '@betterpt/better-components';
import { Link } from 'react-router-dom';
import AuthWrapper from '../AuthWrapper';
import TextField from '../../Shared/ThemedTextField';

//style
import { loginButtonStyle } from '../shared.style';
import useQueryParams from '../hooks/useQueryParams';

const ForgotPassword = () => {
  const { variant } = useParams<{ variant: string }>();
  const { push } = useHistory();
  const email = useEmailState();
  const auth = useAuthentication();
  const queryParams = useQueryParams();

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    const forgotPasswordSecondStepLink = variant
      ? `/forgot-password-submit/${variant}/${encodeURIComponent(
          email.value.replace('.', '$dot$')
        )}`
      : `/forgot-password-submit/${encodeURIComponent(
          email.value.replace('.', '$dot$')
        )}?` + queryParams;
    e.preventDefault();
    auth?.forgotPassword(email.value.trim()).then(() => {
      push(forgotPasswordSecondStepLink);
    });
  };

  const primaryColor = determineVariant(variant).color;
  const isButtonDisabled = !!email.error || auth?.isLoading;
  const loginLink = variant ? `/login/${variant}` : '/login?' + queryParams;
  return (
    <AuthWrapper>
      <form onSubmit={handleSubmit}>
        <h1 className='H2'>Forgot Password</h1>
        <p>Please enter your email below</p>
        <TextField
          onChange={(e) => email.updateValue(e.currentTarget.value)}
          label='Your email'
          placeholder='Enter your email here'
          onBlur={email.handleBlur}
          error={!!email.error}
          helperText={email.error}
        />
        <Link to={loginLink} className='forgot-password'>
          <p className='Body button-or-link'>Back to login</p>
        </Link>
        <Button
          loading={auth?.isLoading}
          type='submit'
          fullWidth
          size='large'
          style={loginButtonStyle(primaryColor)}
          disabled={isButtonDisabled}
        >
          Submit
        </Button>
      </form>
    </AuthWrapper>
  );
};

export default ForgotPassword;
