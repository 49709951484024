import * as React from 'react';

// types
import { AppointmentMedium, Role, CompanyListOptionType } from '../../../../../generated/graphql';

// hooks
import { useHistory } from 'react-router-dom';
import { useMeQuery } from '../../../../../generated/graphql';
import useFeatureAccessCheck from '../../../../../hooks/useFeatureAccessCheck';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';
import useCreateInPersonAppointmentFormHandlers from './hooks/useCreateInPersonAppointmentFormHandlers';

// components
import { Animation, Button, Card, CloseButton } from '@betterpt/better-components';
import { Dialog } from '@material-ui/core';
import AppointmentTypePicker from '../../Shared/AppointmentTypePicker';
import EmployeePicker from '../../Shared/EmployeePicker';
import DateTimePickers from '../../Shared/DateTimePickers';
import PatientPicker from '../../Shared/PatientPicker';
import FacilityPicker from '../../Shared/FacilityPicker';
import BlockedPageMessage from '../../../../Shared/BlockedPageMessage';

// assets
import { IcArrowLeft, ImgSuitLadyComputer, ImgBrunetteGirlComputer } from '@betterpt/better-icons';

// styles
import * as styles from './CreateInPersonAppointment.style';
import { bestTextColor, colorValues } from '@betterpt/better-components';

type Props = {
  existingAppointment?: any;
  onDone?: () => void; // call done when rescheduling or creating appointment for existing patient
  onCancelClick?: () => void;
  lockPatient?: boolean;
  lockEmployee?: boolean;
  isInModal?: boolean;
};

const CreateInPersonAppointment = ({ existingAppointment, onDone, onCancelClick, lockPatient, lockEmployee, isInModal }: Props) => {
  const history = useHistory();
  const companyFeatures = useFeatureAccessCheck();
  const { loading: remoteConfigLoading, patientsSingularAlias, primaryColor } = useRemoteConfigAliases();

  const meQuery = useMeQuery();

  const [usePatientSecondary, updateUsePatientSecondary] = React.useState(false);

  const form = useCreateInPersonAppointmentFormHandlers({
    existingAppointment,
  });

  // Scroll to top of page on mount
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGoBack = () => {
    history.goBack();
  };

  const renderForm = () => (
    <>
      <styles.CardHeader>
        {!existingAppointment && (
          <styles.BackButton onClick={onCancelClick}>
            <IcArrowLeft style={{ height: 25, width: 24 }} />
          </styles.BackButton>
        )}
        <Button
          type="submit"
          size="large"
          style={{
            backgroundColor: primaryColor,
          }}
          form="appointment-form"
          loading={form.saving}
          disabled={!form.isValid || form.saving}
          data-cy="create-appointment-button"
        >
          SCHEDULE APPOINTMENT
        </Button>
        {existingAppointment && <CloseButton onClose={() => onCancelClick?.()} />}
      </styles.CardHeader>
      <styles.CardBody>
        <DateTimePickers form={form} />
        <FacilityPicker facilityType={CompanyListOptionType.InPersonFacilities} form={form} disabled={lockPatient} />
        <EmployeePicker form={form} facilityId={form.facilityId.value} lockEmployee={lockEmployee} />
        {meQuery.data?.me?.company?.providerAppId?.toLowerCase() !== 'acorn' && (
          <AppointmentTypePicker form={form} employeeId={form.employeeId.value} medium={AppointmentMedium.InClinic} />
        )}
        <PatientPicker
          form={form}
          lockPatient={lockPatient}
          usePatientSecondary={usePatientSecondary}
          updateUsePatientSecondary={updateUsePatientSecondary}
        />
      </styles.CardBody>
      <styles.CardFooter>
        <p className="Form">
          Please note that by clicking “Schedule”, you acknowledge that you have contacted this {patientsSingularAlias.toLowerCase()} and
          that they agree to take part in the appointment above. Patient consent form to be sent as part of this email.
        </p>
      </styles.CardFooter>
    </>
  );

  const generateBlockPageCopy = () => {
    let icon: any;
    let title = '';
    let message = '';
    let buttonText = '';
    let pathname = '';
    const hideButtonText = true;
    if (companyFeatures.isNotWhiteLabel) {
      icon = <ImgSuitLadyComputer />;
      message = `Contact support@betterhealthcare.co to start adding more facilities. You will then be able to add staff and appointments, and gain access to analytics and more!`;
      buttonText = 'THANK YOU';
      title = 'Your company needs a subscription for in-person appointments';
    } else {
      icon = <ImgBrunetteGirlComputer />;
      title = 'We are hard at work on in-person scheduling!';
      message = `In-person scheduling is only available for our physical therapy customers right now. However, rest assured that we are hard at work building something that works for everyone :)`;
      buttonText = 'THANK YOU';
    }

    return {
      icon,
      title,
      message,
      hideButtonText,
      pathname,
    };
  };

  const renderBody = () => {
    if (companyFeatures.isNotWhiteLabel && companyFeatures.hasSubscription) {
      return renderForm();
    } else {
      const blockPageCopy = generateBlockPageCopy();
      return (
        <div style={{ position: 'relative' }}>
          {!isInModal && (
            <Button
              size="small"
              color="transparent"
              onClick={() => history.goBack()}
              style={{
                height: 36,
                position: 'absolute',
                top: 10,
                left: 10,
              }}
            >
              <IcArrowLeft color={colorValues.betterptblack} />
              GO BACK
            </Button>
          )}
          <div
            style={{
              height: '80vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BlockedPageMessage
              icon={blockPageCopy.icon}
              title={blockPageCopy.title}
              message={blockPageCopy.message}
              hideButton={true}
              pathname={blockPageCopy.pathname}
            />
          </div>
        </div>
      );
    }
  };

  const loading = companyFeatures.loading || remoteConfigLoading || meQuery.loading;

  if (loading) {
    return <Animation type="providerAppLoader" />;
  }

  return (
    <>
      <Dialog open={meQuery.data?.me?.role === Role.Self && companyFeatures.hasSubscription} onClick={handleGoBack}>
        <styles.DialogBody>
          <styles.DialogTitle>Permissions required</styles.DialogTitle>
          <styles.DialogMessage>
            Please contact a company owner, administrator, or manager to update your permissions to “Scheduler”. This will allow you to
            create in-person appointments.{' '}
          </styles.DialogMessage>
          <Button
            size="large"
            style={{
              backgroundColor: primaryColor,
              color: bestTextColor(primaryColor),
              marginTop: 30,
            }}
            fullWidth
            onClick={handleGoBack}
          >
            THANK YOU
          </Button>
        </styles.DialogBody>
      </Dialog>
      <styles.Form onSubmit={(e: any) => form.handleSubmit(e, onDone)} id="appointment-form" isInModal={isInModal}>
        <Card data-testid="create-appointment-container" style={lockPatient || isInModal ? { width: '100%' } : styles.cardStyle}>
          {renderBody()}
        </Card>
      </styles.Form>
    </>
  );
};

export default CreateInPersonAppointment;
