import * as React from 'react';
import { InPersonAppointmentFiltersContext } from '../context/inPersonAppointmentFilters';

export default function useInPersonAppointmentFilters() {
  const context = React.useContext(InPersonAppointmentFiltersContext);

  if (context === undefined) {
    throw new Error(
      '`useInPersonAppointmentFilters` hook must be used within correct context component'
    );
  }
  return context;
}
