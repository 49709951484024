import * as React from 'react';
import { emailValidation } from '../../../helpers/validations';

const useEmailState = () => {
  const [email, updateEmail] = React.useState('');
  const [error, updateError] = React.useState<string>();
  const [touched, updateTouched] = React.useState(false);

  React.useEffect(() => {
    if (touched && email.length > 0) {
      updateError(emailValidation(email));
    }
  }, [email, touched]);

  const handleBlur = () => {
    if (email.length > 0) {
      updateTouched(true);
    }
  };

  return {
    value: email,
    updateValue: updateEmail,
    error,
    handleBlur,
    touched,
  };
};

export default useEmailState;
