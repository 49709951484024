import React from 'react';

// hooks
import { useParams, Redirect } from 'react-router-dom';
import { useEmployeeQuery, useMeQuery } from '../../../../generated/graphql';
import useFeatureAccessCheck from '../../../../hooks/useFeatureAccessCheck';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../hooks/useSnackbar';

// components
import { Link } from 'react-router-dom';
import { colorValues, HorizontalNavigation } from '@betterpt/better-components';
import AddAppointmentButtonWithDialog from '../../../Shared/AddAppointmentButtonWithDialog';
import EmployeeInfoTab from './EmployeeInfoTab';
import AppointmentConfigTab from './AppointmentConfigTab';
import YextMessage from './YextMessage';
import ErrorPage from '../../../Shared/ErrorPage';

//styles
import { TitleWrapper, TitleSection, TitleBreadCrumb, TitleName } from './styles/EmployeeDetail.style';

const EmployeeDetail = () => {
  const snackbar = useSnackbar();
  const { primaryColor, employeeSingularAlias } = useRemoteConfigAliases();
  const { hasSubscription } = useFeatureAccessCheck();

  const meQuery = useMeQuery();
  const { employeeId } = useParams<{ employeeId: string }>();
  const employeeQuery = useEmployeeQuery({
    variables: { id: employeeId },
    skip: !employeeId,
    fetchPolicy: 'cache-and-network',
  });

  const queryError = meQuery.error || employeeQuery.error;

  React.useEffect(() => {
    if (queryError?.message) {
      snackbar?.useGenericErrorMessage();
    }
  });

  const company = meQuery.data?.me?.company;
  const isNotWhiteLabel = company?.providerAppId !== 'acorn' && company?.providerAppId !== 'somatus';
  const isManagedViaYext = employeeQuery.data?.employee?.isManagedViaYext;
  const employeeName = employeeQuery.loading
    ? ''
    : `${employeeQuery?.data?.employee?.firstName} ${employeeQuery?.data?.employee?.lastName}`;

  const tabs = [
    {
      display: `${employeeSingularAlias} Info`,
      path: `/employees/:employeeId`,
      url: `/employees/${employeeId}`,
      exact: true,
      hideTab: true,
      component: () => <Redirect to={`/employees/${employeeId}/info`} />,
    },
    {
      display: `${employeeSingularAlias} Info`,
      path: `/employees/:employeeId/info`,
      url: `/employees/${employeeId}/info`,
      exact: true,
      component: () => <EmployeeInfoTab />,
    },
    {
      display: 'Appointment Config.',
      path: `/employees/:employeeId/config`,
      url: `/employees/${employeeId}/config`,
      hideTab: !isNotWhiteLabel, //white labels don't have appointment types
      component: () => <AppointmentConfigTab />,
    },
  ];

  const CTASection = [<AddAppointmentButtonWithDialog key="1" showInPersonOption={hasSubscription} color={primaryColor} size="medium" />];

  if (
    !employeeQuery.loading &&
    employeeQuery.called &&
    employeeQuery?.error?.message.includes('You can not view employees from another company')
  ) {
    return <ErrorPage message="You do not have permission to view this page." />;
  }

  if (!employeeQuery.loading && employeeQuery.called && employeeQuery?.error?.message.includes('Employee does not exist')) {
    return <ErrorPage message="This page does not exist" />;
  }

  return (
    <>
      <TitleWrapper isManagedViaYext={isManagedViaYext}>
        <TitleSection isManagedViaYext={isManagedViaYext}>
          <TitleBreadCrumb isManagedViaYext={isManagedViaYext}>
            <Link to="/employees">{employeeSingularAlias} /</Link>
          </TitleBreadCrumb>
          <TitleName isManagedViaYext={isManagedViaYext}>{employeeName}</TitleName>
        </TitleSection>
        {isManagedViaYext && <YextMessage />}
      </TitleWrapper>
      <HorizontalNavigation
        pageBackgroundColor={colorValues.earlysmoke}
        navBackgroundColor={colorValues.emptiness}
        tabs={tabs}
        padding={'0px 10px 0px 50px'}
        buttons={CTASection}
      />
    </>
  );
};

export default EmployeeDetail;
