import * as React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...rest}
  >
    <defs>
      <path
        id='03suzbncva'
        d='M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 14h-3v3h-2v-3H8v-2h3v-3h2v3h3v2zm-3-7V3.5L18.5 9H13z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0L24 0 24 24 0 24z' />
      <mask id='2rkzus4rfb' fill='#fff'>
        <use xlinkHref='#03suzbncva' />
      </mask>
      <use fill={color ?? '#333'} fillRule='nonzero' xlinkHref='#03suzbncva' />
      <g fill={color ?? '#333'} mask='url(#2rkzus4rfb)'>
        <path d='M0 0H24V24H0z' />
      </g>
    </g>
  </svg>
);
