import { colorValues } from '@betterpt/better-components';

export const inputFieldStyle = (color?: string) => ({
  marginBottom: 20,
  width: '266px',
  '& label': {
    color: `${colorValues.betterptblack} !important`,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: color,
  },
  '& .MuiInput-underline:hover': {
    borderBottomColor: color,
  },
  '& label.Mui-focused': {
    color: color,
  },
  '& .MuiInput-underline:hover:before': {
    borderBottom: 'color',
  },
  '& .MuiInput-underline.Mui-disabled:after': {
    borderBottom: `1px solid ${colorValues.concretejungle}`,
  },
  '& .MuiInput-underline.Mui-disabled:before': {
    borderBottom: `1px solid ${colorValues.concretejungle}`,
  },
});
