import React from 'react';
import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

const ErrorContainer = styled.div`
  span {
    color: ${colorValues.messyketchup};
    font-size: 14px;
  }
`;

const CustomOptionsErrorField = (props: any) => {
    const { help, children, error } = props;

    return (
        <>
            {children}
            <ErrorContainer>
                {error &&
                    <span>Please enter a value.</span>}
            </ErrorContainer>
            {help}
        </>
    );
};
export default CustomOptionsErrorField;
