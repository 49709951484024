import { colorValues, HorizontalNavigation } from '@betterpt/better-components';
import TableauEmbed from './TableauEmbed';
import styled from '@emotion/styled';
import Can from '../../Can';
import { Roles } from '../../../helpers';
import { DownloadType, useMeQuery } from '../../../generated/graphql';
import { GridCard } from '../../Shared/GridCard';
import BlockedPageMessage from '../../Shared/BlockedPageMessage';
import { ImgBoyShrug } from '@betterpt/better-icons';
import AnalyticsCSVTab from './AnalyticsCSVTab/AnalyticsCSVTab';
import DownloadAnalyticsCSV from './AnalyticsCSVTab/components/AnalyticsCSV/DownloadAnalyticsCSV';
import { TABLEAU_PATHS } from '../../../constants/tableau-paths';

export const Page = styled.div`
  margin: 20px;
`;

// Renders the Tableau Dashboard
const EmbeddedAnalytics = () => {
  console.log('ENV : ' + window.__RUNTIME_CONFIG__.REACT_APP_NODE_ENV);
  console.log('tableau path : ' + TABLEAU_PATHS[window.__RUNTIME_CONFIG__.REACT_APP_NODE_ENV].channel_attribution);

  const meQuery = useMeQuery();

  const companyUseTableau = meQuery?.data?.me?.company?.isUsingTableau ?? undefined;
  const employeeUseTableau = meQuery?.data?.me?.isUsingTableau ?? undefined;
  const showTabeleauTab = !(companyUseTableau && employeeUseTableau);

  const PermissionRequiredCard = () => (
    <Page>
      <GridCard data-testid="permission-required" fullWidth>
        <BlockedPageMessage
          icon={<ImgBoyShrug />}
          title="Permission Required"
          message={`Sorry, you don't have permission to access this information. Please contact your account owner or administrator to update your permissions.`}
          hideButton={true}
          style={{ height: '80vh', minHeight: 600, width: '100%' }}
        />
      </GridCard>
    </Page>
  );

  // commenting this out in case we want to revert the changes
  // const tabs = !(companyUseTableau && employeeUseTableau)
  //   ? [
  //       {
  //         display: 'CSV Download',
  //         path: '/embedded-analytics/csv-download',
  //         exact: true,
  //         hideTab: false,
  //         component: () => (
  //           <Page>
  //             <Can
  //               role={Roles[meQuery.data?.me?.role ?? 'initial']}
  //               perform="analytics:access"
  //               yes={() => <AnalyticsCSVTab />}
  //               no={() => <PermissionRequiredCard />}
  //             />
  //           </Page>
  //         ),
  //       },
  //       {
  //         display: '',
  //         path: '/embedded-analytics/telehealth-csv',
  //         exact: true,
  //         hideTab: true,
  //         component: () => (
  //           <Page>
  //             <Can
  //               role={Roles[meQuery.data?.me?.role ?? 'initial']}
  //               perform="analytics:access"
  //               yes={() => <DownloadAnalyticsCSV type={DownloadType.Telehealth} />}
  //               no={() => <PermissionRequiredCard />}
  //             />
  //           </Page>
  //         ),
  //       },
  //       {
  //         display: '',
  //         path: '/embedded-analytics/in-person-csv',
  //         exact: true,
  //         hideTab: true,
  //         component: () => (
  //           <Page>
  //             <Can
  //               role={Roles[meQuery.data?.me?.role ?? 'initial']}
  //               perform="analytics:access"
  //               yes={() => <DownloadAnalyticsCSV type={DownloadType.Appointment} />}
  //               no={() => <PermissionRequiredCard />}
  //             />
  //           </Page>
  //         ),
  //       },
  //     ]
  //   : [
  //       {
  //         default: true,
  //         display: 'Channel Attribution',
  //         path: '/embedded-analytics/campaign',
  //         hideTab: false,
  //         exact: true,
  //         component: () => <TableauEmbed dashboardPath={TABLEAU_PATHS[window.__RUNTIME_CONFIG__.REACT_APP_NODE_ENV].channel_attribution} />,
  //       },
  //       {
  //         display: 'Appointment Volume',
  //         path: '/embedded-analytics/appointments-volume',
  //         exact: true,
  //         hideTab: false,
  //         component: () => <TableauEmbed dashboardPath={TABLEAU_PATHS[window.__RUNTIME_CONFIG__.REACT_APP_NODE_ENV].appointment_volume} />,
  //       },
  //       {
  //         display: 'Appointments Modification',
  //         path: '/embedded-analytics/appointments-modification',
  //         exact: true,
  //         hideTab: false,
  //         component: () => (
  //           <TableauEmbed dashboardPath={TABLEAU_PATHS[window.__RUNTIME_CONFIG__.REACT_APP_NODE_ENV].appointment_modification} />
  //         ),
  //       },
  //       {
  //         display: 'CSV Download',
  //         path: '/embedded-analytics/csv-download',
  //         exact: true,
  //         hideTab: false,
  //         component: () => (
  //           <Page>
  //             <AnalyticsCSVTab />
  //           </Page>
  //         ),
  //       },
  //     ];

  const tabs = [
    {
      display: '',
      path: '/embedded-analytics/telehealth-csv',
      exact: true,
      hideTab: true,
      component: () => (
        <Page>
          <Can
            role={Roles[meQuery.data?.me?.role ?? 'initial']}
            perform="analytics:access"
            yes={() => <DownloadAnalyticsCSV type={DownloadType.Telehealth} />}
            no={() => <PermissionRequiredCard />}
          />
        </Page>
      ),
    },
    {
      display: '',
      path: '/embedded-analytics/in-person-csv',
      exact: true,
      hideTab: true,
      component: () => (
        <Page>
          <Can
            role={Roles[meQuery.data?.me?.role ?? 'initial']}
            perform="analytics:access"
            yes={() => <DownloadAnalyticsCSV type={DownloadType.Appointment} />}
            no={() => <PermissionRequiredCard />}
          />
        </Page>
      ),
    },
    {
      default: true,
      display: 'Channel Attribution',
      path: '/embedded-analytics/campaign',
      hideTab: showTabeleauTab,
      exact: true,
      component: () => <TableauEmbed dashboardPath={TABLEAU_PATHS[window.__RUNTIME_CONFIG__.REACT_APP_NODE_ENV].channel_attribution} />,
    },
    {
      display: 'Appointment Volume',
      path: '/embedded-analytics/appointments-volume',
      exact: true,
      hideTab: showTabeleauTab,
      component: () => <TableauEmbed dashboardPath={TABLEAU_PATHS[window.__RUNTIME_CONFIG__.REACT_APP_NODE_ENV].appointment_volume} />,
    },
    {
      display: 'Appointments Modification',
      path: '/embedded-analytics/appointments-modification',
      exact: true,
      hideTab: showTabeleauTab,
      component: () => (
        <TableauEmbed dashboardPath={TABLEAU_PATHS[window.__RUNTIME_CONFIG__.REACT_APP_NODE_ENV].appointment_modification} />
      ),
    },
    {
      display: 'CSV Download',
      path: '/embedded-analytics/csv-download',
      exact: true,
      hideTab: false,
      component: () => (
        <Page>
          <Can
            role={Roles[meQuery.data?.me?.role ?? 'initial']}
            perform="analytics:access"
            yes={() => <AnalyticsCSVTab />}
            no={() => <PermissionRequiredCard />}
          />
        </Page>
      ),
    },
  ];

  return (
    <HorizontalNavigation
      pageBackgroundColor={colorValues.earlysmoke}
      navBackgroundColor={colorValues.emptiness}
      tabs={tabs}
      padding={'50px 20px'}
    />
  );
};

export default EmbeddedAnalytics;
