import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='160'
    height='161'
    viewBox='0 0 160 161'
    {...rest}
  >
    <defs>
      <path
        id='zjjeddxkea'
        d='M57.116 36.927c-2.16 7.738-7.25 13.868-13.742 17.518-6.486 3.66-14.365 4.834-22.104 2.673C5.796 52.794-3.243 36.748 1.08 21.272c.085-.306.177-.6.27-.903 1.138-3.626 2.925-6.876 5.196-9.661 2.312-2.837 5.127-5.186 8.277-6.953C21.309.095 29.188-1.08 36.926 1.08c7.738 2.162 13.867 7.25 17.517 13.743 1.778 3.143 2.969 6.612 3.475 10.236.498 3.56.342 7.266-.565 10.957-.077.306-.151.605-.237.91'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <path
                fill='#FFDF55'
                d='M110.123 133.506h-90v-38.05c0-15.42 14.034-28.405 33.132-32.272 1.296-.272 2.611-.493 3.956-.67 2.572-.338 5.212-.514 7.912-.514 3.403 0 6.706.28 9.89.809 1.346.228 2.661.492 3.957.809 6.853 1.64 12.995 4.478 17.97 8.183 8.15 6.052 13.183 14.42 13.183 23.655v38.05z'
                transform='translate(-731 -228) translate(608.5 228.5) translate(122.5) translate(16 19)'
              />
              <path
                fill='#FFF'
                d='M77.102 63.504v2.25c0 6.144-4.98 11.123-11.123 11.123-6.143 0-11.123-4.98-11.123-11.123V63c1.12-.316 2.259-.573 3.423-.778l15.4.342c1.164.265 2.302.573 3.423.941'
                transform='translate(-731 -228) translate(608.5 228.5) translate(122.5) translate(16 19)'
              />
              <path
                fill='#2A4E82'
                d='M55.712 63.653v2.102c0 5.66 4.605 10.267 10.267 10.267 5.662 0 10.267-4.607 10.267-10.267V64.13c-.847-.262-1.728-.496-2.673-.714l-15.229-.338c-.897.16-1.78.354-2.632.575m10.267 14.08C59.374 77.733 54 72.36 54 65.755v-2.756c0-.383.255-.719.624-.823 1.124-.318 2.303-.586 3.506-.798.056-.01.108-.019.167-.013l15.401.343c.059.001.115.008.172.02 1.25.285 2.394.6 3.499.963.352.115.589.444.589.813v2.25c0 6.605-5.374 11.98-11.979 11.98'
                transform='translate(-731 -228) translate(608.5 228.5) translate(122.5) translate(16 19)'
              />
              <path
                fill='#CB7E5C'
                d='M73.68 50.926v12.835c0 4.252-3.449 7.7-7.701 7.7s-7.7-3.448-7.7-7.7V50.926h15.4z'
                transform='translate(-731 -228) translate(608.5 228.5) translate(122.5) translate(16 19)'
              />
              <path
                fill='#DE8E68'
                d='M49.44 25.15c-.51 1.821-1.706 3.263-3.234 4.122-1.526.862-3.38 1.138-5.201.629-3.642-1.017-5.769-4.792-4.75-8.434.02-.072.04-.141.063-.212.268-.854.688-1.618 1.223-2.273.544-.669 1.206-1.221 1.947-1.637 1.526-.86 3.38-1.137 5.2-.628 1.821.508 3.264 1.705 4.123 3.232.418.74.698 1.557.817 2.409.116.838.08 1.71-.134 2.579-.017.072-.035.142-.054.214M99.44 41.689c-.51 1.82-1.707 3.262-3.234 4.121-1.526.862-3.38 1.137-5.2.63-3.642-1.018-5.77-4.793-4.751-8.435.02-.071.041-.14.063-.212.268-.854.689-1.618 1.223-2.273.544-.668 1.206-1.221 1.947-1.637 1.525-.86 3.38-1.137 5.2-.629 1.821.51 3.264 1.706 4.123 3.234.418.74.698 1.556.817 2.408.117.838.08 1.71-.134 2.579-.017.072-.035.142-.054.214'
                transform='translate(-731 -228) translate(608.5 228.5) translate(122.5) translate(16 19)'
              />
              <g transform='translate(-731 -228) translate(608.5 228.5) translate(122.5) translate(16 19) translate(39.265 1.807)'>
                <mask id='s0sqxv41gb' fill='#fff'>
                  <use xlinkHref='#zjjeddxkea' />
                </mask>
                <use fill='#DE8E68' xlinkHref='#zjjeddxkea' />
                <path
                  fill='#6A564E'
                  fillOpacity='.24'
                  d='M17.838 40.168c-5.397 1.198-4.87 2.498-5.727 5.868-.855 3.371-4.289 2.083-4.289 0s1.555-3.82 1.555-5.007c0-1.186-5.685-12.743-12-13.806-6.316-1.064 9.3 29.048 23.084 33.19 13.785 4.144 34.342-12.255 35.218-14.377.583-1.414-1.301-3.083-5.655-5.007-4.867 3.338-8.592 5.007-11.174 5.007-5.404 0-3.048 6.206-5.055 6.206-5.293 0-.559-7.933-5.305-10.055-4.746-2.121-5.256-3.218-10.652-2.02z'
                  mask='url(#s0sqxv41gb)'
                />
              </g>
              <path
                fill='#6A564E'
                d='M42.415 19.307l3.032 1.501c1.797-5.548 5.219-8.913 10.265-10.096 7.569-1.775 11.172 1.4 13.053 4.998 1.882 3.597 5.657 0 10.444 0 4.788 0 10.985 1.192 12.539 5.098 1.553 3.906 0 6.358 0 9.348 0 2.989 3.107 2.617 1.553 5.367-1.035 1.834-.488 2.75 1.643 2.75 2.214-2.315 3.321-4.593 3.321-6.833 0-3.36 0-19.116-14.383-26.106C69.5-1.656 59.625.457 54 3.264c-3.75 1.871-7.611 6.02-11.585 12.446v3.597zM56.251 32.656c-.91-.254-1.442-1.199-1.188-2.108l.921-3.297c.254-.91 1.199-1.442 2.11-1.188.91.255 1.44 1.198 1.187 2.109l-.92 3.296c-.256.91-1.2 1.442-2.11 1.188'
                transform='translate(-731 -228) translate(608.5 228.5) translate(122.5) translate(16 19)'
              />
              <path
                fill='#6A564E'
                d='M74.38 37.72c-.91-.253-1.442-1.197-1.187-2.107l.92-3.297c.254-.91 1.199-1.442 2.11-1.188.91.254 1.441 1.199 1.187 2.11l-.92 3.295c-.255.91-1.2 1.442-2.11 1.188'
                transform='translate(-731 -228) translate(608.5 228.5) translate(122.5) translate(16 19)'
              />
              <path
                fill='#CB7E5C'
                d='M6.91 133.356c5.686 2.329 12.181-.393 14.51-6.079 1.97-4.809-.334-10.305-5.144-12.276l-3.167-1.296c-4.81-1.97-10.306.332-12.277 5.142-2.327 5.685.394 12.18 6.079 14.509M110.91 133.356c5.686 2.329 12.181-.393 14.51-6.079 1.97-4.809-.334-10.305-5.144-12.276l-3.167-1.296c-4.81-1.97-10.306.332-12.277 5.142-2.327 5.685.394 12.18 6.079 14.509'
                transform='translate(-731 -228) translate(608.5 228.5) translate(122.5) translate(16 19)'
              />
            </g>
            <g>
              <path
                fill='#F9F9FA'
                d='M0 54H111V59H0z'
                transform='translate(-731 -228) translate(608.5 228.5) translate(122.5) translate(24 100)'
              />
              <path
                fill='#294F83'
                d='M1.621 55.557v2.128c.002.351.654.758 1.462.758h105.834c.808 0 1.46-.407 1.462-.745v-2.141H1.62zM108.917 60H3.083C1.36 60 .006 58.993 0 57.707V54h112v3.685c-.007 1.308-1.362 2.315-3.083 2.315z'
                transform='translate(-731 -228) translate(608.5 228.5) translate(122.5) translate(24 100)'
              />
              <path
                fill='#A3BBD8'
                d='M64 51c-.205 1.722-.95 3-1.835 3H50.836c-.888 0-1.631-1.278-1.836-3h15z'
                transform='translate(-731 -228) translate(608.5 228.5) translate(122.5) translate(24 100)'
              />
              <path
                fill='#294F83'
                d='M49.141 52.004c.285.605.785.992 1.34.992H62.52c.555 0 1.055-.387 1.34-.992H49.14zM62.52 54H50.48c-1.126 0-2.098-.982-2.365-2.389L48 51h17l-.116.611C64.616 53.018 63.644 54 62.519 54z'
                transform='translate(-731 -228) translate(608.5 228.5) translate(122.5) translate(24 100)'
              />
              <path
                fill='#E5E5E4'
                d='M110.592 59H4.25C3.01 59 2 57.87 2 56.482c0-.253.183-.482.408-.482.227 0 .409.18.409.431v.05c0 .885.643 1.605 1.434 1.605h106.34c.226 0 .409.205.409.457s-.183.457-.408.457'
                transform='translate(-731 -228) translate(608.5 228.5) translate(122.5) translate(24 100)'
              />
              <path
                fill='#F9F9FA'
                d='M97.491 58H14.51C13.124 58 12 56.906 12 55.556V5.264C12 2.909 13.96 1 16.378 1h79.245C98.04 1 100 2.909 100 5.264v50.292c0 1.35-1.123 2.444-2.509 2.444'
                transform='translate(-731 -228) translate(608.5 228.5) translate(122.5) translate(24 100)'
              />
              <path
                fill='#294F83'
                d='M16.157 1.628c-1.95 0-3.536 1.594-3.536 3.552v51.503c0 .932.753 1.689 1.68 1.689H96.7c.927 0 1.68-.757 1.68-1.689V5.18c0-1.958-1.586-3.552-3.535-3.552H16.157zM96.7 60H14.3C12.481 60 11 58.512 11 56.683V5.18C11 2.324 13.314 0 16.157 0h78.687C97.687 0 100 2.324 100 5.18v51.503C100 58.512 98.519 60 96.699 60z'
                transform='translate(-731 -228) translate(608.5 228.5) translate(122.5) translate(24 100)'
              />
              <path
                fill='#E5E5E4'
                d='M92.191 58H18.088C15.283 58 13 55.694 13 52.859V7.818c0-.453.362-.818.809-.818.447 0 .809.365.809.818v45.04c0 1.934 1.557 3.507 3.47 3.507h74.103c.447 0 .809.365.809.817 0 .453-.362.818-.809.818'
                transform='translate(-731 -228) translate(608.5 228.5) translate(122.5) translate(24 100)'
              />
              <path
                fill='#E5E5E4'
                d='M62 30c0 3.314-2.686 6-6 6s-6-2.686-6-6 2.686-6 6-6 6 2.686 6 6'
                transform='translate(-731 -228) translate(608.5 228.5) translate(122.5) translate(24 100)'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
