import { useEffect, useState } from 'react';
import { ProviderHoursQueryQuery } from '../../../../../../../generated/graphql';
import { convertServerHoursToAppHours } from './convertHours';
import {
  initialFormattedOpenCloseDaysHours,
  initialSelectedDays,
  ProviderHoursState,
} from './initialValues';

export default function useHoursOfOperation(
  data: ProviderHoursQueryQuery | undefined
) {
  const [providerHours, setProviderHours] = useState<ProviderHoursState>({
    formattedOpenCloseDaysHours: initialFormattedOpenCloseDaysHours,
    selectedDays: initialSelectedDays,
  });
  const [dataHasBeenUpdated, setDataHasBeenUpdated] = useState(false);

  useEffect(() => {
    if (data?.employeeHoursAtClinic) {
      setProviderHours(
        convertServerHoursToAppHours(data.employeeHoursAtClinic)
      );
    }
  }, [data]);

  const copyTimeToAll = () => {
    const newHours: any = {};
    const newSelectedDays: any = {};
    const mondayOpen = providerHours.formattedOpenCloseDaysHours.mondayOpen;
    const mondayClose = providerHours.formattedOpenCloseDaysHours.mondayClose;

    Object.keys(providerHours.formattedOpenCloseDaysHours).forEach((day) => {
      if (day.includes('Open')) {
        newHours[day] = mondayOpen;
      } else if (day.includes('Close')) {
        newHours[day] = mondayClose;
      }
    });

    Object.keys(providerHours.selectedDays).forEach(
      (day) => (newSelectedDays[day] = true)
    );

    setProviderHours({
      selectedDays: newSelectedDays,
      formattedOpenCloseDaysHours: newHours,
    });
    setDataHasBeenUpdated(true);
  };

  const setFieldValue = (field: string, value: any) => {
    // we do this because internally the HoursOfOperation component is sending
    // hours.{dayVariable} here. All we need is dayVariable, so we have to get
    // everything after the period.
    const normalizedFieldName = field.split('.')[1];

    setProviderHours((oldValues: any) => {
      return {
        ...oldValues,
        formattedOpenCloseDaysHours: {
          ...oldValues.formattedOpenCloseDaysHours,
          [normalizedFieldName]: value,
        },
      };
    });
    setDataHasBeenUpdated(true);
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    // I don't know why we have to do e.persist, but apparently the event turns
    // to null if we don't. Strange react stuff.
    e.persist();
    // we do this because internally the HoursOfOperation component is sending
    // a nested object. Depending on the first part of the nested object,
    // we have to determine what part of the object has to be updated.
    const fieldNameComponents = e.target.name.split('.');
    setDataHasBeenUpdated(true);
    if (fieldNameComponents[0] === 'hours') {
      setProviderHours((oldValues: any) => {
        return {
          ...oldValues,
          formattedOpenCloseDaysHours: {
            ...oldValues.formattedOpenCloseDaysHours,
            [fieldNameComponents[1]]: e.target.value,
          },
        };
      });
    } else {
      setProviderHours((oldValues: any) => {
        return {
          ...oldValues,
          selectedDays: {
            ...oldValues.selectedDays,
            [fieldNameComponents[1]]: e.target.value !== 'true',
          },
        };
      });
    }
  };

  const clearAllHours = () => {
    setDataHasBeenUpdated(true);
    setProviderHours({
      selectedDays: initialSelectedDays,
      formattedOpenCloseDaysHours: initialFormattedOpenCloseDaysHours,
    });
  };

  return {
    providerHours,
    dataHasBeenUpdated,
    handleChange,
    setFieldValue,
    copyTimeToAll,
    clearAllHours,
  };
}
