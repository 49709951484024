import React from 'react';

// styles
import {
  Header,
  addEmployeeButton,
  TitleAndSearchSection,
} from '../Employees.style';

//components
import ThemedTextField from '../../../Shared/ThemedTextField';
import { Button } from '@betterpt/better-components';
import { Link } from 'react-router-dom';
import Can from '../../../Can';

//Icons
import { IcProfile } from '@betterpt/better-icons';

//hooks
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';

//helpers
import { Roles } from '../../../../helpers';
import { colorValues } from '@betterpt/better-components';

//queries
import { useMeQuery } from '../../../../generated/graphql';

const EmployeesPageHeader = ({
  searchTerm,
  setSearchTerm,
}: {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
}) => {
  const meQuery = useMeQuery();
  const companyName = meQuery.data?.me?.company?.companyName;

  const {
    employeePluralAlias,
    employeeSingularAlias,
    primaryColor,
    loading,
  } = useRemoteConfigAliases();

  return (
    <Header>
      <h1>{(loading && '') || `${employeePluralAlias} at ${companyName}`}</h1>
      <TitleAndSearchSection>
        <ThemedTextField
          style={{ marginBottom: '-7px', marginRight: 10 }}
          InputProps={{
            startAdornment: <IcProfile color={colorValues.cityblock} />,
            style: { width: 207 },
          }}
          value={searchTerm}
          placeholder={`Quick search ${employeeSingularAlias}`}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Can
          role={Roles[meQuery.data?.me?.role || 'initial']}
          perform='employee:create'
          yes={() => (
            <Link to={'/employees/new'}>
              <Button
                color='transparent'
                size='large'
                style={addEmployeeButton(primaryColor)}
              >
                ADD {employeeSingularAlias.toUpperCase()}
              </Button>
            </Link>
          )}
        />
      </TitleAndSearchSection>
    </Header>
  );
};

export default EmployeesPageHeader;
