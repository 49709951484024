import * as React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...rest}
  >
    <defs>
      <path
        id='6sh41lfjza'
        d='M11.637 9.277c2.215 0 4.347.852 5.953 2.379.12.118.314.116.433-.004l1.156-1.166c.06-.061.094-.143.093-.229 0-.085-.035-.167-.096-.227-4.215-4.04-10.865-4.04-15.08 0-.06.06-.095.141-.096.227 0 .086.033.168.093.229l1.157 1.166c.119.12.312.122.433.004 1.606-1.527 3.737-2.379 5.954-2.379zm0 3.796c1.217 0 2.391.452 3.294 1.27.122.116.314.113.433-.006l1.155-1.167c.06-.06.094-.144.093-.23 0-.086-.036-.169-.098-.229-2.748-2.556-7.004-2.556-9.752 0-.062.06-.098.143-.099.229 0 .086.033.17.094.23l1.155 1.167c.119.12.31.122.433.006.902-.817 2.075-1.27 3.292-1.27zm2.219 2.784c.061-.06.095-.144.094-.23-.002-.087-.04-.169-.104-.227-1.275-1.079-3.143-1.079-4.42 0-.063.058-.1.14-.103.227-.001.086.033.17.094.23l1.998 2.016c.058.06.138.093.222.093.083 0 .163-.034.221-.093l1.998-2.016z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0L24 0 24 24 0 24z' />
      <mask id='3kjee9l2xb' fill='#fff'>
        <use xlinkHref='#6sh41lfjza' />
      </mask>
      <use fill='#FFF' fillRule='nonzero' xlinkHref='#6sh41lfjza' />
      <g fill={color ?? '#333'} mask='url(#3kjee9l2xb)'>
        <path d='M0 0H24V24H0z' />
      </g>
      <path
        fill='#E74C3C'
        d='M10 2H13V22H10z'
        transform='rotate(-45 11.5 12)'
      />
    </g>
  </svg>
);
