import React from 'react';

import { Hub } from '@aws-amplify/core';

import { Animation } from '@betterpt/better-components';

//hooks
import useAuthentication from '../../../hooks/useAuthentication';
import { useHistory } from 'react-router-dom';

const FederatedLogin = () => {
  const auth = useAuthentication();
  const { push, location } = useHistory();

  React.useEffect(() => {
    const loginProvider = location.pathname.split('/')[1].split('-')[0];
    if (!location.pathname.includes('signedIn') && !auth?.user) {
      auth?.federatedSignin(loginProvider);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  Hub.listen('auth', (data) => {
    const { payload } = data;
    console.log('AUTH PAYLOAD: ', payload);
    switch (payload.event) {
      case 'signIn':
        setImmediate(() => {
          console.log('SIGN IN DATA: ', payload?.data);
          auth?.completeFederatedSignIn(payload?.data);
          push('/');
        });
        break;
      case 'signOut':
        push('/login');
        break;
      case 'signIn_failure':
      case 'cognitoHostedUI_failure':
        setImmediate(() => {
          console.error('Sign In Failure:', payload, location);
          push('/');
          if (
            payload?.data.message.includes(
              'Signup+disallowed.+Account+does+not+exist.'
            )
          ) {
            console.log(
              'Account has not been set up. Please contact your administrator to have your email address added to the corporate BetterPT account.'
            );
          } else {
            console.log('Something went wrong logging in the user');
          }
        });
        break;
    }
  });

  return <Animation type='providerAppLoader' />;
};

export default FederatedLogin;
