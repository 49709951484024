import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    {...rest}
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='25'
    viewBox='0 0 24 25'
  >
    <g fill='none' fillRule='evenodd' transform='matrix(-1 0 0 1 24 0)'>
      <path d='M0 0h24v25H0z' />
      <rect
        width='20'
        height='20.833'
        x='2'
        y='2.083'
        fill={color || '#2A4E82'}
        rx='10'
      />
      <path fill='#FFF' d='M9 6.25h2v12.5H9zM13 6.25h2v12.5h-2z' />
    </g>
  </svg>
);
