import React from 'react';

//types
import { BoolFieldState } from '../../../../hooks/useBoolFieldState';

// components
import {
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { Radio, TextField } from '@betterpt/better-components';

//assets
import { IcDropDownSelected } from '@betterpt/better-icons';

//styles
import { colorValues } from '@betterpt/better-components';
import {
  dialogTextInputStyle,
  dropdownIconStyle,
  SelectContainer,
  SelectDropdown,
} from '../AppointmentTypes.style';

interface Props {
  legacyIsInitialEvalFormState: BoolFieldState;
  dropdown: {
    isOpen: boolean;
    toggle: () => void;
    close: () => void;
  };
}

const ApptMediumSelector = ({
  legacyIsInitialEvalFormState,
  dropdown,
}: Props) => {
  const textFieldValue = (() => {
    switch (legacyIsInitialEvalFormState.value) {
      case true:
        return 'Initial Evaluation';
      case false:
        return 'Follow Up';
      case null:
        return 'None';
      default:
        return 'None';
    }
  })();

  return (
    <ClickAwayListener onClickAway={dropdown.close}>
      <SelectContainer>
        <TextField
          disabled
          fullWidth
          tabIndex={0}
          label='Optional Legacy Appointment Type (for bidirectional integrations only)'
          id='newApptLegacyIsInitialEval'
          placeholder='Is this appointment for initial evaluations or follow ups?'
          InputLabelProps={{ shrink: true }}
          style={dialogTextInputStyle}
          value={textFieldValue}
          InputProps={{
            endAdornment: (
              <IcDropDownSelected
                color={colorValues.coneyislandsidewalk}
                style={dropdownIconStyle(dropdown.isOpen)}
              />
            ),
          }}
          onClick={dropdown.toggle}
          onKeyDown={(e) => e?.key === 'Enter' && dropdown.toggle()}
          data-cy='appointment-type-is-initial-eval-textfield'
        />
        <SelectDropdown className={dropdown.isOpen ? 'animate' : 'closed'}>
          <FormGroup>
            <FormControlLabel
              label='None'
              control={
                <Radio
                  checked={legacyIsInitialEvalFormState.value === null}
                  onClick={() => legacyIsInitialEvalFormState.updateValue(null)}
                  data-cy='appointment-type-is-initial-eval-none'
                />
              }
            />
            <FormControlLabel
              label='Initial Evaluation'
              control={
                <Radio
                  checked={legacyIsInitialEvalFormState.value === true}
                  onClick={() => legacyIsInitialEvalFormState.updateValue(true)}
                  data-cy='appointment-type-is-initial-eval-true'
                />
              }
            />
            <FormControlLabel
              label='Follow Up'
              control={
                <Radio
                  checked={legacyIsInitialEvalFormState.value === false}
                  onClick={() =>
                    legacyIsInitialEvalFormState.updateValue(false)
                  }
                  data-cy='appointment-type-is-initial-eval-false'
                />
              }
            />
          </FormGroup>
        </SelectDropdown>
      </SelectContainer>
    </ClickAwayListener>
  );
};

export default ApptMediumSelector;
