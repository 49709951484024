import React from 'react';

//types
import { Employee, Maybe, useMeQuery } from '../../../../generated/graphql';
import { AddAppointmentTypePayload } from '../hooks/useAppointmentTypeOperations';

//helpers
import Flags from '../../../../constants/flags';
import { normalizeOnlyNumbers, validateTimePeriod } from '../../../../helpers';

//hooks
import useSnackbar from '../../../../hooks/useSnackbar';
import useTextField from '../../../../hooks/useTextFieldState';
import useBoolField from '../../../../hooks/useBoolFieldState';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';

//components
import { Button, Switch } from '@betterpt/better-components';
import ThemedTextField from '../../ThemedTextField';
import FormField from '../../FormField';
import ApptInitialEvalSelector from './ApptInitialEvalSelector';
import ProgressBar from '../../ProgressBar';

//styles
import { colorValues } from '@betterpt/better-components';
import { clickableInputStyle, dialogTextInputStyle, DialogBody, DialogHeader, WarningText } from '../AppointmentTypes.style';
import useHandleError from '../../../../hooks/useHandleError';
import useFeatureFlagWrapper from 'apps/provider-app/src/hooks/useFeatureFlagWrapper';

// styles
const formStyle = { width: '100%' };
const firstHeaderSize = { fontSize: 24 };
const durationWrapperStyle = {
  position: 'relative' as 'relative',
  width: '100%',
};
const buttonStyle = (backgroundColor: string) => ({
  backgroundColor,
  marginTop: '7vh',
  position: 'absolute' as 'absolute',
  top: 40,
  right: 40,
  width: 211,
});

const providerSubheaderStyle = {
  fontStyle: 'italic',
  margin: '10px 0 19px',
};
const minutesTextStyle = {
  fontSize: 14,
  color: colorValues.coneyislandsidewalk,
};

interface Props {
  companyId: string;
  onClose: () => void;
  shouldRemoveCallLengthLimit: boolean;
  addAppointmentType: (payload: AddAppointmentTypePayload) => Promise<any>;
  provider?: Maybe<Partial<Employee>>;
  setShowNewApptForm: Function;
  setShowDefaultQuestion: Function;
  setShowQualifyingQuestion: Function;
  setAppointmentTypeId: Function;
  isPT: boolean;
}

const AddNewApptTypeForm = ({
  companyId,
  provider,
  addAppointmentType,
  setShowNewApptForm,
  setShowDefaultQuestion,
  setShowQualifyingQuestion,
  setAppointmentTypeId,
  isPT,
}: Props) => {
  const snackbar = useSnackbar();
  const handleError = useHandleError();
  const featureFlagWrapper = useFeatureFlagWrapper();
  const { employeeSingularAlias, facilitiesPluralAlias, patientsSingularAlias, primaryColor } = useRemoteConfigAliases();

  const meQuery = useMeQuery();

  const [loading, updateLoading] = React.useState(false);
  const [legacyIsInitialEvalOpen, updateLegacyIsInitialEvalOpen] = React.useState(false);

  const displayNameFormState = useTextField({ required: true });
  const medium = 'inClinic';
  const legacyIsInitialEvalFormState = useBoolField({
    initialValue: null,
  });
  const durationFormState = useTextField({
    required: true,
    validation: validateTimePeriod('duration'),
    normalizer: normalizeOnlyNumbers,
  });
  const intervalFormState = useTextField({
    required: true,
    validation: validateTimePeriod('interval'),
    normalizer: normalizeOnlyNumbers,
  });
  const canChooseProviderFormState = useBoolField({
    initialValue: true,
  });
  const shouldRequireManualInsuranceFormState = useBoolField({
    initialValue: false,
  });
  const shouldShowFinalCheckboxFormState = useBoolField({ initialValue: true });

  const customFormTemplate = {
    title: 'Qualifying Questions for this Appointment Type',
    description:
      'the custom qualifying questions for this appointment type. Please note, for data tracking and user experience purposes, any required questions will automatically be ordered before optional questions.',
    questions: [],
    schemaVersion: '1.0.0',
  }; // dummy object for the new schema

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateLoading(true);
    try {
      const newAppointmentType = await addAppointmentType({
        companyId,
        displayNameFormState,
        medium,
        durationFormState,
        intervalFormState,
        canChooseProviderFormState,
        legacyIsInitialEvalFormState,
        shouldRequireManualInsuranceFormState,
        shouldShowFinalCheckboxFormState,
        customFormTemplate: featureFlagWrapper(customFormTemplate, Flags.CustomCascadingLogic, null),
      });

      updateLoading(false);
      snackbar?.setSuccessMessage('New appointment type saved!');
      setAppointmentTypeId(newAppointmentType?.createAppointmentType?.id);
      setShowNewApptForm(false);
      if (isPT) setShowDefaultQuestion(true);
      else setShowQualifyingQuestion(true);
    } catch (e) {
      updateLoading(false);
      handleError(e);
    }
  };

  const handleCloseSelectors = () => {
    updateLegacyIsInitialEvalOpen(false);
  };

  const toggleSelect = (type: 'providers' | 'legacyIsInitialEval') => {
    handleCloseSelectors();
    switch (type) {
      case 'legacyIsInitialEval':
        updateLegacyIsInitialEvalOpen(!legacyIsInitialEvalOpen);
        break;
      default:
        break;
    }
  };

  return (
    <DialogBody wide>
      <ProgressBar width={40} style={{ marginTop: 20 }} />
      <form onSubmit={handleSubmit} style={formStyle}>
        <DialogHeader style={firstHeaderSize}>Create a New Appointment Type</DialogHeader>
        {!!provider && (
          <p className="Body" style={providerSubheaderStyle}>
            Please note, once you assign this appointment type to this {employeeSingularAlias.toLowerCase()}, it will be associated with all{' '}
            {facilitiesPluralAlias.toLowerCase()} where the {employeeSingularAlias.toLowerCase()} works.
          </p>
        )}
        <FormField
          fullWidth
          label="Name"
          id="newApptTypeName"
          placeholder="Name for this appointment type"
          formHandler={displayNameFormState}
          style={dialogTextInputStyle}
          data-cy="create-appointment-type-name"
        />
        {meQuery.data?.me?.company?.hasIntegration && (
          <ApptInitialEvalSelector
            legacyIsInitialEvalFormState={legacyIsInitialEvalFormState}
            dropdown={{
              isOpen: legacyIsInitialEvalOpen,
              toggle: () => toggleSelect('legacyIsInitialEval'),
              close: () => updateLegacyIsInitialEvalOpen(false),
            }}
          />
        )}

        <DialogHeader>Booking Experience for This Appointment Type</DialogHeader>
        <div style={durationWrapperStyle}>
          <FormField
            fullWidth
            label="Duration"
            id="newApptDuration"
            placeholder="Duration in minutes for this appointment type"
            formHandler={durationFormState}
            style={dialogTextInputStyle}
            inputProps={{ style: clickableInputStyle }}
            InputProps={{
              endAdornment: <p style={minutesTextStyle}>Minutes</p>,
            }}
            data-cy="create-appointment-type-duration"
          />
        </div>

        <FormField
          fullWidth
          label="Time Inverval"
          id="newApptInterval"
          placeholder="Interval in minutes between each appointment"
          formHandler={intervalFormState}
          style={dialogTextInputStyle}
          inputProps={{ style: clickableInputStyle }}
          InputProps={{
            endAdornment: <p style={minutesTextStyle}>Minutes</p>,
          }}
          data-cy="create-appointment-type-interval"
        />

        <ThemedTextField
          fullWidth
          label={`${employeeSingularAlias} Select`}
          id="newCanChooseProvider"
          style={dialogTextInputStyle}
          value={`${patientsSingularAlias} ability to choose a specific ${employeeSingularAlias.toLowerCase()} is turned ${
            canChooseProviderFormState.value ? 'ON' : 'OFF'
          }`}
          data-cy="create-appointment-type-choose-provider"
          inputProps={{
            style: { cursor: 'default' },
          }}
          multiline
          InputProps={{
            endAdornment: (
              <Switch
                name="newCanChooseProviderSwitch"
                value={canChooseProviderFormState.value}
                checked={!!canChooseProviderFormState.value}
                onChange={(e) => canChooseProviderFormState.updateValue(e.target.checked)}
              />
            ),
          }}
        />
        <ThemedTextField
          fullWidth
          label="Require Insurance"
          id="requireInsurance"
          style={dialogTextInputStyle}
          value={`${patientsSingularAlias} will${shouldRequireManualInsuranceFormState.value ? '' : ' NOT'} be required to enter insurance`}
          multiline
          InputProps={{
            endAdornment: (
              <Switch
                data-cy="require-insurance-toggle"
                name="requireInsurancedSwitch"
                value={shouldRequireManualInsuranceFormState.value === true}
                checked={shouldRequireManualInsuranceFormState.value === true}
                onChange={(e) => {
                  shouldRequireManualInsuranceFormState.updateValue(e.target.checked);
                }}
              />
            ),
          }}
        />

        <ThemedTextField
          fullWidth
          label="Patient Request Acknowledgement"
          id="patientRequestAcknowledgement"
          style={dialogTextInputStyle}
          value={`On booking, patients are${
            shouldShowFinalCheckboxFormState.value ? '' : ' NOT'
          } required to acknowledge they are requesting an appointment`}
          multiline
          InputProps={{
            endAdornment: (
              <Switch
                data-cy="patient-acknowledgment-toggle"
                name="patientRequestAcknowledgementSwitch"
                value={shouldShowFinalCheckboxFormState.value === true}
                checked={shouldShowFinalCheckboxFormState.value === true}
                onChange={(e) => {
                  shouldShowFinalCheckboxFormState.updateValue(e.target.checked);
                }}
              />
            ),
          }}
        />

        <Button
          fullWidth
          type="submit"
          size="large"
          style={buttonStyle(primaryColor)}
          loading={loading}
          data-cy="create-appointment-type-button"
        >
          SAVE AND CONTINUE
        </Button>
      </form>
    </DialogBody>
  );
};

export default AddNewApptTypeForm;
