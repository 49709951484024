import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...rest}
  >
    <defs>
      <path
        id='amkuvsz5ca'
        d='M7 11h10v2H7v-2zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0L24 0 24 24 0 24z' />
      <mask id='n6i2l12qgb' fill='#fff'>
        <use xlinkHref='#amkuvsz5ca' />
      </mask>
      <use fill='#333' fillRule='nonzero' xlinkHref='#amkuvsz5ca' />
      <g fill={color || '#E74C3C'} mask='url(#n6i2l12qgb)'>
        <path d='M0 0H24V24H0z' />
      </g>
    </g>
  </svg>
);
