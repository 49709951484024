import { useMeQuery, Role } from '../../../../../generated/graphql';
import { Roles } from '../../../../../helpers';

export default function useRoleChecker() {
  const meQuery = useMeQuery();

  const rolesChecker = (role: Roles) => {
    const me = meQuery.data?.me;
    if (me?.company?.shouldPreventNewAdminEmployees && role === Roles.admin) {
      return false;
    }
    if (me?.company?.shouldPreventNewBasicEmployees && role === Roles.self) {
      return false;
    }

    if (me?.role === Role.Admin && [Roles.owner, Roles.admin].includes(role)) {
      return false;
    }

    if (
      me?.role === Role.Manager &&
      [Roles.owner, Roles.admin, Roles.manager].includes(role)
    ) {
      return false;
    }
    return true;
  };

  return rolesChecker;
}
