import React, { useState } from 'react';

//
import { Button } from '@betterpt/better-components';
import QuestionContainerTemplate from './QuestionContainerTemplate';

// helper
import _ from 'lodash';

// types
import { Question } from './CustomTypes';

type Props = {
    questions: Question[];
    actions: any;
    prefix: string;
    childQuestions?: any;
}

const QuestionsArrayTemplateNew = (props: Props) => {
    let { questions, actions, prefix, childQuestions } = props;

    return (
        <div>
            <h2 style={{ maxWidth: '300px' }} className='H2'>
                {prefix} Custom Qualifying Questions for this Appointment Type
            </h2>
            <p className='Body'>
                <i>
                    Please note, for data tracking and user experience purposes, primary questions that are required-as well as any cascading secondary questions they prompt-will automatically be ordered before optional questions.
                </i>

            </p>
            {/* <p className='Body'>
                <i>
                    To learn more about the best practices when creating qualifying questions. click here.
                </i>
            </p> */}
            {questions.length > 0 && questions.map((question: Question, index: number) => {

                if (question?.level === 0)
                    return <QuestionContainerTemplate
                        key={question.id}
                        question={question}
                        actions={actions}
                        index={index}
                        childQuestions={childQuestions[`${question.id}`]}
                    />
            })}

            <Button
                color='lightBlue'
                fullWidth
                onClick={actions.addQuestion}
                style={{ marginBottom: "20px" }}
                type='button'
                size='large'
            >
                ADD NEW QUESTION
            </Button>
        </div >
    );
};

export default QuestionsArrayTemplateNew;
