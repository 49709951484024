import React from 'react';

//components
import { HorizontalNavigation, Button, bestTextColor, colorValues } from '@betterpt/better-components';
import { Link } from 'react-router-dom';
import FacilityInfoTab from './FacilityInfoTab';
import AppointmentConfigTab from './AppointmentConfigTab';
import AddEmployeesToClinicDialog from './components/AddEmployeesToClinicDialog';
import AddAppointmentButtonWithDialog from '../../../Shared/AddAppointmentButtonWithDialog';
import ActivateClinicDialog from './ActivateClinicDialog';
import ErrorPage from '../../../Shared/ErrorPage';

// styles
import { Page } from './FacilityDetails.style';
import * as styles from './FacilityDetails.style';

// types
import { Todo } from '../FacilitiesListView';

//helpers
import { Roles } from '../../../../helpers/rbac-rules';
import Can from '../../../Can';

//hooks
import { useFacilityQuery, useMeQuery } from '../../../../generated/graphql';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import useFacilityDetailOperations from './hooks/useFacilityDetailOperations';
import useSnackbar from '../../../../hooks/useSnackbar';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import useFeatureFlagWrapper from '../../../../hooks/useFeatureFlagWrapper';

export interface LocationState {
  activateClinicDialogOpen?: boolean;
  todo?: Todo;
}

const FacilityDetail = () => {
  const featureWrapper = useFeatureFlagWrapper();
  const { facilityId } = useParams<{ facilityId: string }>();
  const history = useHistory<LocationState>();
  const snackbar = useSnackbar();
  const [addEmployeesToClinicDialogOpen, updateAddEmployeesToClinicDialogOpen] = React.useState(false);

  const [activateClinicDialogOpen, updateActivateClinicDialogOpen] = React.useState(false);

  const {
    commands: { addEmployeesToClinic },
  } = useFacilityDetailOperations(facilityId);

  const meQuery = useMeQuery();

  const facilityQuery = useFacilityQuery({
    variables: { id: facilityId },
    onError: () => snackbar?.useGenericErrorMessage(),
    fetchPolicy: 'cache-and-network',
  });

  const { facilitiesPluralAlias, employeeSingularAlias, primaryColor, secondaryColor } = useRemoteConfigAliases();

  const handleDialogClose = () => {
    updateActivateClinicDialogOpen(false);
    history.push({
      state: {
        activateClinicDialogOpen: false,
      },
    });
  };

  const tabs = [
    {
      display: 'Facility Info',
      path: `/facilities/${facilityId}`,
      exact: true,
      hideTab: true,
      component: () => <Redirect to={`/facilities/${facilityId}/info`} />,
    },
    {
      display: 'Facility Info',
      path: `/facilities/${facilityId}/info`,
      exact: true,
      component: () => (
        <Page>
          <FacilityInfoTab facilityId={facilityId} />
        </Page>
      ),
    },
    {
      display: 'Appointment Config.',
      path: `/facilities/${facilityId}/config`,
      exact: true,
      component: () => (
        <Page>
          <AppointmentConfigTab facilityId={facilityId} />
        </Page>
      ),
    },
  ];

  const CTASection = [
    (facilityQuery.data?.clinic?.betterPTSubscriptionActive || facilityQuery.data?.clinic?.isTelehealthActive) && (
      <AddAppointmentButtonWithDialog
        key="1"
        showInPersonOption={!!facilityQuery.data?.clinic?.betterPTSubscriptionActive}
        color={primaryColor}
        size="medium"
      />
    ),
    <Can
      key="2"
      role={Roles[meQuery.data?.me?.role || 'initial']}
      perform="employee:create"
      yes={() => (
        <Button
          size="medium"
          style={{
            backgroundColor: secondaryColor,
            color: bestTextColor(secondaryColor),
          }}
          onClick={() => updateAddEmployeesToClinicDialogOpen(true)}
        >
          ADD {employeeSingularAlias.toUpperCase()}
        </Button>
      )}
    />,
  ];

  React.useEffect(
    () => {
      if (history.location.state?.activateClinicDialogOpen) {
        updateActivateClinicDialogOpen(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history.location.state?.activateClinicDialogOpen]
  );

  if (facilityQuery.error?.message.includes('Not permitted')) {
    return <ErrorPage message="You do not have permission to view this page." />;
  }

  return (
    <>
      <ActivateClinicDialog isOpen={activateClinicDialogOpen} handleClose={handleDialogClose} />
      <styles.PageHeader>
        <h1 className="H1">
          <Link to="/facilities">{facilitiesPluralAlias}</Link> / {facilityQuery.data?.clinic?.clinicName}
        </h1>
      </styles.PageHeader>

      {addEmployeesToClinicDialogOpen && (
        <AddEmployeesToClinicDialog
          isOpen={addEmployeesToClinicDialogOpen}
          handleClose={() => updateAddEmployeesToClinicDialogOpen(false)}
          addEmployeesToClinic={addEmployeesToClinic}
        />
      )}

      <HorizontalNavigation
        pageBackgroundColor={colorValues.earlysmoke}
        navBackgroundColor={colorValues.emptiness}
        tabs={tabs}
        padding={'0px 25px 0px 50px'}
        buttons={CTASection}
      />
    </>
  );
};

export default FacilityDetail;
