import React from 'react';
import styled from '@emotion/styled';
import { Card, Header } from '@betterpt/better-components';
import YextLogo from '../../../../assets/logos-partners-square-logo-yext@3x.png';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';

const YextLogoImage = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 16px;
`;

const headerStyle = {
  justifyContent: 'flex-start',
  paddingTop: '25px',
  paddingBottom: '25px',
};

const cardStyle = {
  maxWidth: '1182px',
  margin: '20px 28px 20px 0px',
};

const CardText = styled.p`
  margin: 20px 37px 20px 60px;
`;

const YextMessage = () => {
  const { facilitiesSingularAlias } = useRemoteConfigAliases();
  const lowerFacilitiesSingularAlias = facilitiesSingularAlias.toLowerCase();
  return (
    <Card style={cardStyle}>
      <Header underline style={headerStyle}>
        <YextLogoImage src={YextLogo} alt='Yext logo' />
        <h3 className='H3'>
          This {lowerFacilitiesSingularAlias}&apos;s account is managed on Yext
        </h3>
      </Header>
      <CardText className='Body'>
        This {lowerFacilitiesSingularAlias}’s account is managed through your
        Yext – BetterAccess integration. You can edit most information for this{' '}
        {lowerFacilitiesSingularAlias} here, but if you need to make any changes
        to the account name, phone or address, please do so through Yext.
      </CardText>
    </Card>
  );
};

export default YextMessage;
