/* Refactored on 3/12/2021 */

import * as React from 'react';

// hooks
import useCompanyInfoOperations from './hooks/useCompanyInfoOperations';
import { useMeQuery } from '../../../generated/graphql';

// components
import OnboardingWrapper from '../OnboardingWrapper';
import CompanyForm from './components/CompanyForm';
import ImageForm from './components/ImageForm';
import { CompanyFormContainer } from './CompanyInfo.style';
import { Loader } from '../../Home/Home.style';
import { Animation } from '@betterpt/better-components';

const CompanyInfo = () => {
  // hooks
  const meQuery = useMeQuery();
  const [saving, updateSaving] = React.useState(false);

  const {
    commands: { updateCompanyInfo, uploadCompanyAvatar },
  } = useCompanyInfoOperations();

  if (meQuery?.loading) {
    return (
      <Loader>
        <Animation type='providerAppLoader' />
      </Loader>
    );
  }

  return (
    <OnboardingWrapper form='companyOnboardingForm' saving={saving}>
      <CompanyFormContainer>
        <ImageForm uploadCompanyAvatar={uploadCompanyAvatar} />
        <CompanyForm
          updateCompanyInfo={updateCompanyInfo}
          updateSaving={updateSaving}
        />
      </CompanyFormContainer>
    </OnboardingWrapper>
  );
};

export default CompanyInfo;
