import * as React from 'react';
import { Maybe } from '../generated/graphql';

interface TextFieldOptions {
  required?: boolean;
  validation?: (value: string) => any | void;
  normalizer?: (value: string) => string;
  initialValue?: Maybe<string>;
}

const useTextFieldState = (options?: TextFieldOptions) => {
  const [value, updateValue] = React.useState<string>(
    options?.initialValue ?? ''
  );
  const [error, updateError] = React.useState<string | null>();
  const [touched, updateTouched] = React.useState(false);

  React.useEffect(() => {
    if (value.length > 0) {
      updateTouched(true);
    }
    if (options?.validation && touched) {
      updateError(options?.validation(value));
    } else if (options?.required && touched && value.length === 0) {
      updateError('Required');
    } else if (options?.required && touched && value.length > 0) {
      updateError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, touched]);

  return {
    value,
    updateValue: (value: string) =>
      updateValue(options?.normalizer ? options.normalizer(value) : value),
    touched,
    updateTouched: () => updateTouched(true),
    error,
    valid: !error && !!(options?.required ? !!value : true),
  };
};

export type TextFieldState = {
  value: string;
  updateValue: (value: string) => void;
  touched: boolean;
  updateTouched: () => void;
  error: string | null | undefined;
  valid: boolean;
};

export default useTextFieldState;
