import * as React from 'react';

//types
import { Appointment, useMeQuery, Role } from '../../../../../../../generated/graphql';
import Flags from '../../../../../../../constants/flags';

// helpers
import { format, utcToZonedTime } from 'date-fns-tz';
import { AppointmentStatus } from '../InPersonAppointmentStatus';
import { parseAppointmentTypeField } from '../../../../../../../helpers';

// hooks
import { useHistory } from 'react-router-dom';
import useAppointmentFilters from '../../../../../../../hooks/useInPersonAppointmentFilters';
import useRemoteConfigAliases from '../../../../../../../hooks/useRemoteConfigAliases';
import useFeatureFlagWrapper from '../../../../../../../hooks/useFeatureFlagWrapper';

// components
import { Card, colorValues, Table, InfoBox } from '@betterpt/better-components';
import NoAppointmentsView from '../../../../Shared/NoAppointmentsView';
import InPersonOptions from '../../../InPersonOptions';
import PatientContacted from '../../../../Shared/ContactedPatientInfoBox';

type Props = {
  isLoading: boolean;
  totalCount: number;
  onChangePage: (pageSize: number, idx: number, activeSection: number) => void;
  appointmentsQuery: any;
  pagesize: number;
  onDoneChangingOptionsAppointment: () => Promise<any> | undefined;
  tableOptions?: any;
  defaultPageIndex?: number;
  small?: boolean;
};

const InPersonAppointmentsTable: React.FC<React.PropsWithChildren<Props>> = ({
  isLoading,
  totalCount,
  onChangePage,
  appointmentsQuery,
  pagesize,
  onDoneChangingOptionsAppointment,
  tableOptions: options = {},
  defaultPageIndex,
  small,
}) => {
  const history = useHistory();
  const appointments = appointmentsQuery?.data?.inPersonAppointments?.result ?? [];
  const filters = useAppointmentFilters();
  const featureFlagWrapper = useFeatureFlagWrapper();

  const meQuery = useMeQuery();
  const notAcorn = meQuery.data?.me?.company?.providerAppId?.toLowerCase() !== 'acorn';

  const { facilitiesSingularAlias, patientsSingularAlias, primaryColor } = useRemoteConfigAliases();

  const [backToFirstPage, updateBackToFirstPage] = React.useState(false);
  const [selectedApptId, setSelectedApptId] = React.useState<string | null>(null);
  //so that user trying to close quick action doesnt accidentally link to the detail view via clicking the row

  React.useEffect(() => {
    const backToStart = filters.offset.value === 0 && filters.defaultTableSection.value === 1 && filters.defaultPageIndex.value === 0;
    updateBackToFirstPage(backToStart);
  }, [filters]);

  const tableColumns = [
    { field: 'requestedDate', title: 'REQUESTED ON' },
    { field: 'date', title: 'APPT. TIME' },
    { field: 'employee', title: 'PROVIDER' },
    { field: 'facility', title: facilitiesSingularAlias.toUpperCase() },
    { field: 'patient', title: patientsSingularAlias.toUpperCase() },
    notAcorn
      ? {
          field: 'apptType',
          title: 'APPT. TYPE',
        }
      : {},
    { field: 'status', title: 'APPT. STATUS' },
    featureFlagWrapper({ field: 'assignedEmployee', title: 'SCHEDULING ACTIVITY' }, Flags.AssignAppointments, {})!,
    meQuery.data?.me?.role !== Role.Self
      ? {
          field: 'options',
          title: 'ACTION',
        }
      : {},
  ];

  const rowData =
    appointments.map((appointment: Appointment) => {
      const patient = appointment?.contact || appointment?.patient;
      const employee = appointment?.employee;
      const facility = appointment?.clinic;
      const hasContacted = appointment?.hasPatientBeenContacted;
      const patientContactedByEmployee = appointment?.patientContactedByEmployee;
      const requestedDate = (
        <div style={{ marginLeft: '10px', position: 'relative' }}>
          <p style={{ margin: '0px' }}>
            {format(
              utcToZonedTime(
                new Date(appointment?.createdAt ?? null),
                (appointment as any)?.clinic?.timeZone ?? (appointment as any)?.timeZone ?? 'America/New_York'
              ),
              'MM/dd/yyyy @h:mma'
            )}
          </p>
        </div>
      );

      const date = (
        <div style={{ margin: '0px', position: 'relative' }}>
          <p style={{ margin: '0px' }}>
            {format(
              utcToZonedTime(
                new Date(appointment?.startTime ?? null),
                (appointment as any)?.clinic?.timeZone ?? (appointment as any)?.timeZone ?? 'America/New_York'
              ),
              'MM/dd/yyyy @h:mma'
            )}
          </p>
        </div>
      );

      const patientContactedAt = format(
        utcToZonedTime(
          new Date(appointment?.patientContactedAt ?? null),
          (appointment as any)?.clinic?.timeZone ?? (appointment as any)?.timeZone ?? 'America/New_York'
        ),
        'MM/dd/yyyy'
      );
      const patientFullName = `${patient?.firstName ?? '-'} ${patient?.lastName ?? '-'}`;
      const employeeFullName = `${employee?.firstName ?? '-'} ${employee?.lastName ?? '-'}`;
      const contactedEmployeeFullName = `${patientContactedByEmployee?.firstName} ${patientContactedByEmployee?.lastName}`;

      const assignedEmployee = appointment.assignedEmployeeId ? (
        <p style={{ color: colorValues.betterptblack }}>
          Appt. assigned to{' '}
          {appointment.assignedEmployeeId === meQuery.data?.me?.id
            ? 'me'
            : `${appointment.assignedEmployee?.firstName} ${appointment.assignedEmployee?.lastName}`}
        </p>
      ) : (
        <p style={{ color: colorValues.charcoalgray }}>Appt. not assigned to scheduler</p>
      );

      return {
        id: appointment?.id,
        requestedDate,
        date,
        employee: employeeFullName,
        facility: facility?.clinicName ?? '-',
        patient: (
          <div
            style={{
              position: 'relative',
              display: 'flex',
            }}
          >
            {hasContacted && PatientContacted(patientContactedAt, contactedEmployeeFullName)}
            {patientFullName}
          </div>
        ),
        apptType: parseAppointmentTypeField({
          apptType: appointment?.appointmentType,
          wasRequested: appointment?.wasRequested,
          isInitialEval: appointment?.isInitialEval,
          facilitiesSingularAlias,
        }),
        status: <AppointmentStatus appointment={appointment} />,
        assignedEmployee,
        options: meQuery.data?.me?.role !== Role.Self && (
          <InPersonOptions
            onDoneChangingOptionsAppointment={onDoneChangingOptionsAppointment}
            appointment={appointment}
            openStatus={{
              value: selectedApptId === appointment?.id,
              update: (isOpen) => setSelectedApptId(isOpen ? appointment?.id : null),
            }}
          />
        ),
      };
    }) ?? [];

  const tableOptions = {
    minBodyHeight: small ? 400 : 690,
    overflow: 'visible' as 'visible',
    pagination: {
      totalCount,
      pagesize,
      onChangePage,
      defaultPageIndex: defaultPageIndex ?? filters.defaultPageIndex.value,
      defaultSection: filters.defaultTableSection.value,
      primaryColor: primaryColor,
      backToFirstPage,
    },
    searchNotFoundText: 'Appointment Not Found',
    ...options,
  };

  if (appointments.length === 0 && !isLoading) {
    return <NoAppointmentsView isNoMatchingAppointmentsPage />;
  }

  return (
    <Card style={{ width: '100%' }} data-testid="appointment-table-view-container">
      <Table
        columns={tableColumns}
        data={rowData}
        isLoading={isLoading}
        options={tableOptions}
        data-private
        onRowClick={(_e, rowData) => history.push(`/appointments/details/${rowData?.id}`)}
      />
    </Card>
  );
};

export default InPersonAppointmentsTable;
