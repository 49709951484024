import axios from 'axios';
import Auth from '@aws-amplify/auth';
import useHandleError from '../../../../../hooks/useHandleError';
import { Buffer } from 'buffer';

const useGetAuthenticatedImage = () => {
  const handleError = useHandleError();
  return async (url: string) => {
    try {
      const currentSession = await Auth.currentSession();

      const token = currentSession.getIdToken().getJwtToken();

      const response = await axios({
        method: 'get',
        url,
        headers: {
          authorization: token,
          accept: 'image/png; image/jpeg',
        },
        responseType: 'arraybuffer',
      });

      return `data:image/png;base64,${Buffer.from(response.data).toString(
        'base64'
      )}`;
    } catch (e) {
      handleError(e);
    }
  };
};

export default useGetAuthenticatedImage;
