// This has to be manually kept up to date by the frontend team. When a new flag is created, put it in here
// It should have an enum key, and the enum value will always be the flag in camel case, NOT kabob case.
// Launch Darkly converts all flags to camel case for the React SDK.

enum Flags {
  AssignAppointments = 'assignAppointments',
  ClinicSmsMessages = 'clinicSmsMessages',
  CustomCascadingLogic = 'customCascadingLogic',
  UpdateSchemaToCascadingLogic = 'updateSchemaToCascadingLogic',
  EmbeddedAnalytics = 'embeddedAnalytics',
  ShowRealCompanyData = 'showRealCompanyData',
  DisableBillingForCompanySetup = 'disableBillingForCompanySetup',
  ChangeAppointmentLocation = 'changeAppointmentLocation',
}

export default Flags;
