import React from 'react';
import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';
import { MappedAppointmentTypeDetail } from '../hooks/useAppointmentTypeDetailOperations';

const QuestionContainer = styled.div`
  border-radius: 5px;
  border: solid 2px ${colorValues.concretejungle};
  margin: 15px 25px 15px 50px;
  padding: 18px 0px 18px 21px;
`;

const QuestionText = styled.p`
  border-bottom: 1px solid ${colorValues.coneyislandsidewalk};
`;

const AnswerText = styled.p`
  border-bottom: 1px solid ${colorValues.coneyislandsidewalk};
`;

const AnswersContainer = styled.div`
  margin-left: 30px;
`;

const QuestionNumber = styled.h3`
  margin: 0px 0px 10px 0px;
  color: ${colorValues.charcoalgray};
`;

interface Props {
  question: string;
  details: any;
  mappedAppointmentType: MappedAppointmentTypeDetail;
  questionNumber: number;
}

const DisplayQuestion: React.FC<React.PropsWithChildren<Props>> = ({
  question,
  details,
  mappedAppointmentType,
  questionNumber,
}) => {
  const determineQuestionType = (questionDetails: any) => {
    if (
      (questionDetails.enum?.length ?? 0) === 2 &&
      questionDetails.enum.includes('Yes') &&
      questionDetails.enum.includes('No')
    ) {
      return 'Yes or No Question';
    } else if (questionDetails.enum) {
      return 'Multiple Choice Question';
    } else {
      return 'Open-Ended Question';
    }
  };

  const questionType = determineQuestionType(details);
  const isMultipleChoice = questionType === 'Multiple Choice Question';
  const isRequired = mappedAppointmentType.customFormTemplate.required.includes(
    question
  );
  return (
    <QuestionContainer key={question}>
      <QuestionNumber className='H3'>Question {questionNumber}</QuestionNumber>
      <p className='H6'>{questionType}</p>
      <QuestionText className='Body'>
        {question} ({isRequired ? 'Required' : 'Optional'})
      </QuestionText>
      {isMultipleChoice && (
        <AnswersContainer>
          <p className='Form'>Answers</p>
          {details?.enum.map((answer: string) => (
            <AnswerText className='Body' key={answer}>
              {answer}
            </AnswerText>
          ))}
        </AnswersContainer>
      )}
    </QuestionContainer>
  );
};

export default DisplayQuestion;
