import {
  useAssociateFacilityWithEmployeesMutation,
  useUpdateFacilityMutation,
} from '../../../../../generated/graphql';

const useFacilityDetailOperations = (clinicId: string) => {
  const [
    associateEmployeesToClinic,
  ] = useAssociateFacilityWithEmployeesMutation();

  const [updateFacility] = useUpdateFacilityMutation();

  // I think I'm starting to make sense of these functions as being for these main purposes:
  // 1. Sending network requests
  // 2. Updating application level state via hooks
  // 3. Determining if numbers 1 and 2 are ok to do.
  // It's not responsible for updating UI or reporting loading states or error states.
  const addEmployeesToClinic = async (employeeIds: string[]) => {
    await associateEmployeesToClinic({
      variables: {
        input: {
          clinicId,
          employeeIds,
        },
      },
    });
  };

  const addHoursOfOperationNote = async ({
    hoursOfOperationNotes,
  }: {
    hoursOfOperationNotes: string;
  }) => {
    await updateFacility({
      variables: {
        input: {
          clinicId,
          hoursOfOperationNotes,
        },
      },
    });
  };

  return {
    commands: {
      addEmployeesToClinic,
      addHoursOfOperationNote,
    },
  };
};

export default useFacilityDetailOperations;
