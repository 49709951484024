import React from 'react';
import { colorValues } from '@betterpt/better-components';
import { IcSuccess } from '@betterpt/better-icons';
import { Clinic } from '../../../../generated/graphql';

const SubscriptionColumn = ({
  clinic,
  isTelehealth = false,
}: {
  clinic?: Clinic | null;
  isTelehealth?: boolean;
}) => {
  const subActive = isTelehealth
    ? clinic?.isTelehealthActive
    : clinic?.betterPTSubscriptionActive;

  return (
    <div style={{ position: 'relative' }}>
      {subActive && (
        <IcSuccess
          color={colorValues.guajirogreen}
          style={{ position: 'absolute', left: -25 }}
        />
      )}
      <p
        style={{
          color: subActive ? colorValues.betterptblack : colorValues.cityblock,
        }}
      >
        {subActive ? `${isTelehealth ? 'Telehealth' : 'In-Person'} On` : 'Off'}
      </p>
    </div>
  );
};

export default SubscriptionColumn;
