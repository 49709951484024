import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='138'
    height='137'
    viewBox='0 0 138 137'
    {...rest}
  >
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <path
              d='M0 0H137V137H0z'
              transform='translate(-730 -272) translate(597 272) translate(133.5)'
            />
            <path
              fill='#333'
              d='M101.18 71.879L32.538 71.879 40.244 25.998 96.564 25.998z'
              transform='translate(-730 -272) translate(597 272) translate(133.5)'
            />
            <g>
              <path
                fill='#FF6B5A'
                d='M77.919 100.61v19.265H0v-19.266c0-17.955 12.15-33.077 28.684-37.58 1.122-.317 2.26-.574 3.425-.78 2.227-.394 4.513-.599 6.85-.599 2.946 0 5.806.325 8.563.942 1.164.265 2.303.574 3.425.942 5.934 1.91 11.251 5.214 15.558 9.53 7.055 7.047 11.414 16.79 11.414 27.545'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(29.113)'
              />
              <path
                fill='#D2E9F8'
                d='M51.375 62.078v2.252c0 4.461-2.624 8.308-6.412 10.084-1.433.671-3.032-4.373-4.72-4.373-1.536 0-3 5.109-4.333 4.545-3.994-1.69-6.797-5.645-6.797-10.256v-2.757c1.121-.317 2.26-.574 3.425-.78l15.412.342c1.164.267 2.303.574 3.425.943'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(29.113)'
              />
              <path
                fill='#2B4F83'
                fillRule='nonzero'
                d='M47.844 61.99l-15.24-.339c-.903.162-1.78.353-2.635.576v2.103c0 4.115 2.44 7.78 6.134 9.407.06-.05.138-.128.228-.236.26-.312.448-.607 1.05-1.607 1.221-2.033 1.78-2.71 2.863-2.71 1.103 0 1.73.678 3.018 2.597.668.995.866 1.273 1.148 1.572.114.12.21.202.277.244 3.533-1.696 5.832-5.273 5.832-9.267v-1.626c-.85-.262-1.736-.498-2.675-.715zm-3.127 11.625l.015.007-.007-.002-.008-.005zm7.514-11.537v2.252c0 4.697-2.726 8.9-6.904 10.86-.78.364-1.493.047-2.162-.661-.377-.4-.59-.698-1.325-1.794-.872-1.299-1.371-1.838-1.596-1.838-.138 0-.61.572-1.396 1.88-.658 1.094-.86 1.41-1.203 1.821-.62.744-1.288 1.107-2.068.777-4.4-1.861-7.32-6.184-7.32-11.045v-2.757c0-.384.254-.72.623-.824 1.135-.32 2.3-.585 3.509-.799l.167-.013 15.413.342.172.021c1.24.284 2.395.6 3.502.965.35.115.588.443.588.813z'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(29.113)'
              />
              <path
                fill='#A96648'
                d='M47.95 51.375v12.844c0 4.255-3.45 7.706-7.706 7.706s-7.706-3.45-7.706-7.706V51.375H47.95z'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(29.113)'
              />
              <path
                fill='#C37D5B'
                d='M68.072 31.253c0 8.04-3.254 15.318-8.528 20.584-5.266 5.275-12.544 8.529-20.585 8.529-16.08 0-29.112-13.032-29.112-29.113 0-.317.008-.625.017-.942.103-3.356.78-6.559 1.918-9.53.154-.402.317-.796.488-1.181 1.464-3.357 3.545-6.38 6.105-8.931C23.641 5.394 30.92 2.14 38.96 2.14c6.62 0 12.716 2.2 17.596 5.925.06.034.112.077.172.137.993.753 1.935 1.584 2.817 2.466 2.56 2.551 4.64 5.574 6.105 8.93 1.104 2.544 1.866 5.267 2.209 8.126.103.848.171 1.713.197 2.586.008.317.017.625.017.942'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(29.113)'
              />
              <path
                fill='#333'
                d='M29.113 40.166c-.946 0-1.713-.568-1.713-1.268v-2.22c0-.7.767-1.267 1.713-1.267.945 0 1.712.568 1.712 1.268v2.219c0 .7-.767 1.268-1.712 1.268M47.95 40.166c-.945 0-1.713-.568-1.713-1.268v-2.22c0-.7.768-1.267 1.713-1.267.945 0 1.712.568 1.712 1.268v2.219c0 .7-.767 1.268-1.712 1.268M39.586 0c6.22 0 12.114 3.35 15.03 5.416l.078-.112c1.342 1.048 2.614 2.204 3.806 3.431 3.457 3.55 6.266 7.755 8.244 12.425 1.492 3.539 2.521 7.327 2.984 11.306-3.613-1.368-6.81-3.046-9.468-4.965H49.33l-3.742-12.54-2.867 12.54H16.889c-2.658 1.919-5.856 3.597-9.468 4.965.463-3.979 1.491-7.767 2.984-11.306 1.977-4.67 4.787-8.875 8.245-12.425.547-.564 1.112-1.113 1.693-1.644l.213-.734C25.834 2.36 32.427 0 39.586 0z'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(29.113)'
              />
              <path
                fill='#D2E9F8'
                d='M41.742 88.622c0 1.063-.863 1.926-1.926 1.926-1.064 0-1.927-.863-1.927-1.926 0-1.064.863-1.927 1.927-1.927 1.063 0 1.926.863 1.926 1.927'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(29.113)'
              />
              <path
                fill='#2B4F83'
                fillRule='nonzero'
                d='M40.886 88.622c0-.59-.48-1.07-1.07-1.07-.59 0-1.07.48-1.07 1.07 0 .59.48 1.07 1.07 1.07.59 0 1.07-.48 1.07-1.07zm1.712 0c0 1.536-1.246 2.783-2.782 2.783-1.537 0-2.783-1.247-2.783-2.783 0-1.536 1.246-2.783 2.783-2.783 1.536 0 2.782 1.247 2.782 2.783z'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(29.113)'
              />
              <path
                fill='#D2E9F8'
                d='M41.742 101.252c0 1.063-.863 1.926-1.926 1.926-1.064 0-1.927-.863-1.927-1.926 0-1.064.863-1.927 1.927-1.927 1.063 0 1.926.863 1.926 1.927'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(29.113)'
              />
              <path
                fill='#2B4F83'
                fillRule='nonzero'
                d='M40.886 101.252c0-.591-.48-1.07-1.07-1.07-.59 0-1.07.479-1.07 1.07 0 .59.48 1.07 1.07 1.07.59 0 1.07-.48 1.07-1.07zm1.712 0c0 1.536-1.246 2.782-2.782 2.782-1.537 0-2.783-1.246-2.783-2.782 0-1.537 1.246-2.783 2.783-2.783 1.536 0 2.782 1.246 2.782 2.783z'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(29.113)'
              />
              <path
                fill='#D2E9F8'
                d='M41.742 113.881c0 1.064-.863 1.927-1.926 1.927-1.064 0-1.927-.863-1.927-1.927 0-1.063.863-1.926 1.927-1.926 1.063 0 1.926.863 1.926 1.926'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(29.113)'
              />
              <path
                fill='#2B4F83'
                fillRule='nonzero'
                d='M40.886 113.881c0-.59-.48-1.07-1.07-1.07-.59 0-1.07.48-1.07 1.07 0 .59.48 1.07 1.07 1.07.59 0 1.07-.48 1.07-1.07zm1.712 0c0 1.537-1.246 2.783-2.782 2.783-1.537 0-2.783-1.246-2.783-2.783 0-1.536 1.246-2.783 2.783-2.783 1.536 0 2.782 1.247 2.782 2.783z'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(29.113)'
              />
              <path
                fill='#C37D5B'
                d='M17.696 36.676c0 1.655-.67 3.154-1.756 4.238-1.084 1.085-2.582 1.756-4.238 1.756-3.31 0-5.994-2.684-5.994-5.994 0-.065.002-.128.004-.194.021-.691.16-1.35.394-1.962.032-.083.066-.163.101-.243.302-.691.73-1.314 1.257-1.84 1.084-1.085 2.583-1.755 4.238-1.755 1.363 0 2.618.453 3.623 1.22.013.007.023.016.035.029.204.155.398.325.58.507.527.525.955 1.148 1.256 1.839.228.523.385 1.084.456 1.672.021.175.035.353.04.533.002.066.004.13.004.194M71.925 36.676c0 1.655-.67 3.154-1.756 4.238-1.083 1.085-2.582 1.756-4.238 1.756-3.31 0-5.993-2.684-5.993-5.994 0-.065.001-.128.003-.194.021-.691.16-1.35.395-1.962.031-.083.065-.163.1-.243.302-.691.73-1.314 1.258-1.84 1.083-1.085 2.581-1.755 4.237-1.755 1.363 0 2.619.453 3.622 1.22.013.007.024.016.036.029.205.155.398.325.58.507.527.525.955 1.148 1.257 1.839.228.523.384 1.084.454 1.672.023.175.036.353.042.533l.003.194'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(29.113)'
              />
            </g>
            <g>
              <path
                fill='#294F83'
                d='M1.66 57.211v2.126c.002.351.671.758 1.498.758h108.421c.828 0 1.496-.407 1.498-.744v-2.14H1.66zm109.92 4.439H3.157C1.394 61.65.007 60.644 0 59.36v-3.704h114.737v3.681c-.007 1.307-1.395 2.313-3.158 2.313z'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(11.131 75.35)'
              />
              <path
                fill='#A3BBD8'
                d='M65.075 53.087c-.21 1.475-.975 2.57-1.886 2.57H51.55c-.912 0-1.676-1.095-1.886-2.57h15.412z'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(11.131 75.35)'
              />
              <path
                fill='#294F83'
                d='M50.812 53.947c.287.519.791.85 1.35.85h12.126c.559 0 1.063-.331 1.35-.85H50.812zm13.476 1.71H52.162c-1.134 0-2.113-.842-2.382-2.046l-.117-.523h17.124l-.117.523c-.27 1.204-1.249 2.045-2.382 2.045z'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(11.131 75.35)'
              />
              <path
                fill='#E5E5E4'
                d='M113.464 59.938H4.867c-1.267 0-2.298-.968-2.298-2.157 0-.216.187-.412.417-.412.23 0 .417.153.417.37v.042c0 .757.657 1.374 1.464 1.374h108.597c.23 0 .417.175.417.391 0 .216-.187.392-.417.392'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(11.131 75.35)'
              />
              <path
                fill='#F9F9FA'
                d='M99.33 59.938H14.55c-1.414 0-2.563-1.134-2.563-2.534V5.276c0-2.441 2.002-4.42 4.473-4.42h80.962c2.47 0 4.472 1.979 4.472 4.42v52.128c0 1.4-1.148 2.533-2.563 2.533'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(11.131 75.35)'
              />
              <path
                fill='#294F83'
                d='M16.39 1.673c-1.988 0-3.606 1.637-3.606 3.65v52.919c0 .957.769 1.735 1.714 1.735h84.029c.945 0 1.714-.778 1.714-1.735V5.322c0-2.012-1.617-3.649-3.606-3.649H16.391zM98.528 61.65H14.498c-1.856 0-3.367-1.53-3.367-3.408V5.322c0-2.935 2.36-5.322 5.26-5.322h80.244c2.9 0 5.259 2.387 5.259 5.323v52.919c0 1.879-1.51 3.408-3.367 3.408z'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(11.131 75.35)'
              />
              <path
                fill='#E5E5E4'
                d='M95.069 59.081h-76.14c-2.883 0-5.229-2.362-5.229-5.265V7.687c0-.463.372-.837.831-.837.46 0 .831.374.831.837v46.129c0 1.98 1.6 3.59 3.566 3.59h76.14c.46 0 .832.375.832.838 0 .463-.372.837-.831.837'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(11.131 75.35)'
              />
              <path
                fill='#E5E5E4'
                d='M63.362 30.825c0 3.31-2.683 5.994-5.993 5.994-3.31 0-5.994-2.684-5.994-5.994 0-3.31 2.684-5.994 5.994-5.994 3.31 0 5.993 2.684 5.993 5.994'
                transform='translate(-730 -272) translate(597 272) translate(133.5) translate(11.131 75.35)'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
