import React from 'react';

//types
import { DownloadType } from '../../../../../../generated/graphql';

//helpers
import { format, subMonths } from 'date-fns';
import { colorValues, bestTextColor } from '@betterpt/better-components';
import { possessiveName } from '../../../../../../helpers';

//hooks
import { Link } from 'react-router-dom';
import useHandleError from '../../../../../../hooks/useHandleError';
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';
import { useRequestCsvDownloadMutation } from '../../../../../../generated/graphql';

//components
import { Grid, GridCard } from '../../../../../Shared/GridCard';
import { Button } from '@betterpt/better-components';
import CSVDatePickerDialog from './components/CSVDatePickerDialog';
import SentCSVDialog from './components/SentCSVDialog';
import TimeCSVClock from './components/TimeCSVClock';

//assets
import { ImgAdminComputer } from '@betterpt/better-icons';

//styles
import { FormBody as CardBody } from '../../../EmbeddedAnalytics.style';
import {
  CSVBreadcrumb,
  CSVDisclaimer,
  CSVHeader,
  CSVPageBody,
  CSVParagraph,
  CSVRow,
} from './AnalyticsCSV.style';

const DownloadCSV = ({ type }: { type: DownloadType }) => {
  const handleError = useHandleError();
  const { primaryColor, facilitiesSingularAlias } = useRemoteConfigAliases();
  const [requestCSVDownload, { loading }] = useRequestCsvDownloadMutation();

  const [sentDialogOpen, toggleSentDialogOpen] = React.useState(false);
  const [datePickerOpen, toggleDatePickerOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState<string>('');
  const [endDate, setEndDate] = React.useState<string>('');
  const [quickSend, setQuickSend] = React.useState(false);

  const [isTimerActive, setTimerActive] = React.useState(false);

  const telehealth = type === DownloadType.Telehealth;

  const showTimer = isTimerActive;

  const sendCSVDownloadEmail = async (dates?: {
    startDate: Date;
    endDate: Date;
  }) => {
    setTimerActive(false);
    const start = dates ? dates.startDate : subMonths(new Date(), 3);
    const end = dates ? dates.endDate : new Date();

    try {
      await requestCSVDownload({
        variables: {
          input: {
            type,
            start: format(start, 'yyyy-MM-dd'),
            end: format(end, 'yyyy-MM-dd'),
          },
        },
      });
      setQuickSend(false);
      toggleDatePickerOpen(false);
      toggleSentDialogOpen(true);
      setTimerActive(true);
      setStartDate('');
      setEndDate('');
    } catch (e) {
      setQuickSend(false);
      handleError(e);
    }
  };

  return (
    <>
      {sentDialogOpen && (
        <SentCSVDialog
          open={sentDialogOpen}
          handleClose={() => toggleSentDialogOpen(false)}
          telehealth={telehealth}
        />
      )}
      {datePickerOpen && (
        <CSVDatePickerDialog
          open={datePickerOpen}
          handleClose={() => toggleDatePickerOpen(false)}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          sendCSVDownloadEmail={sendCSVDownloadEmail}
          loading={loading}
        />
      )}
      <Grid data-testid={`download-${type}-csv`}>
        <GridCard fullWidth>
          <CardBody>
            <CSVRow>
              <CSVRow>
                <Link to='/embedded-analytics/csv-download'>
                  <CSVBreadcrumb
                    className='button-or-link'
                    style={{ color: colorValues.frenchblue }}
                  >
                    Analytics Overview /
                  </CSVBreadcrumb>
                </Link>
                <CSVBreadcrumb>
                  Download {telehealth ? 'Telehealth' : 'In-Person'} CSV
                </CSVBreadcrumb>
              </CSVRow>

              {showTimer && <TimeCSVClock />}
            </CSVRow>
            <CSVPageBody>
              <ImgAdminComputer />
              <CSVHeader>
                Download {telehealth ? 'Telehealth' : 'In-Person'} Data
              </CSVHeader>
              <CSVParagraph>
                Recieve an email with a CSV file of your{' '}
                {possessiveName(facilitiesSingularAlias.toLowerCase())} data.
                You can either choose a specific period of time or download the
                last three months of appointment data.
              </CSVParagraph>
              <Button
                size='large'
                style={{
                  backgroundColor: primaryColor,
                  color: bestTextColor(primaryColor),
                  width: 449,
                  marginBottom: 18,
                }}
                loading={loading && quickSend}
                onClick={() => {
                  setQuickSend(true);
                  sendCSVDownloadEmail();
                }}
              >
                DOWNLOAD DATA FROM LAST THREE MONTHS
              </Button>
              <Button
                size='large'
                color='white'
                style={{ width: 449 }}
                onClick={() => toggleDatePickerOpen(true)}
              >
                CHOOSE A SPECIFIC PERIOD
              </Button>
              <CSVDisclaimer>
                For security reasons, you will have a ten minute window to
                download this file. You will also not be allowed to download
                more than three months’ data at a time.
              </CSVDisclaimer>
            </CSVPageBody>
          </CardBody>
        </GridCard>
      </Grid>
    </>
  );
};

export default DownloadCSV;
