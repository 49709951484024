import * as React from 'react';
import { Button } from '@betterpt/better-components';
import { useHistory } from 'react-router-dom';

export const pageNotFound = {
  height: '100vh',
  width: '90vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column' as 'column',
  textAlign: 'center' as 'center',
};

const ErrorPage: React.FC<React.PropsWithChildren<{ message: string }>> = ({ message }) => {
  const history = useHistory();
  return (
    <div style={pageNotFound}>
      <h1 className='H1'>{message}</h1>
      <Button
        style={{ maxWidth: '400px' }}
        size='large'
        fullWidth
        onClick={() => history.push('/')}
      >
        GO HOME
      </Button>
    </div>
  );
};

export default ErrorPage;
