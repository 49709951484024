import { colorValues, bestTextColor } from '@betterpt/better-components';
import styled from '@emotion/styled';

export const InPersonAppointmentContainer = styled.div`
  margin: 31px 33px;
  position: relative;
  width: 90%;
`;

export const NoAppointmentsFoundContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  height: 70vh;
  background-color: ${colorValues.earlysteam};
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    margin: 41px auto;
  }
  .inner-container {
    max-width: 476px;
    text-align: center;
  }
  p {
    text-align: left;
  }
  svg {
    height: 98px;
    width: 98px;
  }
`;

export const addAppointmentsButton = (color: string) => ({
  backgroundColor: color,
  color: bestTextColor(color),
  width: 282,
  fontWeight: 'bold' as 'bold',
});
