import React from 'react';

//hooks
import useSnackbar from '../../../../../hooks/useSnackbar';

//components
import Dialog from '@material-ui/core/Dialog';
import ConfirmContactPatient from './components/ConfirmContactPatient';
import SlideTransition from '../../../../Shared/SlideTransition';
import useHandleError from '../../../../../hooks/useHandleError';

interface ContactPatientInput {
  appointmentId: string;
}

type Props = {
  open: boolean;
  onClose: () => void;
  appointmentId?: string;
  contactPatient: ({ appointmentId }: ContactPatientInput) => Promise<void>;
};

const MarkPatientContactedDialog = ({
  open,
  onClose,
  appointmentId = '',
  contactPatient,
}: Props) => {
  const snackbar = useSnackbar();
  const handleError = useHandleError();

  const [saving, updateSaving] = React.useState(false);

  const handleContactPatient = async () => {
    updateSaving(true);
    try {
      await contactPatient({ appointmentId });
      snackbar?.setSuccessMessage('Patient marked as contacted');
      updateSaving(false);
      onClose()
    } catch (e) {
      updateSaving(false);
      handleError(e);
    }
  };

  return (
    <Dialog
      TransitionComponent={SlideTransition}
      open={open}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
    >
      <ConfirmContactPatient
        contactPatient={handleContactPatient}
        closeDialog={onClose}
        saving={saving}
      />
    </Dialog>
  );
};

export default MarkPatientContactedDialog;
