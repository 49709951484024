import React from 'react';
import { useHistory, useParams } from 'react-router';
import styled from '@emotion/styled';
import { Button, colorValues } from '@betterpt/better-components';
import { Dialog } from '@material-ui/core';
import useHandleError from '../../../../../../hooks/useHandleError';

const DialogBody = styled.div`
  padding: 52px;
`;

const buttonStyle = {
  border: `solid 2px ${colorValues.orangepink}`,
  backgroundColor: colorValues.emptiness,
  color: colorValues.orangepink,
  width: '376px',
};

const dialogButtonStyle = {
  marginTop: '20px',
};

const closeDialogButtonStyle = {
  ...dialogButtonStyle,
  color: colorValues.betterptblack,
};

interface Props {
  openSnackbar?(payload: { isError: boolean; message: string }): void;
  archiveEmployee(payload: { employeeId: string }): Promise<void>;
}

const ArchiveEmployee: React.FC<React.PropsWithChildren<Props>> = ({
  openSnackbar,
  archiveEmployee,
}) => {
  const history = useHistory();
  const { employeeId } = useParams<{ employeeId: string }>();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isSaving, updateIsSaving] = React.useState(false);
  const handleError = useHandleError();
  const closeDialog = () => setIsDialogOpen(false);
  const openDialog = () => setIsDialogOpen(true);

  const handleArchiveSubmit = async () => {
    try {
      updateIsSaving(true);
      await archiveEmployee({ employeeId });
      updateIsSaving(false);
      openSnackbar?.({ message: 'User successfully archived', isError: false });
      closeDialog();
      history.push('/employees');
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <>
      <Dialog open={isDialogOpen} onClose={closeDialog} maxWidth='sm'>
        <DialogBody>
          <h2 className='Hero'>
            Are you sure you want to archive this user? This cannot be undone.
          </h2>
          <p className='Body'>
            Archiving this user will make them inactive on BetterAccess. This
            means that they will not be able to use the platform or be scheduled
            for appointments.
          </p>
          <Button
            style={dialogButtonStyle}
            size='large'
            color='red'
            onClick={handleArchiveSubmit}
            loading={isSaving}
            fullWidth
          >
            ARCHIVE THIS USER
          </Button>
          <Button
            style={closeDialogButtonStyle}
            size='large'
            color='transparent'
            onClick={closeDialog}
            fullWidth
          >
            NEVER MIND
          </Button>
        </DialogBody>
      </Dialog>
      <Button size='large' onClick={openDialog} style={buttonStyle}>
        ARCHIVE THIS USER
      </Button>
    </>
  );
};

export default ArchiveEmployee;
