import styled from '@emotion/styled';
import { colorValues, bestTextColor } from '@betterpt/better-components';

export const Navbar = styled.div`
  height: 68px;
  background-color: ${(props) => props.color};
  width: 100vw;
  display: flex;
  align-items: center;
  svg {
    height: 28px;
    width: 28px;
    margin-left: 44px;
    margin-right: 80px;
  }
  h1 {
    color: ${colorValues.emptiness};
  }
`;

export const BodySection = styled.div`
  width: 935px;
  margin: 8vh auto 0px auto;
  position: relative;
  min-height: 400px;
`;

export const Numbers = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  border-bottom: 1px solid ${colorValues.dust};
`;

export const numberStyle = (activeStep: boolean) => ({
  height: '38px',
  width: '38px',
  margin: '14px',
  opacity: activeStep ? '1' : '0.2',
});

export const nextbuttonStyle = (color?: string) => ({
  backgroundColor: color,
  color: bestTextColor(color),
  position: 'absolute' as 'absolute',
  right: '12px',
  top: '12px',
  width: '200px',
});

export const lowerNextButtonStyle = {
  position: 'absolute' as 'absolute',
  bottom: 0,
  right: 0,
  color: colorValues.cityblock,
};

export const previousButtonStyle = {
  color: colorValues.cityblock,
  position: 'absolute' as 'absolute',
  left: '0px',
  bottom: '12px',
  padding: '6px 16px 6px 7px',
};
