import style from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

export const Container = style.div`
  display: flex;
  height: 100vh;
`;

export const FormSection = style.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 40%;
  form {
    .signup-link {
      text-decoration: none !important;
      color: ${colorValues.betterptblack};
      p {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .forgot-password {
      text-decoration: none !important;
      color: ${colorValues.betterptblack};
    }
  }
`;

export const LogoContainer = style.div`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  background-color: ${(props) => props.color};
  svg {
    height: 48px;
    width: 48px;
  }
`;

export const ImageLogo = style.img<{ wideLogo: boolean }>`
  height: auto;
  width: ${({ wideLogo }) => (wideLogo ? '250px' : '60px')};
`;

export const DemoSection = style.div`
  width: 60%;
  background-color: ${colorValues.earlysmoke};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  h2 {
    color: ${colorValues.betterptblack}
  }
  p {
    color: ${colorValues.betterptblack}
  }
  img {
    max-width: 736px;
    width: 80%;
    height: auto;
  }
  .copy-and-link {
    text-align: center;
    margin-top: 10vh;
    margin-bottom: 7vh;
    align-self: center;
  }
`;

export const demoButtonStyle = {
  width: '278px',
  height: '48px',
  boxShadow: '0 2px 10px 0 rgba(51, 51, 51, 0.24)',
  backgroundColor: colorValues.emptiness,
  color: colorValues.betterptblack,
  fontSize: '16px',
  margin: '12px 0px',
};

export const NewUpdatesCopy = style.a`
  position: relative;
  bottom: -40px;
  white-space: nowrap;
  p {
    border-bottom: 1px solid;
    &:hover {
      border-bottom: 1px dotted;
    }
    &:focus {
      border-bottom: 1px dotted;
    }
  }
`;

export const PoweredByCopy = style.div`
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 32px;
    width: 32px;
    vertical-align: bottom;
    margin-right: 20px;
    
  }
`;

export const InnerFormSection = style.div`
  width: 300px;
  margin: 10vh auto 20px;
`;

export const WatchTutorialCopy = style.h3`
  a {
    color: ${colorValues.frenchblue};
  }
`;
