import { TextFieldState } from '../hooks/useTextFieldState';
import { format, isValid, parse } from 'date-fns';

export const normalizePhone = (value: string) => {
  if (!value) {
    return value;
  }
  const clippedNums = value.charAt(0) === '1' ? value.slice(1) : value;
  const onlyNums = clippedNums.replace(/[^\d]/g, '');

  return onlyNums.replace(/(\d{1,3})?(\d{1,3})?(\d{1,4})?/, function(
    _,
    p1,
    p2,
    p3
  ) {
    let output = '';
    if (p1) output = `${p1}`;
    if (p2) output += `-${p2}`;
    if (p3) output += `-${p3}`;
    return output;
  });
};

export const normalizePhoneForSMSDisplay = (phone?: string | null) => {
  if (!phone) return '';
  const countryCode = phone.slice(phone.indexOf('+'), 2);
  const number = phone.slice(phone.indexOf('+') + 2);
  return `${countryCode ?? ''}${normalizePhone(number)}`;
};

export const formatPhoneForBackend = (s?: string | null) => {
  if (!s) return '';
  return s?.toString()?.replace(/\D/g, '');
};

export const normalizeMonthYearDate = (value: string) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length >= 4) return value.slice(0, 7).replace(/[^\d\/]/g, '');
  return onlyNums.replace(/(\d{1,2})?(\d{1,2})?/, function(_, p1, p2) {
    let output = '';
    if (p1) output = `${p1}`;
    if (p2) output += `/${p2}`;
    return output;
  });
  // const onlyNums = value.replace(/[^\d]/g, '');
  // if (value.length > 1 && value[value.length - 1] !== '/') {
  //   // typing forward
  //   if (onlyNums.length === 2) {
  //     return `${onlyNums}/`;
  //   }
  //   if (onlyNums.length === 7) {
  //     return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 6)}`;
  //   }
  // }
  // if (onlyNums.length <= 2) {
  //   return onlyNums;
  // }
  // if (onlyNums.length <= 7) {
  //   return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`;
  // }
  // return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 6)}`;
};

export const normalizeCcNumber = (value: string) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (value.length > 1 && value[value.length - 1] !== ' ') {
    // typing forward
    if (onlyNums.length === 4) {
      return `${onlyNums} `;
    }
    if (onlyNums.length === 8) {
      return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)}`;
    }
    if (onlyNums.length === 12) {
      return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
        8,
        12
      )}`;
    }
    if (onlyNums.length === 16) {
      return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
        8,
        12
      )} ${onlyNums.slice(12, 16)}`;
    }
    if (onlyNums.length === 20) {
      return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
        8,
        12
      )} ${onlyNums.slice(12, 16)} ${onlyNums.slice(16, 20)}`;
    }
  }
  if (onlyNums.length <= 4) {
    return onlyNums;
  }
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4)}`;
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
      8
    )}`;
  }
  if (onlyNums.length <= 16) {
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
      8,
      12
    )} ${onlyNums.slice(12)}`;
  }
  if (onlyNums.length <= 20) {
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
      8,
      12
    )} ${onlyNums.slice(12, 16)} ${onlyNums.slice(16)}`;
  }
  return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
    8,
    12
  )} ${onlyNums.slice(12, 16)} ${onlyNums.slice(16, 20)}`;
};

export const normalizeDate = (value: string) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length >= 6) return value.slice(0, 10).replace(/[^\d\/]/g, '');
  return onlyNums.replace(/(\d{1,2})(\d{1,2})?(\d{1,2})?/, function(
    _,
    p1,
    p2,
    p3
  ) {
    let output = '';
    if (p1) output = `${p1}`;
    if (p2) output += `/${p2}`;
    if (p3) output += `/${p3}`;
    return output;
  });

  //
  // if (value.length > 1 && value[value.length - 1] !== '/') {
  //   // typing forward
  //   if (onlyNums.length === 2) {
  //     return `${onlyNums}/`;
  //   }
  //   if (onlyNums.length === 4) {
  //     return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/`;
  //   }
  //   if (onlyNums.length === 8) {
  //     return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
  //       4,
  //       8
  //     )}`;
  //   }
  // }
  // if (onlyNums.length <= 2) {
  //   return onlyNums;
  // }
  // if (onlyNums.length <= 4) {
  //   return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;
  // }
  // if (onlyNums.length <= 8) {
  //   return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
  //     4
  //   )}`;
  // }

  // return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
  //   4,
  //   8
  // )}`;
};

export const normalizeZipcode = (value: string) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');

  return onlyNums.slice(0, 5);
};

export const normalizeOnlyNumbers = (value: string) => {
  if (!value) {
    return value;
  }
  return value.replace(/[^\d]/g, '');
};

export const normalizeDollars = (number?: number | null) => {
  if (!number) return '$0.00';

  return (number / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const normalizeMillisecondTime = (milliseconds?: number) => {
  if (!milliseconds) return '0:00';

  const minutes = Math.floor(milliseconds / 60000);
  const seconds = Math.floor((milliseconds % 60000) / 1000);
  const sixtySeconds = seconds === 60;
  const accountForOneDigitSeconds =
    (seconds < 10 ? '0' : '') + seconds.toFixed(0);

  const formattedMinutes = (sixtySeconds ? minutes + 1 : minutes).toString();
  const formattedSeconds = sixtySeconds ? '00' : accountForOneDigitSeconds;

  return `${formattedMinutes}:${formattedSeconds}`;
};

export const formatVideoCallDuration = (duration?: number | null) => {
  if (duration === undefined || duration === null) return '';

  if (duration < 60) return `${duration} second${duration > 1 ? 's' : ''}`;

  const totalMinutes = Math.ceil(duration / 60);
  const hourCount = totalMinutes > 60 ? Math.floor(totalMinutes / 60) : 0;
  const minCount = totalMinutes > 60 ? totalMinutes % 60 : totalMinutes;

  const hrUnit = ` hr${hourCount > 1 ? 's' : ''}`;
  const minUnit = ` min${minCount > 1 ? 's' : ''}`;

  return `${!!hourCount ? hourCount + hrUnit : ''} ${
    !!minCount ? minCount + minUnit : ''
  }`;
};

export const formatYearsExperience: (
  yearsExperience?: TextFieldState
) => string | null = (yearsExperience?: TextFieldState) => {
  if (!yearsExperience?.value?.length) return null;
  if (isValid(parse(yearsExperience?.value, 'MM/yyyy', new Date()))) {
    return format(
      parse(yearsExperience?.value, 'MM/yyyy', new Date()),
      'yyyy-MM-dd'
    );
  } else return null;
};
