import React from 'react';

//hooks
import { useMeQuery } from '../../../generated/graphql';
import useProfileOperations from './hooks/useProfileOperations';
import useSnackbar from '../../../hooks/useSnackbar';

//components
import UserInfo from './components/UserInfo';
import ProfileHeader from './components/ProfileHeader';
import InPersonAppointmentsList from './components/InPersonAppointmentsList';
import FacilitiesList from './components/FacilitiesList';
import LoginContactInformation from './components/LoginContactInformation';
import RolesAndPermissions from './components/RolesAndPermissions';
import ProviderDetails from './components/ProviderDetails';
import AppointmentTypes from '../../Shared/AppointmentTypes';
import { Grid, GridRow, MultiCardGrid } from '../../Shared/GridCard';

//style
import { Page } from './styles/Profile.style';

const Profile = () => {
  const snackbar = useSnackbar();
  const meQuery = useMeQuery({
    onError: () => snackbar?.useGenericErrorMessage(),
  });
  const company = meQuery.data?.me?.company;
  const isNotWhiteLabel = company?.providerAppId !== 'acorn' && company?.providerAppId !== 'somatus';

  const {
    commands: { updateUserInfo, updateLoginContactInfo, updateProviderDetails },
  } = useProfileOperations();

  return (
    <Page>
      <ProfileHeader />
      <Grid>
        <GridRow>
          <MultiCardGrid>
            <UserInfo updateUserInfo={updateUserInfo} />
            <RolesAndPermissions />
          </MultiCardGrid>
          <MultiCardGrid>
            <LoginContactInformation updateLoginContactInfo={updateLoginContactInfo} />
            <ProviderDetails updateProviderDetails={updateProviderDetails} />
          </MultiCardGrid>
        </GridRow>
        {isNotWhiteLabel && <AppointmentTypes fullWidth profileId={meQuery.data?.me?.id} isOnProfileScreen />}
        {isNotWhiteLabel && <InPersonAppointmentsList />}
        <FacilitiesList />
      </Grid>
    </Page>
  );
};

export default Profile;
