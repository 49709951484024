/* eslint-disable @typescript-eslint/ban-types */
import * as React from 'react';

import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import { VerticalNavigation, Animation } from '@betterpt/better-components';

// context
import { SnackbarProvider } from '../../context/snackbarMessage';
import { PatientFiltersProvider } from '../../context/patientFilters';
import EmbeddedAnalytics from '../Views/EmbeddedAnalytics';
import Analytics from '../Views/Analytics';
// hooks
import useRemoteConfig from '../../hooks/useRemoteConfig';
import useProfileCompletionCheck from './hooks/useProfileCompletionCheck';
import useMeQueryWithLDIdentify from './hooks/useMeQueryWithLDIdentify';
import useTotalNotificationsCount from '../../hooks/useTotalNotificationsCount';

// views
import Appointments from '../Views/Appointments';
import Company from '../Views/Company';
import Employees from '../Views/Employees';
import Facilities from '../Views/Facilities';
import Logout from '../Logout';
import NotFoundPage from '../NotFoundPage';
import Profile from '../Views/Profile';
import Notifications from '../Views/Notifications';

// components
import CongratsDialog from '../Shared/CongratsDialog';
import NavLogoSection from './NavLogoSection';
import Snackbar from '../Shared/Snackbar';
import NotificationCounter from '../Shared/NotificationCounter';

// graphics
import {
  IcDateRange,
  IcProfile,
  IcBusiness,
  IcFace,
  IcAlert,
  IcClipboard,
  IcTrendingUp,
  // IcClipboard,
} from '@betterpt/better-icons';
// styles
import { colorValues } from '@betterpt/better-components';
import { Loader } from './Home.style';
import Patients from '../Views/Patients';
import useRemoteConfigAliases from '../../hooks/useRemoteConfigAliases';
import updateFavicon from '../../helpers/updateFavicon';
import useFeatureFlagWrapper from '../../hooks/useFeatureFlagWrapper';
import Flags from '../../constants/flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AnalyticsCSV from '../Views/Company/AnalyticsTab/components/AnalyticsCSV';
import AnalyticsCSVTab from '../Views/EmbeddedAnalytics/AnalyticsCSVTab/AnalyticsCSVTab';

/**
 * ! NOTE: For conditional rendering based on feature flah:
 * component: () => isEmbeddedAnalyticsToggledOn ? <EmbeddedAnalytics /> : <Redirect to="/analytics" />
 */
type Props = RouteComponentProps<{}, {}, { congratsDialogOpen?: boolean; congratsDialogText?: string }>;

const Home = ({ location }: Props) => {
  const remoteConfig = useRemoteConfig();
  const organizationName = remoteConfig?.info?.getValue('organizationName').asString();

  const {
    employeePluralAlias,
    facilitiesPluralAlias,
    patientsPluralAlias,
    primaryColor,
    loading: remoteConfigLoading,
  } = useRemoteConfigAliases();

  const { loading } = useProfileCompletionCheck();
  const meQuery = useMeQueryWithLDIdentify();

  const companyUseTableau = meQuery?.data?.me?.company?.isUsingTableau;
  const employeeUseTableau = meQuery?.data?.me?.isUsingTableau;

  const isUsingTableau = companyUseTableau && employeeUseTableau;

  const notificationCount = useTotalNotificationsCount();

  const featureWrapper = useFeatureFlagWrapper();

  const isEmbeddedAnalyticsToggledOn = featureWrapper<boolean>(true, Flags.EmbeddedAnalytics);

  React.useEffect(() => {
    const determineFaviconType = () => {
      if (meQuery?.data?.me?.company?.providerAppId === 'acorn') {
        return 'acorn';
      } else if (meQuery?.data?.me?.company?.providerAppId === 'somatus') {
        return 'somatus';
      } else {
        return 'betteraccess';
      }
    };
    updateFavicon({
      faviconType: determineFaviconType(),
    });
  }, [meQuery.data]);

  if (loading || remoteConfigLoading) {
    return (
      <Loader>
        <Animation type="providerAppLoader" />
      </Loader>
    );
  }

  return (
    <div style={{ backgroundColor: colorValues.earlysmoke }}>
      <PatientFiltersProvider>
        <SnackbarProvider>
          <Snackbar />
          {!!location?.state?.congratsDialogOpen && <CongratsDialog open={true} text={location?.state?.congratsDialogText || ''} />}
          <VerticalNavigation
            fixed
            logoSectionAsRoute={{
              componentOverride: ({ isMainNavOpen }) => <NavLogoSection isMainNavOpen={isMainNavOpen} />,
              title: organizationName,
              route: {
                path: '/company',
                component: () => <Company />,
              },
            }}
            hideFooterLogo
            catchAllView={<NotFoundPage />}
            primaryColor={primaryColor}
            routes={{
              header: [
                {
                  path: '/',
                  exact: true,
                  hideTab: true,
                  display: '',
                  icon: <div />,
                  component: () => <Redirect to="/company" />,
                },
                {
                  path: '/company',
                  hideTab: true,
                  display: '',
                  icon: <div />,
                  component: () => <Company />,
                },
                {
                  path: '/notifications',
                  display: 'Notifications',
                  icon: (
                    <>
                      <IcAlert color={colorValues.betterptblack} height="24" width="24" />
                      {notificationCount > 0 && <NotificationCounter notificationCount={notificationCount} />}
                    </>
                  ),
                  component: () => <Notifications />,
                },
                {
                  path: '/appointments',
                  exact: false,
                  display: 'Appointments',
                  icon: <IcDateRange style={{ height: 24, width: 24 }} color={colorValues.betterptblack} />,
                  component: () => <Appointments />,
                },
                {
                  path: '/facilities',
                  exact: false,
                  display: facilitiesPluralAlias || 'Facilities',
                  icon: <IcBusiness color={colorValues.betterptblack} />,
                  component: () => <Facilities />,
                },
                {
                  path: '/employees',
                  exact: false,
                  display: employeePluralAlias || 'Employees',
                  icon: <IcProfile color={colorValues.betterptblack} />,
                  component: () => <Employees />,
                },
                {
                  path: '/patients',
                  exact: false,
                  display: patientsPluralAlias || 'Patients',
                  icon: <IcFace color={colorValues.betterptblack} height="24" width="24" />,
                  component: () => <Patients />,
                },
                {
                  path: isUsingTableau ? '/embedded-analytics/campaign' : '/embedded-analytics/csv-download',
                  exact: false,
                  display: 'Analytics',
                  icon: <IcTrendingUp color={colorValues.betterptblack} height="24" width="24" />,
                  component: () => <EmbeddedAnalytics />,
                },
                {
                  path: '/embedded-analytics/csv-download',
                  hideTab: true,
                  display: 'Analytics',
                  icon: <IcClipboard />,
                  component: () => <EmbeddedAnalytics />,
                },
                {
                  path: '/embedded-analytics/in-person-csv',
                  hideTab: true,
                  display: 'Analytics',
                  icon: <IcClipboard />,
                  component: () => <EmbeddedAnalytics />,
                },
              ],
              footer: [
                {
                  display: "What's new?",
                  icon: <IcClipboard color={colorValues.betterptblack} height="24" width="24" />,
                  id: 'notificationButton',
                  component: () => <div id="notificationButton">What&apos;s new?</div>,
                },
                {
                  path: '/profile',
                  display: 'Profile',
                  icon: <IcProfile />,
                  component: () => <Profile />,
                },
                {
                  path: '/embedded-analytics/appointments-modification',
                  hideTab: true,
                  display: 'Analytics',
                  icon: <IcClipboard />,
                  component: () => <EmbeddedAnalytics />,
                },
                {
                  path: '/embedded-analytics/campaign',
                  hideTab: true,
                  display: 'Analytics',
                  icon: <IcClipboard />,
                  component: () => <EmbeddedAnalytics />,
                },
                {
                  path: '/embedded-analytics/appointments-volume',
                  hideTab: true,
                  display: 'Analytics',
                  icon: <IcClipboard />,
                  component: () => <EmbeddedAnalytics />,
                },

                {
                  path: '/logout',
                  hideTab: true,
                  display: '',
                  icon: <div />,
                  component: () => <Logout />,
                },
              ],
            }}
          />
        </SnackbarProvider>
      </PatientFiltersProvider>
    </div>
  );
};

export default withRouter(Home);
