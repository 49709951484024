import styled from '@emotion/styled';
import { bestTextColor } from '@betterpt/better-components';

export const NavSection = styled.div<{ primaryColor?: string }>`
  background-color: ${({ primaryColor }) => primaryColor};
  height: 68px;
  display: flex;
  align-items: center;
  button {
    background: none;
    border: none;
  }
  h4 {
    color: ${({ primaryColor }) => bestTextColor(primaryColor)};
  }
`;
