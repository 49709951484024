import * as React from 'react';

//hooks
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';

//components
import BlockedPageMessage from '../../../Shared/BlockedPageMessage';

//assets
import { ImgGirlBlueDressPhone } from '@betterpt/better-icons';

const NoEmployeesFound = ({ resetFilters }: { resetFilters?: () => void }) => {
  const {
    employeeSingularAlias,
    employeePluralAlias,
    loading,
  } = useRemoteConfigAliases();

  return loading ? null : (
    <BlockedPageMessage
      icon={<ImgGirlBlueDressPhone />}
      title={`No ${employeePluralAlias.toLowerCase()} found matching these filters`}
      message={'Try resetting your filters by clicking the button below'}
      buttonText={`RESET ${employeeSingularAlias.toUpperCase()} FILTERS`}
      onClick={resetFilters}
      style={{ paddingBottom: 50 }}
      wideText
    />
  );
};

export default NoEmployeesFound;
