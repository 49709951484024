import * as React from 'react';

export default ({ opacity, color, ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='10'
    height='5'
    viewBox='0 0 10 5'
    {...rest}
  >
    <g fill='none' fillRule='evenodd' opacity={opacity || '.38'}>
      <path fill={color || '#333'} fillRule='nonzero' d='M10 5L5 0 0 5z' />
      <path d='M17 15H-7V-9h24z' />
    </g>
  </svg>
);
