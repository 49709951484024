import BetterHealthcareFavicon from '../assets/favicons/logo_bhc.png';
import AcornFavicon from '../assets/favicons/logo_acorn.png';
import SomatusIcon from '../assets/favicons/logo_somatus.png';

type Favicon = 'betteraccess' | 'somatus' | 'acorn';

const updateFavicon = ({ faviconType }: { faviconType: Favicon }) => {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    (link as any).rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  switch (faviconType) {
    case 'acorn':
      (link as any).href = AcornFavicon;
      break;
    case 'somatus':
      (link as any).href = SomatusIcon;
      break;
    case 'betteraccess':
      (link as any).href = BetterHealthcareFavicon;
      break;
    default:
      (link as any).href = BetterHealthcareFavicon;
      break;
  }
};

export default updateFavicon;
