import React, { useState } from 'react';
import styled from '@emotion/styled';

//component and styles
import {
  IcArrowUp,
  IcArrowDown,
  IcAdd,
  IcRemove,
} from '@betterpt/better-icons';
import { colorValues } from '@betterpt/better-components';
import CustomFormInputNew from './CustomFormInputNew';
import CustomOptionsErrorField from './CustomOptionsErrorField';


//types
import { Option } from './CustomTypes';

const EditButton = styled.button<{ color: string }>`
  border: none;
  background-color: transparent;
  border: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: -7px;
  top: 8px;
`;

const AnswerContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
  margin-left: 20px;
`;

const AddAnotherContainer = styled.div`
  border-bottom: 1px solid ${colorValues.coneyislandsidewalk};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-left: 20px;
`;

const AddAnother = styled.p`
  color: ${colorValues.frenchblue};
  font-size: 14px;
  border-bottom: 1px solid ${colorValues.frenchblue};
  margin: 0px;
`;

type Props = {
  options: Option[];
  updateQuestion: any;
  questionId: number;
}



const MultipleChoiceAnswersTemplateNew = (props: Props) => {

  let { options, updateQuestion, questionId } = props;

  const setOptionStatement = (optionId: number, key: string, value: string) => {
    let newOptions = options.map(option => option.id === optionId ? option = { ...option, [key]: value } : option)
    updateQuestion(questionId, "options", newOptions);
  }

  const deleteOption = (element: Option) => {
    let newOptions = options.filter((option: Option) => option.id !== element.id);
    updateQuestion(questionId, "options", newOptions);
  }

  const addOption = () => {
    let newOptions = [...options, { id: options.length > 0 ? options[options.length - 1].id + 1 : options.length + 1, optionStatement: '' }];
    updateQuestion(questionId, "options", newOptions);
  }

  return (
    <div style={{ maxWidth: '500px', margin: '0 auto' }}>
      {options.length > 0 &&
        options.map((option: Option) => (
          <AnswerContainer key={option.id}>
            <CustomOptionsErrorField error={option.error}>
              <CustomFormInputNew
                value={option.optionStatement}
                elementId={option.id}
                elementKey="optionStatement"
                onChange={setOptionStatement}
                label="Answer"
              />
              <ButtonContainer>
                {/* <EditButton
                title='Move answer up'
                color={colorValues.ibizateal}
                onClick={element.onReorderClick(
                  element.index,
                  element.index - 1
                )}
                disabled={!element.hasMoveUp}
              >
                <IcArrowUp
                  opacity={1}
                  height='12px'
                  width='12px'
                  color={
                    element.hasMoveUp ? colorValues.cityblock : colorValues.dust
                  }
                />
              </EditButton>
              <EditButton
                title='Move answer down'
                color={colorValues.ibizateal}
                onClick={element.onReorderClick(
                  element.index,
                  element.index + 1
                )}
                disabled={!element.hasMoveDown}
              >
                <IcArrowDown
                  opacity={1}
                  height='12px'
                  width='12px'
                  color={
                    element.hasMoveDown
                      ? colorValues.cityblock
                      : colorValues.dust
                  }
                />
              </EditButton> */}
                <EditButton
                  title='Delete answer'
                  color={colorValues.emptiness}
                  onClick={() => deleteOption(option)}
                >
                  <IcRemove color={colorValues.orangepink} />
                </EditButton>
              </ButtonContainer>
            </CustomOptionsErrorField>
          </AnswerContainer>
        ))
      }

      <AddAnotherContainer
        onClick={addOption}
        onKeyDown={(e) => e.key === 'Enter' && addOption()}
        role='button'
        tabIndex={0}
        id='clickToAddResponse'
        aria-label='Click to add another user response'
      >
        <AddAnother>
          Click here to add another answer to this question
        </AddAnother>
        <IcAdd />
      </AddAnotherContainer>
    </div >
  );
};

export default MultipleChoiceAnswersTemplateNew;
