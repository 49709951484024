import styled from '@emotion/styled';
import { bestTextColor, colorValues } from '@betterpt/better-components';

// styles
export const DialogBody = styled.div`
  position: relative;
  padding: 47px 45px 36px 48px;
  margin: 5px;
`;

export const StaffTableContainer = styled.div`
  border: 1px solid ${colorValues.concretejungle};
  border-radius: 5px;
  margin: 10px 0px;
`;

export const StaffTable = styled.div`
  overflow: auto;
  height: 250px;
`;

export const StaffMemberLabel = styled.label`
  display: block;
`;

export const generateButtonStyle = (color: string) => ({
  backgroundColor: color,
  color: bestTextColor(color),
  margin: '10px 0px',
});

export const searchTextStyle = {
  padding: '0px 15px',
};
