import React from 'react';

export default ({ ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='135'
    height='135'
    viewBox='0 0 135 135'
    {...rest}
  >
    <g fill='none' fillRule='evenodd'>
      <g>
        <g>
          <g>
            <g>
              <g transform='translate(-732 -278) translate(597 272) translate(0 6.218) translate(135.5) translate(28.475)'>
                <path
                  fill='#FFCC46'
                  fillRule='nonzero'
                  d='M9.213 18.425L66.162 27.298 66.162 67 9.213 67z'
                />
                <path
                  fill='#4A79B5'
                  d='M35 71.196l2.512 2.504c-.444.293-.78.77-.905 1.424-.017.084-.025.168-.033.251l-.461 8.844-4.564-11.155c.1.067.2.117.301.168.21.1.419.2.637.292.578.243 1.515-.938 2.512-2.328zm6.507 11.139l-.503-6.96c-.008-.083-.025-.168-.042-.251l-.008-.05c-.151-.712-.553-1.198-1.064-1.474l2.278-2.278c.921 1.172 1.784 2.177 2.404 2.295l-3.066 8.718zm23.542-12.546c-.218-.217-.436-.426-.654-.628-.31-.302-.628-.595-.946-.879-.1-.093-.202-.185-.301-.26-.218-.21-.453-.402-.679-.587-.269-.226-.536-.443-.812-.653-.52-.418-1.047-.812-1.591-1.197-3.024-2.144-6.39-3.844-9.975-5.034-.084-.025-.176-.058-.26-.084-.067-.025-.134-.04-.2-.066-.118-.034-.235-.075-.36-.11-.914-.283-1.843-.526-2.79-.745-.142-.033-.285-.058-.427-.092-2.563-.544-5.218-.829-7.948-.829-2.286 0-4.522.201-6.7.586-.134.026-.276.05-.41.076-.997.184-1.977.41-2.94.687-.335.092-.661.184-.988.284-.226.068-.444.135-.67.218-2.713.863-5.301 2.044-7.713 3.484-2.78 1.641-5.327 3.626-7.588 5.904C4.237 76.75 0 86.237 0 96.731v18.844h76.213V96.731c0-10.518-4.263-20.05-11.164-26.942z'
                />
                <path
                  fill='#FFCC46'
                  d='M43.751 115.123h-9.413l.258-5.1.344-6.567v-.008l.209-3.894.026-.495v-.008l.193-3.651.108-2.136.503-9.497.46-8.844c.009-.084.017-.168.034-.252.125-.653.46-1.13.905-1.423.368-.26.82-.386 1.273-.386.384 0 .77.093 1.104.285.512.277.913.762 1.065 1.474l.008.05c.016.084.034.168.042.252l.502 6.96v.008l.05.703.82 11.474.152 2.144.285 3.928.067 1.013.686 9.54.319 4.43z'
                />
                <path
                  fill='#FFCC46'
                  d='M42.486 71.07L41.933 71.623 39.655 73.901 38.415 75.141 37.276 74.001 34.764 71.497 34.337 71.07 38.415 67z'
                />
                <path
                  fill='#E7E7E7'
                  d='M45.17 73.482c-.119.066-.243.133-.36.184-.11.068-.21.117-.32.168-.091.042-.2.05-.309.034h-.008c-.62-.119-1.483-1.124-2.405-2.295-1.372-1.768-2.863-3.903-3.784-3.903-.83 0-2.153 2.052-3.384 3.776-.996 1.39-1.934 2.572-2.512 2.33-.218-.094-.427-.194-.637-.294-.1-.05-.2-.1-.301-.167-4.163-2.261-6.047-7.22-4.816-11.767.092-.335.201-.671.327-1.005l.008-.034.427-1.122c1.097-.31 2.211-.561 3.35-.762h.143l14.932.334c.05.01.092.025.143.034 1.088.252 2.16.545 3.207.888l.134.277.151.325c.025.06.05.11.075.169.1.217.185.444.26.669v.008c1.632 4.54-.059 9.774-4.322 12.153'
                />
                <path
                  fill='#CB7553'
                  d='M46.063 46.063v12.562c0 4.162-3.376 7.537-7.538 7.537-4.162 0-7.537-3.375-7.537-7.537V46.062h15.075z'
                />
                <path
                  fill='#E19068'
                  d='M65.032 34.586c-.585 7.842-4.29 14.704-9.819 19.456-5.52 4.76-12.855 7.404-20.698 6.818C18.83 59.688 7.07 46.027 8.24 30.342c.024-.31.055-.609.087-.917.344-3.267 1.238-6.34 2.564-9.156.18-.382.367-.753.562-1.117 1.673-3.167 3.923-5.964 6.606-8.266 5.521-4.76 12.857-7.404 20.7-6.818 6.455.482 12.24 3.073 16.73 7.062.056.037.104.083.157.145.914.808 1.772 1.687 2.568 2.612 2.312 2.675 4.12 5.775 5.304 9.155.893 2.561 1.437 5.272 1.563 8.087.038.834.042 1.683.003 2.536-.014.31-.028.612-.052.92'
                />
                <circle
                  cx='23.45'
                  cy='40.2'
                  r='2.513'
                  fill='#2A4E82'
                  fillRule='nonzero'
                />
                <circle
                  cx='43.55'
                  cy='40.2'
                  r='2.513'
                  fill='#2A4E82'
                  fillRule='nonzero'
                />
                <circle
                  cx='63.65'
                  cy='35.175'
                  r='5.025'
                  fill='#E19068'
                  fillRule='nonzero'
                />
                <circle
                  cx='10.05'
                  cy='29.313'
                  r='5.025'
                  fill='#E19068'
                  fillRule='nonzero'
                />
                <path
                  fill='#FFCC46'
                  d='M51.557 5.881c-.028.229-.056.457-.094.676-.218 1.315-.656 2.6-1.295 3.844v.009c-3.855 7.547-14.911 13.63-28.29 14.757-5.239.442-10.226.076-14.64-.957.125-.472.26-.936.404-1.39 1.228-3.955 3.104-7.583 5.5-10.713C18.069 5.64 25.183 1.305 33.314.62 40.008.055 46.34 2.046 51.557 5.88'
                />
                <path
                  fill='#FFCC46'
                  d='M67.925 32.507c-10.564-3.455-17.834-9.993-18.468-17.92-.088-1.115-.046-2.228.13-3.324.256-1.627.788-3.212 1.58-4.743.101-.226.216-.44.341-.657 1.353.977 2.65 2.07 3.875 3.239 3.557 3.384 6.555 7.477 8.803 12.098 1.697 3.502 2.978 7.29 3.739 11.307'
                />
                <path
                  fill='#4A79B5'
                  d='M76.313 96.48v18.844h-47L50.19 60.3c3.585 1.19 6.952 2.89 9.975 5.033 1.776 1.257 3.45 2.664 4.983 4.205C72.05 76.43 76.313 85.96 76.313 96.48'
                />
                <path
                  fill='#2A4E82'
                  fillRule='nonzero'
                  d='M53.6 82.912H65.325V84.587H53.6z'
                />
              </g>
              <g>
                <path
                  fill='#294F83'
                  d='M1.624 55.959v2.08c.002.342.656.74 1.465.74h106.047c.81 0 1.463-.398 1.465-.728v-2.092H1.624zM109.136 60.3H3.09C1.364 60.3.006 59.316 0 58.06v-3.623h112.225v3.601c-.007 1.278-1.365 2.262-3.089 2.262z'
                  transform='translate(-732 -278) translate(597 272) translate(0 6.218) translate(135.5) translate(10.887 73.7)'
                />
                <path
                  fill='#A3BBD8'
                  d='M63.65 51.925c-.206 1.442-.954 2.513-1.845 2.513H50.42c-.892 0-1.64-1.071-1.845-2.513H63.65z'
                  transform='translate(-732 -278) translate(597 272) translate(0 6.218) translate(135.5) translate(10.887 73.7)'
                />
                <path
                  fill='#294F83'
                  d='M49.7 52.766c.28.507.773.83 1.32.83h11.86c.547 0 1.04-.323 1.32-.83H49.7zm13.18 1.672H51.02c-1.11 0-2.067-.823-2.33-2l-.115-.513h16.75l-.115.512c-.263 1.178-1.221 2-2.33 2z'
                  transform='translate(-732 -278) translate(597 272) translate(0 6.218) translate(135.5) translate(10.887 73.7)'
                />
                <path
                  fill='#E5E5E4'
                  d='M110.98 58.625H4.76c-1.238 0-2.247-.946-2.247-2.11 0-.21.182-.403.407-.403.226 0 .408.15.408.362v.042c0 .74.643 1.344 1.433 1.344H110.98c.225 0 .407.171.407.382 0 .212-.182.383-.407.383'
                  transform='translate(-732 -278) translate(597 272) translate(0 6.218) translate(135.5) translate(10.887 73.7)'
                />
                <path
                  fill='#F9F9FA'
                  d='M97.155 58.625H14.232c-1.384 0-2.507-1.11-2.507-2.478V5.16c0-2.387 1.958-4.322 4.375-4.322h79.189c2.415 0 4.373 1.935 4.373 4.322v50.987c0 1.369-1.122 2.478-2.507 2.478'
                  transform='translate(-732 -278) translate(597 272) translate(0 6.218) translate(135.5) translate(10.887 73.7)'
                />
                <path
                  fill='#294F83'
                  d='M16.032 1.636c-1.945 0-3.528 1.602-3.528 3.57v51.76c0 .937.752 1.698 1.677 1.698h82.188c.925 0 1.677-.761 1.677-1.697V5.207c0-1.97-1.582-3.57-3.527-3.57H16.032zM96.369 60.3H14.181c-1.816 0-3.293-1.496-3.293-3.333V5.207C10.887 2.334 13.194 0 16.032 0h78.487c2.837 0 5.143 2.335 5.143 5.206v51.76c0 1.838-1.477 3.334-3.293 3.334z'
                  transform='translate(-732 -278) translate(597 272) translate(0 6.218) translate(135.5) translate(10.887 73.7)'
                />
                <path
                  fill='#E5E5E4'
                  d='M92.987 57.788H18.514c-2.82 0-5.114-2.31-5.114-5.15V7.517c0-.452.364-.818.813-.818.45 0 .813.366.813.819v45.118c0 1.937 1.565 3.513 3.488 3.513h74.473c.45 0 .813.366.813.819 0 .452-.363.819-.813.819'
                  transform='translate(-732 -278) translate(597 272) translate(0 6.218) translate(135.5) translate(10.887 73.7)'
                />
                <path
                  fill='#E5E5E4'
                  d='M61.975 30.15c0 3.238-2.625 5.863-5.863 5.863-3.237 0-5.862-2.625-5.862-5.863 0-3.238 2.625-5.862 5.862-5.862 3.238 0 5.863 2.624 5.863 5.862'
                  transform='translate(-732 -278) translate(597 272) translate(0 6.218) translate(135.5) translate(10.887 73.7)'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
