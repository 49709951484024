/* refactored 3/12/2021 */
import React from 'react';

//types
import { UpdateLoginContactInput } from '../hooks/useProfileOperations';

//helpers
import { normalizePhone, phoneValidation } from '../../../../helpers';

//hooks
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../hooks/useSnackbar';
import useTextFieldState from '../../../../hooks/useTextFieldState';
import usePasswordState from '../../../authentication/hooks/usePasswordState';
import useMfa from '../hooks/useMfa';
import { useMeQuery } from '../../../../generated/graphql';

//components
import { Button, Header, Switch } from '@betterpt/better-components';
import { GridCard } from '../../../Shared/GridCard';
import EnableMFADialog from './EnableMFADialog';
import DisableMFADialog from './DisableMFADialog';
import FormField from '../../../Shared/FormField';
import ThemedTextField from '../../../Shared/ThemedTextField';
import ValidatedPhoneNumber from '../../../Shared/Picas/ValidatedPhoneNumber';

//style
import { logoutButtonStyle, H4, FormBody } from '../styles/Profile.style';
import useHandleError from '../../../../hooks/useHandleError';

interface Props {
  updateLoginContactInfo: (input: UpdateLoginContactInput) => Promise<boolean>;
}

const LoginContactInformation = ({ updateLoginContactInfo }: Props) => {
  const snackbar = useSnackbar();
  const { primaryColor } = useRemoteConfigAliases();

  const [saving, updateSaving] = React.useState(false);
  const [isEditModeActive, setEditModeActive] = React.useState(false);
  const [activateMFAOpen, setActivateMFAOpen] = React.useState(false);
  const [confirmDisableMFAOpen, setConfirmDisableMFAOpen] =
    React.useState(false);
  const emailFormState = useTextFieldState();
  const phoneFormState = useTextFieldState({
    normalizer: normalizePhone,
    validation: phoneValidation,
  });
  const oldPasswordFormState = useTextFieldState();
  const newPasswordFormState = usePasswordState();
  const meQuery = useMeQuery();
  const mfa = useMfa();
  const handleError = useHandleError();

  React.useEffect(() => {
    const me = meQuery.data?.me;
    if (me) {
      emailFormState.updateValue(me?.email ?? '');
      phoneFormState.updateValue(me?.phone ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meQuery.data]);

  React.useEffect(() => {
    setActivateMFAOpen(false);
    setConfirmDisableMFAOpen(false);
    setEditModeActive(false);
  }, [mfa.isEnabled]);

  const handleDialogClose = () => {
    setActivateMFAOpen(false);
    setConfirmDisableMFAOpen(false);
  };

  //functions
  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateSaving(true);
    try {
      const savedNewChanges = await updateLoginContactInfo({
        oldPasswordFormState,
        newPasswordFormState,
        phoneFormState,
      });
      if (savedNewChanges)
        snackbar?.setSuccessMessage('Information updated successfully');
      setEditModeActive(false);
      oldPasswordFormState.updateValue('');
      newPasswordFormState.updateValue('');
    } catch (e) {
      handleError(e);
    }
    updateSaving(false);
  };

  const newPasswordError =
    newPasswordFormState.errors.length > 0
      ? newPasswordFormState.errors.map((err) => (
          <React.Fragment key={err}>
            {err}
            <br />
          </React.Fragment>
        ))
      : '';

  return (
    <>
      <EnableMFADialog
        onClose={handleDialogClose}
        open={activateMFAOpen}
        mfa={mfa}
      />
      <DisableMFADialog
        onClose={handleDialogClose}
        open={confirmDisableMFAOpen}
        mfa={mfa}
      />
      <GridCard>
        <form onSubmit={handleSubmit}>
          <Header underline style={{ padding: '22px 23px 13px 42px' }}>
            <H4>Login and Contact Information</H4>
            {isEditModeActive ? (
              <Button
                disabled={saving}
                size='small'
                color='transparent'
                type='submit'
                style={logoutButtonStyle(primaryColor, true)}
                loading={saving}
              >
                SAVE
              </Button>
            ) : (
              <Button
                size='small'
                color='transparent'
                style={logoutButtonStyle(primaryColor)}
                onClick={() => setEditModeActive(true)}
              >
                EDIT
              </Button>
            )}
          </Header>
          <FormBody>
            <FormField
              label='Account Email'
              disabled
              formHandler={emailFormState}
              fullWidth
            />
            <FormField
              label='Mobile Phone'
              id='phone'
              placeholder={
                isEditModeActive
                  ? 'Enter your mobile phone number'
                  : 'Click edit to add a phone number'
              }
              formHandler={phoneFormState}
              disabled={!isEditModeActive}
              fullWidth
              InputProps={{
                endAdornment: meQuery.data?.me?.hasValidatedMobileNumber && (
                  <>
                    {
                      <ValidatedPhoneNumber
                        position='left'
                        message={`We have confirmed this is a valid mobile phone number.`}
                        mainStyle={{ position: 'relative' }}
                      />
                    }
                  </>
                ),
              }}
            />
            {!isEditModeActive ? (
              <ThemedTextField
                disabled
                label='Password'
                value='********'
                fullWidth
              />
            ) : (
              <>
                <ThemedTextField
                  onChange={(e) =>
                    oldPasswordFormState.updateValue(e.currentTarget.value)
                  }
                  label='Old Password'
                  placeholder='Enter your old password to save a new one'
                  type='password'
                  fullWidth
                />
                <ThemedTextField
                  onChange={(e) =>
                    newPasswordFormState.updateValue(e.currentTarget.value)
                  }
                  label='New Password'
                  placeholder='Enter your new password here'
                  type='password'
                  error={newPasswordFormState.errors.length > 0}
                  helperText={newPasswordError}
                  fullWidth
                />
              </>
            )}
            <ThemedTextField
              label='Two-Factor Authentication'
              id='MFA'
              value={mfa.isEnabled ? 'Enabled' : 'Disabled'}
              disabled
              fullWidth
              InputProps={{
                endAdornment: (
                  <Switch
                    name='mfaEnabled'
                    value={mfa.isEnabled}
                    checked={mfa.isEnabled}
                    onChange={() => {
                      if (mfa.isEnabled) {
                        setConfirmDisableMFAOpen(true);
                      } else {
                        mfa.getQRUrl();
                        setActivateMFAOpen(true);
                      }
                    }}
                    disabled={!isEditModeActive}
                    inputProps={{
                      ['data-testid' as any]: `activate-mfa`,
                    }}
                  />
                ),
              }}
            />
          </FormBody>
        </form>
      </GridCard>
    </>
  );
};

export default LoginContactInformation;
