import * as React from 'react';

//types
import { useRouteMatch, useLocation } from 'react-router-dom';

//components
import { FilterButtonOnly } from '@betterpt/better-components';
import { Link } from 'react-router-dom';

//assets
import { IcMenu, IcCard } from '@betterpt/better-icons';

//styles
import { colorValues } from '@betterpt/better-components';
import * as styles from './Filters.style';

const CardListToggle: React.FC<React.PropsWithChildren<unknown>> = () => {
  const match = useRouteMatch();
  const location = useLocation();
  const isCards = location.pathname.includes('details');
  const filterCopy = isCards ? 'card view' : 'list view';

  return (
    <styles.FilterContainer>
      <Link
        to={`${match.path}/${isCards ? 'list' : 'details'}${location.search}`}
        data-testid='toggle-view-button'
      >
        <FilterButtonOnly
          buttonTitle={filterCopy}
          icon={
            isCards ? (
              <IcCard
                color={colorValues.cityblock}
                style={{ height: 19, width: 19, marginRight: '5px', marginTop: '3px'}}
              />
            ) : (
              <IcMenu
                color={colorValues.cityblock}
                style={{ height: 19, width: 19, marginRight: '5px', marginTop: '3px' }}
              />
            )
          }
          style={{ buttonMargin: "12px 2px 0 0" }}
        />
      </Link>
    </styles.FilterContainer>
  );
};

export default CardListToggle;