import React from 'react';

//components
import { GridCard } from '../../../Shared/GridCard';
import { Header, colorValues } from '@betterpt/better-components';
import { IcInfoOutline } from '@betterpt/better-icons';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';

const NotAFacilityMemberCard = () => {
  const { facilitiesSingularAlias } = useRemoteConfigAliases();
  return (
    <GridCard style={{ marginRight: 30 }}>
      <Header underline>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IcInfoOutline color={colorValues.betterptblack} />
          <p style={{ marginLeft: 20, fontWeight: 'bold' }}>
            Please note, you have not been assigned as a member of staff for this {facilitiesSingularAlias?.toLowerCase() ?? ''}
          </p>
        </div>
      </Header>
      <p style={{ margin: 30, lineHeight: 1.5 }}>
        To access more information about this {facilitiesSingularAlias?.toLowerCase() ?? ''}, such as insurance details, and appointment
        information, please contact your company owner or administrator and have them add you as a member.
      </p>
    </GridCard>
  );
};

export default NotAFacilityMemberCard;
