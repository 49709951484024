import React from 'react';

//types
import { TextFieldState } from '../../../../hooks/useTextFieldState';
import { BoolFieldState } from '../../../../hooks/useBoolFieldState';
import {
  MappedAppointmentTypeDetail,
  ToggleArchiveAppointmentTypePayload,
} from '../hooks/useAppointmentTypeDetailOperations';

//helpers
import { colorValues } from '@betterpt/better-components';
import Flags from '../../../../constants/flags';

//hooks
import { useParams } from 'react-router-dom';
import { useMeQuery } from '../../../../generated/graphql';
import useSnackbar from '../../../../hooks/useSnackbar';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import useHandleError from '../../../../hooks/useHandleError';

// components
import { Dialog } from '@material-ui/core';
import {
  Button,
  CloseButton,
  InfoBox,
  Switch,
} from '@betterpt/better-components';
import SlideTransition from '../../../Shared/SlideTransition';
import FormField from '../../../Shared/FormField';
import ApptInitialEvalSelector from '../../../Shared/AppointmentTypes/components/ApptInitialEvalSelector';
import FeatureFlagCheck from '../../../Shared/FeatureFlagCheck';
import ThemedTextField from '../../../Shared/ThemedTextField';

//styles
import {
  pinnedDialogStyle,
  clickableInputStyle,
  dialogButtonStyle,
  dialogTextInputStyle,
  DialogBody,
  DialogHeader,
  DialogSubheader,
  WarningText,
  WarningTextFieldContainer,
} from '../../../Shared/AppointmentTypes/AppointmentTypes.style';

const paperStyle = { borderRadius: 5, position: 'relative' as 'relative' };
const formStyle = { width: '100%' };
const infoboxStyle = { top: 45 };
const infoboxTextStyle = { fontSize: 12 };
const archiveButtonStyle = (typeIsActive?: boolean) => ({
  color: typeIsActive ? colorValues.messyketchup : colorValues.guajirogreen,
  overflow: 'visible',
  whiteSpace: 'normal' as 'normal',
});
const minutesTextStyle = {
  fontSize: 14,
  color: colorValues.coneyislandsidewalk,
};

interface Props {
  open: boolean;
  onClose: () => void;
  mappedAppointmentType: MappedAppointmentTypeDetail;
  displayNameFormState: TextFieldState;
  legacyIsInitialEvalFormState: BoolFieldState;
  shouldRequireManualInsuranceFormState: BoolFieldState;
  durationFormState: TextFieldState;
  intervalFormState: TextFieldState;
  canChooseProviderFormState: BoolFieldState;
  shouldShowFinalCheckboxFormState: BoolFieldState;
  handleSave: (event: React.FormEvent<HTMLFormElement>) => void;
  toggleArchiveAppointmentType: (
    payload: ToggleArchiveAppointmentTypePayload
  ) => Promise<void>;
}

const EditAppointmentTypeDialog = ({
  open,
  onClose,
  mappedAppointmentType,
  displayNameFormState,
  legacyIsInitialEvalFormState,
  durationFormState,
  intervalFormState,
  canChooseProviderFormState,
  handleSave,
  toggleArchiveAppointmentType,
  shouldRequireManualInsuranceFormState,
  shouldShowFinalCheckboxFormState,
}: Props) => {
  const snackbar = useSnackbar();
  const handleError = useHandleError();
  const {
    employeeSingularAlias,
    patientsSingularAlias,
  } = useRemoteConfigAliases();
  const { appointmentTypeId } = useParams<{ appointmentTypeId: string }>();
  const meQuery = useMeQuery({ fetchPolicy: 'cache-first' });
  const shouldRemoveCallLengthLimit =
    meQuery.data?.me?.company?.shouldRemoveCallLengthLimit;

  const [isArchiving, updateIsArchiving] = React.useState(false);
  const [
    legacyIsInitialEvalOpen,
    updateLegacyIsInitialEvalOpen,
  ] = React.useState(false);

  const telehealthDurationWarningText = (() => {
    if (mappedAppointmentType.medium === 'Telehealth') {
      const totalMinutes = parseInt(durationFormState.value);
      if (totalMinutes > 70 && !shouldRemoveCallLengthLimit) {
        return 'Please note that video rooms for this company close after about 70 mins';
      } else if (totalMinutes > 180 && shouldRemoveCallLengthLimit) {
        return 'Please note that video rooms for this company close after about 180 mins';
      }
    }
  })();

  const handleToggleArchive = async () => {
    updateIsArchiving(true);
    try {
      await toggleArchiveAppointmentType({
        appointmentTypeId,
        isActive: !mappedAppointmentType.isActive,
      });
      updateIsArchiving(false);
      snackbar?.setSuccessMessage(
        `Appointment type successfully ${
          mappedAppointmentType.isActive ? 'archived' : 'activated'
        }`
      );
      onClose();
    } catch (e) {
      updateIsArchiving(false);
      handleError(e);
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      style={pinnedDialogStyle}
      TransitionComponent={SlideTransition}
      PaperProps={{ style: paperStyle }}
      onClose={onClose}
    >
      <DialogBody>
        <form onSubmit={handleSave} style={formStyle}>
          <DialogHeader>Edit Appointment Type</DialogHeader>
          <FormField
            fullWidth
            label='Name'
            id='newApptTypeName'
            placeholder='Name for this appointment type'
            formHandler={displayNameFormState}
            style={dialogTextInputStyle}
            data-cy='edit-appointment-type-name'
          />

          <ThemedTextField
            fullWidth
            disabled
            id='apptMedium'
            label='Medium'
            value={mappedAppointmentType.medium}
            style={dialogTextInputStyle}
            data-cy='edit-appointment-type-medium'
            InputProps={{
              endAdornment: (
                <InfoBox
                  openWith='hover'
                  width={230}
                  height={200}
                  position='bottom'
                  padding={0}
                  boxStyle={infoboxStyle}
                >
                  <h4>APPOINTMENT TYPE MEDIUM</h4>
                  <p style={infoboxTextStyle}>
                    Appointment type medium cannot be edited, as this would
                    create errors for any past appointments using this type.
                  </p>
                </InfoBox>
              ),
            }}
          />
          {meQuery.data?.me?.company?.hasIntegration && (
            <div style={dialogTextInputStyle}>
              <ApptInitialEvalSelector
                legacyIsInitialEvalFormState={legacyIsInitialEvalFormState}
                dropdown={{
                  isOpen: legacyIsInitialEvalOpen,
                  toggle: () =>
                    updateLegacyIsInitialEvalOpen(!legacyIsInitialEvalOpen),
                  close: () => updateLegacyIsInitialEvalOpen(false),
                }}
              />
            </div>
          )}

          <DialogSubheader>
            Booking Experience for This Appointment Type
          </DialogSubheader>
          <WarningTextFieldContainer style={dialogTextInputStyle}>
            <FormField
              fullWidth
              label='Duration'
              id='newApptDuration'
              placeholder='Duration in minutes for this appointment type'
              formHandler={durationFormState}
              inputProps={{ style: clickableInputStyle }}
              InputProps={{
                endAdornment: <p style={minutesTextStyle}>Minutes</p>,
              }}
              data-cy='edit-appointment-type-duration'
            />
            {telehealthDurationWarningText && (
              <WarningText>{telehealthDurationWarningText}</WarningText>
            )}
          </WarningTextFieldContainer>

          <FormField
            fullWidth
            label='Time Interval'
            id='newApptInterval'
            placeholder='Interval in minutes between each appointment'
            formHandler={intervalFormState}
            style={dialogTextInputStyle}
            inputProps={{ style: clickableInputStyle }}
            InputProps={{
              endAdornment: <p style={minutesTextStyle}>Minutes</p>,
            }}
            data-cy='edit-appointment-type-interval'
          />

          <ThemedTextField
            fullWidth
            label={`${employeeSingularAlias} Select`}
            id='editCanChooseProvider'
            value={`${patientsSingularAlias} ability to choose a specific ${employeeSingularAlias.toLowerCase()} is turned ${
              canChooseProviderFormState.value ? 'ON' : 'OFF'
            }`}
            style={dialogTextInputStyle}
            data-cy='edit-appointment-type-choose-provider'
            inputProps={{
              style: { cursor: 'default' },
            }}
            multiline
            InputProps={{
              endAdornment: (
                <Switch
                  name='editCanChooseProviderSwitch'
                  data-cy='edit-appointment-type-choose-provider-switch'
                  value={canChooseProviderFormState.value}
                  checked={!!canChooseProviderFormState.value}
                  onChange={(e) =>
                    canChooseProviderFormState.updateValue(e.target.checked)
                  }
                />
              ),
            }}
          />
          <ThemedTextField
            fullWidth
            label='Require Insurance'
            id='requireInsurance'
            style={dialogTextInputStyle}
            value={`${patientsSingularAlias} will${
              shouldRequireManualInsuranceFormState.value ? '' : ' NOT'
            } be required to enter insurance`}
            multiline
            InputProps={{
              endAdornment: (
                <Switch
                  name='requireInsuranceSwitch'
                  data-cy='edit-appointment-type-require-insurance-switch'
                  value={shouldRequireManualInsuranceFormState.value === true}
                  checked={shouldRequireManualInsuranceFormState.value === true}
                  onChange={(e) => {
                    shouldRequireManualInsuranceFormState.updateValue(
                      e.target.checked
                    );
                  }}
                />
              ),
            }}
          />

          <ThemedTextField
            fullWidth
            label='Patient Request Acknowledgement'
            id='patientRequestAcknowledgement'
            style={dialogTextInputStyle}
            value={`On booking, patients are${
              shouldShowFinalCheckboxFormState.value ? '' : ' NOT'
            } required to acknowledge they are requesting an appointment`}
            multiline
            InputProps={{
              endAdornment: (
                <Switch
                  data-cy='patient-acknowledgment-toggle'
                  name='patientRequestAcknowledgementSwitch'
                  value={shouldShowFinalCheckboxFormState.value === true}
                  checked={shouldShowFinalCheckboxFormState.value === true}
                  onChange={(e) => {
                    shouldShowFinalCheckboxFormState.updateValue(
                      e.target.checked
                    );
                  }}
                />
              ),
            }}
          />

          <Button
            fullWidth
            size='large'
            color='transparent'
            style={archiveButtonStyle(mappedAppointmentType.isActive)}
            onClick={handleToggleArchive}
            loading={isArchiving}
          >
            CLICK HERE TO {mappedAppointmentType.isActive ? '' : 'UN'}ARCHIVE
            THIS APPOINTMENT TYPE
          </Button>

          <Button
            type='submit'
            size='large'
            style={dialogButtonStyle}
            data-cy='edit-appointment-type-dialog-button'
          >
            SAVE AND FINISH UP
          </Button>
        </form>
      </DialogBody>
      <CloseButton onClose={onClose} />
    </Dialog>
  );
};

export default EditAppointmentTypeDialog;
