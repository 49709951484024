import * as React from 'react';

//types
import { PatientContactListResult } from '../hooks/usePatientContactList';

// helpers
import { format, parse } from 'date-fns';
import { colorValues } from '@betterpt/better-components';
import { addIndefiniteArticle, normalizePhone } from '../../../../helpers';

// hooks
import { useMeQuery, useToggleContactArchiveStatusMutation } from '../../../../generated/graphql';
import { useRouteMatch, useHistory } from 'react-router-dom';
import usePatientContactList from '../hooks/usePatientContactList';
import usePatientFilters from '../../../../hooks/usePatientFilters';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import useDebounce from '../../../../hooks/useDebounce';

// components
import { Link } from 'react-router-dom';
import { Button, Card, InfoBox, Table } from '@betterpt/better-components';
import Filters from '../components/Filters';
import ContactOptions from '../components/ContactOptions';
import ThemedTextField from '../../../Shared/ThemedTextField';
import ValidatedPhoneNumberPica from '../../../Shared/Picas/ValidatedPhoneNumber';
import NoPatientsFound from '../components/NoPatientsFound';

// assets
import { IcFace } from '@betterpt/better-icons';
import { IcSuccessCircle } from '@betterpt/better-icons';

// style
import { PatientsHeader } from './PatientList.style';

export const BetterAccessColumnHeader = () => {
  return (
    <div style={{ position: 'relative' }}>
      <p style={{ textAlign: 'center', lineHeight: 1.17, margin: '0px' }}>
        HAS
        <br />
        BETTERACCESS
        <br />
        ACCOUNT
      </p>
      <InfoBox height={120} width={250} openWith="hover" fixedPlacement padding={0} mainStyle={{ right: '-30px', top: '10px' }}>
        <h3 className="H6">BETTERACCESS PATIENTS</h3>
        <p className="Body">BetterAccess patients are patients who have signed up and created a profile.</p>
      </InfoBox>
    </div>
  );
};

const PatientList = () => {
  const { push } = useHistory();
  const match = useRouteMatch();
  const filters = usePatientFilters();

  const [backToFirstPage, updateBackToFirstPage] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [selectedContactId, setSelectedContactId] = React.useState<string | null>(null);
  const debouncedSearchText = useDebounce(searchText, 500);

  const resetFilters = () => {
    setSearchText('');
    filters.resetFilters();
  };

  React.useEffect(() => {
    const backToStart = filters.offset.value === 0 && filters.defaultPageIndex.value === 0 && filters.defaultTableSection.value === 1;
    updateBackToFirstPage(backToStart);
  }, [filters]);

  React.useEffect(() => {
    filters.searchTerm.update(debouncedSearchText);
    if (debouncedSearchText) {
      filters.offset.update(0);
      filters.defaultPageIndex.update(0);
      filters.defaultTableSection.update(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  React.useEffect(() => {
    if (!filters.searchTerm.value) {
      setSearchText('');
    }
  }, [filters.searchTerm.value]);

  const meQuery = useMeQuery();
  const isPartnerSite = window.location.host.includes('acorn') || window.location.host.includes('somatus');

  const patientList = usePatientContactList();
  const [toggleArchivePatient, toggleArchivePatientOptions] = useToggleContactArchiveStatusMutation({
    onCompleted: () => {
      patientList.refetch();
    },
  });
  const { facilitiesSingularAlias, patientsSingularAlias, patientsPluralAlias, primaryColor } = useRemoteConfigAliases();

  const rowData =
    patientList?.data?.contacts?.result?.map((contact: PatientContactListResult) => {
      return {
        id: contact?.id,
        patientName: `${contact?.lastName}, ${contact?.firstName}${!!contact?.archivedAt ? ' (Archived)' : ''}`,
        email: contact?.email || '-',
        phone: (
          <div
            style={{
              position: 'relative',
              display: 'flex',
            }}
          >
            {contact?.hasValidatedMobileNumber && <ValidatedPhoneNumberPica mainStyle={{ left: '-25px', top: '-2px' }} />}
            {normalizePhone(contact?.phone || '') || '-'}
          </div>
        ),
        dateOfBirth: contact?.dateOfBirth ? format(parse(contact.dateOfBirth, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy') : '-',
        facility: contact?.clinic?.displayName || '-',
        hasBetterAccessAccount: !isPartnerSite && contact.patientId && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <IcSuccessCircle color={colorValues.guajirogreen} />
          </div>
        ),
        options: (
          <ContactOptions
            patient={contact}
            toggleArchivePatient={toggleArchivePatient}
            isSavingArchive={toggleArchivePatientOptions.loading}
            openStatus={{
              value: selectedContactId === contact.id,
              update: (isOpen) => {
                setSelectedContactId(isOpen ? contact.id : null);
              },
            }}
          />
        ),
      };
    }) ?? [];

  const sharedColumns = [
    {
      field: 'patientName',
      title: `${patientsSingularAlias.toUpperCase()} NAME`,
    },
    { field: 'email', title: 'EMAIL' },
    { field: 'phone', title: 'PHONE', cellStyle: { minWidth: 112 } },
    { field: 'dateOfBirth', title: 'DATE OF BIRTH' },
    { field: 'facility', title: facilitiesSingularAlias.toUpperCase() },
  ];

  const partnerColumns = [
    ...sharedColumns,
    {
      field: 'options',
      title: 'ACTION',
      cellStyle: { width: 100, textAlign: 'center' },
    },
  ];

  const betterAccessColumns = [
    ...sharedColumns,
    {
      field: 'hasBetterAccessAccount',
      title: <BetterAccessColumnHeader />,
      cellStyle: {
        maxWidth: 120,
        minWidth: 65,
        width: 65,
      },
    },
    {
      field: 'options',
      title: 'ACTION',
      cellStyle: { width: 100, textAlign: 'center' },
    },
  ];

  const companyName = meQuery.data?.me?.company?.companyName;

  const options = {
    minBodyHeight: 690,
    overflow: 'visible' as 'visible',
    pagination: {
      backToFirstPage,
      totalCount: patientList?.data?.contacts?.pager?.total || 0,
      pagesize: 12,
      defaultPageIndex: filters.defaultPageIndex.value,
      defaultSection: filters.defaultTableSection.value,
      onChangePage: (pageSize: number, idx: number, activeSection: number) => {
        filters.offset.update(idx * pageSize);
        filters.defaultTableSection.update(activeSection);
        filters.defaultPageIndex.update(idx);
      },
      primaryColor,
    },
    searchNotFoundComponent: <NoPatientsFound isNoResultsPage resetFilters={resetFilters} />,
  };

  return (
    <div data-testid="patients-list-container" style={{ margin: '31px 33px', width: '90%' }}>
      <PatientsHeader>
        <h1 className="Hero">
          {patientsPluralAlias} at {companyName}
        </h1>
        <span style={{ display: 'flex', alignItems: 'flex-end' }}>
          <ThemedTextField
            style={{ marginBottom: '-7px', marginRight: 10 }}
            InputProps={{
              startAdornment: <IcFace color={colorValues.cityblock} width="24px" height="24px" style={{ marginRight: -8 }} />,
              style: { width: 207 },
            }}
            value={searchText}
            placeholder={`Quick search ${patientsPluralAlias.toLowerCase()}`}
            onChange={(e) => {
              filters.offset.update(0);
              setSearchText(e.currentTarget.value);
            }}
          />
          <Link to={`${match.url}/new`}>
            <Button style={{ backgroundColor: primaryColor, width: '267px' }} size="large">
              ADD {addIndefiniteArticle(patientsSingularAlias).toUpperCase()}
            </Button>
          </Link>
        </span>
      </PatientsHeader>
      <Filters resetFilters={resetFilters} loading={patientList.loading} />

      <Card
        style={{
          position: 'relative',
          width: '100%',
        }}
        fullWidthAtBreakpoint={1400}
      >
        <Table
          columns={isPartnerSite ? partnerColumns : betterAccessColumns}
          data={rowData}
          onRowClick={(_, rowData) => push(`${match.url}/${rowData?.id}`)}
          options={options}
          isLoading={patientList.loading || toggleArchivePatientOptions.loading}
          isSearching
        />
      </Card>
    </div>
  );
};

export default PatientList;
