import * as React from 'react';

//types
import { Contact } from '../../../../../generated/graphql';

// helpers
import {
  emailValidation,
  phoneValidation,
  normalizePhone,
  formatPhoneForBackend,
} from '../../../../../helpers';

// hooks
import { useUpdatePatientContactMutation } from '../../../../../generated/graphql';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../../hooks/useSnackbar';
import useTextFieldState from '../../../../../hooks/useTextFieldState';
import useHandleError from '../../../../../hooks/useHandleError';

// components
import ValidatedPhoneNumberPica from '../../../../Shared/Picas/ValidatedPhoneNumber';
import { Animation, Header, Button } from '@betterpt/better-components';
import FormField from '../../../../Shared/FormField';

// style
import { formFieldStyle, editSaveButtonStyle } from './PatientForm.style';

type Props = {
  existingDetails?: Contact;
  refetch?: (...args: any[]) => void;
  loading?: boolean;
};

const SecondaryContactForm: React.FC<React.PropsWithChildren<Props>> = ({
  existingDetails,
  refetch,
  loading,
}) => {
  const snackbar = useSnackbar();
  const handleError = useHandleError();
  const { primaryColor, loading: remoteConfigLoading } =
    useRemoteConfigAliases();

  const [secondaryFormDisabled, updateSecondaryFormDisabled] =
    React.useState(true);

  const secondaryDescription = useTextFieldState({
    initialValue: existingDetails?.secondaryDescription ?? '',
  });
  const secondaryEmail = useTextFieldState({
    validation: (value: string) => emailValidation(value, false),
    initialValue: existingDetails?.secondaryEmail,
  });
  const secondaryPhone = useTextFieldState({
    normalizer: normalizePhone,
    validation: (value: string) => phoneValidation(value),
    initialValue: normalizePhone(existingDetails?.secondaryPhone ?? ''),
  });

  const [updateSecondaryContact, { loading: savingSecondaryContact }] =
    useUpdatePatientContactMutation();

  const handleSubmitSecondaryContact = async (
    e: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    const formattedDescription = secondaryDescription.value?.trim();
    const formattedEmail = secondaryEmail.value?.trim();
    const formattedPhone = formatPhoneForBackend(secondaryPhone.value);
    const descriptionForBackend = formattedDescription.length
      ? formattedDescription
      : null;
    const emailForBackend = formattedEmail.length ? formattedEmail : null;
    const phoneForBackend = formattedPhone.length ? formattedPhone : null; //nulls allow for erasing existing data

    const somethingChanged =
      formattedDescription !== existingDetails?.secondaryDescription ||
      emailForBackend !== existingDetails?.secondaryEmail ||
      phoneForBackend !== existingDetails?.secondaryPhone;

    if (existingDetails?.id && somethingChanged) {
      try {
        await updateSecondaryContact({
          variables: {
            input: {
              contactId: existingDetails.id,
              secondaryDescription: descriptionForBackend,
              secondaryEmail: emailForBackend,
              secondaryPhone: phoneForBackend,
            },
          },
        });
        refetch?.();
        snackbar?.setSuccessMessage('Succesfully updated secondary contact!');
        updateSecondaryFormDisabled(true);
      } catch (e) {
        handleError(e);
      }
    } else {
      updateSecondaryFormDisabled(true);
    }
  };

  if (remoteConfigLoading) {
    return <Animation type='providerAppLoader' />;
  }

  //if there is a description, there must be either an email or phone number
  const needsInfo =
    !!secondaryDescription.value &&
    !secondaryPhone.value &&
    !secondaryEmail.value;

  //if there is a phone or email, there must be a description
  const needsDescription =
    !secondaryDescription.value &&
    (!!secondaryPhone.value || !!secondaryEmail.value);

  const isSecondaryValid =
    secondaryDescription.valid &&
    secondaryEmail.valid &&
    secondaryPhone.valid &&
    !needsInfo &&
    !needsDescription;

  const secondarySubmitButtonDisabled =
    savingSecondaryContact || !isSecondaryValid;

  return (
    <form data-testid='secondary-contact-container' id='secondary-contact-form'>
      <Header underline style={{ paddingLeft: '45px' }}>
        <h2 className='H3'>Secondary Contact Info</h2>
        {secondaryFormDisabled ? (
          <Button
            style={editSaveButtonStyle(false)}
            size='small'
            type='button'
            loading={loading || savingSecondaryContact}
            onClick={(e) => {
              e.preventDefault();
              updateSecondaryFormDisabled(false);
            }}
          >
            {existingDetails?.secondaryEmail || existingDetails?.secondaryPhone
              ? 'EDIT'
              : 'ADD'}
          </Button>
        ) : (
          <Button
            style={editSaveButtonStyle(true, isSecondaryValid, primaryColor)}
            size='small'
            disabled={secondarySubmitButtonDisabled}
            onClick={handleSubmitSecondaryContact}
            loading={savingSecondaryContact || loading}
          >
            SAVE
          </Button>
        )}
      </Header>

      <FormField
        style={formFieldStyle}
        id='secondaryDescription'
        placeholder='Enter a name, relationship, or location'
        label='Description'
        formHandler={{
          ...secondaryDescription,
          error: needsDescription ? 'Required' : secondaryDescription.error,
        }}
        disabled={secondaryFormDisabled}
        inputProps={{
          maxLength: 50,
        }}
      />
      <FormField
        style={formFieldStyle}
        id='secondaryEmail'
        placeholder='Add a secondary contact email'
        label='Email (Secondary)'
        formHandler={{
          ...secondaryEmail,
          error: needsInfo
            ? 'Add at least one form of contact info with your description'
            : secondaryEmail.error,
        }}
        disabled={secondaryFormDisabled}
        inputProps={{
          maxLength: 50,
        }}
      />
      <FormField
        style={formFieldStyle}
        id='secondaryPhone'
        placeholder='Add a secondary contact phone'
        label='Phone (Secondary)'
        formHandler={{
          ...secondaryPhone,
          error: needsInfo
            ? 'Add at least one form of contact info with your description'
            : secondaryPhone.error,
        }}
        disabled={secondaryFormDisabled}
        InputProps={{
          endAdornment: existingDetails?.hasValidatedSecondaryMobileNumber && (
            <ValidatedPhoneNumberPica mainStyle={{ position: 'relative' }} />
          ),
        }}
      />
    </form>
  );
};

export default SecondaryContactForm;
