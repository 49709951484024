import React from 'react';

//components
import TableView from '../../../../Appointments/InPersonAppointments/InPersonAppointmentsList/components/InPersonAppointmentsTable';
import { GridCard } from '../../../../../Shared/GridCard';
import { CardBody, ListTitle } from '../../FacilityDetails.style';
import Can from '../../../../../Can';

//queries
import {
  Order,
  useMeQuery,
  useFacilityQuery,
  useInPersonAppointmentsLazyQuery,
  Role,
} from '../../../../../../generated/graphql';

//hooks
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../../../hooks/useSnackbar';

// helpers
import { Roles } from '../../../../../../helpers/rbac-rules';

const InPersonAppointmentsList = ({ facilityId }: { facilityId: string }) => {
  const snackbar = useSnackbar();
  const [offset, setOffset] = React.useState(0);
  const [defaultPageIndex, updateDefaultPageIndex] = React.useState(0);
  const pagesize = 9;
  const aliasConfig = useRemoteConfigAliases();

  const facilityString = aliasConfig.facilitiesSingularAlias ?? 'Facility';
  const appointmentsQueryInput = {
    limit: pagesize,
    offset,
    clinicIds: [facilityId],
    order: Order.Desc,
  };

  const [
    callInPersonAppointmentsQuery,
    inPersonAppointmentsQuery,
  ] = useInPersonAppointmentsLazyQuery({
    variables: {
      input: appointmentsQueryInput,
    },
    onError: () => snackbar?.useGenericErrorMessage(),
    fetchPolicy: 'cache-and-network',
  });

  const facility = useFacilityQuery({
    variables: { id: facilityId },
  });

  const meQuery = useMeQuery({
    onCompleted(data) {
      const hasAccessToThisClinic = !!data.me?.clinics?.find(
        (clinic) => clinic?.id === facilityId
      );
      if (hasAccessToThisClinic || data.me?.role === Role.Owner) {
        callInPersonAppointmentsQuery();
      }
    },
  });

  const totalCount =
    inPersonAppointmentsQuery.data?.inPersonAppointments?.pager?.total;
  const loading = inPersonAppointmentsQuery.loading;

  const noAppointmentsCreated =
    inPersonAppointmentsQuery.called &&
    !loading &&
    !inPersonAppointmentsQuery.data?.inPersonAppointments?.pager?.total;

  return (
    <>
      <ListTitle>In-Person Appointments with this {facilityString}</ListTitle>
      <GridCard style={{ width: '100%', marginRight: 0 }}>
        <Can
          role={Roles[meQuery.data?.me?.role ?? 'initial']}
          perform='facility:view'
          data={{
            employeeClinics: meQuery?.data?.me?.clinics,
            clinicToView: facility.data?.clinic,
          }}
          yes={() => (
            <>
              {noAppointmentsCreated ? (
                <CardBody>
                  <p>
                    There are either no in person appointments registered with
                    this {facilityString.toLowerCase()}, or you do not have
                    permissions to view them.
                  </p>
                </CardBody>
              ) : (
                <TableView
                  tableOptions={{ minBodyHeight: 440 }}
                  onChangePage={(pageSize: number, idx: number) => {
                    setOffset(idx * pageSize);
                    updateDefaultPageIndex(idx);
                  }}
                  isLoading={
                    inPersonAppointmentsQuery.loading || meQuery.loading
                  }
                  totalCount={totalCount ?? 0}
                  appointmentsQuery={inPersonAppointmentsQuery}
                  defaultPageIndex={defaultPageIndex}
                  pagesize={pagesize}
                  onDoneChangingOptionsAppointment={() =>
                    inPersonAppointmentsQuery.refetch?.({
                      input: appointmentsQueryInput,
                    })
                  }
                />
              )}
            </>
          )}
          no={() => (
            <CardBody>
              <p>
                You do not have permission to view the in person appointments at
                this {facilityString.toLowerCase()} because you are not assigned
                to it.
              </p>
            </CardBody>
          )}
        />
      </GridCard>
    </>
  );
};

export default InPersonAppointmentsList;
