import React from 'react';

import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
// context
import { InPersonAppointmentFiltersProvider } from '../../../../context/inPersonAppointmentFilters';

// components
import InPersonAppointmentsList from './InPersonAppointmentsList';
import CreateInPersonAppointment from './CreateInPersonAppointment';
import InPersonAppointmentDetails from './InPersonAppointmentDetails';

const InPersonAppointments = () => {
  const match = useRouteMatch();
  const history = useHistory();

  return (
    <Switch>
      <Route path={`${match.path}/new`} exact>
        <CreateInPersonAppointment onCancelClick={() => history.push('/appointments/list')} />
      </Route>
      <Route path={`${match.path}/details/:appointmentId`} exact>
        <InPersonAppointmentDetails />
      </Route>

      <InPersonAppointmentFiltersProvider>
        <Route path={`${match.path}`}>
          <InPersonAppointmentsList />
        </Route>
      </InPersonAppointmentFiltersProvider>
    </Switch>
  );
};

export default InPersonAppointments;
