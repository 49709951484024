import { CSSObject, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { header } from './style';

const Div = styled.div``;

export type HeaderProps = {
  children: React.ReactNode;
  underline?: boolean;
  style?: CSSObject;
};
/**
 * Card Header Component
 *
 * @param {HeaderProps} { children: React.ReactNode; underline?: boolean; style?: CSSProperties; }
 */
const Header = ({
  children,
  style,
  underline,
  ...rest
}: HeaderProps & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <Div css={[header({ underline }), style]} {...rest}>
    {children}
  </Div>
);

export default Header;
