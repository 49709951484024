import { css } from '@emotion/react';
import { colorValues } from '../../helpers/colors';

export const pagerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 27px;
`;

export const arrowStyle = css`
  border: none;
  background: none;
  cursor: pointer;
  svg {
    height: 25px;
    width: 25px;
  }
`;

export const numbersSection = css`
  display: flex;
`;

const makeRGBAColor = (colorAsHex: string) =>
  `rgba(${parseInt(colorAsHex.substring(1, 3), 16)}, ${parseInt(
    colorAsHex.substring(3, 5),
    16
  )}, ${parseInt(colorAsHex.substring(5, 7), 16)}, 0.12)`;

export const numberStyle = (isActive: boolean, primaryColor?: string) => css`
  border: none;
  display: flex;
  height: 36px;
  width: 36px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: ${isActive
    ? (primaryColor && makeRGBAColor(primaryColor)) || colorValues.frost
    : colorValues.emptiness};
  z-index: ${isActive ? 1 : 0};
  border-bottom: 2px solid
    ${isActive
      ? primaryColor || colorValues.eveningsky
      : (primaryColor && makeRGBAColor(primaryColor)) || colorValues.fresco};
  p {
    padding: 0;
    margin: 0;
    color: ${primaryColor || colorValues.eveningsky};
  }
`;
