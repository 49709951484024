import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

//components and styles
import { Button, colorValues } from '@betterpt/better-components';
import { IcArrowDown, IcArrowUp, IcRemove, IcAdd, } from '@betterpt/better-icons';
import QuestionTypeSelectInputNew from './QuestionsTypeSelectInputNew';
import CustomFormInputNew from './CustomFormInputNew';
import MultipleChoiceAnswersTemplateNew from './MultipleChoiceAnswersTemplateNew';
import CustomCheckboxInputNew from './CustomCheckboxInputNew';
import CustomErrorField from './CustomErrorField';

//types
import { Question } from './CustomTypes'

const EditButton = styled.button<{ color: string }>`
  border: none;
  background-color: ${(question) => question.color};
  height: 28px;
  width: 40px;
  border-radius: 5px;
  margin: 5px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 5px;
`;

const QuestionContainer = styled.div`
  position: relative;
  margin: 20px 0px;
  border: 2px solid ${colorValues.concretejungle};
  border-radius: 5px;
  padding: 12.3px 14.5px 12.7px 12.8px;
`;

const QuestionNumber = styled.h3`
  margin: 4px 60.7px 20px 0;
  color: ${colorValues.charcoalgray};
`;

const AddAnotherContainer = styled.div`
  border-bottom: 1px solid ${colorValues.coneyislandsidewalk};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-left: 20px;
`;

const AddAnother = styled.p`
  color: ${colorValues.frenchblue};
  font-size: 14px;
  border-bottom: 1px solid ${colorValues.frenchblue};
  margin: 0px;
`;

type Props = {
    question: Question;
    index: number;
    actions: any;
    childQuestions?: Question[];
    style?: any;
    isRequired?: boolean;
}

const QuestionContainerTemplate = (props: Props) => {
    let {
        question,
        index,
        actions: { addDependentQuestion, deleteQuestion, reOrderQuestions, updateQuestion, canMoveUp, canMoveDown, changingTypePrompt, changeTypeState },
        childQuestions,
        style,
        isRequired = true } = props;

    let canMoveUpState = canMoveUp(question.sortOrder);
    let canMoveDownState = canMoveDown(question.sortOrder);
    let isSecondaryQuestion = question.level === 1;
    let isPrimaryQuestion = question.level === 0;
    let canHaveSecondaryQuestions = question.questionType === "Yes/No";

    return (
        <QuestionContainer key={question.id} style={style}>
            <QuestionNumber className='H3'>
                {isSecondaryQuestion && 'Secondary'} Question {question.sortOrder}
            </QuestionNumber>
            {/* this field only appears for dependent questions to determine which option is their parent */}
            {isSecondaryQuestion &&
                <CustomErrorField rawErrors={question.errors} errorField="parentType">
                    <QuestionTypeSelectInputNew
                        value={question.parentType}
                        label="Which patient answer is this secondary question dependent on?"
                        onChange={updateQuestion}
                        elementId={question.id}
                        elementKey="parentType"
                        options={['Yes', 'No']}
                    />
                </CustomErrorField>
            }
            <CustomErrorField rawErrors={question.errors} errorField="questionType">
                <QuestionTypeSelectInputNew
                    value={question.questionType}
                    label={isPrimaryQuestion ? "What type of question do you want to ask?" : "What type of secondary question do you want to ask?"}
                    onChange={updateQuestion}
                    elementId={question.id}
                    elementKey="questionType"
                    options={["Open-ended", "Multiple Choice", "Yes/No"]}
                    prompt={changingTypePrompt}
                    hasDependency={question.hasDependency}
                    changeTypeState={changeTypeState}
                />
            </CustomErrorField>
            {/* Question Statement and Other fields related to question only shown once question type has been selected */}
            {question.questionType &&
                <>
                    <CustomErrorField rawErrors={question.errors} errorField="questionStatement">
                        <CustomFormInputNew
                            value={question.questionStatement}
                            onChange={updateQuestion}
                            label={isPrimaryQuestion ? "What is the text of the question?" : "Enter your question below"}
                            elementId={question.id}
                            elementKey="questionStatement"
                        />
                    </CustomErrorField>
                    {/* field only shown in case of multiple choice questions */}
                    {question.questionType === "Multiple Choice" &&
                        <MultipleChoiceAnswersTemplateNew
                            options={question.options}
                            updateQuestion={updateQuestion}
                            questionId={question.id}
                        />}
                    {/* checkboxes only available in case of questions being parent or first level questions */}

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {isRequired &&
                            <CustomCheckboxInputNew
                                value={question.required}
                                elementKey="required"
                                elementId={question.id}
                                onChange={updateQuestion}
                                level={question.level}
                                label="Question is required"
                            />}
                        {/* checkbox only shown in case of the parent question being a Yes or No Question */}
                        {isPrimaryQuestion && canHaveSecondaryQuestions &&
                            <CustomCheckboxInputNew
                                value={question.hasDependency}
                                elementKey="hasDependency"
                                elementId={question.id}
                                onChange={updateQuestion}
                                label="Prompt secondary questions based on user response"
                            />
                        }
                    </div>

                </>
            }
            <ButtonContainer>
                {/* sorting buttons for question only available for parent questions */}
                {isPrimaryQuestion &&
                    <>
                        <EditButton
                            title='Move question up'
                            color={canMoveUpState ? colorValues.frost : colorValues.dust}
                            onClick={() => reOrderQuestions(
                                question.sortOrder,
                                question.sortOrder - 1
                            )}
                            disabled={!canMoveUpState}
                        >
                            <IcArrowUp
                                color={
                                    canMoveUpState
                                        ? colorValues.eveningsky
                                        : colorValues.emptiness
                                }
                                opacity={1}
                                height='12px'
                                width='12px'
                            />
                        </EditButton>
                        <EditButton
                            title='Move question down'
                            color={
                                canMoveDownState ? colorValues.frost : colorValues.dust
                            }
                            onClick={() => reOrderQuestions(
                                question.sortOrder,
                                question.sortOrder + 1
                            )}
                            disabled={!canMoveDownState}
                        >
                            <IcArrowDown
                                color={
                                    canMoveDownState
                                        ? colorValues.eveningsky
                                        : colorValues.emptiness
                                }
                                opacity={1}
                                height='12px'
                                width='12px'
                            />
                        </EditButton>
                    </>}
                <EditButton
                    title='Delete question'
                    color={colorValues.orangepink}
                    onClick={() => deleteQuestion(question.id, question.level, question.parentId)}
                >
                    <IcRemove
                        height='25px'
                        width='25px'
                        color={colorValues.emptiness}
                    />
                </EditButton>
            </ButtonContainer>

            {question.hasDependency && isPrimaryQuestion &&
                <>
                    {childQuestions &&
                        childQuestions.map((childQuestion: Question, index: number) =>
                            <QuestionContainerTemplate
                                key={childQuestion.id}
                                question={childQuestion}
                                actions={props.actions}
                                index={index}
                                style={{ marginLeft: '20px', backgroundColor: colorValues.earlysmoke, border: 'none' }}
                                isRequired={question.required}
                            />
                        )}
                    {canHaveSecondaryQuestions &&
                        <AddAnotherContainer
                            onClick={() => addDependentQuestion(question.id, question.required)}
                            onKeyDown={(e) => e.key === 'Enter' && addDependentQuestion(question.id, question.required)}
                            role='button'
                            tabIndex={0}
                            id='clickToAddResponse'
                            aria-label='Click to add another user response'
                        >
                            <AddAnother>
                                Click here to add another secondary question
                            </AddAnother>
                            <IcAdd />
                        </AddAnotherContainer>
                    }
                </>
            }
        </QuestionContainer>
    );

}

export default QuestionContainerTemplate;