import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

//components
import { colorValues, Table } from '@betterpt/better-components';
import { GridCard } from '../../../../../Shared/GridCard';
import { ListTitle, CardListBody } from '../../styles/EmployeeDetail.style';
import Can from '../../../../../Can';

//queries
import {
  useMeQuery,
  useEmployeeQuery,
} from '../../../../../../generated/graphql';

//hooks
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';

// helpers
import { Roles } from '../../../../../../helpers/rbac-rules';
import { possessiveName } from '../../../../../../helpers';

// assets
import { IcSuccessCircle } from '@betterpt/better-icons';

const FacilitiesList = () => {
  const { employeeId } = useParams<{ employeeId: string }>();
  const history = useHistory();

  const {
    employeeSingularAlias,
    facilitiesPluralAlias,
    facilitiesSingularAlias,
  } = useRemoteConfigAliases();

  const employeeQuery = useEmployeeQuery({
    variables: { id: employeeId },
    skip: !employeeId,
  });

  const meQuery = useMeQuery();

  const noAppointmentsCreated =
    employeeQuery.called &&
    !employeeQuery.loading &&
    !employeeQuery.data?.employee?.clinics?.length;

  const tableColumns = [
    { field: 'facilityName', title: 'NAME' },
    { field: 'id', title: 'ID' },
    { field: 'timeZone', title: 'TIME ZONE' },
    { field: 'address', title: 'ADDRESS' },
    { field: 'city', title: 'CITY' },
    { field: 'state', title: 'STATE' },
    {
      field: 'isMyFacility',
      title: `IS MY ${facilitiesSingularAlias.toUpperCase()}`,
    },
  ];

  const rowData =
    employeeQuery.data?.employee?.clinics?.map((clinic) => ({
      facilityName: clinic?.clinicName ?? '-',
      id: clinic?.id ?? '-',
      timeZone: clinic?.timeZone ?? '-',
      address: clinic?.location?.address ?? '-',
      city: clinic?.location?.city ?? '-',
      state: clinic?.location?.state ?? '-',
      isMyFacility: meQuery.data?.me?.clinics
        ?.map((clinic) => clinic?.id)
        .includes(clinic?.id) ? (
        <p
          style={{
            margin: 0,
            color: colorValues.betterptblack,
            position: 'relative',
          }}
        >
          <span style={{ position: 'absolute', left: '-27px', bottom: '-7px' }}>
            <IcSuccessCircle color={colorValues.guajirogreen} />
          </span>{' '}
          is my {facilitiesSingularAlias.toLowerCase()}
        </p>
      ) : (
        <p style={{ margin: 0, color: colorValues.charcoalgray }}>
          not my {facilitiesSingularAlias.toLowerCase()}
        </p>
      ),
    })) ?? [];

  return (
    <>
      <ListTitle>
        {possessiveName(employeeQuery.data?.employee?.firstName)}{' '}
        {facilitiesPluralAlias}
      </ListTitle>
      <GridCard style={{ width: '100%', marginRight: 0 }}>
        <Can
          role={Roles[meQuery.data?.me?.role || 'initial']}
          perform='employee:viewAppointments'
          yes={() => (
            <>
              {noAppointmentsCreated ? (
                <CardListBody>
                  <p>
                    This {employeeSingularAlias.toLowerCase()} does not belong
                    to any {facilitiesPluralAlias.toLowerCase()}.
                  </p>
                </CardListBody>
              ) : (
                <Table
                  columns={tableColumns}
                  data={rowData}
                  isLoading={employeeQuery.loading || meQuery.loading}
                  onRowClick={(_e, rowData) =>
                    history.push(`/facilities/${rowData?.id}`)
                  }
                  options={{
                    minBodyHeight: 440,
                    maxBodyHeight: 440,
                    overflow: 'auto',
                    searchNotFoundText: `${facilitiesSingularAlias} Not Found`,
                  }}
                />
              )}
            </>
          )}
          no={() => (
            <CardListBody>
              <p>
                You do not have permission to view the{' '}
                {facilitiesPluralAlias.toLowerCase()} of this{' '}
                {employeeSingularAlias.toLowerCase()}.
                {employeeId === meQuery.data?.me?.id && (
                  <>
                    <br />
                    <br />
                    To view your own {facilitiesPluralAlias.toLowerCase()},
                    please go to your profile <Link to='/profile'>here</Link>.
                  </>
                )}
              </p>
            </CardListBody>
          )}
        />
      </GridCard>
    </>
  );
};

export default FacilitiesList;
