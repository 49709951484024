import styled from '@emotion/styled';
import { colorValues, bestTextColor } from '@betterpt/better-components';

// Main View Styles
export const Page = styled.div`
  margin: 31px 12px;
`;

// Header Styles
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 38px;
  margin-left: 17px;
  max-width: 1170px;
`;

export const PhotoAndNameSection = styled.div`
  display: flex;
  align-items: center;
  h1 {
    font-size: 24px;
    max-width: 600px;
  }
`;

export const logoutButtonStyle = (color?: string, primary?: boolean) => {
  const bgColor = (primary && color) || colorValues.dust;
  const textColor = bestTextColor(bgColor);
  return {
    backgroundColor: bgColor,
    color: textColor,
    width: 110,
  };
};

//Form Styles
export const H4 = styled.h4`
  color: ${colorValues.betterptblack};
`;

export const FormBody = styled.div`
  padding: 11px 23px 13px 42px;
  & .MuiInputBase-multiline {
    padding: 6px 0;
  }
`;

export const Message = styled.p`
  font-size: 12px;
`;

export const multilineInputStyle = { padding: 0 };

// list styles
export const ListTitle = styled.h4`
  font-size: 20px;
  margin: 22px 10px;
`;

export const CardListBody = styled.div`
  margin: 10px 44px;
  p {
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 50px;
    margin-top: 50px;
    color: ${colorValues.cityblock};
  }
`;
