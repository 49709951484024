import styled from '@emotion/styled';
import { colorValues, bestTextColor } from '@betterpt/better-components';

export const Form = styled.form<{ isInModal?: boolean }>`
  max-width: 1133px;
  min-height: ${(props) => (props.isInModal ? 'auto' : '120vh')};
  width: 100%;
`;

export const cardStyle = {
  margin: '40px 28px',
  width: '95%',
};
export const CardHeader = styled.div`
  padding: 45px 25px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colorValues.concretejungle};
  flex-wrap: wrap;
  position: relative;
  h1 {
    display: flex;
    align-items: center;
    svg {
      margin-right: 40px;
    }
  }
`;

export const CardBody = styled.div``;

export const CardFooter = styled.div`
  background-color: ${colorValues.earlysteam};
  width: 100%;
  height: 73px;
  text-align: center;
  p {
    max-width: 508px;
    margin: 25px auto;
  }
`;

export const BackButton = styled.button`
  left: 10px;
  top: 10px;
  background-color: transparent;
  border: none;
`;

export const buttonStyle = (color?: string) => ({
  backgroundColor: color || colorValues.cityblock,
  color: bestTextColor(color ?? colorValues.cityblock),
  fontWeight: 'bold' as 'bold',
  marginTop: '20px',
});

export const NotAvailableContainer = styled.div`
  width: 100%;
  height: 90vh;
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  h2 {
    margin: 25px auto;
    font-size: 20px;
  }
  .inner-container {
    max-width: 400px;
    text-align: center;
    svg {
      height: 137px;
      width: 137px;
    }
  }
  p {
    text-align: left;
  }
`;

export const DialogBody = styled.div`
  padding: 52px;
`;

export const DialogTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
`;

export const DialogMessage = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
`;
