import { jsx } from '@emotion/react';
import React from 'react';

//types
import { FilterOption, FilterSelectionMode } from './';
import { ButtonColor } from '../Button/constants';

//helpers
import {
  pluralNoun,
  bestTextColor,
  alphabeticalSort,
} from '../helpers/functions';

//hooks
import { useDebounce } from '../helpers/hooks';

//components
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import Button from '../Button';
import Checkbox from '../Checkbox';
import InfoBox from '../InfoBox';
import TextField from '../TextField';

//graphics
import IcDropDownSelected from '../Icons/IcDropDownSelected';

//styles
import { colorValues } from '../helpers/colors';
import {
  FilterButton,
  FilterContainer,
  FilterLabel,
  OptionsContainer,
  OptionsList,
  radioStyle,
  RadioLabel,
  rotateStyles,
} from './style';

type Props = {
  type: FilterSelectionMode;
  name: string;
  useFilter?: boolean;
  search?: {
    mode?: 'external' | 'interal';
    searchText?: { value: string; update: (searchText: string) => void };
  };
  boxTitle?: string;
  icon?: any;
  options: FilterOption[];
  onSubmit?: (selected: FilterOption[]) => void;
  customButtonTitle?: (selected: FilterOption[]) => string;
  sort?: boolean;
  loading?: boolean;
  complete?: boolean;
  rotateIcon?: boolean;
  applyButtonColor?: ButtonColor;
  applyButtonText?: string;
  overrideButtonColor?: string;
  customButton?: React.ReactNode;
  openStatus?: { value: boolean; update: (open: boolean) => void };
  callOnOpen?: () => void;
  style?: {
    box?: React.CSSProperties;
    button?: React.CSSProperties;
    arrow?: React.CSSProperties;
    labelPadding?: string;
    buttonMargin?: string;
    [key: string]: any;
  };
};

const Filter = ({
  type,
  name,
  useFilter,
  search,
  boxTitle,
  icon,
  options,
  onSubmit,
  customButtonTitle,
  sort = true,
  loading,
  complete,
  rotateIcon,
  applyButtonColor,
  applyButtonText,
  overrideButtonColor,
  customButton,
  openStatus,
  callOnOpen,
  style,
}: Props) => {
  let activeTimeout: any | undefined;
  let animationTimeout: any | undefined;
  const kebabCaseLabel = (option: FilterOption) =>
    option?.label?.toLowerCase()?.replace(/\s/g, '-');
  const startingVisibleOptions = options.filter((option) => !option?.hidden);
  const searchText = search?.searchText;
  const usingExternalSearch = search?.mode === 'external';

  const useCheckboxes = type === 'check';
  const startingHeightBuffer = (options.length - 1) * 46;
  const defaultBoxMin = 260;
  const [boxHeight, setBoxHeight] = React.useState(
    defaultBoxMin + startingHeightBuffer
  );

  const bestSearchTerm = usingExternalSearch
    ? searchText?.value ?? ''
    : useDebounce(searchText?.value ?? '', 500);
  const [isOpen, setOpenTo] = React.useState(false);
  const [rotate, setRotate] = React.useState(false);
  const [buttonTitle, setButtonTitle] = React.useState('loading...');
  const [visibleOptions, setVisibleOptions] = React.useState(
    startingVisibleOptions
  );
  const [
    selectedVisibleOptionIndex,
    setSelectedVisibleOptionIndex,
  ] = React.useState<number>(-1);

  const checkNoneSelected = () => {
    const selected = visibleOptions.filter(
      (option) => option?.selected && !option?.hidden
    );
    return (
      (selectedVisibleOptionIndex < 0 && !selected.length) ||
      selected.some((option) => option?.isResetOption)
    );
  };

  const sortOptions = (optionsToSort?: FilterOption[]) => {
    if (useCheckboxes) {
      let newVisibleOptions = optionsToSort ?? [
        ...options.filter((option) => !option?.hidden),
      ];
      //account for the presence of a reset option, to keep it on top
      const [resetOption] = newVisibleOptions.filter(
        (option) => option?.isResetOption
      );
      //select the options that are NOT a reset option
      const mainOptions = newVisibleOptions.filter(
        (option) => !option?.isResetOption
      );

      if(sort) { // with sorting
        //set which options are already selected, if any
        const selected: FilterOption[] = [];
        const notSelected: FilterOption[] = [];
        mainOptions.forEach((option) => {
          if (option?.selected) {
            selected.push(option);
          } else notSelected.push(option);
        });
        //keep the un-selected boxes back in their proper order
        const sortedNotSelected = alphabeticalSort(
          notSelected,
          'label',
          'fullTitle'
        );
        newVisibleOptions = resetOption
          ? [resetOption, ...selected, ...sortedNotSelected]
          : [...selected, ...sortedNotSelected];
      } else { // without sort
        newVisibleOptions = resetOption
        ? [resetOption, ...mainOptions]
        : [...mainOptions];
      }
      setVisibleOptions(newVisibleOptions);
    }
  };

  const toggleCheckbox = (option: FilterOption) => {
    const workingOptions = [...visibleOptions];
    const updatedOptions = workingOptions.map((visibleOption) => {
      if (option?.isResetOption && !option?.selected) {
        //if you select the reset option, check it and uncheck everything else
        return {
          ...visibleOption,
          selected: visibleOption === option,
        };
      } else if (!option?.isResetOption) {
        //if you check a normal option, make sure the reset is unchecked
        if (visibleOption.isResetOption) {
          return {
            ...visibleOption,
            selected: false,
          };
        } else
          return {
            ...visibleOption,
            selected:
              visibleOption === option
                ? !visibleOption.selected
                : visibleOption.selected,
          };
      } else return visibleOption;
    });
    sortOptions(updatedOptions);
  };

  //set up which radio button is selected
  React.useEffect(() => {
    let noneSelected = true;
    visibleOptions.forEach((_option, i) => {
      if (visibleOptions[i]?.selected) {
        setSelectedVisibleOptionIndex(i);
        noneSelected = false;
      }
    });
    if (noneSelected) setSelectedVisibleOptionIndex(-1);
  }, [visibleOptions]);

  // reset visible options if main options change
  React.useEffect(() => {
    setVisibleOptions(options.filter((option) => !option?.hidden));
  }, [options]);

  //to handle lazy loading and/or search text state
  React.useEffect(() => {
    openStatus?.update(isOpen);
    if (isOpen) {
      callOnOpen?.();
    }
    if (!isOpen && !usingExternalSearch) {
      searchText?.update?.('');
    }
  }, [isOpen]);

  //minitor working options to update button title
  React.useEffect(() => {
    handleButtonTitle();
  }, [visibleOptions]);

  //monitor raw parent options for initial set up of:
  //options list, button title, and filter's box height (to account for hidden items)
  React.useEffect(() => {
    let computedHeightBuffer = (options.length - 1) * 46;
    if (startingVisibleOptions.length > 6) {
      computedHeightBuffer = 5 * 46;
    } else {
      for (let i = 0; i < options.length; i++) {
        if (options[i]?.hidden) {
          computedHeightBuffer -= 46;
        }
      }
    }
    sortOptions();
    handleButtonTitle();
    setBoxHeight(defaultBoxMin + computedHeightBuffer);
  }, [options]);

  //handle search
  React.useEffect(() => {
    if (!usingExternalSearch) {
      if (bestSearchTerm.length) {
        const searchResults = visibleOptions.filter(
          (option) =>
            option?.label
              ?.toLowerCase()
              .includes(bestSearchTerm.toLowerCase()) ||
            option?.fullTitle
              ?.toLowerCase()
              .includes(bestSearchTerm.toLowerCase())
        );
        sortOptions(searchResults);
      } else sortOptions();
    }
  }, [bestSearchTerm, isOpen, usingExternalSearch]);

  //handle success animation
  React.useEffect(() => {
    if (complete) {
      activeTimeout = setTimeout(() => {
        setOpenTo(false);
      }, 500);
    }
    //cleanup any lingering timeout effects on dismount
    return () => {
      if (activeTimeout) clearTimeout(activeTimeout);
    };
  }, [complete]);

  //handle rotate icon animation
  React.useEffect(() => {
    if (rotate && rotateIcon) {
      animationTimeout = setTimeout(() => {
        setRotate(false);
      }, 333);
    }
    //cleanup any lingering timeout effects on dismount
    return () => {
      if (animationTimeout) clearTimeout(animationTimeout);
    };
  }, [rotate]);

  const handleButtonTitle = () => {
    const noneSelected = checkNoneSelected();
    const selectedOptions = visibleOptions.filter(
      (option) => option?.selected && !option?.isResetOption
    );
    if (noneSelected) {
      setButtonTitle(`all ${pluralNoun(name)}`);
    } else {
      const bestCategoryName =
        selectedOptions.length > 1 || !selectedOptions.length ? pluralNoun(name) : name;
      const bestCheckTitle = customButtonTitle?.(selectedOptions) ?? `${selectedOptions.length || 'all'} ${bestCategoryName}`;
      const bestRadioTitle =
        visibleOptions[selectedVisibleOptionIndex]?.fullTitle ??
        visibleOptions[selectedVisibleOptionIndex]?.label;
      const bestButtonTitle = useCheckboxes ? bestCheckTitle : bestRadioTitle;

      setButtonTitle(bestButtonTitle ?? '');
    }
  };

  const handleApplyFilter = () => {
    if (useCheckboxes) {
      const selectedOptions = visibleOptions.filter(
        (option) => option?.selected && !option?.isResetOption
      );
      onSubmit?.(selectedOptions);
    } else {
      const callback = visibleOptions[selectedVisibleOptionIndex]?.callback;
      callback?.();
    }
    if (!usingExternalSearch) searchText?.update?.('');
    setOpenTo(false);
  };

  const applyButtonStyle = () => {
    const buttonStyle: any = {
      height: 36,
      width: 255,
      margin: '20px 25px 0',
      ...(style?.button ?? {}),
    };
    if (overrideButtonColor) {
      buttonStyle.backgroundColor = overrideButtonColor;
      buttonStyle.color = bestTextColor(overrideButtonColor);
    }
    return buttonStyle;
  };

  const renderOptions = () => {
    const optionsBody = (
      <OptionsList>
        {visibleOptions.map(
          (option: FilterOption, index: number) =>
            !option?.hidden && (
              <FormControlLabel
                key={(option?.label ?? 'filter') + index}
                value={index}
                control={
                  useCheckboxes ? (
                    <Checkbox
                      id={`filter-checkbox-${kebabCaseLabel(option)}`}
                      disabled={option?.disabled}
                      checked={option?.selected}
                      onChange={() => toggleCheckbox(option)}
                      inputProps={{
                        ['data-testid' as any]: `filter-checkbox-${kebabCaseLabel(
                          option
                        )}`,
                      }}
                    />
                  ) : (
                    <Radio
                      css={radioStyle}
                      disabled={option?.disabled}
                      id={`filter-radio-${kebabCaseLabel(option)}`}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      inputProps={{
                        ['data-testid' as any]: `filter-radio-${kebabCaseLabel(
                          option
                        )}`,
                      }}
                    />
                  )
                }
                label={
                  <RadioLabel
                    data-testid={`filter-label-${kebabCaseLabel(option)}`}
                    style={{
                      color: option?.disabled
                        ? colorValues.cityblock
                        : colorValues.betterptblack,
                      display: 'flex',
                      alignItems: 'center',
                      wordBreak: 'break-word',
                    }}
                  >
                    {option?.label ?? '-'}
                    {option?.icon ?? null}
                  </RadioLabel>
                }
              />
            )
        )}
      </OptionsList>
    );

    return useCheckboxes ? (
      optionsBody
    ) : (
      <RadioGroup
        name='options'
        value={selectedVisibleOptionIndex}
        onChange={(e) => {
          e.stopPropagation();
          setSelectedVisibleOptionIndex(parseInt(e.target.value));
        }}
      >
        {optionsBody}
      </RadioGroup>
    );
  };

  return (
    <FilterContainer margin={style?.buttonMargin}>
      <InfoBox
        openWith='click'
        isOpen={isOpen}
        toggleBox={setOpenTo}
        width={280}
        height={boxHeight}
        iconHeight={36}
        iconWidth={204}
        position='bottom'
        padding={2}
        testId={`${name}-filter`}
        boxStyle={{
          display: isOpen ? 'flex' : 'none',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '15px 25px 25px 0px',
          ...(style?.box ?? {}),
        }}
        arrowStyle={style?.arrow}
        icon={
          customButton ?? (
            <FilterButton
              open={isOpen}
              css={rotateStyles}
              onClick={() => rotateIcon && setRotate(true)}
            >
              <FilterLabel padding={style?.labelPadding}>
                <div
                  className={rotate && rotateIcon ? 'rotationContainer' : ''}
                >
                  {icon ?? (
                    <IcDropDownSelected
                      style={{ width: 20, height: 20, marginTop: 5 }}
                      color={colorValues.charcoalgray}
                      css={rotate && rotateIcon ? rotateStyles : undefined}
                    />
                  )}
                </div>
                <p style={{whiteSpace: 'break-spaces', fontSize: '10px'}}>{loading ? 'loading...' : buttonTitle?.toLowerCase()}</p>
              </FilterLabel>
            </FilterButton>
          )
        }
      >
        <h4 style={{ paddingLeft: 35, marginBottom: 5 }}>
          {(boxTitle ?? name).toUpperCase()}
        </h4>
        <OptionsContainer
          style={{ overflow: style?.overflow, maxHeight: style?.maxHeight }}
        >
          {useFilter && (
            <div>
              <TextField
                value={searchText?.value ?? ''}
                placeholder={`Type to ${
                  usingExternalSearch ? 'search' : 'filter'
                }`}
                onChange={(e) => searchText?.update?.(e.target.value)}
                overrideStyle={{
                  margin: '0 auto',
                  width: 210,
                }}
              />
              {visibleOptions.length < 1 && !!bestSearchTerm.length && (
                <p style={{ marginLeft: 34 }}>No results</p>
              )}
            </div>
          )}
          {renderOptions()}
        </OptionsContainer>
        <Button
          loading={loading}
          color={applyButtonColor}
          size='small'
          style={applyButtonStyle()}
          fullWidth
          onClick={handleApplyFilter}
          success={complete}
        >
          {applyButtonText ?? 'APPLY FILTER'}
        </Button>
      </InfoBox>
    </FilterContainer>
  );
};

export default Filter;