import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { colorValues, bestTextColor } from '@betterpt/better-components';

export const datePickerSelectedColor = (primaryColor: string) => css`
  .rdrDayNumber {
    color: ${bestTextColor(primaryColor)};
  }
`;

export const DatePickerStyleWrapper = styled.div`
  .rdrInfiniteMonths {
    height: 272px !important;
  }
  .rdrMonth {
    border: 1px solid ${colorValues.concretejungle};
    border-radius: 5px;
    height: 250px !important;
    margin: 0;
  }
  .rdrMonthName {
    color: ${colorValues.betterptblack};
  }
  .rdrWeekDay {
    color: ${colorValues.cauldronblack};
  }
`;

export const FilterContainer = styled.div`
  position: relative;
  margin: 0px;
  display: flex;
  h2 {
    color: ${colorValues.cityblock};
  }
`;

export const DatePickerDialogBody = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px;
  height: 404px;
`;

export const sectionDates = {
  display: 'flex',
  alignItems: 'center',
};

export const inputSectionStyle = {
  width: 218,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column' as 'column',
  position: 'relative' as 'relative',
  marginLeft: 10,
};
