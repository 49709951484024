import * as React from 'react';
import { format } from 'date-fns-tz';
import FormField from '../../../../../Shared/FormField';
import { TextFieldState } from '../../../../../../hooks/useTextFieldState';
import { ServiceAreaHeader } from './FacilityContact.style';
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';
import states from '../../../../../../constants/states';

type Props = {
  city: TextFieldState;
  state: TextFieldState;
  serviceAreaName: TextFieldState;
  isFormDisabled: boolean;
  timeZone: string;
  shouldDisableYextFields: boolean;
};

const ServiceAreaFields: React.FC<React.PropsWithChildren<Props>> = ({
  city,
  state,
  serviceAreaName,
  isFormDisabled,
  timeZone,
  shouldDisableYextFields,
}) => {
  const { primaryColor } = useRemoteConfigAliases();

  return (
    <>
      <ServiceAreaHeader color={primaryColor}>
        <h2 className='H4'>Service Area</h2>
        <h3 className='timezone H6'>
          {format(new Date(), 'zzzz', { timeZone }).toUpperCase()}
        </h3>
      </ServiceAreaHeader>
      <FormField
        style={{ margin: '20px 0px' }}
        label='Service Area Name'
        formHandler={serviceAreaName}
        id='serviceAreaNameInput'
        disabled={isFormDisabled}
        fullWidth
        inputProps={{
          maxLength: 255,
        }}
      />
      <FormField
        style={{ margin: '20px 0px' }}
        label='City'
        formHandler={city}
        id='cityInput'
        disabled={shouldDisableYextFields || isFormDisabled}
        fullWidth
        inputProps={{
          maxLength: 255,
        }}
      />
      <FormField
        style={{ margin: '20px 0px' }}
        label='State'
        formHandler={state}
        id='stateInput'
        disabled={shouldDisableYextFields || isFormDisabled}
        fullWidth
        select
        SelectProps={{ native: true }}
      >
        {states.map((state) => (
          <option key={state.value} value={state.value}>
            {state.text}
          </option>
        ))}
      </FormField>
    </>
  );
};

export default ServiceAreaFields;
