import React from 'react';

//types
import { Employee, Maybe } from '../../../../generated/graphql';
import { SetAppointmentTypeProvidersPayload } from '../hooks/useAppointmentTypeDetailOperations';

//helpers
import {
  facilitiesString,
  hasLoggedIn,
} from '../../Employees/EmployeesList/EmployeesTable';
import { Roles, RoleToString } from '../../../../helpers/rbac-rules';

//hooks
import useHandleError from '../../../../hooks/useHandleError';
import { useParams } from 'react-router-dom';
import useDebounce from '../../../../hooks/useDebounce';
import useSnackbar from '../../../../hooks/useSnackbar';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import {
  useCompanyEmployeesLazyQuery,
  useMeForProfileCheckQuery,
} from '../../../../generated/graphql';

// components
import {
  Button,
  Checkbox,
  CloseButton,
  Table,
} from '@betterpt/better-components';
import { Dialog } from '@material-ui/core';
import SlideTransition from '../../../Shared/SlideTransition';

import styled from '@emotion/styled';
//style
const DialogBody = styled.div`
  width: 100%;
  margin: '0 auto';
  padding-bottom: 10px;
`;
const TableContainer = styled.div`
  padding-top: 20px;
`;
const TableTitle = styled.h1`
  font-size: 18px;
  margin: 0 10px;
`;
const NameField = styled.div`
  display: flex;
  align-items: center;
`;
const dialogStyle = {
  width: '80%',
  margin: '0 auto',
  display: 'block',
  marginTop: '8vh',
};
const paperStyle = {
  borderRadius: '5px 5px 0 0 ',
  position: 'relative' as 'relative',
};
const checkboxStyle = { padding: 0, marginRight: 10 };
const buttonStyle = (primaryColor: string) => ({
  minWidth: 316,
  backgroundColor: primaryColor,
});

interface Props {
  open: boolean;
  onClose: () => void;
  existingProviderIds: string[];
  setAppointmentTypeProviders: (
    payload: SetAppointmentTypeProvidersPayload
  ) => Promise<boolean>;
}

const SetAppointmentTypeProvidersDialog = ({
  open,
  onClose,
  existingProviderIds,
  setAppointmentTypeProviders,
}: Props) => {
  const snackbar = useSnackbar();
  const handleError = useHandleError();
  const { primaryColor, employeePluralAlias, facilitiesPluralAlias } =
    useRemoteConfigAliases();
  const { appointmentTypeId } = useParams<{
    appointmentTypeId: string;
  }>();
  const meQuery = useMeForProfileCheckQuery({
    fetchPolicy: 'cache-first',
  });

  const [saving, updateSaving] = React.useState(false);
  const pageSize = 7;
  const [offset, setOffset] = React.useState(0);
  const [searchText, setSearchText] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [providerIds, updateProviderIds] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (!saving) {
      updateProviderIds(existingProviderIds);
    }
  }, [existingProviderIds, saving]);

  React.useEffect(() => {
    if (!open) {
      setOffset(0);
      setSearchText('');
    }
  }, [open]);

  const [callCompanyEmployeesQuery, companyEmployeesQuery] =
    useCompanyEmployeesLazyQuery({
      variables: {
        id: meQuery.data?.me?.company?.id!,
        input: {
          limit: pageSize,
          offset,
          query: debouncedSearchTerm,
        },
      },
    });

  React.useEffect(() => {
    if (
      meQuery.data?.me?.company?.id &&
      !isNaN(Number(meQuery.data?.me?.company?.id)) &&
      open
    ) {
      callCompanyEmployeesQuery();
    }
  }, [callCompanyEmployeesQuery, meQuery.data, open]);

  const handleSubmit = async () => {
    updateSaving(true);
    try {
      const updatedBackend = await setAppointmentTypeProviders({
        appointmentTypeId,
        providerIds,
        existingProviderIds,
      });
      if (updatedBackend) {
        snackbar?.setSuccessMessage(
          `Appointment type ${employeePluralAlias.toLowerCase()} saved`
        );
      }
      updateSaving(false);
      onClose();
    } catch (e) {
      updateSaving(false);
      handleError(e);
    }
  };

  const handleCheckbox = (employeeId?: string) => {
    if (employeeId) {
      if (providerIds.includes(employeeId)) {
        updateProviderIds((current) =>
          current.filter((id) => id !== employeeId)
        );
      } else {
        updateProviderIds((current) => [...current, employeeId]);
      }
    }
  };

  const nameField = (employee?: Maybe<Partial<Employee>>) => (
    <NameField>
      <Checkbox
        style={checkboxStyle}
        checked={providerIds.includes(employee?.id ?? '')}
        onClick={() => handleCheckbox(employee?.id)}
      />
      <p>{`${employee?.firstName ?? '-'} ${employee?.lastName ?? '-'}`}</p>
    </NameField>
  );

  const columns = [
    { field: 'name', title: 'NAME' },
    { field: 'id', title: 'ID' },
    { field: 'email', title: 'EMAIL' },
    { field: 'clinics', title: facilitiesPluralAlias.toUpperCase() },
    { field: 'role', title: 'ROLE' },
    { field: 'hasLoggedIn', title: 'HAS LOGGED IN' },
    { field: 'spaceFiller', title: '' },
  ];

  const rowData = (
    companyEmployeesQuery.data?.company?.employees?.result ?? []
  ).map((employee) => ({
    name: nameField(employee),
    id: employee?.id ?? '-',
    email: employee?.email ?? '-',
    clinics: facilitiesString(employee?.clinics),
    role: RoleToString(Roles[employee?.role ?? 'initial']),
    hasLoggedIn: hasLoggedIn(employee?.hasLoggedIn),
  }));

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      style={dialogStyle}
      TransitionComponent={SlideTransition}
      PaperProps={{ style: paperStyle }}
    >
      <DialogBody>
        <TableContainer>
          <Table
            columns={columns}
            data={rowData}
            isLoading={companyEmployeesQuery.loading}
            onSearchChange={(text) => {
              setOffset(0);
              setSearchText(text);
            }}
            options={{
              toolbar: {
                title: (
                  <TableTitle>
                    Assign {employeePluralAlias} to this Appointment Type
                  </TableTitle>
                ),
                searchLabel: `Search ${employeePluralAlias}`,
                searchPlaceholder: 'Enter name or email',
                searchValue: searchText,
                searchSide: 'left',
                searchWidth: 150,
                buttonColor: primaryColor,
                CTA: (
                  <Button
                    onClick={handleSubmit}
                    loading={saving}
                    style={buttonStyle(primaryColor)}
                  >
                    {providerIds.length} SELECTED, CLICK TO FINISH
                  </Button>
                ),
              },
              minBodyHeight: 500,
              pagination: {
                backToFirstPage: !!searchText,
                totalCount:
                  companyEmployeesQuery.data?.company?.employees?.pager
                    ?.total ?? 0,
                pagesize: pageSize,
                onChangePage: (pageSize, idx) => setOffset(idx * pageSize),
              },
            }}
          />
        </TableContainer>
      </DialogBody>
      <CloseButton onClose={onClose} />
    </Dialog>
  );
};

export default SetAppointmentTypeProvidersDialog;
