import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='25'
    viewBox='0 0 24 25'
    {...rest}
  >
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0L23.245 0 23.245 24 0 24z' transform='translate(.613 1)' />
      <path
        fill={color || '#E74C3C'}
        fillRule='nonzero'
        d='M11.623 1c5.88 0 10.653 4.928 10.653 11s-4.772 11-10.653 11S.969 18.072.969 12 5.742 1 11.623 1zm4.741 4.678l-4.77 4.924L6.84 5.693l-1.356 1.4 4.755 4.91-4.8 4.956 1.356 1.4 4.8-4.957 4.833 4.991 1.356-1.4-4.833-4.99 4.769-4.925-1.356-1.4z'
        transform='translate(.613 1)'
      />
    </g>
  </svg>
);
