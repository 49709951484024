import React from 'react';
import { colorValues, TextField } from '@betterpt/better-components';
import { Widget } from '@rjsf/core';

const textFieldStyle = { margin: '10px 0px 20px 0px' };

const QuestionTypeSelectInput: Widget = (props) => {
  const { value = '', label, onChange, schema } = props;

  const styleOverride = {
    '& label.Mui-focused': {
      color: colorValues.guajirogreen,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colorValues.guajirogreen,
    },
  };
  return (
    <TextField
      value={value}
      label={label}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      select
      SelectProps={{ native: true }}
      style={textFieldStyle}
      overrideStyle={styleOverride}
      inputProps={{
        style: {
          fontWeight: 'bold',
          fontSize: '12px',
        },
      }}
    >
      <option disabled value={''}></option>
      {schema.enum?.map((choice: any) => (
        <option key={choice as string} value={choice as string}>
          {choice}
        </option>
      ))}
    </TextField>
  );
};

export default QuestionTypeSelectInput;
