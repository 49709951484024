import React from 'react';

// components
import InPersonAppointments from './InPersonAppointments';
import BlockedPageMessage from '../../Shared/BlockedPageMessage';
import { ImgDanBeard } from '@betterpt/better-icons';

//hooks
import { useMeQuery, Role } from '../../../generated/graphql';
import useRemoteConfigAliases from '../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../hooks/useSnackbar';

const Appointments = () => {
  const meQuery = useMeQuery();
  const snackbar = useSnackbar();
  const role = meQuery.data?.me?.role;
  const { facilitiesPluralAlias } = useRemoteConfigAliases();

  React.useEffect(() => {
    if (meQuery.error?.message) {
      snackbar?.useGenericErrorMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meQuery.error]);

  const hasClinics = role === Role.Owner || !!meQuery.data?.me?.clinics?.length;

  if (!hasClinics && !meQuery.loading) {
    return (
      <BlockedPageMessage
        icon={<ImgDanBeard />}
        title={`You haven’t been added to any ${facilitiesPluralAlias?.toLowerCase()} yet`}
        message={`You can’t create an appointment because you haven’t been added to any ${
          facilitiesPluralAlias?.toLowerCase() ?? ''
        } yet. Please contact your company owner or administrator and have them add you to your ${
          facilitiesPluralAlias?.toLowerCase() ?? ''
        } on the platform.`}
      />
    );
  }

  return <InPersonAppointments />;
};

export default Appointments;
