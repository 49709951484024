import React from 'react';
import { Button, colorValues, Header } from '@betterpt/better-components';
import { BorderedStyle, LogoImage } from './styles';
import Placeholder from '../../../../../../assets/img-unknown-cliniclogo.svg';
import { Clinic, Maybe } from '../../../../../../generated/graphql';
import { Link } from 'react-router-dom';
import StaffHoursInfoBox from './StaffHoursInfoBox';

interface Props {
  facility: Maybe<
    Pick<Clinic, 'id' | 'clinicName' | 'logoUrl' | 'shouldUseProviderSchedule'>
  >;
}

const DirectToClinicHours = ({ facility }: Props) => {
  if (!facility) return null;

  const { id, logoUrl, clinicName } = facility;
  const image = logoUrl ?? Placeholder;

  return (
    <BorderedStyle>
      <Header underline style={{ padding: '20px 25px 20px 20px' }}>
        <h4>
          <LogoImage src={image} alt='' />
          {clinicName}
          <StaffHoursInfoBox clinicName={clinicName} facilityId={id} />
        </h4>
        <Link to={`/facilities/${id}/config`}>
          <Button color='gray' size='small'>
            ADD HOURS
          </Button>
        </Link>
      </Header>
      <p className='Body redirect-message'>
        {clinicName} hours are set at the facility level, so this staff member’s
        hours will simply display as the facility’s hours of operation. To view{' '}
        {clinicName} hours of operation or make any changes to hours settings,
        click{' '}
        <Link
          to={`/facilities/${id}/config`}
          style={{ color: colorValues.frenchblue }}
        >
          here
        </Link>
        .
      </p>
    </BorderedStyle>
  );
};

export default DirectToClinicHours;
