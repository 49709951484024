import { format, parse } from 'date-fns';
import { MappedPatientInfoData } from '../components/Views/Patients/PatientDetail/PatientInfo';
import { MappedInsuranceInfoData } from '../components/Views/Patients/PatientDetail/InsuranceInfo';
import { Appointment, Patient, VideoAppointment } from '../generated/graphql';

export const mapAppointmentToPatientInfo = (
  appointment: Appointment | VideoAppointment
): MappedPatientInfoData => {
  const firstName =
    appointment.contact?.firstName ||
    appointment.patient?.firstName ||
    appointment.patientFirstName ||
    'Not Provided';
  const lastName =
    appointment.contact?.lastName ||
    appointment.patient?.lastName ||
    appointment?.patientLastName ||
    'Not Provided';
  const gender = appointment.patient?.gender || 'Not Provided';
  const birthday = appointment.contact?.dateOfBirth
    ? format(
        parse(appointment?.contact?.dateOfBirth, 'yyyy-MM-dd', new Date()),
        'MM/dd/yyyy'
      )
    : appointment.patient?.birthday
    ? format(
        parse(appointment.patient?.birthday, 'yyyy-MM-dd', new Date()),
        'MM/dd/yyyy'
      )
    : 'Not Provided';
  const email =
    appointment.contact?.email ||
    appointment.patient?.email ||
    appointment?.patientEmail ||
    'Not Provided';
  const phone =
    appointment.contact?.phone ||
    appointment.patient?.phone ||
    appointment?.patientPhoneNumber ||
    'Not Provided';
  const address =
    appointment.patient?.primaryAddress?.address || 'Not Provided';
  const address2 = appointment.patient?.primaryAddress?.address2 ?? '';
  const city = appointment.patient?.primaryAddress?.city || 'Not Provided';
  const state = appointment.patient?.primaryAddress?.state || 'Not Provided';
  const zipcode =
    appointment.patient?.primaryAddress?.zipcode || 'Not Provided';

  const preferredContactMethod = appointment.patient?.preferredContactMethod;

  return {
    firstName,
    lastName,
    gender,
    birthday,
    email,
    phone,
    address,
    address2,
    city,
    state,
    zipcode,
    preferredContactMethod,
  };
};

export const mapAppointmentToInsuranceInfo = (
  appointment: Appointment | VideoAppointment
): MappedInsuranceInfoData => {
  const insuranceCardFrontUrl = appointment.patient?.insuranceCardFrontUrl;
  const insuranceCardBackUrl = appointment.patient?.insuranceCardBackUrl;

  const primaryInsuranceCarrier = appointment.patient?.insuranceCarrier;
  const primaryInsurancePlan = appointment.patient?.insurancePlan;
  const primaryInsuranceMemberId = appointment.patient?.insuranceMemberId;

  const secondaryInsuranceCarrier =
    appointment.patient?.secondaryInsuranceCarrier;
  const secondaryInsurancePlan = appointment.patient?.secondaryInsurancePlan;
  const secondaryInsuranceMemberId =
    appointment.patient?.secondaryInsuranceMemberId;

  return {
    insuranceCardFrontUrl,
    insuranceCardBackUrl,
    primaryInsuranceCarrier,
    primaryInsurancePlan,
    primaryInsuranceMemberId,
    secondaryInsuranceCarrier,
    secondaryInsurancePlan,
    secondaryInsuranceMemberId,
  };
};

export const mapPatientToPatientInfo = (
  patient: Patient
): MappedPatientInfoData => {
  const firstName = patient?.firstName ?? 'Not Provided';
  const lastName = patient?.lastName || 'Not Provided';
  const gender = patient?.gender || 'Not Provided';
  const birthday = patient?.birthday
    ? format(parse(patient?.birthday, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy')
    : 'Not Provided';
  const email = patient?.email || 'Not Provided';
  const phone = patient?.phone || 'Not Provided';
  const address = patient?.primaryAddress?.address || 'Not Provided';
  const address2 = patient?.primaryAddress?.address2 ?? '';
  const city = patient?.primaryAddress?.city || 'Not Provided';
  const state = patient?.primaryAddress?.state || 'Not Provided';
  const zipcode = patient?.primaryAddress?.zipcode || 'Not Provided';
  const preferredContactMethod = patient?.preferredContactMethod;

  return {
    firstName,
    lastName,
    gender,
    birthday,
    email,
    phone,
    address,
    address2,
    city,
    state,
    zipcode,
    preferredContactMethod,
  };
};

export const mapPatientToInsuranceInfo = (
  patient: Patient
): MappedInsuranceInfoData => {
  const insuranceCardFrontUrl = patient?.insuranceCardFrontUrl;
  const insuranceCardBackUrl = patient?.insuranceCardBackUrl;

  const primaryInsuranceCarrier = patient?.insuranceCarrier;
  const primaryInsurancePlan = patient?.insurancePlan;
  const primaryInsuranceMemberId = patient?.insuranceMemberId;

  const secondaryInsuranceCarrier = patient?.secondaryInsuranceCarrier;
  const secondaryInsurancePlan = patient?.secondaryInsurancePlan;
  const secondaryInsuranceMemberId = patient?.secondaryInsuranceMemberId;

  return {
    insuranceCardFrontUrl,
    insuranceCardBackUrl,
    primaryInsuranceCarrier,
    primaryInsurancePlan,
    primaryInsuranceMemberId,
    secondaryInsuranceCarrier,
    secondaryInsurancePlan,
    secondaryInsuranceMemberId,
  };
};
