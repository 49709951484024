import React from 'react';
import axios from 'axios';

//types
import { Roles } from '../../../../../helpers';

// hooks
import { useParams } from 'react-router-dom';
import { useEmployeeQuery, useMeQuery } from '../../../../../generated/graphql';
import useEmployeeOperations from '../hooks/useEmployeeOperations';
import useSnackbar from '../../../../../hooks/useSnackbar';
import useAuthentication from '../../../../../hooks/useAuthentication';
import useHandleError from '../../../../../hooks/useHandleError';

// components
import { Grid, GridRow, MultiCardGrid } from '../../../../Shared/GridCard';
import FacilitiesList from './components/FacilitiesList';
import UserInfo from './components/UserInfo';
import RolesAndPermissions from './components/RolesAndPermissions';
import LoginContactInformation from './components/LoginContactInformation';
import LoginStatus from './components/LoginStatus';
import InPersonAppointmentsList from './components/InPersonAppointmentsList';
import ArchiveEmployee from './components/ArchiveEmployee';
import Can from '../../../../Can';
import { ImageUploader } from '@betterpt/better-components';

//styles
import { AvatarSection, Name, Page } from '../styles/EmployeeDetail.style';

//assets
import PlaceholderImage from '../../../../../assets/img-unknown-user.svg';

const EmployeeInfoTab = () => {
  const { employeeId } = useParams<{ employeeId: string }>();
  const auth = useAuthentication();
  const snackbar = useSnackbar();
  const openSnackbar = snackbar?.openSnackbar;
  const meQuery = useMeQuery();
  const employeeQuery = useEmployeeQuery({
    variables: { id: employeeId },
    skip: !employeeId,
    fetchPolicy: 'cache-and-network',
  });
  const handleError = useHandleError();

  const company = meQuery.data?.me?.company;
  const isNotWhiteLabel = company?.providerAppId !== 'acorn' && company?.providerAppId !== 'somatus';

  const queryError = meQuery.error || employeeQuery.error;

  React.useEffect(() => {
    if (queryError?.message) {
      snackbar?.useGenericErrorMessage();
    }
  });

  const {
    commands: { updateEmployeeInfo, updateRole, updateLoginAndContact, resetEmployeeInvite, archiveEmployee },
  } = useEmployeeOperations();

  const providerIsManagedByYext = employeeQuery.data?.employee?.isManagedViaYext;

  const [loading, updateLoading] = React.useState(false);

  const [logo, updateLogo] = React.useState('');

  React.useEffect(() => {
    if (employeeQuery.data?.employee?.smallProfileImgUrl) {
      updateLogo(employeeQuery.data?.employee?.smallProfileImgUrl);
    }
  }, [employeeQuery.data]);

  const postPhoto = async (base64Img: string) => {
    updateLoading(true);
    const userSession = auth?.user?.getSignInUserSession();
    const token = userSession?.getIdToken();
    const endpoint = window.__RUNTIME_CONFIG__.REACT_APP_API_ENDPOINT ?? 'https://api.development.betterpt.com/v0/api';
    try {
      await axios.post(
        `${endpoint}/employees/${employeeId}/avatar`,
        { file: base64Img },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: token?.getJwtToken() as string,
          },
        }
      );
      updateLogo(base64Img);
      updateLoading(false);
    } catch (e) {
      handleError(e);
      updateLoading(false);
    }
  };
  return (
    <Page>
      <Can
        role={Roles[meQuery.data?.me?.role ?? 'initial']}
        perform="employee:edit"
        data={{
          targetRole: employeeQuery?.data?.employee?.role,
        }}
        yes={() => (
          <AvatarSection>
            <ImageUploader
              width={56}
              loading={loading}
              type="avatar"
              image={logo || PlaceholderImage}
              onSubmit={(event) => {
                postPhoto(event.base64Img);
              }}
              style={{ margin: 0 }}
              onError={(message) => snackbar?.openSnackbar({ isError: true, message })}
            />
            <Name>
              {employeeQuery.data?.employee?.firstName} {employeeQuery.data?.employee?.lastName}
            </Name>
          </AvatarSection>
        )}
      />
      <Grid>
        <GridRow>
          <MultiCardGrid>
            <UserInfo updateEmployeeInfo={updateEmployeeInfo} />
            <RolesAndPermissions updateRole={updateRole} />
          </MultiCardGrid>
          <MultiCardGrid>
            <LoginStatus resetEmployeeInvite={resetEmployeeInvite} />
            <LoginContactInformation updateLoginAndContact={updateLoginAndContact} />
          </MultiCardGrid>
        </GridRow>
        {isNotWhiteLabel && <InPersonAppointmentsList />}
        <FacilitiesList />
      </Grid>
      {!providerIsManagedByYext && (
        <Can
          role={Roles[meQuery.data?.me?.role ?? 'initial']}
          perform="employee:edit"
          data={{
            targetRole: employeeQuery?.data?.employee?.role,
          }}
          yes={() => <ArchiveEmployee openSnackbar={openSnackbar} archiveEmployee={archiveEmployee} />}
        />
      )}
    </Page>
  );
};

export default EmployeeInfoTab;
