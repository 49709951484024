import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { colorValues } from '@betterpt/better-components';

export const InvisiblePlaceholder = styled.div`
  width: 576px;
  flex: 1 1 0%;
  margin-right: 28px;
`;
export const Page = styled.div`
  margin: 31px 28px;
`;

export const cardColumnStyle = {
  display: 'flex' as 'flex',
  flexDirection: 'column' as 'column',
};

export const ListTitle = styled.h4`
  font-size: 20px;
  margin: 22px 0px;
`;

export const CardBody = styled.div`
  margin: 10px 44px;
  p {
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 50px;
    margin-top: 50px;
    color: ${colorValues.cityblock};
  }
`;

export const Message = styled.p`
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.43;
  margin: 0 !important;
`;

export const InnerCard = styled.div`
  padding: 0px 25px 40px 58px;
`;

export const headerStyle = {
  justifyContent: 'flex-start',
  padding: '15px 25px 15px 25px',
  position: 'relative' as 'relative',
};

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
  background-color: ${colorValues.emptiness};
  padding: 38px 28px 32px 28px;
  h1 {
    margin: 0;
    a {
      color: ${colorValues.frenchblue};
      text-decoration: underline;
    }
  }
`;

export const editButtonStyle = (isFormDisabled: boolean, color: string) => {
  return {
    width: '105px',
    color: isFormDisabled ? colorValues.betterptblack : colorValues.emptiness,
    backgroundColor: isFormDisabled ? colorValues.dust : color,
    marginLeft: 'auto'
  };
};

export const loaderCardStyle = {
  padding: '0px 40px',
  position: 'relative' as 'relative',
  marginRight: '28px',
};

export const formFieldStyle = {
  margin: '20px 0px',
};

export const clinicImage = {
  margin: '20px 0px',
  width: '100%',
};

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0px 20px 0px;
`;

export const ButtonContainer = styled.div`
  margin-right: 28px;
  display: flex;
  @media only screen and (max-width: 1400px) {
    margin-right: 0px;
  }
  button {
    width: 275px !important;
    @media only screen and (max-width: 1020px) {
      width: 206px !important;
    }
  }
`;

export const cardStyle = {
  marginRight: 28,
  marginBottom: 25,
};

export const cardWithAnimationStyle = {
  marginRight: 0,
  marginBottom: 0,
};

const highlightAnimation = keyframes({
  from: {
    boxShadow: `rgb(231 77 60 / 95%) 0px 1px 10px 4px, rgb(231 77 60 / 45%) 0px 2px 3px 0px`,
  },
  to: { boxShadow: 'none' },
});

export const ActiveTodoOutline = styled.div<{ activeTodo: boolean }>`
  flex: 1 1 0%;
  width: 576px;
  height: min-content;
  border-radius: 5px;
  margin-bottom: ${(props) => (props.activeTodo ? '25px' : '0')};
  margin-right: ${(props) => (props.activeTodo ? '28px' : '0')};
  animation-name: ${(props) =>
    props.activeTodo ? highlightAnimation : 'none'};
  animation-duration: 4000ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  @media (max-width: 1400px) {
    width: 100%;
  }
`;
