import * as React from "react";

//types
import {
  Contact,
  Maybe,
  usePatientContactListQuery,
} from "../../../generated/graphql";
import { VideoAppointmentFiltersContextType } from "../../../context/videoAppointmentFilters";
import { InPersonAppointmentFiltersContextType } from "../../../context/inPersonAppointmentFilters";

//hooks
import useRemoteConfigAliases from "../../../hooks/useRemoteConfigAliases";
import _ from "lodash";

//components
import {
  FilterOption,
  FilterScrollAndSearch,
} from "@betterpt/better-components";

//helpers
import { pluralNoun } from "../../../helpers";

const PatientFilter: React.FC<React.PropsWithChildren<{
  filters:
    | VideoAppointmentFiltersContextType
    | InPersonAppointmentFiltersContextType;
  primaryColor?: string;
}>> = ({ filters, primaryColor }) => {
  const { patientsPluralAlias, patientsSingularAlias } =
    useRemoteConfigAliases();

  const [nameFilter, updateNameFilter] = React.useState("");

  const [isOpen, setOpenTo] = React.useState(false);
  const [contactIds, updateContactIds] = React.useState<string[]>([]);
  const [offset, setOffset] = React.useState<number>(0);
  const [patientList, setPatientList] = React.useState<any[]>([]);

  const onQueryComplete = (data: any) =>
    setPatientList((prev) =>
      _.unionBy([...prev, ...(data?.contacts?.result ?? [])], "id")
    );

  // To Get List of Patients Wiht Pagination
  const patientQuery = usePatientContactListQuery({
    variables: {
      input: {
        query: nameFilter,
        offset,
        limit: 10,
        isArchived: false,
      },
    },
    skip: !isOpen,
    notifyOnNetworkStatusChange: true,
    onCompleted: onQueryComplete,
    fetchPolicy: "cache-and-network",
  });

  // To Get List of Patients for Patient Ids Selected in Patient Filter
  const filterPatientQuery = usePatientContactListQuery({
    variables: {
      input: {
        contactIds: filters.contactIds.value,
        isArchived: false,
      },
    },
    onCompleted: onQueryComplete,
    skip: !filters.contactIds.value.length || !isOpen,
    fetchPolicy: "cache-and-network",
  });

  const count = patientQuery?.data?.contacts?.result?.length ?? 0;

  const handleSubmitPatients = (selected: FilterOption[]) => {
    setOffset(0);
    setPatientList([]);
    const newPatients = selected?.length
      ? selected?.map((option) => option?.value ?? "0")
      : [];

    filters.contactIds.update(newPatients);
  };

  const invertName = (patient: Maybe<Contact>) =>
    `${patient?.lastName}, ${patient?.firstName}`;

  React.useEffect(() => {
    updateContactIds(filters.contactIds?.value ?? []);
  }, [filters.contactIds, isOpen]);

  const patientOptions = (() => {
    const selectAllPatients = [
      {
        label: `All ${patientsPluralAlias}`,
        isResetOption: true,
        selected: contactIds.length < 1 || contactIds.includes("0"),
      },
    ];

    const patientOptions = patientList
      .map((patient: any) => ({
        label: invertName(patient),
        value: patient?.id,
        selected: contactIds.includes(patient?.id),
      }));

    return [...selectAllPatients, ...patientOptions];
  })();

  const filterPatientOptions = (() => {
    const filterPatientOptions = [
      ...(filterPatientQuery?.data?.contacts?.result ?? []),
    ].map(invertName);

    return [...filterPatientOptions];
  })();

  const handleButtonTitle = (() => {
    let contact_ids = filters.contactIds?.value;
    let title = "loading...";
    if (contact_ids.length < 1) {
      title = `all ${pluralNoun(patientsSingularAlias)}`;
    } else {
      const bestCategoryName =
        contact_ids.length > 1
          ? pluralNoun(patientsSingularAlias)
          : patientsSingularAlias;
      const bestCheckTitle = `${contact_ids.length} ${bestCategoryName}`;
      title = bestCheckTitle ?? "";
    }
    return title;
  })();

  const handlePatientClick = (option: FilterOption) => {
    if (!option?.isResetOption) {
      updateContactIds((ids) => {
        if (contactIds.includes(option?.value)) {
          return ids.filter((id) => id !== option?.value);
        } else {
          return [...ids, option?.value];
        }
      });
    } else {
      updateContactIds([]);
    }
  };

  const handleSearch = (searchText: string) => {
    updateNameFilter(searchText);
    setPatientList([
      ...patientList.filter((patient) => contactIds.includes(patient?.id)),
    ]);
    setOffset(0);
  };

  return (
    <FilterScrollAndSearch
      selectedOptions={filterPatientOptions}
      options={patientOptions}
      buttonTitle={handleButtonTitle}
      search={{
        value: nameFilter,
        update: handleSearch,
      }}
      openStatus={{
        value: isOpen,
        update: (open: boolean) => {
          setOpenTo(open);
        },
      }}
      optionCallback={handlePatientClick}
      name={patientsSingularAlias}
      overrideButtonColor={primaryColor}
      onLoadMore={() => setOffset(offset + count)}
      hasMore={!!count}
      onSubmit={handleSubmitPatients}
      loading={patientQuery.loading}
      style={{ buttonMargin: "12px 2px 0 0" }}
    />
  );
};

export default PatientFilter;
