import React from 'react';

//types
import { Appointment } from '../../../../../../../../generated/graphql';

//components
import { AppointmentStatus } from '../../InPersonAppointmentStatus';
import InPersonOptions from '../../../../InPersonOptions/';
import NotMigratedInfoBox from './NotMigratedInfoBox';

type Props = {
  appointment: Appointment;
  facilitiesSingularAlias: string;
  isBasic: boolean;
  waitingForPatient: boolean;
  detailPage: boolean;
  activeRequest: boolean;
  onDoneChangingOptionsAppointment: () => Promise<any> | undefined;
  openStatus: {
    value: boolean;
    update: (open: boolean) => void;
  };
};

const InPersonStatusCTA = ({
  appointment,
  facilitiesSingularAlias,
  isBasic,
  detailPage,
  activeRequest,
  onDoneChangingOptionsAppointment,
  openStatus,
}: Props) => {
  const facilityIsNotMigrated = !appointment?.clinic?.isBetterAccessOnly;
  if (facilityIsNotMigrated) {
    return (
      <NotMigratedInfoBox
        facilitiesSingularAlias={facilitiesSingularAlias}
        icon={<AppointmentStatus appointment={appointment} isLarge />}
        isRequest={activeRequest}
      />
    );
  } else if (detailPage && activeRequest) {
    return (
      <>
        <AppointmentStatus
          appointment={appointment}
          detailPage={detailPage}
          isBasic={isBasic}
          isLarge
        />
        {/*HotFix: Appointment Status is now a separate tag instead of an icon wrapped in InPersonOptions */}
        <InPersonOptions
          onDoneChangingOptionsAppointment={onDoneChangingOptionsAppointment}
          appointment={appointment}
          detailPage={detailPage}
          openStatus={openStatus}
          color
        />
      </>
    );
  } else
    return (
      <>
        <AppointmentStatus
          appointment={appointment}
          detailPage={detailPage}
          isBasic={isBasic}
          isLarge
        />
        {!isBasic && (
          <InPersonOptions
            onDoneChangingOptionsAppointment={onDoneChangingOptionsAppointment}
            appointment={appointment}
            detailPage={detailPage}
            openStatus={openStatus}
            color
          />
        )}
      </>
    );
};

export default InPersonStatusCTA;
