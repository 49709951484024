import React from 'react';
import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

const ErrorContainer = styled.div`
  p {
    color: ${colorValues.messyketchup};
    font-size: 14px;
  }
`;

const errorMap = new Map([
    ['questionType', 'Question type is required'],
    ['questionStatement', 'Question statement is required'],
    ['parentType', 'Parent type of question is required'],
]);

const CustomErrorField = (props: any) => {
    const { help, children, rawErrors = [], errorField } = props;

    return (
        <div>
            {children}
            <ErrorContainer>
                {rawErrors.length > 0 &&
                    rawErrors.includes(errorField) &&
                    <p>{errorMap.get(errorField)}</p>}
            </ErrorContainer>
            {help}
        </div>
    );
};
export default CustomErrorField;
