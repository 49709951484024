import React from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import useSnackbar from '../../hooks/useSnackbar';

const Snackbar = () => {
  const [open, setOpen] = React.useState(false);
  const snackbar = useSnackbar();

  React.useEffect(() => {
    if (snackbar?.errorMessage || snackbar?.successMessage) {
      setOpen(true);
    }
  }, [snackbar?.errorMessage, snackbar?.successMessage]);

  const handleClose = () => {
    snackbar?.clear();
    setOpen(false);
  };

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      key={`top,center`}
      open={open}
      onClose={handleClose}
      autoHideDuration={4000}
      message={snackbar?.errorMessage || snackbar?.successMessage}
      data-testid='snackbar'
    />
  );
};

export default Snackbar;
