import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...rest}
  >
    <defs>
      <path
        id='fgq8dsb99a'
        d='M12 1l9 4v6c0 5.55-3.84 10.74-9 12-5.16-1.26-9-6.45-9-12V5l9-4zm1.667 6h-3.334v3.333H7v3.334h3.333V17h3.334v-3.333H17v-3.334h-3.333V7z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0L24 0 24 24 0 24z' />
      <mask id='e2r2szyk8b' fill='#fff'>
        <use xlinkHref='#fgq8dsb99a' />
      </mask>
      <use fill={color || '#333'} fillRule='nonzero' xlinkHref='#fgq8dsb99a' />
      <g fill={color || '#333'} mask='url(#e2r2szyk8b)'>
        <path d='M0 0H24V24H0z' />
      </g>
    </g>
  </svg>
);
