import React from 'react';

//hooks
import { UseMFAReturnType } from '../hooks/useMfa';

//components
import { Dialog } from '@material-ui/core';
import { Button, Animation } from '@betterpt/better-components';
import { IcClose } from '@betterpt/better-icons';

//styles
import { colorValues } from '@betterpt/better-components';
import { DialogBody, DialogButtons } from '../styles/MFADialogs.style';

type Props = {
  open: boolean;
  onClose: () => void;
  mfa: UseMFAReturnType;
};

const DisableMFADialog = ({ open, onClose, mfa }: Props) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {(mfa.loading && (
        <DialogBody>
          <Animation type='providerAppLoader' />
        </DialogBody>
      )) || (
        <div data-testid='dialog-mfa'>
          <IcClose
            color={colorValues.cityblock}
            onClick={onClose}
            style={{
              position: 'absolute',
              top: 20,
              right: 20,
              cursor: 'pointer',
            }}
          />
          <DialogBody>
            <h2 style={{ marginBottom: 20 }}>
              Turn Off Two-Factor Authentication
            </h2>
            <p style={{ marginBottom: 60 }}>
              Turning off this feature removes a valuable security feature.
            </p>
            <DialogButtons>
              <Button
                style={{ color: colorValues.messyketchup }}
                onClick={() => mfa.disableMfa()}
                loading={mfa.loading}
                color='no-border'
              >
                TURN OFF
              </Button>
              <Button
                style={{ color: colorValues.cityblock }}
                onClick={onClose}
                color='no-border'
              >
                CANCEL
              </Button>
            </DialogButtons>
          </DialogBody>
        </div>
      )}
    </Dialog>
  );
};

export default DisableMFADialog;
