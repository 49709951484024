// Appointment Options popup style
import styled from '@emotion/styled';
import { colorValues, useBlackText } from '@betterpt/better-components';

export const DialogBody = styled.div`
  position: relative;
  padding: 47px 45px 36px 48px;
  margin: 5px;
`;

export const H1 = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 24px;
`;

export const DialogParagraph = styled.p`
  margin-bottom: 25px;
  line-height: 1.5;
  max-width: 480px;
`;

export const cancelInputStyle = (cancelText: string) =>
  !!cancelText.length
    ? {
        fontWeight: 'bold' as 'bold',
        fontSize: 20,
        color: colorValues.charcoalgray,
        height: 24,
      }
    : { height: 24 };

export const useButtonStyle = (color?: string) => ({
  backgroundColor: color || colorValues.cityblock,
  color: useBlackText(color ?? colorValues.cityblock)
    ? colorValues.betterptblack
    : colorValues.emptiness,
  fontWeight: 'bold' as 'bold',
  marginTop: '20px',
});
