import React, { CSSProperties } from 'react';
import { NavLink } from 'react-router-dom';
// types
import { RouteType } from './types';
import { Tab, TabSection, LineCut } from './HorizontalNavigation.styles';

type Props = {
  routes: RouteType[];
  backgroundColor: CSSProperties['color'];
};

export default ({ routes, backgroundColor }: Props) => (
  <TabSection backgroundColor={backgroundColor}>
    {routes
      .filter((route) => !route.hideTab)
      .map((route, idx) => {
        if (!route.path) {
          // if you don't provide a path, it will just render the item, but with no link provided.
          // You can also give it an onclick handler if youwant
          return (
            <Tab
              onClick={route?.onClick}
              className='listItem'
              key={idx}
              id={route.id}
            >
              {route.display}
              <LineCut className='line' backgroundColor={backgroundColor} />
            </Tab>
          );
        }
        return (
          <div key={route.path}>
            <NavLink to={route.url ?? route.path}>
              <Tab className='listItem'>
                {route.display}

                <LineCut className='line' backgroundColor={backgroundColor} />
              </Tab>
            </NavLink>
          </div>
        );
      })}
  </TabSection>
);
