import React from 'react';

//helpers
import { bestTextColor } from '@betterpt/better-components';

//hooks
import useRemoteConfigAliases from '../../../../../../../hooks/useRemoteConfigAliases';
import { useMeQuery } from '../../../../../../../generated/graphql';

//components
import { Dialog } from '@material-ui/core';
import { Button, CloseButton } from '@betterpt/better-components';
import SlideTransition from '../../../../../../Shared/SlideTransition';

//styles
import {
  CSVEmail,
  CSVHeader,
  CSVParagraph,
  CSVSentDialog,
} from '../AnalyticsCSV.style';

type Props = {
  open: boolean;
  handleClose: () => void;
  telehealth?: boolean;
};

const SentCSVDialog = ({ open, handleClose, telehealth }: Props) => {
  const meQuery = useMeQuery();
  const { primaryColor } = useRemoteConfigAliases();

  const email = meQuery.data?.me?.email;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      disablePortal
      PaperProps={{
        style: {
          width: '555px',
          maxWidth: '555px',
        },
      }}
    >
      <CSVSentDialog>
        <CSVHeader style={{ margin: 0 }}>All set!</CSVHeader>
        <CSVParagraph style={{ margin: '30px 0', maxWidth: 410 }}>
          Please check the email we have associated with your account
          {!!email ? (
            <>
              {', '}
              <CSVEmail>{email}</CSVEmail>
            </>
          ) : (
            ''
          )}{' '}
          for the CSV of your {telehealth ? 'telehealth' : 'in-person'} data.
        </CSVParagraph>
        <Button
          size='large'
          fullWidth
          onClick={handleClose}
          style={{
            backgroundColor: primaryColor,
            color: bestTextColor(primaryColor),
          }}
        >
          THANK YOU
        </Button>
        <CloseButton onClose={handleClose} />
      </CSVSentDialog>
    </Dialog>
  );
};

export default SentCSVDialog;
