import * as React from 'react';

export default ({
  color,
  width,
  height,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width ?? '111'}
    height={height ?? '111'}
    viewBox='0 0 111 111'
    {...rest}
  >
    <g fill='none' fillRule='evenodd'>
      <path
        fill={color ?? '#333'}
        fillRule='nonzero'
        d='M41.625 50.875h-9.25v9.25h9.25v-9.25zm18.5 0h-9.25v9.25h9.25v-9.25zm18.5 0h-9.25v9.25h9.25v-9.25zm9.25-32.375H83.25V9.25H74v9.25H37V9.25h-9.25v9.25h-4.625c-5.134 0-9.204 4.163-9.204 9.25l-.046 64.75c0 5.088 4.116 9.25 9.25 9.25h64.75c5.088 0 9.25-4.162 9.25-9.25V27.75c0-5.087-4.162-9.25-9.25-9.25zm0 74h-64.75V41.625h64.75V92.5z'
      />
      <path d='M0 0L111 0 111 111 0 111z' />
    </g>
  </svg>
);
