import React from 'react';

//components
import { TableAction } from '@betterpt/better-components';

// hooks
import { useHistory } from 'react-router-dom';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../hooks/useSnackbar';

// gql
import { Contact } from '../../../../generated/graphql';
import { loader } from 'graphql.macro';
import useHandleError from '../../../../hooks/useHandleError';

const QUERY_CONTACT_DETAILS = loader(
  '../../../../gql/patients/PatientContactDetails.graphql'
);

type Props = {
  patient?: Contact | null;
  isSavingArchive: boolean;
  toggleArchivePatient: (options: any) => Promise<any>;
  openStatus: {
    value: boolean;
    update: (isOpen: boolean) => void;
  };
};

const ContactOptions = ({
  patient,
  toggleArchivePatient,
  isSavingArchive,
  openStatus,
}: Props) => {
  const handleError = useHandleError();
  const history = useHistory();
  const snackbar = useSnackbar();
  const { patientsSingularAlias, primaryColor } = useRemoteConfigAliases();

  const handleToggleArchivePatient = async () => {
    try {
      await toggleArchivePatient({
        variables: {
          input: {
            contactId: patient?.id,
            isArchived: !patient?.archivedAt,
          },
        },
        refetchQueries: [
          { query: QUERY_CONTACT_DETAILS, variables: { id: patient?.id } },
        ],
        awaitRefetchQueries: true,
      });
      snackbar?.setSuccessMessage(
        `${patientsSingularAlias} ${
          patient?.archivedAt ? 'activated' : 'archived'
        } successfully.`
      );
    } catch (e) {
      handleError(e);
    }
  };

  const availableActions = [
    {
      label: `View ${patientsSingularAlias.toLowerCase()}`,
      callback: () => history.push(`/patients/${patient?.id}`),
      hidden: !patient?.id,
    },
    {
      label: `${
        patient?.archivedAt ? 'Activate' : 'Archive'
      } ${patientsSingularAlias.toLowerCase()}`,
      callback: () => handleToggleArchivePatient(),
      hidden: !patient?.id,
    },
  ];

  return (
    <div
      data-testid={`contact-options-${patient?.id}`}
      onClick={(e) => e.stopPropagation()}
    >
      <TableAction
        options={availableActions}
        loading={isSavingArchive}
        buttonColor={primaryColor}
        mainStyle={{ justifyContent: 'center' }}
        openStatus={openStatus}
      />
    </div>
  );
};

export default ContactOptions;
