import * as React from 'react';

//types
import { Patient } from '../../../../../../generated/graphql';

//helpers
import { format, parse } from 'date-fns';
import { normalizePhone } from '../../../../../../helpers';

//hooks
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';

//components
import { Button } from '@betterpt/better-components';
import ThemedTextField from '../../../../../Shared/ThemedTextField';

//styles
import { colorValues, bestTextColor } from '@betterpt/better-components';
import { TextFieldRow } from '../../../InPersonAppointments/InPersonAppointmentDetails/InPersonAppointmentDetails.style';
import {
  DialogBody,
  TextFieldContainer,
} from '../LinkContactAndPatientDialog.style';

type Props = {
  patient: Patient | null | undefined;
  handleCreateAndLinkNewContact: () => void;
  handleSearchContactsClick: () => void;
  saving: boolean;
};

const NewPatientContacTextField = ({
  value,
  label,
  isFullWidth,
  ...rest
}: {
  value?: string | null;
  label: string;
  isFullWidth?: boolean;
}) => (
  <ThemedTextField
    disabled
    value={value}
    label={label}
    style={{ width: isFullWidth ? 'initial' : '48%' }}
    fullWidth={isFullWidth}
    {...rest}
  />
);

const NewPatientContactView = ({
  patient,
  saving,
  handleCreateAndLinkNewContact,
  handleSearchContactsClick,
}: Props) => {
  const { firstName, lastName, phone, email, birthday } = patient ?? {
    firstName: 'Not Provided',
    lastName: 'Not Provided',
    phone: 'Not Provided',
    email: 'Not Provided',
    birthday: null,
  };

  const formattedBirthday = birthday
    ? format(parse(birthday, 'yyyy-MM-dd', new Date()), 'MMMM do, yyyy')
    : 'Not Provided';

  const { patientsSingularAlias, primaryColor } = useRemoteConfigAliases();

  const lowerCasePatientAlias = patientsSingularAlias.toLowerCase();
  const upperCasePatientAlias = patientsSingularAlias.toUpperCase();
  return (
    <DialogBody>
      <h2 className='H2'>New {patientsSingularAlias}!</h2>
      <p className='Body'>
        Looks like you have an appointment request from a new patient!
      </p>
      <p className='Body'>
        Click “Create new {lowerCasePatientAlias}” to add this person to your
        company’s {lowerCasePatientAlias} database when you confirm their
        request. This way, you will have them on file for future appointments.
        If you think the contact information listed below is part of a{' '}
        {lowerCasePatientAlias} account you already have on file, click
        “Associate appointment with a different {lowerCasePatientAlias}”.
      </p>
      <TextFieldContainer>
        <TextFieldRow>
          <NewPatientContacTextField
            value={firstName}
            label='First Name'
            data-cy='new-contact-link-firstName'
          />
          <NewPatientContacTextField
            value={lastName}
            label='Last Name'
            data-cy='new-contact-link-lastName'
          />
        </TextFieldRow>
        <TextFieldRow>
          <NewPatientContacTextField
            value={email}
            label='Email'
            data-cy='new-contact-link-email'
          />
          <NewPatientContacTextField
            value={normalizePhone(phone ?? '')}
            label='Phone'
            data-cy='new-contact-link-phone'
          />
        </TextFieldRow>
        <NewPatientContacTextField
          value={formattedBirthday}
          label='DOB'
          isFullWidth
          data-cy='new-contact-link-dob'
        />
      </TextFieldContainer>
      <Button
        size='large'
        fullWidth
        style={{
          marginTop: '20px',
          backgroundColor: primaryColor,
          color: bestTextColor(primaryColor),
          whiteSpace: 'nowrap',
        }}
        loading={saving}
        onClick={handleCreateAndLinkNewContact}
        data-cy='new-contact-link-button'
      >
        CREATE NEW {upperCasePatientAlias} WITH INFO DISPLAYED HERE
      </Button>
      <Button
        size='large'
        fullWidth
        style={{
          marginTop: '20px',
          backgroundColor: colorValues.emptiness,
          color: colorValues.betterptblack,
        }}
        loading={saving}
        onClick={handleSearchContactsClick}
      >
        ASSOCIATE APPOINTMENT WITH A DIFFERENT {upperCasePatientAlias}
      </Button>
    </DialogBody>
  );
};

export default NewPatientContactView;
