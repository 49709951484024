import React from 'react';

export default ({
  color,
  ...rest
}: { color?: string } & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...rest}
  >
    <defs>
      <path
        id='bem7p6wtsa'
        d='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4l6 6v10c0 1.1-.9 2-2 2H7.99C6.89 23 6 22.1 6 21l.01-14c0-1.1.89-2 1.99-2h7zm-1 7h5.5L14 6.5V12z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0L24 0 24 24 0 24z' />
      <mask id='m3x320a2qb' fill='#fff'>
        <use xlinkHref='#bem7p6wtsa' />
      </mask>
      <use fill={color || '#333'} fillRule='nonzero' xlinkHref='#bem7p6wtsa' />
      <g fill={color || '#333'} mask='url(#m3x320a2qb)'>
        <path d='M0 0H24V24H0z' />
      </g>
    </g>
  </svg>
);
