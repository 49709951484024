import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

export const BorderedCardSection = styled.div`
  display: flex;
  padding: 14px 25px 14px 25px;
  align-items: center;
`;

export const inPersonTextInputStyle = {
  marginLeft: '16px',
};

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 25px 39px 58px;
  align-items: center;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${colorValues.concretejungle};
  width: 100%;
`;

export const HoursContainer = styled.div`
  padding-left: 14px;
  position: relative;
`;
