import React from 'react';
import { Redirect, Route, useRouteMatch, Switch } from 'react-router-dom';

// components
import CreateFormNav from '../../../Shared/CreateFormNav';
import Dialog from '@material-ui/core/Dialog';
import NameAndContactInfo from './views/NameAndContactInfo';
import HoursAndAbout from './views/HoursAndAbout';

// hooks
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';

// styles
import { CreateFacilityBody } from './CreateFacility.style';

const CreateFacility = () => {
  const { url } = useRouteMatch();
  const { facilitiesSingularAlias } = useRemoteConfigAliases();

  return (
    <Dialog open={true} fullScreen>
      <CreateFormNav title={`${facilitiesSingularAlias} Setup`} />
      <CreateFacilityBody>
        <Switch>
          <Redirect from={url} to={`${url}/name-and-contact`} exact />
          <Route path={`${url}/name-and-contact`} exact>
            <NameAndContactInfo />
          </Route>
          <Route path={`${url}/:facilityId/hours-and-about`} exact>
            <HoursAndAbout />
          </Route>
        </Switch>
      </CreateFacilityBody>
    </Dialog>
  );
};

export default CreateFacility;
