import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import {
  NewAppointmentStatus,
  Order,
  useMeQuery,
  Role,
  useUpdateEmployeeMutation,
} from '../generated/graphql';
import {
  format,
  startOfYear,
  endOfYear,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  subDays
} from 'date-fns';
import {
  sharedDefaultFilters,
  createTelehealthAppointmentsSearchUrl,
  validateTelehealthAppointmentsSearchParams,
} from '../helpers/telehealthAppointmentsFilterHelper';

import useQueryParams from '../components/authentication/hooks/useQueryParams';

export enum TimeDisplayValue {
  Today = 'today',
  Week = 'this week',
  Month = 'this month',
  Year = 'this year',
  Range = 'range',
}

export enum CreatedTimeDisplayValue {
  Today = 'today',
  Week = 'last 7 days',
  Month = 'last 30 days',
  Year = 'last 365 days',
  Range = 'range',
}

export enum FullTelehealthStatus {
  Upcoming = 'upcoming',
  Pending = 'pending',
  Confirmed = 'confirmed',
  Completed = 'completed',
  NoshowPatient = 'noshowpatient',
  NoshowProvider = 'noshowprovider',
  Cancelled = 'cancelled',
  Declined = 'declined',
}

type Filter<T> = {
  value: T;
  update: (value: T) => void;
};

export type VideoAppointmentFiltersContextType = {
  appointmentStatuses: Filter<NewAppointmentStatus[]>;
  start: Filter<string>;
  end: Filter<string>;
  createdAtStart: Filter<string>;
  createdAtEnd: Filter<string>;
  clinicIds: Filter<string[]>;
  employeeIds: Filter<string[]>;
  contactIds: Filter<string[]>;
  timeDisplayValue: Filter<TimeDisplayValue>;
  createdTimeDisplayValue: Filter<CreatedTimeDisplayValue>;
  dateOrder: Filter<Order>;
  offset: Filter<number>;
  defaultPageIndex: Filter<number>;
  defaultTableSection: Filter<number>;
  resetFilters: () => void;
  filtersHaveBeenSet: boolean;
  saveDefaultFilterURL: (filterURL: string) => Promise<void>;
};

const defaultContextValue: VideoAppointmentFiltersContextType = {
  appointmentStatuses: {
    value: [],
    update: () => {
      return;
    },
  },
  start: {
    value: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    update: () => {
      return;
    },
  },
  end: {
    value: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
    update: () => {
      return;
    },
  },
  createdAtStart: {
    value: format(subDays(new Date(),30), 'yyyy-MM-dd'),
    update: () => {
      return;
    },
  },
  createdAtEnd: {
    value: format(new Date(), 'yyyy-MM-dd'),
    update: () => {
      return;
    },
  },
  timeDisplayValue: {
    value: TimeDisplayValue.Month,
    update: () => {
      return;
    },
  },
  createdTimeDisplayValue: {
    value: CreatedTimeDisplayValue.Month,
    update: () => {
      return;
    },
  },
  clinicIds: {
    value: [],
    update: () => {
      return;
    },
  },
  employeeIds: {
    value: [],
    update: () => {
      return;
    },
  },
  contactIds: {
    value: [],
    update: () => {
      return;
    }
  },
  offset: {
    value: 0,
    update: () => {
      return;
    },
  },

  dateOrder: {
    value: Order.Desc,
    update: () => {
      return;
    },
  },
  defaultPageIndex: {
    value: 0,
    update: () => {
      return;
    },
  },
  defaultTableSection: {
    value: 1,
    update: () => {
      return;
    },
  },
  resetFilters: () => {
    return;
  },
  filtersHaveBeenSet: false,
  saveDefaultFilterURL: () => {
    return new Promise((resolve) => resolve());
  },
};

export const VideoAppointmentFiltersContext = React.createContext<
  VideoAppointmentFiltersContextType
>(defaultContextValue);

export const VideoAppointmentFiltersProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const meQuery = useMeQuery();
  const history = useHistory();

  const [updateEmployee] = useUpdateEmployeeMutation();
  const urlQuerySearchParams = useQueryParams();
  const company = meQuery.data?.me?.company;
  const isWhiteLabel =
    company?.providerAppId === 'acorn' || company?.providerAppId === 'somatus';

  const determineDefaultFilter = useCallback(() => {
    const me = meQuery.data?.me;

    const myClinics = me?.clinics
      ?.filter((clinic: any) => !!clinic?.id)
      .map((clinic: any) => clinic.id);

    let filters: any;

    switch (me?.role) {
      case Role.Owner:
        filters = {
          clinicIds: [],
          appointmentStatuses: [isWhiteLabel 
            ? NewAppointmentStatus.Completed 
            : NewAppointmentStatus.Requested],
          employeeIds: [],
          contactIds: []
        };
        return {
          ...sharedDefaultFilters,
          ...filters,
          url: createTelehealthAppointmentsSearchUrl({ ...filters }),
        };
      case Role.Admin:
        filters = {
          clinicIds: myClinics,
          appointmentStatuses: [isWhiteLabel 
            ? NewAppointmentStatus.Completed 
            : NewAppointmentStatus.Requested],
          employeeIds: [],
          contactIds: []
        };
        return {
          ...sharedDefaultFilters,
          ...filters,
          url: createTelehealthAppointmentsSearchUrl({ ...filters }),
        };
      case Role.Manager:
        filters = {
          clinicIds: myClinics,
          appointmentStatuses: [isWhiteLabel 
            ? NewAppointmentStatus.Completed 
            : NewAppointmentStatus.Requested],
          employeeIds: [],
          contactIds: []
        };
        return {
          ...sharedDefaultFilters,
          ...filters,
          url: createTelehealthAppointmentsSearchUrl({ ...filters }),
        };
      case Role.Scheduler:
        filters = {
          clinicIds: myClinics,
          appointmentStatuses: [isWhiteLabel 
            ? NewAppointmentStatus.Completed 
            : NewAppointmentStatus.Requested],
          employeeIds: [],
          contactIds: []
        };
        return {
          ...sharedDefaultFilters,
          ...filters,
          url: createTelehealthAppointmentsSearchUrl({ ...filters }),
        };
      case Role.Self:
        filters = {
          clinicIds: myClinics,
          appointmentStatuses: [NewAppointmentStatus.Completed],
          employeeIds: [me.id],
          contactIds: []
        };
        return {
          ...sharedDefaultFilters,
          ...filters,
          url: createTelehealthAppointmentsSearchUrl({ ...filters }),
        };
      default:
        filters = {
          clinicIds: myClinics,
          appointmentStatuses: [NewAppointmentStatus.Completed],
          employeeIds: [],
          contactIds: []
        };
        return {
          ...sharedDefaultFilters,
          ...filters,
          url: createTelehealthAppointmentsSearchUrl({ ...filters }),
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWhiteLabel, meQuery.data?.me]);

  const [appointmentStatuses, updateAppointmentStatuses] = React.useState<NewAppointmentStatus[]>(
    []
  );
  const [start, updateStart] = React.useState<string>(
    format(startOfMonth(new Date()), 'yyyy-MM-dd')
  );
  const [end, updateEnd] = React.useState<string>(
    format(endOfMonth(new Date()), 'yyyy-MM-dd')
  );
  const [createdAtStart, updateCreatedAtStart] = React.useState<string>(
    format(subDays(new Date(),30), 'yyyy-MM-dd')
  );
  const [createdAtEnd, updateCreatedAtEnd] = React.useState<string>(
    format(new Date(), 'yyyy-MM-dd')
  );
  const [clinicIds, updateClinicIds] = React.useState<string[]>([]);
  const [employeeIds, updateEmployeeIds] = React.useState<string[]>([]);
  const [contactIds, updateContactIds] = React.useState<string[]>([]);

  const [timeDisplayValue, updateTimeDisplayValue] = React.useState<
    TimeDisplayValue
  >(TimeDisplayValue.Month);

  const [createdTimeDisplayValue, updateCreatedTimeDisplayValue] =
  React.useState<CreatedTimeDisplayValue>(CreatedTimeDisplayValue.Month);

  const [offset, setOffset] = React.useState(0);
  const [defaultPageIndex, updateDefaultPageIndex] = React.useState(0);
  const [dateOrder, updateDateOrder] = React.useState(Order.Desc);
  const [defaultTableSection, updateDefaultTableSection] = React.useState(1);
  const [filtersHaveBeenSet, updateFiltersHaveBeenSet] = React.useState(false);
  // function to use if there are search params on the URL to persist and pre populate filters
  const setFiltersWithUrlSearchParams = useCallback(
    (defaultUrlFromBackend?: string) => {
      // use default search url if one is provided from backend to set filters
      const searchParams = defaultUrlFromBackend
        ? new URLSearchParams(defaultUrlFromBackend)
        : urlQuerySearchParams;
      if (!validateTelehealthAppointmentsSearchParams(searchParams)) {
        resetFilters();
        return;
      }
      if (defaultUrlFromBackend) {
        history.replace(`${history.location.pathname}${defaultUrlFromBackend}`);
      }

      const isRange = searchParams.get('timeDisplayValue') === 'range';
      const isRequestedRange = searchParams.get('createdTimeDisplayValue') === 'range';

      // determinestart and end date based on timeDisplayValue so it calculates based on the current year and not old url end and start dates saved on filters url
      const determineStartEndDates = () => {
        switch (searchParams.get('timeDisplayValue')) {
          case 'this year':
            return {
              start: format(startOfYear(new Date()), 'yyyy-MM-dd'),
              end: format(endOfYear(new Date()), 'yyyy-MM-dd'),
            };
          case 'this month':
            return {
              start: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
              end: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
            };
          case 'this week':
            return {
              start: format(startOfWeek(new Date()), 'yyyy-MM-dd'),
              end: format(endOfWeek(new Date()), 'yyyy-MM-dd'),
            };
          case 'today':
            return {
              start: format(new Date(), 'yyyy-MM-dd'),
              end: format(new Date(), 'yyyy-MM-dd'),
            };
          default:
            return {
              start: format(startOfYear(new Date()), 'yyyy-MM-dd'),
              end: format(endOfYear(new Date()), 'yyyy-MM-dd'),
            };
        }
      };

      // determine created start and end date based on createdTimeDisplayValue so it calculates based on the current date and not old url end and start dates saved on filters url
      const determineCreatedStartEndDates = () => {
        switch (searchParams.get('createdTimeDisplayValue')) {
          case 'last 365 days':
            return {
              createdAtStart: format(subDays(new Date(),365), 'yyyy-MM-dd'),
              createdAtEnd: format(new Date(), 'yyyy-MM-dd'),
            };
          case 'last 30 days':
            return {
              createdAtStart: format(subDays(new Date(),30), 'yyyy-MM-dd'),
              createdAtEnd: format(new Date(), 'yyyy-MM-dd'),
            };
          case 'last 7 days':
            return {
              createdAtStart: format(subDays(new Date(),7), 'yyyy-MM-dd'),
              createdAtEnd: format(new Date(), 'yyyy-MM-dd'),
            };
          case 'today':
            return {
              createdAtStart: format(new Date(), 'yyyy-MM-dd'),
              createdAtEnd: format(new Date(), 'yyyy-MM-dd'),
            };
          default:
            return {
              createdAtStart: format(subDays(new Date(),30), 'yyyy-MM-dd'),
              createdAtEnd: format(new Date(), 'yyyy-MM-dd'),
            };
        }
      };

      const dates = determineStartEndDates();
      const createdDates = determineCreatedStartEndDates();

      if (!isRange) {
        searchParams.set('start', dates.start);
        searchParams.set('end', dates.end);

        history.replace(
          `${history.location.pathname}?${searchParams.toString()}`
        );
      }

      if (!isRequestedRange) {
        searchParams.set('createdAtStart', createdDates.createdAtStart);
        searchParams.set('createdAtEnd', createdDates.createdAtEnd);
        
        history.replace(
          `${history.location.pathname}?${searchParams.toString()}`
          );
      }
        
      updateAppointmentStatuses(
        (searchParams.get('appointmentStatuses') as any)
        .split(',')
        .filter((s: string) => s !== '')
      );
      updateStart((isRange && searchParams.get('start')) || dates.start);
      updateEnd((isRange && searchParams.get('end')) || dates.end);
      updateCreatedAtStart((isRequestedRange && searchParams.get('createdAtStart')) || createdDates.createdAtStart);
      updateCreatedAtEnd((isRequestedRange && searchParams.get('createdAtEnd')) || createdDates.createdAtEnd);
      updateClinicIds(
        searchParams.get('clinicIds')
          ? searchParams.get('clinicIds')?.split(',') ?? []
          : []
      );
      updateEmployeeIds(
        searchParams.get('employeeIds')
          ? searchParams.get('employeeIds')?.split(',') ?? []
          : []
      );
      updateContactIds(
        searchParams.get('contactIds') 
          ? searchParams.get('contactIds')?.split(',') ?? []
          : []
      )
      updateTimeDisplayValue(
        (searchParams.get('timeDisplayValue') as any) ?? TimeDisplayValue.Month
      );
      updateCreatedTimeDisplayValue(
        (searchParams.get('createdTimeDisplayValue') as any) ?? CreatedTimeDisplayValue.Month
      );
      updateDateOrder((searchParams.get('dateOrder') as any) ?? Order.Asc);
      setOffset(0);
      updateDefaultPageIndex(0);
      updateDefaultTableSection(1);
      updateFiltersHaveBeenSet(true);
    },
    [history, urlQuerySearchParams]
  );

  const resetFilters = useCallback(() => {
    const defaultFiltersUrl =
      meQuery.data?.me?.customSearchTemplates
        ?.telehealthAppointmentSearchTemplate?.default;
    if (defaultFiltersUrl &&
      validateTelehealthAppointmentsSearchParams(
        new URLSearchParams(defaultFiltersUrl)
      )) {
      setFiltersWithUrlSearchParams(defaultFiltersUrl);
      return;
    } 

    const defaultFilters = determineDefaultFilter();
    history.replace(`${history.location.pathname}?${defaultFilters.url}`);

    updateAppointmentStatuses(defaultFilters.appointmentStatuses);
    updateStart(
      defaultFilters?.start ?? format(startOfMonth(new Date()), 'yyyy-MM-dd')
    );
    updateEnd(
      defaultFilters?.end ?? format(endOfMonth(new Date()), 'yyyy-MM-dd')
    );
    updateCreatedAtStart(
      defaultFilters?.createdAtStart ?? format(subDays(new Date(),30), 'yyyy-MM-dd')
    );
    updateCreatedAtEnd(
      defaultFilters?.createdAtEnd ?? format(new Date(), 'yyyy-MM-dd')
    );
    updateClinicIds(defaultFilters?.clinicIds ?? []);
    updateEmployeeIds(defaultFilters?.employeeIds ?? []);
    updateContactIds(defaultFilters?.contactIds ?? []);
    updateTimeDisplayValue(
      defaultFilters?.timeDisplayValue ?? TimeDisplayValue.Month
    );
    updateCreatedTimeDisplayValue(
      defaultFilters?.createdTimeDisplayValue ?? CreatedTimeDisplayValue.Month
    );
    updateDateOrder(defaultFilters?.dateOrder ?? Order.Asc);
    setOffset(0);
    updateDefaultPageIndex(0);
    updateDefaultTableSection(1);
    updateFiltersHaveBeenSet(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [determineDefaultFilter]);

  const saveDefaultFilterURL = async (filterUrl: string) => {
    const employeeId = meQuery.data?.me?.id;
    if (!employeeId) {
      return;
    }
    await updateEmployee({
      variables: {
        input: {
          employeeId,
          customTelehealthSearchTemplate: {
            default: filterUrl,
          },
        },
      },
    });
  };

  const toggleDateOrder = (order: Order) => {
    setOffset?.(0);
    updateDefaultPageIndex?.(0);
    updateDefaultTableSection?.(1);
    updateDateOrder?.(order);
  };

  React.useEffect(() => {
    setOffset?.(0);
    updateDefaultPageIndex?.(0);
    updateDefaultTableSection?.(1);
  }, [
    appointmentStatuses,
    start,
    end,
    createdAtStart,
    createdAtEnd,
    clinicIds,
    employeeIds,
    contactIds,
    timeDisplayValue,
    createdTimeDisplayValue
  ]);

  React.useEffect(
    () => {
      if (meQuery.data) {
        validateTelehealthAppointmentsSearchParams(urlQuerySearchParams)
          ? setFiltersWithUrlSearchParams()
          : resetFilters();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [meQuery.data]
  );

  // Update the URL with selected search param value
  const updateParams = (key: string, value: any) => {
    urlQuerySearchParams.set(key, value);
    history.replace(
      `${history.location.pathname}?${urlQuerySearchParams.toString()}`
    );
  };

  const values = {
    appointmentStatuses: {
      value: appointmentStatuses,
      update: (value: NewAppointmentStatus[]) => {
        updateParams('appointmentStatuses', value.join(','));
        updateAppointmentStatuses(value);
      },
    },
    start: {
      value: start,
      update: (value: string) => {
        updateParams('start', value);
        updateStart(value);
      },
    },
    end: {
      value: end,
      update: (value: string) => {
        updateParams('end', value);
        updateEnd(value);
      },
    },
    timeDisplayValue: {
      value: timeDisplayValue,
      update: (value: TimeDisplayValue) => {
        updateParams('timeDisplayValue', value);
        updateTimeDisplayValue(value);
      },
    },
    createdAtStart: {
      value: createdAtStart,
      update: (value: string) => {
        updateParams('createdAtStart', value);
        updateCreatedAtStart(value);
      },
    },
    createdAtEnd: {
      value: createdAtEnd,
      update: (value: string) => {
        updateParams('createdAtEnd', value);
        updateCreatedAtEnd(value);
      },
    },
    createdTimeDisplayValue: {
      value: createdTimeDisplayValue,
      update: (value: CreatedTimeDisplayValue) => {
        updateParams('createdTimeDisplayValue', value);
        updateCreatedTimeDisplayValue(value);
      },
    },
    clinicIds: {
      value: clinicIds,
      update: (value: string[]) => {
        updateParams('clinicIds', value.join(','));
        updateClinicIds(value);
      },
    },
    employeeIds: {
      value: employeeIds,
      update: (value: string[]) => {
        updateParams('employeeIds', value.join(','));
        updateEmployeeIds(value);
      },
    },
    contactIds: {
      value: contactIds,
      update: (value: string[]) => {
        updateParams('contactIds', value.join(','));
        updateContactIds(value);
      }
    },
    offset: {
      value: offset,
      update: setOffset,
    },
    defaultPageIndex: {
      value: defaultPageIndex,
      update: updateDefaultPageIndex,
    },
    dateOrder: {
      value: dateOrder,
      update: (value: Order) => {
        updateParams('dateOrder', value);
        toggleDateOrder(value);
      },
    },
    defaultTableSection: {
      value: defaultTableSection,
      update: updateDefaultTableSection,
    },
    resetFilters,
    filtersHaveBeenSet,
    saveDefaultFilterURL,
  };

  return (
    <VideoAppointmentFiltersContext.Provider value={values}>
      {children}
    </VideoAppointmentFiltersContext.Provider>
  );
};
