import { format, formatDistance } from 'date-fns';
import {
  Appointment,
  Conversation,
  VideoAppointment,
} from '../generated/graphql';

export type Notification = {
  id: string;
  title: string;
  timeStamp: Date;
  subTitle: string;
  linkToOnCtaClick: string;
};

const renderSubTitleText = (time: Date) => {
  return `${formatDistance(new Date(time), new Date())} ago`;
};

export const mapUnreadConversationToNotification = (
  unreadConversations: Conversation[]
): Notification[] => {
  return unreadConversations.map(
    (conversation): Notification => ({
      id: conversation?.id ?? '',
      title: `You have a new message from ${
        conversation?.patientName ?? conversation?.patientPhone
      }`,
      subTitle: renderSubTitleText(conversation?.mostRecentMessage?.timestamp),
      timeStamp: conversation?.mostRecentMessage?.timestamp,
      linkToOnCtaClick: `/facilities/${conversation?.clinicId}/sms-messages/${conversation?.id}/conversation`,
    })
  );
};

type MapPendingAppointmentsInput = {
  appointmentData?: Appointment[] | VideoAppointment[] | null;
  isInPerson: boolean;
};

export const mapPendingAppointmentsToNotificationData = ({
  appointmentData,
  isInPerson,
}: MapPendingAppointmentsInput) =>
  appointmentData?.map(
    (appointment: Appointment | VideoAppointment): Notification => ({
      id: appointment?.id ?? '',
      title: `${appointment?.clinic?.clinicName} has a new ${
        isInPerson ? 'appointment' : 'telehealth'
      } request for ${format(new Date(appointment?.startTime), 'MM/dd/yyyy')}`,
      subTitle: renderSubTitleText(appointment?.createdAt),
      timeStamp: appointment?.createdAt,
      linkToOnCtaClick: `/appointments/details/${appointment?.id}`,
    })
  );
