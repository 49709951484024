import * as React from 'react';

// components
import { Button, CloseButton, colorValues } from '@betterpt/better-components';
import ThemedTextField from '../../../../Shared/ThemedTextField';

//styles
import {
  useButtonStyle,
  cancelInputStyle,
  DialogBody,
  DialogParagraph,
  H1,
} from './ConfirmCancellationPage.style';

type Props = {
  cancelAppointment: () => void;
  closeDialog: () => void;
  saving: boolean;
};

const ConfirmCancellationPage = ({
  cancelAppointment,
  closeDialog,
  saving,
}: Props) => {
  const [cancelText, updateCancelText] = React.useState('');

  return (
    <DialogBody>
      <H1>Cancel Appointment</H1>
      <DialogParagraph className='Body'>
        If you are sure you wish to cancel this appointment, type “CANCEL” in
        the prompt below and then click “CONTINUE WITH CANCELLATION”. All
        parties involved will be notified.
      </DialogParagraph>

      <ThemedTextField
        label='Cancel Appointment'
        placeholder='Type "CANCEL" to continue'
        fullWidth
        value={cancelText}
        onChange={(e) => updateCancelText(e.currentTarget.value.toUpperCase())}
        inputProps={{
          style: cancelInputStyle(cancelText),
        }}
      />
      <Button
        fullWidth
        size='large'
        style={useButtonStyle(colorValues.orangepink)}
        onClick={cancelAppointment}
        disabled={saving || cancelText.toUpperCase() !== 'CANCEL'}
        loading={saving}
      >
        CONTINUE WITH CANCELLATION
      </Button>
      <Button
        fullWidth
        size='large'
        style={useButtonStyle(colorValues.emptiness)}
        onClick={closeDialog}
      >
        NEVER MIND
      </Button>
      <CloseButton onClose={closeDialog} />
    </DialogBody>
  );
};

export default ConfirmCancellationPage;
