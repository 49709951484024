import React from 'react';
import styled from '@emotion/styled';
import { Checkbox } from '@betterpt/better-components';

const CheckboxLabel = styled.label`
  position: relative;
  right: 12px;
  width: 100%;
`;

type Props = {
    value: boolean;
    elementId: number;
    elementKey: string;
    onChange: any;
    label: string;
    level?: number;
}

const CustomCheckboxInputNew = (props: Props) => {
    return (
        <CheckboxLabel className='Body'>
            <Checkbox
                checked={props.value}
                onClick={() => props.onChange(props.elementId, props.elementKey, !props.value, props.level)}
            />
            {props.label}
        </CheckboxLabel>
    );
};

export default CustomCheckboxInputNew;
