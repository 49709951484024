import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

export const FormStyles = styled.div`
  padding: 0 40px;
  margin-top: 13px;
  max-width: 600px;
`;

export const CardTitle = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const SwitchSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const PageSectionHeader = styled.h2`
  font-size: 20px;
  color: ${colorValues.betterptblack};
`;

export const SectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-size: 16px;
    margin: 28px 0 22px;
  }
`;

export const headerStyle = { padding: '20px 25px 20px 40px', height: 46 };
