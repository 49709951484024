import { css } from '@emotion/react';
import { colorValues } from '../../helpers/colors';

export const mainSection = css`
  font-family: Roboto, sans-serif;
  padding: 30px 30px 28px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colorValues.concretejungle};
  h1 {
    font-size: 20px;
    color: ${colorValues.betterptblack};
    padding: 0;
    margin: 0;
  }
`;

export const searchSection = css`
  display: flex;
  align-items: center;
`;
