import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useMeQuery } from '../../../generated/graphql';
import useSnackbar from '../../../hooks/useSnackbar';

export default function useMeQueryWithLDIdentify() {
  const ldClient = useLDClient();
  const snackbar = useSnackbar();

  return useMeQuery({
    onError: () => snackbar?.useGenericErrorMessage(),
    onCompleted: ({ me }) => {
      me &&
        ldClient?.identify({
          name: `${me.firstName} ${me.lastName}`,
          email: me.email ?? '-',
          key: me.id,
          custom: {
            clinicIds: me.clinics?.map((clinic) => clinic?.id ?? '-') ?? [],
            companyId: me.company?.id ?? '-',
          },
        });
    },
  });
}
