import React from 'react';

//helpers
import { Roles } from '../../../helpers';
import { colorValues, bestTextColor } from '@betterpt/better-components';

//hooks
import { useMeQuery } from '../../../generated/graphql';
import { useHistory, Redirect } from 'react-router-dom';
import useFeatureAccessCheck from '../../../hooks/useFeatureAccessCheck';
import useRemoteConfigAliases from '../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../hooks/useSnackbar';
//components
import { Button, HorizontalNavigation } from '@betterpt/better-components';
import AddAppointmentButtonWithDialog from '../../Shared/AddAppointmentButtonWithDialog';
import AppointmentTypeDetail from '../AppointmentTypeDetail';
import Can from '../../Can';
import CompanyInfoTab from './CompanyInfoTab';

const Company = () => {
  const history = useHistory();
  const meQuery = useMeQuery();

  const snackbar = useSnackbar();

  React.useEffect(() => {
    if (meQuery.error?.message) {
      snackbar?.useGenericErrorMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meQuery.error]);

  const { hasSubscription } = useFeatureAccessCheck();

  const { employeeSingularAlias, tertiaryColor } = useRemoteConfigAliases();

  const tabs = [
    {
      display: 'Company Info',
      path: '/company',
      hideTab: true,
      exact: true,
      component: () => <Redirect to="/company/info" />,
    },
    {
      display: 'Company Info',
      path: '/company/info',
      exact: true,
      component: () => <CompanyInfoTab />,
    },
    {
      display: '',
      path: '/company/appointment-type/:appointmentTypeId',
      exact: true,
      hideTab: true,
      component: () => <AppointmentTypeDetail />,
    },
  ];

  const CTASection = [
    <AddAppointmentButtonWithDialog showInPersonOption={hasSubscription} key={1} />,
    <Can
      key={3}
      role={Roles[meQuery.data?.me?.role || 'initial']}
      perform="employee:create"
      yes={() => (
        <Button
          style={{
            backgroundColor: tertiaryColor,
            color: tertiaryColor === colorValues.frost ? colorValues.eveningsky : bestTextColor(tertiaryColor),
            minWidth: 174,
          }}
          onClick={() => history.push('/employees/new')}
        >
          ADD {employeeSingularAlias.toUpperCase()}
        </Button>
      )}
    />,
  ];

  return (
    <HorizontalNavigation
      pageBackgroundColor={colorValues.earlysmoke}
      navBackgroundColor={colorValues.emptiness}
      tabs={tabs}
      padding={'50px 20px'}
      buttons={CTASection}
      hideTabs={history.location?.pathname?.includes('appointment-type')}
    />
  );
};

export default Company;
