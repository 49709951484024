import { useMediaQuery } from '@material-ui/core';
import { InfoBox } from '@betterpt/better-components';
import * as React from 'react';

const EmailInfoBox = () => {
  const smallScreen = useMediaQuery('(max-width: 1190px)', { noSsr: true });

  return (
    <InfoBox
      openWith='hover'
      width={230}
      height={135}
      iconHeight={24}
      iconWidth={24}
      position={smallScreen ? 'left' : 'right'}
      padding='15px'
      fixedPlacement
      mainStyle={{ right: '-45px', top: '-5px' }}
    >
      <h4>COMPANY HQ EMAIL</h4>
      <p>
        Please enter a company contact email that users will be able to refer to
        if they have any questions.
      </p>
    </InfoBox>
  );
};

export default EmailInfoBox;
